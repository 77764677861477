import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../../front/Loader";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function States() {
    document.title = "BevPort - Keywords"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [keyword, setKeyword] = useState("")
    const [keywordError, setKeywordError] = useState(false)
    const [keywordID, setKeywordID] = useState("")
    const [buttonName, setButtonName] = useState("Submit")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        getKeywords();
    }, [])

    const handleStatus = async (status, id) => {
        setLoader(true);
        let formData = {
            "ID": id,
            "Status": status
        }
        await axios.post(API_URL + "ChangeKeywordStatus?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getKeywords();
                toast.success(res.data.responseMessage)
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }


    const getKeywords = async () => {
        await axios.post(API_URL + "GetAllKeywordsData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setData(res.data.responseData)
                setSubmitLoader(false);
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    const columns = [
        {
            name: 'Keywords',
            selector: row => (row.name).toLowerCase(),
            cell: row => row.name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == "0") ? <span style={{ "color": "#00aeef" }}>Inactive</span> : <span style={{ "color": "#00aeef" }}>Active</span>,
            sortable: true,
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "0") ? "1" : "0", row.id)}>{(row.status == "1") ? "DEACTIVATE" : "Activate"}</Link>&nbsp;<Link className="btn btn-primary" to={"#"} onClick={() => getKeywordInfo(row.id)}>Edit</Link>&nbsp;</>,
        }
    ];
    const handleKeyword = (e) => {
        setKeywordError(false)
        setKeyword(e.target.value)
    }
    const handleValidation = () => {
        let formValid = true
        if (!keyword) {
            setKeywordError(true)
            formValid = false
        }
        return formValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            if (keywordID) {
                updateKeywordRecord()
            } else {
                insertKeywordRecord()
            }
        }
    }
    const insertKeywordRecord = async () => {
        setLoader(true);
        let formData = {
            "Name": keyword
        }
        await axios.post(API_URL + "AddKeywordData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getKeywords();
                toast.success(res.data.responseMessage)
                setKeyword("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const getKeywordInfo = async (id) => {
        setLoader(true);
        let formData = {
            "ID": id
        }
        await axios.post(API_URL + "GetKeywordInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                window.scrollTo(0, 0)
                let data = res.data.responseData
                setKeyword(data?.name)
                setButtonName("Update")
                setKeywordID(data?.id)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const updateKeywordRecord = async () => {
        setLoader(true);
        let formData = {
            "ID": keywordID,
            "Name": keyword
        }
        await axios.post(API_URL + "UpdateKeywordData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getKeywords();
                toast.success(res.data.responseMessage)
                setKeyword("")
                setButtonName("Submit")
                setKeywordID("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }

    const tableData = {
        columns,
        data
    };

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Keywords</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className='wizardShowOnly'>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <form className="form" onSubmit={handleSubmit}>

                                                    <div className="form-group mx-sm-3 mb-2">
                                                        <label for="inputPassword2" >Keyword</label>
                                                        <input type="text" className={keywordError ? "form-control error" : "form-control"} placeholder="Keyword" onChange={handleKeyword} value={keyword} />
                                                    </div>
                                                    <button type="submit" className="btn btn-primary mb-2">{buttonName}</button>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='tbaShowcase'>

                                        <div className="Content-body dataTableSearchBox">
                                            <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Keywords">
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                    //defaultSortFieldId={1}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}