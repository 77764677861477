import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import {BLOB_BRAND_CONTAINER} from './../../config/constant.js';
const containerName = BLOB_BRAND_CONTAINER;
const sasToken = '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiyx&se=2028-03-31T15:31:16Z&st=2023-07-04T07:31:16Z&spr=https,http&sig=YSOVELove%2FSrONY0X2DiP2fotiYXQK%2FudNuRxbJy828%3D';
const storageAccountName = 'bevportfunctions20230303'; 
export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}
const getBlobsInContainer = async (containerClient: ContainerClient) => {
  const returnedBlobUrls: string[] = [];
  for await (const blob of containerClient.listBlobsFlat()) {
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/hello/${blob.name}`
    );
  }

  return returnedBlobUrls;
}

const createBlobInContainer = async (containerClient: ContainerClient, files: File) => {
  let res = '';
  files.map( async (file, key) => {
    const blobClient = containerClient.getBlockBlobClient(file.name);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    res = await blobClient.uploadData(file, options);
  })
  
  return true;
}
const uploadFileToBlob = async (file: File | null, containerName): Promise<string[]> => {
  if (!file.length) return [];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  /*await containerClient.createIfNotExists({
    access: 'container',
  });*/
  let fileUpload = await createBlobInContainer(containerClient, file);
  return fileUpload;//getBlobsInContainer(containerClient);
};

export default uploadFileToBlob;
export const deleteBlobIfItExists = async (blobName, containerName) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  const options = {
    deleteSnapshots: 'include'
  }
  const blockBlobClient = await containerClient.getBlockBlobClient(blobName);
  await blockBlobClient.deleteIfExists(options);
  console.log(`deleted blob ${blobName}`);
}
