import AddBrand from './../components/brewers/AddBrand'
import BrandList from './../components/brewers/BrandList'
import AddProduct from './../components/brewers/AddProduct'
import ProductList from './../components/brewers/productList'
import Profile from './../components/brewers/Profile'
import ProductDetail from './../components/brewers/productDetail'
import TeamMember from './../components/brewers/teamMember';
import EditProfile from './../components/brewers/editProfile';
import ChangePassword from './../components/brewers/changePassword';
import OrderHistoryPage from './../components/brewers/OrderHistory';
import SetupDeliveryPage from './../components/brewers/SetupDelivery';
import SetupDeliveryList from './../components/brewers/SetupDeliveryList';
import Compliance from './../components/brewers/complianceComponent/Compliance';
import Inventory from './../components/brewers/Inventory';
import OrderDetail from '../components/brewers/OrderDetails';
import Dashboard from '../components/brewers/Dashboard';
import EditInventory from '../components/brewers/EditInventory';
import BuyerList from '../components/brewers/BuyerList';
import OrderHistoryBuyer from '../components/brewers/OrderHistoryBuyer';
import OrderDetailBuyer from '../components/brewers/OrderDetailBuyer';
import SalesRep from '../components/brewers/SalesRep';
import AllSalesRep from '../components/brewers/AllSalesRep'
import AssociateSalesRep from '../components/brewers/AssociateSalesRep'
import FirstStep from '../components/brewers/FirstStep';
import FifthStep from '../components/brewers/FifthStep';
import BrewersOrderHistory from '../components/brewers/BrewerOrderHistoryList';
import BrewersInvoiceList from '../components/brewers/BrewersInvoiceLIst';
import BrewersInvoiceDetails from '../components/brewers/BrewerInvoiceDetails';
import BrewersComPliance from '../components/brewers/BrewerCompliance';
import SalesServices from '../components/brewers/SalesServices';
import SalesServicePayment from '../components/brewers/SalesServicePayment';
import BrewerBuyerView from '../components/brewers/BrewerBuyerView';
import BrewerBuyerViewDetail from '../components/brewers/BrewerBuyerViewDetail';

const route =  [
  { path: '/brewer/add-brand', Component: AddBrand},
  { path: '/brewer/edit-brand/:brID', Component: AddBrand},
  { path: '/brewer/brand-list', Component: BrandList},
  { path: '/brewer/add-product', Component: AddProduct},
  { path: '/brewer/add-product/:brID', Component: AddProduct},
  { path: '/brewer/product-list', Component: ProductList},
  { path: '/brewer/edit-product/:prodID', Component: AddProduct},
  { path: '/brewer/profile', Component: Profile},
  { path: '/brewer/product-detail/:productID', Component: ProductDetail},
  { path: '/brewer/team-member', Component: TeamMember},
  { path: '/brewer/edit-profile', Component: EditProfile},
  { path: '/brewer/change-password', Component: ChangePassword},
  { path: '/brewer/order-history', Component: OrderHistoryPage},
  { path: '/brewer/setup-delivery', Component: SetupDeliveryPage},
  { path: '/brewer/setup-delivery-list', Component: SetupDeliveryList},
  { path: '/brewer/compliance', Component: BrewersComPliance},
  { path: '/brewer/inventory', Component: Inventory},
  { path: '/brewer/order-detail/:orderID', Component: OrderDetail},
  { path: '/brewer/dashboard', Component: Dashboard},
  { path: '/brewer/inventory-edit/:productID', Component: EditInventory},
  { path: '/brewer/edit-setup-delivery/:id', Component: SetupDeliveryPage},
  { path: '/brewer/mybuyers', Component: BuyerList},
  { path: '/brewer/order-history-buyer/:buyerID', Component: OrderHistoryBuyer},
  { path: '/brewer/order-detail-buyer/:orderID', Component: OrderDetailBuyer},
  { path: '/brewer/salesrep', Component: SalesRep},
  { path: '/brewer/allSalesRep', Component: AllSalesRep},
  { path: '/brewer/associateSalesRep/:salesRepID', Component: AssociateSalesRep},
  { path: '/brewer/register/firststep', Component: FirstStep},
  { path: '/brewer/register/fifthstep', Component: FifthStep},
  { path: '/brewer/order-history-list', Component: BrewersOrderHistory},
  { path: '/brewer/invoice-list', Component: BrewersInvoiceList},
  { path: '/brewer/invoice-details/:orderID', Component: BrewersInvoiceDetails },
  { path: '/brewer/salesservices', Component: SalesServices },
  { path: '/brewer/salesservicepayment', Component: SalesServicePayment },
  { path: '/brewer/brewerbuyerview', Component: BrewerBuyerView },
  { path: '/brewer/brewerbuyerviewdetail/:productID', Component: BrewerBuyerViewDetail },
]
export default route;