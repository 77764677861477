import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Swal from 'sweetalert2';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};


toast.configure()
export default function AdminContactList() {
	document.title = "BevPort - Contact List"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const [data, setData] = useState([])
	useEffect(() => {
		getSalesServicesList()
	}, [])


	const groupByMaxDateAndUserId = (data, paramChargeAmount) => {
		const grouped = {};

		data.forEach((item) => {
			const { userID, createdDate, chargeAmount} = item;

			if (!grouped[userID]) {
				if (chargeAmount === paramChargeAmount) {
					grouped[userID] = item;
				}
				
			} else {
				const existingDate = new Date(grouped[userID].createdDate);
				const newDate = new Date(createdDate);

				if (newDate > existingDate) {
					if (chargeAmount === paramChargeAmount) {
						grouped[userID] = item;
					}
					
				}
			}
		});

	

		return Object.values(grouped);
	};


	// Get Sales Services List --> for grid
	const getSalesServicesList = async (userID) => {
		await axios.post(API_URL + "SalesServiceGetAll?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {

				setData(res.data.responseData)
				console.log("resposnse", res.data.responseData);

				var returndata = res.data.responseData; //return data for grid

				// 1500
				const result = groupByMaxDateAndUserId(returndata, 1500); // rows that are editable function
				for (var i = 0; i < returndata.length; i++) {
					for (var j = 0; j < result.length; j++)
					{
						if (returndata[i].id === result[j].id) {
							returndata[i].isRowEditable = true;
						}
					}							
				}
				////------------------------------------------------------------------------------------------

				// 500
				const result2 = groupByMaxDateAndUserId(returndata, 500); // rows that are editable function
				for (var k = 0;  k < returndata.length; k++) {

					for (var m = 0; m < result2.length; m++) {
						if (returndata[k].id === result2[m].id) {
							returndata[k].isRowEditable = true;
						}
					}
				}
				//-------------------------------------------------------------------------------------------

			

			} else {
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	//------------------------ end get admin sales services List ------------------




	// Get Sales Services List --> for grid
	const openEditModalWindow = async (row) =>
	{
        // Modal Open swalfire
        var modalHtml = "<p>  For " + row.userName + "  </br> </br>  <input id='serviceIsActive' type='checkbox'> </input> &nbsp; <label>IsActive</label> </p>";
        var Title = "Edit Service";
        var modalText = "";


        Swal.fire({
            title: Title,
            text: modalText,
            html: modalHtml,
            icon: 'question',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Update',
            denyButtonText: 'NO',
            background: '#fff',
            color: '#000',
            iconColor: "rgb(255, 153, 0)",
            width: "500px",
            padding: '2em',
            reverseButtons: false
        }).then((result) => {
            if (result.isConfirmed) {

				handleEdit_SalesService(row);

            } else if (result.isDenied) {

            }
        })
        //--------------

        document.getElementById("serviceIsActive").checked = row.active;
	}
	//---------------------------------------end openEditModalWindow -----------------------------------------


	// handleCancel_SalesService for any amount
	const handleEdit_SalesService = async (row) => {

		// set loading animation
		setLoader(true);

		//alert(row.userID);
		//alert(row.chargeType);
		//alert(row.id);

	
		// formData to pass to api
		let formData = { 
			"UserID": row.userID,
			"ID": row.id,
			"Active": document.getElementById("serviceIsActive").checked
		}

		await axios.post(API_URL + "SalesServiceAdminEdit?code=" + API_KEY, formData, config).then(async (res) => {

			if (res.data.responseCode === "200")
			{
				toast.success(res.data.responseMessage);


				// rebind grid
				getSalesServicesList();
			} else {
				toast.error(res.data.responseMessage);
			}
			//-----

			setLoader(false)
		}).catch(function (error) {
			console.log(error)
			setLoader(false)

		})
		//-------------------------------------------

	}
    //------------------------------------ end handleEdit_SalesService -----------------------------------------------------------------------------


	// -----------------------------Table columns ---------------------
	const columns = [

		//{
		//	name: 'ID',
		//	selector: row => (row.id).toLowerCase(),
		//	cell: row => (row.id),
		//	sortable: true,

		//},
		{
			name: 'Name',
			selector: row => (row.userName).toLowerCase(),
			cell: row => (row.userName),
			sortable: true,
		},
		{
			name: 'Charge ID',
			selector: row => (row.chargeID).toLowerCase(),
			cell: row => (row.chargeID),
			sortable: true,
		},
		{
			name: 'Charge Status',
			selector: row => (row.chargeStatus).toLowerCase(),
			cell: row => (row.chargeStatus),
			sortable: true,
		},
		{
			name: 'Reject Reason',
			selector: row => (row.rejectReason).toLowerCase(),
			cell: row => (row.rejectReason),
			sortable: true,
		},
		{
			name: 'Payment Intent ID',
			selector: row => (row.paymentIntentID).toLowerCase(),
			cell: row => (row.paymentIntentID),
			sortable: true,
		},
		{
			name: 'Created Date',
			selector: row => (row.createdDate).toLowerCase(),
			cell: row => row.createdDate ? moment(row.createdDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true,
		},
	
		{
			name: 'Updated Date',
			selector: row => (row.updatedDate).toLowerCase(),
			cell: row => row.updatedDate ? moment(row.updatedDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true,
		},
		{
			name: 'Customer ID',
			selector: row => (row.customerID).toLowerCase(),
			cell: row => (row.customerID),
			sortable: true,
		},
		{
			name: 'Charge Amount',
			selector: row => (row.chargeAmount).toLowerCase(),
			cell: row => (row.chargeAmount),
			sortable: true,
		},
		{
			name: 'Charge Type',
			selector: row => (row.chargeType).toLowerCase(),
			cell: row => (row.chargeType),
			sortable: true,
		},
		{
			name: 'Is Active',
			selector: row => (row.active).toLowerCase(),
			cell: row => (row.active === true) ? <span style={{ "color": "#00aeef" }}>Active</span> : <span style={{ "color": "#00aeef" }}>Inactive</span>,
			sortable: true,
		},

		{
			name: 'Edit',
			//selector: row => (row.active).toLowerCase(),
			cell: row => (row.isRowEditable === true) ? <span onClick={() => openEditModalWindow(row)} style={{ "color": "#00aeef", cursor: "pointer" }}>Edit</span> : <span></span>,
			sortable: true,
		},

	];
	const tableData = {
		columns,
		data
	};
	return (
		<>
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Sales Service List</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={"No record found!"}


											//defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}
