
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import homecnavs from "./../../assets/images/homecnavs.png";
import brewermaie from "./../../assets/images/brewermaie.png";
import InputMask from 'react-input-mask';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, SITE_KEY } from './../../config/constant';
import Header from './Header';
import Footer from './Footer';
import Loader from "./Loader"
import StripePaymentCard from "./stripePaymentCard";
import BankCardInfo from "./bankInfoCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { STRIPE_PK } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Swal from 'sweetalert2';

const stripePromise = loadStripe(STRIPE_PK)
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
function Registration(props) {

    document.title = "BevPort - Brewers Registration"
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const [fName, setFname] = useState("")
    const [fnameError, setFnameError] = useState(false)
    const [lName, setLname] = useState("")
    const [lnameError, setLnameError] = useState(false)
    const [email, setEmail] = useState("")  
    const [emailError, setEmailError] = useState(false)

    const [primaryBusiness, setPrimaryBusiness] = useState("")
    const [primaryBusinessError, setPrimaryBusinessError] = useState(false)
    const [primaryBusinessList, setprimaryBusinessList] = useState([])

    const [cEmail, setCEmail] = useState("")
    const [cEmailError, setCEmailError] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [cPassword, setCPassword] = useState("")
    const [cPasswordError, setCPasswordError] = useState(false)
    const [emailSameError, setEmailSameError] = useState(false)
    const [passwordSameError, setPasswordSameError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [passText, setPassText] = useState("")
    const [dupError, setDupError] = useState(false)
    const [dupErrorText, setDupErrorText] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false);
    const [visible, SetVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [btnDisabled, setBtnDisable] = useState(false);

    //*****************************Business Details Variables***************************************** */

    const [bName, setBname] = useState("")
    const [bnameError, setbNameError] = useState(false)
    const [ein, setEin] = useState("")
    const [einError, setEinError] = useState(false)
    const [stn, setStn] = useState("")
    const [country, setCountry] = useState(1)
    const [countryError, setCountryError] = useState(false)
    const [state, setState] = useState(15)
    const [stateError, setStateError] = useState(false)
    const [city, setCity] = useState("")
    const [cityError, setCityError] = useState(false)
    const [street, setStreet] = useState("")
    const [streetError, setStreetError] = useState(false)
    const [zip, setZip] = useState("")
    const [zipError, setZipError] = useState(false)
    const [mobile, setMobile] = useState("")
    const [mobileError, setMobileError] = useState(false)
    const [altMobile, setAltMobile] = useState("")
    const [stateList, setStateList] = useState([])
    const [check, setCheck] = useState("")
    const [checkError, setCheckError] = useState(false)
    const [altMobError, setaltMobError] = useState(false);

    const [humanError, setHumanError] = useState(false)



    //********************************************************END***************************************************************** */

    //*********************************************Bank Account Details Variables************************************************* */
    const [bankName, setBankName] = useState("")
    const [bankNameError, setBankNameError] = useState(false)
    const [bankAddress, setBankAddress] = useState("")
    const [bankAddressError, setBankAddressError] = useState(false)
    const [routingNumber, setRoutingNumber] = useState("")
    const [routingNumberError, setRoutingNumberError] = useState(false)
    const [accountNumber, setAccountNumber] = useState("")
    const [accountNumberError, setAccountNumberError] = useState(false)
    const [accountType, setAccountType] = useState("")
    const [accountTypeError, setAccountTypeError] = useState(false)
    const [nameAccount, setNameAccount] = useState("")
    const [accountNameError, setAccountNameError] = useState(false)

    //************************************************END Bank Account Details Variables********************************************* */


    //*******************************************Bank Info Variables ************************************************************ */
    const [bInfoName, setBinfoName] = useState("");
    const [bInfoNameError, setBinfoNameError] = useState(false);
    const [bInfoAddress, setBinfoAddress] = useState("");
    const [bInfoAddressError, setbInfoAddressError] = useState(false);
    const [bInfoRouting, setBinfoRouting] = useState("");
    const [bInfoRoutingError, setBinfoRoutingError] = useState(false);
    const [bInfoAccountNo, setBinfoAccountNo] = useState("");
    const [bInfoAccountNoError, setBinfoAccountNoError] = useState(false);
    const [bInfoAccountType, setBinfoAccountType] = useState("");
    const [bInfoAccountTypeError, setBinfoAccountTypeError] = useState(false);
    const [bInfoNameAccount, setBinfoNameAccount] = useState("");
    const [bInfoNameAccountError, setBinfoNameAccountError] = useState(false);


    //********************************************End Bank info******************************************************* */

    //**************************************************Plan and Payment Variables*************************************************** */
    const [memberShipPlan, setMemberShipPlan] = useState("")
    const [memberShipError, setMemberShipError] = useState(false)
    const [pMethodError, setPMethodError] = useState(false)
    const [nameCard, setNameCard] = useState("")
    const [nameCardError, setNameCardError] = useState(false)
    const [cardNumberError, setCardNumberError] = useState(false)
    const [cardNumberErrorText, setCardNumberErrorText] = useState("")
    const [cardExpireError, setCardExpireError] = useState(false)
    const [cardExpireErrorText, setCardExpireErrorText] = useState("")
    const [cardCvvError, setCardCvvError] = useState(false)
    const [cardCvvErrorText, setCardCvvErrorText] = useState("")
    const [planList, setPlanList] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("")
    useEffect(() => {
        let id = localStorage.getItem("id");
        let isAuthentic = localStorage.getItem("isAuthentic")
        if (id && isAuthentic === "true") {
            let userType = localStorage.getItem("userType")
            if (userType == "1") {
                navigate("/brewer/add-brand")
            } else if (userType == "2") {

            }
        }


    }, [])

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
            if (isScriptExist && callback) callback();
        }
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    //**************************************************END Plan and Payment Variables************************************************** */
    const handleFirstName = (e) => {
        setFnameError(false)
        setFname(e.target.value)
    }
    const handleLastName = (e) => {
        setLnameError(false)
        setLname(e.target.value)
    }
    const handleEmail = (e) => {
        setEmailError(false)
        setEmailSameError(false)
        setEmail(e.target.value)
        setDupError(false)
        setDupErrorText("");
    }

    const handlePrimaryBusiness = (e) => {
         setPrimaryBusinessError(false)
      
       // alert(e.target.value);
        setPrimaryBusiness(e.target.value)
    }
    const handleConfirmEmail = (e) => {
        setCEmailError(false)
        setEmailSameError(false)
        setCEmail(e.target.value)
    }
    const handlePassword = (e) => {
        setPassError(false)
        setPassText("")
        setPasswordError(false)
        setPassword(e.target.value)
    }
    const handleConfirmPassword = (e) => {
        setPasswordSameError(false)
        setCPasswordError(false)
        setCPassword(e.target.value)
    }
    const handleShow = () => {
        SetVisible(!visible);
    }

    const handleConfirmShow = () => {
        setConfirmVisible(!confirmVisible)
    }
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function checkPwd(str) {
        if (str.length < 6) {
            setPassError(true)
            setPassText("Must have at least 6 characters.")
            return false;
        } else if (str.search(/\d/) == -1) {
            setPassError(true)
            setPassText("Must have at least one number.")
            return false;
        } else if (str.search(/[A-Z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one capital letter.")
            return false;
        } else if (str.search(/[a-z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one small letter.")
            return false;
        } else if (str.search(/[!@#$%^&*()]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one symbol.")
            return false;
        }
        return true;
    }

    const checkEmailAddressFunc = async (e) => {
        setDupError(false)
        setDupErrorText("")
        await axios.post(API_URL + "GetUser?code=" + API_KEY, { EmailID: e.target.value }, config).then((res) => {
            if (res.data.responseCode === "401") {
                setDupError(true)
                setDupErrorText(res.data.responseMessage)

                //  setBtnDisable(true);
            } else {
                setBtnDisable(false);
            }
        }).catch(function (error) {
            console.log(error)
            setBtnDisable(false);
        })
    }

    //****************************************************Business Details function****************************************************************** */
    useEffect(() => {
        getStateList()
        getPrimaryBusinessList();
    }, [])

    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setStateList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    //-----------------


    const getPrimaryBusinessList = async () => {

        await axios.post(API_URL + "GetBrewerUserTypes?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode === "200") {
                setprimaryBusinessList(res.data.responseData);
            }

            else {
              
            }

           
        }).catch(function (error) {
            console.log(error)
        })
    }
    //-----------

    useEffect(() => {
        if (mobile != "" && altMobile != "") {
            checkAltNo();
        }
    }, [mobile, altMobile])

    const handleCheck = (e) => {
        setCheck(e.target.checked);
        setCheckError(false);
    }


    const handleHumanCheck = (e) => {
        setHumanError(e.target.checked);
    }

    
    const handleBName = (e) => {
        setbNameError(false)
        setBname(e.target.value)
    }
    const handleEIN = (e) => {
        setEinError(false)
        setEin(e.target.value)
    }
    const handleSTN = (e) => {
        setStn(e.target.value)
    }
    const handleCountry = (e) => {
        setCountryError(false)
        setCountry(e.target.value)
    }
    const handleState = (e) => {
        setStateError(false)
        setState(e.target.value)
    }
    const handleCity = (e) => {
        setCityError(false)
        setCity(e.target.value)
    }
    const handleStreet = (e) => {
        setStreetError(false)
        setStreet(e.target.value)
    }
    const handleZIP = (e) => {
        setZipError(false)
        setZip(e.target.value)
    }
    const handleMobile = (e) => {
        setMobileError(false)
        setMobile(e.target.value)
    }
    const handleAltMobile = (e) => {
        setAltMobile(e.target.value);
        if (altMobile == mobile) {
            setaltMobError(false)
        }
    }


    const checkAltNo = () => {
        if (mobile != "" && altMobile != "") {
            if (mobile == altMobile) {
                setaltMobError(true);
                setBtnDisable(true);
            } else {
                setaltMobError(false);
                setBtnDisable(false);
            }
        }
    }


    //******************************************************END  Business Details function******************************************************************* */
    //***************************************************************Bank Account Details Function*************************************************************************** */
    const handleBankName = (e) => {
        setBankNameError(false)
        setBankName(e.target.value)
    }
    const handleBankAddress = (e) => {
        setBankAddressError(false)
        setBankAddress(e.target.value)
    }
    const handleRoutingNumber = (e) => {
        setRoutingNumberError(false)
        setRoutingNumber(e.target.value)
    }
    const handleAccountNumber = (e) => {
        setAccountNumberError(false)
        setAccountNumber(e.target.value)
    }
    const handleAccountType = (e) => {
        setAccountTypeError(false)
        setAccountType(e.target.value)
    }
    const handleNameAccount = (e) => {
        setAccountNameError(false)
        setNameAccount(e.target.value)
    }

    //***************************************************************END Bank Account Details Fuction************************************************************************************ */

    //**************************************************************Bank Info Function***************************************************** */

    const handleBinfoName = (e) => {
        setBinfoName(e.target.value);
        setBinfoNameError(false)
    }

    const handleBinfoAddress = (e) => {
        setBinfoAddress(e.target.value);
        setbInfoAddressError(false);
    }

    const handleBinfoRoutingNo = (e) => {
        setBinfoRouting(e.target.value);
        setBinfoRoutingError(false);
    }

    const handleBinfoAccountNo = (e) => {
        setBinfoAccountNo(e.target.value);
        setBinfoAccountNoError(false);
    }

    const handleBinfoAccountType = (e) => {
        setBinfoAccountType(e.target.value);
        setBinfoAccountTypeError(false);
    }

    const handleBinfoNameAccount = (e) => {
        setBinfoNameAccount(e.target.value);
        setBinfoNameAccountError(false);
    }

    //******************************************************************End Bank info Function****************************************************************** */




    //***************************************************************Plan and Payment Function************************************************************************************************* */

    useEffect(() => {
        getPlanDetails()
    }, [])
    const getPlanDetails = async () => {
        //process.env.REACT_APP_ENV
        await axios.post(API_URL + "GetPlans?code=" + API_KEY, { Type: process.env.REACT_APP_ENV }, config).then((res) => {
            if (res.data.responseCode === "200") {
                setPlanList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    const handleMemberPlan = (e) => {
        if (e.target.value == "Select Membership Plan") {
            setMemberShipPlan("")
        } else {
            setMemberShipError(false)
            setMemberShipPlan(e.target.value)
        }
    }
    const handlePaymentMethod = (e) => {
        setPMethodError(false)
        setPaymentMethod(e.target.value)
        setBinfoNameError(false)
        setbInfoAddressError(false)
        setBinfoRoutingError(false)
        setBinfoAccountNoError(false)
        setBinfoAccountTypeError(false)
        setBinfoNameAccountError(false)
        setNameCardError(false)
        setCardNumberError(false)
        setCardExpireError(false)
        setCardCvvError(false)
        if (e.target.value == "bank") {
            setBinfoName(bankName)
            setBinfoAddress(bankAddress)
            setBinfoRouting(routingNumber)
            setBinfoAccountNo(accountNumber)
            setBinfoAccountType(accountType)
            setBinfoNameAccount(nameAccount)
        } else {
            setBinfoName("")
            setBinfoAddress("")
            setBinfoRouting("")
            setBinfoAccountNo("")
            setBinfoAccountType("")
            setBinfoNameAccount("")
        }
    }
    const handleNameCard = (e) => {
        const regex = /^[a-zA-Z ]*$/;
        const value = e.target.value;
        if (regex.test(value)) {
            setNameCard(value);
            setNameCardError(false);
        }

    }
    const stripeElementChange = (e) => {
        setHandleStripeForm(false)
        setCardNumberError(false)
        if (!e.complete) {
            setCardNumberError(true)
            setCardNumberErrorText(e?.error?.message)
        }
    }
    const stripeCvvChange = (e) => {
        setHandleStripeForm(false)
        setCardCvvError(false)
        if (!e.complete) {
            setCardCvvError(true)
            setCardCvvErrorText(e?.error?.message)
        }
    }
    const stripeExpireChange = (e) => {
        setHandleStripeForm(false)
        setCardExpireError(false)
        if (!e.complete) {
            setCardExpireError(true)
            setCardExpireErrorText(e?.error?.message)
        }
    }

    //***************************************************************END Plan and Payment Function************************************************************************************************ */

    const handleValidation = () => {
        let formIsValid = true;

        var zoomElement_ContactDetails = document.getElementById("zoomContactDetails");
        var zoomElement_BusinessDetails = document.getElementById("zoomBusinessDetails");
        var zoomElement_BankDetails = document.getElementById("zoomBankDetails");
        var zoomElement_MemberShipDetails = document.getElementById("zoomMemberShipDetails");


        if (paymentMethod == 0) {
            formIsValid = false;
            setPMethodError(true);
            // on error --> scroll to div where the error is
            zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (paymentMethod === "bank")
        {
            if (!bInfoName) {
                formIsValid = false;
                setBinfoNameError(true);
                zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
            }
            if (!bInfoAddress) {
                formIsValid = false;
                setbInfoAddressError(true);
                zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
            }
            if (!bInfoRouting) {
                formIsValid = false;
                setBinfoRoutingError(true);
                zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
            }
            if (!bInfoAccountNo) {
                formIsValid = false;
                setBinfoAccountNoError(true);
                zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
            }
            if (!bInfoAccountType) {
                formIsValid = false;
                setBinfoAccountTypeError(true);
                zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
            }
            if (!bInfoNameAccount) {
                formIsValid = false;
                setBinfoNameAccountError(true);
                zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
            }
        }


        if (!check) {
            formIsValid = false;
            setCheckError(true);
        }

        if (!humanError) {


            var hidden = document.getElementsByClassName("classHumanCheck");
            for (var i = hidden.length - 1; i > -1; i--) {
                hidden[i].classList.remove("hidden");
            }

         


            formIsValid = false;
            setHumanError(true);
        }

        if (paymentMethod === "cc" && !nameCard) {
            formIsValid = false;
            setNameCardError(true);          
            zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });  // on error --> scroll to div where the error is
        }

        if (!memberShipPlan) {
            formIsValid = false;
            setMemberShipError(true);
            zoomElement_MemberShipDetails.scrollIntoView({ behavior: 'smooth' });
        }


        // jesse dudley - 2/19/2024 commented out validation for all 6 bank information fields --- because we are making it optional for the user 
        //--also they will not get payed for sales-- until they fill out this bank information - which a messsage goes in a popup telling them after they submit


        //if (!nameAccount) {
        //    formIsValid = false;
        //    setAccountNameError(true);
        //    zoomElement_BankDetails.scrollIntoView({ behavior: 'smooth' });
        //}
        //if (!accountType) {
        //    formIsValid = false;
        //    setAccountTypeError(true);
        //    zoomElement_BankDetails.scrollIntoView({ behavior: 'smooth' });
        //}
        //if (!accountNumber) {
        //    formIsValid = false;
        //    setAccountNumberError(true);
        //    zoomElement_BankDetails.scrollIntoView({ behavior: 'smooth' });
        //}
        //if (!routingNumber) {
        //    formIsValid = false;
        //    setRoutingNumberError(true);
        //    zoomElement_BankDetails.scrollIntoView({ behavior: 'smooth' });
        //}

        //if (!bankAddress) {
        //    formIsValid = false;
        //    setBankAddressError(true);
        //    zoomElement_BankDetails.scrollIntoView({ behavior: 'smooth' });
        //}
        //if (!bankName) {
        //    formIsValid = false;
        //    setBankNameError(true);
        //    zoomElement_BankDetails.scrollIntoView({ behavior: 'smooth' });
        //}


        if (!mobile) {
            formIsValid = false;
            setMobileError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!zip) {
            formIsValid = false;
            setZipError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!street) {
            formIsValid = false;
            setStreetError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!city) {
            formIsValid = false;
            setCityError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!state) {
            formIsValid = false;
            setStateError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!country) {
            formIsValid = false;
            setCountryError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!ein) {
            formIsValid = false;
            setEinError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!bName) {
            formIsValid = false;
            setbNameError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        //----------------

       
        if (primaryBusiness == 0) {
            formIsValid = false;
            setPrimaryBusinessError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (cPassword && password !== cPassword) {
            formIsValid = false;
            setPasswordSameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!cPassword) {
            formIsValid = false;
            setCPasswordError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (password && !checkPwd(password)) {
            formIsValid = false;
            setPasswordError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!password) {
            formIsValid = false;
            setPasswordError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        // if duplicate email in the system 
        if (dupError == true) {
            // on error --> scroll to div where the error is
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (cEmail && email !== cEmail) {
            formIsValid = false;
            setEmailSameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!cEmail) {
            formIsValid = false;
            setCEmailError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!validateEmail(email)) {
            formIsValid = false;
            setEmailError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!email) {
            formIsValid = false;
            setEmailError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!lName) {
            formIsValid = false;
            setLnameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }


        if (!fName) {
            formIsValid = false;
            setFnameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
         

        return formIsValid;
    }

    // end validation --


    const BankInformation_ModalWindow = (someOrAll) => {
   
        // Check User's Local Storage --> activityState --> to see what html to show
        var modalHtml = "";
        var Title = "Bank Information";
        var modalText = "“You have not filled out " + someOrAll + " your Bank information! Please note that you will need to provide that information in order to be paid for sales. Visit your profile on the Dashboard to provide information at a later date.”";      

        Swal.fire({
            title: Title,
            text: modalText,
            html: modalHtml,
            icon: 'question',
            showDenyButton: false,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Continue',
            denyButtonText: 'NO',
            background: '#fff',
            color: '#000',
            iconColor: "rgb(255, 153, 0)",
            width: "500px",
            padding: '2em',
            reverseButtons: false
        }).then((result) => {

            // ---
            if (result.isConfirmed)
            {             
                navigate('/login');
               
            } else if (result.isDenied) {
                        
            }
        })
    }
    //-----------------------------------------------------end BankInformation_ModalWindow --------------------------------------------------------------

    const [handleStripeForm, setHandleStripeForm] = useState(false)
    const [handleTokenFun, setHandleTokenFunc] = useState(false)
    const generateToken = () => {
        setHandleTokenFunc(true)
    }
    const [stripeError, setStripeError] = useState(false)

    const handleBankInfoSumbit = async () => {
     
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async ctoken => {
                let formData = {
                    "FirstName": fName,
                    "LastName": lName,
                    "emailid": email,
                    "Password": password,
                    "UserType": "1",
                    "BusinessName": bName,
                    "EIN": ein,
                    "StateTaxNumber": stn,
                    "StreetAddress": street,
                    "BusinessPhone": mobile,
                    "OptionalPhone": altMobile,
                    "State": state,
                    "Country": country,
                    "City": city,
                    "ZipCode": zip,
                    "BankName": bankName,
                    "AccountNumber": accountNumber,
                    "AccountName": nameAccount,
                    "AccountType": accountType,
                    "RoutingNumber": routingNumber,
                    "BankAddress": bankAddress,
                    "tokenId": "",
                    "PriceID": memberShipPlan,
                    "CaptchaToken": ctoken,
                    "UserBankName": bInfoName,
                    "UserAccountNumber": bInfoAccountNo,
                    "BankRoutingNumber": bInfoRouting,
                    "BankAccountType": bInfoAccountType,
                    "UserBankAddress": bInfoAddress,
                    "UserAccountName": bInfoNameAccount,
                    "BrewerUserType": primaryBusiness
                }
                await axios.post(API_URL + "RegisterUser?code=" + API_KEY, formData, config).then((res) => {
                    if (res.data.responseCode === "200") {

                        toast.success(res.data.responseMessage);

                        navigate('/login');
                       
                    } else {
                        toast.error(res.data.responseMessage);
                    }
                    setSubmitLoader(false)
                    setBtnDisable(false);
                }).catch(function (error) {
                    console.log(error)
                    setSubmitLoader(false)
                    setBtnDisable(false);
                })
            })
        })
    }

    const handleTokenData = async (error, token) => {

        console.log("teee",)
        setHandleTokenFunc(false)

        if (!error) {
            console.log("jashdjas");
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async ctoken => {
                    let formData = {
                        "FirstName": fName,
                        "LastName": lName,
                        "emailid": email,
                        "Password": password,
                        "UserType": "1",
                        "BusinessName": bName,
                        "EIN": ein,
                        "StateTaxNumber": stn,
                        "StreetAddress": street,
                        "BusinessPhone": mobile,
                        "OptionalPhone": altMobile,
                        "State": state,
                        "Country": country,
                        "City": city,
                        "ZipCode": zip,
                        "BankName": bankName,
                        "AccountNumber": accountNumber,
                        "AccountName": nameAccount,
                        "AccountType": accountType,
                        "RoutingNumber": routingNumber,
                        "BankAddress": bankAddress,
                        "tokenId": token?.id,
                        "PriceID": memberShipPlan,
                        "CaptchaToken": ctoken,
                        "UserBankName": "",
                        "UserAccountNumber": "",
                        "BankRoutingNumber": "",
                        "BankAccountType": "",
                        "UserBankAddress": "",
                        "UserAccountName": "",
                        "BrewerUserType": primaryBusiness
                    }

                    await axios.post(API_URL + "RegisterUser?code=" + API_KEY, formData, config).then((res) => {
                        if (res.data.responseCode === "200") {

                            toast.success(res.data.responseMessage);

                            // handle bank information --modal window ----> when the user - does not completly fill out, Just some of there bank information
                            //-- or  even all of the fields are blank.
                            var someOrAll = "";
                            if (!bankName && !bankAddress && !routingNumber && !accountNumber && !accountType && !nameAccount) {
                                var ifString = "None of your Bank Information fields were filled out.";
                                someOrAll = "All";
                                BankInformation_ModalWindow(someOrAll);
                            }

                            else if (!bankName || !bankAddress || !routingNumber || !accountNumber || !accountType || !nameAccount) {
                                var ifString = "1 or more of your Bank Information fields were not filled out.";
                                someOrAll = "Some of";
                                BankInformation_ModalWindow(someOrAll);
                            }

                            else {                             
                                navigate('/login');
                            }

                           
                        } else {
                            toast.error(res.data.responseMessage);
                        }
                        setSubmitLoader(false)
                        setBtnDisable(false);
                    }).catch(function (error) {
                        console.log(error)
                        setSubmitLoader(false)
                        setBtnDisable(false);
                    })
                })
            })

        } else {
            toast.error(error?.message);
            setSubmitLoader(false)
            setBtnDisable(false);
        }
    }
    const handleStripeFormFun = () => {
        setHandleStripeForm(true)
    }
    const handleSubmit = async (e) =>
    {
        e.preventDefault()
        if (paymentMethod === "cc") {
            handleStripeFormFun();
        }
        if (handleValidation()) {
            if (paymentMethod === "cc")
            {
                if (!stripeError) {
                    setSubmitLoader(true)
                    setBtnDisable(true);
                    generateToken();
                }

                // un hide human check box
                var hidden = document.getElementsByClassName("classHumanCheck");
                for (var i = hidden.length - 1; i > -1; i--) {
                    hidden[i].classList.remove("hidden");
                }

               
            }
            else if (paymentMethod === "bank")
            {

                //Jesse Dudley 2/2/2024 ----> Code Never Comes Here--- paymentMethod === "bank"  --- Review and get Rid Of Code Next Time - If Not Needed
                // The Form Gets submited through Stripe ---> handleTokenData


                // put back in after testing 
                setSubmitLoader(true)
                setBtnDisable(true);

                handleBankInfoSumbit();
              
            } // end else ---- paymentMethod === "bank" ---> 


        } // end if
    } // end handle submit -----------------

    const resetAll = () => {
        setFname("");
        setLname("");
        setEmail("");
        setCEmail("");
        setPassword("");
        setCPassword("");
        setBname("");
        setEin("");
        setStn("");
        setCountry("");
        setState("");
        setCity("");
        setStreet("");
        setZip("");
        setMobile("");
        setAltMobile("");
        setBankName("");
        setBankAddress("");
        setRoutingNumber("");
        setAccountNumber("");
        setAccountNumber("");
        setAccountType("");
        setNameAccount("");
        setBinfoName("");
        setBinfoAddress("");
        setBinfoRouting("");
        setBinfoAccountNo("");
        setBinfoAccountType("");
        setBinfoNameAccount("");
        setPrimaryBusiness(0);
    }
    return (
        <>
            {submitLoader ? <Loader /> : ""}
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />

                    <section className="simplyfying Empowering registraionPages" id="simplys">
                        <div className="simplyfyingDiv">
                            <div className="container-fluid">
                                <div className="row upcontent">
                                    <div className="col-md-5 simplecontent registrationPg">
                                        <div className="simplecontentimg">
                                            <img className="img-responsive" src={homecnavs} />
                                        </div>
                                    </div>
                                    <div className="col-md-7 manBgimg">
                                        <div className="manImg">
                                            <img className="img-responsive" src={brewermaie} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="moveTopUp registraionPhone">
                                        <div className="container">
                                            <div className="row main-contentprt">
                                                <div className="col-md-4">
                                                    <div className="textSimply">
                                                        <h1><span class="browntext">Brewers</span>Signup</h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                   {/* // contact Details ---> scroll to div on form submit error*/}
                    <div id="zoomContactDetails">
                    </div>

                    <section id="registerformc">
                        <div className="regiterComponent">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-headings">
                                            <h2>Contact Details</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">First Name <span className="red-tick"> *</span></label>
                                            <input type="text" className={fnameError ? "form-control error" : "form-control"} value={fName} placeholder="Enter First Name" onChange={handleFirstName} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Last Name <span className="red-tick"> *</span></label>
                                            <input type="text" className={lnameError ? "form-control error" : "form-control"} value={lName} placeholder="Enter Last Name" onChange={handleLastName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Email <span className="red-tick">*</span></label>
                                            <input type="text" className={emailError ? "form-control error" : "form-control"} value={email} placeholder="Enter Email ID" onChange={handleEmail} onBlur={checkEmailAddressFunc} />
                                            {dupError ? <span className="error-message">{dupErrorText}</span> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Confirm Email <span className="red-tick">*</span></label>
                                            <input type="text" className={cEmailError ? "form-control error" : "form-control"} value={cEmail} placeholder="Confirm Email ID" onChange={handleConfirmEmail} />
                                            {emailSameError ? <span className="error-message">Confirm email should be same as email.</span> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group passwordeye">
                                            <label for="firstname">Password <span className="red-tick">*</span></label>
                                            <div className="showhidepassowrd">
                                                <input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} value={password} placeholder="Enter Password" onChange={handlePassword} />
                                                {passError ? <span className="error-message">{passText}</span> : ""}
                                                <span className="eyeslash" onClick={handleShow} > {!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group passwordeye">
                                            <label for="firstname">Confirm Password <span className="red-tick">*</span></label>
                                            <div className="showhidepassowrd">
                                                <input type={confirmVisible ? "text" : "password"} className={cPasswordError ? "form-control error" : "form-control"} value={cPassword} placeholder="Confirm Password" onChange={handleConfirmPassword} />
                                                {passwordSameError ? <span className="error-message">Confirm password should be same as password.</span> : ""}
                                                <span className="eyeslash" onClick={handleConfirmShow} > {!confirmVisible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Primary Business</label>
                                            <select className={primaryBusinessError ? "form-control error" : "form-control"} name="ddlPrimaryBuesiness" id="ddlPrimaryBuesiness" onChange={handlePrimaryBusiness} value={primaryBusiness} >

                                               
                                                <option value="0">--Select--</option>
                                                {
                                                    primaryBusinessList.length && primaryBusinessList.map((_v, _i) => {
                                                        return (
                                                            <option key={_i} value={_v.id}>{_v.brewerTypeName}</option>
                                                        )
                                                    })
                                                }
                                              
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* // business Details ---> scroll to div on form submit error*/}
                        <div id="zoomBusinessDetails">
                        </div>

                        <div className="regiterComponent colorshade">
                            <div  className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-headings">
                                            <h2>Business Details</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Business Name <span className="red-tick">*</span></label>
                                            <input type="text" className={bnameError ? "form-control error" : "form-control"} value={bName} placeholder="Enter Business Name" onChange={handleBName} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Employer Identification Number <span className="red-tick">*</span></label>
                                            <InputMask
                                                /*mask='999-999999999'*/  // old mask
                                                mask='99-9999999'                                             
                                                value={ein}
                                                onChange={handleEIN}
                                                className={einError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="EIN"
                                            >
                                            </InputMask>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">State Tax Number</label>
                                            <InputMask
                                                mask='999-99-9999'  
                                                value={stn}
                                                onChange={handleSTN}
                                                className="form-control"
                                                maskChar={null}
                                                placeholder="If Different From EIN"
                                            >
                                            </InputMask>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Country <span className="red-tick">*</span></label>
                                            <select className={countryError ? "form-control error" : "form-control"} onChange={handleCountry} value={country}>
                                                <option value="">Select Country</option>
                                                <option value="1">USA</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">State <span className="red-tick">*</span></label>
                                            <select className={stateError ? "form-control error" : "form-control"} onChange={handleState} value={state}>
                                                <option value="">Select State</option>
                                                {
                                                    stateList.length && stateList.map((_v, _i) => {
                                                        return (
                                                            <option key={_i} value={_v.id}>{_v.statename}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">City <span className="red-tick">*</span></label>
                                            <input type="text" className={cityError ? "form-control error" : "form-control"} value={city} placeholder="City" onChange={handleCity} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Street Address <span className="red-tick">*</span></label>
                                            <input type="text" className={streetError ? "form-control error" : "form-control"} value={street} placeholder="Enter Street Address" onChange={handleStreet} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">ZIP Code <span className="red-tick">*</span></label>
                                            <InputMask
                                                mask='99999'
                                                value={zip}
                                                onChange={handleZIP}
                                                className={zipError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="ZIP Code"
                                            >
                                            </InputMask>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Mobile Number <span className="red-tick">*</span></label>
                                            <InputMask
                                                mask='(999) 999 9999'
                                                value={mobile}
                                                onChange={handleMobile}
                                                className={mobileError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="Mobile Number"
                                            >
                                            </InputMask>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Alternative Mobile Number (optional)</label>
                                            <InputMask
                                                mask='(999) 999 9999'
                                                value={altMobile}
                                                onChange={handleAltMobile}
                                                className={altMobError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="Alternative Mobile Number"
                                            >
                                            </InputMask>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                        {/* // bank Details ---> scroll to div on form submit error*/}
                        <div id="zoomBankDetails">
                        </div>


                        <div className="regiterComponent">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-headings">
                                            <h2>Bank Account Deposit Details</h2>
                                            <span className="brewer-message">This information is used to pay you for orders placed by retailers.</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Bank Name <span className="red-tick"></span></label>
                                            <input type="text" className={bankNameError ? "form-control error" : "form-control"} value={bankName} placeholder="Bank Name" onChange={handleBankName} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Bank Address <span className="red-tick"></span></label>
                                            <input type="text" className={bankAddressError ? "form-control error" : "form-control"} value={bankAddress} placeholder="Bank Address" onChange={handleBankAddress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Routing Number <span className="red-tick"></span></label>
                                            <InputMask
                                                mask='999999999'
                                                value={routingNumber}
                                                onChange={handleRoutingNumber}
                                                className={routingNumberError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="Routing Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Account Number <span className="red-tick"></span></label>
                                            <InputMask
                                                mask='999999999999999' 
                                                value={accountNumber}
                                                onChange={handleAccountNumber}
                                                className={routingNumberError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="Account Number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Bank Account Type <span className="red-tick"></span></label>
                                            <select className={accountTypeError ? "form-control error" : "form-control"} onChange={handleAccountType} value={accountType}>
                                                <option value="">Choose Account Type</option>
                                                <option value="1">Checking Account</option>
                                                <option value="2">Saving Account</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Name on Account <span className="red-tick"></span></label>
                                            <input type="text" className={accountNameError ? "form-control error" : "form-control"} value={nameAccount} placeholder="Name on Account" onChange={handleNameAccount} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* // bank Details ---> scroll to div on form submit error*/}
                        <div id="zoomMemberShipDetails">
                        </div>

                        <div className="regiterComponent colorshade">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-headings">
                                            <h2>Membership Plan and Payment</h2>
                                            <span className="brewer-message">You won’t be charged until your license is approved and account is activated.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Payment Method <span className="red-tick">*</span></label>
                                            <select className={pMethodError ? "form-control error" : "form-control"} onChange={handlePaymentMethod}>
                                                <option value="0">Select Payment Method</option>
                                                {/* <option value="bank">Bank Account</option> */}
                                                <option value="cc">Credit Card</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Membership Plan <span className="red-tick">*</span></label>
                                            <select className={memberShipError ? "form-control error" : "form-control"} onChange={handleMemberPlan}  >
                                                <option>Select Membership Plan</option>
                                                {
                                                    planList.length && planList.map((_v, _i) => {
                                                        return (
                                                            <option value={_v?.priceID}>{`${_v?.planType} $${_v?.planAmount}`}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    paymentMethod === "bank" ? <><BankCardInfo
                                        handleBinfoName={handleBinfoName}
                                        bInfoName={bInfoName}
                                        bInfoNameError={bInfoNameError}
                                        handleBinfoAddress={handleBinfoAddress}
                                        bInfoAddress={bInfoAddress}
                                        bInfoAddressError={bInfoAddressError}
                                        bInfoRouting={bInfoRouting}
                                        bInfoRoutingError={bInfoRoutingError}
                                        handleBinfoRoutingNo={handleBinfoRoutingNo}
                                        bInfoAccountNo={bInfoAccountNo}
                                        bInfoAccountNoError={bInfoAccountNoError}
                                        handleBinfoAccountNo={handleBinfoAccountNo}
                                        bInfoAccountType={bInfoAccountType}
                                        bInfoAccountTypeError={bInfoAccountTypeError}
                                        handleBinfoAccountType={handleBinfoAccountType}
                                        bInfoNameAccount={bInfoNameAccount}
                                        bInfoNameAccountError={bInfoNameAccountError}
                                        handleBinfoNameAccount={handleBinfoNameAccount}

                                    /></> : (paymentMethod === "cc" ? <><Elements stripe={stripePromise}>
                                        <StripePaymentCard
                                            handleMemberPlan={handleMemberPlan}
                                            memberShipError={memberShipError}
                                            cardNumberError={cardNumberError}
                                            stripeElementChange={stripeElementChange}
                                            cardNumberErrorText={cardNumberErrorText}
                                            cardExpireError={cardExpireError}
                                            stripeExpireChange={stripeExpireChange}
                                            cardExpireErrorText={cardExpireErrorText}
                                            cardCvvError={cardCvvError}
                                            stripeCvvChange={stripeCvvChange}
                                            cardCvvErrorText={cardCvvErrorText}
                                            nameCardError={nameCardError}
                                            handleNameCard={handleNameCard}
                                            handleTokenFun={handleTokenFun}
                                            handleTokenData={handleTokenData}
                                            handleStripeForm={handleStripeForm}
                                        />
                                    </Elements></> : "")
                                }



                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={handleCheck} />
                                            <label className="form-check-label" for="exampleCheck1">I agree with the <Link to="/termsofuse" target="_blank">Terms and Conditions</Link> and the <Link to="/privacy" target="_blank">Privacy Policy</Link>.</label>
                                            {checkError ? <div className="error-message">Please accept our Terms and Conditions and Privacy Policy by checking the box before proceeding.</div> : ""}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input classHumanCheck hidden" id="humanCheck" onClick={handleHumanCheck} />
                                          
                                            {humanError ? <div className="error-message"><p style={{fontSize:"20px"} }> Please Confirm you are Human. </p></div> : ""}
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="regitrationBtns">
                                            <button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit} disabled={btnDisabled} >Submit</button>
                                            <button type="submit" name="subxcmit" className="loginb resetFields" onClick={resetAll}>Reset</button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>




                    </section>



                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Registration;
