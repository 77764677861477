// JavaScript source code
import aboutbgd from "./../../assets/images/aboutbgd.png"


import blogMain from "./../../assets/images/BlogWebsiteImage.png"
import blogPost1 from "./../../assets/images/HappyNewYear.png"


import Header from './Header';
import Footer from './Footer';
import { useEffect } from "react"
export default function Index() {
	document.title = "BevPort - Blog"
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
		if (script) {
			script.remove();
		}
		const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
		if (recaptchaElems.length) {
			recaptchaElems[0].remove();
		}
	}, [])
	return (
		<>
			<div className="site-wrapper">
				<div className="main-wrapper">
					<Header />
					<section className="simplyfying Empowering" id="simplys">
						<div className="simplyfyingDiv">
							<div className="container-fluid">
						
								<div className="row upcontent">
									<div className="col-md-5 simplecontent blogmainBg">
										<div className="simplecontentimg">
											<img className="img-responsive" src={aboutbgd} />
										</div>
									</div>
									<div className="col-md-7 manBgimg">
										<div className="manImg">
											<img className="img-responsive" src={blogMain} />
										</div>
									</div>
								</div>

								<div className="row">
									<div className="moveTopUp blogheadphone">
										<div className="container">
											<div className="row main-contentprt">
												<div className="col-md-4">
													<div style={{top: "10px"}} className="textSimply muddtext">
													
														<h1  className="text-center"><span className="darkblacktext">The BevPort</span>Blog</h1>				
														<img className="img-responsive" src={blogPost1} />
														<br />
														<p style={{fontWeight: "bold", color:"black", fontSize: "20px"}}>Exciting BevPort News for 2024. Get caught up!</p>
														
														<p style={{ fontWeight: "bold", color: "black" }}>With the new year come some exciting news and update information on BevPort&apos;s launch date and details on how to be part of it.</p>

														<p style={{ color: "black" }}>January 2, 2024</p>

													</div>
												</div>
												<div className="col-md-8">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>


					<section className="GenesisBevPort" id="GenesisBevPortStory">
						<div style={{padding:"10px"}} className="Persistence">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1>Bevport.com Celebrates Successful Launch and Introduces Game-Changing Sales Services coming in April of 2024!</h1>

									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<p className="authorintro">

											<b>Introduction:</b> Since its launch on March 1st, 2024, Bevport.com has been making waves in the brewing industry with its innovative platform and commitment to empowering brewers. With an influx of new brewers joining and a series of promising milestones achieved, the platform is rapidly solidifying its position as a go-to resource for brewers looking to expand their reach and streamline their operations.

											A <b>Flourishing Community:</b> The launch of Bevport.com witnessed an enthusiastic response from brewers, with a flurry of activity as new sign-ups flooded in. This surge in interest underscores the platform's relevance and the industry's appetite for solutions that cater to their specific needs. The growing community of brewers on Bevport.com is testament to its effectiveness in connecting businesses and facilitating growth opportunities.

										</p>

									</div>
									<div className="col-md-6">


										<p className="authorintro">

											<b>Continuous Improvement:</b> Bevport.com remains committed to enhancing its offerings and user experience. One of the most exciting developments is the introduction of new features aimed at further empowering brewers. Among these additions, the standout is the introduction of "Sales Services," which promises to revolutionize how brewers approach sales and distribution. Look for more about these new services to be available in April of 2024! 

											<b>  Conclusion: </b> Bevport.com's successful launch on March 1st, 2024, marks a significant milestone in the brewing industry's evolution. With an ever-expanding community of brewers, coupled with groundbreaking services and New services, the platform is poised to redefine how brewers approach sales and distribution. As Bevport.com continues to innovate and grow, it remains a trusted partner for brewers seeking to unlock new opportunities and achieve their business goals.
										</p>

									</div>
								</div>  {/*// end row */}






							</div>
						</div>
					</section>

					<section className="GenesisBevPort" id="GenesisBevPortStory">
						<div style={{ padding: "10px" }} className="Persistence">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1>Exciting News for 2024: Bevport.com Launch Update!</h1>
										
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<p className="authorintro">
											Dear Valued Users and Future Customers,
											As we bid farewell to another remarkable year, the Bevport.com team is thrilled to share
											some important updates with you. Crafting a platform like Bevport.com from scratch
											has proven to be an exciting yet intricate journey, and as we approach the year's end,
											we've come to realize that ensuring the delivery of the best possible product requires a
											bit more time than initially anticipated.
											With this commitment to excellence in mind, we've decided to adjust our launch date to
											February 1st, 2024. We believe that taking the extra time to fine-tune and thoroughly test
											our platform is crucial. After all, we understand the significance of making a lasting first
											impression, and we are dedicated to getting it right.
											Starting in January, we will be embarking on live testing with real Brewers, a significant
											milestone on our journey. We are confident in hitting our target date and are excited to
											invite anyone interested in being part of our Beta group. During the testing phase, we
											won't be charging for services, and early adopters will enjoy exclusive savings as they
											come on board with Bevport.com.
											</p>
									
									</div>
									<div className="col-md-6">


										<p className="authorintro">
										
											We also extend an open invitation to brewers to explore our pages and share their
											insights on what they would like to see and which features are essential for them.
											Recognizing that Bevport.com will evolve as more users join, we eagerly welcome any
											suggestions for improvement.
											As we approach the holiday season, we want to take a moment to wish everyone a
											wonderful and peaceful time with their loved ones. Looking ahead to 2024, we are
											excited about the prospect of revolutionizing the beverage distribution industry.
											Bevport.com is not just a platform; it's a game-changer, and we can't wait to embark on
											this transformative journey with you.
											Thank you for your continued support, and here's to a fantastic 2024!
											Best Regards,
											The Bevport.com Team
											Join the Revolution!</p>
										
									</div>
								</div>  {/*// end row */}






							</div>
						</div>
					</section>

				
					<Footer />
				</div>
			</div>
		</>
	)
}
