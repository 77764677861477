import React, { useEffect, useState, useCallback } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone'
import { useNavigate } from "react-router-dom";
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './../blob';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_BRAND_CONTAINER } from '../../../config/constant';
import Loader from "../../front/Loader"
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function CollatralReviews({ brandID, brandData, handleActiveTab, brandMediaData }) {

	let navigate = useNavigate();
	const [collatral, setCollatral] = useState([])
	const [review, setReview] = useState([])
	const [collatralPre, setCollatralPre] = useState([])
	const [reviewPre, setReviewPre] = useState([])
	const [submitLoader, setSubmitLoader] = useState(false);
	useEffect(() => {
		getUserProfileFunction(localStorage.getItem("id"))
	},[])
	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				if (data?.brandID) {
					localStorage.setItem("brandID", data?.brandID)
				}
			}
		}).catch(function (error) {
		})
	}
	const thumbsContainer = {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16
	};

	const thumbInner = {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden'
	};
	const img = {
		display: 'block',
		width: 'auto',
		height: '100%'
	};
	useEffect(() => {
		let brandCollatralTemp = []
		let brandReviewTemp = []
		if (brandMediaData.length) {
			brandMediaData.map((_v, _i) => {
				if (_v?.filetype == "collatral") {
					brandCollatralTemp.push({ "preview": _v.filepath, fileID: _v?.id })
				}
				if (_v?.filetype == "review") {
					brandReviewTemp.push({ "preview": _v.filepath, fileID: _v?.id })
				}
			})
			setCollatralPre(brandCollatralTemp)
			setReviewPre(brandReviewTemp)
		} else {
			setCollatral([])
			setReview([])
			setCollatralPre([])
			setReviewPre([])
		}
	}, [brandMediaData])

	const onDropFunction = (acceptedFiles, fileType) => {
		if (fileType === "collatral") {
			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Collatral_${file.name}`, { type: file.type })
			))
			let imgData = [...collatral, renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			}))]
			let tmpImgData = [].concat(...imgData)
			setCollatral(tmpImgData);
		}
		if (fileType === "review") {
			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Review_${file.name}`, { type: file.type })
			))
			let imgData = [...review, renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			}))]
			let tmpImgData = [].concat(...imgData)
			setReview(tmpImgData);
		}
	}
	const removeFiles = (index, type) => {
		if (type === "collatral") {
			const newFiles = [...collatral];
			newFiles.splice(index, 1);
			setCollatral(newFiles)
		}
		if (type === "review") {
			const newFiles = [...review];
			newFiles.splice(index, 1);
			setReview(newFiles)
		}
	}
	const removeFilesPre = (index, type, fileID, fileName) => {
		if (typeof fileID !== "undefined") {
			deleteFiles(fileID, fileName)
		}
		if (type === "collatral") {
			const newFiles = [...collatralPre];
			newFiles.splice(index, 1);
			setCollatralPre(newFiles)
		}
		if (type === "review") {
			const newFiles = [...reviewPre];
			newFiles.splice(index, 1);
			setReviewPre(newFiles)
		}
	}
	const deleteFiles = async (fileID, fileName) => {
		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
			await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)
		}).catch(function (error) {
			console.log(error)
		})
	}
	const collatralThumb = collatral.map((file, i) => (
		<div className="mainThumbnail" key={file.name}>
			<span className="deleteThis" onClick={() => removeFiles(i, "collatral")}><i className="fa fa-times"></i></span>
			<div style={thumbInner}>
				{
					(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif") ? <img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/> : <>
						<i className="fa fa-file-text-o" style={{ fontSize: "100px" }}></i>
					</>
				}

			</div>
		</div>
	));
	const collatralThumbPre = collatralPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFilesPre(i, "collatral", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					{
						(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif") ? <img
							src={file.preview}
							style={img}
							onLoad={() => { URL.revokeObjectURL(file.preview) }}
						/> : <>
							<i className="fa fa-file-text-o" style={{ fontSize: "100px" }}></i>
						</>
					}

				</div>
			</div>
		)
	});
	const reviewThumb = review.map((file, i) => (
		<div className="mainThumbnail" key={file.name}>
			<span className="deleteThis" onClick={() => removeFiles(i, "review")}><i className="fa fa-times"></i></span>
			<div style={thumbInner}>
				{
					(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif") ? <img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/> : <>
						<i className="fa fa-file-text-o" style={{ fontSize: "100px" }}></i>
					</>
				}

			</div>
		</div>
	));
	const reviewThumbPre = reviewPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFilesPre(i, "review", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					{
						(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif") ? <img
							src={file.preview}
							style={img}
							onLoad={() => { URL.revokeObjectURL(file.preview) }}
						/> : <>
							<i className="fa fa-file-text-o" style={{ fontSize: "100px" }}></i>
						</>
					}

				</div>
			</div>
		)
	});
	const handleActivityState = async () => {
		let formData = {
			UserID: localStorage.getItem("id"),
			ActivityState: "50"
		}
		await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				setSubmitLoader(false);
				localStorage.setItem("activityState", 50);
				navigate("/brewer/add-product/" + localStorage.getItem("brandID"));
			} else {
				toast.error(res.data.responseMessage);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
		})
	}
	const saveCollatral = async () => {
		let da = collatral.concat(review);
		let finalArray = [];
		da.forEach((_v, _i) => {
			let d = {
				"UserID": localStorage.getItem("id"),
				"BRANDID": (brandData) ? brandData?.id : brandID,
				"PRODUCTID": "0",
				"FILETYPE": _v?.imageType,
				"FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
				"FILEDATA": "",
				"FILENAME": _v?.name
			}
			finalArray.push(d)
		})
		if (da.length) {
			setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					setCollatral([])
					setReview([])
					if (localStorage.getItem("activityState") == 25) {
						toast.success(res.data.responseMessage);
						handleActivityState();
					} else {
						toast.success(res.data.responseMessage)
						setSubmitLoader(false)
						navigate("/brewer/brand-list")
					}


				}).catch(function (error) {
					console.log(error)
					setSubmitLoader(false)
				})
			})
		} else {

			toast.success("Brand submitted successfully")
			//toast.success("Brand Activated Successfully")
			if (localStorage.getItem("activityState") == 25) {
				handleActivityState();
			} else {
				navigate("/brewer/brand-list")
			}

		}
	}
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="LogosUplaods">
				<div className="row">
					<div className="col-md-6">
						<h3 className="card-heading">Collateral</h3>
						<p className="cardSubtext">Use this space as an opportunity to place downloadable fact sheets, POS or pdf versions of presentations related to your brand. Collateral can be viewed in the store and also available for buyers to download. You can include product specific collateral on the Products page. Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF ).</p>
					</div>
					<div className="col-md-4">
						<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "collatral")} multiple={true} accept={'image/*'}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps({ className: 'dropzone custom-input-drop-brewer' })}>
									<input {...getInputProps()} />
									<p></p>

									<span className="addDocText_brewer">Drop Collateral Files Here or Click To Upload.
										<br />Formats accepted: (JPEG , JPG , PNG , JFIF, PDF ).</span>
								</div>
							)}
						</Dropzone>
					</div>


					<div class="col-md-2">
						<div className="thumbanilOptions">
							<aside style={thumbsContainer}>
								{collatralThumbPre}
								{collatralThumb}
							</aside>
						</div>
					</div>
				</div>

				
			</div>
			<div className="LogosUplaods BrandPhotos">
				<div className="row">
					<div className="col-md-6">
						<h3 className="card-heading">Reviews and Mentions</h3>
						<p className="cardSubtext">Reviews and Mentions for your brand can be viewed in the store and available to buyers for download. Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF ).</p>
					</div>
					<div className="col-md-4">
						<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "review")} multiple={true}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps({ className: 'dropzone custom-input-drop-brewer' })}>
									<input {...getInputProps()} />

								

									<span className="addDocText_brewer">Drop Reviews and Mentions Files Here or Click To Upload.
										<br />Formats accepted: (JPEG , JPG , PNG , JFIF, PDF ).</span>
								</div>
							)}
						</Dropzone>
					</div>


					<div class="col-md-2">
						<div className="thumbanilOptions">
							<aside style={thumbsContainer}>
								{reviewThumbPre}
								{reviewThumb}
							</aside>
						</div>
					</div>
				</div>

				
			</div>
			<div className="row">
				<div className="col-md-12">
					<div className="formBtns threeSubSets">
						<button type="button" className="btn btn-primary save-button" onClick={saveCollatral}>{brandMediaData ? "Save" : "Save"}</button>
					</div>
				</div>
			</div>
		</>
	)
}