import React, { useState, useEffect } from "react";
import Loader from "../../front/Loader"
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: 'transparent',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			border: '1px solid #b6b6af',
			boxShadow: null,
		},
		'&:focus': {
			border: '1px solid transparent',
			boxShadow: null,
		}
	})
};
toast.configure()
export default function BrandInfo({ handleBrandID, handleActiveTab, brandData, brandID, brewerList, handlebrewerID }) {


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [nameError, setNameError] = useState(false);
	const [sBrandName, setSBrandName] = useState("");
	const [website, setWebsite] = useState("");
	const [socialMedia, setSocialMedia] = useState("");
	const [tripAd, setTripAd] = useState("");
	const [yelpLink, setYelpLink] = useState("");
	const [facebook, setFacebook] = useState("");

	const [tikTok, setTikTok] = useState("");
	const [linkedin, setLinkedIn] = useState("");
	const [instagram, setInstagram] = useState("");

	const [keywords, setKeywords] = useState([]);
	const [submitLoader, setSubmitLoader] = useState(false)
	const [dupError, setDupError] = useState(false)
	const [dupErrorText, setDupErrorText] = useState("")
	const [btnDisable, setBtnDisable] = useState(false)
	const [keyWordOption, setKeywordsOption] = useState([]);
	const [keyValue, setKeyValue] = useState(null);

	const [brewerID, setBrewerID] = useState("");
	const [brewerIDError, setBrewerIDError] = useState(false);

	const [brewerOptions, setBrewerOptions] = useState([]);
	const [businessValue, setBusinessValue] = useState("");
	const toastId = 'brandInfo';

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};

	useEffect(() => {

		let newArr = []
		brewerList.map((_v, _i) => {
			newArr.push({ value: _v?.userID, label: _v?.businessName })
		})
		setBrewerOptions(newArr);
	}, [brewerList]);

	useEffect(() => {
		handleKeywordsData();
	}, [])

	useEffect(() => {
		if (brandData) {
			brewerList.map((_v, _i) => {

				if (brandData?.userID === _v?.userID) {
					setBusinessValue({ value: _v?.userID, label: _v?.businessName })
					
				}
			})
			setName(brandData?.brandName)
			setSBrandName(brandData?.story)
			setWebsite(brandData?.website)
			setSocialMedia(brandData?.twitter)
			setTripAd(brandData?.tripAdvisor)
			setYelpLink(brandData?.yelp)
			setFacebook(brandData?.facebook);
			setTikTok(brandData?.tikTok);
			setLinkedIn(brandData?.linkedIn);
			setInstagram(brandData?.instagram); 
			if (brandData?.keywords) {
				let d = (brandData?.keywords).split(", ")
				setKeywords(d)
				let tmpData = [];
				d.forEach((_v, i) => {
					tmpData.push({ value: _v, label: _v });
				})
				setKeyValue(tmpData)
			}
		} else {
			console.log(brandData)
			setName("")
			setBusinessValue("")
			setSBrandName("")
			setWebsite("")
			setSocialMedia("")
			setTripAd("")
			setYelpLink("")
			setFacebook("")
			setKeyValue([])
		}
	}, [brandData,brewerList])

	const handleKeywordsData = async () => {
		//setSubmitLoader(true)
		await axios.post(API_URL + "GetKeyWords?code=" + API_KEY, {}, config).then((res) => {

			let keywordDataOption = [];
			if (res.data.responseCode === "200") {

				let data = res.data.responseData

				data.forEach((da) => {

					keywordDataOption.push({ value: da.name, label: da.name });

				}
				);

				setKeywordsOption(keywordDataOption);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}


	const handleName = (e) => {
		setName(e.target.value);

		setNameError(false);
		setDupError(false)
		setDupErrorText("");
		setBtnDisable(false);
	}

	const handleSBrand = (e) => {
		setSBrandName(e.target.value);

	}

	const handleWebsite = (e) => {
		setWebsite(e.target.value);

	}

	const handleSocialMedia = (e) => {
		setSocialMedia(e.target.value)

	}

	const handleTripAdvisor = (e) => {
		setTripAd(e.target.value)

	}

	const handleYelpLink = (e) => {
		setYelpLink(e.target.value);

	}

	const handleFacebook = (e) => {
		setFacebook(e.target.value);

	}

	const handleTikTok = (e) => {
		setTikTok(e.target.value);
	}

	const handleLinkedin = (e) => {
		setLinkedIn(e.target.value);
	}

	const handleInstagram = (e) => {
		setInstagram(e.target.value);
	}

	const handleKeywords = (e) => {

		let data = [];
		let data1 = [];
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})

		setKeywords(data);
		setKeyValue(data1)

	}


	const handleValidation = () => {
		let formIsValid = true;
		if (!name) {
			formIsValid = false;
			setNameError(true);
		}
		
		if (!businessValue) {
			formIsValid = false;
			setBrewerIDError(true);
		}


		return formIsValid;
	}
	const updatefunction = async (brandID) => {
		let formData = {
			"ID": brandID,
			"UserID": localStorage.getItem('id'),
			"BrandName": name,
			"Website": website,
			"Twitter": socialMedia,
			"Yelp": yelpLink,
			"TripAdvisor": tripAd,
			"Facebook": facebook,
			"Keywords": keywords.join(', '),
			"Story": sBrandName,
			"TikTok": tikTok,
			"LinkedIn": linkedin,
			"Instagram": instagram
		}
		await axios.post(API_URL + "UpdateBrandInformation?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage);
				handleActiveTab("brandMedia")
			} else {
				toast.error(res.data.responseMessage);
			}
			setSubmitLoader(false);
			setBtnDisable(false);

		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
			setBtnDisable(false)
		})
	}
	const insertDataFunc = async () => {
		let formData = {
			"UserID": businessValue?.value,
			"BrandName": name,
			"Website": website,
			"Twitter": socialMedia,
			"Yelp": yelpLink,
			"TripAdvisor": tripAd,
			"Facebook": facebook,
			"Keywords": keywords.join(', '),
			"Story": sBrandName,
			"TikTok": tikTok,
			"LinkedIn": linkedin,
			"Instagram": instagram
		}
		
		await axios.post(API_URL + "AddBrand?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage, {
					autoClose: 3000, //3 seconds
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					toastId
				});
				if (res.data?.responseData.length) {
					let data = res.data?.responseData[0];
					handleBrandID(data?.id)
					handleActiveTab("brandMedia")
				}
			} else {
				toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
			setBtnDisable(false);

		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
			setBtnDisable(false)
		})
	}
	const handleSubmit = async (e) => {

		if (handleValidation()) {
			setSubmitLoader(true)
			setBtnDisable(true)
			if (brandData) {
				updatefunction(brandData?.id)

			} else {
				if (brandID) {
					updatefunction(brandID)
				} else {
					insertDataFunc()
				}
			}
		}
	}


	const handleExitsBrand = async (e) => {
		setDupError(false)
		setDupErrorText("")
		if (!name == "") {
			let formData = {
				"UserID": localStorage.getItem('id'),
				"BrandName": name
			}
			await axios.post(API_URL + "CheckBrandName?code=" + API_KEY, formData, config).then((res) => {

				if (res.data.responseCode === "401") {

					setDupError(true)
					setDupErrorText(res.data.responseMessage)
					setBtnDisable(true);


				} else {
					setDupError(false)
					setDupErrorText("");
					setBtnDisable(false);
				}

			}).catch(function (error) {
				console.log(error)
			})
		}

	}
	

	const handleBrewerSelected = (e) => {
		console.log("gasfdgas", e);
		//setBrewerID(e.value);
		setBusinessValue({ value: e.value, label: e.label })
		setBrewerIDError(false);
		handlebrewerID(e.value);
	}
	return (
		<>

			{submitLoader ? <Loader /> : ""}
			<form>
				<div className="InsertForms">
					<div className="row">
						<div className="col-md-12">
							<h3 className="card-heading topsubheading">Basics and Social Media</h3>
							<p className="cardSubtext">Enter full links/URLs to brand websites and social media pages.</p>
							<div className="form-group">

								{(!brandID) ? <div> <label className="brand-tab"> Business Name <span className="red-tick">*</span></label>
									<Select
										className={(brewerIDError) ? "selectTodesign error" : "selectTodesign"}
										styles={customStyles}
										options={brewerOptions}
										onChange={handleBrewerSelected}
										value={businessValue}
										isDisabled={(brandData) ? true : false}
										closeMenuOnSelect={true} />
								</div> : ""}
							</div>

						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label className="brand-tab">Brand Name <span className="red-tick">*</span></label>
								<input type="text" name="" id="" className={nameError ? "form-control error" : "form-control"} value={name} onChange={handleName} onBlur={(e) => (brandData || brandID) ? e.preventDefault() : handleExitsBrand()} placeholder="Brand Name" />
								{dupError ? <span className="error-message">{dupErrorText}</span> : ""}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h3 className="card-heading">Story</h3>
							<p className="cardSubtext">Tell us about your Brand and your story. This is what will appear on your Brand page for retailers to see. So make it compelling!</p>
							<div className="form-group">

								<textarea id="STORY" className="form-control" rows="5" placeholder="Story" value={sBrandName} onChange={handleSBrand}></textarea>
							</div>

						</div>
					</div>

					{/*<div className="row">*/}
					{/*	<div className="col-md-6">*/}
					{/*		<div className="form-group">*/}
					{/*			<label className="brand-tab">Website</label>*/}
					{/*			<input type="text" name="" id="" className="form-control" placeholder="https://www.xyz.com" value={website} onChange={handleWebsite} />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="col-md-6">*/}
					{/*		<div className="form-group">*/}
					{/*			<label className="brand-tab">Social media links (Twitter Link) </label>*/}
					{/*			<input type="text" name="" id="" className="form-control" placeholder="Twitter Profile Link" value={socialMedia} onChange={handleSocialMedia} />*/}
					{/*		</div>*/}
					{/*		<div className="form-group">*/}
					{/*			<label className="brand-tab">TripAdvisor Link</label>*/}
					{/*			<input type="text" name="" id="" className="form-control" placeholder="Trip Advisor Profile Link" value={tripAd} onChange={handleTripAdvisor} />*/}
					{/*		</div>*/}
					{/*		<div className="form-group">*/}
					{/*			<label className="brand-tab">Yelp Link</label>*/}
					{/*			<input type="text" name="" id="" className="form-control" placeholder="Yelp Profile Link" value={yelpLink} onChange={handleYelpLink} />*/}
					{/*		</div>*/}
					{/*		<div className="form-group">*/}
					{/*			<label className="brand-tab">Facebook Link</label>*/}
					{/*			<input type="text" name="" id="" className="form-control" placeholder="Facebook Profile Link" value={facebook} onChange={handleFacebook} />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}



					<div class="row">
						<div className="col-md-12">
							{/*	<label className="brand-tab">Social media links</label>*/}

							<h3 className="card-heading">Social Media Links</h3>
						</div>
					</div>

					<div class="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Website</label>
								<input type="text" name="" id="" className="form-control" placeholder="https://www.xyz.com" value={website} onChange={handleWebsite} />
							</div>
						</div>

						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab"> Twitter Link </label>
								<input type="text" name="" id="" className="form-control" placeholder="Twitter Profile Link" value={socialMedia} onChange={handleSocialMedia} />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Yelp Link</label>
								<input type="text" name="" id="" className="form-control" placeholder="Yelp Profile Link" value={yelpLink} onChange={handleYelpLink} />
							</div>
						</div>
						<div className="col-md-6">

							{/*	// --- was commented out ---> Sunday, November 19, 2023 but nulls still save  - jesse dudley -*/}
							{/*<div className="form-group">*/}
							{/*	<label className="brand-tab">TripAdvisor Link</label>*/}
							{/*	<input type="text" name="" id="" className="form-control" placeholder="Trip Advisor Profile Link" value={tripAd} onChange={handleTripAdvisor} />*/}
							{/*</div>*/}

							<div className="form-group">
								<label className="brand-tab">Facebook Link</label>
								<input type="text" name="" id="" className="form-control" placeholder="Facebook Profile Link" value={facebook} onChange={handleFacebook} />
							</div>

						</div>
					</div>

					<div class="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Tik Tok</label>
								<input type="text" name="" id="" className="form-control" placeholder="Tik Tok Profile Link" value={tikTok} onChange={handleTikTok} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Linkedin</label>
								<input type="text" name="" id="" className="form-control" placeholder="Linkedin Profile Link" value={linkedin} onChange={handleLinkedin} />
							</div>
						</div>
					</div>

					<div class="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Instagram</label>
								<input type="text" name="" id="" className="form-control" placeholder="Instagram Profile Link" value={instagram} onChange={handleInstagram} />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<h3 className="card-heading">Keywords</h3>
							<p className="cardSubtext">Brands can be searched for by certain characteristics. Type in your own words or select some of the predefined keywords below that describe this brand.</p>
							<div className="form-group">
								{/* <textarea id="STORY" placeholder="Click here to select keyword" className="form-control" rows="5" name="STORY" value={keywords} onChange={handleKeywords} ></textarea> */}
								<CreatableSelect
									isMulti
									options={keyWordOption}
									onChange={handleKeywords}
									menuPlacement="top"
									className="selectTodesign"
									styles={customStyles}
									value={keyValue}
									closeMenuOnSelect={false}
									placeholder={<div className="italic-placeholder">Select...</div>}

									isSearchable
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="formBtns">
								<button type="button" className="btn btn-primary save-button" onClick={handleSubmit} disabled={btnDisable} >{brandData ? "Save" : "Save & Next"}</button>
							</div>
						</div>
					</div>

				</div>
			</form>
		</>
	)
}