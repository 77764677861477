
import React, { useEffect, useRef, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useParams, Link, useNavigate} from "react-router-dom"
import homecnavs from "./../../assets/images/homecnavs.png"
import brewermaie from "./../../assets/images/brewermaie.png"
import memberhsippageright from "./../../assets/images/memberhsip-pageright.jpg"
import interinfor from "./../../assets/images/interinfor.jpg"
import legaldocs from "./../../assets/images/legal-docs.jpg"
import showcasebeer from "./../../assets/images/showcasebeer.jpg"
import setpricing from "./../../assets/images/setpricing.jpg"
import readytosell from "./../../assets/images/readytosell.jpg"
import buidligncirkce from "./../../assets/images/buidlign-cirkce.jpg"
import streamvan from "./../../assets/images/stream-van.jpg"
import chartsicon from "./../../assets/images/charts-icon.jpg"
import beermug from "./../../assets/images/beer-mug.jpg"
import displaydoallr from "./../../assets/images/display-doallr.jpg"

import GroundLevelSales1 from "./../../assets/images/SalesServices/GroundLevelSales1.png"
import GroundLevelSales2 from "./../../assets/images/SalesServices/GroundLevelSales2.jpg"

import RetailServices1 from "./../../assets/images/SalesServices/RetailServices1.jpg"
import regimd from "./../../assets/images/regimd.jpg"
import Header from './Header';
import Footer from './Footer';
import BasicInfo from './wizard/BasicInfo'
import BusinessInfo from './wizard/BusinessInfo'
import BankInfo from './wizard/BankInfo'
import Payment from './wizard/Payment'
import toast, { Toaster } from 'react-hot-toast';
export default function Brewers() {
	document.title = "BevPort - Brewers"
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
	    if (script) {
	      	script.remove();
	    }
	    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
	    if (recaptchaElems.length) {
	      	recaptchaElems[0].remove();
	    }
	},[])
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const navigate = useNavigate();
	const { hash } = useLocation();
	const { tabID, userID } = useParams();
	const homeRef = useRef()
	const joinRef = useRef()
	useEffect(() => {
		if (hash) {
			executeScroll(hash.slice(1))
		}
	}, [hash])
	const executeScroll = (type) => {
		console.log(type)
		if (type === "memberships") {
			homeRef.current.scrollIntoView()
		}
		if (type === "joinew") {
			joinRef.current.scrollIntoView()
		}
	}
	const [page, setPage] = useState(1)
	const [lastUserID, setLastUserID] = useState("")
	useEffect(() => {
		if (tabID) {
			setPage(parseInt(tabID))
		}
	}, [tabID])
	useEffect(() => {
		if (userID) {
			setLastUserID(userID)
		}
	}, [userID])
	const handlePage = (page, lastUserID) => {
		setPage(page)
		setLastUserID(lastUserID)
	}
	const handleJoin = () =>{
		navigate("/brewer-registration");
	}

	// CostComparisonLink
	const CostComparisonLink = () => {

		var profileLink = "";
		profileLink = "http://" + window.location.host + "/CostComparison";

		window.location.href = profileLink;
	}
	//--------------------------------


	// Sales Services learn more
	const SalesServicesBtnClick = () => {

		var profileLink = "";
		profileLink = "http://" + window.location.host + "/SalesServicesLearnMore";

		window.location.href = profileLink;
	};



	 const [expanded, setExpanded] = React.useState('panel1');
	  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

	return (
		<>
			<Toaster />
			<div className="site-wrapper">
				<div className="main-wrapper">
					<Header />
					<section className="simplyfying Empowering" id="simplys">
						<div className="simplyfyingDiv">
							<div className="container-fluid">
								<div className="row upcontent">
									<div className="col-md-5 simplecontent">
										<div className="simplecontentimg">
											<img className="img-responsive" src={homecnavs} />
										</div>
									</div>
									<div className="col-md-7 manBgimg">
										<div className="manImg">
											<img className="img-responsive" src={brewermaie} />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="moveTopUp">
										<div className="container">
											<div className="row main-contentprt">
												<div className="col-md-4">
													<div className="textSimply">
														<h1><span className="browntext">Empowering Brewers to</span>Expand Distribution Effortlessly, starting in Florida</h1>
														<div class="submitbtns" >
															<button style={{ background: "#714e42" }} className="btn btn-default" type="submit" id="" onClick={handleJoin}  >JOIN THE REVOLUTION!</button>
														</div>    
													</div>
												</div>
												<div className="col-md-8">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>

					<section className="potential" id="potentials">
						<div className="unlockThePotential">
							<div className="container">
								<div className="row">
									<div className="col-md-12">

										<p>Unlock the potential of the internet to reach new markets and overcome traditional barriers to entry with BevPort. Starting in Florida, our innovative platform revolutionizes the way brewers connect with distributors, providing a seamless and efficient solution for expanding your reach. Say goodbye to the cumbersome hurdles and embrace a new era of growth for your brewery.</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section ref={homeRef} className="membershipOptions" id="memberships">
						<div style={{padding:"18px"}} className="OptionsForBrewers">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1>Membership Options for Brewers</h1>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 packagesStory">
										
												<div className="pacakegBlock">													
													<h1><span>Monthly Membership fee</span> <sup>$</sup>29.99<br /><span>or Full year at</span><sup>$</sup>329.89 <span>and get one month free.</span></h1>
													<p>Your membership gets you the following:</p>

													<div className="pacakegBlocklists">
														<ol>
															<li>Brewer page to promote your brand to Retailers across the state.</li>
															<li>Upload images, videos of your Brand.</li>
															<li>Full inventory control</li>
															<li>Set up shipping and track orders to our warehouse</li>
															<li>Automated payment system for product sold to retailers</li>
															<li>Customer lists</li>
															<li>Sales Order history</li>
															<li>Access to sales information</li>
															<li>Discounted pricing to third party vendor sales teams to assist you in selling your brand.</li>
														</ol>
													</div>
										

													<div className="getreadybtn">
														<Link to="/brewer-registration">GET STARTED</Link>
													</div>
												</div>
										
										</div>
									<div className="col-md-6">
										<div className="member-right">
											<img className="img-responsive" src={memberhsippageright} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>



					<section ref={homeRef} className="membershipOptions" id="memberships">


						<div className="row">
							<div className="col-md-12 text-center">
								<p>

									BevPort is excited to offer new Sales Services to our Customers. Once you sign up for Bevport go to your dashboard to get started.

								</p>
							</div>
						</div>

						<div style={{ padding: "10px" }} className="OptionsForBrewers">
							<div className="container">
								
								<div className="row">
									<div className="col-md-6 packagesStory">
										<div className="blogCorners">
											<h1 style={{ fontSize: "21px" }}>Ground Level Sales Services</h1>


											<div className="row">
												<div className="col-md-6">
													<img className="img-responsive" src={GroundLevelSales1} />
												</div>

												<div className="col-md-6">
													<img className="img-responsive" src={GroundLevelSales2} />
												</div>
											</div>

											<div className="row">
												<div style={{marginBottom:"7px"}} className="col-md-12">
													<div>
														<p>BevPort now has additional sales services to help you brand grow locally. With a sales rep. in the market, they can show your brand directly to retailers, offer tastings and take orders directly from independent retailers and restaurants.</p>

													</div>
												</div>
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="blogCorners">
											<h1 style={{ fontSize: "21px" }}>Retail Chain Services</h1>

											<div className="row">
												<div className="col-md-offset-3 col-md-6">
													<img className="img-responsive" src={RetailServices1} />													
												</div>
											</div>

											<div className="row">
                                                <div className="col-md-12">
                                                    <p>BevPort now has a professional team of Retail Chain experts with years of experience to help your brand get in front of  all the main Retail Chain buyers throughout the state of Florida.</p>
                                                </div>
												<div>
												&nbsp;
                                                </div>
                                            </div>

										</div>
									</div>
								</div>


								<div className="row">
									<div className="col-md-12">
										<div className="text-center">
											<button onClick={SalesServicesBtnClick} style={{ color: "#ffffff", backgroundColor: "#c55b11", border:"1px solid black", height:"30px"}}>Click To Learn More</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>


					<section className="Streamlined" id="stremline">
						<div className="StreamlinedOnboarding patern1">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="StreamlinedProcess">
											<h1>Streamlined Onboarding Process</h1>
											<div className="imgtags">
												<img className="imgstrem" src={interinfor} />
											</div>
											<div className="imgcontent">
												<h3>1. Sign up and Enter Your Information:</h3>
												<p>Join the BevPort community by providing us with your brewery details. We’ll guide you through a user-friendly sign-up process, ensuring a hassle-free experience from the start.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="StreamlinedOnboarding patern2">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="StreamlinedProcess">
											<div className="imgtags">
												<img className="imgstrem" src={legaldocs} />
											</div>
											<div className="imgcontent">
												<h3>2. Submit Legal Documents to State:</h3>
												<p>Navigating legal requirements can be time-consuming, but we’ve simplified the process for you. Easily upload your necessary legal documents directly through our secure platform, allowing us to handle the administrative burden on your behalf.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="StreamlinedOnboarding patern1">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="StreamlinedProcess">
											<div className="imgtags">
												<img className="imgstrem" src={showcasebeer} />
											</div>
											<div className="imgcontent">
												<h3>3. Showcase Your Beers:</h3>
												<p>Captivate potential customers with eye-catching images of your brews. Our intuitive interface lets you effortlessly upload images and showcase your beers in their best light. Craft your brand story and leave a lasting impression on buyers.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="StreamlinedOnboarding patern2">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="StreamlinedProcess">
											<div className="imgtags">
												<img className="imgstrem" src={setpricing} />
											</div>
											<div className="imgcontent">
												<h3>4. Set Pricing Information:</h3>
												<p>Take control of your pricing strategy with BevPort. Enter your desired pricing information for each beer, ensuring transparency and consistency across the board. Our platform makes it easy for distributors to evaluate your offerings and make informed purchasing decisions.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="StreamlinedOnboarding patern1">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="StreamlinedProcess">
											<div className="imgtags">
												<img className="imgstrem" src={readytosell} />
											</div>
											<div className="imgcontent">
												<h3>5. And You’re Done! You Are Ready to Sell:</h3>
												<p>Once you’ve completed the onboarding process, you’re all set to start selling your beers through BevPort. Reach a broader audience, tap into new markets, and witness your distribution network expand effortlessly.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</section>
					<section className="WhyChooseB MakeMoney" id="whychosebmakeMoney">
						<div className="WhyChooseBevport MakeMoneys">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h2>So How Does BevPort Make Money?</h2>
										<p>Its rather simple. Bevport takes a 10% margin on all product sold. When you sign up, you will set your pricing using our proprietary Bevport Pricing Calculator, where you can see all your costs, retailer margin, Bevport margin and create you BevPort Listing Price. That’s all there is to it.  We believe in full transparency and share all the numbers with you, so you can set the right price for your brand.</p>
										<p>So instead of the typical distributor taking 30-35% margins and not selling your brand, we would rather let you save that money so you can reinvest it in growing your brand independently.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="WhyChooseB" id="whychoseb">
						<div className="WhyChooseBevport">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="text-center" >
											<button onClick={CostComparisonLink} className="costComparisonBtn" >“See how BevPort’s Pricing Compares to a Traditional Distributor”</button>
										</div>  

										{/*// spacing div*/}
										<div>
										&nbsp;
										</div>

										<h2>Why Choose BevPort?</h2>
										<div className="bevfeartures">
											<div className="featuresimg"><img src={buidligncirkce} /></div>
											<div className="mainFeatures">
												<p><span>ACCESS NEW DISTRIBUTION CHANNELS:</span> Break free from traditional barriers to entry and gain access to a wide network of Buyers actively seeking unique and quality craft beers.</p>
											</div>
										</div>

										<div className="bevfeartures">
											<div className="featuresimg"><img src={streamvan} /></div>
											<div className="mainFeatures">
												<p><span>STREAMLINED OPERATIONS:</span> Simplify the complexities of distribution with our user-friendly platform. Focus on brewing exceptional beer while we handle the logistics, paperwork, and administrative tasks.</p>
											</div>
										</div>

										<div className="bevfeartures">
											<div className="featuresimg"><img src={chartsicon} /></div>
											<div className="mainFeatures">
												<p><span>EXPANDED REACH, REDUCED EFFORT:</span> Expand your reach without exhausting resources. BevPort opens doors to markets that were once out of reach, enabling you to grow your business efficiently and effectively.</p>
											</div>
										</div>

										<div className="bevfeartures">
											<div className="featuresimg"><img src={beermug} /></div>
											<div className="mainFeatures">
												<p><span>GREATER BRAND EXPOSURE:</span> Showcase your brewery and beers to a diverse community of beer enthusiasts and industry professionals. Elevate your brand presence and make a lasting impression in the ever-evolving craft beer landscape.
												</p>
											</div>
										</div>

										<div className="bevfeartures">
											<div className="featuresimg"><img src={displaydoallr} /></div>
											<div className="mainFeatures">
												<p><span>DATA-DRIVEN INSIGHTS:</span> Leverage powerful analytics and reporting tools to gain valuable insights into consumer preferences, market trends, and sales performance. Make informed decisions and stay ahead of the competition.
												</p>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="JoinTheNew" id="joinew">
						<div className="jointhe">
							<div className="container" ref={joinRef}>
								<div className="row">
									<div className="col-md-12">
										<h1>Join the New Distribution Revolution!</h1>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="regText">
											<p>Join BevPort today and revolutionize the way you approach distribution. Expand your horizons, connect with distributors, and take your brewery to new heights. Embrace the power of the internet and experience seamless growth with BevPort.
											</p>
											<div className="submitbtns">
												<button className="btn btn-default" type="submit" id="" onClick={handleJoin}  >JOIN THE REVOLUTION!</button>
											</div>
										</div>
										
									</div>
									<div className="col-md-6">
										<div className="regImg">
											<img className="img-responsive" src={regimd} />
										</div>
										<div className="formtab-view" style={{ display: 'none' }}>
											<div className="formactions">
												<span className={page === 1 ? "tabsbtns active" : "tabsbtns"}>Basic Info</span>
												<span className={page === 2 ? "tabsbtns active" : "tabsbtns"}>Business Info</span>
												<span className={page === 3 ? "tabsbtns active" : "tabsbtns"}>Bank Info</span>
												<span className={page === 4 ? "tabsbtns active" : "tabsbtns"}>Payment</span>
											</div>
											<div className="registraionDiv">

												{
													page === 1 ? <BasicInfo handlePage={handlePage} userType="1" /> : ""
												}
												{
													page === 2 ? <BusinessInfo handlePage={handlePage} lastUserID={lastUserID} userType="1" /> : ""
												}
												{
													page === 3 ? <BankInfo handlePage={handlePage} lastUserID={lastUserID} /> : ""
												}
												{
													page === 4 ? <Payment lastUserID={lastUserID} /> : ""
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="faqSections brewerspage">
						<div className="faqDivision">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1>FAQ</h1>
										<div className="faqPanels">
									       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel1a-content"
									          id="panel1a-header"
									        >
									          <Typography className="accordianHead">When will BevPort Go Live?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Our teams are working day and night get BevPort Launched. We are expecting to be live in the Fall of 2023. Once we go live, we will be sure to let everyone know. Stay Tuned!
									          </Typography>
									        </AccordionDetails>
									      </Accordion>
									       <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel2a-content"
									          id="panel2a-header"
									        >
									          <Typography className="accordianHead">How does BevPort help Brewers?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            BevPort is an innovative digital distribution model designed for Brewers. It revolutionizes your business operations, enabling brand growth by granting access to crucial markets and circumventing the control exerted by major distributors over state-level sales. BevPort offers a personalized online platform to manage your product portfolio, ensuring licensing, and providing access to essential tools.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>
									      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel3a-content"
									          id="panel3a-header"
									        >
									          <Typography className="accordianHead">Where do I start?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            To get started, click on "Learn More" and sign up on our Portal. Once registered, complete your business information and licensing details. We will take care of the approval process to get your brand ready for distribution in Florida. Meanwhile, you can upload images, set pricing, and more. Upon label approval, we'll notify you, and you'll be ready to Go Live!
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									     <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel4a-content"
									          id="panel4a-header"
									        >
									          <Typography className="accordianHead">What is the expense to join BevPort?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Joining BevPort as your distributor is notably more cost-effective than traditional distribution. We charge only a 10% margin when selling to retailers, allowing significant savings for brand development. Additionally, BevPort incurs a monthly membership fee for managing your portal page, inventory, orders, customer lists, and automated payment and sales reporting processes.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel5a-content"
									          id="panel5a-header"
									        >
									          <Typography className="accordianHead">What can I sell on BevPort?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
														“BevPort exclusively sells Canned products only. We sell beer, wine, spirits and RTD cocktails, seltzers and non alcoholic. We will also sell beverages in plastic or cardboard”
									          </Typography>
									        </AccordionDetails>
									      </Accordion>
									      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel12a-content"
									          id="panel5a-header"
									        >
									          <Typography className="accordianHead">Why only Cans?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            To maintain cost-efficiency for our Brewers and streamline processes, BevPort currently accepts only canned products.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel6a-content"
									          id="panel6a-header"
									        >
									          <Typography className="accordianHead">Who has access to my BevPort Portal?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Your BevPort Portal is accessible solely to you and any designated team members to whom you grant access.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel7a-content"
									          id="panel7a-header"
									        >
									          <Typography className="accordianHead">How does my product get to BevPort?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            After your brand's approval for distribution within the state, our logistics partner, Country Malt Group (CMG), will handle product pick-up, delivery, and warehousing until ready for sale. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel8a-content"
									          id="panel8a-header"
									        >
									          <Typography className="accordianHead">Does CMG charge storage fees?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Yes, CMG applies a minor monthly per-palette storage fee. Contact your CMG team representative for detailed information. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel9a-content"
									          id="panel9a-header"
									        >
									          <Typography className="accordianHead">Which of my team members should use the Brewer Portal?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Administrative staff and sales personnel within your team should have access to your Brewer Portal. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel10a-content"
									          id="panel10a-header"
									        >
										  <Typography className="accordianHead">How and when are Brewers paid for sales?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Brewers are paid via ACH directly to their bank accounts. Sales are paid each month for the total of the previous months sales on the 15th of the following month. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel11a-content"
									          id="panel11a-header"
									        >
									          <Typography className="accordianHead">When will BevPort add more states to its Distribution network?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									           BevPort’s expansion plan for 2024 will include a number of new states across the country focusing on major markets. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>
									      
									    </div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>
			</div>
		</>
	)
}