import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
/*import Loader from "./../../front/Loader";*/
import Loader from "./../front/Loader";


import "react-responsive-carousel/lib/styles/carousel.min.css";

import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import Swal from 'sweetalert2';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import Select from 'react-select';


import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';

const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
//------------------------------------------


export default function SalesServicePayment() {
    document.title = "BevPort - Sales Service Payment"

    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)

    const [activeTab, setActiveTab] = useState("all")
    const [warehouseList, setWarehouseList] = useState([]);
    const [wareHouseName, setWareHouseName] = useState("")

    //loaders
    const [submitLoader, setSubmitLoader] = useState(true); // this the black back ground with loader when set to true
    const [loader, setLoader] = useState(false);


    //---------------------------------
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        // setLoader(false);

        // check if user has a active sales service if they do then show the cancel service buttons
        showCancelService("Ground Level Sales Team");
        showCancelService("Retail Chain Sales");

    }, [])

    // 1500 
    const SalesService_1500_Confirm = () => {
        LetsGetStarted(1500, "Ground Level Sales Team");
    };

    // 500
    const SalesService_500 = () => {
        LetsGetStarted(500, "Retail Chain Sales");
    };


    // Cancel Service GroundLevelSalesTeam 
    const CancelService_GroundLevelSalesTeam = () => {
        ConfirmCancelService("Ground Level Sales Team");
    };

    // Cancel Service RetailChainSales
    const CancelService_RetailChainSales = () => {
        ConfirmCancelService("Retail Chain Sales");
    };


    // Sales Service Contact Us
    const SalesServicesContact = () => {

        var profileLink = "";
        profileLink = "http://" + window.location.host + "/SalesServicesContact";

        window.location.href = profileLink;
    };


    const LetsGetStarted = (ChargeAmount, ChargeType) => {

        // Check user's local storage --> activityState --> to see what html to show
        var modalHtml = "<div class='text-left'> <input type='checkbox'> Tampa/Central Florida </input>  </br> </br>   <input type='checkbox'> I authorize initial charge of <b>$" + ChargeAmount + "</b> per month for three months.  Charge will automatically renew at the end of three months. </input> </br> </br> <input type='checkbox'> Use Credit card on file </input>  </div>";
        var Title = ChargeType;


        var modalText = "";

        Swal.fire({
            title: Title,
            text: modalText,
            html: modalHtml,
            /*icon: 'question',*/
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Submit Payment',
            denyButtonText: 'NO',
            background: '#fff',
            color: '#000',
            iconColor: "rgb(255, 153, 0)",
            width: "500px",
            padding: '2em',
            reverseButtons: false
        }).then((result) => {


            if (result.isConfirmed) {
                getUserActive_SalesService(ChargeAmount, ChargeType);
            } else if (result.isDenied) {

            }
        })
    }
    // end LetsGetStarted  -----------------------------------------

    const getUserActive_SalesService = async (ChargeAmount, ChargeType) => {

        let formData = {
            "UserID": localStorage.getItem("id"),
            "ChargeType": ChargeType
        }

        await axios.post(API_URL + "SalesServiceActiveUser?code=" + API_KEY, formData, config).then(async (res) => {

            if (res.data.responseCode === "200") {
                ConfirmModalWindow(ChargeAmount, ChargeType);

            } else {
                toast.error(res.data.responseMessage);
            }

            setLoader(false)
        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })
        //-------------------------------------------
    }


    const ConfirmModalWindow = (ChargeAmount, ChargeType) => {

        // Check user's local storage --> activityState --> to see what html to show
        var modalHtml = "";
        var Title = "Confirm Order $" + ChargeAmount;
        var modalText = "Your Card On File Will Charged $" + ChargeAmount + " For this Sales Service.";

        Swal.fire({
            title: Title,
            text: modalText,
            html: modalHtml,
            icon: 'question',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'YES',
            denyButtonText: 'NO',
            background: '#fff',
            color: '#000',
            iconColor: "rgb(255, 153, 0)",
            width: "500px",
            padding: '2em',
            reverseButtons: false
        }).then((result) => {


            if (result.isConfirmed) {
                handleSubmit_Charge(ChargeAmount, ChargeType);

                //localStorage.removeItem("ShowPopUp");
            } else if (result.isDenied) {
                // localStorage.removeItem("ShowPopUp");
            }
        })
    }
    // end ConfirmModalWindow -----------------------------------------



    // handleSubmit_Charge for any amount 
    const handleSubmit_Charge = async (ChargeAmount, ChargeType) => {

        // set loading animation
        setLoader(true);

        // UserID 
        // alert(localStorage.getItem("id"));

        // formData to pass to api
        let formData = {
            "UserID": localStorage.getItem("id"),
            "ChargeAmount": ChargeAmount,
            "ChargeType": ChargeType
        }


        await axios.post(API_URL + "SalesServiceCharge?code=" + API_KEY, formData, config).then(async (res) => {

            if (res.data.responseCode === "200") {

                if (ChargeType === "Ground Level Sales Team") {
                    showCancelService("Ground Level Sales Team");// call this function  ---> to do check to show cancel sales services button
                }

                else {
                    showCancelService("Retail Chain Sales");// call this function  ---> to do check to show cancel sales services button
                }

                toast.success(res.data.responseMessage);
            } else {
                toast.error(res.data.responseMessage);
            }

            setLoader(false)
        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })
        //-------------------------------------------


    }
    //------------------------------------ end handleSubmit_Charge -----------------------------------------------------------------------------



    // ConfirmCancelService modal window
    const ConfirmCancelService = (ChargeType) => {

        // Check user's local storage --> activityState --> to see what html to show
        var modalHtml = "<p> Are You Sure You want To Cancel Your </br> </br> <b>" + ChargeType + " </b> Subscription.</p>";
        var Title = "Cancel Service";
        var modalText = "";

        Swal.fire({
            title: Title,
            text: modalText,
            html: modalHtml,
            icon: 'question',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'YES',
            denyButtonText: 'NO',
            background: '#fff',
            color: '#000',
            iconColor: "rgb(255, 153, 0)",
            width: "500px",
            padding: '2em',
            reverseButtons: false
        }).then((result) => {


            if (result.isConfirmed) {
                handleCancel_SalesService(ChargeType);

            } else if (result.isDenied) {

            }
        })
    }
    // end ConfirmCancelService -----------------------------------------



    // handleCancel_SalesService for any amount
    const handleCancel_SalesService = async (ChargeType) => {

        // set loading animation
        setLoader(true);

        // formData to pass to api
        let formData = {
            "UserID": localStorage.getItem("id"),
            "ChargeType": ChargeType

        }

        await axios.post(API_URL + "SalesServiceCancelService?code=" + API_KEY, formData, config).then(async (res) => {

            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);

                if (ChargeType === "Ground Level Sales Team") {
                    var element = document.getElementById("rowCancelService_GroundLevelSalesTeam");
                    element.classList.add("hidden");

                    // remove class Hidden to lets get started GroundLevelSalesTeam
                    var element = document.getElementById("LetsGetStarted_GroundLevelSalesTeam");
                    element.classList.remove("hidden");
                }
                else if (ChargeType === "Retail Chain Sales") {
                    var element = document.getElementById("rowCancelService_RetailChainSales");
                    element.classList.add("hidden");

                    // remove class Hidden to lets get started RetailChainSales 
                    var element = document.getElementById("LetsGetStarted_RetailChainSales");
                    element.classList.remove("hidden");
                }
                //---------------------------------------------

            } else {
                toast.error(res.data.responseMessage);
            }

            setLoader(false)
        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })
        //-------------------------------------------

    }
    //------------------------------------ end handleCancel_SalesService -----------------------------------------------------------------------------




    const showCancelService = async (ChargeType) => {

        let formData = {
            "UserID": localStorage.getItem("id"),
            "ChargeType": ChargeType
        }

        await axios.post(API_URL + "SalesServiceActiveUser?code=" + API_KEY, formData, config).then(async (res) => {

            if (res.data.responseCode === "200") {

                // if data count is 0 which means no records come back with this user id and charge type
                //then remove hidden and alow the user to charge there card
                if (ChargeType === "Ground Level Sales Team") {

                    // add class Hidden to lets get started since they already have a 500 sales services
                    var element = document.getElementById("LetsGetStarted_GroundLevelSalesTeam");
                    element.classList.remove("hidden");
                }

                else if (ChargeType === "Retail Chain Sales") {

                    // add class Hidden to lets get started since they already have a 500 sales services
                    var element = document.getElementById("LetsGetStarted_RetailChainSales");
                    element.classList.remove("hidden");
                }



            } else {


                // if the there is data related to the user and charge type 
                // then hide the charge  buttons and show the cancel buttons
                if (ChargeType === "Ground Level Sales Team") {
                    // user Has A Active Sales Service - so show cancel button
                    var element = document.getElementById("rowCancelService_GroundLevelSalesTeam");
                    element.classList.remove("hidden");


                    // add class Hidden to lets get started since they already have a 500 sales services
                    var element = document.getElementById("LetsGetStarted_GroundLevelSalesTeam");
                    element.classList.add("hidden");
                }

                else if (ChargeType === "Retail Chain Sales") {
                    // user Has A Active Sales Service - so show cancel button
                    var element = document.getElementById("rowCancelService_RetailChainSales");
                    element.classList.remove("hidden");


                    // add class Hidden to lets get started since they already have a 500 sales services
                    var element = document.getElementById("LetsGetStarted_RetailChainSales");
                    element.classList.add("hidden");
                }


            }

            setLoader(false)
        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })
        //-------------------------------------------
    }
    // --- end show cancel service ------------------------

    return (
        <>


            <div className="mainAdminWrapper brewersDashArea">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>

                    {loader ? <Loader /> : ""}
                    <BrewerHeader collapseSidebar={collapseSidebar} />

                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Sales Services</h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="">
                                <div className='tbaShowcase'>
                                    <div className="Content-body">

                                        <div style={{ fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif", color: "black" }} className="site-wrapper">
                                            <div className="main-wrapper">


                                                <section className="GenesisBevPort" id="GenesisBevPortStory">
                                                    <div style={{ padding: "0px 0" }} className="Persistence">

                                                        <div className="saleServiceBackGround">
                                                            <div className="container">

                                                                <div className="row">
                                                                    <div className="col-md-12 text-center">
                                                                        <h1 className="text-center"><span style={{ color: "black" }}>Sales Services Payment</span></h1>
                                                                    </div>
                                                                </div>



                                                                {/*// ---------------------new row ----------------------------------*/}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        &nbsp;
                                                                    </div>
                                                                </div>




                                                                {/*// ---------------------new row ----------------------------------*/}
                                                                <div className="row">
                                                                    <div id="payment1500" className="col-md-6 text-center payment1500">
                                                                        <div className="corners1">
                                                                            <label style={{ color: "#fdb54f", fontSize: "20px" }}>Ground Level Sales Team</label> <br />

                                                                            <div className="text-left">
                                                                                <label style={{ color: "white" }}>1. Term is 90 days at a time and renews automatically if not cancelled.</label><br />
                                                                                <label style={{ color: "white" }}>2. Reps. See 10-15 accounts per week in their respective markets</label><br />
                                                                                <label style={{ color: "white" }}>3. Monthly sales recaps.</label><br />
                                                                                <label style={{ color: "white" }}>4. Once you sign up, your local rep. will contact you within 24 hours to get started.</label><br />
                                                                            </div>
                                                                            <button id="LetsGetStarted_GroundLevelSalesTeam" className="hidden" style={{ backgroundColor: "black", color: "white" }} onClick={SalesService_1500_Confirm}>Lets Get Started!</button>

                                                                            {/*// spacing*/}
                                                                            <div>
                                                                                &nbsp;
                                                                            </div>

                                                                            <div id="rowCancelService_GroundLevelSalesTeam" className="text-center hidden">

                                                                                <button style={{ backgroundColor: "black", color: "red" }} onClick={CancelService_GroundLevelSalesTeam}>Cancel Service!</button>

                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                    <div id="payment500" className="col-md-6 text-center payment500">
                                                                        <div className="corners1">
                                                                            <label style={{ color: "#fdb54f", fontSize: "20px" }}>Retail Chain Sales</label><br />

                                                                            <div className="text-left">
                                                                                <label style={{ color: "white" }}>1. Team of Chain experts working on your brand inside of the state to get you in front of buyers.</label><br />
                                                                                <label style={{ color: "white" }}>2. Monthly recaps</label><br />
                                                                                <label style={{ color: "white" }}>3. Direct orders from Chains through our sales team.</label><br />
                                                                                <label style={{ color: "white" }}>4. Once you sign up, someone from our Retail Sales team will reach out to you get started.</label><br />

                                                                            </div>
                                                                            <button id="LetsGetStarted_RetailChainSales" className="hidden" style={{ backgroundColor: "black", color: "white" }} onClick={SalesService_500}>Lets Get Started!</button>

                                                                            {/*// spacing*/}
                                                                            <div>
                                                                                &nbsp;
                                                                            </div>

                                                                            <div id="rowCancelService_RetailChainSales" className="text-center hidden">
                                                                                <button style={{ backgroundColor: "black", color: "red" }} onClick={CancelService_RetailChainSales}>Cancel Service!</button>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*// ---------------------new row ----------------------------------*/}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        &nbsp;
                                                                    </div>
                                                                </div>


                                                                {/*// ---------------------new row ----------------------------------*/}
                                                                <div className="row">
                                                                    <div className="col-md-12 text-center">

                                                                        <div className="corners1">
                                                                            <p style={{ fontSize: "20px", fontWeight: "bold", color: "#fdb54f" }}>Still have questions?</p>
                                                                            <p style={{ fontSize: "17px", fontWeight: "bold", color: "white" }}> Feel free to shoot us an email and we will be happy to answer any questions you may have.</p>
                                                                            <button style={{ backgroundColor: "black", color: "white" }} onClick={SalesServicesContact}>Contact Us</button>
                                                                        </div>

                                                                    </div>
                                                                </div>




                                                            </div>


                                                        </div>
                                                    </div>
                                                </section>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <BrewerFooter />
                </div>
            </div>


        </>
    )
}

