import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import DefaultAvtar from './../../assets/images/avatar-1.jpg';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Loader from "./../front/Loader"
const config = {
  headers: { Authorization: `Bearer ${API_TOKEN}` }
};
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function BrewerHeader({ collapseSidebar, profilePic }) {
  const [userData, setUserData] = useState();
  const [userName, setUserName] = useState("")
  const [businessName, setBusinessName] = useState("")
  const [getApiResp, setGetApiResp] = useState(0);
  const [displayPic, setDisplaypic] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  let navigate = useNavigate();
  let id = localStorage.getItem("id");
  let adminID = localStorage.getItem("adminID")
  useEffect(() => {
    if (localStorage.getItem("userType") == "4") {
      getTeamInfo(adminID)
			getUserProfileFunction(adminID);
		} else {
			getUserProfileFunction(id);
			
		}
  }, [])
  let isAuthentic = localStorage.getItem("isAuthentic")
  useEffect(() => {
    setTimeout(() => {
      if (id && isAuthentic === "true") {
        let userType = localStorage.getItem("userType")
        setUserName(localStorage.getItem("firstName"))
        setBusinessName(localStorage.getItem("businessName"))
        if (userType != "1") {
          if (userType != "4") {
            navigate("/login")
          }else{
            // navigate("/login")
          }
          
        }
      } else {
        navigate("/login")
      }
    },1000)
  }, [id, isAuthentic, localStorage.getItem("firstName")])
 
  useEffect(() => {
    if (typeof profilePic !== "undefined") {
      setDisplaypic(profilePic)
    }
  }, [profilePic])


  const getUserProfileFunction = async (userID) => {
    await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
      if (res.data?.responseCode == "200") {
        let data = res.data?.responseData; 
        if(data?.brandID){
          localStorage.setItem("brandID",data?.brandID)
        }
        if(localStorage.getItem("userType") !== "4"){
        setUserData(data);
        }
        if(data?.activityState >= 0 && data?.activityState < 100){
          setIsDisabled(true);
        }
        if (data.filePath != "") {
          setGetApiResp(1);
        }
      }
      // setSubmitLoader(false)
    }).catch(function (error) {
      // setSubmitLoader(false)
    })
  }

  const getTeamInfo = async (userID) => {
		await axios.post(API_URL + "GetMemberInfo?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				console.log(data)
				if(localStorage.getItem("userType") == "4"){
					setUserData(data)
				//setProfilePic(data?.filePath);
				}
				
				if (data.filePath != "") {
					setGetApiResp(1);
				}

			}
			//setSubmitLoader(false)
		}).catch(function (error) {
			//setSubmitLoader(false)
		})
	}
  // const getUserDetailFunction = async (id) => {
  //   await axios.post(API_URL + "GetUserData?code=" + API_KEY, {"ID": id}, config).then((res) => {
  //     if(res.data?.responseCode == "200" && res.data?.responseData.length){
  //       let data = res.data?.responseData[0];
  //       setUserData(data)
  //     }else{
  //       navigate("/login")
  //     }
  //   }).catch(function (error) {
  //     console.log(error)
  //     navigate("/login")
  //   })
  // }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("isAuthentic");
    localStorage.removeItem("userType");
    localStorage.removeItem("firstName");
    localStorage.removeItem("businessName");
    localStorage.removeItem("activityState");
    localStorage.removeItem("brandID");
    localStorage.removeItem("productID");
    navigate("/login")
  }
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      className="OpenBOx"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disableScrollLock={true}
    >
      <div className="test-memnu">
        {/* <Link to={(!isDisabled)?"/brewer/profile":"#"}><MenuItem className="sub-menu-item" disabled={isDisabled}>Profile</MenuItem></Link> */}
        <Link to={"/brewer/profile"}><MenuItem className="sub-menu-item" >Profile</MenuItem></Link>
        <Link to={(!isDisabled)?"/brewer/change-password":"#"}><MenuItem className="sub-menu-item" disabled={isDisabled}>Change Password</MenuItem></Link>
        <MenuItem className="sub-menu-item" onClick={handleLogout}>Logout</MenuItem>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <>

      <Box sx={{ flexGrow: 1 }} className="RightContent">
        <AppBar position="static" className="HeaderTag">
          <Toolbar className="HeaderContent">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => { collapseSidebar(); }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />
            <Box className="profileHeadSide" sx={{ display: { xs: 'none', md: 'flex' } }} onClick={handleProfileMenuOpen}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                className="prfileDivIcon"
              >
                {displayPic ? (<img src={displayPic} alt="Selected" style={{ height: "60px", width: "60px", borderRadius: '50%' }} />) : (getApiResp) ? (
                  <img src={userData.filePath} alt="Selected" style={{ height: "60px", width: "60px", borderRadius: '50%' }} />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
              <div className="Profile-head">
                <span className="OwnerName">{userName}</span>
                <span className="bussinessName">{businessName}</span>
              </div>
            </Box>

          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
}