import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ProSidebarProvider, Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PeopleIcon from '@mui/icons-material/People';
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import TeamMemberHeader from "./TeamMemberHeader";
import "./../../assets/css/admin-style.css"
import Logo from "./../../assets/images/logo.png"
import SmallLogo from "./../../assets/images/small-logo.png"
import OrderListIcon from '@mui/icons-material/ViewList';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Inventory2Icon from '@mui/icons-material/Inventory2';

export default function TeamMemberSideBar({ collapse }) {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    useEffect(() => {
        collapseSidebar(collapse);
    }, [collapse])
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("isAuthentic");
        localStorage.removeItem("userType");
        localStorage.removeItem("firstName");
        localStorage.removeItem("businessName");
        localStorage.removeItem("createdBy");
        navigate("/login")
    }

    return (
        <>
            <Sidebar className="mycustomSidebar">
                <Menu>
                    <MenuItem className="TopMiamLogo" >
                        <span className="logoNotCollapsed"><img src={Logo} /></span>
                        <span className="logoCollapsed"><img src={SmallLogo} /></span>
                    </MenuItem>
                    <MenuItem className="dashboard" icon={<GridViewRoundedIcon />} component={<NavLink to="/team-member/dashboard" className="link" />}> Dashboard </MenuItem>
                    <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/team-member/product-list" className="link" />}>Products List</MenuItem>
                    <MenuItem icon={<OrderListIcon />} component={<NavLink to="/team-member/order-history" className="link" />}> Sales Order </MenuItem>
                    <MenuItem className="dashboard" icon={<Inventory2Icon />} component={<NavLink to="/team-member/inventory" className="link" />}> Inventory </MenuItem>
                    <MenuItem icon={<OrderListIcon />} component={<NavLink to="/team-member/mybuyers" className="link" />}> My Buyers </MenuItem>
                    <MenuItem icon={<LogoutRoundedIcon />} onClick={logout}> Logout </MenuItem>
                </Menu>
            </Sidebar>
        </>
    )
}