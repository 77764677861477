import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Swal from 'sweetalert2';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};


toast.configure()
export default function AdminContactList(){
	document.title = "BevPort - Contact List"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const [data, setData] = useState([])
	useEffect(() => {
		getAdminContactList()
	}, [])
	const getAdminContactList = async (userID) => {
		await axios.post(API_URL + "GetAllContactUsData?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				setData(res.data.responseData)
				console.log("resposnse", res.data.responseData);

			} else {
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	// Delete Contact 
	const deleteContact = async (id) => {
		let formData = {
			"ID": id		
		}
		setLoader(true);
		await axios.post(API_URL + "DeleteContactMessage?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				toast.success(res.data.responseMessage)
				getAdminContactList();// rebind the contact list after deleting to show user it deleted
				setLoader(false);
			} else {
				setLoader(false);
				toast.error(res.data.responseMessage)
			}
		}).catch(function (error) {

		})
	}

	// end delete contact function --------------------------




	const ConfirmDelete = (id) => {

		// Check user's local storage --> activityState --> to see what html to show
		var modalHtml = "";
		var Title = "Confirm Delete";


		var modalText = "";

		Swal.fire({
			title: Title,
			text: modalText,
			html: modalHtml,
			/*icon: 'question',*/
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'Yes',
			denyButtonText: 'NO',
			background: '#fff',
			color: '#000',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result) => {


			if (result.isConfirmed) {
				deleteContact(id);
			} else if (result.isDenied) {

			}
		})
	}
    // end LetsGetStarted  -----------------------------------------


	const columns = [
		{
			name: 'Name',
			selector: row => (row.firstname + " " + row.lastname).toLowerCase(),
			cell: row => (row.firstname)?(row.firstname + " " + row.lastname):"-",
			sortable: true,

		},
		{
			name: 'Created Date',
			selector: row => (row.createddate) ? moment(row.createddate, 'YYYY-MM-DD HH:mm A') : 0,
			cell: row => row.createddate ? moment(row.createddate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true,
		},
		{
			name: 'E-mail',
			selector: row => (row.emailid).toLowerCase(),
			cell: row => (row.emailid)?row.emailid:"-",
			sortable: true,
			width: '250px',

		},

		{
			name: 'Phone No.',
			selector: row => row.phonenumber,
            cell: row => (row.phonenumber)?row.phonenumber:"-",
			sortable: true,

		},
		{
			name: 'Title',
			selector: row => (row.title).toLowerCase(),
			cell: row => (row.title)?row.title:"-",
			sortable: true,

		},
		{
			name: 'Company Name',
			selector: row => (row.companyname).toLowerCase(),
			cell: row => (row.companyname)?row.companyname:"-",
			sortable: true,
			wrap: true

		},
        {
			name: 'Comment',
			selector: row => (row.content).toLowerCase(),
			cell: row => (row.content)?row.content:"-",
			sortable: true,
			wrap: true

		},
		{
			name: 'Delete',
			width: '250px',
			export:false,
			selector: row => <>
				
				<Link className="btn btn-secondary" to="#" onClick={() => ConfirmDelete(row.id)}>{"Delete"}</Link> </>,
		}

	];
	const tableData = {
		columns,
		data
	};
	return(
		<>
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Contact List</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={"No record found!"}
											

												//defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}