import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_BRAND_CONTAINER } from './../../../config/constant.js';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './../blob';
import Select from 'react-select';
import Loader from "./../../front/Loader.js";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Dropzone, { useDropzone } from 'react-dropzone'
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';


// Added for new Special Beer Type dropdown ----
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()

export default function BeerInfo({ bID, handlePackSizeFun, handleCaseSizeFun, handleActiveTab, handleProductID, handleBrandID, productData, productID, getProductDetail , productImagesInfo }) {
	const [BrandNameList, setBrandNameList] = useState([]);
	const [beerTypeList, setBeerTypeList] = useState([]);
	const [canSizeList, setCanSizeList] = useState([]);
	const [packSizeList, setPackSizeList] = useState([]);
	const [caseSizeList, setCaseSizeList] = useState([]);
	const [stateList, setStateList] = useState([])
	const [bName, setbName] = useState();
	const [bNameError, setbNameError] = useState(false)
	const [bNameValue, setbNameValue] = useState(null);
	const [beerType, setBeerType] = useState([])
	const [beerTypeError, setBeerTypeError] = useState(false)
	const [beerTypeValue, setBeerTypeValue] = useState(null);
	const [canSize, setCanSize] = useState([]);
	const [canSizeError, setCanSizeError] = useState(false);
	const [canSizeValue, setCanSizeValue] = useState(null);
	const [packSize, setPackSize] = useState([]);
	const [packSizeError, setPackSizeError] = useState(false);
	const [packSizeValue, setPackSizeValue] = useState(null);
	const [caseSize, setCaseSize] = useState([]);
	const [caseSizeError, setCaseSizeError] = useState(false);
	const [caseSizeValue, setCaseSizeValue] = useState(null);
	const [country, setCountry] = useState("1");
	const [countryError, setCountryError] = useState(false);
	const [countryValue, setCountryValue] = useState({ label: "USA", value: "1" });
	const [state, setState] = useState("FLORIDA");
	const [stateError, setStateError] = useState(false);
	const [stateValue, setStateValue] = useState({ value: 'FLORIDA', label: 'FLORIDA', id: 15 });
	const [productName, setProductName] = useState("");
	const [productError, setProductError] = useState(false);
	const [breweryName, setBreweryName] = useState("");
	const [breweryNameError, setBreweryNameError] = useState(false);
	const [alchol, setAlchol] = useState("");
	const [alcholError, setAlcholError] = useState(false);
	const [calories, setCalories] = useState("");
	const [calorieError, setCalorieError] = useState(false);
	const [sugar, setSugar] = useState("");
	const [sugarError, setSugarError] = useState(false);
	const [carbs, setCarbs] = useState("");
	const [carbsError, setCarbsError] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false)
	const [btnDisable, setBtnDisable] = useState(true)
	const [brandID, setBrandID] = useState("")
	const [canID, setCanID] = useState("");
	const [packID, setPackID] = useState("");
	const [caseID, setCaseID] = useState("");
	const [stateID, setStateID] = useState(15);
	const [beerID, setbeerID] = useState("");
	const [dupError, setDupError] = useState(false);
	const [dupErrorText, setDupErrorText] = useState("");
	const [productUpcNumber, setProductUpcNumber] = useState();
	const [productUpcNumberError, setProductUpcNumberError] = useState(false);
	const [caseUpcNumber, setCaseUpcNumber] = useState();
	const [caseUpcNumberError, setCaseUpcNumberError] = useState(false);
	const [productUpcImg, setProductUpcImg] = useState([]);
	const [productUpcImgError, setProductUpcImgError] = useState(false);
	const [caseUpcImg, setCaseUpcImg] = useState([]);
	const [caseUpcImgError, setCaseUpcImgError] = useState(false);
	const [productUpcImgPre, setProductUpcImgPre] = useState([]);
	const [caseUpcImgPre, setCaseUpcImgPre] = useState([]);
	const [palet, setPalet] = useState("");
	const [paletError, setPaletError] = useState("");

	const [productLabelImgFront, setProductLabelImgFront] = useState([]);
	const [productLabelImgFrontError, setProductLabelImgFrontError] = useState(false);
	const [productLabelImgFrontPre, setProductLabelImgFrontPre] = useState([]);

	const [productLabelImgBack, setProductLabelImgBack] = useState([]);
	const [productLabelImgBackError, setProductLabelImgBackError] = useState(false);
	const [productLabelImgBackPre, setProductLabelImgBackPre] = useState([]);


	const thumbsContainer = {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16
	};

	const thumbInner = {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden'
	};

	const img = {
		display: 'block',
		width: '100px',
		height: '100%',
		objectFit: "contain"
	};


	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};
	useEffect(() => {
		if (!bID) {
			if (productData) {

				// set company name as business name always just incase user changes business name they can update there compnay name --> when they update a product
				setBreweryName(localStorage.getItem("businessName"));


				BrandNameList.forEach((_v, _i) => {
					if (_v.brandID == productData?.brandID) {
						setBrandID(_v.brandID);
						setbNameValue(_v)
					}
				})
				beerTypeList.forEach((_v, _i) => {
					if (_v.id == productData?.beerType) {
						setbeerID(_v.id)
						setBeerType(_v.value);
						setBeerTypeValue(_v)
					}
				})
				canSizeList.forEach((_v, _i) => {
					if (_v.id == productData?.canSize) {
						setCanID(_v?.id)
						setCanSize(_v.value);
						setCanSizeValue(_v)
					}
				})
				packSizeList.forEach((_v, _i) => {
					if (_v.id == productData?.packageSize) {
						setPackID(_v?.id)
						setPackSize(_v.value);
						setPackSizeValue(_v)
					}
				})
				caseSizeList.forEach((_v, _i) => {
					if (_v.id == productData?.caseSize) {
						setCaseID(_v?.id)
						setCaseSize(_v.value);
						setCaseSizeValue(_v)
					}
				})
				stateList.forEach((_v, _i) => {
					if (_v.id == productData?.state) {
						setStateID(_v?.id)
						setState(_v.value);
						setStateValue(_v)
					}
				})
				setProductName(productData?.productName)
				setAlchol(productData?.alcoholPercentage)
				if(productData?.calories == 0){
					setCalories("")
				}else{
					setCalories(productData?.calories)
				}
				if(productData?.sugar == 0){
					setSugar("");
				}else{
					setSugar(productData?.sugar)
				}
				if(productData?.carbs == 0){
					setCarbs("");
				}else{
					setCarbs(productData?.carbs)
				}
				setPalet(productData?.palette)
				
				setCountryValue(countryOption)
				setCountry("1");
				setProductUpcNumber(productData?.productUPC)
				setCaseUpcNumber(productData?.caseUPC)

				if (productImagesInfo.length) {
					let productupcImgTemp = []
					let caseupcImgTemp = []
					let labelFrontTemp = []
					let labelBackTemp = []
					productImagesInfo?.map((_v, _i) => {
						if (_v?.filetype == "ProductUpc") {
							productupcImgTemp.push({ "preview": _v.filepath, fileID: _v?.id })
						}
						if (_v?.filetype == "CaseUpc") {
							caseupcImgTemp.push({ "preview": _v.filepath, fileID: _v?.id })
						}
						if (_v?.filetype == "LabelFront") {
							labelFrontTemp.push({ "preview": _v.filepath, fileID: _v?.id })
						}
						if (_v?.filetype == "LabelBack") {
							labelBackTemp.push({ "preview": _v.filepath, fileID: _v?.id })
						}
					})
					setProductUpcImgPre(productupcImgTemp);
					setCaseUpcImgPre(caseupcImgTemp);
					setProductLabelImgFrontPre(labelFrontTemp);
					setProductLabelImgBackPre(labelBackTemp);
				}
			} else {
				setBreweryName(localStorage.getItem("businessName"));
				setProductName("")
				setAlchol("")
				setCalories("")
				setSugar("")
				setCarbs("")
				setBrandID("");
				setbNameValue();
				setbeerID("")
				setBeerType("");
				setBeerTypeValue();
				setCanID("")
				setCanSize("");
				setCanSizeValue()
				setPackID("")
				setPackSize("");
				setPackSizeValue()
				setCaseID("")
				setCaseSize("");
				setCaseSizeValue();
				setPalet("");
				setCountry("1")
				setCountryValue({ label: "USA", value: "1" })
				setState("FLORIDA")
				setStateValue({ value: 'FLORIDA', label: 'FLORIDA', id: 15 })
				setProductUpcNumber("")
				setCaseUpcNumber("")
				setCaseUpcImgPre([])
				setProductUpcImgPre([])
				setCaseUpcImg([])
				setProductUpcImg([])
				setProductLabelImgFront([])
				setProductLabelImgFrontPre([])
				setProductLabelImgBack([])
				setProductLabelImgBackPre([])
			}
		}

	}, [productData, BrandNameList, beerTypeList, canSizeList, packSizeList, caseSizeList, stateList])

	useEffect(() => {
		getBrandName();
		getTypeofBeer();
		getCanSize();
		getPackSize();
		getCaseSize();
		getStateList();

	}, [])

	const countryOption = [
		{ label: "USA", value: "1" },

	];


	const getBrandName = async () => {
		await axios.post(API_URL + "GetBrandList?code=" + API_KEY, { UserID: localStorage.getItem("id") }, config).then((res) => {
			if (res.data.responseCode == "200") {
				let brandOption = []
				let brID = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					if (da?.status == 1) {
						brandOption.push({ value: da.brandName, label: da.brandName, brandID: da.id });
					}
				}
				);
				setBrandNameList(brandOption);


			} else {

			}
		}).catch(function (error) {

		})

	}
	const getTypeofBeer = async () => {
		await axios.post(API_URL + "BeerTypes?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let brandTypeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					brandTypeOption.push({ value: da.beerType, label: da.beerType, id: da.id });
				}
				);
				setBeerTypeList(brandTypeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}




	const getCanSize = async () => {
		await axios.post(API_URL + "CanSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let canSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					canSizeOption.push({ value: da.value, label: da.value, id: da.id });
				}
				);
				setCanSizeList(canSizeOption);

			} else {

			}
		}).catch(function (error) {

		})
	}


	const getPackSize = async () => {
		await axios.post(API_URL + "PackageSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let packSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					packSizeOption.push({ value: da.value, label: da.value, id: da.id });

				}
				);
				setPackSizeList(packSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}



	const getCaseSize = async () => {
		await axios.post(API_URL + "CaseSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let caseSizeOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					caseSizeOption.push({ value: da.value, label: da.value, id: da.id });

				}
				);
				setCaseSizeList(caseSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}

	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {

					stateOption.push({ value: da.statename, label: da.statename, id: da.id });

				}
				);

				setStateList(stateOption)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	const handleBrandName = (e) => {
		handleBrandID(e.brandID)
		setBrandID(e.brandID);
		setbName(e.value);
		setbNameValue({ value: e.value, label: e.label })
		setDupError(false)
		setDupErrorText("");
		if (!productData) {
			setProductName("");
		}

		setBtnDisable(false)
		setbNameError(false);
	}
	useEffect(() => {
		if (bID) {
			handleBrandID(bID)
			setBrandID(bID);
		}
	}, [bID])
	const handleBeerType = (e) => {
		setbeerID(e.id);
		setBeerType(e.value);
		setBeerTypeValue({ value: e.value, label: e.label })
		setBeerTypeError(false)
	}


	const handleCanSize = (e) => {

		setCanID(e.id);
		setCanSize(e.value);
		setCanSizeValue({ value: e.value, label: e.label })
		setCanSizeError(false)
	}


	const handlePackSize = (e) => {
		if (e.value === "Singles") {
			handlePackSizeFun(1)
		} else {
			let u = (e.value).split(" ")
			handlePackSizeFun(u[0])
		}
		setPackID(e.id)
		setPackSize(e.value);
		setPackSizeValue({ value: e.value, label: e.label })
		setPackSizeError(false);
	}

	const handleCaseSize = (e) => {
		let u = (e.value).split(" ")
		handleCaseSizeFun(u[0])
		setCaseID(e.id)
		setCaseSize(e.value);
		setCaseSizeValue({ value: e.value, label: e.label })
		setCaseSizeError(false);
	}

	const handleCountry = (e) => {
		setCountry(e.value);
		setCountryValue({ value: e.value, label: e.label })
		setCountryError(false);

	}

	const handleState = (e) => {
		setStateID(e.id);
		setState(e.value);
		setStateValue({ value: e.value, label: e.label })
		setStateError(false);
	}

	const handleProductName = (e) => {
		setProductName(e.target.value);
		setProductError(false)
		setDupError(false)
		setDupErrorText("");
		setBtnDisable(false);
	}

	const handleBreweryName = (e) => {
		setBreweryName(e.target.value)
		setBreweryNameError(false);
	}

	const handleAlchol = (e) => {
		setAlchol(e.target.value)
		setAlcholError(false);
	}
	const handleCalories = (e) => {
		setCalories(e.target.value);
		//setCalorieError(false);
	}
	const handleSugar = (e) => {
		setSugar(e.target.value);
		//setSugarError(false);
	}
	const handlePalette = (e) => {
		const re = /^[0-9\b]+$/;
		if(re.test(e.target.value) || e.target.value == ''){
			setPalet(e.target.value);
		}
		
		setPaletError(false);
	}

	const handleCarbs = (e) => {
		setCarbs(e.target.value);
		//setCarbsError(false);
	}
	const handleProductUpcNumber = (e) => {
		setProductUpcNumber(e.target.value);
		setProductUpcNumberError(false);
	}
	const handleCaseUpcNumber = (e) => {
		setCaseUpcNumber(e.target.value);
		setCaseUpcNumberError(false);
	}
	const imageFormats = {
		'image/*': []
	};
	const onDropFunction = (acceptedFiles, fileType) => {
		if (fileType == "ProductUpc") {

			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_ProductUpcImg_${file.name}`, { type: file.type })
			))
			setProductUpcImg(renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			})));
			setProductUpcImgError(false);
		}
		if (fileType == "CaseUpc") {
			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_CaseUpcImg_${file.name}`, { type: file.type })
			))
			setCaseUpcImg(renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			})));
			setCaseUpcImgError(false)
		}
		if (fileType == "LabelFront") {

			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_LabelFront_${file.name}`, { type: file.type })
			))
			setProductLabelImgFront(renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			})));
			setProductLabelImgFrontError(false);
		}
		if (fileType == "LabelBack") {

			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_LabelBack_${file.name}`, { type: file.type })
			))
			setProductLabelImgBack(renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			})));
			setProductLabelImgBackError(false);
		}
	}
	const removeFiles = (index, type, fileID, fileName) => {
		if (typeof fileID !== "undefined") {
			deleteFiles(fileID, fileName)
		}
		if (type == "ProductUpc") {
			const newFiles = [...productUpcImg];
			newFiles.splice(index, 1);
			setProductUpcImg(newFiles);
		}
		if (type == "CaseUpc") {
			const newFiles1 = [...caseUpcImg];
			newFiles1.splice(index, 1);
			setCaseUpcImg(newFiles1);
		}
		if (type == "LabelFront") {
			const newFiles1 = [...productLabelImgFront];
			newFiles1.splice(index, 1);
			setProductLabelImgFront(newFiles1);
		}
		if (type == "LabelBack") {
			const newFiles1 = [...productLabelImgBack];
			newFiles1.splice(index, 1);
			setProductLabelImgBack(newFiles1);
		}
	}
	const removeFilesPre = (index, type, fileID, fileName) => {
		if (typeof fileID !== "undefined") {
			deleteFiles(fileID, fileName)
		}
		if (type === "ProductUpc") {
			const newFiles = [...productUpcImgPre];
			newFiles.splice(index, 1);
			setProductUpcImgPre(newFiles);
		}
		if (type === "CaseUpc") {
			const newFiles1 = [...caseUpcImgPre];
			newFiles1.splice(index, 1);
			setCaseUpcImgPre(newFiles1);
		}
		if (type === "LabelFront") {
			const newFiles1 = [...productLabelImgFrontPre];
			newFiles1.splice(index, 1);
			setProductLabelImgFrontPre(newFiles1);
		}
		if (type === "LabelBack") {
			const newFiles1 = [...productLabelImgBackPre];
			newFiles1.splice(index, 1);
			setProductLabelImgBackPre(newFiles1);
		}
	}
	const deleteFiles = async (fileID, fileName) => {
		setSubmitLoader(true);
		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
			if(res.data.responseCode === "200"){
				await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false);
			console.log(error)
		})
	}
	const productUPCthumb = productUpcImg.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});
	const productUPCthumbPre = productUpcImgPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});
	const caseUPCthumb = caseUpcImg.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});
	const caseUPCthumbPre = caseUpcImgPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	const labelFrontThumb = productLabelImgFront.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	const labelFrontThumbPre = productLabelImgFrontPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	const labelBackThumb = productLabelImgBack.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	const labelBackThumbPre = productLabelImgBackPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	const handleValidation = () => {
		let formIsValid = true;
		if (!bName && !bNameValue) {
			formIsValid = false;
			setbNameError(true);
		}
		if (!beerType.length) {
			formIsValid = false;
			setBeerTypeError(true);
		}

		if (!productName) {
			formIsValid = false;
			setProductError(true);
		}

		if (!breweryName) {
			formIsValid = false;
			setBreweryNameError(true);
		}
		if (!alchol) {
			formIsValid = false;
			setAlcholError(true);
		}
		// if (!calories) {
		// 	formIsValid = false;
		// 	setCalorieError(true);
		// }
		// if (!sugar) {
		// 	formIsValid = false;
		// 	setSugarError(true);
		// }
		if (!palet) {
			formIsValid = false;
			setPaletError(true);
		}
		if (palet == '0') {
			formIsValid = false;
			setPaletError(true);
		}
		// if (!carbs) {
		// 	formIsValid = false;
		// 	setCarbsError(true);
		// }
		if (!canSize.length) {
			formIsValid = false;
			setCanSizeError(true);
		}
		if (!packSize.length) {
			formIsValid = false;
			setPackSizeError(true);
		}

		if (!caseSize.length) {
			formIsValid = false;
			setCaseSizeError(true);
		}

		if (!country.length) {
			formIsValid = false;
			setCountryError(true);
		}

		if (!state.length) {
			formIsValid = false;
			setStateError(true);
		}
		if (!productUpcNumber) {
			formIsValid = false;
			setProductUpcNumberError(true);
		}
		if (!caseUpcNumber) {
			formIsValid = false;
			setCaseUpcNumberError(true);
		}
		if (!productUpcImgPre.length) {
			if (!productUpcImg.length) {
				formIsValid = false;
				setProductUpcImgError(true);
			}
		}
		if (!caseUpcImgPre.length) {
			if (!caseUpcImg.length) {
				formIsValid = false;
				setCaseUpcImgError(true);
			}
		}
		if (!productLabelImgFrontPre.length) {
			if (!productLabelImgFront.length) {
				formIsValid = false;
				setProductLabelImgFrontError(true);
			}
		}
		if (!productLabelImgBackPre.length) {
			if (!productLabelImgBack.length) {
				formIsValid = false;
				setProductLabelImgBackError(true);
			}
		}
		return formIsValid;
	}

	const saveUPCimG = async (prodID) => {
		let da = (productUpcImg.concat(caseUpcImg).concat(productLabelImgFront).concat(productLabelImgBack));
		console.log(da)
		let finalArray = [];
		da.forEach((_v, _i) => {
			let d = {
				"UserID": localStorage.getItem("id"),
				"BRANDID": brandID,
				"PRODUCTID": prodID,
				"FILETYPE": _v?.imageType,
				"FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
				"FILEDATA": "",
				"FILENAME": _v?.name
			}
			finalArray.push(d)
		})

		if (da.length) {
			const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					setProductUpcImg([])
					setCaseUpcImg([])
					setProductUpcImgPre([])
					setCaseUpcImgPre([])
					setProductLabelImgFront([])
					setProductLabelImgFrontPre([])
					setProductLabelImgBack([])
					setProductLabelImgBackPre([])
					getProductDetail(prodID)
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}

	const insertProductInfo = async () => {

		let formData = {
			"BrandID": brandID.toString(),
			"BeerType": beerID.toString(),
			"ProductName": productName,
			"BreweryName": breweryName,
			"AlcoholPercentage": alchol,
			"Calories": (calories == "")?"0":calories,
			"Sugar": (sugar == "")?"0":sugar,
			"Carbs": (carbs == "")?"0":carbs,
			"CanSize": canID.toString(),
			"PackageSize": packID.toString(),
			"CaseSize": caseID.toString(),
			"Country": "1",
			"State": stateID.toString(),
			"Palette": palet,
			"ProductUPC": productUpcNumber,
			"CaseUPC": caseUpcNumber
		}
		await axios.post(API_URL + "AddProduct?code=" + API_KEY, formData, config).then((res) => {

			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage);
				let data = res.data?.responseData[0];
				handleProductID(data?.id)
				handleActiveTab("priceCalculator")
				saveUPCimG(data?.id)
			} else {
				toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
			setBtnDisable(false);

		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
			setBtnDisable(false)
		})
	}

	// Insert new Beer Type   ---> When User Clicks the add button after they have typed in the name of the new Beer Type
	const insertNewBeerType = async (beerTypeName) => {

		let formData = {
			"ID": 0,
			"BeerType": beerTypeName,
			"Status": 1,
			"CREATEDDATE": "1900-01-01 00:00:00.000",
			"UPDATEDDATE": "1900-01-01 00:00:00.000",
			"IsNameExists": ""

		}

		await axios.post(API_URL + "AddBeerTypeData?code=" + API_KEY, formData, config).then((res) => {

			if (res.data.responseCode === "200") {

				toast.success(res.data.responseMessage);

				// last created beer Type ID
				var LastCreatedBeerTypeID = res.data.responseData.id;

				//// push the last created value to the dropdown list
				beerTypeList.push({ value: beerTypeName, label: beerTypeName, id: LastCreatedBeerTypeID });

				// Set id and values for current form dropdown
				setbeerID(LastCreatedBeerTypeID);
				setBeerType(beerTypeName);
				setBeerTypeValue({ value: beerTypeName, label: beerTypeName });
				setBeerTypeError(false);

			} else {
				toast.error(res.data.responseMessage);

			}
			//setSubmitLoader(false);
			//setBtnDisable(false);
		}).catch(function (error) {
			console.log(error)

			//alert("console error");

			//setSubmitLoader(false)
			//setBtnDisable(false)

		})
	}
	//-------------
	const updateProductInfo = async (productID) => {
		let formData = {
			"ID": productID,
			"BrandID": brandID.toString(),
			"BeerType": beerID.toString(),
			"ProductName": productName,
			"BreweryName": breweryName,
			"AlcoholPercentage": alchol,
			"Calories": (calories == "")?"0":calories,
			"Sugar": (sugar == "")?"0":sugar,
			"Carbs": (carbs == "")?"0":carbs,
			"CanSize": canID.toString(),
			"PackageSize": packID.toString(),
			"CaseSize": caseID.toString(),
			"Country": "1",
			"State": stateID.toString(),
			"Palette": palet,
			"ProductUPC": productUpcNumber,
			"CaseUPC": caseUpcNumber
		}
		await axios.post(API_URL + "UpdateProductData?code=" + API_KEY, formData, config).then((res) => {

			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage);
				handleActiveTab("priceCalculator")
				saveUPCimG(productID);
			} else {
				toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
			setBtnDisable(false);

		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
			setBtnDisable(false)
		})
	}
	const handleSubmit = async (e) => {

		if (handleValidation()) {
			setSubmitLoader(true)
			setBtnDisable(true)
			if (productData) {
				updateProductInfo(productData?.id)
			} else {
				if (productID) {
					updateProductInfo(productID)
				} else {
					insertProductInfo()
				}
			}
		}
	}

	const handleFloatNumber = (e) => {
		if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
			e.preventDefault();
		}
	}


	const checkProductName = async (e) => {
		setDupError(false)
		setDupErrorText("")
		if (!productName == "") {
			let formData =
			{
				"BrandID": brandID.toString(),
				"ProductName": productName
			}
			await axios.post(API_URL + "CheckProductName?code=" + API_KEY, formData, config).then((res) => {

				if (res.data.responseCode === "401") {

					setDupError(true)
					setDupErrorText(res.data.responseMessage)
					setBtnDisable(true);


				} else {
					setDupError(false)
					setDupErrorText("");
					setBtnDisable(false);
				}

			}).catch(function (error) {
				console.log(error)
			})
		}
	}
	useEffect(() => {
		BrandNameList.forEach((_v, _i) => {
			if (_v.brandID == bID) {
				setbNameValue(_v)
			}
		})
	}, [bID, BrandNameList])
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="InsertForms">
				<div className="row">
					<div className="col-md-6">
						{/*// brewery name got changed to company name*/}
						<div className="form-group">
							<label className="brand-tab">Company Name<span className="red-tick"> *</span></label>
							<input type="text" className={breweryNameError ? "form-control error" : "form-control"} value={breweryName} onChange={handleBreweryName} placeholder="Company Name" disabled={true} />
						</div>						
					</div>			

					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Brewery/Brand Name<span className="red-tick"> *</span></label>
							<Select className={bNameError ? "selectTodesign error" : "selectTodesign"}
								styles={customStyles}
								options={BrandNameList}
								onChange={handleBrandName}
								value={bNameValue}
								closeMenuOnSelect={true}
								placeholder={<div className="italic-placeholder">Brewery/Brand Name</div>}
								isSearchable
								isDisabled={bID ? true : false}
							/>

						</div>					
					</div>
				</div> {/*/// end row*/}

				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Product Name<span className="red-tick"> *</span></label>
							<input type="text" className={productError ? "form-control error" : "form-control"} value={productName} onChange={handleProductName} placeholder="Product Name" onBlur={checkProductName} />
							{dupError ? <span className="error-message">{dupErrorText}</span> : ""}
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Type of Product<span className="red-tick"> *</span></label>
							<Autocomplete

								value={beerTypeValue || null}
								// Note : adding (|| null ) after beerTypeValue fixed MUI console error

								className={beerTypeError ? "selectTodesign error" : "selectTodesign"}

								filterOptions={(options, params) => {
									const filtered = filter(options, params);

									const { inputValue } = params;
									// Suggest the creation of a new value
									const isExisting = options.some((option) => inputValue === option.value);
									if (inputValue !== '' && !isExisting) {

										filtered.push({
											inputValue,
											value: `Add "${inputValue}"`,
										});
									}
									return filtered;
								}}// end ilterOptions ----------->

								//styles={customStyles}
								//options={beerTypeList}
								//onChange={handleBeerType}
								//value={beerTypeValue}


								onChange={(event, newValue) => {
									if (newValue == null) {

									}
									else if (typeof newValue === 'string') {
										// alert(string);

									} else if (newValue && newValue.inputValue) {
										// Create a new value from the user input
										// call insert beerType api
										insertNewBeerType(newValue.inputValue);

									} else {

										//alert(newValue.id)

										// set values for form submit
										setbeerID(newValue.id);
										setBeerType(newValue.value);
										setBeerTypeValue({ value: newValue.value, label: newValue.value });
										setBeerTypeError(false);

									}// end else ------->
								}}// end on change event --------->

								id="beerTypeAutocompleteDDL"
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								//style={{ width: 300 }}
								freeSolo
								getOptionLabel={(option) => {
									// Value selected with enter, right from the input
									if (typeof option === 'string') {
										return option;
									}
									// Add "xxx" option created dynamically
									if (option.inputValue) {
										return option.inputValue;
									}
									// Regular option
									return option.value;
								}}


								renderOption={(props, option) => <li style={{ fontSize: 16 }} {...props}>{option.value}</li>}
								options={beerTypeList}
								//renderInput={(params) => <TextField {...params} label="freeSolo" />}

								renderInput={(params) => (
									<TextField {...params} placeholder="TType of Product"
										variant="outlined" />
								)}

							/>

						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Alcohol %<span className="red-tick"> *</span></label>
							<input type="text" className={alcholError ? "form-control error" : "form-control"} value={alchol} onChange={handleAlchol} onKeyPress={handleFloatNumber} placeholder="Alcohol %" />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Calories</label>
							<input type="text" className={calorieError ? "form-control error" : "form-control"} value={calories} onChange={handleCalories} onKeyPress={handleFloatNumber} placeholder="Calories" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Sugar</label>
							<input type="text" className="form-control" value={sugar} onChange={handleSugar} onKeyPress={handleFloatNumber} placeholder="Sugar" />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Carbs</label>
							<input type="text" className="form-control" value={carbs} onChange={handleCarbs} onKeyPress={handleFloatNumber} placeholder="Carbs" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Can Size<span className="red-tick"> *</span></label>
							<Select className={canSizeError ? "selectTodesign error" : "selectTodesign"}
								styles={customStyles}
								options={canSizeList}
								onChange={handleCanSize}
								value={canSizeValue}
								closeMenuOnSelect={true}
								placeholder={<div className="italic-placeholder">Can Size</div>}
								isSearchable
							/>
						</div>
					</div>

					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Pack Size<span className="red-tick"> *</span></label>
							<Select className={packSizeError ? "selectTodesign error" : "selectTodesign"}
								styles={customStyles}
								options={packSizeList}
								onChange={handlePackSize}
								value={packSizeValue}
								closeMenuOnSelect={true}
								placeholder={<div className="italic-placeholder">Pack Size</div>}
								isSearchable
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Case size<span className="red-tick"> *</span></label>
							<Select className={caseSizeError ? "selectTodesign error" : "selectTodesign"}
								styles={customStyles}
								options={caseSizeList}
								onChange={handleCaseSize}
								value={caseSizeValue}
								closeMenuOnSelect={true}
								placeholder={<div className="italic-placeholder">Case size</div>}
								isSearchable
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Cases per Palette<span className="red-tick"> *</span></label>
							<input type="text" className={paletError ? "form-control error" : "form-control"} value={palet} onChange={handlePalette} placeholder="Cases per Palette" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">State<span className="red-tick"> *</span></label>
							<Select className={stateError ? "selectTodesign error" : "selectTodesign"}
								styles={customStyles}
								menuPlacement="top"
								options={stateList}
								onChange={handleState}
								value={stateValue}
								closeMenuOnSelect={true}
								placeholder={<div className="italic-placeholder">State</div>}
								isSearchable
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Country<span className="red-tick"> *</span></label>
							<Select className={countryError ? "selectTodesign error" : "selectTodesign"}
								styles={customStyles}
								menuPlacement="top"
								options={countryOption}
								onChange={handleCountry}
								value={countryValue}
								closeMenuOnSelect={true}
								placeholder={<div className="italic-placeholder">Country</div>}
								isSearchable
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Product UPC Number <span className="red-tick"> *</span></label>
							<InputMask
								mask='999999999999'
								value={productUpcNumber}
								onChange={handleProductUpcNumber}
								className={productUpcNumberError ? "form-control error" : "form-control"}
								maskChar={null}
								placeholder="Product UPC Number"
							>
							</InputMask>
						</div>				
					</div>

					<div className="col-md-6">

						<label className="brand-tab">Upload Product UPC Image<span className="red-tick"> *</span></label>

						<Dropzone disabled={true} disabled={true} onDrop={acceptedFiles => onDropFunction(acceptedFiles, "ProductUpc")} multiple={false} accept={imageFormats}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps({ className: (productUpcImgError) ? 'dropzone custom-input-drop-brewer error' : 'dropzone custom-input-drop-brewer' })}>
									<input {...getInputProps()} />
									<p>Drop or Click Product UPC Image.
										<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
									</p>
								</div>
							)}
						</Dropzone>


						<div className="thumbanilOptions">
							<aside style={thumbsContainer}>
								{/* {(productUpcImgPre.length) ? productUPCthumbPre : productUPCthumb} */}
								{productUPCthumbPre}
								{productUPCthumb}
							</aside>
						</div>

					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Case UPC Number<span className="red-tick"> *</span></label>
							<InputMask
								mask='999999999999'
								value={caseUpcNumber}
								onChange={handleCaseUpcNumber}
								className={caseUpcNumberError ? "form-control error" : "form-control"}
								maskChar={null}
								placeholder="Case UPC Number"
							>
							</InputMask>
						</div>
						
					</div>
					<div className="col-md-6">
						<label className="brand-tab">Upload Case UPC Image<span className="red-tick"> *</span></label>
						<Dropzone disabled={true} disabled={true} onDrop={acceptedFiles => onDropFunction(acceptedFiles, "CaseUpc")} multiple={false} accept={imageFormats}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps({ className: (caseUpcImgError) ? 'dropzone custom-input-drop-brewer error' : 'dropzone custom-input-drop-brewer' })}>
									<input {...getInputProps()} />
									<p>Drop or Click Case UPC Image.
										<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
									</p>
								</div>
							)}
						</Dropzone>

						<div className="thumbanilOptions">
							<aside style={thumbsContainer}>
								{caseUPCthumbPre}
								{caseUPCthumb}
							</aside>
						</div>

					</div>
				</div>
				<div className="row">
					
					<div className="col-md-6">
						<label className="brand-tab">Upload Product Label Image Front<span className="red-tick"> *</span></label>
						<Dropzone disabled={true} onDrop={acceptedFiles => onDropFunction(acceptedFiles, "LabelFront")} multiple={false} accept={imageFormats}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps({ className: (productLabelImgFrontError) ? 'dropzone custom-input-drop-brewer error' : 'dropzone custom-input-drop-brewer ' })}>
									<input {...getInputProps()} />
									<p>Drop or Click Product Label Image Front.
										<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
									</p>
								</div>
							)}
						</Dropzone>

						<div className="thumbanilOptions">
							<aside style={thumbsContainer}>
								{labelFrontThumbPre}
								{labelFrontThumb}
							</aside>
						</div>

					</div>


					<div className="col-md-6">
						<label className="brand-tab">Upload Product Label Image Back<span className="red-tick"> *</span></label>
						<Dropzone disabled={true} onDrop={acceptedFiles => onDropFunction(acceptedFiles, "LabelBack")} multiple={false} accept={imageFormats}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps({ className: (productLabelImgBackError) ? 'dropzone custom-input-drop-brewer error' : 'dropzone custom-input-drop-brewer' })}>
									<input {...getInputProps()} />
									<p>Drop or Click Product Label Image Back.
										<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
									</p>
								</div>
							)}
						</Dropzone>


						<div className="thumbanilOptions">
							<aside style={thumbsContainer}>
								{labelBackThumbPre}
								{labelBackThumb}
							</aside>
						</div>

					</div>



				</div>
				
				<div className="row">
					<div className="col-md-12">
						<div className="formBtns">
							<button type="button" className="btn btn-primary save-button" disabled={btnDisable}>{productData ? "SAVE" : "Save & Next"}</button>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}
