import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function OrderHistory() {
    document.title = "BevPort - Order History"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("all");

    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Qty. in Cases',
            selector: row => row.quantity,
            cell: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Missing Quantity',
            selector: row => row.missingQuantity,
            cell: row => row.missingQuantity,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.amount),
            cell: row => "$" + parseFloat(row.amount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDateVal) ? moment(row.orderDateVal, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.orderDateVal ? moment(row.orderDateVal, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => <span style={{ "color": "#00aeef" }}>{(row.status == 1 || row.status == 2) ? "Placed" : (row.status == 3) ? "Ready for Pick Up" : (row.status == 3) ? "Left Warehouse" : (row.status == 4) ? "Order Left Warehouse" : (row.status == 5) ? "Order Staged at SSD" : (row.status == 6) ? "Order Out for Delivery" : (row.status == 7) ? "Order Delivered" : (row.status == 8) ? "Declined" : ""}</span>,
            sortable: true
        },
        // {
        //     name: 'Action',
        //     selector: row => <>
        //         <Link className="btn btn-primary" disabled>View</Link></>,
        // }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        getOrderHistory()
    }, [])
    const getOrderHistory = async () => {
        await axios.post(API_URL + "GetInvoiceData?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };

    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>

                <div className='expandable-tablerow inventorytbds'>
                    <div className='expandable col one'><strong>History</strong></div>
                </div>



                {
                    data?.orderLogData?.map((v, i) => {
                        return (
                            <>
                                <div className="expandable-tablerow inventorytbds">
                                    <div className="expandable col one bol bold size">
                                        <strong >{v?.action}</strong><br/>
                                        {v?.notes}
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </tbody>
        </table>)
    };

    // const newData = data.map(_v =>{
    //     let disabled = false;
    // 	if (!_v?.orderLogData?.length) {
    // 		disabled = true;
    // 	}
    // 	return { ..._v, disabled };
    // })

    const checkDisable = (row) => {
        if (row?.orderLogData?.length) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Order History</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            expandableRows={true}
                                            expandableRowsComponent={ExpandedComponent}
                                            defaultSortAsc={false}
                                            defaultSortFieldId={5}
                                            // expandableRowDisabled={row => row.disabled}
                                            expandableRowDisabled={row => checkDisable(row)}
                                            noDataComponent={<span className='norecordFound'>No record found!</span>}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}