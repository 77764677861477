import React, { useState, useEffect } from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./../front/Loader";
import axios from 'axios';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};

toast.configure()
export default function AddSalesRep() {
    document.title = "BevPort - Add SalesRep"
    const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [loader, setLoader] = useState(false)
    const [stateList, setStateList] = useState([])
    const [fname, setFname] = useState("");
    const [fnameError, setFnameError] = useState(false);
    const [lName, setLname] = useState("");
    const [lNameError, setLnameError] = useState(false);
    const [email, setEmail] = useState("");
    const [cemail, setCEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [cemailError, setCEmailError] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneError, setPhoneError] = useState(false)
    const [businessName, setBusinessName] = useState("");
    const [businessNameError, setBusinessNameError] = useState(false);
    const [countryValue, setCountryValue] = useState({ label: "USA", value: "1" });
    const [counrtyError, setCountryError] = useState(false);
    const [stateValue, setStateValue] = useState("");
    const [stateError, setStateError] = useState(false)
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false)
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState(false);
    const [userData, setUserData] = useState("");
    let Navigate = useNavigate();
    const {ID} = useParams();


    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const countryOption = [
        { label: "USA", value: "1" },

    ];

    useEffect(() => {
        getStateList();

    }, [])
    const getSalesRepData = async (id) => {
        await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": id }, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setUserData(data)
             
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })
    }
    useEffect(() => {
        if(ID){
            getSalesRepData(ID)
        }
    },[ID])
    useEffect(() => {
        if (ID && userData) {
            setFname(userData?.firstName)
            setLname(userData?.lastName)
            setEmail(userData?.emailID)
            setCEmail(userData?.emailID)
            setPhoneNo(userData?.phoneNumber)
            setBusinessName(userData?.businessName)
            setStateValue({ value: userData?.stateName, label: userData?.stateName, id: userData?.stateID })
            setCity(userData?.city);
            setStreet(userData?.streetAddress)
            setZip(userData?.zipCode)
        }else{
            setFname("")
            setLname("")
            setEmail("")
            setCEmail("")
            setPhoneNo("")
            setBusinessName("")
            setStateValue("")
            setCity("");
            setStreet("")
            setZip("")
        }
    }, [ID, userData])
    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode === "200") {
                let stateOption = [];
                let resData = res.data.responseData
                resData.forEach((da) => {

                    stateOption.push({ value: da.statename, label: da.statename, id: da.id });

                }
                );

                setStateList(stateOption)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleFirstName = (e) => {
        setFname(e.target.value);
        setFnameError(false);
    }

    const handleLastName = (e) => {
        setLname(e.target.value)
        setLnameError(false)
    }

    const handlePhoneNo = (e) => {
        setPhoneNo(e.target.value)
        setPhoneError(false);
    }

    const handleBusinessName = (e) => {
        setBusinessName(e.target.value);
        setBusinessNameError(false);
    }

    const handleCountry = (e) => {
        setCountryValue({ value: e.value, label: e.label })
        setCountryError(false);

    }

    const handleState = (e) => {
        setStateValue({ value: e.value, label: e.label, id: e.id })
        setStateError(false)
    }

    const handleCity = (e) => {
        setCity(e.target.value);
        setCityError(false)
    }

    const handleStreetAddress = (e) => {
        setStreet(e.target.value)
        setStreetError(false);
    }

    const handleZIP = (e) => {
        setZip(e.target.value);
        setZipError(false);

    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    }
    const handleCEmail = (e) => {
        setCEmail(e.target.value);
        setCEmailError(false);
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!fname) {
            formIsValid = false;
            setFnameError(true)
        }
        if (!lName) {
            formIsValid = false;
            setLnameError(true)
        }
        if (!phoneNo) {
            formIsValid = false;
            setPhoneError(true)
        }
        if (!countryValue) {
            formIsValid = false;
            setCountryError(true)
        }
        if (!city) {
            formIsValid = false;
            setCityError(true)
        }
        if (!stateValue) {
            formIsValid = false;
            setStateError(true)
        }
        if (!street) {
            formIsValid = false;
            setStreetError(true)
        }
        if (!zip) {
            formIsValid = false;
            setZipError(true)
        } 
        if (!email) {
            formIsValid = false;
            setEmailError(true);
        }
        if (!validateEmail(email)) {
            formIsValid = false;
            setEmailError(true)
        }
        if (!cemail) {
            formIsValid = false;
            setCEmailError(true);
        }
        if(cemail !== email){
            formIsValid = false;
            setCEmailError(true);
        }
        return formIsValid;
    }

    const insertSalesRep = async () => {
        if (handleValidation()) {
            setLoader(true)
            let formData = {
                "FirstName":fname,
                "LastName":lName,
                "EmailID":email,
                "PhoneNumber":phoneNo,
                "UserType": "3",
                "StreetAddress":street,
                "State":stateValue?.id,
                "Country":"1",
                "City":city,
                "ZipCode":zip,
                "CreatedBy":"Admin"
            }
            await axios.post(API_URL + "RegisterUser?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/salesrep-list");
                } else {
                    toast.error(res.data.responseMessage);
                }
                setLoader(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
            })
        }
    }

    const updateSalesRep = async (id) => {
        if (handleValidation()) {
            setLoader(true)
            let formData = {
                "FirstName":fname,
                "LastName":lName,
                "PhoneNumber":phoneNo,
                "StreetAddress":street,
                "State":stateValue?.id,
                "City":city,
                "ZipCode":zip,
                "UserID": id
            }
            await axios.post(API_URL + "UpdateProfile?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/salesrep-list");
                } else {
                    toast.error(res.data.responseMessage);
                }
                setLoader(false)

            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
            })
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        if(ID){
            updateSalesRep(ID)
        }else{
            insertSalesRep()
        }
    }

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>{ID?"Edit SalesRep":"Add SalesRep"} </h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">First Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={fnameError ? "form-control error" : "form-control"} placeholder="First Name" value={fname} onChange={handleFirstName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Last Name<span className="red-tick"> *</span></label>
                                                    <input type="text" className={lNameError ? "form-control error" : "form-control"} placeholder="Last Name" value={lName} onChange={handleLastName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">E-mail<span className="red-tick"> *</span></label>

                                                    <input type="text" className={emailError ? "form-control error" : "form-control"} placeholder="Enter Email ID" value={email} onChange={handleEmail} disabled={ID?true:false}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Confirm E-mail<span className="red-tick"> *</span></label>

                                                    <input type="text" className={cemailError ? "form-control error" : "form-control"} placeholder="Confirm Email ID" value={cemail} onChange={handleCEmail}  disabled={ID?true:false}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Phone No.<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='(999) 999 9999'
                                                        value={phoneNo}
                                                        onChange={handlePhoneNo}
                                                        className={phoneError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="Phone No."
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Country <span className="red-tick"> *</span></label>
                                                    <Select className={counrtyError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={countryOption}
                                                        onChange={handleCountry}
                                                        value={countryValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Country</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">State<span className="red-tick"> *</span></label>
                                                    <Select className={stateError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={stateList}
                                                        onChange={handleState}
                                                        value={stateValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">State</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">City <span className="red-tick"> *</span></label>
                                                    <input type="text" className={cityError ? "form-control error" : "form-control"} placeholder="City" value={city} onChange={handleCity} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Street Address<span className="red-tick"> *</span></label>

                                                    <input type="text" className={streetError ? "form-control error" : "form-control"} placeholder="Street Address" value={street} onChange={handleStreetAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">ZIP Code<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='99999'
                                                        value={zip}
                                                        onChange={handleZIP}
                                                        className={zipError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="ZIP Code"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >{ID?"Update":"Save"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}