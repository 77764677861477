import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
export default function BrandList(){
	document.title = "BevPort - Brand List"
	const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () =>{
		setToggle(!toggle)
	}

	const handleStatus = async(status, brandID) =>{
		let formData = {
			"ID": brandID,
			"Status":status
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeBrandStatus?code=" + API_KEY, formData, config).then((res) => {
		if(res.data.responseCode == "200"){
				toast.success(res.data.responseMessage, {
					autoClose: 3000, //3 seconds
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					
				  });
				getBrandList(localStorage.getItem("id"))
				setLoader(false);
			}else{
				toast.error(res.data.responseMessage)	
			}
		}).catch(function (error) {

		})	
	}
	const alertFunction = () => {
		toast.error("Please activate brand before adding products")
	}
	const columns = [
	    {
	        name: 'Name',
	        selector: row => (row.brandName).toLowerCase(),
	        cell: row => row.brandName,
			sortable: true,
	    },
	    {
	        name: 'No. of Active Products',
	        selector: row => row.productCount,
			sortable: true,
	    },
	    {
	        name: 'Creation Date',
	        selector: row => (row.createdDate)?moment(row.createdDate):0,
	        cell: row => row.createdDate ? moment(row.createdDate).format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true,
	    },
	    {
	        name: 'Status',
	        selector: row => row.status,
	        cell: row => (row.status == 0) ? <span style={{ "color": "#00aeef" }}>Inactive</span> : <span style={{ "color": "#00aeef" }}>Active</span>,
	        sortable: true
			
	    },
	    {
	        name: 'Action',
			width: '338px',
	        selector: row => <>
			<Link className="btn btn-secondary" to="#"  onClick={() => handleStatus((row.status == "0")?"1":"0", row.id)}>{(row.status == "1")?"Deactivate":"Activate"}</Link>&nbsp;<Link className="btn btn-primary" to={"/brewer/edit-brand/"+row.id}>Manage</Link>&nbsp;{(row.status == 1)?<Link className="btn btn-success" to={"/brewer/add-product/"+row.id}>Add Product</Link>:<Link className="btn btn-success" to="#" onClick={alertFunction}>Add Product</Link>}</>,
	    }
	];
	const [data, setData] = useState([])
	useEffect(() => {
		let userID = localStorage.getItem("id")
		getBrandList(userID)
	},[])
	const getBrandList = async (userID) => {
		await axios.post(API_URL + "GetBrandList?code=" + API_KEY, {UserID: userID}, config).then((res) => {
			if(res.data.responseCode == "200"){
				setData(res.data.responseData)
				
			}else{
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const tableData = {
        columns,
        data
    };
	return(
		<>
		
			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle}/>
				<div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
				{submitLoader ? <Loader /> : ""}
				{loader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar}/>
					<div className="rightContetBlock">
					
						<div className="headingTop">
							<h1>Brand List</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body dataTableSearchBox">
									<DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Brands">
									<DataTable
							            columns={columns}
							            data={data}
										noDataComponent={<span className='norecordFound'>No record found!</span>}
										defaultSortFieldId={3}
							        />
							        </DataTableExtensions>
								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}