

import { Link } from "react-router-dom";
import React, {useEffect} from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png"
import PressRelease from "./../../assets/images/press-release.jpg"
import Header from './Header';
import Footer from './Footer';
export default function Resource(){
	document.title = "BevPort - Press Release"
	useEffect(() => {
	  	window.scrollTo(0, 0)
	}, [])
	return(
		<>
		<div className="site-wrapper">
        		<div className="main-wrapper">
			<Header />
			<section className="simplyfying Empowering privacy-policy" id="simplys">
				<div className="simplyfyingDiv">
				  <div className="container-fluid">
				    <div className="row upcontent">
				      <div className="col-md-5 simplecontent aboutmainBg"> 
				        <div className="simplecontentimg"> 
				          <img className="img-responsive" src={aboutbgd} />  
				        </div>    
				      </div>
				      <div className="col-md-7 manBgimg">
				        <div className="manImg">
				          <img className="img-responsive" src={PressRelease} />
				        </div>
				      </div>
				    </div>
				    <div className="row">
				    <div className="moveTopUp aboutheadphone">
				      <div className="container">
				        <div className="row main-contentprt">
				          <div className="col-md-4">
				            <div className="textSimply muddtext">
				              <h1><span className="darkblacktext">Press</span>Release</h1>
				            </div>
				          </div>
				          <div className="col-md-8">
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>

				</div>
				</section>
<section className="press-release-listing">
<div className="presslitItmes">
	<div className="container">
		<div className="row">
			<div className="col-md-4">
				<div className="pressCards">
					<Link to="/CraftBrewNews">
						<h3>Craft Brew News</h3>
						<div className="releaseDate">Release Date: 24-07-2023</div>
					</Link>
				</div>
			</div>
			<div className="col-md-4">
				<div className="pressCards">
					<Link to="/BevPortDistribution">
						<h3>BevPort Distribution LLC Launches a First-of-its-Kind Three-Tier e-Commerce Distribution Portal for Breweries</h3>
						<div className="releaseDate">Release Date: 24-07-2023</div>
					</Link>
				</div>
			</div>
			
		</div>
	</div>
</div>
</section>	
<Footer />
</div>
</div>
				
		</>
	)
}