import React, {useEffect, useState, useCallback} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone'
import {useNavigate, useParams} from "react-router-dom";
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './../../brewers/blob';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_BRAND_CONTAINER } from '../../../config/constant';
import Loader from "../../front/Loader"
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function CollatralReviews({brandID, brandData, handleActiveTab, brandMediaData, brewerID}){
	
	let navigate = useNavigate();
	const [collatral, setCollatral] = useState([])
	const [review, setReview] = useState([])
	const [collatralPre, setCollatralPre] = useState([])
	const [reviewPre, setReviewPre] = useState([])
	const [submitLoader, setSubmitLoader] = useState(false)
	let { brID } = useParams();
	const thumbsContainer = {
	  	display: 'flex',
	  	flexDirection: 'row',
	  	flexWrap: 'wrap',
	  	marginTop: 16
	};

	const thumbInner = {
	  	display: 'flex',
	  	minWidth: 0,
	  	overflow: 'hidden'
	};
	const img = {
	  	display: 'block',
	  	width: 'auto',
	  	height: '100%'
	};
	useEffect(() => {
		let brandCollatralTemp = []
		let brandReviewTemp = []
		if(brandMediaData.length){
				brandMediaData.map((_v,_i) => {
				if(_v?.filetype == "collatral"){
					brandCollatralTemp.push({"preview":_v.filepath,fileID:_v?.id})
				}
				if(_v?.filetype == "review"){
					brandReviewTemp.push({"preview":_v.filepath,fileID:_v?.id})
				}
			})
			setCollatralPre(brandCollatralTemp)
			setReviewPre(brandReviewTemp)
		}else{
			setCollatral([])
			setReview([])
			setCollatralPre([])
			setReviewPre([])
		}
	},[brandMediaData])
	const onDropFunction = (acceptedFiles, fileType) => {
		if(fileType === "collatral"){
			const renamedAcceptedFiles = acceptedFiles.map((file) => (
	            new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Collatral_${file.name}`, { type: file.type })
	        ))
			let imgData = [...collatral, renamedAcceptedFiles.map(file => Object.assign(file, {
        		preview: URL.createObjectURL(file),
        		imageType: fileType
      		}))]
      		let tmpImgData = [].concat(...imgData)
  			setCollatral(tmpImgData);
  		}
  		if(fileType === "review"){
  			const renamedAcceptedFiles = acceptedFiles.map((file) => (
	            new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Review_${file.name}`, { type: file.type })
	        ))
			let imgData = [...review, renamedAcceptedFiles.map(file => Object.assign(file, {
        		preview: URL.createObjectURL(file),
        		imageType: fileType
      		}))]
      		let tmpImgData = [].concat(...imgData)
  			setReview(tmpImgData);
  		}
	}
	const removeFiles = (index, type) => {
		if(type === "collatral"){
  			const newFiles = [...collatral]; 
  			newFiles.splice(index, 1);
  			setCollatral(newFiles)
  		}
  		if(type === "review"){
  			const newFiles = [...review]; 
  			newFiles.splice(index, 1);
  			setReview(newFiles)
  		}
	}
	const removeFilesPre = (index, type, fileID, fileName) => {
		if(typeof fileID !== "undefined"){
  			deleteFiles(fileID, fileName)
  		}
		if(type === "collatral"){
  			const newFiles = [...collatralPre]; 
  			newFiles.splice(index, 1);
  			setCollatralPre(newFiles)
  		}
  		if(type === "review"){
  			const newFiles = [...reviewPre]; 
  			newFiles.splice(index, 1);
  			setReviewPre(newFiles)
  		}
	}
	const deleteFiles = async (fileID, fileName) => {
  		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, {"ID":fileID}, config).then(async (res) => {
  			await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)
  		}).catch(function (error) {
  			console.log(error)
		})
  	}
	const collatralThumb = collatral.map((file,i) => (
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFiles(i,"collatral")}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
      			{
      				(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif")?<img
			          		src={file.preview}
			          		style={img}
			          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
			        	/>:<>
			        		<i className="fa fa-file-text-o" style={{fontSize:"100px"}}></i>
			        	</>
      			}
	        	
	      	</div>
    	</div>
  	));
  	const collatralThumbPre = collatralPre.map((file,i) => {
  		let fileName = file?.preview;
  		let fFileName = (fileName.split("/")).slice(-1)[0];
  		return(
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFilesPre(i,"collatral", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
      			{
      				(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif")?<img
			          		src={file.preview}
			          		style={img}
			          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
			        	/>:<>
			        		<i className="fa fa-file-text-o" style={{fontSize:"100px"}}></i>
			        	</>
      			}
	        	
	      	</div>
    	</div>
  	)});
  	const reviewThumb = review.map((file,i) => (
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFiles(i,"review")}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
      			{
      				(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif")?<img
			          		src={file.preview}
			          		style={img}
			          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
			        	/>:<>
			        		<i className="fa fa-file-text-o" style={{fontSize:"100px"}}></i>
			        	</>
      			}
	        	
	      	</div>
    	</div>
  	));
  	const reviewThumbPre = reviewPre.map((file,i) => {
  		let fileName = file?.preview;
  		let fFileName = (fileName.split("/")).slice(-1)[0];
  		return (
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFilesPre(i,"review", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
      			{
      				(file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type === "image/gif")?<img
			          		src={file.preview}
			          		style={img}
			          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
			        	/>:<>
			        		<i className="fa fa-file-text-o" style={{fontSize:"100px"}}></i>
			        	</>
      			}
	        	
	      	</div>
    	</div>
  	)});
  	const saveCollatral = async () => {
  		let da = collatral.concat(review);
  		let finalArray = [];
  		da.forEach((_v,_i) => {
  			let d = {
			    "UserID":(brewerID)?brewerID:brID,
			    "BRANDID":(brandData)?brandData?.id:brandID,
			    "PRODUCTID":"0",
			    "FILETYPE":_v?.imageType,
			    "FILEPATH":BLOB_URL+BLOB_BRAND_CONTAINER+'/'+_v?.name,
			    "FILEDATA":"",
			    "FILENAME":_v?.name
			}
			finalArray.push(d)
  		})
  		if(da.length){
  			setSubmitLoader(true)
  			const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
	  			await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
		  			setCollatral([])
		  			setReview([])
					toast.success(res.data.responseMessage)
					setSubmitLoader(false)
					navigate("/admin/brand-list")
		  		}).catch(function (error) {
		  			console.log(error)
		  			setSubmitLoader(false)
				})
	  		})
  		}else{
				 toast.success("File uploaded successfully")
				//toast.success("Brand Activated Successfully")
				navigate("/admin/brand-list")
  		}
  		
  	}
	return(
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="LogosUplaods">
	      		<div className="row">
	      			<div className="col-md-6">
	      				<h3 className="card-heading">Collateral</h3>
	      				<p className="cardSubtext">Use this space as an opportunity to place downloadable fact sheets, POS or pdf versions of presentations related to your brand. Collateral can be viewed in the store and also available for buyers to download. You can include product specific collateral on the Products page. Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF).</p>
	      			</div>
	      			<div className="col-md-6">
	      			<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles,"collatral")} multiple={true} accept= {'image/*'}>
	  					{({getRootProps, getInputProps}) => (
	      				<div {...getRootProps({className: 'dropzone custom-input-drop'})}>      					
					        	<input {...getInputProps()}/>
					        	<p>Drop collateral files here or click to upload.</p>				      	
	      				</div>
	      				)}
					</Dropzone>
	      			</div>
	      		</div>
	      	
		      	<div className="thumbanilOptions">
		      	<aside style={thumbsContainer}>
		      		{collatralThumbPre}
		        	{collatralThumb}
		      	</aside>
		      	</div>
	      	</div>
	      	<div className="LogosUplaods BrandPhotos">
	      		<div className="row">
	      			<div className="col-md-6">
	      				<h3 className="card-heading">Reviews and Mentions</h3>
	      				<p className="cardSubtext">Reviews and Mentions for your brand can be viewed in the store and available to buyers for download. Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF).</p>
	      			</div>
	      			<div className="col-md-6">
	      				<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles,"review")} multiple={true}>
	  					{({getRootProps, getInputProps}) => (
								<div {...getRootProps({ className: 'dropzone'})}>      					
				        	<input {...getInputProps()} />
				        	<p>Drop reviews and mentions files here or click to upload.</p>				      	
	      				</div>
	      				)}
					</Dropzone>
	      			</div>
	      		</div>
	      	
		      	<div className="thumbanilOptions">
		      	<aside style={thumbsContainer}>
		      		{reviewThumbPre}
		        	{reviewThumb}
		      	</aside>
		      	</div>
	      	</div>
	      	<div className="row">
      		<div className="col-md-12">
      			<div className="formBtns threeSubSets">
      				<button type="button" className="btn btn-primary save-button" onClick={saveCollatral}>{brandMediaData?"Save":"Save"}</button>
      			</div>
      		</div>
      	</div>
      	</>
	)
}