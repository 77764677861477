import Dashboard from "../components/salesreps/Dashboard";
import ProductList from "../components/salesreps/ProductList";
import Profile from "../components/salesreps/Profile";
import OrderHistory from "../components/salesreps/OrderHistory";
import CartList from "../components/salesreps/cartList";
import WishList from "../components/salesreps/WishList";
import OrderDetail from "../components/salesreps/OrderDetails";


const route = [
    { path: '/salesrep/dashboard', Component: Dashboard },
    { path: '/salesrep/profile', Component: Profile },
    { path: '/salesrep/products', Component: ProductList },
    { path: '/salesrep/order-history', Component: OrderHistory},
    { path: '/salesrep/cartlist', Component: CartList},
    { path: "/salesrep/wishlist", Component: WishList},
    { path: "/salesrep/order-detail/:orderID", Component: OrderDetail},
  ]
  export default route;