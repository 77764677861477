import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function Warehouses() {
    document.title = "BevPort - Warehouses"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [warehouseType, setWarehouseType] = useState("")
    const [warehouseTypeError, setWareHouseTypeError] = useState(false)
    const [warehouseTypeID, setWarehouseTypeID] = useState("")
    const [warehouseCode, setWarehouseCode] = useState("")
    const [buttonName, setButtonName] = useState("Submit")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        getWarehousesData();
        console.log("warehouseType",warehouseType)
    }, [warehouseType])

    const handleStatus = async (status, id) => {
        setLoader(true);
        let formData = {
            "ID": id,
            "Status" : status
        }
        await axios.post(API_URL + "DeleteWarehouseMaster?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getWarehousesData();
                toast.success(res.data.responseMessage)
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }


    const getWarehousesData = async () => {
        await axios.post(API_URL + "GetWarehouses?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setData(res.data.responseData)
                setSubmitLoader(false);
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    const columns = [
        {
            name: 'Warehouse Name',
            selector: row => (row.wareHouseName).toLowerCase(),
            cell: row => row.wareHouseName,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == "0") ? <span style={{ "color": "#00aeef" }}>Inactive</span> : <span style={{ "color": "#00aeef" }}>Active</span>,
            sortable: true,
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "0") ? "1" : "0", row.id)}>{(row.status == "1") ? "DEACTIVATE" : "Activate"}</Link>&nbsp;<Link className="btn btn-primary" to={"#"} onClick={() => getWarehouseInfo(row.id)}>Edit</Link>&nbsp;</>,
        }
    ];
    const handleWareHouseType = (e) => {
        setWareHouseTypeError(false)
        setWarehouseType(e.target.value)
    }
    const handleValidation = () => {
        let formValid = true
        if (!warehouseType) {
            setWareHouseTypeError(true)
            formValid = false
        }
        return formValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            if (warehouseTypeID) {
                updateWarehouseTypeRecord()
            } else {
                insertWarehouseTypeRecord()
            }
        }
    }
    const insertWarehouseTypeRecord = async () => {
        setLoader(true);
        let formData = {
             "WareHouseName": warehouseType
        }
        await axios.post(API_URL + "InsertWarehouseMaster?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getWarehousesData();
                toast.success(res.data.responseMessage)
                setWarehouseType("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const getWarehouseInfo = async (id) => {
        setLoader(true);
        let formData = {
            "ID": id
        }
        await axios.post(API_URL + "GetWareHouseByID?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                console.log("first")
                window.scrollTo(0, 0)
                let data = res.data?.responseData
                console.log("indfoData", data?.wareHouseName);
                setWarehouseType(data?.wareHouseName)
                setButtonName("Update")
                setWarehouseTypeID(data?.id)
                setWarehouseCode(data?.wareHouseCode)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const updateWarehouseTypeRecord = async () => {
        setLoader(true);
        let formData = {
             "ID": warehouseTypeID,
             "WareHouseName":warehouseType
        }
        await axios.post(API_URL + "UpdateWarehouseMaster?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getWarehousesData();
                toast.success(res.data.responseMessage)
                setWarehouseType("")
                setButtonName("Submit")
                setWarehouseTypeID("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }

    const tableData = {
        columns,
        data
    };

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Warehouses</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className='wizardShowOnly'>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <form className="form" onSubmit={handleSubmit}>

                                                    <div className="form-group mx-sm-3 mb-2">
                                                        <label for="inputPassword2" >Warehouse Type</label>
                                                        <input type="text" className={warehouseTypeError ? "form-control error" : "form-control"} placeholder="Warehouse Type" onChange={handleWareHouseType} value={warehouseType} />
                                                    </div>
                                                    <button type="submit" className="btn btn-primary mb-2">{buttonName}</button>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='tbaShowcase'>
                                        <div className="Content-body dataTableSearchBox">
                                            <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Warehouse Types">
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                   // defaultSortFieldId={1}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}