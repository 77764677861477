import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
    document.title = "BevPort - Buyer Order History"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }



    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Qty. in Cases',
            selector: row => row.quantity,
            cell: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Missing Quantity',
            selector: row => row.missingQuantity,
            cell: row => row.missingQuantity,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.amount),
            cell: row => "$" + parseFloat(row.amount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDateVal) ? moment(row.orderDateVal) : 0,
            cell: row => row.orderDateVal ? moment(row.orderDateVal).format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            // cell: row => <span style={{ "color": "#00aeef" }}>{(row.status == 1)?"BOL Created": (row.status == 2)? "On Dock": (row.status == 3)? "Left Warehouse" : (row.status == 4)?"Stagged at SSD" : (row.status ==5)?"Out for Delivery":(row.status ==6)?"Delivered":""}</span>,
            cell: row => (row.status == 1 || row.status == 2  ||  row.status == 3 ) ? <span style={{ "color": "#00aeef" }}>Placed</span> :( row.status == 4 || row.status == 5 || row.status == 6) ? <span style={{ "color": "#00aeef" }}>In Transit</span>:( row.status == 7)?<span style={{ "color": "#00aeef" }}>Delivered</span>:(row.status == 8)?<span style={{ "color": "#00aeef" }}>Declined</span>:"",
            sortable: true
        },
        // {
        //     name: 'Action',
        //     selector: row => <>
        //         <Link className="btn btn-primary"   disabled>View</Link></>,
        // }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        let userID = localStorage.getItem("id")
        getOrderHistory(userID)
    }, [])
    const getOrderHistory = async (userID) => {
        await axios.post(API_URL + "GetInvoiceData?code=" + API_KEY,{ID: userID}, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)
                console.log("data", res.data.responseData);

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <BuyerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Order History</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    {/* <h3 className="card-heading">Order List</h3> */}
                                <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">
                                
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noDataComponent={<span className='norecordFound'>No record found!</span>}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={5}
                                       
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BuyerFooter />
                </div>
            </div>
        </>
    )
}