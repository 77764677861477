// JavaScript source code
//import Header from './Header';
import SalesServices1 from "./../../assets/images/SalesServices/SalesService1.png"
import SalesServices2 from "./../../assets/images/SalesServices/SalesService2.png"
import simply from "./../../assets/images/simply.png"


import "react-responsive-carousel/lib/styles/carousel.min.css";

import React, { useState, useEffect } from 'react';


import Header from './Header';
import Footer from './Footer';


const SalesServiceContact = () => {
    var profileLink = "";
    profileLink = "http://" + window.location.host + "/SalesServicesContact";
    window.location.href = profileLink;

};



	export default function Index() {
		document.title = "BevPort - Blog"
		useEffect(() => {
			const script = document.getElementById('recaptcha-key');
			if (script) {
				script.remove();
			}
			const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
			if (recaptchaElems.length) {
				recaptchaElems[0].remove();
			}
		}, [])
		return (
			<>
				<div className="site-wrapper">
					<div className="main-wrapper">
						<Header />
				
                        <div className="">

                            <div className="fixtopContentBg">
                                <div className="">
                                    <div className='tbaShowcase'>
                                        <div className="Content-body">

                                            <div style={{ fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif", color: "black" }} className="site-wrapper">
                                                <div className="main-wrapper">


                                                    <section className="GenesisBevPort" id="GenesisBevPortStory">
                                                        <div style={{ padding: "0px 0" }} className="Persistence">

                                                            <div style={{ backgroundColor:"#ffffff"}} className="saleServiceBackGround">
                                                                <div className="container">

                                                                    <div className="row">
                                                                        <div className="col-md-12 text-center">
                                                                            <h1 className="text-center"><span style={{ color: "black" }}>BevPort&#39;s Additional Sales Services</span></h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                                                                            <label>Do you need Feet on the street in Florida selling your brand directly to retailers? We got you.</label>
                                                                        </div>

                                                                        <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                                                                            <label>Do you need representation with large Retail chains in Florida? We can help.</label>
                                                                        </div>
                                                                    </div>

                                                                    {/*// ---------------------new row ----------------------------------*/}
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                                                                            <div className="corners1">
                                                                                <img className="img-responsive" src={SalesServices1} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                                                                            <div className="corners1">
                                                                                <img className="img-responsive" src={SalesServices2} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*// ---------------------new row ----------------------------------*/}
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            &nbsp;
                                                                        </div>
                                                                    </div>

                                                                    {/*// ---------------------new row ----------------------------------*/}
                                                                    <div className="row">
                                                                        <div className="col-md-6 text-center">
                                                                            <div className="corners1">
                                                                                <label style={{ color: "#fdb54f" }}>Ground Level Sales Team</label> <br />
                                                                                <label style={{ color: "white" }}>Cost: $1,500 per month</label><br />
                                                                                <label style={{ fontSize: "20px", color: "white" }} className="pull-left">Get the following Services:</label> <br />
                                                                                <br />
                                                                                <div className="text-left">
                                                                                    <label style={{ color: "white" }}>Sales person in the market selling your brand to Off and On Premise Independent accounts.</label><br />
                                                                                    <label style={{ color: "white" }}>Product tastings.</label><br />
                                                                                    <label style={{ color: "white" }}>Sales orders placement by rep.</label><br />
                                                                                    <label style={{ color: "white" }}>Direct contact with rep. and training as needed.</label><br />
                                                                                    <label style={{ color: "white" }}>Ride-withs in the market as needed.</label><br />
                                                                                </div>

                                                                                <button style={{ backgroundColor: "black", color: "white" }} onClick={SalesServiceContact}>Contact Us</button>

                                                                            </div>
                                                                        </div>


                                                                        <div className="col-md-6 text-center">
                                                                            <div className="corners1">
                                                                                <label style={{ color: "#fdb54f" }}>Retail Chain Sales</label><br />
                                                                                <label style={{ color: "white" }}>Cost: $500 per month</label><br />
                                                                                <label style={{ fontSize: "20px", color: "white" }} className="pull-left">Get the following Services:</label> <br />
                                                                                <br />
                                                                                <div className="text-left">
                                                                                    <label style={{ color: "white" }}>Team of Executive sales people pitching your brand to FL based retail chains.</label><br />
                                                                                    <label style={{ color: "white" }}>Years of experience working with chains.</label><br />
                                                                                    <label style={{ color: "white" }}>Monthly digital recaps on progress and contacts made.</label><br />
                                                                                    <label style={{ color: "white" }}>You are part of any meetings or presentations, when buyer permits.</label><br />
                                                                                    <label style={{ color: "white" }}>&nbsp;</label><br />
                                                                                </div>

                                                                                <button style={{ backgroundColor: "black", color: "white" }} onClick={SalesServiceContact}>Contact Us</button>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*// ---------------------new row ----------------------------------*/}
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            &nbsp;
                                                                        </div>
                                                                    </div>

                                                                    {/*// ---------------------new row ----------------------------------*/}
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-center corners1">
                                                                            <label style={{ color: "white" }}>You can also reach out directly to speak to someone about our services 1-844-973-PORT (7678)</label><br />
                                                                            <label style={{ color: "white" }}>We look forward to serving you.</label><br />
                                                                        </div>
                                                                    </div>


                                                                </div>


                                                            </div>
                                                        </div>
                                                    </section>



                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


						<Footer />
					</div>
				</div>
			</>
		)
	}






