
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "./../../assets/images/logo.png"
import "./../../assets/css/style.css";


export default function Header(){
	const [showMenu, setShowMenu] = useState(false)
	const toggleMenu = () => {
		setShowMenu(!showMenu)
	}

	// block dropdown menu button click 
	const blogClick = () => {
		
		// open blog page in window
		window.open('https://edgebeverage.wixstudio.io/bevportblog', '_blank');
	};


	// about click dropdown menu main button 
	const aboutClick = () => {
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/About";
		window.location.href = profileLink;
	};

	

	// Sales Services dropdown menu button click 
	const SalesServices = () => {
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/SalesServices";
		window.location.href = profileLink;
	};


	// Resources dropdown menu button click 
	const Resources  = () => {
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/resources";
		window.location.href = profileLink;
	};


	



	return(
		<div className="main-naivgation">
			<nav className="navbar navbar-default">
	        	<div className="container">
	        		<div className="navbar-header">
	      				<NavLink className="navbar-brand" to="/">
	        				<img alt="Brand" src={Logo} />
	      				</NavLink>
				      	<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false" onClick={toggleMenu}>
					        <span className="sr-only">Toggle navigation</span>
					        <span className="icon-bar"></span>
					        <span className="icon-bar"></span>
					        <span className="icon-bar"></span>
				      	</button>
				    </div>
				    <div className={showMenu?"collapse navbar-collapse main-menu-nav mainsubmenu show":"collapse navbar-collapse main-menu-nav mainsubmenu"} id="bs-example-navbar-collapse-1">
	      				<ul className="nav navbar-nav main-menu-navigation ">        
					        
					        <li><NavLink to="/brewers">Brewers</NavLink></li>
					        <li><NavLink to="/buyers">Buyers</NavLink></li>
							<li><NavLink to="/resources">Resources</NavLink></li>

							{/*// ------------------New Resources-----------*/}
							{/*<li className="dropdown">*/}
							{/*	<a onClick={Resources} className="dropdown-toggle" data-toggle="dropdown" data-hover="More option" role="button" aria-expanded="false">Resources<span class="caret"></span></a>*/}

							{/*	<ul className="dropdown-menu" role="menu">*/}
							{/*		<li><a href="#" onClick={Resources} id="btnMenuBlog" >Resources</a></li>*/}
							{/*		<li><a href="#" onClick={SalesServices} id="btnMenuBlog" >Additional Sales Services</a></li>*/}
							{/*	</ul>*/}
							{/*</li>*/}

							{/*--------------------About--------------- */}
							<li className="dropdown">							
								<a onClick={aboutClick} className="dropdown-toggle" data-toggle="dropdown" data-hover="More option" role="button" aria-expanded="false">About
									<span class="caret"></span></a>
							
								<ul className="dropdown-menu" role="menu">
									<li><a href="#" onClick={aboutClick} id="btnMenuBlog" >Our Story</a></li>
									<li><a href="#" onClick={blogClick} id="btnMenuBlog" >Blog</a></li>								
								</ul>
							</li>
					      
							<li><NavLink to="/contact">Contact Us</NavLink></li>   
						</ul> 


					
	      				<div className="makebtnright">
							<Link to="/login">Login </Link>
	      				</div>
	    			</div>
	  			</div>
			</nav>
		</div>
	)
}