import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function SalesRepList() {
    document.title = "BevPort - Order List"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("all")
    const [loader, setLoader] = useState(false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    const handleStatus = async (status, id) => {
        let formData = {
            "ID": id,
            "Status": status
        }
        setLoader(true);
        await axios.post(API_URL + "ChangeUserStatus?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode == "200") {
                toast.success(res.data.responseMessage, {
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,

                });
                getSalesRepList()
                setLoader(false);
            } else {
                toast.error(res.data.responseMessage)
            }
        }).catch(function (error) {

        })
    }
    const handleAlertBox = (status) => {
        if (status != "10") {
            toast.error("Please activate SalesRep before associating Brewer and Brand!")
        }
    }
    const columns = [
        {
            name: 'Name',
            selector: row => row.firstName + " " + row.lastName,
            cell: row => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: row => row.emailID,
            cell: row => row.emailID,
            sortable: true,
        },
        {
            name: 'Phone No.',
            selector: row => row.businessPhone,
            cell: row => row.businessPhone,
            sortable: true,

        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status === 10) ? <span style={{ "color": "#00aeef" }}>Active</span> : <span style={{ "color": "#00aeef" }}>Inactive</span>,
            sortable: true,

        },
        {
            name: 'Action',
            width: '350px',
            selector: row => <>
                <Link className="btn btn-primary" to={(row.status == "10") ? "/admin/associateSalesRep/" + row.id : "#"} onClick={() => handleAlertBox(row.status)}>Associate</Link> <Link className="btn btn-secondary" to={"/admin/edit-salesrep/" + row.id}>Edit</Link> <Link className="btn btn-warning" to="#" onClick={() => handleStatus((row.status == "10") ? "90" : "10", row.id)}>{(row.status === 10) ? "Deactivate" : "Activate"}</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        getSalesRepList()
    }, [])
    const getSalesRepList = async () => {
        await axios.post(API_URL + "GetAllSalesRepList?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode == "200") {
                setData(res.data.responseData)
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Sales Rep List</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Sales Rep">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            defaultSortAsc={false}
                                            //defaultSortFieldId={1}
                                            noDataComponent={<span className='norecordFound'>No record found!</span>}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}