// JavaScript source code

import Header from './Header';
import Footer from './Footer';
import { useEffect } from "react"



import "react-responsive-carousel/lib/styles/carousel.min.css";


const printData = () => {

    var divToPrint = document.getElementById("costComparisonTable");
    var newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();

};
//------------------------------------------


export default function Index() {
    document.title = "BevPort - Blog"
    useEffect(() => {
        const script = document.getElementById('recaptcha-key');
        if (script) {
            script.remove();
        }
        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    }, [])


    return (
        <>
            <div style={{ fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif", color: "black" }} className="site-wrapper">
                <div className="main-wrapper">
                    <Header />

                    <section className="GenesisBevPort" id="GenesisBevPortStory">
                        <div style={{ padding: "20px 0" }} className="Persistence">
                            <div className="container">


                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {/*// print works just commented out*/}
                                        {/*	<button className="pull-right" onClick={printData}>Print</button>*/}
                                        <h1 className="text-center"><span style={{ color: "black" }}>Cost Comparison</span></h1>
                                        <p style={{ fontSize: "18px" }}>Here you can see how BevPort is a better option than traditional distribution. Below you see a number of Case Studies based on your FOB pricing and how many Cases per Pallet. </p>


                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-6 text-center">
                                        <label>Bevport Model</label>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-6  text-center">
                                        <label>Traditional Distribution Model</label>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="table-responsive">
                                            <table className="" style={{ width: "100%" }} id="costComparisonTable" >
                                                <tr>
                                                    <th className="text-center" style={{ minWidth: "275px", color: "black" }}></th>
                                                    <th style={{ minWidth: "63px" }}>&nbsp;</th>
                                                    <th className="pull-left" style={{ minWidth: "180px" }}></th>

                                                    <th style={{ minWidth: "20px" }}>&nbsp;</th>

                                                    <th className="text-center" style={{ minWidth: "275px", color: "black" }}></th>
                                                    <th style={{ minWidth: "50px" }}>&nbsp;</th>
                                                    <th style={{ minWidth: "180px" }}>&nbsp;</th>
                                                </tr>
                                                <tr>
                                                    
                                                    <td></td>
                                                    <td></td>
                                                    <td className="pull-right"> <button className="costComparisonFOBBtn text-center" >FOB: $20.00 per Case</button></td>
                                                    <td></td>

                                                  

                                                    <td className="pull-left"> <button className="costComparisonFOBBtn text-center" >Cases per Pallet:50</button></td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor:"#97959542" }}>Fees</td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Cost per Case</td>
                                                    <td></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Fees</td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Cost per Case</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Fees per pallet/case/month</td>
                                                    <td>$75.00</td>
                                                    <td>$1.50</td>
                                                    <td></td>
                                                    <td>Storage Fees per pallet/case/month</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>BevPort Margin</td>
                                                    <td>10%</td>
                                                    <td>$22.22</td>
                                                    <td></td>
                                                    <td>Distributor Margin</td>
                                                    <td>35%</td>
                                                    <td>$30.77</td>
                                                </tr>
                                                <tr>
                                                    <td>Portal membership</td>
                                                    <td>$29.99</td>
                                                    <td>$0.60</td>
                                                    <td></td>
                                                    <td>Portal membership</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Delivery Cost/case</td>
                                                    <td>$5.00</td>
                                                    <td>$5.00</td>
                                                    <td></td>
                                                    <td>Delivery Cost/case</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "yellow" }}>
                                                    <td>Total Cost per case</td>
                                                    <td></td>
                                                    <td>$29.32</td>
                                                    <td></td>
                                                    <td>Total Cost per case</td>
                                                    <td></td>
                                                    <td>$30.77</td>
                                                </tr>


                                                {/*// space*/}
                                                <tr>

                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>





                                                {/*// space*/}
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>



                                                <tr>
                                                   
                                                    <td></td>
                                                    <td></td>
                                                    <td className="pull-right"> <button className="costComparisonFOBBtn text-center" >FOB: $50.00 per Case</button></td>
                                                    <td></td>
                                                    <td className="pull-left"> <button className="costComparisonFOBBtn text-center" >Cases per Pallet:100</button></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Fees</td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Cost per Case</td>
                                                    <td></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Fees</td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Cost per Case</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Fees per pallet/case/month</td>
                                                    <td>$75.0</td>
                                                    <td>$0.75</td>
                                                    <td></td>
                                                    <td>Storage Fees per pallet/case/month</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>BevPort Margin</td>
                                                    <td>10%</td>
                                                    <td>$55.56</td>
                                                    <td></td>
                                                    <td>Distributor Margin</td>
                                                    <td>35%</td>
                                                    <td>$76.92</td>
                                                </tr>
                                                <tr>
                                                    <td>Portal membership</td>
                                                    <td>$29.99</td>
                                                    <td>$0.30</td>
                                                    <td></td>
                                                    <td>Portal membership</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Delivery Cost/case</td>
                                                    <td>$5.00</td>
                                                    <td>$5.00</td>
                                                    <td></td>
                                                    <td>Delivery Cost/case</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "yellow" }}>
                                                    <td>Total Cost per case</td>
                                                    <td></td>
                                                    <td>$61.61</td>
                                                    <td></td>
                                                    <td>Total Cost per case</td>
                                                    <td></td>
                                                    <td>$76.92</td>
                                                </tr>



                                                {/*// space*/}
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                {/*// space*/}
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>


                                                <tr>
                                                   
                                                    <td></td>
                                                    <td></td>
                                                    <td className="pull-right"> <button className="costComparisonFOBBtn text-center" >FOB: $80.00 per Case</button></td>
                                                    <td></td>
                                                    <td className="pull-left"> <button className="costComparisonFOBBtn text-center" >Cases per Pallet:100</button></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Fees</td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Cost per Case</td>
                                                    <td></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}></td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Fees</td>
                                                    <td style={{ fontWeight: "bold", backgroundColor: "#97959542" }}>Cost per Case</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Fees per pallet/case/month</td>
                                                    <td>$75.00</td>
                                                    <td>$0.75</td>
                                                    <td></td>
                                                    <td>Storage Fees per pallet/case/month</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>BevPort Margin</td>
                                                    <td>10%</td>
                                                    <td>$88.89</td>
                                                    <td></td>
                                                    <td>Distributor Margin</td>
                                                    <td>35%</td>
                                                    <td>$123.08</td>
                                                </tr>
                                                <tr>
                                                    <td>Portal membership</td>
                                                    <td>$29.99</td>
                                                    <td>$0.30</td>
                                                    <td></td>
                                                    <td>Portal membership</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Delivery Cost/case</td>
                                                    <td>$5.00</td>
                                                    <td>$5.00</td>
                                                    <td></td>
                                                    <td>Delivery Cost/case</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "yellow" }}>
                                                    <td>Total Cost per case</td>
                                                    <td></td>
                                                    <td>$94.94</td>
                                                    <td></td>
                                                    <td>Total Cost per case</td>
                                                    <td></td>
                                                    <td>$123.08</td>
                                                </tr>




                                            </table>
                                        </div>
                                    </div>
                                </div>







                            </div>
                        </div>
                    </section>


                    <Footer />
                </div>
            </div>
        </>
    )
}
