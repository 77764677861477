export default function BrandFooter() {
	return (
		<>
			<div className="footerDivision">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<p>Copyrights � 2023 All Rights Reserved by BevPort. </p>
						</div>
					</div>
				</div>

			</div>
		</>
	)
}
