import React, { useState, useEffect } from 'react';
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Link, useParams } from "react-router-dom"
import BeerInfo from "./productComponent/beerInfo";
import PriceCalculator from "./productComponent/priceCalculator";
import ProductDesc from "./productComponent/productDesc";
import Compliance from "./complianceComponent/Compliance";
import Loader from "./../front/Loader";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};


export default function AddProduct() {
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [activeTab, setActiveTab] = useState("packageInfo")
	const [productID, setProductID] = useState("")
	const [packSize, setPackSize] = useState(0)
	const [caseSize, setCaseSize] = useState(0)
	const [canSize, setCanSize] = useState(0)
	const [brandID, setBrandID] = useState(0)
	const [productData, setProductData] = useState("")
	const [submitLoader, setSubmitLoader] = useState(false);
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const handleProductID = (id) => {
		setProductID(id)
	}
	const handleTab = (type) => {
		setActiveTab(type)
		if (type == "packageInfo") {
			document.title = "BevPort - Beer and Package Information"
		} else if (type == "priceCalculator") {
			document.title = "BevPort - Price Calculator"
		} else if (type == "productDesc") {
			document.title = "BevPort - Description and Tasting Notes"
		} else if (type == "compliance") {
			document.title = "BevPort - Compliance"
			getUpcData()
		}
	}
	const [upcData, setUpcData] = useState([])
	const getUpcData = async () => {
		let formData = {};
		setSubmitLoader(true);
		if(prodID){
			formData = {
				"PRODUCTID": prodID
			}
		}else{
			formData = {
				"PRODUCTID": productID
			}
		}
		await axios.post(API_URL + "GetProductUPCFiles?code=" + API_KEY, formData, config).then((res) => {
			if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setUpcData(data)
				setSubmitLoader(false);
			}else{
				setUpcData([])
			}
		}).catch(function (error) {
            console.log(error)
			setSubmitLoader(false);
			setUpcData([])
        })
	}
	const handleActiveTab = (tab) => {
		setActiveTab(tab)
		if (tab == "packageInfo") {
			document.title = "BevPort - Beer and Package Information"
		} else if (tab == "priceCalculator") {
			document.title = "BevPort - Price Calculator"
		} else if (tab == "productDesc") {
			document.title = "BevPort - Description and Tasting Notes"
		} else if (tab == "compliance") {
			document.title = "BevPort - Compliance"
			getUpcData()
		}
	}
	const handlePackSize = (v) => {
		setPackSize(v)
	}
	const handleCaseSize = (v) => {
		setCaseSize(v)
	}


	const handleCanSize = (v) => {
		setCanSize(v)
	}
	const handleBrandID = (id) => {
		setBrandID(id)
	}
	const { brID } = useParams();
	const { prodID } = useParams();

	useEffect(() => {
		if (prodID && typeof prodID !== "undefined") {
			//console.log(brID, prodID);
			setSubmitLoader(true)
			getProductDetail(prodID)
		}else{
			setProductData("")
		}
	}, [prodID,productID])
	const getProductDetail = async (productID) => {
		await axios.post(API_URL + "ProductDetailsByID?code=" + API_KEY, { ID: productID }, config).then((res) => {
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				setProductData(res.data.responseData[0])
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	useEffect(() => {
		if (productData) {
			if (productData?.packageSizeVal === "Singles") {
				setPackSize(1)
			} else {
				let u = (productData?.packageSizeVal).split(" ")
				setPackSize(u[0])
			}

			let cu = (productData?.caseSizeVal).split(" ")
			let cu2 = (productData?.canSizeVal).split(" ")
	
			setCaseSize(cu[0])


			//alert(cu2[0])
			setCanSize(cu2[0])
		}

	}, [productData])
	return (
		<>

			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
						{(prodID)?<h1>Edit Product</h1>:<h1>Add Product</h1>}
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">


					{/*	// zoom controls validation div for first three controls*/}
							<div id="zoom1Controls">
							</div>

							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className={(activeTab === "packageInfo") ? "active" : ""}><Link to="#" onClick={(e) => (prodID || productID) ? handleTab("packageInfo") : e.preventDefault()}>Beer and Package Information</Link></li>
										<li className={(activeTab === "priceCalculator") ? "active" : ""}><Link to="#" onClick={(e) => (prodID || productID) ? handleTab("priceCalculator") : e.preventDefault()}>Price Calculation</Link></li>
										<li className={(activeTab === "productDesc") ? "active" : ""}><Link to="#" onClick={(e) => (prodID || productID) ? handleTab("productDesc") : e.preventDefault()}>Description and Tasting Notes</Link></li>
										<li className={(activeTab === "compliance") ? "active" : ""}><Link to="#" onClick={(e) => (prodID || productID) ? handleTab("compliance") : e.preventDefault()}>Compliance</Link></li>
									</ul>

									<div className="tab-content">
										<div className={(activeTab === "packageInfo") ? "tab-pane fade in active" : "tab-pane fade"}>
											<BeerInfo bID={brID} handleProductID={handleProductID} productID={productID} handleActiveTab={handleActiveTab} handlePackSizeFun={handlePackSize} handleCaseSizeFun={handleCaseSize} handleBrandID={handleBrandID} productData={productData} getProductDetail={getProductDetail} handleCanSizeFun={handleCanSize} />
										</div>
										<div id="menu1" className={(activeTab === "priceCalculator") ? "tab-pane fade in active" : "tab-pane fade"}>
											<PriceCalculator packSizeD={packSize} caseSizeD={caseSize} productID={productID} handleActiveTab={handleActiveTab} handleProductID={handleProductID} productData={productData} canSize={canSize} />
										</div>
										<div id="menu2" className={(activeTab === "productDesc") ? "tab-pane fade in active" : "tab-pane fade"}>
											<ProductDesc productID={productID} brandID={brandID} productData={productData} handleActiveTab={handleActiveTab} />
										</div>
										<div id="menu3" className={(activeTab === "compliance") ? "tab-pane fade in active" : "tab-pane fade"}>
											<Compliance productID={productID} brandID={brandID} productData={productData} upcData={upcData} handleActiveTab={handleActiveTab} manageProd={prodID}/>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<BrewerFooter />
				</div>
			</div>
		</>
	)
}
