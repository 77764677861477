import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Loader from "./../front/Loader";

import InputMask from 'react-input-mask';// for input textboxes
import Button from '@mui/material/Button';// boostrap modal button
import { Button2, Modal } from "react-bootstrap"; //boostrap modal window
import { Slide, ToastContainer, toast } from 'react-toastify';

const config = {
  headers: { Authorization: `Bearer ${API_TOKEN}` }
};
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function BuyerHeader({collapseSidebar, cartCount, wishListCount}) {
  const [userData, setUserData] = useState();
  const [userName, setUserName] = useState("")
  // const [businessName, setBusinessName] = useState("")
  let navigate = useNavigate();
  let id = localStorage.getItem("id");
  let isAuthentic = localStorage.getItem("isAuthentic")
  let fname = localStorage.getItem("firstName")
    useEffect(() => {

    if(id && isAuthentic === "true"){
      let userType = localStorage.getItem("userType")
        setUserName(localStorage.getItem("firstName"))

        var firstName = localStorage.getItem("firstName");
      // setBusinessName(localStorage.getItem("businessName"))
        if (userType != "admin") {
            navigate("/admin/login");
        }

        else if (firstName === "SSD Admin") {
            alert("Error: You can't login into BevPort Admin With The SSD UserName and Password.");
            navigate("/admin/login")
        }
        //----
    }else{
      navigate("/admin/login")
    }
  },[id, isAuthentic])

    const [countCart, setCartCount] = useState(0)
    const [countWishlist, setWishListCount] = useState(0)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);



    // Boostrap Modal Window For Password Change -----------------
    const [show, setShow] = useState(false);
    const handleCloseModal = () =>
    {
        setShow(false);
        setAdminPassword("");// 
    }

    const [AdminPassword, setAdminPassword] = useState("");
    const [AdminPasswordError, setAdminPasswordError] = useState(false);

    const [submitLoader, setSubmitLoader] = useState(false);


    // First Click right corner chnage password
    const onClickChangePassword = (e) => {
        setShow(true); //show modal window 
        handleMenuClose();
    }
    //--

    const handle_AdminPasswordChange = (e) => {

        setAdminPassword(e.target.value);
        setAdminPasswordError(false);
    }
    //--

    const handleValidation_AdminPassword = () => {
        let formIsValid = true;

        // check validation for buyerPaymentType
        if (AdminPassword === "") {
            formIsValid = false;
            setAdminPasswordError(true);
        }

        return formIsValid;
    }
    //--


    const handleSubmit_AdminPassword = async (e) => {
        e.preventDefault()
        setSubmitLoader(true);
        if (handleValidation_AdminPassword()) {

        	let formData = {
        		"ID": 1,
        		"Password": AdminPassword,      	
            }
            //--
            await axios.post(API_URL + "UpdateAdminPassword?code=" + API_KEY, formData, config).then((res) => {
        		if (res.data.responseCode === "200") {
        			toast.success(res.data.responseMessage);

        			setShow(false); //close modal window 
        		} else {
        			toast.error(res.data.responseMessage);
                }
            
        		setSubmitLoader(false)
        		//setBtnDisable(false)
        	}).catch(function (error) {
        		console.log(error)
        		setSubmitLoader(false)
        		//setBtnDisable(false)
        	})

        }
    }



    //-------------------------------------------------------------------------

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("isAuthentic");
    localStorage.removeItem("userType");
    localStorage.removeItem("firstName");
    localStorage.removeItem("businessName");
    navigate("/admin/login")
  }
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      className="OpenBOx"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disableScrollLock={true}
    >
    <div className="test-memnu">
              <Link onClick={onClickChangePassword} to="#"><MenuItem className="sub-menu-item">Change Password</MenuItem></Link>
      <MenuItem className="sub-menu-item" onClick={handleLogout}>Logout</MenuItem>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
      <>


          <Modal show={show} onHide={handleCloseModal} animation={false}>
              <Modal.Header style={{ background: "#587dd7" }} > {/*closeButton*/}
                  <Button style={{ color: "red" }} className="btn-close pull-right" onClick={handleCloseModal}>
                      X
                  </Button>
                  <Modal.Title className="text-center">Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                  <div className="row">
                      {submitLoader ? <Loader /> : ""}
                      <div className="col-md-6">
                          <div className="form-group">
                              <label className="brand-tab">New Password <span className="red-tick"> </span></label>
                            
                             {/* mask='********************'*/}
                              <InputMask                                
                                  maxLength={20}
                                  value={AdminPassword}
                                  onChange={handle_AdminPasswordChange}
                                  className={AdminPasswordError ? "form-control error" : "form-control"}
                                  maskChar={null}
                                  placeholder="Password"
                              >
                              </InputMask>
                          </div>
                      </div>
                  </div>


              </Modal.Body>
              <Modal.Footer>
                  <Button style={{ background: "#30bf5d3d" }} className="form-control" variant="primary" onClick={handleSubmit_AdminPassword}>
                      Save Changes
                  </Button>

                  <div>
                      &nbsp;
                  </div>

                  <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                  </Button>
              </Modal.Footer>
          </Modal>


   
    <Box sx={{ flexGrow: 1 }} className="RightContent">
      <AppBar position="static" className="HeaderTag SuperAdmin">
        <Toolbar className="HeaderContent SuperAdminToolbar">
          
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {collapseSidebar();}}
          >
            <MenuIcon />
          </IconButton>
          <div className='IconProfileSetup'>
          
          
          <Box sx={{ flexGrow: 1 }} />
          <Box className="profileHeadSide" sx={{ display: { xs: 'none', md: 'flex' } }} onClick={handleProfileMenuOpen}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              
              color="inherit"
              className="prfileDivIcon"
            >
              <AccountCircle />
            </IconButton>
           
            <div className="Profile-head">
                <span className="OwnerName">{userName}</span>
                {/* <span className="bussinessName">{businessName}</span> */}
            </div>
          </Box>
          </div>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
    </>
  );
}