import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BuyerList() {
	document.title = "BevPort - SSD Dashboard"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)

	const collapseSidebar = () => {
		setToggle(!toggle)
	}

	
	
	const columns = [
		{
			name: 'Ship City',
			selector: row => (row.shipCity + " " + row.shipCity).toLowerCase(),
			cell: row => row.shipCity + " " + row.shipCity,
			sortable: true

		},
		{
			name: 'County',
			selector: row => row.county,
			cell: row => row.county.toString(),
			sortable: true
/*			width: '100px',*/

		},
		{
			name: 'Shipping Address',
			selector: row => row.shipAdr2,
			cell: row => row.shipAdr2.toString(),
			sortable: true

		},
		{
			name: 'Zip Code',
			selector: row => (row.shipZip).toLowerCase(),
			cell: row => row.shipZip.toString(),
			sortable: true

		},

		{
			name: 'Customer Key',
			selector: row => row.cusKey ,
			cell: row => row.cusKey.toString(),
			sortable: true,
			wrap: true

		},

		{
			name: 'Customer Name',
			selector: row => row.cusName,
			cell: row => row.cusName.toString(),
			sortable: true,
			wrap: true
		},

		{
			name: 'Sales Date',
			selector: row => (row.salesDate) ? moment(row.salesDate) : 0,
			cell: row => row.salesDate ? moment(row.salesDate).format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true
		},



		// order table 
		{
			name: 'OrderID',
			selector: row => row.orderID,
			cell: row => row.orderID.toString(),
			sortable: true,
			wrap: true
		},

		{
			name: 'Cases/Qty',
			selector: row => row.quantity,
			cell: row => row.quantity.toString(),
			sortable: true,
			wrap: true
		},
	
		{
			name: 'Product Name',
			selector: row => row.productName,
			cell: row => row.productName.toString(),
			sortable: true,
			wrap: true
		}

		//{
		//	name: 'Amount',
		//	selector: row => row.amount,
		//	cell: row => row.amount.toString(),
		//	sortable: true,
		//	wrap: true
		//},

		//{
		//	name: 'FinalPrice',
		//	selector: row => row.finalPrice,
		//	cell: row => row.finalPrice.toString(),
		//	sortable: true,
		//	wrap: true
		//}
	
	];


	
	const [data, setData] = useState([])
	const [tomorrowData, setTomorrowData] = useState([])
	useEffect(() => {

		// todays client date
		let date = new Date();
		date = date.toLocaleDateString();

		GetTodaysDeliveryList(date);
		GetTommorowsDeliveryList(date);


	}, [])


	// -- Get todays SSD delviery List----------------------------------------------
	const GetTodaysDeliveryList = async (todaysDate) => {

		let formData = {
			"TodaysClientDate": todaysDate
		}
		//--

		await axios.post(API_URL + "GetSSDDeliverysListToday?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				setData(res.data.responseData);

			} else {
				setData([])
			}
			setSubmitLoader(false);
			setLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
			setLoader(false);
		})
	}
	//-------------------------------------------------------------------------------


	// -- Get tomorrows SSD delviery List----------------------------------------------
	const GetTommorowsDeliveryList = async (todaysDate) => {

		let formData = {
			"TodaysClientDate": todaysDate
		}
		//--


		await axios.post(API_URL + "GetSSDDeliverysListTomorrow?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {

				setTomorrowData(res.data.responseData);

			} else {
				setTomorrowData([])
			}
			setSubmitLoader(false);
			setLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
			setLoader(false);
		})
	}
	//-------------------------------------------------------------------------------


	const tableData = {
		columns,
		data
	};


	//----
	const tableData2 = {
		columns,
		data:tomorrowData
	};


	const refreshDeliverGrids = () => {

		//setSubmitLoader(true);
		setLoader(true); // turn on loading animation
		// todays client date
		let date = new Date();
		date = date.toLocaleDateString();

		GetTodaysDeliveryList(date);
		GetTommorowsDeliveryList(date);

	}

	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>SSD Dashboard</h1>
						</div>
						<div className="blank150"></div>

						<div className="fixtopContentBg">
							<div className="card-Content">

								<div className="row">
									<div className="col-md-12 text-center">
										<input className="pull-left" type="button" value="Refresh" onClick={refreshDeliverGrids} ></input>
									</div>
								</div>

								
								<div className="row">
									<div className="col-md-12 text-center">
										 <label>Today's Delivery</label>
									</div>
								</div>

								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search Customers">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={<span className='norecordFound'>No record found!</span>}
											//defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>


							

								<div style={{ paddingTop: "20px" }} className='tbaShowcase'>
									<div className="text-center">
										<label>Tomorrow's Delivery</label>
									</div>
									
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData2} exportHeaders export={true} print={false} filterPlaceholder="Search Customers">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={<span className='norecordFound'>No record found!</span>}
											//defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>


							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}