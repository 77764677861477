import React, { useState, useEffect } from 'react';
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

export default function RevolutionList(){
	document.title = "BevPort - Revolution List"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const [data, setData] = useState([])
	useEffect(() => {
		getRevolutionList()
	}, [])
	const getRevolutionList = async (userID) => {
		await axios.post(API_URL + "GetAllJoinRevolutionData?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				setData(res.data.responseData)
				console.log("resposnse", res.data.responseData);

			} else {
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const columns = [
		{
			name: 'Type',
			selector: row => (row.userTypeVal).toLowerCase(),
			cell: row => row.userTypeVal,
			sortable: true,

		},
		{
			name: 'Name',
			selector: row => (row.firstName + " " + row.lastName).toLowerCase(),
			cell: row => row.firstName + " " + row.lastName,
			sortable: true,

		},
		{
			name: 'E-mail',
			selector: row => (row.emailID).toLowerCase(),
			cell: row => row.emailID.toString(),
			sortable: true,
			width: '250px',

		},
		{
			name: 'Phone No.',
			selector: row => row.mobileNumber,
			cell: row => row.mobileNumber.toString(),
			sortable: true,

		},
		{
			name: 'Title',
			selector: row => (row.title).toLowerCase(),
			cell: row => row.title.toString(),
			sortable: true,

		},

		{
			name: 'Company Name',
			selector: row => (row.companyName).toLowerCase(),
			cell: row => row.companyName.toString(),
			sortable: true,
			wrap: true

		}
	];
	const tableData = {
		columns,
		data
	};
	return(
		<>
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Revolution List</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={"No record found!"}
												defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}