import React, { useState, useEffect } from 'react';
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import Loader from "./../front/Loader";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};


toast.configure()
export default function EditInventory() {
    document.title = "BevPort - Edit Inventory"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false);

    const [quantity, setQuantity] = useState("");
    const [quantityError, setQuantityError] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);

    const [remark, setRemark] = useState("");
    const { productID } = useParams();
    const [type, setType] = useState("");
    const [brandID, setBrandID] = useState("");
    let { state } = useLocation();

    let Navigate = useNavigate();
    useEffect(() => {
        if (state) {
            setType(state?.type);
            setBrandID(state?.brandID)
        }
    }, [state])
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const handleQuantity = (e) => {
        setQuantity(e.target.value)
        setQuantityError(false);
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!quantity) {
            formIsValid = false;
            setQuantityError(true)
        }
        return formIsValid;
    }

    const handleFloatNumber = (e) => {
        if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    }

    const handleRemark = (e) => {
        setRemark(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setSubmitLoader(true)
            let formData = {
                ID : localStorage.getItem("id"),
                BrandID : brandID,
                ProductID : productID,
                InventoryType : (type == "plus")?"Added":"Damaged", 
                Quantity : quantity,
                WareHouseID : "1",
                Remarks : remark
            }

        await axios.post(API_URL + "UpdateInventory?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);
                Navigate("/brewer/inventory");
            } else {
                toast.error(res.data.responseMessage);
            }
            setSubmitLoader(false)

            //setBtnDisable(false)
        }).catch(function (error) {
            console.log(error)
            setSubmitLoader(false)
            //setBtnDisable(false)
        })
    }
}

return (
    <>
        {submitLoader ? <Loader /> : ""}
        <div className="mainAdminWrapper">
            <Sidebar collapse={toggle} />
            <div className={!toggle ? "wholeCompoundRight" : "wholeCompoundRight fullwidth"}>
                <BrewerHeader collapseSidebar={collapseSidebar} />
                <div className="rightContetBlock">

                    <div className="headingTop">
                        <h1>Edit Inventory</h1>
                    </div>
                    <div className="blank150"></div>

                    <div className="fixtopContentBg">
                        <div className="card-Content">
                            <div className="Content-body">
                                <div className="InsertForms" style={{ paddingTop: 0 }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="brand-tab">Quantity In Case<span className="red-tick"> *</span></label>

                                                <input type="text" className={quantityError ? "form-control error" : "form-control"} placeholder="Quantity In Case" value={quantity} onChange={handleQuantity} onKeyPress={handleFloatNumber} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="brand-tab">Remark</label>
                                                <input type="text" className="form-control" placeholder="Remark" value={remark} onChange={handleRemark} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="formBtns">
                                                <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BrewerFooter />
            </div>
        </div>
    </>
)
}