import React, { useState, useEffect } from 'react';
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { useParams } from "react-router-dom"
import DatePicker from "react-datepicker";
import Loader from "../../front/Loader";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
import Select from 'react-select';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};

toast.configure()
export default function EditProfile() {
    document.title = "BevPort - Deal Info"
    const { id } = useParams();
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [BrandNameList, setBrandNameList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [brandNameError, setBrandNameError] = useState(false);
    const [brandID, setBrandID] = useState("");
    const [productName, setProductName] = useState("");
    const [productID, setProductID] = useState("");
    const [productNameError, setProductNameError] = useState(false);
    const [poplarCheck, setPopularCheck] = useState(false);
    const [newArrivalCheck, setNewArrivalCheck] = useState(false);
    const [specialCheck, setSpecialCheck] = useState(false);
    const [hazyCheck, setHazyCheck] = useState(false);
    const [dealData, setDealData] = useState("")
    const [buttonName, setButtonName] = useState("Save")


    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)

    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    useEffect(() => {
        getBrandName();
        //getProducName();


    }, [])

    useEffect(() => {
        if (id) {
            getDealInfo(id);
        }
    }, [])

    useEffect(() => {
        if (id) {
            setBrandName({ value: dealData?.brandName, label: dealData?.brandName });
            setProductName({ value: dealData?.productName, label: dealData?.productName })
            setPopularCheck(dealData?.popularBrew);
            setNewArrivalCheck(dealData?.newArrival);
            setSpecialCheck(dealData?.specialDeal);
            setHazyCheck(dealData?.getHezy);
            setBrandID(dealData?.brandID);
            setProductID(dealData?.productID)
            setButtonName("Update");

        } else {
            setBrandName("");
            setProductName("")
            setPopularCheck("");
            setNewArrivalCheck("");
            setSpecialCheck("");
            setHazyCheck("");
            setBrandID("");
            setProductID("");
            setButtonName("Save");
        }
    }, [id, dealData])

    const getBrandName = async () => {
        await axios.post(API_URL + "GetBrandslist?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode == "200") {
                let brandOption = []
                let resData = res.data.responseData
                resData.forEach((da) => {
                    brandOption.push({ value: da.brandName, label: da.brandName, brandID: da.id });

                }
                );
                setBrandNameList(brandOption);
                setSubmitLoader(false)

            } else {

            }
        }).catch(function (error) {

        })

    }

    const getDealInfo = async (id) => {
        await axios.post(API_URL + "GetDealInfobyID?code=" + API_KEY, { "ID": id }, config).then((res) => {
            if (res.data.responseCode == "200") {
                setDealData(res.data.responseData[0])
                setSubmitLoader(false);
                console.log("sdfs", res.data.responseData[0]);
            }
        }).catch(function (error) {

        })
    }







    const handleBrandName = async (e) => {
        setBrandID(e.brandID);
        setBrandName({ value: e.value, label: e.label });
        setBrandNameError(false);
        setProductName("");
        if ({ value: e.value, label: e.label }) {
            await axios.post(API_URL + "GetProductNames?code=" + API_KEY, { ID: e.brandID }, config).then((res) => {
                if (res.data.responseCode == "200") {
                    let productOption = []
                    let resData = res.data.responseData
                    resData.forEach((da) => {
                        productOption.push({ value: da.productName, label: da.productName, productID: da.id });

                    }
                    );
                    setProductList(productOption);


                } else {

                }
            }).catch(function (error) {

            })
        }
    }

    const handleProduct = (e) => {
        setProductID(e.productID)
        setProductName({ value: e.value, label: e.label });
        setProductNameError(false);
    }



    const handleCheckBoxes = (e, type) => {
        if (type == 'popular') {
            if (e.target.checked) {
                setPopularCheck(true);

            } else {
                setPopularCheck(false);
            }

        }
        if (type == 'newArrival') {
            if (e.target.checked) {
                setNewArrivalCheck(true);

            } else {
                setNewArrivalCheck(false);
            }

        }
        if (type == 'specialDeal') {
            if (e.target.checked) {
                setSpecialCheck(true);

            } else {
                setSpecialCheck(false);
            }

        }
        if (type == 'getHazy') {
            if (e.target.checked) {
                setHazyCheck(true);

            } else {
                setHazyCheck(false);
            }

        }
    }



    const handleValidation = () => {
        let formIsValid = true;

        if (!brandName) {
            formIsValid = false;
            setBrandNameError(true)
        }


        if (!productName) {
            formIsValid = false;
            setProductNameError(true)
        }

        if (brandName && productName && !poplarCheck && !newArrivalCheck && !specialCheck && !hazyCheck) {
            toast.error("Please check one of them");
            formIsValid = false
        }

        return formIsValid;
    }

    const insertRecord = async (e) => {
        setLoader(true)
        let formData = {
            "BrandID": brandID,
            "ProductID": productID,
            "PopularBrew": (poplarCheck) ? "1" : "0",
            "NewArrival": (newArrivalCheck) ? "1" : "0",
            "SpecialDeal": (specialCheck) ? "1" : "0",
            "GetHezy": (hazyCheck) ? "1" : "0"
        }
        await axios.post(API_URL + "AddDealInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);
            } else {
                toast.error(res.data.responseMessage);
            }
            setLoader(false)
            setBrandName("");
            setProductName("");
            setPopularCheck(false);
            setNewArrivalCheck(false);
            setSpecialCheck(false);
            setHazyCheck(false);


        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })

    }

    const updateRecord = async (id) => {
        setLoader(true)
        let formData = {
            "ID": id,
            "BrandID": brandID,
            "ProductID": productID,
            "PopularBrew": (poplarCheck) ? "1" : "0",
            "NewArrival": (newArrivalCheck) ? "1" : "0",
            "SpecialDeal": (specialCheck) ? "1" : "0",
            "GetHezy": (hazyCheck) ? "1" : "0"
        }

        await axios.post(API_URL + "UpdateDealInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);
            } else {
                toast.error(res.data.responseMessage);
            }
            setLoader(false)

        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            if (id) {
                updateRecord(id)
            } else {
                insertRecord()
            }

        }
    }



    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Deal Info</h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Brand<span className="red-tick"> *</span></label>
                                                    <Select className={brandNameError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        options={BrandNameList}
                                                        onChange={handleBrandName}
                                                        closeMenuOnSelect={true}
                                                        value={brandName}
                                                        placeholder={<div className="italic-placeholder">Brand Name</div>}
                                                        isDisabled={(id)?true:false}
                                                        isSearchable

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Product<span className="red-tick"> *</span></label>
                                                    <Select className={productNameError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        options={productList}
                                                        onChange={handleProduct}
                                                        value={productName}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Product Name</div>}
                                                        isDisabled={(id)?true:false}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div class="checkbox rightTextShift">
                                                        <label><span className='mkWidths'>Popular Brewers</span> <input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'popular')} checked={poplarCheck} /></label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div class="checkbox rightTextShift">
                                                        <label><span className='mkWidths'>New Arrival</span> <input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'newArrival')} checked={newArrivalCheck} /></label>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div class="checkbox rightTextShift">
                                                        <label><span className='mkWidths'>Special Deal</span> <input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'specialDeal')} checked={specialCheck} /></label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div class="checkbox rightTextShift">
                                                        <label><span className='mkWidths'>Monthly Special</span> <input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'getHazy')} checked={hazyCheck} /></label>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >{buttonName}</button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}