import Login from "../components/SSD/Login";
import Dashboard from "../components/SSD/Dashboard";
import Sidebar from "../components/SSD/Sidebar";
import Header from "../components/SSD/Header";
import Footer from "../components/SSD/Footer";


const route = [
    { path: '/SSD/login', Component: Login },
    { path: '/SSD/Dashboard', Component: Dashboard },
    { path: '/SSD/Sidebar', Component: Sidebar },
    { path: '/SSD/Header', Component: Header },
    { path: '/SSD/Footer', Component: Footer },
  
]
export default route;