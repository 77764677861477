import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BASE_URL } from './../../config/constant';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Loader from "./../front/Loader";
import LogoInvoice from './../../assets/images/logo.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Home from './../../components/front/Index';


const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
function OrderDetail() {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }


    const [submitLoader, setSubmitLoader] = useState(true);

    let { orderID } = useParams()
    let { type } = useParams()

    document.title = "Blank-Page"


    useEffect(() => {
        console.log("type", type);
        handleApproved();
    }, [])

    const handleApproved = async () => {

        let formData = {
            "OrderID": orderID,
            "Status": (type == "approve") ? "1" : "20"
        }

        console.log("formData", formData);

        await axios.post(API_URL + "OrderStatusUpdate?code=" + API_KEY, formData, config).then((res) => {
            console.log("respose", res);
            if (res.data.responseCode == "200") {


                Swal.fire({
                    title: res.data.responseMessage,
                    //text: res.data.responseMessage ,
                    html: "",
                    icon: (res.data.responseMessage == "Order already approved" || res.data.responseMessage == "Order Approved successfully") ? 'success' : 'error',
                    //showDenyButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#205380',
                    denyButtonColor: '#205380',
                    confirmButtonText: 'OK',
                    //denyButtonText: 'NO',
                    background: '#fff',
                    color: '#000',
                    iconColor: "rgb(255, 153, 0)",
                    width: "500px",
                    padding: '2em',
                    reverseButtons: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/");
                    }
                })

                setSubmitLoader(false);
                //navigate("/login");
                localStorage.removeItem('id');
                localStorage.removeItem('userType');
                localStorage.removeItem('isAuthentic');

            } else {
                toast.error(res.data.responseMessage)
            }
        }).catch(function (error) {

        })


    }



    return (
        <>
            {submitLoader ? <Loader /> : ""}
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Home />

                </div>
            </div>
        </>
    )
}

export default OrderDetail