import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import MyProfilePic from './../../assets/images/beernabs.png'
import SalesServices1 from "./../../assets/images/SalesServices/SalesService1.png"
import Canareas from './../../assets/images/cansareas.png'
import buyerBlankList from './../../assets/images/buyerBlankList2.png'
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ReactGA from "react-ga4";
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';

import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';



import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: false,
			text: '',
		},
	},
};
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export default function Dashboard() {
	document.title = "BevPort - Dashboard"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(false)
	const [loader, setLoader] = useState(false)
	const [isCompliance, setIsCompliance] = useState()
	const [isDisabled, setIsDisabled] = useState(false);

	//-------------------------------------------------------------------------------------------------
	let Navigate = useNavigate();
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	let activityState = localStorage.getItem("activityState");
	useEffect(() => {

		// check active states after getting product list
		if (activityState > 0 && activityState <= 100) {
			
			Handle_ActivityState_0_To_100_PopUp();
		}

		else if (activityState == 150) {
			setIsDisabled(true);
			Handle_ActivityState150_PopUp();
		}


		// todays client date
		let date = new Date();
		date = date.toLocaleDateString();

		GetRetailExortList(date);
	
		// Tracking if user came to this page - google analytics
		//ReactGA.(window.location.pathname)

		//alert(window.location);


		ReactGA.send({ hitType: "Dashboard", page: window.location, title: "User Loaded Dashboard" });

	}, [activityState])
	useEffect(() => {
		getDashboardData();

	

	}, [])

	const [orderList, setOrderList] = useState([])
	const [buyerList, setBuyerList] = useState([])
	const [sellState, setSellState] = useState("")
	const [agreementURL, setAgreementURL] = useState("");

	const getDashboardData = async () => {
		let formData = {
			"ID": localStorage.getItem("id")
		}
		await axios.post(API_URL + "GetBrewerDashboardData?code=" + API_KEY, formData, config).then((res) => {

			if (res.data?.responseCode === "200") {
				console.log("resss", res.data?.agreementFilePath);
				if (res.data?.agreementFilePath != "") {
					setAgreementURL(res.data?.agreementFilePath);
				}

				/*setBrewerList(res.data?.responseDataMostSoldProducts)
				setWhisList(res.data?.responseDataWishList)
				setOrderList(res.data?.responseDataOrder)
				setNewArrival(res.data?.buyerDashboardNewArrivals)*/
				setIsCompliance(res.data?.isComplianceUpload)
				setOrderList(res.data?.responseDataOrder)
				setBuyerList(res.data?.brewerDashboardBuyerlist);


				// Check if buyer list is empty or not and display image or bot products---------
				if (res.data?.brewerDashboardBuyerlist.length == 0) {

					var blankBuyerList = document.getElementById("blankBuyerListImage");
					blankBuyerList.classList.remove("hidden");

				}
				else {

					var notblankBuyerList = document.getElementById("notBlankBuyerListImage");
					notblankBuyerList.classList.remove("hidden");


					var blankBuyerList2 = document.getElementById("blankBuyerListImage");
					blankBuyerList2.classList.add("hidden");
				}
				//-------------------------------------------------------------------------------

				let graphData = res?.data?.brewerDashboardGraphData;

				//console.log(graphData)
				let graphArray = [];
				let monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
				monthArray.forEach((x, y) => {
					let dataRes = graphData.filter((c, s) => {
						if (c?.month === x) {
							return c;
						}
					})
					// Push data to graphs 
					if (dataRes.length) {
						graphArray.push(dataRes[0]?.total_Sales)
					} else {
						graphArray.push(0);
					}
				})
				//setSellState(res?.data?.brewerDashboardGraphData)
				setSellState(graphArray)
			} else if (res.data?.responseCode === "401") {
				setIsCompliance(res.data?.isComplianceUpload)
			}

			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}



	const data = {
		labels,
		datasets: [
			{
				label: 'Sell',
				data: sellState,
				backgroundColor: '#65b12d',
			}
		],
	};

	// export to excel - npm install  - npm install xlsx file-saver---------------
	const ExportData = [
		{ name: "John", email: "john@example.com", age: 28, width: 100, wch: 200 },
		{ name: "Jane", email: "jane@example.com", age: 32, width: 100, wch: 200 }
	];


	// export column widths excel - for retail list
	var wscols = [
		{ wch: 20 },
		{ wch: 55 },
		{ wch: 10 },
		{ wch: 40 },
		{ wch: 20 }
	];



	const exportToExcel = () => {

		const worksheet = XLSX.utils.json_to_sheet(RetailData);
		worksheet['!cols'] = wscols;
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

		// Buffer to store the generated Excel file
		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

		saveAs(blob, "RetailerList.xlsx");
	};
	// - end export to excel ---------------------------------------------------


	const [RetailData, setRetailData] = useState([])
	// -- Get todays SSD delviery List----------------------------------------------
	const GetRetailExortList = async (todaysDate) => {

		let formData = {
			/*"TodaysClientDate": todaysDate*/
		}
		//--

		await axios.post(API_URL + "GetRetailerListExport?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				/*alert(res.data.responseData);*/
				setRetailData(res.data.responseData);

			} else {
				setRetailData([])
			}
			setSubmitLoader(false);
			setLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
			setLoader(false);
		})
	}
	//-------------------------------------------------------------------------------

	const swalFire = (productData) => {


		// set dates array for getting max date
		const arrOfDates = new Array();
		for (var i = 0; i < productData.length; i++) {
			var updatedDate = new Date(productData[i].updatedDate);
			arrOfDates.push(updatedDate)
		}
		//-------

		// get last approved product label 
		var lastProductName_Approved = "";
		var lastProductName_Rejected = "";
		var complianceApproved = 0;
		var MaxDate = new Date(Math.max(...arrOfDates)).toLocaleString('en-us', { hour12: false }); // get max date from array which is the last updated product label

		var RejectReasonText = "";
		for (var i = 0; i < productData.length; i++)
		{
			var updatedDate = new Date(productData[i].updatedDate).toLocaleString('en-us', { hour12: false });

			if (productData[i].complianceApproved == 3 && updatedDate == MaxDate) {
				lastProductName_Approved = productData[i].productName;
				complianceApproved = 3;
				break; // only get last approved label , so break out of loop so its always last approved product
			}

			else if (productData[i].complianceApproved == 90 && updatedDate == MaxDate)
			{
				lastProductName_Rejected = productData[i].productName;
				RejectReasonText = productData[i].rejectReason;
				complianceApproved = 90;
				break;
			}
		} // end loop
		//--------------------------------------

		// Check user's local storage --> activityState --> to see what html to show
		var modalHtml = "";
		var Title = "Lets Continue";
		var modalText = "";

		if (localStorage.getItem("activityState") == 100 && productData[0].brewerProductType != 4) {
			modalHtml = '<div class="col-md-12 text-left"> <input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160;<label class="activeStateLabel125">Enter your Brand information </label><br/><input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160;<label class="activeStateLabel125">Add products and label images </label><br/><input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160; <label class="activeStateLabel125">Submit Licensing Compliance Information </label><br/><input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160; <label class="activeStateLabel125"> Upload label images </label><br/><input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160; <label class="activeStateLabel125"> Approve payment for label submission </label><br/><input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160; <label class="activeStateLabel125"> Review and Sign Distribution Agreement </label><br/><input type="checkbox"/>&#160; <label class="activeStateLabel125"> We will submit everything to the state Licensing board. </label><br/><input type="checkbox"/>&#160; <label class="activeStateLabel125"> Once labels have been approved, Set up first Delivery to Warehouse </label><br/><input type="checkbox"/>&#160; <label class="activeStateLabel125"> Go live </label></div> ';
			Title = "Lets Continue";
			modalText = ""
		}

		else if (localStorage.getItem("activityState") == 100 && productData[0].brewerProductType == 4) {
			modalHtml = '<div class="col-md-12 text-left"> <input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160;<label class="activeStateLabel125">Enter your Brand information </label><br/><input type="checkbox" class="activeStateCheckBox125" checked="checked"/>&#160;<label class="activeStateLabel125">Add products and label images </label> <br/><input type="checkbox"/>&#160; <label class="activeStateLabel125"> Once labels have been approved, Set up first Delivery to Warehouse </label><br/><input type="checkbox"/>&#160; <label class="activeStateLabel125"> Go live </label></div> ';
			Title = "Lets Continue";
			modalText = ""
		}
		else if (localStorage.getItem("activityState") == 150 && complianceApproved == 3) {
			modalHtml = "<label style='font-weight:500'>Congratulations! Your label has been approved for <b>" + lastProductName_Approved + "</b>. You are now ready to go live and start selling.<br/><br/>  Do you need to set up shipment? </label>";
			Title = "Lets Continue";
			modalText = "Do you need to set up shipment?"
		}
		else if (localStorage.getItem("activityState") == 150 && complianceApproved == 90) {
			modalHtml = "<label style='font-weight:500'>The State of Flordia has <b style='color:#fd0000;'>Rejected</b> your Labels for approval for <b>" + lastProductName_Rejected + "</b>. <br/><br/>  For the following reason(s): <br/><br/> <b>" + RejectReasonText + " </b>  <br/><br/> We ask that you correct the Issue(s) above and resubmit labels through the portal. <br/><br/>  If you have any questions, please feel free to reach out to one our our compliance experts at <a>Compliance@bevport.com </a>. Our team is always available to assist you. <br/><br/> Cheers <br/> Team BevPort </label>";
			Title = "Lets Continue";
			modalText = "Do you need to set up shipment?"
		}
		else {
			modalHtml = "";
			Title = "Lets Continue";
			modalText = "You haven’t completed your Brand Set up process. Would you like to continue?"
		}
		//-----------------------------------------------------------------------------


		Swal.fire({
			title: Title,
			text: modalText,
			html: modalHtml,
			icon: 'question',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'YES',
			denyButtonText: 'NO',
			background: '#fff',
			color: '#000',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result) => {


			if (result.isConfirmed) {
				if (localStorage.getItem("activityState") == 25) {
					if (localStorage.getItem("brandID")) {
						Navigate("/brewer/edit-brand/" + localStorage.getItem("brandID"))
					} else {
						Navigate("/brewer/add-brand")
					}
				} else if (localStorage.getItem("activityState") == 50) {
					if (localStorage.getItem("productID")) {
						Navigate("/brewer/edit-product/" + localStorage.getItem("productID"))
					} else {
						Navigate("/brewer/add-product/" + localStorage.getItem("brandID"))
					}

				}
				else if (localStorage.getItem("activityState") == 75) {
					Navigate("/brewer/register/fifthstep")
				}

				else if (localStorage.getItem("activityState") == 100) {

					// update users (local storage) activityState to 125 and update the users activityState in the (database login table)
					handleActivityState(125);
				}
				else if (localStorage.getItem("activityState") == 150 && complianceApproved == 3) {

					// update users (local storage) activityState to 175 and update the users activityState in the (database login table)--->
					// if user clicks yes take them to set up delivery page and set activity state to 175 or 0
					handleActivityState(175);
					Navigate("/brewer/setup-delivery/");
				}

				else if (localStorage.getItem("activityState") == 150 && complianceApproved == 90) {

					// update users (local storage) activityState to 175 and update the users activityState in the (database login table)--->
					// if user clicks yes take them to set up delivery page and set activity state to 175 or 0
					handleActivityState(175);
				}



				localStorage.removeItem("ShowPopUp");
			} else if (result.isDenied) {
				Navigate("#")

				if (localStorage.getItem("activityState") == 150 && complianceApproved == 3) {
					swalFireConfirm();
				}
				localStorage.removeItem("ShowPopUp");
			}
		})
	}



	const swalFireConfirm = () => {

		// Check user's local storage --> activityState --> to see what html to show
		var modalHtml = "";
		var Title = "Lets Continue";
		var modalText = "";


		if (localStorage.getItem("activityState") == 150) {
			modalHtml = "<label style='font-weight:500'>“Please be sure to set up delivery of your product to our warehouses, Until you do so, retailers will not be able to order product.” <br/><br/> Do you still want to wait on setting up your delivery? </label>";
			Title = "Lets Continue";
			modalText = "Do you need to set up shipment?"
		}
		else {
			modalHtml = "";
			Title = "Lets Continue";
			modalText = "You haven’t completed your Brand Set up process. Would you like to continue?"
		}
		//-----------------------------------------------------------------------------


		Swal.fire({
			title: Title,
			text: modalText,
			html: modalHtml,
			icon: 'question',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'YES',
			denyButtonText: 'NO',
			background: '#fff',
			color: '#000',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result) => {


			if (result.isConfirmed) {

				if (localStorage.getItem("activityState") == 150) {


				}


				localStorage.removeItem("ShowPopUp");
			} else if (result.isDenied) {
				Navigate("#")

				if (localStorage.getItem("activityState") == 150) {
					// update users (local storage) activityState to 175 and update the users activityState in the (database login table)--->
					// if user clicks yes take them to set up delivery page and set activity state to 175 or 0
					handleActivityState(175);
					Navigate("/brewer/setup-delivery/");
				}
				localStorage.removeItem("ShowPopUp");
			}
		})
	}


	const Handle_ActivityState150_PopUp = async () => {
		const formData = {
			"UserID": localStorage.getItem("id")
		}
		//setSubmitLoader(true);
		await axios.post(API_URL + "GetProductDetailsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;




				// set dates array for getting max date
				const arrOfDates = new Array();
				for (var i = 0; i < data.length; i++) {
					var updatedDate = new Date(data[i].updatedDate);
					arrOfDates.push(updatedDate)
				}
		//-------

				// get last approved products - complianceApproved
				var complianceApproved = 0;
				var MaxDate = new Date(Math.max(...arrOfDates)).toLocaleString('en-us', { hour12: false }); // get max date from array which is the last updated product label

				var RejectReasonText = "";
				for (var i = 0; i < data.length; i++) {
					var updatedDate = new Date(data[i].updatedDate).toLocaleString('en-us', { hour12: false });

					if (data[i].complianceApproved == 3 && updatedDate == MaxDate) {
						complianceApproved = 3;
						break; // only get last approved label , so break out of loop so its always last approved product
					}

					else if (data[i].complianceApproved == 90 && updatedDate == MaxDate) {
						RejectReasonText = data[i].rejectReason;
						complianceApproved = 90;
						break;
					}
				} // end loop
		//--------------------------------------




				if (activityState == 150 && complianceApproved == 90 || complianceApproved == 3) {
					setIsDisabled(true);
					swalFire(data);
				}

			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}



	const Handle_ActivityState_0_To_100_PopUp = async () => {
		const formData = {
			"UserID": localStorage.getItem("id")
		}
		//setSubmitLoader(true);
		await axios.post(API_URL + "GetProductDetailsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
			
					setIsDisabled(true);
					swalFire(data);			

			} else {
				// if no product data or error send swalfire modal window blank ---> product data
				var ProductBlankData = new Array();
				swalFire(ProductBlankData);
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const handleActivityState = async (activityStateNumber) => {
		let formData = {
			UserID: localStorage.getItem("id"),
			ActivityState: activityStateNumber
		}
		await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				setSubmitLoader(false);
				localStorage.setItem("activityState", activityStateNumber);
				//navigate("/brewer/add-product/" + localStorage.getItem("brandID"));
			} else {
				toast.error(res.data.responseMessage);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
		})
	}




	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = agreementURL;
		link.download = 'distributor-agreement-files.pdf'; // Specify the desired filename for the downloaded PDF
		link.style.display = 'none';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// profile image click event
	const ProfileImageClick = () => {

		var profileLink = "";
		profileLink = "http://" + window.location.host + "/brewer/profile";

		window.location.href = profileLink;
	};


	// product image click event
	const ProductImageClick = () => {

		var profileLink = "";
		profileLink = "http://" + window.location.host + "/brewer/product-list";

		window.location.href = profileLink;
	};


	// buyer image click event
	const BuyerImageClick = () => {

		var profileLink = "";
		profileLink = "http://" + window.location.host + "/brewer/mybuyers";

		window.location.href = profileLink;
	};


	// Sales Service Button Click
	const SalesServicesBtnClick = () => {

		var profileLink = "";
		profileLink = "http://" + window.location.host + "/brewer/salesservices";

		window.location.href = profileLink;
	};


	const ViewAsBuyer = () => {
	
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/brewer/brewerbuyerview";

		window.location.href = profileLink;
	};





	return (
		<>
			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} />
					
					<div className="rightContetBlock">
						
						<div className="headingTop">
							<h1>Brewers Dashboard</h1>
						</div>
						
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<div className="brewerDashboardInner">


										<div className="row">
											<div className="col-md-12">
												<div>
													<span id="retailerListButton" onClick={exportToExcel} className="pull-left viewRetailListButton"> <FileDownloadIcon />  Retailer List</span>
												</div>

											
												<div className="tooltip_viewasBuyer">
													<span id="viewAsBuyerButton" onClick={ViewAsBuyer} className="pull-right viewAsBuyerButton"> <VisibilityIcon />  View as a Buyer</span>
													<span className="tooltiptext_viewasBuyer text-right pull-right">View your brand as a buyer would see it.</span>
												</div>

											</div>
										</div>


										<div className="row">
											<div className="col-md-12">
												<h2 className="dashboardMainbuyer">Craft Your Beer Brand’s <span>Success on BevPort!</span></h2>
											</div>
										</div>
										<div className="row MainBoxSIde">
											<div className="col-md-12">
												<div className="row threeMainBoxe">
													<div className="col-lg-4 col-md-6">
														<h3 className="yourOrderheadings">MY SALES</h3>
														<div className="productFoourCard">
															<Bar options={options} data={data} />

															<div className="orderHisBtn">
																<Link to={(!isDisabled) ? "/brewer/order-history" : "#"}>VIEW ALL SALES</Link>
															</div>
														</div>
														<p className="cardParas">
															An overview of your sales performance. Analyze sales trends to gain valuable insights for business growth and optimization.
														</p>
													</div>
													<div className="col-lg-4 col-md-6">
														<h3 className="yourOrderheadings">INVENTORY</h3>
														<div className="productFoourCard InventoryCard">
															<Link to={(!isDisabled) ? "/brewer/inventory" : "#"}>YOUR<br />INVENTORY</Link>
															<Link to={(!isDisabled) ? "/brewer/setup-delivery-list" : "#"}>PLACE DELIVERY ORDER</Link>
															<Link to="#">PRODUCT DELIVERY LIVE VIEW</Link>
														</div>
														<p className="cardParas">
															The hub for inventory management, delivery scheduling, and real-time tracking of your products during transit, to enhance operational efficiency and customer communication.
														</p>
													</div>
													<div className="col-lg-4 col-md-6">
														<h3 className="yourOrderheadings">ORDERS</h3>
														<div className="orderDetialsListing">
															<ul>
																{
																	orderList.map((_value, _index) => {
																		if (_index < 7) {
																			return (
																				<li><Link to={(!isDisabled) ? "/brewer/order-detail/" + _value?.orderID : "#"} target="_blank"><span className="datesOrder">{moment(_value?.createdDate).format("MM-DD-YYYY")}</span><span className="orderIdOrder">#{((_value?.orderID).replace("BEVPORT", "")).substr(0, 9)}</span><span className="orderDallrPrice">${parseFloat(_value?.amount).toFixed(2)}</span></Link></li>
																			)
																		}

																	})
																}

															</ul>
															<div className="orderHisBtn">
																<Link to={(!isDisabled) ? "/brewer/order-history" : "#"}>VIEW ALL ORDERS</Link>
															</div>
														</div>
														<p className="cardParas">
															Your organized list of all customer orders. It provides a clear view of what’s been ordered, enabling efficient order processing and management.
														</p>

													</div>

												</div>

												<div className="row threeMainBoxe btmpostions">
													<div className="col-md-4">
														<h3 className="yourOrderheadings">MY PROFILE</h3>
														<div className="productFoourCard profileimgBlocks">
															<div onClick={ProfileImageClick} className="priflePicdata">
																<img src={MyProfilePic} />
															</div>
														</div>
														<p className="cardParas">
															View and edit your personal details, adjust account settings,and manage team member roles. Ensure accuracy to guarantee efficient team operations.
														</p>
													</div>
													<div className="col-md-4">
														<h3 className="yourOrderheadings">MY PRODUCTS</h3>
														<div className="productFoourCard profileimgBlocks">
															<div onClick={ProductImageClick} className="priflePicdata">
																<img src={Canareas} />
															</div>
														</div>
														<p className="cardParas">
															Update product details, add new offerings, or insert additional collateral. Detailed product information assists buyers in making informed decisions.
														</p>
													</div>
													<div className="col-md-4">
														<h3 className="yourOrderheadings">BUYERS</h3>

														<div id="blankBuyerListImage" class="blankBuyerListImage productFoourCard profileimgBlocks">
															<div onClick={BuyerImageClick} class="priflePicdata">
																<img src={buyerBlankList} />
															</div>
														</div>

														<div id="notBlankBuyerListImage" className="notBlankBuyerListImage hidden orderDetialsListing">

															<ul>
																{
																	buyerList.map((_v, _o) => {
																		return (
																			<li>{_o + 1}. {_v?.firstName + ' ' + _v?.lastName} {(_v?.state) ? ", " + _v?.state : ""}</li>
																		)
																	})
																}
															</ul>
															<div className="orderHisBtn">
																<Link to={(!isDisabled) ? "/brewer/mybuyers" : "#"}>VIEW ALL BUYERS</Link>
															</div>
														</div>
														<p className="cardParas">
															Your comprehensive list of buyers, providing a quick overview of your customer base and allowing for effective relationship management.
														</p>

													</div>

												</div>

											{/*	// ---------------------Sales Services -----------ROW---------------*/}
												<div className="row threeMainBoxe btmpostions">
													<div className="col-md-4">
														<h3 className="yourOrderheadings">Sales Services</h3>
														<div className="productFoourCard profileimgBlocks">
															<div onClick={SalesServicesBtnClick} className="priflePicdata">
																<img src={SalesServices1} />
															</div>
														</div>

														<p className="cardParas">
															Do you need Feet on the street in Florida selling your brand directly to retailers? We got you.
														</p>

														<div>
														&nbsp;
														</div>


														{/*<div className="text-center">*/}
														{/*	<button onClick={SalesServicesBtnClick} className="SalesServicesBtn">View Sales Services</button>*/}
              {/*                                          </div>*/}
													
														{/*<Link to={(!isDisabled) ? "/brewer/SalesServices" : "#"}>YOUR<br />INVENTORY</Link>*/}
														
													</div>
												</div>


											</div>


											



											{/*// get rid of later */}

											{/*<div className="col-md-3 fiexedRightCols">*/}
											{/*	<h3 className="yourOrderheadings">MY TASKS</h3>*/}
											{/*	<div className="productFoourCard Fixedright">*/}
											{/*		<ul>*/}
											{/*			<li className="active"><span className="liTExtdata">Complete User Profile</span></li>*/}
											{/*			<li><span className="liTExtdata">Add Company Logo</span></li>*/}
											{/*			<li className="active"><span className="liTExtdata">Add New Products</span></li>*/}
											{/*			<li><span className="liTExtdata">Add New Categories</span></li>*/}
											{/*			<li><span className="liTExtdata">Update Inventory</span></li>*/}
											{/*			<li><span className="liTExtdata">Add Collateral Pdf</span></li>*/}
											{/*			<li><span className="liTExtdata">Low Inventory Alert: 10 Cases Left Of Ale</span></li>*/}

											{/*			*/}{/*// might put this in later for tasks that need to be completed when user clicks yes on active state 125*/}


											{/*			<li><span className="liTExtdata">We will submit everything to the state Licensing board.</span></li>*/}
											{/*			<li><span className="liTExtdata">Once labels have been approved, Set up first Delivery to Warehouse</span></li>*/}
											{/*			<li><span className="liTExtdata">Go Live</span></li>*/}


											{/*		</ul>*/}

											{/*	</div>*/}



											{/*	*/}{/*// comment out download user distribution agreement button */}
											{/*	<div className="fixed-bottombtns">*/}
											{/*		{(agreementURL != "") ? <button className='btns' onClick={handleDownload} >Download Distribution Agreement</button> : ""}*/}
											{/*	</div>*/}
											{/*</div>*/}






										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}