import Dashboard from '../components/team-members/dashboard';
import ProductList from '../components/team-members/ProductList';
import OrderHistoryPage from '../components/team-members/OrderHistory';
import Inventory from '../components/team-members/Inventory';
import BuyerList from '../components/team-members/BuyerList';
import Profile from '../components/team-members/Profile';
import CartList from '../components/team-members/cartList';
import WishList from '../components/team-members/WishList';
import OrderDetail from '../components/team-members/OrderDetails';
import OrderHistoryBuyer from '../components/team-members/OrderHistoryBuyer';
const route = [
  { path: '/team-member/product-list', Component: ProductList },
  { path: '/team-member/profile', Component: Profile},
  // { path: '/team-member/product-detail/:productID', Component: ProductDetail},
  // { path: '/team-member/edit-profile', Component: EditProfile},
  // { path: '/team-member/change-password', Component: ChangePassword},
  { path: '/team-member/order-history', Component: OrderHistoryPage },
  { path: '/team-member/inventory', Component: Inventory},
  { path: '/team-member/order-detail/:orderID', Component: OrderDetail},
  { path: '/team-member/dashboard', Component: Dashboard },
  // { path: '/team-member/inventory-edit/:productID', Component: EditInventory},
  { path: '/team-member/mybuyers', Component: BuyerList},
  { path: '/team-member/my-cart', Component: CartList},
  { path: '/team-member/wishlist', Component: WishList},
  { path: '/team-member/order-history-buyer/:buyerID', Component: OrderHistoryBuyer},
  { path: '/team-member/order-detail-buyer/:orderID', Component: OrderDetail},
]
export default route;
