import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function AssociateSaleRep() {
    document.title = "BevPort - Associate Sales Rep"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    const [submitLoader, setSubmitLoader] = useState(true);
    const [data, setData] = useState([])
    const [tmpData, setTmpData] = useState([])
    const { SalesRepID } = useParams();
    const [jsonData, setJsonData] = useState([])
    useEffect(() => {
        getBrewerList();
    }, [])
    let Navigate = useNavigate();
    const getBrewerList = async () => {
        let formData = {
            SalesRepID: SalesRepID
        }
        await axios.post(API_URL + "GetBrewerBrandList?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode == "200") {
                let data = res.data.responseData
                setData(data);
                setTmpData(data)
                let brData = []
                data.forEach((_o, _i) => {
                    _o?.brandListData.length && _o?.brandListData.forEach((_l, _p) => {
                        if (_l?.isAssociated) {
                            let tempData = {
                                "BrewerID": _l?.userID,
                                "SalesRepID": SalesRepID,
                                "BrandID": _l?.id,
                                Status: "1"
                            }
                            brData.push(tempData)
                        }
                    })
                })
                setJsonData(brData)
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const [brandList, setBrandList] = useState([])
    const [activeTab, setActiveTab] = useState("")
    const getBrandList = (userID) => {
        setActiveTab(userID)
        let d = data.filter((v) => {
            return v?.userID === userID
        })
        if (d.length) {
            setBrandList(d[0]?.brandListData);
        } else {
            setBrandList([]);
        }
    }

    const handleCheckBox = (e, data) => {
        if (e.target.checked) {
            let f = { "BrewerID": data?.userID, "SalesRepID": SalesRepID, "BrandID": data?.id, Status: "1" }
            setJsonData([...jsonData, f])
            let dd = brandList.map((x, y) => {
                if (x?.id === data?.id) {
                    x.isAssociated = 1;
                }
                return x;
            })
            setBrandList(dd)
        } else {
            let index = jsonData.findIndex(x => x.BrandID === data?.id);
            jsonData[index].Status = 0
            //jsonData.splice(index, 1);
            let dd = brandList.map((x, y) => {
                if (x?.id === data?.id) {
                    x.isAssociated = 0;
                }
                return x;
            })
            setBrandList(dd)
        }
    }
    const handleValidate = () => {
        let fomValid = true;
        if (!jsonData.length || !activeTab) {
            fomValid = false;
            toast.error("Select atleast one brand")
        }
        return fomValid;
    }
    const handleSubmit = async () => {
        if (handleValidate()) {
            let formData = jsonData;
            setSubmitLoader(true);
            await axios.post(API_URL + "InsertBrewerSalesRep?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode == "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/salesrep-list");
                }
                setSubmitLoader(false);
            }).catch(function (error) {
                setSubmitLoader(false);
            })
        }
    }
    const checkBrandData = (bData, string) => {
        let d = bData.filter((item) => {
            if ((item?.brandName).toLowerCase().includes((string).toLowerCase())) {
                return item;
            }
        });
        return d;
    }
    const handleSearchBox = (e) => {
        setBrandList([])
        const filterBySearch = tmpData.filter((item) => {
            console.log(item?.brandListData)
            if ((item?.brewerFirstName + " " + item?.brewerLastName).toLowerCase().includes((e.target.value).toLowerCase()) || checkBrandData(item?.brandListData, e.target.value).length) {
                return item;
            }
        })
        setData(filterBySearch)
    }
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Associate SalesRep</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="searchOntopPage">
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="searchBox">
                                            <div className="input-group">
                                                <div className="input-group-btn">
                                                    <button className="btn btn-default" type="submit"><i className="glyphicon glyphicon-search"></i></button>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Search" onChange={handleSearchBox} />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    {
                                        (data.length) ?
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-group list-height-data">
                                                        {
                                                            data.map((_v, _i) => {
                                                                return (
                                                                    <li className={activeTab === _v?.userID ? "list-group-item active" : "list-group-item"} onClick={() => getBrandList(_v?.userID)} key={_i}>{_v?.businessName}</li>
                                                                )
                                                            })

                                                        }
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        brandList.length ?
                                                            <>
                                                                <ul className="list-group">
                                                                    {
                                                                        brandList.map((_v, _i) => {
                                                                            return (
                                                                                <li className="list-group-item" key={_i}><input type="checkbox" onChange={(e) => handleCheckBox(e, _v)} checked={_v?.isAssociated} /> &nbsp; {_v?.brandName}</li>
                                                                            )
                                                                        })

                                                                    }
                                                                </ul>
                                                            </> : "No brand found!"
                                                    }

                                                </div>
                                            </div> : "No Data Found!"}
                                </div>
                                {(data.length) ? <button className='btn btn-primary' onClick={handleSubmit}> Submit</button> : ""}

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}