import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import BrewerSideBar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BuyerList(){
	document.title = "BevPort - Buyer List"
	const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)

	
	
	const collapseSidebar = () =>{
		setToggle(!toggle)
	}

	const handleStatus = async(status, id) =>{
		let formData = {
			"ID": id,
			"Status":status
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeUserStatus?code=" + API_KEY, formData, config).then((res) => {
		if(res.data.responseCode == "200"){
				toast.success(res.data.responseMessage, {
					autoClose: 3000, //3 seconds
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					
				  });
                  getBuyerList()
				  setLoader(false);
			}else{
				toast.error(res.data.responseMessage)	
			}
		}).catch(function (error) {

		})	
	}
	const alertFunction = () => {
		toast.error("Please activate brand before adding products")
	}
	const columns = [
	    {
	        name: 'Name',
	        selector: row => (row.firstName +" "+ row.lastName).toLowerCase(),
	        cell: row => row.firstName +" "+ row.lastName,
			sortable: true,
        
	    },
	    {
	        name: 'E-mail',
	        selector: row => row.emailID,
			sortable: true,
            width: '250px',
           
	    },
        {
	        name: 'Phone No.',
	        selector: row => row.businessPhone,
			sortable: true,
          
	    },
        {
	        name: 'Business Name',
	        selector: row => (row.businessName).toLowerCase(),
			cell: row => row.businessName,
			sortable: true,
           
	    },
        
        {
	        name: 'Address',
	        selector: row => row.streetAddress +", " + row.city +", " + row.stateName +", " +row.zipCode +", " + "USA",
			sortable: true,
            wrap: true
           
	    },
	    {
	        name: 'Action',
			width: '150px',
	        selector: row => <>
			<Link className="btn btn-primary" to={"/brewer/order-history-buyer/" + row.id} >VIEW ORDER</Link> </>,
	    }
	];
	const [data, setData] = useState([])
	useEffect(() => {
		getBuyerList()
	},[])
	const getBuyerList = async () => {
		let formData = {
			ID: localStorage.getItem("id")
		}
		await axios.post(API_URL + "GetBuyersList?code=" + API_KEY, formData, config).then((res) => {
			if(res.data.responseCode == "200"){
				setData(res.data.responseData);
			
			}else{
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const tableData = {
        columns,
        data
    };
	return(
		<>
		{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper brewersDashArea">
				<BrewerSideBar collapse={toggle}/>
				<div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
				{submitLoader ? <Loader /> : ""}
				{loader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar}/>
					<div className="rightContetBlock">
						<div className="headingTop">
						<h1>Buyer List</h1>
						</div>
						<div className="blank150"></div>
						
						<div className="fixtopContentBg">
							<div className="card-Content">
								
								<div className="Content-body dataTableSearchBox">
									<DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Buyers">
                                    <DataTable
							            columns={columns}
							            data={data}
										noDataComponent={<span className='norecordFound'>No record found!</span>}
										defaultSortFieldId={1}
							        />
							        </DataTableExtensions>
								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}