import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Slide, ToastContainer, toast } from 'react-toastify';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()

export default function StripePaymentCard({payableAmount, postData, loaderFunc, submitFunction}){
	const [nameCard, setNameCard] = useState("")
	const [nameCardError, setNameCardError] = useState(false)
	const [cardNumberError, setCardNumberError] = useState(false)
    const [cardNumberErrorText, setCardNumberErrorText] = useState("")
    const [cardExpireError, setCardExpireError] = useState(false)
    const [cardExpireErrorText, setCardExpireErrorText] = useState("")
    const [cardCvvError, setCardCvvError] = useState(false)
    const [cardCvvErrorText, setCardCvvErrorText] = useState("")
	const stripe = useStripe()
    const elements = useElements()
    const generateToken = async () => {
    	loaderFunc(true)
        const card = elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement);
        const {error, token} = await stripe.createToken(card);
        //console.log(error, token)
        if(!error){
        	submitFunction(token)
        	//loaderFunc(false)
          }else{
            toast.error(error?.message)
            loaderFunc(false)
          }
    }
    const stripeElementChange = (e) => {
        setCardNumberError(false)
        if (!e.complete) {
            setCardNumberError(true)
            setCardNumberErrorText(e?.error?.message)
        }
    }
    const stripeCvvChange = (e) => {
        setCardCvvError(false)
        if (!e.complete) {
            setCardCvvError(true)
            setCardCvvErrorText(e?.error?.message)
        }
    }
    const stripeExpireChange = (e) => {
        setCardExpireError(false)
        if (!e.complete) {
            setCardExpireError(true)
            setCardExpireErrorText(e?.error?.message)
        }
    }
	const handleNameCard = (e) => {
	    const regex = /^[a-zA-Z ]*$/;
	    const value = e.target.value;
	   	if (regex.test(value)) {
	     	setNameCard(value);
	      	setNameCardError(false);
    	}
  	}
	return(
		<>
			<div className="col-lg-4 col-md-4 col-sm-12 col-12">
				<div className="list-items">
					<div className="row heading">
						<div className="col-md-12">
							<div className="cartHeadings">
							<h4 className="main-head">Card Details</h4>
						</div>
						</div>						
					</div>

					<div className="InsertForms">
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label className="brand-tab">Name on Card<span className="red-tick"> *</span></label>
									<input type="text" className="form-control" placeholder="Name on Card" value={nameCard} onChange={handleNameCard} />
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label className="brand-tab">Card Number<span className="red-tick"> *</span></label>
									<CardNumberElement className={cardNumberError?"form-control error":"form-control"} onChange={stripeElementChange} />
                            		{cardNumberError?<span className="error-message">{cardNumberErrorText}</span>:""}
								</div>
							</div>	
						</div>
						<div className="row">
							
							<div className="col-md-6">
								<div className="form-group">
									<label className="brand-tab">Expiry Date<span className="red-tick"> *</span></label>
									<CardExpiryElement className={cardExpireError?"form-control error":"form-control"} onChange={stripeExpireChange}/>
                            		{cardExpireError?<span className="error-message">{cardExpireErrorText}</span>:""}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label className="brand-tab">CVC/VV<span className="red-tick"> *</span></label>
									<CardCvcElement  className={cardCvvError?"form-control error":"form-control"} onChange={stripeCvvChange}  />
                            		{cardCvvError?<span className="error-message">{cardCvvErrorText}</span>:""}
								</div>
							</div>
						</div>
						<div className="row m">													
							<div className="col-lg-6 col-sm-6 col-md-6 col-6"><b>Total</b></div>
							<div className="col-lg-6 col-sm-6 col-md-6 col-6 text-right">${payableAmount}</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="formBtns btn-spaces">
									<button type="button" className="btn btn-primary save-button btn-block" onClick={generateToken}>Pay Now</button>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	)
}