

import LoaderLogo from "./../../assets/images/loader-logo.png"
export default function Loader(){
	return(
		<>
		<div className="loaderbfg">
			<div className="loadercompnen">
			  <div className="spinner-border" role="status"></div>
			  <span className="loaderimg"><img alt="Brand" src={LoaderLogo}/></span>
			</div>
			</div>
		</>
	)
}