import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../../front/Loader";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function States() {
    document.title = "BevPort - Package Size"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([]);
    const [packSize, setPackSize] = useState("")
    const [packSizeError, setPackSizeError] = useState(false)
    const [packSizeID, setPackSizeID] = useState("")
    const [buttonName, setButtonName] = useState("Submit")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        getPackSizeData();
    }, [])

    const handleStatus = async (status, id) => {
        setLoader(true);
        let formData = {
            "ID": id,
            "Status": status
        }
        await axios.post(API_URL + "ChangePackageSizeStatus?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getPackSizeData();
                toast.success(res.data.responseMessage)
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }


    const getPackSizeData = async () => {
        await axios.post(API_URL + "GetAllPackageSizeData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setData(res.data.responseData)
                console.log("data", res.data.responseData);
                setSubmitLoader(false);
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    const columns = [
        {
            name: 'Pack Size',
            selector: row => (row.value).toLowerCase(),
            cell: row => row.value,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == "0") ? <span style={{ "color": "#00aeef" }}>Inactive</span> : <span style={{ "color": "#00aeef" }}>Active</span>,
            sortable: true,
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "0") ? "1" : "0", row.id)}>{(row.status == "1") ? "DEACTIVATE" : "Activate"}</Link>&nbsp;<Link className="btn btn-primary" to={"#"} onClick={() => getPackSizeInfo(row.id)}>Edit</Link>&nbsp;</>,
        }
    ];
    const handlePackSize = (e) => {
        setPackSizeError(false)
        setPackSize(e.target.value)
    }
    const handleValidation = () => {
        let formValid = true
        if (!packSize) {
            setPackSizeError(true)
            formValid = false
        }
        return formValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            if (packSizeID) {
                updatePackSizeRecord()
            } else {
                insertPackSizeRecord()
            }
        }
    }
    const insertPackSizeRecord = async () => {
        setLoader(true);
        let formData = {
            "Value": packSize,
            "Status": "1"
        }
        await axios.post(API_URL + "AddPackageSizeData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getPackSizeData();
                toast.success(res.data.responseMessage)
                setPackSize("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const getPackSizeInfo = async (id) => {
        setLoader(true);
        let formData = {
            "ID": id
        }
        await axios.post(API_URL + "GetPackageSizeInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                window.scrollTo(0, 0)
                let data = res.data.responseData
                console.log("indfoData", data);
                setPackSize(data?.value)
                setButtonName("Update")
                setPackSizeID(data?.id)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const updatePackSizeRecord = async () => {
        setLoader(true);
        let formData = {
            "ID": packSizeID,
            "Value": packSize
        }
        await axios.post(API_URL + "UpdatePackageSizeData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getPackSizeData();
                toast.success(res.data.responseMessage)
                setPackSize("")
                setButtonName("Submit")
                setPackSizeID("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }

    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Package Size</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className='wizardShowOnly'>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <form className="form" onSubmit={handleSubmit}>

                                                    <div className="form-group mx-sm-3 mb-2">
                                                        <label for="inputPassword2" >Package Size</label>
                                                        <input type="text" className={packSizeError ? "form-control error" : "form-control"} placeholder="Package Size" onChange={handlePackSize} value={packSize} />
                                                    </div>
                                                    <button type="submit" className="btn btn-primary mb-2">{buttonName}</button>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='tbaShowcase'>
                                        <div className="Content-body dataTableSearchBox">
                                            <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Package Size">
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                   // defaultSortFieldId={1}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}