import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BASE_URL } from './../../config/constant';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams,useNavigate } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import LogoInvoice from './../../assets/images/logo.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from "qrcode.react";
import moment from 'moment';
import Select from 'react-select';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function OrderDetail() {
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: 'transparent',
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                border: '1px solid #b6b6af',
                boxShadow: null,
            },
            '&:focus': {
                border: '1px solid transparent',
                boxShadow: null,
            }
        })
    };


    let { orderID } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [loader, setLoader] = useState(false)
    const [encyptedOrderID, setEncryptedOrderID] = useState("");
    const [ssdList, setSsdList] = useState([]);
    const [ssdValue, setSsdValue] = useState(null);
    const [truckNumber, setTruckNumber] = useState("");
    const [carrierName, setCarrierName] = useState("");
    const [ssdError, setSsdError] = useState(false);
    const [info , setInfo] = useState([]);
    let componentRef = useRef();
    document.title = "#" + orderID
    useEffect(() => {
        getOrderDetail();
        getSSDList();
        getInfo();
    }, [])

    const getOrderDetail = async () => {
        await axios.post(API_URL + "GetOrderDetails?code=" + API_KEY, { OrderID: orderID }, config).then((res) => {
            if (res.data.responseCode == "200") {
                setData(res.data.responseData);
                setEncryptedOrderID(res.data.responseData?.[0]?.encryptOrderID);
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const getSSDList = async () => {
        await axios.post(API_URL + "GetSSDList?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode == "200") {
                let brandTypeOption = []
                let resData = res.data.responseData.length? res.data.responseData[0]:""
                console.log(resData);
                setTruckNumber(resData?.vehicleNumber);
                setCarrierName(resData?.courierName)
                resData.forEach((da) => {
                    brandTypeOption.push({ value: da.courierName, label: da.courierName, id: da.id, vehicleNumber: da.vehicleNumber });
                }
                );
                setSsdList(brandTypeOption);
            } else {

            }
        }).catch(function (error) {

        })
    }

    const handleSSD = (e) => {
       
        setSsdValue({ value: e.value, label: e.label, id: e.id })
        setTruckNumber(e.vehicleNumber);
        setCarrierName(e.label)
        setSsdError(false);

    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!ssdValue) {
            formIsValid = false;
            setSsdError(true);
        }
        return formIsValid;
    }

    const CreateBOL = async () => {
        if (handleValidation()) {
            setLoader(true)
            await axios.post(API_URL + "UpdatePickListStatus?code=" + API_KEY, { "ID": orderID,"SSDID": 1 }, config).then((res) => {
               
                if (res.data.responseCode == "200") {
                    toast.success(res.data.responseMessage, {
                        autoClose: 3000, //3 seconds
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,

                    });

                    navigate("/admin/order-bol");

                } else {
                    //setData([])
                }
                setLoader(false);
            }).catch(function (error) {
                setLoader(false);
            })
        }
    }

    const getInfo = async () => {
        await axios.post(API_URL + "GetPickListOrderData?code=" + API_KEY, {"OrderID" : orderID}, config).then((res) => {

            if (res.data.responseCode == "200") {
                 setInfo(res.data.responseData);
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    var QRValue = BASE_URL + "/view-invoice/" + encyptedOrderID + "/" + localStorage.getItem('id');

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Create BOL</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="invoicePagesWrapper">
                                    <div className="wizardShowOnly">
                                        <div className="wizardShowOnly" ref={(el) => (componentRef = el)}>
                                            <div className="row invoiceHeader">
                                                <div className="col-md-6">
                                                    <div className="LogoInvoice">
                                                        <img src={LogoInvoice} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="InvoiceTextHead">
                                                        <h3>Pick List</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row orderHeader">
                                                <div className="col-md-6">
                                                    {/*<div className="helloAreas">
                                                        <div> <label className="brand-tab"> SSD <span className="red-tick">*</span></label></div>
                                                        <Select
                                                            className={ssdError ? "selectTodesign error" : "selectTodesign"}
                                                            styles={customStyles}
                                                            options={ssdList}
                                                            onChange={handleSSD}
                                                            value={ssdValue}
                                                            closeMenuOnSelect={true} />
                                                    </div>*/}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="orderIdText">
                                                        <p>PICK LIST ID: <span className="orderNumbersId">#{data?.[0]?.orderID}</span></p>
                                                        <p>{moment(data?.[0]?.createdDate).format('MM-DD-YYYY HH:mm')}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row informationBiligns">
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>SHIP FROM</h3>
                                                        <div className="callAdress samllwidth">
                                                        <p>{info?.[0]?.wareHouseName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="billingImforArea">
                                                        <p ><span className='orderNumbersId' >CARRIER NAME: </span> {carrierName}</p>

                                                        <div className="callAdress">
                                                            <p ><span className='orderNumbersId' >Trailer Number: </span> {truckNumber}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="billingImforArea qr-code">
                                                        {/* <QRCode value={QRValue} /> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row informationBiligns Shippinhind">
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>SHIP TO</h3>
                                                        <div className="callAdress samllwidth">
                                                       <p> {info?.[0]?.buyerAddress}, {info?.[0]?.cityName}, {info?.[0]?.stateName}, {info?.[0]?.countryName} {info?.[0]?.zipCode}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row puchaseHistoryTable">
                                                <div className="col-md-12">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="40%">Item</th>
                                                                <th className="brewerNames" scope="col" >Business Name</th>
                                                                <th className="qtyTextr" scope="col" width="20%">Quantity In Case</th>
                                                                <th className="amountDallrs" scope="col" width="20%">Amount(In $)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((_v, _i) => {
                                                                return [
                                                                    <tr>
                                                                        <td>
                                                                            <span className="mainPrName">{_v?.productName}</span>
                                                                            <span className="brandNameSm">{_v?.brandName}</span>
                                                                        </td>
                                                                        <td className="brewerNames">{_v?.businessName}</td>
                                                                        <td className="qtyTextr">{_v?.quantity}</td>
                                                                        <td className="amountDallrs">{parseFloat(_v?.amount).toFixed(2)}</td>
                                                                    </tr>
                                                                ]
                                                            })}


                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="qtyTextr grandtotals">Grand Total</td>
                                                                <td className="amountDallrs finalPrice">{parseFloat(data?.[0]?.finalPrice).toFixed(2)}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row Prinatblebtn">
                                            <div className="col-md-6">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={CreateBOL}>Save</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default OrderDetail