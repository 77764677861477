
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
export default function SripePaymentCard({handleMemberPlan, memberShipError, cardNumberError, stripeElementChange, cardNumberErrorText, cardExpireError, stripeExpireChange, cardExpireErrorText, cardCvvError, stripeCvvChange, cardCvvErrorText, nameCardError, handleNameCard, handleTokenData, handleTokenFun, handleStripeForm}){
    const [planList, setPlanList] = useState([]);
    useEffect(() => {
        getPlanDetails()
    }, [])
    const getPlanDetails = async () => {
        await axios.post(API_URL + "GetPlans?code=" + API_KEY, { Type: process.env.REACT_APP_ENV }, config).then((res) => {
            if (res.data.responseCode === "200") {
                setPlanList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    const stripe = useStripe()
    const elements = useElements()
    const generateToken = async () => {
        const card = elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement);
        const {error, token} = await stripe.createToken(card);
        handleTokenData(error, token)
    }
    const checkForm = async () => {
        const card = elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement);
        const {error, token} = await stripe.createToken(card);
    } 
    useEffect(() => {
        if(handleTokenFun){
            generateToken()
        }
    },[handleTokenFun])
    useEffect(() => {
        if(handleStripeForm){
            checkForm();
        }
    },[handleStripeForm])
    return(
        <>
                
                
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="firstname">Card Number <span className="red-tick">*</span></label>
                            <CardNumberElement className={cardNumberError?"form-control error":"form-control"} onChange={stripeElementChange} />
                            {cardNumberError?<span className="error-message">{cardNumberErrorText}</span>:""}
                            
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="firstname">Expiration Date <span className="red-tick">*</span></label>
                            <CardExpiryElement className={cardExpireError?"form-control error":"form-control"} onChange={stripeExpireChange}/>
                            {cardExpireError?<span className="error-message">{cardExpireErrorText}</span>:""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="firstname">CVC / CVV <span className="red-tick">*</span></label>
                            <CardCvcElement  className={cardCvvError?"form-control error":"form-control"} onChange={stripeCvvChange}  />
                            {cardCvvError?<span className="error-message">{cardCvvErrorText}</span>:""}
                            
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="firstname">Name on Card <span className="red-tick">*</span></label>
                            <input type="text" className={nameCardError?"form-control error":"form-control"} placeholder="Name on Card" onChange={handleNameCard}/>
                        </div>
                    </div>
                </div>
            </>
    )
}