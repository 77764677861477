import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function Dashboard(){
    document.title = "BevPort - Dashboard"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    useEffect(() => {
        getDashboardData()
    },[])
    const [orderList, setOrderList] = useState([])
    const [wishList, setWhisList] = useState([])
    const [brewerList, setBrewerList] = useState([])
    const [newArrival, setNewArrival] = useState([])
    const [specialDeal, setSpecialDeals] = useState([])
    const [getHazy, setGetHazy] = useState([])
    const getDashboardData = async () => {
        let formData = {
             "ID":localStorage.getItem("id")
        }
        await axios.post(API_URL + "GetBuyerDashboard?code=" + API_KEY, formData, config).then((res) => {
            if(res.data?.responseCode === "200"){
                let data  = res.data?.responseData
                console.log(res.data?.responseData)

                setBrewerList(data?.popularBrew)
                setWhisList(res.data?.responseDataWishList)
                setOrderList(res.data?.responseDataOrder)
                setNewArrival(data?.newArrival)
                setSpecialDeals(data?.specialDeal)
                setGetHazy(data?.hezyData)
            }
            
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    return(
        <>
            <div className="mainAdminWrapper buyerDashboard">
                <Sidebar collapse={toggle} />
                <div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
                {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Sales Rep Dashboard</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="buyerDashboardInner">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 className="dashboardMainbuyer">Experience a World <span>of Choices on BevPort!</span></h2>
                                                <div className="shopBuyerOrange"><Link to="/salesrep/products/">SHOP AT YOUR SALES REP'S PORTAL</Link></div>
                                            </div>
                                        </div>

                                        <div className="row orderAndPopular">    
                                            <div className="col-md-7">
                                                <h3 className="yourOrderheadings">YOUR ORDERS</h3>
                                                <div className="orderDetialsListing">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <ul>
                                                                {
                                                                    orderList.map((_value, _index) => {
                                                                        if(_index < 7){
                                                                            return(
                                                                                <li><Link to={"/salesrep/order-detail/"+_value?.orderID} target="_blank"><span className="datesOrder">{moment(_value?.createdDateVal).format("MM-DD-YYYY hh:mm A")}</span><span className="orderIdOrder">#{(_value?.orderID).replace("BEVPORT", "")}</span><span className="orderDallrPrice">${parseFloat(_value?.amount).toFixed(2)}</span></Link></li>
                                                                            )
                                                                        }
                                                                        
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 orderbns">
                                                            <ul>
                                                                {
                                                                    orderList.map((_value, _index) => {
                                                                        if(_index >= 7 && _index < 10){
                                                                            return(
                                                                                <li><Link to={"/salesrep/order-detail/"+_value?.orderID} target="_blank"><span className="datesOrder">{moment(_value?.createdDateVal).format("MM-DD-YYYY hh:mm A")}</span><span className="orderIdOrder">#{(_value?.orderID).replace("BEVPORT", "")}</span><span className="orderDallrPrice">${parseFloat(_value?.amount).toFixed(2)}</span></Link></li>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </ul> 
                                                            <div className="orderHisBtn">
                                                                <Link to="/salesrep/order-history">VIEW ALL ORDERS & DELIVERIES</Link>
                                                            </div>                                                           
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>

                                            <div className="col-md-5">
                                                <h3 className="yourOrderheadings">POPULAR BREWS</h3>
                                                <div className="popularBrewsSlider">
                                                    <Carousel>
                                                        {
                                                            brewerList.map( (item, i) => {
                                                                return(
                                                                    <Paper>
                                                                        <div className="putSliderHere">
                                                                            <img src={item?.filePath} />
                                                                        </div>
                                                                        <div className="SliderItemDetials">
                                                                            <Link to={"/salesrep/products/" + item?.id}>DETAILS</Link>
                                                                        </div>
                                                                    </Paper>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                    
                                                </div>
                                            </div>


                                        </div>  

                                        <div className="row fourProductsBoxs">
                                            <div className="col-md-3">
                                                <h3 className="yourOrderheadings">WATCH LIST</h3>
                                                <div className="productFoourCard watchListBox">
                                                    <ul>
                                                        {
                                                            wishList.map((_s, _O) => {
                                                                return(
                                                                    <li>{_s?.productName}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    <div className="allfavList"><Link to="/salesrep/wishlist">ALL FAVORITES LIST</Link></div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <h3 className="yourOrderheadings">NEW ARRIVALS</h3>
                                                <div className="productFoourCard slideinsdebox">
                                                    <Carousel>
                                                        {
                                                            newArrival.map( (item, i) => {
                                                                return(
                                                                    <Paper className="removeBga">
                                                                        <Link to={"/salesrep/products-type/" + "new-arival"}>
                                                                            <img className="image-slide" src={item?.filePath} alt={item?.fileName} />
                                                                        </Link>
                                                                    </Paper>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                    
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <h3 className="yourOrderheadings">SPECIAL DEALS</h3>
                                                <div className="productFoourCard slideinsdebox">
                                                    <Carousel>
                                                        {
                                                            specialDeal.map( (item, i) => {
                                                                return(
                                                                    <Paper className="removeBga">
                                                                      <Link to={"/salesrep/products-type/" + "special-deals"}>
                                                                            <img className="image-slide" src={item?.filePath} alt={item?.fileName} />
                                                                        </Link>
                                                                    </Paper>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <h3 className="yourOrderheadings">MONTHLY SPECIAL</h3>
                                                <div className="productFoourCard slideinsdebox">
                                                    <Carousel>
                                                        {
                                                            getHazy.map( (item, i) => {
                                                                return(
                                                                    <Paper className="removeBga">
                                                                       <Link to={"/salesrep/products-type/" + "monthly-special"} >
                                                                            <img className="image-slide" src={item?.filePath} alt={item?.fileName} />
                                                                        </Link>
                                                                    </Paper>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ftBottom">
                                            <div className="col-md-12">
                                                <div className="shopBuyerOrange"><Link to="/salesrep/products">SHOP AT YOUR SALES REP'S PORTAL</Link></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}