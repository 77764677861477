import InputMask from 'react-input-mask';


export default function BankInfoCard({ handleBinfoName, bInfoName, bInfoNameError, handleBinfoAddress, bInfoAddress, bInfoAddressError, bInfoRouting, bInfoRoutingError, handleBinfoRoutingNo, bInfoAccountNo, bInfoAccountNoError, handleBinfoAccountNo,bInfoAccountType,bInfoAccountTypeError,handleBinfoAccountType ,bInfoNameAccount,bInfoNameAccountError,handleBinfoNameAccount}) {

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Bank Name <span className="red-tick">*</span></label>
                        <input type="text" className={bInfoNameError ? "form-control error" : "form-control"} placeholder="Bank Name" value={bInfoName} onChange={handleBinfoName} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Bank Address <span className="red-tick">*</span></label>
                        <input type="text" className={bInfoAddressError ? "form-control error" : "form-control"} value={bInfoAddress} placeholder="Bank Address" onChange={handleBinfoAddress} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Routing Number <span className="red-tick">*</span></label>
                        <InputMask
                            mask='999999999'
                            value={bInfoRouting}
                            onChange={handleBinfoRoutingNo}
                            className={bInfoRoutingError ? "form-control error" : "form-control"}
                            maskChar={null}
                            placeholder="Routing Number"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Account Number <span className="red-tick">*</span></label>
                        <InputMask
                            mask='999999999999999'
                            value={bInfoAccountNo}
                            onChange={handleBinfoAccountNo}
                            className={bInfoAccountNoError ? "form-control error" : "form-control"}
                            maskChar={null}
                            placeholder="Account Number"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Bank Account Type <span className="red-tick">*</span></label>
                        <select className= {bInfoAccountTypeError?"form-control error" : "form-control" } value={bInfoAccountType} onChange={handleBinfoAccountType}>
                            <option value="">Choose Account Type</option>
                            <option value="1">Checking Account</option>
                            <option value="2">Saving Account</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Name on Account <span className="red-tick">*</span></label>
                        <input type="text" className={bInfoNameAccountError?"form-control error":"form-control"}  placeholder="Name on Account" value={bInfoNameAccount} onChange={handleBinfoNameAccount} />
                    </div>
                </div>
            </div>
        </>
    )
}