import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Slide, ToastContainer, toast } from 'react-toastify';

import InputMask from 'react-input-mask';
import 'react-toastify/dist/ReactToastify.css';

import { API_URL, API_KEY, API_TOKEN, SITE_KEY, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER, BLOB_BRAND_CONTAINER } from './../../config/constant.js';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';

//import { API_URL, API_KEY, API_TOKEN, SITE_KEY, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER, BLOB_BRAND_CONTAINER } from './../../config/constant';
import Logo from './../../assets/images/logo.png';
import Select from 'react-select';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { useParams } from 'react-router-dom';

// Jesse Dudley added 5/23/2024 - when making liquor license number and image upload 
import Dropzone, { useDropzone } from 'react-dropzone'; // for image upload and drag and drop
import { Button2, Modal } from "react-bootstrap";

const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function ProductList() {
	document.title = "BevPort - Product List"
	const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
	const [productList, setProductList] = useState([])
	const [productListTemp, setProductListTemp] = useState([])
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const [canSizeList, setCanSizeList] = useState([]);
	const [packSizeList, setPackSizeList] = useState([]);
	const [CaseSizeList, setCaseSizeList] = useState([]);
	const [caseSizeValue, setCaseSizeValue] = useState("");
	const [packSizeValue, setPackSizeValue] = useState("");
	const [canSizeValue, setCanSizeValue] = useState("");
	const [pricerange, setPricerange] = useState(150000)
	const [sizeClick, setSizeClick] = useState(false);
	const [priceClick, setPriceClick] = useState(false);
	const [maxVal, setMaxVal] = useState(500);
	const [headingName, setHeadingName] = useState("Products");

	const [caseSizeValues, setCaseSizeValues] = useState("");
	const [canSizeValues, setCanSizeValues] = useState("");
	const [packSizeValues, setPackSizeValues] = useState("");

	const { brandID } = useParams();
	const { productID } = useParams();
	const { productType } = useParams();




	// Jesse Dudley added 5/23/2024--------------------
	const [show, setShow] = useState(false);
	const handleClose2 = () => setShow(false);
	const handleShow = () => setShow(true);
	const [lLicenseImg, setlLicenseImg] = useState([]);
	const [lLicenseImgError, setlLicenseImgError] = useState(false);
	const imageFormats = {
		'image/*,.pdf': [] // jd added 1/28/2024 allow pdf and images added ,.pdf'
	};
	const [lLicenseImgPre, setlLicenseImgPre] = useState([]);


	const thumbsContainer = {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16
	};


	const thumbInner = {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden'
	};

	const img = {
		display: 'block',
		width: '100px',
		height: '100%',
		objectFit: "contain"
	};


	//---
	const [lLicenseNumber, setLLicenseNumber] = useState("");
	const [lLicenseError, setlLicenseError] = useState(false);

	const [userList, setUserList] = useState([]);
	const [ClickedProductID, setClickedProductID] = useState(0);
	const [blobData, setBlobData] = useState("");


	// Buyer Payment Option const
	const [buyerPaymentTypeValue, setBuyerPaymentTypeValue] = useState("");// buyer payment type value get and set
	const [buyerPaymentTypeError, setBuyerPaymentTypeError] = useState(false); // buyer payment type error


	// buyer payments Options array
	const buyerPaymentTypeOption = [
		{ label: "-Select-", value: "" },
		{ label: "Fintech", value: "1" },
		{ label: "Check(COD)", value: "2" },
	];






	//----------------------------------------------------------------------------------------------------------------------

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		if (typeof brandID !== "undefined") {
			getProductListByBrand();
		} else if (typeof productType !== "undefined") {
			if (productType === 'new-arival') {
				getNewArrivalProducts();
				setHeadingName("New Arrivals Products")
			}
			if (productType === 'special-deals') {
				getSpecialDealProducts();
				setHeadingName("Special Deals Products")
			}
			if (productType === 'monthly-special') {
				getMonthlySpecialProducts();
				setHeadingName("Monthly Special Products")
			}
		} else {
			getProductList();
		
		}
	}, [brandID, productType])


	useEffect(() => {
		getCanSize();
		getPackSize();
		getCaseSize();
		getUserData();// 5/24/2024
		
	}, [])
	useEffect(() => {
		if (sizeClick || priceClick) {
			filterBySize()
		}
	}, [sizeClick, priceClick, pricerange])
	useEffect(() => {
		if (pricerange == 0) {
			setProductList([])
		}
	}, [pricerange])

	const getProductListByBrand = async () => {
		const formData = {
			BrandID: brandID,
			ID: localStorage.getItem("id") 
		}

		await axios.post(API_URL + "GetProductsByBrand?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	// Get User Data 5/24/2024
	const getUserData = async () => {

		// Check to see if a buyer User has Entered a liquorLicenseNumber and Image Before allowing them to buy a item
		const userData = {
			"ID": localStorage.getItem("id")
		}
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, userData, config).then(async (res) => {
			if (res.data.responseCode === "200") {

				//toast.success(res.data.responseMessage)

				let data = res.data?.responseData;
				setUserList(data);
				//alert(userList.stateID);

				
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
		//-------------------------------------------
		
	}

	const getNewArrivalProducts = async () => {

		await axios.post(API_URL + "GetNewArrivalinfo?code=" + API_KEY, {"ID":localStorage.getItem("id")}, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const getSpecialDealProducts = async () => {

		await axios.post(API_URL + "GetSpecialDealinfo?code=" + API_KEY, {"ID":localStorage.getItem("id")}, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}


	const getMonthlySpecialProducts = async () => {

		await axios.post(API_URL + "GetMonthlySpecialinfo?code=" + API_KEY, {"ID":localStorage.getItem("id")}, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}



	const getProductList = async () => {
		const formData = {
			"UserID": localStorage.getItem("id")
		}
		//setSubmitLoader(true);
		// await axios.post(API_URL + "GetProductDetailsList?code=" + API_KEY, formData, config).then((res) => {
		await axios.post(API_URL + "GetProductList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				if (productID) {
					data.filter((item) => {
						if (item.id == productID) {
							setProductList(item)
							setProductListTemp(item)
						}
					})
				} else {
					setProductList(data)
					setProductListTemp(data)
				}
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const [searchText, setSearchText] = useState("")
	const handleSearchBox = (e) => {
		setSearchText(e.target.value);
		handleSearchInput(e.target.value);
	}
	function handleSearchClick(e) {
		e.preventDefault()
		if (searchText === "") { setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(searchText.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(searchText.toLowerCase()) || (item?.masterBeerType).toLowerCase()
						.includes(searchText.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}

	function handleSearchInput(e) {
		if (e === "") { setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(e.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(e.toLowerCase()) || (item?.masterBeerType).toLowerCase()
						.includes(e.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}
	const addToFavourite = async (productID, status) => {
		const formData = {
			"ID": localStorage.getItem("id"),
			"PRODUCTID": productID,
			"FavStatus": status ? 0 : 1
		}
		setLoader(true);
		await axios.post(API_URL + "ProductFavorite?code=" + API_KEY, formData, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				if(typeof brandID !== "undefined"){
					// await getProductListByBrand();
					
					setProductList(prodList);
					await cartWishListCountFunc();
				}
				else if(typeof productType !== "undefined"){
					if (productType === 'new-arival') {
				       // await getNewArrivalProducts();
				        await cartWishListCountFunc()
				    }
			        if (productType === 'special-deals') {
				        //await getSpecialDealProducts();
				        await cartWishListCountFunc()
				    }
			        if (productType === 'monthly-special') {
				       // await getMonthlySpecialProducts();
				        await cartWishListCountFunc()
				    }
				}
				else{
                    // await getProductList()
                    await cartWishListCountFunc()
				}
				let prodList = productList.map((_v,_i) => {
					// let index = productList.findIndex((_v => _v?.id == productID));
						if (_v?.id == productID) {
							return { ..._v, favStatus: status ? 0 : 1 };
						}
						return _v;
				})
				setProductList(prodList);
				//await getProductList()
				//await cartWishListCountFunc()
			} 
			else{
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}

	// add to cart Function ------------------------------------------------------------------------------------------------------------------
	const addToCart = async (productID) => {

		// Check to see if a buyer User has Entered a liquorLicenseNumber and Image Before allowing them to buy a item
		const userData = {
			"ID": localStorage.getItem("id")			
		}
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, userData, config).then(async (res) => {
			if (res.data.responseCode === "200") {

				let data = res.data?.responseData;

				if (data.liquorLicenseNumber === "" || data.buyerPaymentType === 0) {
					
					setShow(true);
					setClickedProductID(productID); //set the clicked product id for saving when modal window open

					setLLicenseNumber(data.liquorLicenseNumber); //set liquor license number if there is 1
					// Get Liquor License Image if there is one
					getBlobFileByUserID_FileType(localStorage.getItem("id"), "LiquorLicense");

					//--

					if (data.buyerPaymentType === 0) {
						setBuyerPaymentTypeValue({ value: "", label: "-Select-" });
					}

					else if (data.buyerPaymentType === 1) {
						setBuyerPaymentTypeValue({ value: 1, label: "Fintech" });
					}

					else if (data.buyerPaymentType === 2) {
						setBuyerPaymentTypeValue({ value: 2, label: "Check(COD)" });
					}
				}

				else {
					// continue as usual with adding Product To Cart
                    const formData = {
                        "ID": localStorage.getItem("id"),
                        "ProductID": productID,
                        "Quantity": "1",
                        "numvalue": "Increment"
                    }
                    setLoader(true);
                    await axios.post(API_URL + "AddToUserCart?code=" + API_KEY, formData, config).then(async (res) => {
                        if (res.data.responseCode === "200") {
                            toast.success(res.data.responseMessage)
                            await cartWishListCountFunc()
                        } else {
                            toast.error(res.data.responseMessage)
                        }
                        setLoader(false);
                    }).catch(function (error) {
                        setLoader(false);
					})
					//----
				}// end else ------
				//-----------------------------------------------------
				
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
		//-------------------------------------------
		
	}
	//----------------------------------------------------------------------------------------------------------------


	const [cartCount, setCartCount] = useState(0)
	const [wishListCount, setWishListCount] = useState(0)
	const cartWishListCountFunc = async () => {
		await axios.post(API_URL + "WishCartCount?code=" + API_KEY, { "ID": localStorage.getItem("id") }, config).then((res) => {
			if (res.data.responseCode === "200" && res.data.responseData.length) {
				let data = res.data.responseData[0];
				setCartCount(data?.cartCount)
				setWishListCount(data?.wishCount)
			}
		}).catch(function (error) {
		})
	}

	const getCanSize = async () => {
		await axios.post(API_URL + "CanSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let canSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					canSizeOption.push({ value: da.value, label: da.value, id: da.id });
				}
				);
				setCanSizeList(canSizeOption);

			} else {

			}
		}).catch(function (error) {

		})
	}

	const getPackSize = async () => {
		await axios.post(API_URL + "PackageSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let packSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					packSizeOption.push({ value: da.value, label: da.value, id: da.id });

				}
				);
				setPackSizeList(packSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}

	const getCaseSize = async () => {
		await axios.post(API_URL + "CaseSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let caseSizeOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					caseSizeOption.push({ value: da.value, label: da.value, id: da.id });
                    //console.log("casesizelist", resData)
				}
				);
				setCaseSizeList(caseSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}

	/*const handleCaseSize = (e) => {
		setSizeClick(true)
		setCaseSizeValue(e.value);
		console.log("case size", e.value);
	}*/

    function handleCaseSize(e) {
      setSizeClick(true)
      setCaseSizeValue(e.value);
      setCaseSizeValues({value:e.value,label:e.label});
     }

   /*const handleCanSize = (e) =>{
   	setCanSizeValue(e.value);
   	setSizeClick(true)
   	console.log("can size", e.value);
   }*/

	/*const handlePackSize = (e) => {
		setSizeClick(true)
		setPackSizeValue(e.value);
		console.log("pack size", e.value);
	}*/
     
    function handlePackSize(e) {
    setSizeClick(true)
    setPackSizeValue(e.value);
    setPackSizeValues({value:e.value,label:e.label});
    
   }

   function handleCanSize(e) {
	setSizeClick(true)
    setCanSizeValue(e.value);
    setCanSizeValues({value:e.value,label:e.label});
	}

	// Jesse Dudley - 5/23/2024 -------  dropfunction for LiquorLicense image 
	// ------------- On drop Liquor License Image--------
	const onDropFunction = (acceptedFiles, fileType) => {

		if (fileType == "LiquorLicense") {
			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${localStorage.getItem("id")}_lLicenseImg_${file.name}`, { type: file.type })
			))
			setlLicenseImg(renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			})));
			setlLicenseImgError(false); // set image to false if user added a image

		}

	}
	//-------------------------------------------------------



	// Jesse Dudley - 5/23/2024
	const lLicensethumb = lLicenseImg.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFiles(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});



	// Jesse Dudley - 5/23/2024
	// ------------------------- Upload  image files to blob related functions -------------
	const removeFiles = (index, type, fileID, fileName) => {
		// if (typeof fileID !== "undefined") {
		// 	deleteFiles(fileID, fileName)
		// }
		if (type == "LiquorLicense") {
			const newFiles = [...lLicenseImg];
			newFiles.splice(index, 1);
			setlLicenseImg(newFiles);
		}

	}

	// Jesse Dudley - 5/23/2024 -  LiquorLicense Pre
	const lLicensethumbPre = lLicenseImgPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFilesPre(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	// Jesse Dudley - 5/23/2024 -  Remove Files pre
	const removeFilesPre = (index, type, fileID, fileName) => {
		if (typeof fileID !== "undefined") {
			// deleteFiles(fileID, fileName); // test this
		}
		if (type === "LiquorLicense") {
			const newFiles = [...lLicenseImgPre];
			newFiles.splice(index, 1);
			setlLicenseImgPre(newFiles);
		}

	}

	// Jesse Dudley - 5/23/2024 
	const handle_LiquorLicenseNumber = (e) => {

		setLLicenseNumber(e.target.value);
		setlLicenseError(false);
	}

	//----------------

	//  Jesse Dudley - 5/24/2024 - validation modal window liquor licsense
	const handleValidation_LiquorLicsense = () => {
		let formIsValid = true;

		if (!lLicenseImgPre.length)
		{
            if (!lLicenseImg.length) {
                formIsValid = false;
                setlLicenseImgError(true);
                //zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
            }
		}
		//---

		// check validation for buyerPaymentType
		if (lLicenseNumber === "") {
			formIsValid = false;
			setlLicenseError(true);
			
		}

		if (buyerPaymentTypeValue.value === "" || buyerPaymentTypeValue.value === undefined) {
			formIsValid = false;
			setBuyerPaymentTypeError(true);
		}

		return formIsValid;
	}



	const handleSubmit_LiquorLicsense = async (e) => {
		e.preventDefault()
		if (handleValidation_LiquorLicsense()) {

			let formData = {
				"ID": localStorage.getItem("id"),
				"Type": "Business",
				"BusinessName": userList.businessName,
				"EIN": userList.ein,
				"StateTaxNumber": userList.stateTaxNumber,
				"StreetAddress": userList.streetAddress,
				"State": userList.stateID,
				"Country": "1",
				"City": userList.city,
				"ZipCode": userList.zipCode,
				"BuyerPaymentType": buyerPaymentTypeValue.value,
				"LiquorLicenseNumber": lLicenseNumber
			}
			//--

			await axios.post(API_URL + "UpdateUserInformation?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);


					//getBrandDataByID(localStorage.getItem("id"));
					saveBuyerImages(0, localStorage.getItem("id"));// save buyer images after saving user info

					
					var lastClickedProductID = ClickedProductID;
					myAddtoCart(lastClickedProductID);
					setShow(false); //close modal window 

				} else {
					toast.error(res.data.responseMessage);
				}
				setSubmitLoader(false)
				//setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
				//setBtnDisable(false)
			})

		}
	}



	// add to cart - after saving Liquor License in Modal window -------
	const myAddtoCart = async (paramProductID) =>
	{

		// continue as usual with adding Product To Cart
		const formData = {
			"ID": localStorage.getItem("id"),
			"ProductID": paramProductID,
			"Quantity": "1",
			"numvalue": "Increment"
		}
		setLoader(true);
		await axios.post(API_URL + "AddToUserCart?code=" + API_KEY, formData, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				await cartWishListCountFunc()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})

//----		
	}



	// Save images function 
	const saveBuyerImages = async (prodID, UserID) => {
		let da = (lLicenseImg);
		console.log(da)
		let finalArray = [];
		da.forEach((_v, _i) => {
			let d = {
				"UserID": UserID,
				"BRANDID": 0,
				"PRODUCTID": prodID,
				"FILETYPE": _v?.imageType,
				"FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
				"FILEDATA": "",
				"FILENAME": _v?.name
			}
			finalArray.push(d)
		})

		if (da.length) {
			const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					setlLicenseImg([])

				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}
	// -----------------end save Images function ----------------------




	const getBlobFileByUserID_FileType = async (userid, filetype) => {
		await axios.post(API_URL + "FilesByUserIDandFileType?code=" + API_KEY, { USERID: userid, FILETYPE: filetype }, config).then((res) => {
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				setBlobData(res.data.responseData[0]); // set blob data const but it not getting used - Just yet

				// set liquor License Image			
				let lLicenseImgTemp = [];
				lLicenseImgTemp.push({ "preview": res.data.responseData[0].filepath, fileID: res.data.responseData[0].id })
				setlLicenseImgPre(lLicenseImgTemp);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	//--------------------------------------------------------


	const handleBuyerPaymentType = (e) => {

		var PaymentLabel = document.getElementById("BuyerPaymentLinkLabel");
		var BuyerPaymentLinks = document.getElementById("BuyerPaymentLinks");

		// Go to links on change --------------
		switch (e.value) {
			case "1":
				PaymentLabel.classList.remove("hidden"); // remove bootstrap hidden class				
				BuyerPaymentLinks.classList.remove("hidden");
				break;
			case "2":
				PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
				BuyerPaymentLinks.classList.add("hidden");
				break;
			default:
				PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
				BuyerPaymentLinks.classList.add("hidden");
				break;
		}
		//------------- End Switch  -------------

		setBuyerPaymentTypeValue({ value: e.value, label: e.label });
		setBuyerPaymentTypeError(false);
	}



	







    
	function filterBySize() {
		
		console.log("casesize",caseSizeValue)

		console.log("cansize",canSizeValue)
		const filterProductsBySize = productListTemp.filter((item) => {
			if (((item?.productName).toLowerCase()
				.includes(packSizeValue.toLowerCase()) || (item?.masterPackSize).toLowerCase()
					.includes(packSizeValue.toLowerCase()))
				&& ((item?.productName).toLowerCase()
					.includes( caseSizeValue.toLowerCase()) || (item?.masterCaseSize).toLowerCase()
						.includes(caseSizeValue.toLowerCase()))
				&& ((item?.productName).toLowerCase()
					.includes(canSizeValue.toLowerCase()) || (item?.masterCanSize).toLowerCase()
						.includes(canSizeValue.toLowerCase()))) { return item; }
		})
		if (priceClick) {
			const filterProductByPrice = filterProductsBySize.filter((item) => {
				if (item?.casePrice <= pricerange) {
					return item;
				}
			})
			setProductList(filterProductByPrice)
		} else {
			setProductList(filterProductsBySize);
		}
		setSizeClick(false);
		setPriceClick(false);
	}

	const handlePriceChange = (e) => {
		setMaxVal(e);
		setPriceClick(true)
		setPricerange(e);
	}




	return (
		<>

	
	
			<Modal show={show} onHide={handleClose2} animation={false}>
				<Modal.Header style={{ background:"#587dd7"}} > {/*closeButton*/}

					<Button style={{ color: "red" }} className="btn-close pull-right" onClick={handleClose2}>
						X
					</Button>

					<Modal.Title className="text-center">License Details</Modal.Title>
				
				</Modal.Header>
	
				<Modal.Body>



					<div className="row">
						<div className="col-md-12 text-center">
							<label>The following fields are required prior to placing your first order.
							</label>
						</div>
					</div>


					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Liquor License Number <span className="red-tick"> </span></label>
							{/*	<input type="text" className={lLicenseError ? "form-control error" : "form-control"} placeholder="Liquor License Number" value={lLicenseNumber} onChange={handle_LiquorLicenseNumber} />*/}

								<InputMask
									mask='********************'
									value={lLicenseNumber}
									onChange={handle_LiquorLicenseNumber}
									className={lLicenseError ? "form-control error" : "form-control"}
									maskChar={null}
									placeholder="Liquor License Number"
								>
								</InputMask>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="brand-tab">Liquor License Image <span className="red-tick"> </span></label>

								<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "LiquorLicense")} multiple={false} accept={imageFormats} disabled={(lLicenseImg.length !== 0 || lLicenseImgPre.length !== 0) ? true : false}>
									{({ getRootProps, getInputProps }) => (
										<div {...getRootProps({ className: (lLicenseImgError) ? 'dropzone custom-input-drop-buyer  error' : 'dropzone custom-input-drop-buyer' })}>
											<input {...getInputProps()} />
											<p>Drop or Click Liquor License Image.
												<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
											</p>
										</div>
									)}
								</Dropzone>
							</div>
						</div>


						<div class="col-md-2">
							<div className="thumbanilOptions">
								<aside style={thumbsContainer}>
									{/* {caseUPCthumbPre}
																{caseUPCthumb} */}
									{(lLicenseImg.length) ? lLicensethumb : lLicensethumbPre}
								</aside>
							</div>
						</div>
					</div>


					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label for="buyerPaymentType">Payment Method <span className="red-tick"></span></label>
								<Select className={buyerPaymentTypeError ? "selectTodesign error" : "selectTodesign"}
									// styles={customStyles}
									//menuPlacement="top"
									options={buyerPaymentTypeOption}
									onChange={handleBuyerPaymentType}
									value={buyerPaymentTypeValue}
									closeMenuOnSelect={true}
									placeholder={<div className="italic-placeholder">Payment Method</div>}
									isSearchable
								/>
							</div>
						</div>


						<div className="col-md-6">
							<label id="BuyerPaymentLinkLabel" class="hidden" for="PaymentLinkLabel">Please use either link to log in to your Fintech account and Connect with Bevport Distrbution LLC. <span className="red-tick">*</span></label>
							{/*----------Link List -----*/}
							<ul id="BuyerPaymentLinks" class="hidden">
								<li class=""><a href="https://ezenroll.fintech.net/Retailers/Autopay/Index" target="_blank">DEFT (Distributor EFT)</a></li>
								<li class=""><a href="https://fintech.com/" target="_blank">Payment Source Subscription</a></li>
							</ul>
						</div>
					</div>

				</Modal.Body>
				<Modal.Footer>
					<Button style={{ background:"#30bf5d3d"}} className="form-control" variant="primary" onClick={handleSubmit_LiquorLicsense}>
						Save Changes
					</Button>

					<div>
					&nbsp;
					</div>
					
					<Button variant="secondary" onClick={handleClose2}>
						Close
					</Button>				
				</Modal.Footer>
			</Modal>


		


			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BuyerHeader collapseSidebar={collapseSidebar} cartCount={cartCount} wishListCount={wishListCount} />
					<div className="rightContetBlock">

						<div className="headingTop">
							<h1>Products</h1>
						</div>


					


						<div className="blank150"></div>
						<div className="searchOntopPage">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-9 customPositionFilters">
										<div className="row">
											<div className="col-md-2">
												<Select className="selectTodesign"
													styles={customStyles}
													options={CaseSizeList}
													onChange={handleCaseSize}
													value={caseSizeValues}
													closeMenuOnSelect={true}
													placeholder={<div className="italic-placeholder">Case Size</div>}
												    //isSearchable ={filterByCaseSize}
												    isSearchable={true}
												/>
											</div>
											<div className="col-md-3">
												<Select className="selectTodesign"
													styles={customStyles}
													options={packSizeList}
													onChange={handlePackSize}
													value={packSizeValues}
													// closeMenuOnSelect={true}
													placeholder={<div className="italic-placeholder">Pack Size</div>}
												// isSearchable = {true}
												/>
											</div>
											<div className="col-md-3">
												<Select className="selectTodesign"
													styles={customStyles}
													options={canSizeList}
													onChange={handleCanSize}
													value={canSizeValues}
													// closeMenuOnSelect={true}
													placeholder={<div className="italic-placeholder">Can Size</div>}
													isSearchable
												/>
											</div>
											<div className="col-md-4">
												<div className="rangesliderDesign">
													<span className="prefiexval">$0</span>
													<InputRange
														maxValue={500}
														minValue={0}
														value={pricerange}
														step={1}
														onChange={handlePriceChange} />
													<span className="suffixVal">${maxVal}</span>
												</div>
											</div>
										</div>

									</div>

									<div className="col-md-3">
										<div className="searchBox">
											<form onSubmit={handleSearchClick}>
												<div className="input-group">
													<div className="input-group-btn">
														<button className="btn btn-default" type="submit"><i className="glyphicon glyphicon-search"></i></button>
													</div>
													<input type="text" className="form-control" placeholder="Search Products" onChange={handleSearchBox} />
													
												</div>
											</form>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body productListingPage">
									{/* <h3 className="card-heading">Products</h3> */}
									<div className="row">
										{
											productList.length ? productList.map((_v, i) => {
												let perCasePrice = 0;
												let u = (_v?.masterCaseSize).split(" ")
												if (_v?.unitPer === "unit") {
													perCasePrice = _v?.bevPortListingPrice * u[0]
												}
												if (_v?.unitPer === "pack") {
													let quantityPerPack = 0;
													if (_v?.masterPackSize === "Singles") {
														quantityPerPack = 1
													} else {
														let u0 = (_v?.masterPackSize).split(" ")
														quantityPerPack = u0[0]
													}
													perCasePrice = (_v?.bevPortListingPrice / quantityPerPack) * u[0]
												}
												return (
													<div className="col-md-3 mb-20">
														<Card className="product-list-component" sx={{ maxWidth: 345 }}>
															<div className="stage" onClick={() => addToFavourite(_v?.id, _v?.favStatus)}>
																<i className={(_v?.favStatus) ? "fa fa-heart" : "fa fa-heart-o"}></i>
															</div>

															<Link to={"/buyer/product-detail/" + _v?.id}>
																<CardMedia
																	component="img"
																	className="product-image"
																	alt={_v?.productName}
																	height="210"
																	image={(_v?.filepath) ? _v?.filepath : Logo}
																/>
															</Link>
															<CardContent>
																<Typography gutterBottom variant="h4" component="div" title={_v?.productName}>
																	{_v?.productName}
																</Typography>
																<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																	{_v?.brandName}
																</Typography>
																<Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px' }}>
																	{_v?.masterBeerType}
																</Typography>
															</CardContent>
															<Divider />
															<CardContent className="ProductListCardContent">
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Can Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.masterCanSize}
																		</Typography>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Case Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.masterCaseSize}
																		</Typography>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Pack Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.masterPackSize}
																		</Typography>
																	</div>
																</div>
																{
																	_v?.bevPortListingCasePrice ?
																		<div className="row mutliplePrices">
																			<div className="col-md-6">
																				<Typography gutterBottom variant="h6" component="div">
																					Case Price
																				</Typography>
																			</div>
																			<div className="col-md-6">
																				<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																					${parseFloat(_v?.bevPortListingCasePrice).toFixed(2)}
																				</Typography>

																			</div>
																		</div> : ""}
																{
																	_v?.bevPortListingPackPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingPackPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""}
																{
																	_v?.bevPortListingUnitPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Unit Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingUnitPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""
																}
															</CardContent>
															<CardActions className="cardactionBtns buttonWidth">
																<Link className="btn btn-primary" to={"#"} onClick={() => addToCart(_v?.id)}>Add To Cart</Link>

															</CardActions>
														</Card>
													</div>
												)
											}) :
												<>
													<div className="col-md-12">													
														<div >
															<button className="costComparisonBtn" >No Data.</button>
														</div> 
													</div>
												</>
										}

									</div>

								</div>
							</div>
						</div>
					</div>
					<BuyerFooter />
				</div>
			</div>
		</>
	)
}
