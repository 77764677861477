import React, { useState, useEffect } from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./../front/Loader";
import axios from 'axios';
import Select from 'react-select';
import InputMask from 'react-input-mask';
//import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER, BLOB_BRAND_CONTAINER } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import Dropzone, { useDropzone } from 'react-dropzone'; // for image upload and drag and drop
//import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';

import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';


const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};

toast.configure()
export default function EditInventory() {
    document.title = "BevPort - Edit Buyers"
    const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [loader, setLoader] = useState(false)
    const { buyerID } = useParams();
    const [stateList, setStateList] = useState([])
    const [fname, setFname] = useState("");
    const [fnameError, setFnameError] = useState(false);
    const [lName, setLname] = useState("");
    const [lNameError, setLnameError] = useState(false);
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneError, setPhoneError] = useState(false)
    const [businessName, setBusinessName] = useState("");
    const [businessNameError, setBusinessNameError] = useState(false);
    const [countryValue, setCountryValue] = useState({ label: "USA", value: "1" });
    const [counrtyError, setCountryError] = useState(false);
    const [stateValue, setStateValue] = useState("");
    const [stateError, setStateError] = useState(false)
    const [city, setCity] = useState("");
  
    const [cityError, setCityError] = useState(false);
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false)
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState(false);
    const [userData, setUserData] = useState("");
    const [ein, setEin] = useState("")
    const [einError, setEinError] = useState(false)
    const [stn, setStn] = useState("");
    let Navigate = useNavigate();


    // ---------------------- Added 07/23/2024 ----------
    const [SSDCustomerID, setSSDCustomerID] = useState("")
    // Buyer payment Type 
    const [buyerPaymentTypeValue, setBuyerPaymentTypeValue] = useState("");// buyer payment type value get and set
    const [buyerPaymentTypeError, setBuyerPaymentTypeError] = useState(false); // buyer payment type error



    // Liquor License Number Const ------
    const [lLicenseNumber, setLLicenseNumber] = useState("");
    const [lLicenseError, setlLicenseError] = useState(false);
    const [lLicenseImg, setlLicenseImg] = useState([]);
    const [lLicenseImgPre, setlLicenseImgPre] = useState([]);
    const [lLicenseImgError, setlLicenseImgError] = useState(false);
    const imageFormats = {
        'image/*,.pdf': [] // jd added 1/28/2024 allow pdf and images added ,.pdf'
    };
    const [blobData, setBlobData] = useState("");


    // password------------------------------------------------------------------------


    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [cpassword, setcPassword] = useState("");
    const [cpasswordError, setcPasswordError] = useState(false);
    const [passwordTxt, setPasswordTxt] = useState(false);
    const [visible, SetVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);

    const handleShow = () => {
        SetVisible(!visible);
    }

    const handlePassword = (e) => {
        setPasswordError(false)
        setPasswordTxt(false)
        setPassword(e.target.value)
    }

    const handleConfirmPassword = (e) => {
        setcPasswordError(false)
        setPasswordTxt(false)
        setcPassword(e.target.value)
    }

    const handleConfirmShow = () => {
        setConfirmVisible(!confirmVisible)
    }

    //------------------------------------------------- end password ------------------
 

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: '100px',
        height: '100%',
        objectFit: "contain"
    };
//---------------




    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const countryOption = [
        { label: "USA", value: "1" },

    ];

    useEffect(() => {
        getStateList();

    }, [])

    useEffect(() => {

        getUserProfileFunction(buyerID)
        getBlobFileByUserID_FileType(buyerID, "LiquorLicense");

    }, [buyerID])

    useEffect(() => {
        if (buyerID) {
            setFname(userData?.firstName)
            setLname(userData?.lastName)
            setEmail(userData?.emailID)
            setPhoneNo(userData?.businessPhone)
            setBusinessName(userData?.businessName)
            setStateValue({ value: userData?.stateName, label: userData?.stateName, id: userData?.stateID })
            setCity(userData?.city);
            setStreet(userData?.streetAddress)
            setZip(userData?.zipCode)
            setStn(userData?.stateTaxNumber)
            setEin(userData?.ein);

            // set Buyer Payment Type
            var PaymentLabel = document.getElementById("BuyerPaymentLinkLabel");
            var BuyerPaymentLinks = document.getElementById("BuyerPaymentLinks");

            // Set dropdown value for buyerPaymentType
            switch (userData?.buyerPaymentType) {
                case 1:
                    setBuyerPaymentTypeValue({ value: 1, label: "Fintech" });
                    PaymentLabel.classList.remove("hidden"); // remove bootstrap hidden class				
                    BuyerPaymentLinks.classList.remove("hidden");
                    break;
                case 2:
                    setBuyerPaymentTypeValue({ value: 2, label: "Check(COD)" });
                    PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
                    BuyerPaymentLinks.classList.add("hidden");
                    break;
                default:
                    setBuyerPaymentTypeValue({ value: "", label: "-Select-" });
                    PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
                    BuyerPaymentLinks.classList.add("hidden");
                    break;
            } // end switch



            setSSDCustomerID(userData?.ssdCustomerID);
            setLLicenseNumber(userData?.liquorLicenseNumber);




        }
    }, [buyerID, userData])


    const getUserProfileFunction = async (buyerID) => {
        await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": buyerID }, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setUserData(data)

            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })
    }

    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode === "200") {
                let stateOption = [];
                let resData = res.data.responseData
                resData.forEach((da) => {

                    stateOption.push({ value: da.statename, label: da.statename, id: da.id });

                }
                );

                setStateList(stateOption)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleFirstName = (e) => {
        setFname(e.target.value);
        setFnameError(false);
    }

    const handleLastName = (e) => {
        setLname(e.target.value)
        setLnameError(false)
    }

    const handlePhoneNo = (e) => {
        setPhoneNo(e.target.value)
        setPhoneError(false);
    }

    const handleBusinessName = (e) => {
        setBusinessName(e.target.value);
        setBusinessNameError(false);
    }

    const handleEIN = (e) => {
        setEinError(false)
        setEin(e.target.value)
    }
    const handleSTN = (e) => {
        setStn(e.target.value)
    }

    const handleCountry = (e) => {
        setCountryValue({ value: e.value, label: e.label })
        setCountryError(false);

    }

    const handleState = (e) => {
        setStateValue({ value: e.value, label: e.label, id: e.id })
        setStateError(false)
    }

    const handleCity = (e) => {
        setCity(e.target.value);
        setCityError(false)
    }

    const handleStreetAddress = (e) => {
        setStreet(e.target.value)
        setStreetError(false);
    }

    const handleZIP = (e) => {
        setZip(e.target.value);
        setZipError(false);

    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }


    const handleSSDCustomerID = (e) => {
        setSSDCustomerID(e.target.value)
    }

    // buyer payments Options array
    const buyerPaymentTypeOption = [
        { label: "-Select-", value: "" },
        { label: "Fintech", value: "1" },
        { label: "Check(COD)", value: "2" },
    ];



    // on change 
    const handleBuyerPaymentType = (e) => {

        var PaymentLabel = document.getElementById("BuyerPaymentLinkLabel");
        var BuyerPaymentLinks = document.getElementById("BuyerPaymentLinks");

        // Go to links on change --------------
        switch (e.value) {
            case "1":
                PaymentLabel.classList.remove("hidden"); // remove bootstrap hidden class				
                BuyerPaymentLinks.classList.remove("hidden");
                break;
            case "2":
                PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
                BuyerPaymentLinks.classList.add("hidden");
                break;
            default:
                PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
                BuyerPaymentLinks.classList.add("hidden");
                break;
        }
        //------------- End Switch  -------------

        setBuyerPaymentTypeValue({ value: e.value, label: e.label });
        setBuyerPaymentTypeError(false);
    }
    //-----------------


    const handle_LiquorLicenseNumber = (e) => {
        setLLicenseNumber(e.target.value);
        setlLicenseError(false);
    }



    const handleValidation = () => {
        let formIsValid = true;
        if (!fname) {
            formIsValid = false;
            setFnameError(true)
        }
        if (!lName) {
            formIsValid = false;
            setLnameError(true)
        }
        if (!phoneNo) {
            formIsValid = false;
            setPhoneError(true)
        }
        if (!businessName) {
            formIsValid = false;
            setBusinessNameError(true)
        }
        if (!ein) {
            formIsValid = false;
            setEinError(true)
        }
        if (!countryValue) {
            formIsValid = false;
            setCountryError(true)
        }
        if (!city) {
            formIsValid = false;
            setCityError(true)
        }
        if (!stateValue) {
            formIsValid = false;
            setStateError(true)
        }
        if (!street) {
            formIsValid = false;
            setStreetError(true)
        }
        if (!zip) {
            formIsValid = false;
            setZipError(true)
        }


        if (buyerPaymentTypeValue === "" || buyerPaymentTypeValue.value === "") {
            formIsValid = false;
            setBuyerPaymentTypeError(true);
        }

        // liquor license number
        if (!lLicenseNumber) {
            formIsValid = false;
            setlLicenseError(true);
        }

        return formIsValid;
    }





    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setLoader(true)
            let formData = {
                "UserID": buyerID,
                "FirstName": fname,
                "LastName": lName,
                "BusinessName": businessName,
                "StreetAddress": street,
                "BusinessPhone": phoneNo,
                "State": stateValue?.id,
                "Country": "1",
                "City": city,
                "ZipCode": zip,
                "EIN": ein,
                "StateTaxNumber": stn,
                "BuyerPaymentType": buyerPaymentTypeValue.value,
                "LiquorLicenseNumber": lLicenseNumber,
                "SSDCustomerID": SSDCustomerID
            }
            await axios.post(API_URL + "UpdateProfile?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    saveBuyerImages(0, buyerID);
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/buyer-list");
                } else {
                    toast.error(res.data.responseMessage);
                }
                setLoader(false)

                //setBtnDisable(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
                //setBtnDisable(false)
            })
        }
    }


    // ------------- On drop Liquor License Image--------
    const onDropFunction = (acceptedFiles, fileType) => {

        if (fileType == "LiquorLicense") {
            const renamedAcceptedFiles = acceptedFiles.map((file) => (
                new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${localStorage.getItem("id")}_lLicenseImg_${file.name}`, { type: file.type })
            ))
            setlLicenseImg(renamedAcceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                imageType: fileType
            })));
            setlLicenseImgError(false); // set image to false if user added a image

        }

    }
	//-------------------------------------------------------


    const handleValidation_UserPassword = () => {
        let formIsValid = true;

        if (!password) {
            formIsValid = false;
            setPasswordError(true)
        }
        if (!cpassword) {
            formIsValid = false;
            setcPasswordError(true)
        }
        if (cpassword && password !== cpassword) {
            formIsValid = false;
            setcPasswordError(true)
            setPasswordTxt(true)
        }
        return formIsValid;
    }



    const handleSubmit_UserPassword = async (e) => {
        e.preventDefault()
        if (handleValidation_UserPassword()) {
            setLoader(true)
            let formData = {
                "ID": buyerID,
                "Password": password
            }
            await axios.post(API_URL + "ChangeUserPassword?code=" + API_KEY, formData, config).then((res) => {
                setLoader(false)
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                }
            }).catch(function (error) {
                console.log(error)
                setLoader(false)
            })
        }
    }



    const lLicensethumb = lLicenseImg.map((file, i) => {
        let fileName = file?.preview;
        let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <span className="deleteThis" onClick={() => removeFiles(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });




    const lLicensethumbPre = lLicenseImgPre.map((file, i) => {
        let fileName = file?.preview;
        let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <span className="deleteThis" onClick={() => removeFilesPre(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });



    // ------------------------- Upload  image files to blob related functions -------------
    const removeFiles = (index, type, fileID, fileName) => {
        // if (typeof fileID !== "undefined") {
        // 	deleteFiles(fileID, fileName)
        // }
        if (type == "LiquorLicense") {
            const newFiles = [...lLicenseImg];
            newFiles.splice(index, 1);
            setlLicenseImg(newFiles);
        }

    }

   


    const removeFilesPre = (index, type, fileID, fileName) => {
        if (typeof fileID !== "undefined") {
            deleteFiles(fileID, fileName); // test this
        }
        if (type === "LiquorLicense") {
            const newFiles = [...lLicenseImgPre];
            newFiles.splice(index, 1);
            setlLicenseImgPre(newFiles);
        }

    }

    //-----------


    const getBlobFileByUserID_FileType = async (userid, filetype) => {
        await axios.post(API_URL + "FilesByUserIDandFileType?code=" + API_KEY, { USERID: userid, FILETYPE: filetype }, config).then((res) => {
            if (res.data.responseCode == "200" && res.data.responseData.length) {
                setBlobData(res.data.responseData[0]); // set blob data const but it not getting used - Just yet

                // set liquor License Image			
                let lLicenseImgTemp = [];
                lLicenseImgTemp.push({ "preview": res.data.responseData[0].filepath, fileID: res.data.responseData[0].id })
                setlLicenseImgPre(lLicenseImgTemp);
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })
    }
	//--------------------------------------------------------


    // Save images function 
    const saveBuyerImages = async (prodID, pUserID) => {
        let da = (lLicenseImg);
        console.log(da)
        let finalArray = [];
        da.forEach((_v, _i) => {
            let d = {
                "UserID": pUserID,
                "BRANDID": 0,
                "PRODUCTID": prodID,
                "FILETYPE": _v?.imageType,
                "FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
                "FILEDATA": "",
                "FILENAME": _v?.name
                
            }
            finalArray.push(d)
        })


        if (da.length) {
            const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
                await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
                    setlLicenseImg([])

                }).catch(function (error) {
                    console.log(error)
                })
            })
        }
    }
	// -----------------end save Images function ----------------------

    // For deleting the image from user blob table
    const deleteFiles = async (fileID, fileName) => {
        setSubmitLoader(true);
        await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
            if (res.data.responseCode === "200") {
                await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)

            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false);
            console.log(error)
        })
    }
	//-------------------------------------------

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Edit Buyers</h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">First Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={fnameError ? "form-control error" : "form-control"} placeholder="First Name" value={fname} onChange={handleFirstName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Last Name<span className="red-tick"> *</span></label>
                                                    <input type="text" className={lNameError ? "form-control error" : "form-control"} placeholder="Last Name" value={lName} onChange={handleLastName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Email<span className="red-tick"> *</span></label>

                                                    <input type="text" className="form-control" placeholder="Email" value={email} onChange={handleEmail} readOnly={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Phone No.<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='(999) 999 9999'
                                                        value={phoneNo}
                                                        onChange={handlePhoneNo}
                                                        className={phoneError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="Phone No."
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Business Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={businessNameError ? "form-control error" : "form-control"} placeholder="Business Name" value={businessName} onChange={handleBusinessName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">Employer Identification Number <span className="red-tick">*</span></label>
                                                    <InputMask
                                                        mask='999-999999999'
                                                        value={ein}
                                                        onChange={handleEIN}
                                                        className={einError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="EIN"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">State Tax Number</label>
                                                    <InputMask
                                                        mask='999-99-9999'
                                                        value={stn}
                                                        onChange={handleSTN}
                                                        className="form-control"
                                                        maskChar={null}
                                                        placeholder="If Different From EIN"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Country <span className="red-tick"> *</span></label>
                                                    <Select className={counrtyError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={countryOption}
                                                        onChange={handleCountry}
                                                        value={countryValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Country</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">State<span className="red-tick"> *</span></label>
                                                    <Select className={stateError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={stateList}
                                                        onChange={handleState}
                                                        value={stateValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">State</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">City <span className="red-tick"> *</span></label>
                                                    <input type="text" className={cityError ? "form-control error" : "form-control"} placeholder="City" value={city} onChange={handleCity} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Street Address<span className="red-tick"> *</span></label>

                                                    <input type="text" className={streetError ? "form-control error" : "form-control"} placeholder="Street Address" value={street} onChange={handleStreetAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">ZIP Code<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='99999'
                                                        value={zip}
                                                        onChange={handleZIP}
                                                        className={zipError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="ZIP Code"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="buyerPaymentType">Payment Method <span className="red-tick">*</span></label>
                                                    <Select className={buyerPaymentTypeError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={buyerPaymentTypeOption}
                                                        onChange={handleBuyerPaymentType}
                                                        value={buyerPaymentTypeValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Payment Method</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Liquor License Number <span className="red-tick"> *</span></label>
                                                    <input type="text" className={lLicenseError ? "form-control error" : "form-control"} placeholder="Liquor License Number" value={lLicenseNumber} onChange={handle_LiquorLicenseNumber} />
                                                </div>
                                            </div>


                                        </div>


                                        <div className="row">
                                            <div className="col-md-6">
                                                <label id="BuyerPaymentLinkLabel" class="hidden" for="PaymentLinkLabel">Please use either link to log in to your Fintech account and Connect with Bevport Distrbution LLC. <span className="red-tick">*</span></label>
                                           {/*     ----------Link List -----*/}
                                                <ul id="BuyerPaymentLinks" class="hidden">
                                                    <li class=""><a href="https://ezenroll.fintech.net/Retailers/Autopay/Index" target="_blank">Ezenroll Auto Pay</a></li>
                                                    <li class=""><a href="https://fintech.com/" target="_blank">Fintech | Revolutionizing the B2B Alcohol Industry</a></li>
                                                </ul>
                                            </div>



                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="brand-tab">Liquor License Image <span className="red-tick"> *</span></label>

                                                    <Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "LiquorLicense")} multiple={false} accept={imageFormats} disabled={(lLicenseImg.length !== 0 || lLicenseImgPre.length !== 0) ? true : false}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps({ className: (lLicenseImgError) ? 'dropzone custom-input-drop-buyer error' : 'dropzone custom-input-drop-buyer' })}>
                                                                <input {...getInputProps()} />
                                                                <p>Drop or Click Liquor License Image.
                                                                    <br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div className="thumbanilOptions">
                                                    <aside style={thumbsContainer}>
                                                        
                                                        {(lLicenseImg.length) ? lLicensethumb : lLicensethumbPre}
                                                    </aside>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">SSD Customer ID <span className="red-tick"></span></label>
                                                    <InputMask
                                                        mask='*********************'
                                                        value={SSDCustomerID}
                                                        onChange={handleSSDCustomerID}
                                                        className={"form-control"}
                                                        maskChar={null}
                                                        placeholder="SSD Customer ID"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>





                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="">
                            <h1 style={{ color: "white", fontSize: "28px", paddingLeft: "20px" }}>Password</h1>
                        </div>

                     {/*   // password*/}

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <ul className="nav nav-tabs">
                                        <li className="active"><a data-toggle="tab" href="#home">Change Password</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="home" className="tab-pane fade in active">
                                            <div className="InsertForms">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="brand-tab">Password<span className="red-tick"> *</span></label>
                                                            <div className="showhidepassowrd">
                                                                <input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} placeholder="Password" onChange={handlePassword} />
                                                                <span className="eyeslash" onClick={handleShow}><i className={!visible ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="brand-tab">Confirm Password<span className="red-tick"> *</span></label>
                                                            <div className="showhidepassowrd">
                                                                <input type={confirmVisible ? "text" : "password"} className={cpasswordError ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleConfirmPassword} />
                                                                {passwordTxt ? <span className="error-message">Confirm password should be same as password.</span> : ""}
                                                                <span className="eyeslash" onClick={handleConfirmShow}><i className={!confirmVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="formBtns">
                                                            <button type="button" className="btn btn-primary save-button" onClick={handleSubmit_UserPassword}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}