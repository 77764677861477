import React, { useState, useEffect ,useRef} from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import axios from 'axios';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER, BLOB_BRAND_CONTAINER } from './../../config/constant.js';
import DefaultAvtar from './../../assets/images/avatar-1.jpg'
import moment from "moment";

import Dropzone, { useDropzone } from 'react-dropzone'; // for image upload and drag and drop


import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: 'transparent',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			border: '1px solid #b6b6af',
			boxShadow: null,
		},
		'&:focus': {
			border: '1px solid transparent',
			boxShadow: null,
		}
	})
};

export default function EditProfile() {
	document.title = "BevPort - Edit Profile"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [userData, setUserData] = useState("");
	const fileInputRef = useRef(null);
	const [isFileInputVisible, setFileInputVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [imageRef, setImageRef] = useState(null);
	const [imageName, setImageName] = useState("");
	const [completeCrop, setCompleteCrop] = useState("");

	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [profilePic, setProfilePic] = useState("");

	const [getApiResp, setGetApiResp] = useState(0);
	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		x: 7,
		y: 7,

	});

	/*************************** End Variables for Contact Details ********************/
	const [fname, setFname] = useState("");
	const [lname, setLName] = useState("");
	const [mobile, setMobile] = useState("");
	const [mOption, setMoption] = useState("");
	const [email, setEmail] = useState("")

	// Liquor License Number Const ------
	const [lLicenseNumber, setLLicenseNumber] = useState("");
	const [lLicenseError, setlLicenseError] = useState(false);
	const [lLicenseImg, setlLicenseImg] = useState([]);
	const [lLicenseImgPre, setlLicenseImgPre] = useState([]);
	const [lLicenseImgError, setlLicenseImgError] = useState(false);
	const imageFormats = {
		'image/*,.pdf': []
	};
	const thumbsContainer = {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16
	};

	const thumbInner = {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden'
	};

	const img = {
		display: 'block',
		width: '100px',
		height: '100%',
		objectFit: "contain"
	};


	const [blobData, setBlobData] = useState("");
	//-------------------------------------



	const [fnameError, setFnameError] = useState(false);
	const [lnameError, setLnameError] = useState(false);
	const [mobileError, setMobileError] = useState(false);
	const [optionalError, setOptionalError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [cEmail, setCemail] = useState("");
	const [cEmailError, setCemailError] = useState(false);
	/*************************** Variables for Contact Details ********************/

	/*************************** Variables for Business Details ********************/
	const [bName, setBname] = useState("")
	const [bnameError, setbNameError] = useState(false)
	const [ein, setEin] = useState("")
	const [einError, setEinError] = useState(false)
	const [stn, setStn] = useState("")
	const [country, setCountry] = useState("");
	const [countryValue, setCountryValue] = useState("");
	const [countryError, setCountryError] = useState(false);


    // Buyer payment Type 
	const [buyerPaymentTypeValue, setBuyerPaymentTypeValue] = useState("");// buyer payment type value get and set
	const [buyerPaymentTypeError, setBuyerPaymentTypeError] = useState(false); // buyer payment type error


	const [state, setState] = useState("")
	const [stateID, setStateID] = useState("");
	const [stateError, setStateError] = useState(false)
	const [city, setCity] = useState("")
	const [SSDCustomerID, setSSDCustomerID] = useState("")
	const [cityError, setCityError] = useState(false)
	const [street, setStreet] = useState("")
	const [streetError, setStreetError] = useState(false)
	const [zip, setZip] = useState("")
	const [zipError, setZipError] = useState(false)
	// const [mobile, setMobile] = useState("")
	// const [mobileError, setMobileError] = useState(false)
	// const [altMobile, setAltMobile] = useState("")
	const [stateList, setStateList] = useState([])
	const [stateValue, setStateValue] = useState("");
	const [loader, setLoader] = useState(false)
	// const [altMobError, setaltMobError] = useState(false);
	// const [btnDisable , setBtnDisable] = useState(false);

	/*************************** End Variables for Business Details ********************/
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		let id = localStorage.getItem("id")
		getBrandDataByID(id)
	}, [])

	useEffect(() => {
		getStateList()
	}, [])

	const handleClose = () => {
		setShowModal(false);
	};

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			handleClose();
		}
	};

	const handleEscapeKey = (event) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	};

	useEffect(() => {
		if (showModal) {
			document.addEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [showModal]);

	useEffect(() => {
		if (userData) {
			setFname(userData?.firstName);
			setLName(userData?.lastName);
			setMobile(userData?.businessPhone);
			setMoption(userData?.optionalPhone);
			setEmail(userData?.emailID);
			setBname(userData?.businessName);
			setEin(userData?.ein);
			setStn(userData?.stateTaxNumber);
			stateList.forEach((_v, _i) => {
				if (_v.id == userData?.stateID) {
					setStateID(_v?.id)
					setState(_v.value);
					setStateValue(_v)
				}
			})			
			setCountryValue(countryOption);
			setLLicenseNumber(userData?.liquorLicenseNumber);// set License Number On Page Load and after Update

		
			// set Buyer Payment Type
			var PaymentLabel = document.getElementById("BuyerPaymentLinkLabel");
			var BuyerPaymentLinks = document.getElementById("BuyerPaymentLinks");

			// Set dropdown value for buyerPaymentType
			switch (userData?.buyerPaymentType) {
				case 1:
					setBuyerPaymentTypeValue({ value: 1, label: "Fintech" });
					PaymentLabel.classList.remove("hidden"); // remove bootstrap hidden class				
					BuyerPaymentLinks.classList.remove("hidden");
					break;
				case 2:
					setBuyerPaymentTypeValue({ value: 2, label: "Check(COD)" });
					PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
					BuyerPaymentLinks.classList.add("hidden");
					break;
				default:
					setBuyerPaymentTypeValue({ value: "", label: "-Select-" });
					PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
					BuyerPaymentLinks.classList.add("hidden");
					break;
			} // end switch

			setCity(userData?.city);
			setStreet(userData?.streetAddress);
			setZip(userData?.zipCode);
			setSSDCustomerID(userData?.ssdCustomerID);


	
			// Get Liquor License Image if there is one
			getBlobFileByUserID_FileType(localStorage.getItem("id"), "LiquorLicense");

			//----
		}
	}, [userData, stateList])


	// ------------------------- Upload  image files to blob related functions -------------
	const removeFiles = (index, type, fileID, fileName) => {
		// if (typeof fileID !== "undefined") {
		// 	deleteFiles(fileID, fileName)
		// }
		if (type == "LiquorLicense") {
			const newFiles = [...lLicenseImg];
			newFiles.splice(index, 1);
			setlLicenseImg(newFiles);
		}
		
	}

	const removeFilesPre = (index, type, fileID, fileName) => {
		if (typeof fileID !== "undefined") {
			deleteFiles(fileID, fileName); // test this
		}
		if (type === "LiquorLicense") {
			const newFiles = [...lLicenseImgPre];
			newFiles.splice(index, 1);
			setlLicenseImgPre(newFiles);
		}
		
	}

	const lLicensethumb = lLicenseImg.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFiles(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});
	const lLicensethumbPre = lLicenseImgPre.map((file, i) => {
		let fileName = file?.preview;
		let fFileName = (fileName.split("/")).slice(-1)[0];
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFilesPre(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					<img
						src={file.preview}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.preview) }}
					/>
				</div>
			</div>
		)
	});

	// ------------- On drop Liquor License Image--------
	const onDropFunction = (acceptedFiles, fileType) => {

		if (fileType == "LiquorLicense") {
			const renamedAcceptedFiles = acceptedFiles.map((file) => (
				new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${localStorage.getItem("id")}_lLicenseImg_${file.name}`, { type: file.type })
			))
			setlLicenseImg(renamedAcceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file),
				imageType: fileType
			})));
			setlLicenseImgError(false); // set image to false if user added a image

		}

	}
	//-------------------------------------------------------


	const getBlobFileByUserID_FileType = async (userid, filetype) => {
		await axios.post(API_URL + "FilesByUserIDandFileType?code=" + API_KEY, { USERID: userid, FILETYPE: filetype }, config).then((res) => {
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				setBlobData(res.data.responseData[0]); // set blob data const but it not getting used - Just yet

				// set liquor License Image			
				let lLicenseImgTemp = [];				
				lLicenseImgTemp.push({ "preview": res.data.responseData[0].filepath, fileID: res.data.responseData[0].id })			
				setlLicenseImgPre(lLicenseImgTemp);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	//--------------------------------------------------------

	// For deleting the image from user blob table
	const deleteFiles = async (fileID, fileName) => {
		setSubmitLoader(true);
		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false);
			console.log(error)
		})
	}
	//-------------------------------------------

	const getBrandDataByID = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setUserData(data)
				console.log(data)
				setProfilePic(data?.filePath);
				if (data.filePath != "") {
					setGetApiResp(1);
				}
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	const handleFname = (e) => {
		setFname(e.target.value);
		setFnameError(false);
	}

	const handle_LiquorLicenseNumber = (e) => {
		setLLicenseNumber(e.target.value);
		setlLicenseError(false);
	}

	const handleLastName = (e) => {
		setLName(e.target.value);
		setLnameError(false);
	}

	const handleMobile = (e) => {
		setMobile(e.target.value);
		setMobileError(false);
	}

	const handleOptionalMobile = (e) => {
		setMoption(e.target.value);
		setOptionalError(false);
	}

	const handleEmail = (e) => {
		setEmail(e.target.value);
		setEmailError(false);
	}

	const handleConfirmEmail = (e) => {
		setCemail(e.target.value);
		setCemailError(false);
	}


	const handleSSDCustomerID = (e) => {
		setSSDCustomerID(e.target.value)
	}

	const handleValidation = () => {
		let formIsValid = true;

		if (!fname) {
			formIsValid = false;
			setFnameError(true)
		}
		if (!lname) {
			formIsValid = false;
			setLnameError(true)
		}
		if (!mobile) {
			formIsValid = false;
			setMobileError(true)
		}
		if (!email) {
			formIsValid = false;
			setEmailError(true);
		}

		if (cEmail == email) {
			if (!cEmail) {
				formIsValid = false;
				setCemailError(true);
			}
			return formIsValid;
		} else {
			if (cEmail != email) {
				formIsValid = false;
				setCemailError(true)
			}
		}
		//

		return formIsValid;
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (handleValidation()) {
			setLoader(true)
			//setBtnDisable(true)
			let formData = {
				"ID": localStorage.getItem("id"),
				"FirstName": fname,
				"LastName": lname,
				"EmailID": email,
				"BusinessPhone": mobile,
				"OptionalPhone": mOption
			}

			await axios.post(API_URL + "UpdateUserDetails?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					getBrandDataByID(localStorage.getItem("id"));
				} else {
					toast.error(res.data.responseMessage);
				}
				setLoader(false)
				//setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setLoader(false)
				//setBtnDisable(false)
			})
		}
	}

	const handleBName = (e) => {
		setbNameError(false)
		setBname(e.target.value)
	}
	const handleEIN = (e) => {
		setEinError(false)
		setEin(e.target.value)
	}
	const handleSTN = (e) => {
		setStn(e.target.value)
	}
	const handleCountry = (e) => {
		setCountry("1");
		setCountryValue({ label: 'USA', value: '1' });
		setCountryError(false);
	}


	// on change 
	const handleBuyerPaymentType = (e) => {
	
		var PaymentLabel = document.getElementById("BuyerPaymentLinkLabel");
		var BuyerPaymentLinks = document.getElementById("BuyerPaymentLinks");

		// Go to links on change --------------
		switch (e.value)
		{
			case "1":				
				PaymentLabel.classList.remove("hidden"); // remove bootstrap hidden class				
				BuyerPaymentLinks.classList.remove("hidden");
				break;
			case "2":
				PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
				BuyerPaymentLinks.classList.add("hidden");
				break;
            default:
				PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
				BuyerPaymentLinks.classList.add("hidden");
                break;
		}
		//------------- End Switch  -------------

		setBuyerPaymentTypeValue({ value: e.value, label: e.label });
		setBuyerPaymentTypeError(false);
	}


	const handleState = (e) => {
		setStateID(e.id);
		setState(e.value);
		setStateValue({ value: e.value, label: e.label })
		setStateError(false);
	}
	const handleCity = (e) => {
		setCityError(false)
		setCity(e.target.value)
	}
	const handleStreet = (e) => {
		setStreetError(false)
		setStreet(e.target.value)
	}
	const handleZIP = (e) => {
		setZipError(false)
		setZip(e.target.value)
	}



	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					stateOption.push({ value: da.statename, label: da.statename, id: da.id });
				}
				);
				setStateList(stateOption)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	const countryOption = [
		{ label: "USA", value: "1" },
	];


	// buyer payments Options array
	const buyerPaymentTypeOption = [
		{ label: "-Select-", value: "" },
		{ label: "Fintech", value: "1" },
		{ label: "Check(COD)", value: "2" },
	];

	const handleBusinessValidation = () => {
		let formIsValid = true;
		if (!bName) {
			formIsValid = false;
			setbNameError(true)
		}
		if (!ein) {
			formIsValid = false;
			setEinError(true)
		}
		if (!countryValue) {
			formIsValid = false;
			setCountryError(true)
		}
		if (!state) {
			formIsValid = false;
			setStateError(true)
		}
		if (!city) {
			formIsValid = false;
			setCityError(true)
		}
		if (!street) {
			formIsValid = false;
			setStreetError(true)
		}
		if (!zip) {
			formIsValid = false;
			setZipError(true)
		}
		if (!mobile) {
			formIsValid = false;
			setMobileError(true)
		}

		if (buyerPaymentTypeValue == "" || buyerPaymentTypeValue.value == "" ) {
			formIsValid = false;
			setBuyerPaymentTypeError(true);
		}
	
		if (!lLicenseNumber) {
			formIsValid = false;
			setlLicenseError(true)
		}


		if (!lLicenseImgPre.length) {
			if (!lLicenseImg.length) {
				formIsValid = false;
				setlLicenseImgError(true);
			}
		}

		return formIsValid;
	}




	const handleBusinessSubmit = async (e) => {
		e.preventDefault()
		if (handleBusinessValidation()) {
			setSubmitLoader(true)
			//setBtnDisable(true)
			let formData = {
				"ID": localStorage.getItem("id"),
				"Type": "Business",
				"BusinessName": bName,
				"EIN": ein,
				"StateTaxNumber": stn,
				"StreetAddress": street,
				"State": stateID.toString(),
				"Country": "1",
				"City": city,
				"ZipCode": zip,
				"BuyerPaymentType": buyerPaymentTypeValue.value,
				"LiquorLicenseNumber": lLicenseNumber,
				"SSDCustomerID": SSDCustomerID

			}


			await axios.post(API_URL + "UpdateUserInformation?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					getBrandDataByID(localStorage.getItem("id"));
					saveBuyerImages(0);

				} else {
					toast.error(res.data.responseMessage);
				}
				setSubmitLoader(false)
				//setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
				//setBtnDisable(false)
			})
		}
	}

	// Save images function 
	const saveBuyerImages = async (prodID) => {
		let da = (lLicenseImg);
		console.log(da)
		let finalArray = [];
		da.forEach((_v, _i) => {
			let d = {
				"UserID": localStorage.getItem("id"),
				"BRANDID": 0,
				"PRODUCTID": prodID,
				"FILETYPE": _v?.imageType,
				"FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
				"FILEDATA": "",
				"FILENAME": _v?.name
			}
			finalArray.push(d)
		})

		if (da.length) {
			const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					setlLicenseImg([])
				
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}
	// -----------------end save Images function ----------------------



	const closeUploadMode = (e) => {
		e.preventDefault()
		window.$('#myModel2').modal('hide')
	}

	const handleDivClick = () => {
		setFileInputVisible(true);
		//document.getElementById("uploadFileInput").click();
		fileInputRef.current.click()

	};



	const handleFileInputChange = (event) => {
		// Handle file selection here
		const selectedFile = event.target.files[0];
		console.log('Selected file:', selectedFile);
		if (selectedFile) {
			const imageURL = URL.createObjectURL(selectedFile);
			console.log("imageUrl", imageURL);
			//setUploadProfile(selectedFile);
			setImageName(selectedFile?.name)
			setSelectedImage(imageURL);
			window.$('#myModel2').modal('show')
		}

		// After handling the file, hide the file input
		setFileInputVisible(false);
	};

	const CropImg = (e) => {

		setCrop(e);
	}
	const ImgLoad = (e) => {
		setImageRef(e.target);
	}


	const savePofile = async (dataFile) => {
		let da = dataFile;
		console.log("data", da);
		let finalArray = [];
		//da.forEach((_v, _i) => {
		let d = {
			"UserID": localStorage.getItem("id"),
			"BRANDID": "0",
			"PRODUCTID": "0",
			"FILETYPE": "UserPic",
			"FILEPATH": BLOB_URL + BLOB_TEAM_MEMBER_CONTAINER + '/' + da?.name,
			"FILEDATA": "",
			"FILENAME": da?.name,

		}
		finalArray.push(d)
		//})
		//console.log("tee", finalArray);
		if (finalArray.length) {

			// setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob([da], BLOB_TEAM_MEMBER_CONTAINER).then(async res => {
				console.log("resposnse", res);

				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					console.log(res.data.responseMessage)
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}

	const handleCropImage = (e) => {


		if (imageRef && crop.width && crop.height) {

			const canvas = document.createElement('canvas');
			const scaleX = imageRef.naturalWidth / imageRef.width;
			const scaleY = imageRef.naturalHeight / imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(
				imageRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);

			// const croppedImageUrl = canvas.toDataURL('image/jpeg', 'image/png');
			// setEditPriview(croppedImageUrl);
			// console.log("imageCrop", croppedImageUrl)
			//window.$('#myModel2').modal('hide');
			canvas.toBlob(
				(blob) => {
					const file = new File([blob], imageName, {
						type: "image/png",
						//preview: URL.createObjectURL(File),
						lastModified: Date.now(),
					});
					setGetApiResp(0);
					//setUploadProfile(file);
					console.log("file", file)
					setSelectedImage(URL.createObjectURL(file))
					setCompleteCrop(URL.createObjectURL(file))
					setProfilePic(URL.createObjectURL(file));
					savePofile(file);
					//setUploadProfile(file)
					window.$('#myModel2').modal('hide');
				}

			)
		}

	}




	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BuyerHeader collapseSidebar={collapseSidebar} profilePic={profilePic}/>
					<div className="rightContetBlock">
						<div className="headingTop">

						</div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
								<div className="col-md-2 ImgPart" >
										<div className="profileImages editoptions" onClick={handleDivClick}>
											{getApiResp ? (<img src={userData.filePath} alt="Selected" />) : completeCrop ? (
												<img src={completeCrop} alt="Selected" />
											) : (
												<img src={DefaultAvtar} />
											)}


											<div className="clickable-div"></div>
												<input type='file' className='upload-input' id="uploadFileInput" ref={fileInputRef} onClick={e => (e.target.value = null)} onChange={handleFileInputChange} style={{ display: isFileInputVisible ? 'block' : 'none' }} accept="image/*" />
											
										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>
											</div>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="addProfilePopup editpicture">
							<div className={`modal fade ${showModal ? 'show' : ''}`} id="myModel2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false" >
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" onClick={closeUploadMode}><span aria-hidden="true">&times;</span></button>
											<h4 className="modal-title" id="myModalLabel">Profile Upload</h4>
										</div>
										<div className="modal-body">
											<div className="row">
												<div className="col-md-12">
													{/* <div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} accept="image/*" />

																</div> */}
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														{/* <ReactCrop src={editPriview} onImageLoaded={setImage}
																		crop={crop} onChange={CropImg} />
																	<img src={editPriview} key="0" alt={"Image"} /> */}
														<ReactCrop crop={crop} aspect={1 / 1} onChange={CropImg} onImageLoaded={ImgLoad}>
															<img src={selectedImage} onLoad={ImgLoad} />
														</ReactCrop>
														<div className='checkbtnsse'>
														<button className='btn btn-primary save-button' onClick={handleCropImage}>Crop</button>
														</div>
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Contact Details</a></li>
										<li><a data-toggle="tab" href="#menu1">Business Details</a></li>
										{/* <li><a data-toggle="tab" href="#menu2">Bank Details</a></li>
									<li><a data-toggle="tab" href="#menu5">Subscription Details</a></li> */}
									</ul>
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">First Name<span className="red-tick"> *</span></label>
															<input type="text" className={fnameError ? "form-control error" : "form-control"} placeholder="First Name" value={fname} onChange={handleFname} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Last Name<span className="red-tick"> *</span></label>
															<input type="text" className={lnameError ? "form-control error" : "form-control"} placeholder="Last Name" value={lname} onChange={handleLastName} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Mobile<span className="red-tick"> *</span></label>
															<InputMask
																mask='(999) 999 9999'
																value={mobile}
																onChange={handleMobile}
																className={mobileError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Mobile Number"
															>
															</InputMask>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Mobile (Optional)</label>
															<InputMask
																mask='(999) 999 9999'
																value={mOption}
																onChange={handleOptionalMobile}
																className={optionalError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Alternative Mobile Number"
															>
															</InputMask>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Email<span className="red-tick"> *</span></label>
															<input type="text" className={emailError ? "form-control error" : "form-control"} placeholder="Enter Email Address" value={email} onChange={handleEmail} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Confirm Email<span className="red-tick"> *</span></label>
															<input type="text" className={cEmailError ? "form-control error" : "form-control"} placeholder="Confirm Email" value={cEmail} onChange={handleConfirmEmail} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handleSubmit}>Save</button>
														</div>
													</div>
												</div>

											</div>
										</div>
										<div id="menu1" className="tab-pane fade">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Business Name<span className="red-tick"> *</span></label>
															<input type="text" className={bnameError ? "form-control error" : "form-control"} value={bName} placeholder="Enter Business Name" onChange={handleBName} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Employer Identification Number<span className="red-tick"> *</span></label>
															<InputMask
																mask='999-999999999'
																value={ein}
																onChange={handleEIN}
																className={einError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="EIN"
															>
															</InputMask>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">State Tax Number</label>
															<InputMask
																mask='999-99-9999'
																value={stn}
																onChange={handleSTN}
																className="form-control"
																maskChar={null}
																placeholder="If Different From EIN"
															>
															</InputMask>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Country <span className="red-tick">*</span></label>
															<Select className={countryError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={countryOption}
																onChange={handleCountry}
																value={countryValue}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Country</div>}
																isSearchable
															/>
														</div>
													</div>
												</div>

	

												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">State <span className="red-tick">*</span></label>
															<Select className={stateError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={stateList}
																onChange={handleState}
																value={stateValue}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">State</div>}
																isSearchable
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">City <span className="red-tick">*</span></label>
															<input type="text" className={cityError ? "form-control error" : "form-control"} value={city} placeholder="City" onChange={handleCity} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Street Address <span className="red-tick">*</span></label>
															<input type="text" className={streetError ? "form-control error" : "form-control"} value={street} placeholder="Enter Street Address" onChange={handleStreet} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">ZIP Code <span className="red-tick">*</span></label>
															<InputMask
																mask='99999'
																value={zip}
																onChange={handleZIP}
																className={zipError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="ZIP Code"
															>
															</InputMask>
														</div>
													</div>
												</div>


												{/*///  -------------------------New Row  Liquor License ---------------------------------*/}


									


												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="buyerPaymentType">Payment Method <span className="red-tick">*</span></label>
															<Select className={buyerPaymentTypeError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={buyerPaymentTypeOption}
																onChange={handleBuyerPaymentType}
																value={buyerPaymentTypeValue}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Payment Method</div>}
																isSearchable
															/>
														</div>
													</div>


													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Liquor License Number <span className="red-tick"> *</span></label>
															<input type="text" className={lLicenseError ? "form-control error" : "form-control"} placeholder="Liquor License Number" value={lLicenseNumber} onChange={handle_LiquorLicenseNumber} />
														</div>
													</div>
												</div>


												<div className="row">
													<div className="col-md-6">
														<label id="BuyerPaymentLinkLabel" class="hidden" for="PaymentLinkLabel">Please use either link to log in to your Fintech account and Connect with Bevport Distrbution LLC. <span className="red-tick">*</span></label>
														{/*----------Link List -----*/}
														<ul id="BuyerPaymentLinks" class="hidden">														
															<li class=""><a href="https://ezenroll.fintech.net/Retailers/Autopay/Index" target="_blank">Ezenroll Auto Pay</a></li>
															<li class=""><a href="https://fintech.com/" target="_blank">Fintech | Revolutionizing the B2B Alcohol Industry</a></li>															
														</ul>
													</div>



													<div className="col-md-4">
														<div className="form-group">
															<label className="brand-tab">Liquor License Image <span className="red-tick"> *</span></label>

															<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "LiquorLicense")} multiple={false} accept={imageFormats} disabled={(lLicenseImg.length !== 0 || lLicenseImgPre.length !== 0) ? true : false}>
																{({ getRootProps, getInputProps }) => (
																	<div {...getRootProps({ className: (lLicenseImgError) ? 'dropzone custom-input-drop-buyer error' : 'dropzone custom-input-drop-buyer' })}>
																		<input {...getInputProps()} />
																		<p>Drop or Click Liquor License Image.
																			<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
																		</p>
																	</div>
																)}
															</Dropzone>
														</div>
													</div>

													<div class="col-md-2">
														<div className="thumbanilOptions">
															<aside style={thumbsContainer}>
																{/* {caseUPCthumbPre}
																{caseUPCthumb} */}
																{(lLicenseImg.length) ? lLicensethumb : lLicensethumbPre}
															</aside>
														</div>
													</div>

												</div>


												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">SSD Customer ID <span className="red-tick"></span></label>
															<InputMask
																mask='*********************'
																value={SSDCustomerID}
																onChange={handleSSDCustomerID}
																className={"form-control"}
																maskChar={null}
																placeholder="SSD Customer ID"
															>
															</InputMask>
														</div>
													</div>
												</div>



												<div className="row">
													{/* <div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Mobile Number <span className="red-tick">*</span></label>
															<InputMask
																mask='(999) 999 9999'
																value={mobile}
																onChange={handleMobile}
																className={mobileError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Mobile Number"
															>
															</InputMask>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Alternative Mobile Number (optional)</label>
															<InputMask
																mask='(999) 999 9999'
																value={mOption}
																onChange={handleOptionalMobile}
																className={optionalError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Alternative Mobile Number"
															>
															</InputMask>
														</div>
													</div> */}
												</div>

												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handleBusinessSubmit} >Save</button>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<BuyerFooter />
				</div>
			</div>
		</>
	)
}