import React, {useEffect} from 'react';
import {NavLink, Link, useNavigate} from "react-router-dom";
import { ProSidebarProvider, Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BuyerHeader from "./BuyerHeader";
import "./../../assets/css/admin-style.css"
import Logo from "./../../assets/images/logo.png"
import SmallLogo from "./../../assets/images/small-logo.png"
import OrderListIcon from '@mui/icons-material/ViewList';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FactCheckIcon from '@mui/icons-material/FactCheck';

export default function SideBar({collapse}){
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    useEffect(() => {
        collapseSidebar(collapse);
    },[collapse])
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("isAuthentic");
        localStorage.removeItem("userType");
        localStorage.removeItem("firstName");
        localStorage.removeItem("businessName");
        navigate("/login")
    }
    return(
        <>
            <Sidebar className="mycustomSidebar">
                <Menu>
                    <MenuItem className="TopMiamLogo">
                        <span className="logoNotCollapsed"><img src={Logo} /></span>
                        <span className="logoCollapsed"><img src={SmallLogo} /></span>
                    </MenuItem>
                    <MenuItem className="dashboard" icon={<GridViewRoundedIcon />} component={<NavLink to="/buyer/dashboard" className="link" />}> Dashboard </MenuItem>
                    {/* <SubMenu label="Products" icon={<ProductionQuantityLimitsSharpIcon />}> */}
                        <MenuItem icon={<ProductionQuantityLimitsSharpIcon />} component={<NavLink to="/buyer/product-list" className="link" />}>Product List</MenuItem>
                    {/* </SubMenu> */}
                    <SubMenu label="Order Management" icon={<ReceiptIcon />} active={((window.location.pathname).toLowerCase().includes(("/buyer/order-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/buyer/order-history").toLowerCase()) || window.location.pathname == "/buyer/invoice-list") ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/buyer/order-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/buyer/order-history").toLowerCase()) ||  (window.location.pathname).toLowerCase().includes(("/buyer/invoice-list").toLowerCase())) ? true : false}>
                    <MenuItem icon={<OrderListIcon/>} component={<NavLink to="/buyer/order-list" className="link" />} > Purchase Order </MenuItem>
                        <MenuItem icon={<FactCheckIcon />} component={<NavLink to="/buyer/order-history" className="link" />}> Order History </MenuItem>
                        <MenuItem icon={<FileCopyIcon />} component={<NavLink to="/buyer/invoice-list" className="link" />}> Invoice </MenuItem>
                    </SubMenu>
                   
                    <MenuItem icon={<OrderListIcon/>} component={<NavLink to="/buyer/ordersbysalesrep" className="link" />} > Orders By Sales Rep </MenuItem>
                    <MenuItem icon={<LogoutRoundedIcon/>} onClick={logout}> Logout </MenuItem>
                </Menu>
          </Sidebar>
        </>
    )
}