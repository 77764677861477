export const API_URL = (process.env.REACT_APP_ENV === 'prod')?'https://bevportfunctions20230303194850.azurewebsites.net/api/':((process.env.REACT_APP_ENV ==='test')?"https://bevportstagingapi.azurewebsites.net/api/":"http://localhost:7071/api/");
export const API_KEY = (process.env.REACT_APP_ENV ==='prod')?"m77Gbp69JRQs5X2qp9FtHnZEi7WNRrVAUeFCO_wMjO7WAzFubYz5Kg==":"DcKE2aUskjPAHMcQx7n_KsjrXOBfrEPjlKr1j-jN54yKAzFuqGEi-w==";
export const API_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SaHNnmfDYwW69FEdIL1MEPhBVbcE9DmMIBNTwy31_MY";
export const STRIPE_PK = (process.env.REACT_APP_ENV ==='prod')?"pk_live_51Mek76HQukWuUl4oWh8RhHdqQwkPL6JfFpk50Fu7F1SLYmYBBjZe9CPVs45FIqx3S7ev8flDMN2iw6tMuuIA06P600zZRgcIly":"pk_test_51Mek76HQukWuUl4obWEwZMJdi9AAFpMssc5rTlWuy85tjen9kyejMSsQBpmIneVo8qrToR9hk9HBIhP1ZBBMyW1e00uFAhjy1z";
export const BLOB_BRAND_CONTAINER = "brand-image";
export const BLOB_PRODUCT_CONTAINER = "product-image";
export const BLOB_TEAM_MEMBER_CONTAINER = "team-member";
export const BLOB_URL = (process.env.REACT_APP_ENV == 'prod')?"https://bevportfunctions20230303.blob.core.windows.net/":"https://bevportfunctions20230303.blob.core.windows.net/";
export const BASE_URL = (process.env.REACT_APP_ENV == 'prod')?"https://bevport.com":"https://kind-ocean-070bdb90f.3.azurestaticapps.net"
export const SITE_KEY = "6Ldgv_onAAAAAK88Xtu0q5WTwH28ekj6GnIxjvU0";
