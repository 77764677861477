
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png";
import privacybg from "./../../assets/images/privacy-page.jpg";
import Header from "./Header";
import Footer from "./Footer";
export default function Resource() {
    document.title = "BevPort - Press";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />
                    <section className="simplyfying Empowering TermsofuSep" id="simplys">
                        <div className="simplyfyingDiv">
                            <div className="container-fluid">
                                <div className="row upcontent">
                                    <div className="col-md-5 simplecontent aboutmainBg">
                                        <div className="simplecontentimg">
                                            <img className="img-responsive" src={aboutbgd} />
                                        </div>
                                    </div>
                                    <div className="col-md-7 manBgimg">
                                        <div className="manImg">
                                            <img className="img-responsive" src={privacybg} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="moveTopUp aboutheadphone">
                                        <div className="container">
                                            <div className="row main-contentprt">
                                                <div className="col-md-4">
                                                    <div className="textSimply muddtext">
                                                        <h1>
                                                            <span className="darkblacktext">Press</span>
                                                            Release
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-8"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="termsofusecontent" className="termscosent">
                        <div className="termsofusespara ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 press-head">
                                        <h1 >BevPort Distribution LLC Launches a First-of-its-Kind Three-Tier
                                            e-Commerce Distribution Portal for Breweries</h1>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="termmainPart">
                                            <p>
                                               <b>Tampa, Florida, May 3, 2023</b>  - BevPort Distribution LLC, a company specializing in Three-tier Digital
                                                Distribution solutions for the beer industry, has partnered with Country Malt Group (CMG) to launch a
                                                new three-tier compliant digital distribution portal for Florida. The platform is designed to help small
                                                breweries gain visibility and grow their businesses by providing them with a new option to access
                                                immediate distribution across the state of Florida.

                                            </p>
                                            <p>
                                                The BevPort Distribution Portal is the brainchild of James Williams and Philip Guana. With over 50 years
                                                of experience in the beverage industry, Williams and Guana have been involved in other e-commerce
                                                solutions with Edge Beverage Consulting. They are passionate about helping small brands get attention
                                                and grow their businesses. Phil Guana created Edge to give brands a chance to be seen by national retail
                                                chain buyers seven years ago. And now with BevPort, James and Phil are continuing to do so by creating
                                                BevPort Distribution.

                                            </p>

                                            <p>
                                                Starting in late Summer of 2023, the BevPort Distribution Portal provides brewers with a new option to
                                                enter into the state of Florida to access distribution immediately. By simply signing up, filling out
                                                licensing information, and uploading images and pricing, brewers can post their brands for sale to
                                                retailers across the state. BevPort will provide entry into key states at a 10%-15% savings in cost. The
                                                BevPort web-based platform will handle shipping, warehousing, invoicing, and collecting payments to
                                                give brewers more time and save money. </p>

                                            <p>
                                                BevPort plans to expand into key markets in 2024 across the country, providing a cost-effective ecommerce solution to distribution never before seen in the beer industry. Phil Guana, Managing Partner,
                                                says, "BevPort will revolutionize the Beer Industry and change how the three-tier system operates. Using
                                                state-of-the-art logistics and warehousing through a digital distribution portal, we will make selling beer
                                                through BevPort fast, easy, and less expensive than traditional distribution."

                                            </p>

                                            <p>
                                                “Distribution consolidation has impacted many breweries by preventing them from getting access to
                                                retailers and customers. BevPort finally gives those breweries a chance to get their beer in front of
                                                retailers and consumers, where they may have been shut out in the past or not even considered by
                                                distributors. We’re changing that by giving every brewery that wants and deserves an opportunity to give
                                                retailers and consumers access to taste their amazing Beer!” says Managing Partner James Williams.
                                            </p>

                                            <p>
                                                “Brewers currently face limited beer distribution options, long distributor approval processes, heavy fees
                                                and other related challenges,” said Bryan Bechard, President of CMG. “Country Malt Group is constantly
                                                looking for opportunities to better service our customers and ways that we can help them be successful.
                                                We’re excited to work with BevPort to help our customers gain more access to market while putting more
                                                control with regards to distribution of their products back into the hands of the brewer.”

                                            </p>

                                            <p>
                                                Country Malt Group humbly began in 1995 as North Country Malt. Now, operates 13 distribution centers
                                                located strategically across North America to ensure everything brewers and distillers need is just a truck
                                                ride away. They’re committed to being a one-stop-shop for everyday supplies and ingredients, partnering
                                                with vendors that share our values in quality. As part of the United Malt global family of brands, our staff
                                                have centuries of combined experience to support our customers with the resources they need to succeed.

                                            </p>

                                            <p>
                                                The BevPort Distribution Portal is set to disrupt the beer industry by allowing brands to bypass traditional
                                                distribution and remove the usual barriers to entry when it comes to large distributors. Retailers can now
                                                bypass Distributor Reps and place orders for products directly on the website or mobile device and
                                                receive delivery in days.

                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
}
