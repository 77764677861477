import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm"
import { STRIPE_PK } from './../../../config/constant.js';
const stripePromise = loadStripe(STRIPE_PK)
function Payment({lastUserID}){
	return(
		<>
			<Elements stripe={stripePromise}>
		        <PaymentForm lastUserID={lastUserID}/>
		    </Elements>
		</>
	)
}
export default Payment;
