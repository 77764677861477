import React, {useState} from "react";
import InputMask from 'react-input-mask';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../../config/constant.js';
import Loader from "./../Loader"
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BankInfo({handlePage, lastUserID}){
	const [bankName, setBankName] = useState("")
	const [bankNameError, setBankNameError] = useState(false)
	const [bankAddress, setBankAddress] = useState("")
	const [bankAddressError, setBankAddressError] = useState(false)
	const [routingNumber, setRoutingNumber] = useState("")
	const [routingNumberError, setRoutingNumberError] = useState(false)
	const [accountNumber, setAccountNumber] = useState("")
	const [accountNumberError, setAccountNumberError] = useState(false)
	const [accountType, setAccountType] = useState("")
	const [accountTypeError, setAccountTypeError] = useState(false)
	const [nameAccount, setNameAccount] = useState("")
	const [accountNameError, setAccountNameError] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(false)
	const handleBankName = (e) => {
		setBankNameError(false)
		setBankName(e.target.value)
	}
	const handleBankAddress = (e) => {
		setBankAddressError(false)
		setBankAddress(e.target.value)
	}
	const handleRoutingNumber = (e) => {
		setRoutingNumberError(false)
		setRoutingNumber(e.target.value)
	}
	const handleAccountNumber = (e) => {
		setAccountNumberError(false)
		setAccountNumber(e.target.value)
	}
	const handleAccountType = (e) => {
		setAccountTypeError(false)
		setAccountType(e.target.value)
	}
	const handleNameAccount = (e) => {
		setAccountNameError(false)
		setNameAccount(e.target.value)
	}
	const handleValidation = () => {
		let formIsValid = true;
		if(!bankName){
			formIsValid = false;
			setBankNameError(true)
		}
		if(!bankAddress){
			formIsValid = false;
			setBankAddressError(true)
		}
		if(!routingNumber){
			formIsValid = false;
			setRoutingNumberError(true)
		}
		if(!accountNumber){
			formIsValid = false;
			setAccountNumberError(true)
		}
		if(!accountType){
			formIsValid = false;
			setAccountTypeError(true)
		}
		if(!nameAccount){
			formIsValid = false;
			setAccountNameError(true)
		}
		return formIsValid;
	}
	const handleBankInfo = async (e) => {
		e.preventDefault()
		if(handleValidation()){
			setSubmitLoader(true)
			let formData = {
		    "ID": lastUserID,
		    "Type": "Bank",
		    "BankName": bankName,
		    "AccountNumber": accountNumber,
		    "RoutingNumber": routingNumber,
		    "AccountType": accountType,
		    "BankAddress": bankAddress
			}
			await axios.post(API_URL+"UpdateUser?code="+API_KEY,formData,config).then((res)=>{
				if(res.data.responseCode === "200"){
					handlePage(4, lastUserID)
					toast.success(res.data.responseMessage);
				}else{
					toast.error(res.data.responseMessage);
				}
				setSubmitLoader(false)
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
			})
		}
	}
	return(
		<>
		{submitLoader?<Loader />:""}
			<form onSubmit={handleBankInfo} autoComplete="off">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="firstname">Bank Name <span className="red-tick">*</span></label>
                    <input type="text" className={bankNameError?"form-control error":"form-control"} value={bankName} placeholder="Bank Name" onChange={handleBankName} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="firstname">Bank Address <span className="red-tick">*</span></label>
                    <input type="text" className={bankAddressError?"form-control error":"form-control"} value={bankAddress} placeholder="Bank Address" onChange={handleBankAddress} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="firstname">Routing Number <span className="red-tick">*</span></label>
                    <InputMask 
				      	mask='999999999' 
				      	value={routingNumber}
				      	onChange={handleRoutingNumber}
				      	className={routingNumberError?"form-control error":"form-control"}
				      	maskChar={null}
				      	placeholder="Routing Number"
				    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="firstname">Account Number <span className="red-tick">*</span></label>
                    <InputMask 
				      	mask='999999999' 
				      	value={accountNumber}
				      	onChange={handleAccountNumber}
				      	className={routingNumberError?"form-control error":"form-control"}
				      	maskChar={null}
				      	placeholder="Account Number"
				    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="firstname">Account Type <span className="red-tick">*</span></label>
                    <select className={accountTypeError?"form-control error":"form-control"} onChange={handleAccountType} value={accountType}>
                    	<option value="">Select Account Type</option>
                    	<option value="1">Checking Account</option>
                    	<option value="2">Saving Account</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="firstname">Name on Account <span className="red-tick">*</span></label>
                    <input type="text" className={accountNameError?"form-control error":"form-control"} value={nameAccount} placeholder="Name on Account" onChange={handleNameAccount} />
                  </div>
                </div>
              </div>
              
                  <div className="submitbtns">
                    <button className="btn btn-default" type="submit" id="">NEXT</button>
                  </div>
                
              </form>
		</>
	)
}