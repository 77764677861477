
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png";
import privacybg from "./../../assets/images/privacy-page.jpg";
import Header from "./Header";
import Footer from "./Footer";
export default function Resource() {
    document.title = "BevPort - Press Release";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />
                    <section className="simplyfying Empowering TermsofuSep" id="simplys">
                        <div className="simplyfyingDiv">
                            <div className="container-fluid">
                                <div className="row upcontent">
                                    <div className="col-md-5 simplecontent aboutmainBg">
                                        <div className="simplecontentimg">
                                            <img className="img-responsive" src={aboutbgd} />
                                        </div>
                                    </div>
                                    <div className="col-md-7 manBgimg">
                                        <div className="manImg">
                                            <img className="img-responsive" src={privacybg} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="moveTopUp aboutheadphone">
                                        <div className="container">
                                            <div className="row main-contentprt">
                                                <div className="col-md-4">
                                                    <div className="textSimply muddtext">
                                                        <h1>
                                                            <span className="darkblacktext">Press</span>
                                                            Release
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-8"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="termsofusecontent" className="termscosent">
                        <div className="termsofusespara ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 press-head">
                                        <h1 className="text-center">Craft Brew News</h1>
                                    </div>
                                </div>
                                <div className="row craft-head">
                                    <div class="contact">
                                        <h2>
                                        News, Numbers and More About the Craft Beer World
                                        Vol 14 No 44 | June 7, 2023
                                        </h2>
                                        <hr/>
                                            
                                              <div className="row">
                                                <div className="col-sm-6">
                                                <span class="prefix">Publisher:</span>
                                               <p>Beer Marketer's INSIGHTS, Inc.</p> 
                                                </div>
                                                <div className="col-sm-6">
                                                <span class="prefix">Senior Editors:</span>
                                                  <p> David Steinman & Christopher Shepard</p>
                                                </div>
                                              </div>
                                                    </div>
                                                </div>
                                                <div className="row issue-area">
                                                <div className="col-md-12">
                                                    <h3>In this issue : </h3>
                                                    <ul className="list-issues">
                                                        <li><b>Sierra Nevada CEO Jeff White Retiring; Founder Ken Will Retake Reins on Interim Basis</b></li>
                                                        <li><b>Bell's $$ Up 16% in May Scans; Fueled by New Brands & New Mkts</b></li>
                                                        <li><b>Sapporo-Stone Combo Up 5% YTD in NielsenIQ; Delicious, Vty Pk and Buenaveza Draft Drivers</b></li>
                                                        <li><b>  Short's Brewing Rides Longtime Bond with Sober Newgrasser Billy Strings to Expand Its Thirst Mutilator Hopped Water</b></li>
                                                        <li><b> TX's Top Cannabis Co Buys Houston-Based 8th Wonder Brewery, Distillery, Cannabis Co</b></li>
                                                        <li><b>Digital Distribution Solution BevPort Eyes Fla Launch After Partnering with Country Malt Group</b></li>
                                                        <li><b>  Openings: VA Lures Silver Branch Brewing; Several 3rd Locations; Trophy Brewing's 6th Spot</b></li>
                                                   
                                                    </ul>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="termmainPart">
                                                            <p>
                                                                <b>
                                                                    Sierra Nevada CEO Jeff White Retiring; Founder Ken
                                                                    Will Retake Reins on Interim Basis
                                                                </b>
                                                            </p>
                                                            <p>
                                                                Jeff White will step away this summer as CEO of Sierra
                                                                Nevada Brewing. Sierra owns 4th largest craft portfolio,
                                                                behind AB, Boston and Kirin-Lion, and 3d largest craft
                                                                brand family behind only New Belgium and Sam Adams, CBN
                                                                estimates. Jeff is first outside CEO following the long
                                                                tenure of Ken Grossman, who founded the company back in
                                                                1980. Ken will now "step in as CEO in the interim." Jeff
                                                                will be retiring after 4.5 yrs in role, nearly 10 yrs at
                                                                company, and 38 in beer biz (including long stint at
                                                                Boston Beer too). "Search for a new leader has already
                                                                begun," Sierra said in statement. (This is an expanded
                                                                version of an article that first appeared in sibling pub <a href="https://beerinsights.com/newsletters/insights-express" target="_blank">INSIGHTS Express</a>.)
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    <i>
                                                                        Volume Up 1% Since Takin' CEO Spot; Down from Peak;
                                                                        Hazy Little Thing Reshaped Mix Jeff  </i> </b>
                                                                leaves Sierra about 1% bigger in 2022 at 1.06 mil bbls
                                                                compared to when he took over as CEO in late 2018. Tho
                                                                down from its peak of nearly 1.12 mil bbls in 2020.
                                                                Still, that slight growth over 4 yrs is better than most
                                                                top craft brewers in those yrs, thanks to dramatic
                                                                growth of Hazy Little Thing and Little Things family
                                                                thru most of period. Since launch in 2018, Hazy Little
                                                                Thing IPA transformed Sierra Nevada's portfolio, surging
                                                                to become its #1 brand and near 1/3 of its total mix in
                                                                2022 (see <a href="https://beerinsights.com/archive-article/45670" target="_blank">Jan 16 issue</a>). It currently sits at #3
                                                                Circana-defined craft brand overall by $$ in tracked
                                                                off-prem data, behind only Blue Moon Belgian White and
                                                                New Belgium Voodoo Ranger Imperial IPA, with sales up 4%
                                                                YTD thru May 21 in multi-outlet + convenience data.
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    <i>
                                                                        Lots More Complex; Several New Directions Didn't Pan
                                                                        Out, Others Still Bubblin' in Early Days </i> </b>
                                                                Sierra Nevada biz is also a lot more complex. On Jeff's
                                                                watch, Sierra tried M&A (Sufferfest), hard kombucha
                                                                (Strainge Beast), and hard tea (Tea West), but none of
                                                                them stuck. Earlier this yr, it took minority stake in
                                                                RIOT Energy Bevs. On a smaller scale, co launched Hop
                                                                Splash hoppy non-alc seltzer and plans to test non-alc
                                                                beer. Its CanDo Innovation Center is slated to open this
                                                                summer (originally announced as opening this spring).
                                                                Recall, Sierra will be able to brew 250-500K bbls of
                                                                beyond beer products there, not just Sierra's, and
                                                                including RIOT. CanDo represents a radical departure for
                                                                Sierra, and founder Ken Grossman strongly believes in
                                                                concept and has invested significantly in its potential.
                                                                But Sierra is building out significant capacity for
                                                                CanDo even as co has plenty excess capacity between
                                                                Chico, CA and Mills River, NC facilities, and volume
                                                                remains challenged, seemingly putting pressure on
                                                                profitability short-term.
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    <i>
                                                                        Jeff "Shepherd[ed] in a New Era" for Sierra; Lotsa
                                                                        Questions </i>
                                                                </b>
                                                                Sierra is "excited for Jeff" and "grateful for his many
                                                                contributions." Jeff "helped drive" Sierra "from a beer
                                                                brewer to a beverage company, shepherding in a new era
                                                                of beyond beer products and innovation," said Sierra's
                                                                statement. Jeff "plans to stay on" at Sierra over next
                                                                coupla mos, "focusing on the eagerly anticipated opening
                                                                of our newest venture, CanDo Innovation Center." Yet
                                                                many questions also arise from announcement. Why now?
                                                                Where is Sierra Nevada in search process? What's next
                                                                for Sierra? With Ellie Preslar as head of sales and
                                                                Lesley Albright joining in early 2023 as new marketing
                                                                veep (see <a href="https://beerinsights.com/archive-article/45762" target="_blank">Jan 24 issue</a> ), Sierra Nevada continues to
                                                                welcome new cast of characters from outside the beer biz
                                                                to its exec team most recently. Will Sierra's next CEO
                                                                come from outside the industry too, or perhaps from
                                                                within?
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    <i>
                                                                        Sierra Improving in May Scans; Core Brands Shoring
                                                                        Up; $$ -0.6%, Vol -5% YTD in MULC </i> </b>
                                                                So far in 2023, Sierra Nevada beer $$ down 0.6% and
                                                                volume down 5% in Circana multi-outlet + convenience
                                                                thru 5/21. But $$ up 3% last 4 weeks and co reportedly
                                                                had good May. Hazy Little Thing saw $$ accelerate to
                                                                +10% for period and both Sierra Pale Ale (-0.5%) and
                                                                Torpedo Extra IPA (+1%) posted some of their healthiest
                                                                $$ trends in a long time.
                                                            </p>

                                                            <p>
                                                                Co's still getting nice growth from Atomic Torpedo IPA,
                                                                Hazy Little Thing rotator (Juicy Little Thing) and Hop
                                                                Splash too. Tho Big Little Thing Imperial IPA continues
                                                                to decline vs last yr's launch, as do other Little Thing
                                                                extensions, Seasonal and Variety Pk. And Sierra
                                                                non-craft brands collectively declining 45% YTD with Hop
                                                                Splash growth washed away by steep Strainge Beast and
                                                                Tea West drops (off a smaller base). Non-craft beer
                                                                represents just 0.8% of Sierra Nevada's total $$ mix in
                                                                scans this yr, including just 0.6% for latest 4 wks. How
                                                                will that change once CanDo comes online? And can core
                                                                brands keep up the improved trends?
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    Bell's $$ Up 16% in May Scans; Fueled by New Brands &
                                                                    New Mkts
                                                                </b>
                                                            </p>
                                                            <p>
                                                                Bell's is suddenly roarin' up double-digits again in
                                                                latest tracked off-prem data, thanks in large part to
                                                                sizable new brand launches and new mkt rollouts. Its $$
                                                                grew 16% to $9.9 mil with volume up 14% to 244K cases
                                                                for latest 4 wks thru May 28 in Circana multi-outlet +
                                                                convenience data. So Bell's is back among top-50 beer
                                                                brand families and #7 craft family overall by $$ in
                                                                scans for period. Recall, Bell's Hearted Variety Pk and
                                                                Hazy Hearted brands are #3 and #4 new launches in craft
                                                                so far this yr (see <a href="https://beerinsights.com/archive-article/48877" target="_blank">May 26 issue</a>). And Bell's rolled out
                                                                5 new states in April including WA, OR, UT, ID and AK.
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    Sapporo-Stone Combo Up 5% YTD in NielsenIQ; Delicious,
                                                                    Vty Pk and Buenaveza Draft Drivers
                                                                </b>
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    <i>Outpacing Even Voodoo ex-Convenience </i>
                                                                </b>
                                                                Stone's recent growth bests even the biggest segment
                                                                gainers when isolating all outlet channels in NielsenIQ
                                                                data. Stone volume up 5% for 13 wks and 10% for 52 wks
                                                                in NielsenIQ xAOC thru May 27. New Belgium is +4% and
                                                                8%, respectively, as Voodoo Ranger derives much of its
                                                                growth from c-stores. (NBB brand fam up 11% YTD across
                                                                full NielsenIQ off-premise picture.) Only 2 other top-12
                                                                craft brand fams grew volume for 13 wks in xAOC. Bell's
                                                                up similar 4% and Leinenkugel's up slight 1% in these
                                                                channels. Handful of other top craft brands off
                                                                low-mid-singles, including Sam Adams, Founders, Shiner
                                                                and Sierra. But even AB's Kona brand fam volume down 10%
                                                                as it also gets bigger growth in c-stores.
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    <i>
                                                                        Delicious & IPA Variety Drives Off-Premise Gains
                                                                    </i> </b> Delicious IPA $$ are up a solid 6.1% in NielsenIQ all
                                                                outlet scans, co shared, while new Delicious mixed sixer
                                                                is a top new craft 6-pk, as Sapporo USA CEO Maria Stipp
                                                                <a href="https://beerinsights.com/archive-article/48813" target="_blank"> commented</a> during our recent Spring Conference. Stone's
                                                                IPA vty pk is also #2 craft vty 12er behind only NBB's
                                                                Voodoo Ranger vty pk. That's in part "due to much higher
                                                                velocities" compared to packs from larger craft brand
                                                                fams like Sierra, Goose and Lagunitas, according to co's
                                                                update. Stone sees lots of "distribution runway" for
                                                                both the Delicious Mixed Pack (at 8% CWD in NielsenIQ
                                                                xAOC) and its IPA vty pk (18%).
                                                            </p>

                                                            <p>
                                                                <b><i>Widespread Growth for Buenaveza Draft </i> </b> Then there's Buenaveza, Stone's Mexican lager, with 12-pks up 69% off premise. But it's a key draft brand for the co, again as Maria noted last mo. It's up 4% on draft across US YTD thru May 31 in co's VIP data. And that includes growth across wide array of key mkts for Stone, including CA +5%, AZ +9% and VA +2%. Brand also up mid-singles in TX and NV, growing even faster in CO (+16%) and close to tripling in FL (+180%).
                                                            </p>


                                                            <p>
                                                                <b><i>"Outsized Trends" in "Early" Days,Sapporo Production Likely by Yr-End as Premium Flavor Matched" </i> </b> Even though we are early on this journey, we are already seeing outsized trends versus the overall category of beer," Sapporo USA CEO Maria Stipp commented, pointing to value-add of the brand combo to distribs, retailers and consumers alike. "Expansion in Stone's breweries is underway and we expect to be brewing Sapporo's beers by the end of the year," she added. Indeed, both Stone breweries recently successfully flavor matched full-scale test batches of Sapporo Premium, co shared. "As one organization, we are stronger than we ever were as two separate companies. This is only the beginning," Maria concluded.
                                                            </p>

                                                            <p>
                                                                <b>
                                                                    TX's Top Cannabis Co Buys Houston-Based 8th Wonder Brewery, Distillery, Cannabis Co
                                                                </b>
                                                            </p>

                                                            <p>
                                                                Flurry of craft brewer acquisitions continue to come up as challenging craft landscape is rapidly evolving before our eyes. Houston-based Bayou City Hemp Company, which describes itself as the "largest cannabis operator in Texas," struck deal to acquire Houston-based craft brewer, distiller and cannabis co, 8th Wonder. Co joins Tilray as the only other sizable cannabis co actively acquiring into the beer and bev space.
                                                            </p>

                                                            <p>
                                                                Recall, 8th Wonder first partnered with Bayou City Hemp Co to develop Delta-8 and CBD-infused seltzers in 2021 and made plans to open a Delta-8 based cannabis dispensary & lounge last Sep (see <a href="https://beerinsights.com/archive-article/43405" target="_blank">Sep 23, 2022 issue</a>). Their THC bevs hit Houston, Austin, San Antonio and Dallas mkts "earlier this year" and are available for order statewide thru Flood Distribution. Indeed, Bayou City Hemp Co plans to utilize 8th Wonder's bev expertise and "capture the Texas adult beverage market with a diversified portfolio of leading brands," playing up its unique c-suite of execs from oil and gas, bev sciences and CPG branding/marketing backgrounds. That now includes 8th Wonder brewmaster/co-founder Aaron Corsi becoming COO of the combined cos and prexy Ryan Soroka taking on "Chief Brand and Marketing Officer" role.
                                                            </p>

                                                            <p>
                                                                Bayou City Hemp is talking a big game with this relatively small acquisition. 8th Wonder peaked near 10K bbls in mid-2010s but lost a lotta biz during Covid and declined double-digits to 7K bbls of craft beer in 2022 vs 2021, Brewers Assn estimated (not including spirits and cannabis products). Yet deal has "enormous implications for both the Texas beverage industry and the U.S. cannabis market, which is expected to grow to more than $86 billion by 2030," co hyped. "This is not merely an acquisition" but "a bold declaration of our intent to lead and innovate in the heart of Texas," it went on. Bayou has big eyes, planning to distribute and sell beer, spirits and cannabis products "under one roof" thru "traditional sales channels across the country," lookin' to "compete with national players in craft beer and spirits as well as the emerging NA beer and RTD spirits categories."
                                                            </p>

                                                            <p>
                                                                Gotta note, virtually all established legal cannabis states continue to see sales decline at steep rates, including particularly tuff trends in CO lately, <a href="https://www.denverpost.com/2023/05/21/colorado-cannabis-marijuana-weed-dispensaries-downturn/" target="_blank">Denver Post recently reported</a>. Yet Bayou City Hemp plans to gain mkt share and distribution for "the entire portfolio of products through expanded resources and combined expertise," said CEO Benjamin Meggs in released statement. "We look forward to a refreshed and revitalized 8th Wonder as we move forward with Bayou City Hemp," added co-founder Ryan Soroka.
                                                            </p>
                                                            <p>
                                                                <b>Short's Brewing Rides Longtime Bond with Sober Newgrasser Billy Strings to Expand Its Thirst Mutilator Hopped Water</b>
                                                            </p>
                                                            <p>
                                                                In past coupla years, contemporary bluegrass guitar shredder who goes by Billy Strings has become bona fide pop phenomenon, riding blend of newgrass and jam band styles to Grammy Award, sold-out tours and frequent appearances on late-night TV. It hasn't worked out too badly for Short's Brewing, either. The Bellaire, MI brewery, where Strings played open mic shows in his early days a decade ago, collaborated with musician a year ago on hopped sparkling water with the Liquid Death-style name Thirst Mutilator. (This article also appeared in sibling pub <a href="https://beerinsights.com/newsletters/beverage-business-insights" target="_blank">Beverage Business INSIGHTS</a>.)
                                                            </p>
                                                            <p>It was brewery's first non-alc item after what founder Joe Short claims in new <a href="https://www.youtube.com/watch?v=7lvdZqUWSPY" target="_blank">video</a>  was 10 years of his urging. Mutilator was logical match, not just because of partners' long association but because Strings' career ignited only after he kicked alcohol and drug issues a few years ago to adopt "California sober" lifestyle, per title of musical collaboration he just did with Willie Nelson to celebrate that musician's 90th birthday. ("I don't get to acting mean when I keep my buzzes clean," as they sing, referring to pot as their only stimulant these days.)</p>
                                                            <p>
                                                                The hopped water has done well enough that, on first-year anniversary, brewery has expanded it from 6-pks of 12-oz cans to 12-pks of 16-oz cans. With its mild hop flavor, "we quickly realized that a larger can is ideal for places like concerts, bars and golf courses," explained sales dir Kerry Lynch. And Short's will ride the Strings wave by sampling 15,000 tailgating fans at Pine Knob outdoor amphitheater in Independence Township on Jun 16, largest show that Strings has headlined yet.
                                                            </p>
                                                            <p>
                                                                <b>Digital Distribution Solution BevPort Eyes Fla Launch After Partnering with Country Malt Group</b>
                                                            </p>
                                                            <p>
                                                                A new beer distrib in Florida aims to operate with a fundamentally different model, using a central warehouse and digital retail-focused platform to help more small brands reach the market. The brain-child of biz partners Phil Guana and James Williams, with over a decade of friendship and experience selling hi-end imports, cider and craft brands, BevPort leverages the warehouses and logistics expertise of partner Country Malt Group, a major ingredient supplier to small US brewers. CMG's cautious approach means BevPort is not only taking time to perfect its digital portal, but also to ensure all its regulatory t's are crossed and i's dotted. All that work is nearing completion and co aims to launch sales thru its portal in FL this summer or fall, Phil and James explained to CBN with prexy of warehouse and distribution for CMG parent co United Malt, Bryan Bechard.
                                                            </p>

                                                            <p>
                                                                <b>Initial Model Set for FL Launch</b> After working up and down the US beer supply chain, Phil and James formed Edge Beverage to help small brewers and brands with sales strategy and distribution expansion, eventually becoming importers, too. By early 2021, they saw an oppy for an "online distribution model" but not how to make it work, Phil explained. Models like LibDib and Park Street got going in wine & spirits, James reminded, so the pair sought to build something similar for beer.
                                                            </p>

                                                            <p>
                                                                Once launched, small brands can sign a fairly bare bones distribution agreement with BevPort, a licensed distrib in FL, and then upload info on their portfolio onto the co's digital portal. BevPort will hold product at dedicated, leased areas of CMG's warehouse. Retailer orders placed thru the portal will arrive on leased trucks from a delivery partner who already services almost 6,000 accounts in the state (tho that partner still under wraps).
                                                            </p>

                                                            <p>
                                                                <b><i>"Growing Pains," Evolution Expected </i> </b> But that's only the roll-out model for BevPort's 1st state. All the partners understand that it will likely need to evolve with the biz and in new states. "The policy is key," James said. They have a "whole team of lawyers working on it to try to make it work." And "each state has different requirements," Bryan knows, so the model may not work at all in some states. For a co the size of CMG and United Malt, it was essential to "work within the statutes," so BevPort is "going to walk before we run," Bryan said. They're expecting "growing pains," James added. But they're also aiming for a much bigger rollout in coming years. If it can "cover most major markets in five years, that's a success for us," Phil said.
                                                            </p>

                                                            <p>
                                                                <b><i>Serving Small Brands with Distribution "Minor Leagues"</i></b> BevPort is "here to help the little guy get a piece of the pie with a new way of doing it," Phil summed up. And tho they bill the model as game-changing, they also seem to understand that it's more likely to fill in gaps than replace large swaths of the biz. Because it's built specifically for the needs of small brands that don't fit well into the biz models of standard beer distribs.
                                                            </p>

                                                            <p>
                                                                "Due to the increased consolidation" in the tier, many distribs are "requiring a lot more in order to even consider brands," like having "multiple reps" in the market or state-wide chain approval, James commented. "These are high marks to hit," he said. BevPort doesn't expect any of that. Its service model is clearly different, too. So co plans to work on much lower margin, currently estimated in 10-15% range rather than more standard 30-35%, Phil shared. Platform could come to act as distribution "minor leagues," in Bryan's view, providing a place for a brand to seed a market and grow into scale that would make more sense in a traditional distrib.
                                                            </p>

                                                            <p>
                                                                <b>"Credibility" Added by "Serendipitous" CMG Partnership; Small Brewers Interested</b> But why did a supplier who usually works upstream of a brewery get interested? Country Malt Group is always focused on "driving value for customers," Bryan explained and also "constantly looking for ways for our customers to grow their business." So impediments to its small brewer clients being able to expand production also become blockages to CMG's growth. It was "really serendipitous" when Bryan reached out wondering if there was a way to work with Edge Beverage, Phil explained. It's become a "symbiotic relationship," in Bryan's view. CMG has long positioned itself to small brewers as "trying to provide you service on the products you're buying in." But now it's asking "how can I help you sell? How can I help you solve more problems?"
                                                            </p>

                                                            <p>
                                                                And small brewers are listening. CMG's involvement "lends credibility," Phil said. And potential brand owners showed plenty of interest during "dozens and dozens" of discussions during CBC. Onboarding for FL launch is in process. The portal "starts to really work after about a hundred brands," James thinks. That's when platform is "legitimized in the eyes of the accounts" and when there's "potential volume."
                                                            </p>

                                                            <p>
                                                                <b>Openings: VA Lures Silver Branch Brewing; Several 3rd Locations; Trophy Brewing's 6th Spot</b>
                                                            </p>

                                                            <p>
                                                                It's been a tuff coupla weeks for Maryland's craft beer scene. On top of Flying Dog and DuClaw both planning to close their production facilities, now a separate MD up-and-comer is opting to open its 2nd outpost in Virginia after the state "successfully competed with Maryland for the project," VA gov Glenn Youngkin <a href="https://www.governor.virginia.gov/newsroom/news-releases/2023/june/name-1004246-en.html" target="_blank">announced</a> on Mon.
                                                            </p>

                                                            <p>
                                                                Based out of Silver Spring MD, <b>Silver Branch Brewing</b> will invest $3 mil to open a 2nd spot in Warrenton, VA. It'll take over the former Wort Hog Brewing facility, including a brewery, taproom and restaurant with project expected to add 38 new jobs. VA will support the move thru Jobs Investment Program, plus Silver Branch co-founders grew up in Virginia. Co was founded in 2019 and did just under 4K bbls in 2022, Brewers Assn estimates. But it plans to add capacity and "increase its ability to brew a wider variety of products."
                                                            </p>

                                                            <p>
                                                                <b><i>MadTree, Wild Heaven, Grains of Wrath Adding 3rd Outposts </i> </b>  Cincy-based <b>MadTree Brewingwill</b> look to plant new roots for its 3rd brewery/restaurant location, this time in Blue Ash, OH. "MadTree Parks & Rec" aims to convert a 15K sq-ft airplane hangar into an outdoor "destination surrounded by one of Cincinnati's most popular park settings" at Summit Park (~20 min north of the city), co announced this morn. Blue Ash city council is scheduled to vote on the project tomorrow. While plans are still in development, space would feature "massive outdoor area" with cornhole, walking trails and more.
                                                            </p>

                                                            <p>
                                                                In Atlanta, <b>Wild Heaven Beer</b> plans to debut its 3rd location early next yr, partnering with Fox Bros BBQ to run its food program. The 5K sq-ft space is <a href="https://www.ajc.com/things-to-do/atlanta-restaurant-blog/wild-heaven-beer-to-open-third-location-featuring-fox-bros-bar-b-q/NXGMQVWMCJC7FJ6JEO25QDJCIU/" target="_blank">set to open</a> within the Toco Hills Shopping Center, featuring onsite brewery, distillery and taproom. Camas, WA's <b>Grains of Wrath Brewing</b> <a href="https://newschoolbeer.com/home/2023/6/grains-of-wrath-brewing-opening-third-location-in-washougal" target="_blank">will add</a> its 3rd outpost as well, coming to Port of Washington's recently completed Building 20 site. That location will be "designed for brewing first" and aims to "get more beer in the pipeline," with hopes of beginning to brew there before summer is out.
                                                            </p>

                                                            <p>
                                                                <b><i> Trophy Brewing Bought $4 Mil Building; Frothy Beard to Take Over Bhramari Charlotte </i></b> NC's <b>Trophy Brewing</b> is at it again, expanding to Raleigh's Five Points neighborhood for co's 6th overall location. It <a href="https://www.bizjournals.com/triangle/news/2023/06/05/trophy-brewing-to-open-new-raleigh-taproom.html?utm_source=st&utm_medium=en&utm_campaign=me&utm_content=RA&ana=e_RA_me&j=31704043&senddate=2023-06-06" target="_blank"></a>bought a 22K sq-ft former Electrical Supply Co building for $4.1 mil in May, which will eventually serve as both a taproom and restaurant. Another brewer also found a home in Charlotte, taking over the space <a href="https://www.instagram.com/p/CsoHXDyOkUD/?img_index=1" target="_blank">formerly occupied</a> by Bhramari Brewing. Charleston, SC's <b>Frothy Beard Brewing</b> will open in city's South End this summer. It'll mark co's 1st NC location.
                                                            </p>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </section>
                                <Footer />
                            </div>
                        </div>
                    </>
                    );
}
