

import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png"
import privacybg from "./../../assets/images/minimum-order-re.jpg"
import InputMask from 'react-input-mask';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, SITE_KEY } from './../../config/constant';
import Header from './Header';
import Footer from './Footer';
import Loader from "./Loader"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
export default function Resource(){
	document.title = "BevPort - Join The Revolution"
	useEffect(() => {
	  	window.scrollTo(0, 0)
	}, [])
	const [userType, setUserType] = useState("")
	const [userTypeError, setUserTypeError] = useState(false)
	const [firstName, setFirstName] = useState("")
	const [firstNameError, setFirstNameError] = useState(false)
	const [lastName, setLastName] = useState("")
	const [lastNameError, setLastNameError] = useState(false)
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [mobile, setMobile] = useState("")
	const [mobileError, setMobileError] = useState(false)
	const [title, setTitle] = useState("")
	const [titleError, setTitleError] = useState(false)
	const [companyName, setCompanyName] = useState("")
	const [companyNameError, setCompanyNameError] = useState(false)
	const [question, setQuestion] = useState("")
	const [questionError, setQuestionError] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(false);
	const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    useEffect(() => {
    	const loadScriptByURL = (id, url, callback) => {
	    	const isScriptExist = document.getElementById(id);
	    	if (!isScriptExist) {
	      		var script = document.createElement("script");
	      		script.type = "text/javascript";
	      		script.src = url;
	      		script.id = id;
	      		script.onload = function () {
	        		if (callback) callback();
	      		};
	      		document.body.appendChild(script);
	    	}
	    	if (isScriptExist && callback) callback();
	  	}
	  	loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
	    	console.log("Script loaded!");
	  	});
	}, []);

	const handleValidation = () => {
		let formValid = true
		if(!userType){
			setUserTypeError(true)
			formValid = false
		}
		if(!firstName){
			setFirstNameError(true)
			formValid = false
		}
		if(!lastName){
			setLastNameError(true)
			formValid = false
		}
		if(!email){
			setEmailError(true)
			formValid = false
		}
		if (!validateEmail(email)) {
            formValid = false;
            setEmailError(true)
        }
		if(!mobile){
			setMobileError(true)
			formValid = false
		}
		if(!title){
			setTitleError(true)
			formValid = false
		}
		if(!companyName){
			setCompanyNameError(true)
			formValid = false
		}
		if(!question){
			setQuestionError(true)
			formValid = false
		}
		return formValid;
	}
	const handleInputFunction = (name, e) => {
		if(name === "userType"){
			setUserType(e.target.value)
			setUserTypeError(false)
		}
		if(name === "firstName"){
			setFirstName(e.target.value)
			setFirstNameError(false)
		}
		if(name === "lastName"){
			setLastName(e.target.value)
			setLastNameError(false)
		}
		if(name === "email"){
			setEmail(e.target.value)
			setEmailError(false)
		}
		if(name === "mobile"){
			setMobile(e.target.value)
			setMobileError(false)
		}
		if(name === "title"){
			setTitle(e.target.value)
			setTitleError(false)
		}
		if(name === "companyName"){
			setCompanyName(e.target.value)
			setCompanyNameError(false)
		}
		if(name === "question"){
			setQuestion(e.target.value)
			setQuestionError(false)
		}
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if(handleValidation()){
			window.grecaptcha.ready(() => {
			    window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async token => {
			    	setSubmitLoader(true)
			    	let formData = {
						UserType: userType,
						FirstName: firstName,
						LastName: lastName,
						emailid: email,
						MobileNumber: mobile,
						Title: title,
						CompanyName: companyName,
						Questions: question,
						CaptchaToken: token
					}
					await axios.post(API_URL + "JoinTheRevolution?code=" + API_KEY, formData, config).then((res) => {
		                if (res.data.responseCode === "200") {
		                	toast.success(res.data.responseMessage);
		                	setUserType("")
							setFirstName("")
							setLastName("")
							setEmail("")
							setMobile("")
							setTitle("")
							setCompanyName("")
							setQuestion("")
		                } else {
		                    toast.error(res.data.responseMessage);
		                }
		                setSubmitLoader(false)
		            }).catch(function (error) {
		                setSubmitLoader(false)
		            })
			      console.log(token);
			    });
			});
		}
	}
	return(
		<>
		{submitLoader ? <Loader /> : ""}
		<div className="site-wrapper">
        		<div className="main-wrapper">
			<Header />
			<section className="simplyfying Empowering TermsofuSep" id="simplys">
				<div className="simplyfyingDiv">
				  <div className="container-fluid">
				    <div className="row upcontent">
				      <div className="col-md-5 simplecontent aboutmainBg"> 
				        <div className="simplecontentimg"> 
				          <img className="img-responsive" src={aboutbgd} />  
				        </div>    
				      </div>
				      <div className="col-md-7 manBgimg">
				        <div className="manImg">
				          <img className="img-responsive" src={privacybg} />
				        </div>
				      </div>
				    </div>
				    <div className="row">
				    <div className="moveTopUp aboutheadphone">
				      <div className="container">
				        <div className="row main-contentprt">
				          <div className="col-md-4">
				            <div className="textSimply muddtext">
				              <h1><span className="darkblacktext">Join The</span>Revolution</h1>                          
				            </div>
				          </div>
				          <div className="col-md-8">
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>

				</div>
				</section>

<section className="jointheRv" id="joinrevid">
<div className="joinrevdivision">
	<div className="container">
		<div className="row">
			<div className="col-md-12">
				<div className="form-headings jointheRevpara">
					<h2>Join The Revolution</h2>	
					<p>Come join the BevPort revolution and change the beer industry forever.</p>
					<p>Share your contact information and once we are ready to launch, someone from our team will reach out to you and get you onboarded. We are excite to have you join BevPort.</p>				
				</div>

				<div className="forminpuitsJoin">
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label for="firstname">User Type <span className="red-tick">*</span></label>
								<select value={userType} className={userTypeError?"form-control error":"form-control"} onChange={(e) => handleInputFunction("userType", e)}>
									<option value="">Select User Type</option>
									<option value="1">Brewer</option>
									<option value="2">Buyer</option>
								</select>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label for="firstname">First Name <span className="red-tick">*</span></label>
								<input type="text" className={firstNameError?"form-control error":"form-control"} value={firstName} onChange={(e) => handleInputFunction("firstName", e)} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label for="firstname">Last Name <span className="red-tick">*</span></label>
								<input type="text" className={lastNameError?"form-control error":"form-control"} value={lastName} onChange={(e) => handleInputFunction("lastName", e)} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label for="firstname">Email <span className="red-tick">*</span></label>
								<input type="text" className={emailError?"form-control error":"form-control"} value={email} onChange={(e) => handleInputFunction("email", e)} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label for="firstname">Mobile Number <span className="red-tick">*</span></label>
								 <InputMask mask='(999) 999 9999' className={mobileError?"form-control error":"form-control"} value={mobile} onChange={(e) => handleInputFunction("mobile", e)} maskChar={null} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label for="firstname">Title <span className="red-tick">*</span></label>
								<input type="text" className={titleError?"form-control error":"form-control"} value={title} onChange={(e) => handleInputFunction("title", e)} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label for="firstname">Company Name <span className="red-tick">*</span></label>
								<input type="text" className={companyNameError?"form-control error":"form-control"} value={companyName} onChange={(e) => handleInputFunction("companyName", e)} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label for="firstname">Let Us Know If You Have Any Questions <span className="red-tick">*</span></label>
								<textarea className={questionError?"form-control error":"form-control"} id="exampleFormControlTextarea1" rows="3" value={question} onChange={(e) => handleInputFunction("question", e)}></textarea>
							</div>
						</div>						
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="regitrationBtns">
								<button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit}>Submit</button>
							</div>
						</div>
					</div>




				</div>

			</div>
		</div>
	</div>
</div>
</section>
<Footer />
</div>
</div>	
				
		</>
	)
}