import React, { useState, useEffect } from "react";
import Loader from "./../../front/Loader.js";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../../config/constant.js';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function PriceCalculator({ packSizeD, caseSizeD, productID, handleActiveTab, handleProductID, productData, canSize }) {

	const [submitLoader, setSubmitLoader] = useState(false)
	const [packSize, setPackSize] = useState(6)
	const [caseSize, setCaseSize] = useState(24)
	const [canSizeP, setCanSize] = useState(8.4)
	/*const [stateCaseTax, setStateCaseTax] = useState(1.08)
	const [statePackTax, setStatePackTax] = useState(0)
	const [stateUnitTax, setStateUnitTax] = useState(0)*/
	const [stateCaseTax, setStateCaseTax] = useState(0)
	const [statePackTax, setStatePackTax] = useState(0)
	const [stateUnitTax, setStateUnitTax] = useState(0)
	const [amountPer, setAmountPer] = useState("case")
	const [type, setType] = useState("SRP")
	const [amountIn, setAmountIn] = useState(0)
	const [amountInTmp, setAmountInTmp] = useState(0)
	const [amountError, setAmountError] = useState(false)
	const [unitCasePrice, setUnitCasePrice] = useState(0)
	const [packPrice, setPackPrice] = useState(0)
	const [unitPrice, setUnitPrice] = useState(0)
	/*const [cmcDeliveryFeeCase, setCmcDeliveryFeeCase] = useState(3.50)
	const [cmcDeliveryFeePack, setCmcDeliveryFeePack] = useState(0)
	const [cmcDeliveryFeeUnit, setCmcDeliveryFeeUnit] = useState(0)*/
	const [cmcDeliveryFeeCase, setCmcDeliveryFeeCase] = useState(0)
	const [cmcDeliveryFeePack, setCmcDeliveryFeePack] = useState(0)
	const [cmcDeliveryFeeUnit, setCmcDeliveryFeeUnit] = useState(0)
	const [bevDeliveryFeeCase, setBevDeliveryFeeCase] = useState(5.00)
	const [bevDeliveryFeePack, setBevDeliveryFeePack] = useState(0)
	const [bevDeliveryFeeUnit, setBevDeliveryFeeUnit] = useState(0)
	const [bpMargin, setBPMargin] = useState(10.00)
	const [avMargin, setAVMargin] = useState(28)
	const [caseListingPrice, setCaseListingPrice] = useState(0)
	const [packListingPrice, setPackListingPrice] = useState(0)
	const [unitListingPrice, setUnitListingPrice] = useState(0)
	const [caseSRP, setCaseSRP] = useState(0)
	const [packSRP, setPackSRP] = useState(0)
	const [unitSRP, setUnitSRP] = useState(0)
	const [caseBPPrice, setCaseBPPrice] = useState(0)
	const [packBPPrice, setPackBPPrice] = useState(0)
	const [unitBPPrice, setUnitBPPrice] = useState(0)
	const [listPricemanual, setListPricemanual] = useState(0)
	const [unitPriceListTemp, setUnitPriceListTemp] = useState(0)
	const [packPriceListTemp, setPackPriceListTemp] = useState(0)
	const [casePriceListTemp, setCasePriceListTemp] = useState(0)
	const [unitPriceList, setUnitPriceList] = useState("")
	const [packPriceList, setPackPriceList] = useState("")
	const [casePriceList, setCasePriceList] = useState("")
	const [valueUpd, setValueUpd] = useState(false)
	const [caseAmountError, setCaseAmountError] = useState(false)
	const [packAmountError, setPackAmountError] = useState(false)
	const [unitAmountError, setUnitAmountError] = useState(false)
	const [cmcDeliveryFeePackEdit, setCmcDeliveryFeePackEdit] = useState(true)
	const [cmcDeliveryFeeUnitEdit, setCmcDeliveryFeeUnitEdit] = useState(true)
	const [stateTaxPackEdit, setStateTaxPackEdit] = useState(true)
	const [stateTaxUnitEdit, setStateTaxUnitEdit] = useState(true)
	const [amountInEdit, setAmountInEdit] = useState(false)
	useEffect(() => {
		if (packSizeD || caseSizeD) {
			setPackSize(packSizeD)
			setCaseSize(caseSizeD)
			setCanSize(canSize);

			// amountInTmp ---> is always the value in the price calculator text box
			let div = caseSizeD / packSizeD;
			setAmountIn(amountInTmp * div);

		}
	}, [packSizeD, caseSizeD])
	const calculateListingPrice = (unitCasePrice, cmcDeliveryFeeCase, bevDeliveryFeeCase) => {
		let g27 = parseFloat(unitCasePrice) + parseFloat(cmcDeliveryFeeCase);
		let g28 = parseFloat(bpMargin) / 100;
		return g27 * (1 + parseFloat(g28)) + bevDeliveryFeeCase;
	}
	const srpCalculate = (g29) => {
		//return g29 * (1 + parseFloat(avMargin) / 100)
		return g29 / (1 - parseFloat(avMargin) / 100)
	}

	useEffect(() => {

		if (productData) {
			//------------------------------------------------------Set Sales Tax----------------------------------------------------------------------
			
			var oz = canSize;
			var caseSize = caseSizeD;

			var cal1 = 0;
			var cal2 = 0;
			cal1 = oz * caseSize;
			cal2 = cal1 * 0.00375;
			cal2 = cal2.toFixed(2); // round to 2 decimal places

			setStateCaseTax(cal2);

			//
			var oz2 = canSize;
			var caseSize2 = 4;

			var cal12 = 0;
			var cal22 = 0;
			cal12 = oz2 * caseSize2;
			cal22 = cal12 * 0.00375;
			cal22 = cal22.toFixed(2); // round to 2 decimal places

			setStatePackTax(cal22);


			//
			var oz3 = canSize;
			var caseSize3 = 1;

			var cal13 = 0;
			var cal23 = 0;
			cal13 = oz3 * caseSize3;
			cal23 = cal13 * 0.00375;
			cal23 = cal23.toFixed(2); // round to 2 decimal places

			setStateUnitTax(cal23);


		}
	})
	useEffect(() => {
		let amountIntmp = (amountIn && amountIn != ".") ? amountIn : 0
		let cmcDeliveryFeeCaseTmp = cmcDeliveryFeeCase?cmcDeliveryFeeCase:0;
		let stateCaseTaxTmp = stateCaseTax?stateCaseTax:0;
		//if(amountIn && amountIn != "."){
		if (type === "FOB") {
			setUnitCasePrice(amountIntmp)
			setBPMargin(10.00)
			setAVMargin(28)
			let packByDevide = caseSize / packSize
			setPackPrice(amountIntmp / packByDevide)
			setUnitPrice(amountIntmp / caseSize)
			if(stateTaxPackEdit){
				setStatePackTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / packByDevide):0)
			}
			if(stateTaxUnitEdit){
				setStateUnitTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / caseSize):0)
			}
			
			if(cmcDeliveryFeePackEdit){
				setCmcDeliveryFeePack(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / packByDevide):0)
			}
			if(cmcDeliveryFeeUnitEdit){
				setCmcDeliveryFeeUnit(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / caseSize):0)
			}
			
			setBevDeliveryFeePack(bevDeliveryFeeCase / packByDevide)
			setBevDeliveryFeeUnit(bevDeliveryFeeCase / caseSize)
			//let caseLP = calculateListingPrice(amountIntmp, cmcDeliveryFeeCaseTmp, bevDeliveryFeeCase)
			let caseLP = priceConverter((parseFloat(amountIntmp) + parseFloat(cmcDeliveryFeeCaseTmp) + parseFloat(stateCaseTaxTmp)))
			//let caseLPCalculate = ((parseFloat(caseLP)+bevDeliveryFeeCase)/(1-0.10))
			let caseLPCalculate = ((parseFloat(caseLP))/(1-0.10))+bevDeliveryFeeCase
			setCaseListingPrice(priceConverter(caseLPCalculate))
			//let packLP = calculateListingPrice((amountIntmp / packByDevide), (cmcDeliveryFeeCaseTmp / packByDevide), (bevDeliveryFeeCase / packByDevide))
			let packLP = priceConverter(parseFloat(amountIntmp / packByDevide) + parseFloat(cmcDeliveryFeeCaseTmp / packByDevide) + parseFloat(stateCaseTaxTmp / packByDevide))
			let packLPCalculate = ((parseFloat(packLP))/(1-0.10))+ parseFloat(bevDeliveryFeeCase / packByDevide)
			setPackListingPrice(priceConverter(packLPCalculate))
			//let unitLP = calculateListingPrice((amountIntmp / caseSize), (cmcDeliveryFeeCaseTmp / caseSize), (bevDeliveryFeeCase / caseSize))
			let unitLP = priceConverter(parseFloat(amountIntmp / caseSize) + parseFloat(cmcDeliveryFeeCaseTmp / caseSize) + parseFloat(stateCaseTaxTmp / caseSize))
			let unitLPCalculate = ((parseFloat(unitLP))/(1-0.10))+ parseFloat(bevDeliveryFeeCase / caseSize)
			setUnitListingPrice(priceConverter(unitLPCalculate))
			/*setCaseSRP(srpCalculate(caseLP))
			setPackSRP(srpCalculate(packLP))
			setUnitSRP(srpCalculate(unitLP))*/
			setCaseSRP(srpCalculate(caseLPCalculate))
			setPackSRP(srpCalculate(packLPCalculate))
			setUnitSRP(srpCalculate(unitLPCalculate))
			setCaseBPPrice(priceConverter(parseFloat(amountIntmp) + parseFloat(cmcDeliveryFeeCaseTmp) + parseFloat(stateCaseTaxTmp)))
			setPackBPPrice(priceConverter(parseFloat(amountIntmp / packByDevide) + parseFloat(cmcDeliveryFeeCaseTmp / packByDevide) + parseFloat(stateCaseTaxTmp / packByDevide)))
			setUnitBPPrice(priceConverter(parseFloat(amountIntmp / caseSize) + parseFloat(cmcDeliveryFeeCaseTmp / caseSize) + parseFloat(stateCaseTaxTmp / caseSize)))
		} else if (type === "SRP") {
			setUnitCasePrice(amountIntmp)
			let packByDevide = caseSize / packSize
			setPackPrice(amountIntmp / packByDevide)
			setUnitPrice(amountIntmp / caseSize)
			setBPMargin(10.00)
			setAVMargin(28)
			/*setStatePackTax(priceConverter(stateCaseTaxTmp / packByDevide))
			setStateUnitTax(priceConverter(stateCaseTaxTmp / caseSize))*/
			if(stateTaxPackEdit){
				setStatePackTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / packByDevide):0)
			}
			if(stateTaxUnitEdit){
				setStateUnitTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / caseSize):0)
			}
			/*setCmcDeliveryFeePack(priceConverter(cmcDeliveryFeeCaseTmp / packByDevide))
			setCmcDeliveryFeeUnit(priceConverter(cmcDeliveryFeeCaseTmp / caseSize))*/
			if(cmcDeliveryFeePackEdit){
				setCmcDeliveryFeePack(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / packByDevide):0)
			}
			if(cmcDeliveryFeeUnitEdit){
				setCmcDeliveryFeeUnit(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / caseSize):0)
			}
			setBevDeliveryFeePack(bevDeliveryFeeCase / packByDevide)
			setBevDeliveryFeeUnit(bevDeliveryFeeCase / caseSize)
			let bpCaseLP = amountIntmp * (1 - .28)
			let bpPackLP = (amountIntmp / packByDevide) * (1 - .28)
			let bpUnitLP = (amountIntmp / caseSize) * (1 - .28)




			// 24 Unit - Bevport Listing Price
			// --------------------- 24 unit case Bevport Listing Price ------------------------------------------------
			var a1 = avMargin / 100;
			var BevportListingPrice_24Unit = amountIn * (1 - a1);
			setCaseListingPrice(BevportListingPrice_24Unit);
			//----------------------------------------------------------------------------------------------------------

			// 4 pack -  Bevport Listing Price -------------------------------------------------------------------------
			var a2 = avMargin / 100;
			var FourPackPrice = amountIn / 6;
			var BevportListingPrice_4Pack = FourPackPrice * (1 - a2);
			setPackListingPrice(BevportListingPrice_4Pack);
			//-----------------------------------------------------------------------------------------------------------

			// Unit - Bevport Listing Price-------------------------------------------------------------------------------
			var a3 = avMargin / 100;
			var pUnitPrice = amountIn / 24;
			var BevportListingPrice_Unit = pUnitPrice * (1 - a3);
			setUnitListingPrice(BevportListingPrice_Unit);
			//------------------------------------------------------------------------------------------------------------



			// Just Bevport Price
			// ----------------- 24 Case - Bevport Price ----------------------------------------------------------------------------------------
			var a4 = ((BevportListingPrice_24Unit - bevDeliveryFeeCase) - (stateCaseTax)) - ((BevportListingPrice_24Unit - bevDeliveryFeeCase) * 0.1);
			setCaseBPPrice(a4);
			//-----------------------------------------------------------------------------------------------------------------------------------

			// ----------------- 4 Pack - Bevport Price -----------------------------------------------------------------------------------------
			var a5 = ((BevportListingPrice_4Pack - bevDeliveryFeePack) - (statePackTax)) - ((BevportListingPrice_4Pack - bevDeliveryFeePack) * 0.1);
			setPackBPPrice(a5);
			//-----------------------------------------------------------------------------------------------------------------------------------

			// ----------------- Unit - Bevport price -------------------------------------------------------------------------------------------
			var a6 = ((BevportListingPrice_Unit - bevDeliveryFeeUnit) - (stateUnitTax)) - ((BevportListingPrice_Unit - bevDeliveryFeeUnit) * 0.1);
			setUnitBPPrice(a6);
			//-----------------------------------------------------------------------------------------------------------------------------------



			// FOB  - 24 unit case 
			var a7 = (a4 - cmcDeliveryFeeCaseTmp); // bevDeliveryFeeCase
			setCaseSRP(a7);
			//--------------------------------------------------------------------------------------------

			// FOB - 4 Pack
			var a8 = (a5 - cmcDeliveryFeePack); // bevDeliveryFeePack
			setPackSRP(a8);
			//--------------------------------------------------------------------------------------------

			// FOB - Unit
			var a9 = (a6 - cmcDeliveryFeeUnit); // bevDeliveryFeeUnit
			setUnitSRP(a9);
			//--------------------------------------------------------------------------------------------



		}
	

	}, [amountPer, type, amountIn, cmcDeliveryFeeCase, cmcDeliveryFeePackEdit, stateCaseTax, bevDeliveryFeePack, bevDeliveryFeeUnit])
	useEffect(() => {
		setUnitPriceListTemp(parseFloat(unitListingPrice).toFixed(2))
		setPackPriceListTemp(parseFloat(packListingPrice).toFixed(2))
		setCasePriceListTemp(parseFloat(caseListingPrice).toFixed(2))
		if(amountInEdit){
			setUnitPriceList(parseFloat(unitListingPrice).toFixed(2))
			setPackPriceList(parseFloat(packListingPrice).toFixed(2))
			setCasePriceList(parseFloat(caseListingPrice).toFixed(2))
		}
	}, [caseListingPrice, amountPer, amountInEdit, type])
	useEffect(() => {
		if (amountPer === "case") {
			setAmountIn(amountInTmp)
		} else if (amountPer === "pack") {
			let div = caseSize / packSize
			setAmountIn(amountInTmp * div)
		} else if (amountPer === "unit") {
			setAmountIn(amountInTmp * caseSize)
		}
	}, [amountPer, amountInTmp])
	const handleAmountInFun = (e) => {
		setAmountIn(e.target.value)
		setAmountInTmp(e.target.value)
		setAmountError(false);
		setValueUpd(false)
		setAmountInEdit(true)
	}
	const handleFloatNumber = (e) => {
		if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57) ) {
		    e.preventDefault();
		}
	}
	const priceConverter = (amount) => {
		return parseFloat(amount).toFixed(2)
	}
	const handleTypeOption = (e) => {
		setType(e.target.value)
		setValueUpd(false)
		setAmountInEdit(true)
	}
	const handleAmountPer = (e) => {
		setAmountPer(e.target.value)
		setValueUpd(false)
		setAmountInEdit(true)
	}

	const handleListingPriceFun = (e) => {
		setListPricemanual(e.target.value)
	}
	const handleCasePriceListFun = (e) => {
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		let getunitPrice = v / caseSize;
		setUnitPriceList(parseFloat(getunitPrice).toFixed(2))
		setPackPriceList(parseFloat(getunitPrice * packSize).toFixed(2))
		setCasePriceList(e.target.value === '.'?"0.":e.target.value)
		setUnitAmountError(false)
		setPackAmountError(false)
		setCaseAmountError(false)
		setAmountInEdit(false)
		if(e.target.value){
			if(type === "SRP"){
				let caseLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase)
				let amountCalc = caseLpCalc / (1 - .28)
				if(amountPer === "case"){
					setAmountInTmp(parseFloat(amountCalc).toFixed(2))
				}
				if(amountPer === "pack"){
					setAmountInTmp(parseFloat((amountCalc/caseSize)*packSize).toFixed(2))
				}
				if(amountPer === "unit"){
					setAmountInTmp(parseFloat(amountCalc/caseSize).toFixed(2))
				}
			}
			if(type === "FOB"){
				let cmcDeliveryFeeCaseTmp = cmcDeliveryFeeCase?cmcDeliveryFeeCase:0;
				let stateCaseTaxTmp = stateCaseTax?stateCaseTax:0;
				let caseLpCalc = (parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase))*(1-0.10)
				let amountCalc = caseLpCalc - parseFloat(cmcDeliveryFeeCaseTmp) - parseFloat(stateCaseTaxTmp)
				if(amountPer === "case"){
					setAmountInTmp(parseFloat(amountCalc).toFixed(2))
				}
				if(amountPer === "pack"){
					setAmountInTmp(parseFloat((amountCalc/caseSize)*packSize).toFixed(2))
				}
				if(amountPer === "unit"){
					setAmountInTmp(parseFloat(amountCalc/caseSize).toFixed(2))
				}
			}
		}else{
			setAmountInTmp(0)
		}
		
	}
	const handlePackPriceListFun = (e) => {
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		let getunitPrice = v / packSize;
		setCasePriceList(parseFloat(caseSize * getunitPrice).toFixed(2))
		setUnitPriceList(parseFloat(getunitPrice).toFixed(2))
		setPackPriceList(e.target.value === '.'?"0.":e.target.value)
		setUnitAmountError(false)
		setPackAmountError(false)
		setCaseAmountError(false)
		setAmountInEdit(false)


		let div = caseSize / packSize
		if(e.target.value){
			if(type === "SRP"){
				if(amountPer === "pack"){
					let packLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase / div)
					let amountCalc = (packLpCalc / (1 - .28))
					setAmountInTmp(parseFloat(amountCalc).toFixed(2))
				}
				if(amountPer === "case"){
					let packLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase / div)
					let amountCalc = (packLpCalc / (1 - .28))
					setAmountInTmp(parseFloat((amountCalc/packSize)*caseSize).toFixed(2))
				}
				if(amountPer === "unit"){
					let packLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase / div)
					let amountCalc = (packLpCalc / (1 - .28))
					setAmountInTmp(parseFloat((amountCalc/packSize)).toFixed(2))
				}
			}
			if(type === "FOB"){
				let cmcDeliveryFeeCaseTmp = cmcDeliveryFeeCase?cmcDeliveryFeeCase:0;
				let stateCaseTaxTmp = stateCaseTax?stateCaseTax:0;
				let caseLpCalc = (parseFloat(e.target.value) - parseFloat((bevDeliveryFeeCase/caseSize)*packSize))*(1-0.10)
				console.log(bevDeliveryFeeCase, (bevDeliveryFeeCase/caseSize)*packSize, caseLpCalc)
				let amountCalc = caseLpCalc - parseFloat((cmcDeliveryFeeCaseTmp/caseSize)*packSize) - parseFloat((stateCaseTaxTmp/caseSize)*packSize)
				if(amountPer === "pack"){
					setAmountInTmp(parseFloat(amountCalc).toFixed(2))
				}
				if(amountPer === "case"){
					setAmountInTmp(parseFloat((amountCalc/packSize)*caseSize).toFixed(2))
				}
				if(amountPer === "unit"){
					setAmountInTmp(parseFloat(amountCalc/packSize).toFixed(2))
				}
			}
		}else{
			setAmountInTmp(0)
		}
		
	}
	const handleUnitPriceListFun = (e) => {
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		setPackPriceList(parseFloat(packSize * v).toFixed(2))
		setCasePriceList(parseFloat(caseSize * v).toFixed(2))
		setUnitPriceList(e.target.value === '.'?"0.":e.target.value)
		setUnitAmountError(false)
		setPackAmountError(false)
		setCaseAmountError(false)
		setAmountInEdit(false)
		if(e.target.value){
			if(type === "SRP"){
				if(amountPer === "unit"){
					let packLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase / caseSize)
					let amountCalc = (packLpCalc / (1 - .28))
					setAmountInTmp(parseFloat(amountCalc).toFixed(2))
				}
				if(amountPer === "case"){
					let packLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase / caseSize)
					let amountCalc = (packLpCalc / (1 - .28))
					setAmountInTmp(parseFloat(amountCalc * caseSize).toFixed(2))
				}
				if(amountPer === "pack"){
					let packLpCalc = parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase / caseSize)
					let amountCalc = (packLpCalc / (1 - .28))
					setAmountInTmp(parseFloat(amountCalc * packSize).toFixed(2))
				}
			}
			if(type === "FOB"){
				let cmcDeliveryFeeCaseTmp = cmcDeliveryFeeCase?cmcDeliveryFeeCase:0;
				let stateCaseTaxTmp = stateCaseTax?stateCaseTax:0;
				let caseLpCalc = (parseFloat(e.target.value) - parseFloat(bevDeliveryFeeCase/caseSize))*(1-0.10)
				let amountCalc = caseLpCalc - parseFloat(cmcDeliveryFeeCaseTmp/caseSize) - parseFloat(stateCaseTaxTmp/caseSize)
				if(amountPer === "unit"){
					setAmountInTmp(parseFloat(amountCalc).toFixed(2))
				}
				if(amountPer === "case"){
					setAmountInTmp(parseFloat(amountCalc*caseSize).toFixed(2))
				}
				if(amountPer === "pack"){
					setAmountInTmp(parseFloat(amountCalc*packSize).toFixed(2))
				}
			}
		}else{
			setAmountInTmp(0)
		}
	}
	const validateAmt = () => {
		let formvalid = true;
		if (amountIn == "") {
			formvalid = false;
			setAmountError(true);
		}
		if(!unitPriceList){
			formvalid = false;
			setUnitAmountError(true)
		}
		if(!packPriceList){
			formvalid = false;
			setPackAmountError(true)
		}
		if(!casePriceList){
			formvalid = false;
			setCaseAmountError(true)
		}
		return formvalid;
	}

	const handleSubmit = async () => {
		if (validateAmt()) {
			const formData = {
				"ID": (productData)?productData?.id:productID,
				"CalculatedBy": (type === "FOB") ? 1 : 2,
				"UnitPer": (amountPer)?amountPer:"case",
				"Amount": amountInTmp,
				"BevPortListingPrice": (listPricemanual)?listPricemanual:caseListingPrice,
				"UnitPrice":(unitPriceList)?unitPriceList:0,
			    "PackPrice":(packPriceList)?packPriceList:0,
			    "CasePrice":(casePriceList)?casePriceList:0,
			    "ShippingFeeUnitCase": cmcDeliveryFeeCase,
			    "ShippingFeePack": cmcDeliveryFeePack,
			    "ShippingFeeUnit": cmcDeliveryFeeUnit,
			    "StateTaxUnitCase": stateCaseTax,
			    "StateTaxPack": statePackTax,
			    "StateTaxUnit": stateUnitTax,
			    "BevPortListingCasePrice":casePriceListTemp,
				"BevPortListingPackPrice":packPriceListTemp,
				"BevPortListingUnitPrice":unitPriceListTemp
			}
			setSubmitLoader(true);
			await axios.post(API_URL + "AddProductPrice?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					let data = res.data?.responseData[0];
					handleProductID(data?.id)
					handleActiveTab("productDesc")
				} else {
					toast.error(res.data.responseMessage)
				}
				setSubmitLoader(false);
			}).catch(function (error) {
				setSubmitLoader(false);
			})
		}
	}
	useEffect(() => {
		if(productData){
			//setAmountIn(productData?.amount)
			setAmountPer((productData?.unitPer)?productData?.unitPer:"case")
			setAmountInTmp(productData?.amount)
			setListPricemanual(productData?.bevPortListingPrice)
			setUnitPriceList(productData?.unitPrice)
			setPackPriceList(productData?.packPrice)
			setCasePriceList(productData?.casePrice)
			setCmcDeliveryFeeCase(productData?.shippingFeeUnitCase)
			setStateCaseTax(productData?.stateTaxUnitCase)
			if(productData?.calculatedBy == 1){
				setType("FOB")
			}else{
				setType("SRP")
			}
			if(productData?.packageSizeVal === "Singles"){
				setPackSize(1)
			}else{
				let u = (productData?.packageSizeVal).split(" ")
				setPackSize(u[0])
			}
			let cu = (productData?.caseSizeVal).split(" ")
			setCaseSize(cu[0])
			/*setPackSize(productData?.packageSizeVal)
			setCaseSize()*/
			setValueUpd(true)
			//console.log(productData)
		}
	},[productData])
	const handleEstimateShippingFeeCase = (e) => {
		setCmcDeliveryFeePackEdit(true)
		setCmcDeliveryFeeUnitEdit(true)
		setCmcDeliveryFeeCase(e.target.value === '.'?"0.":e.target.value)
	}
	const handleEstimateShippingFeePack = (e) => {
		setCmcDeliveryFeePackEdit(false)
		setCmcDeliveryFeeUnitEdit(true)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		let getunitVal = v/packSize;
		setCmcDeliveryFeePack(e.target.value === '.'?"0.":e.target.value)
		setCmcDeliveryFeeCase(priceConverter(getunitVal*caseSize))
	}
	const handleEstimateShippingFeeUnit = (e) => {
		setCmcDeliveryFeePackEdit(true)
		setCmcDeliveryFeeUnitEdit(false)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		setCmcDeliveryFeeUnit(e.target.value === '.'?"0.":e.target.value)
		setCmcDeliveryFeeCase(priceConverter(v*caseSize))
	}
	const handleStateTaxCase = (e) => {
		setStateTaxPackEdit(true)
		setStateTaxUnitEdit(true)
		setStateCaseTax(e.target.value === '.'?"0.":e.target.value)
	}
	const handleStateTaxPack = (e) => {
		setStateTaxPackEdit(false)
		setStateTaxUnitEdit(true)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		let getunitVal = v/packSize;
		setStatePackTax(e.target.value === '.'?"0.":e.target.value)
		setStateCaseTax(priceConverter(getunitVal*caseSize))
	}
	const handleStateTaxUnit = (e) => {
		setStateTaxPackEdit(true)
		setStateTaxUnitEdit(false)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		setStateUnitTax(e.target.value === '.'?"0.":e.target.value)
		setStateCaseTax(priceConverter(v*caseSize))
	}
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="InsertForms priceCalculator">
				<h3 className="card-heading topsubheading">BevPort Pricing Calculator<sup>©</sup></h3>
				<div className="row">
					<div className="col-md-6">

						<p className="cardSubtext">This Pricing Calculator will assist you in pricing your products for retailers and Customers.</p>
						<div className="form-group mb-1">
							<label className="" style={{ display: "block" }}>Calcuated By <span style={{ color: "red" }}>*</span></label>
							<label class="radio-inline cardSubtext">
								<input type="radio" name="calcBy" value="FOB" className="" onChange={handleTypeOption} checked={(type === "FOB" ? true : false)} />FOB
							</label>
							<label class="radio-inline cardSubtext">
								<input type="radio" name="calcBy" value="SRP" className="" onChange={handleTypeOption} checked={(type === "SRP" ? true : false)} />SRP
							</label>

						</div>
						<p className="cardSubtext">
							<b>Calculate by FOB:</b> Enter your FOB (Price to Distributor), we will break down your margins and show you what your PTC (Price to customer per unit, pack or case) will be after all the margins, taxes, and delivery fee.
						</p>
						<p className="cardSubtext">
							<b>Calculate by SRP: </b>Enter your SRP (Suggested Retail Price per unit, pack or case), we will break down your margins and show you want your FOB (Price to Distributor) will be after all the margins, taxes, and delivery fee.
						</p>
					</div>
					<div className="col-md-6">

						<div className="form-group amount-field">
							<label className="cardSubtext me-2">Amount Per</label>
							<label class="radio-inline cardSubtext">
								<input type="radio" name="amoutPer" value="unit" onChange={handleAmountPer} checked={(amountPer === "unit" ? true : false)} />Unit
							</label>
							<label class="radio-inline cardSubtext">
								<input type="radio" name="amoutPer" value="pack" onChange={handleAmountPer} checked={(amountPer === "pack" ? true : false)} />Pack
							</label>
							<label class="radio-inline cardSubtext">
								<input type="radio" name="amoutPer" value="case" onChange={handleAmountPer} checked={(amountPer === "case" ? true : false)} />Case
							</label>
						</div>
						<div className="form-group">
							<label className="">Amount in <span style={{ color: "red" }}>*</span></label>
							<input type="text" className={(amountError) ? "form-control error" : "form-control"} onChange={handleAmountInFun} onKeyPress={handleFloatNumber} value={amountInTmp} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="table-responsive">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th style={{ width: "400px" }}></th>
										<th>{caseSize} Units Case</th>
										<th>{packSize} Packs</th>
										<th>Unit</th>

									</tr>
								</thead>
								<tbody>
									<tr>
										<th>SRP
											<Tooltip
												title={<span className="toolTipData">This is the final retail price to customers</span>}
												arrow
												placement={"right-start"}
												style={{ marginBottom: "100px" }}

											><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

											</Tooltip>
										</th>

										{
											type === "FOB" ? <>
												<td>${priceConverter(caseSRP)}</td>
												<td>${priceConverter(packSRP)}</td>
												<td>${priceConverter(unitSRP)}</td>
											</> : <>
												<td>${priceConverter(unitCasePrice)}</td>
												<td>${priceConverter(packPrice)}</td>
												<td>${priceConverter(unitPrice)}</td>
											</>
										}
									</tr>
									<tr>
										<td>Average Retailer Margin<Tooltip
											title={<span className="toolTipData">This is an average margin charged by retailers.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										<td>{priceConverter(avMargin)}%</td>
										<td>{priceConverter(avMargin)}%</td>
										<td>{priceConverter(avMargin)}%</td>
									</tr>
									<tr>
										<td>Bevport Listing Price <Tooltip
											title={<span className="toolTipData">This is the price that Buyers will see on Bevport.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										<td>${priceConverter(caseListingPrice)}</td>
										<td>${priceConverter(packListingPrice)}</td>
										<td>${priceConverter(unitListingPrice)}</td>
									</tr>
									<tr>
										<td>Delivery Fee <Tooltip
											title={<span className="toolTipData">Cost of delivery to retailers.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										<td>${priceConverter(bevDeliveryFeeCase)}</td>
										<td>${priceConverter(bevDeliveryFeePack)}</td>
										<td>${priceConverter(bevDeliveryFeeUnit)}</td>
									</tr>
									
									<tr>
										<td>Bevport Margin <Tooltip
											title={<span className="toolTipData">Percentage for Bevport Distribution.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										<td>{priceConverter(bpMargin)}%</td>
										<td>{priceConverter(bpMargin)}%</td>
										<td>{priceConverter(bpMargin)}%</td>
									</tr>
									<tr>
										<td>Bevport Price <Tooltip
											title={<span className="toolTipData">Price you are selling product to Bevport.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										<td>${priceConverter(caseBPPrice)}</td>
										<td>${priceConverter(packBPPrice)}</td>
										<td>${priceConverter(unitBPPrice)}</td>
									</tr>
									<tr className="custom-dollor">
										<td className="remove-dollar">Estimated Shipping Fee *<Tooltip
											title={<span className="toolTipData"> Country Malt's Cost per case to pick up from your brewery to our warehouses.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										{/*<td>${priceConverter(cmcDeliveryFeeCase)}</td>
										<td>${priceConverter(cmcDeliveryFeePack)}</td>
										<td>${priceConverter(cmcDeliveryFeeUnit)}</td>*/}
										<td><input type="text" className={"form-control"} placeholder="Case Estimated Shipping Fee" value={cmcDeliveryFeeCase} onChange={handleEstimateShippingFeeCase} onKeyPress={handleFloatNumber}/></td>
										<td><input type="text" className={"form-control"} placeholder="Pack Estimated Shipping Fee" value={cmcDeliveryFeePack} onChange={handleEstimateShippingFeePack} onKeyPress={handleFloatNumber}/></td>
										<td><input type="text" className={"form-control"} placeholder="Unit Estimated Shipping Fee" value={cmcDeliveryFeeUnit} onChange={handleEstimateShippingFeeUnit} onKeyPress={handleFloatNumber}/></td>
									</tr>
									
									
									<tr className="custom-dollor">
										<td className="remove-dollar">Estimated State Tax <Tooltip
											title={<span className="toolTipData">State tax for Alcoholic beverages.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}

										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

										</Tooltip></td>
										{/*<td>${priceConverter(stateCaseTax)}</td>
										<td>${priceConverter(statePackTax)}</td>
										<td>${priceConverter(stateUnitTax)}</td>*/}
										<td><input type="text" className={"form-control"} onChange={handleStateTaxCase} placeholder="Case Estimated State Tax" value={stateCaseTax} onKeyPress={handleFloatNumber}/></td>
										<td><input type="text" className={"form-control"}  placeholder="Pack Estimated State Tax" value={statePackTax} onChange={handleStateTaxPack} onKeyPress={handleFloatNumber}/></td>
										<td><input type="text" className={"form-control"}  placeholder="Unit Estimated State Tax" value={stateUnitTax} onChange={handleStateTaxUnit} onKeyPress={handleFloatNumber}/></td>
									</tr>
									<tr>
										<th>FOB <Tooltip
											title={<span className="toolTipData">This is your base cost to Distributor.</span>}
											arrow
											placement={"right-start"}
											style={{ marginBottom: "100px" }}
										><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon/></i>
										</Tooltip></th>
										{
											type === "SRP" ? <>
												<td>${priceConverter(caseSRP)}</td>
												<td>${priceConverter(packSRP)}</td>
												<td>${priceConverter(unitSRP)}</td>
											</> : <>
												<td>${priceConverter(unitCasePrice)}</td>
												<td>${priceConverter(packPrice)}</td>
												<td>${priceConverter(unitPrice)}</td>
											</>
										}


									</tr>
									<tr className="custom-dollor">
										<th>Suggested BevPort Listing Price </th>
										<td><input type="text" className={"form-control"} value={casePriceListTemp}  placeholder="Case BevPort Listing Price" disabled={true} /></td>
										<td><input type="text" className={"form-control"} value={packPriceListTemp}  placeholder="Pack BevPort Listing Price" disabled={true} /></td>
										<td><input type="text" className={"form-control"} value={unitPriceListTemp} placeholder="Unit BevPort Listing Price" disabled={true} /></td>

									</tr>
									<tr className="custom-dollor">
										<th>Enter Final Bevport Price </th>
										<td><input type="text" className={(caseAmountError) ? "form-control error" : "form-control"} value={casePriceList} onChange={handleCasePriceListFun} onKeyPress={handleFloatNumber} placeholder="Case Final Bevport Price" /></td>
										<td><input type="text" className={(packAmountError) ? "form-control error" : "form-control"} value={packPriceList} onChange={handlePackPriceListFun} onKeyPress={handleFloatNumber} placeholder="Pack Final Bevport Price" /></td>
										<td><input type="text" className={(unitAmountError) ? "form-control error" : "form-control"} value={unitPriceList} onChange={handleUnitPriceListFun} onKeyPress={handleFloatNumber} placeholder="Unit Final Bevport Price" /></td>

									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div className="col-md-12">
						<p>
							By Saving, you agree to sell your product at the above pricing indicated. You also understand that any future pricing changes may take up to 30 days to post to buyers in order to comply with State Price Posting laws and the ABT.
						</p>
					</div>
					<div className="col-md-12" style={{textAlign: "center"}}>
						<button className="btn btn-primary save-button ml-2" onClick={handleSubmit}>{productData ? "Save" : "Save & Next"}</button>
					</div>
				</div>
			</div>
		</>
	)
}