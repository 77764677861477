import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import TeamMemberSideBar from "./TeamMemberSideBar";
import TeamMemberHeader from "./TeamMemberHeader";
import TeamMemberFooter from "./TeamMemberFooter";
import LogoInvoice from './../../assets/images/logo.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print";
import moment from 'moment';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function OrderDetail() {
    const [toggle, setToggle] = useState(false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    let { orderID } = useParams();
    const [data, setData] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(true);
    useEffect(() => {
        getOrderDetail();
    }, [])
    document.title = "#"+orderID
    let componentRef = useRef();
    const getOrderDetail = async () => {
        // let dt =[];
        await axios.post(API_URL + "GetOrderDetails?code=" + API_KEY, { OrderID: orderID }, config).then((res) => {
            if (res.data.responseCode == "200") {
                setData(res.data.responseData);
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    
    const sum = () => {
         let add = 0;
         data.filter((_v,_i) => {
            if(_v?.brewerID == localStorage.getItem("createdBy")){
                add = add + _v?.amount;
            }
         })
         return add;
    }
    return (
        <>
        {submitLoader ? <Loader /> : ""}
            <div className="mainAdminWrapper">
                <TeamMemberSideBar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <TeamMemberHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Order Detail</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="invoicePagesWrapper">
                                    <div className="wizardShowOnly">
                                    <div className="wizardShowOnly" ref={(el) => (componentRef = el)}>
                                        <div className="row invoiceHeader">
                                            <div className="col-md-6">
                                                <div className="LogoInvoice">
                                                    <img src={LogoInvoice} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="InvoiceTextHead">
                                                    <h3>Sales Order</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row orderHeader">
                                            <div className="col-md-6">
                                                <div className="helloAreas">
                                                    <p>Hello {data?.[0]?.buyerName},</p>
                                                    <p>Thank you for shopping from our store. </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="orderIdText">
                                                    <p>INVOICE ID: <span className="orderNumbersId">#{data?.[0]?.orderID}</span></p>
                                                    <p>{moment(data?.[0]?.createdDate).format('DD-MM-YYYY HH:mm')}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row informationBiligns">
                                            <div className="col-md-6">
                                                <div className="billingImforArea">
                                                    <h3>BILLING ADDRESS</h3>
                                                    <div className="callAdress samllwidth">
                                                        <p>{data?.[0]?.billingStreet}, {data?.[0]?.billingCityName}, {data?.[0]?.billingStateName}, {data?.[0]?.billingCountryName} {data?.[0]?.billingZipCode}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="billingImforArea">
                                                    <h3>PAYMENT MODE</h3>
                                                    <div className="callAdress">
                                                        <p>Credit Card</p>
                                                        <p>Credit Card Type: <span className="transId">Visa</span></p>
                                                        <p>Worldpay Transaction ID: <span className="transId">4185939336</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row informationBiligns Shippinhind">
                                            <div className="col-md-6">
                                                <div className="billingImforArea">
                                                    <h3>DELIVERY ADDRESS</h3>
                                                    <div className="callAdress samllwidth">
                                                        <p>{data?.[0]?.buyerAddress}, {data?.[0]?.cityName}, {data?.[0]?.stateName}, {data?.[0]?.countryName} {data?.[0]?.zipCode}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="billingImforArea">
                                                    <h3>DELIVERY METHOD</h3>
                                                    <div className="callAdress">
                                                        <p>UPS: <span className="transId">U.S. Shipping Services</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row puchaseHistoryTable">
                                            <div className="col-md-12">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="40%">Item</th>
                                                            <th className="brewerNames" scope="col" >Brewer Name</th>
                                                            <th className="qtyTextr" scope="col" width="20%">Quantity</th>
                                                            <th className="amountDallrs" scope="col" width="20%">Amount(In $)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((_v, _i) => {
                                                            if(_v?.brewerID == localStorage.getItem("createdBy")){
                                                            return [
                                                                <tr>
                                                            <td>
                                                                <span className="mainPrName">{_v?.productName}</span>
                                                                <span className="brandNameSm">{_v?.brandName}</span>
                                                            </td>
                                                            <td className="brewerNames">{_v?.brewerName}</td>
                                                            <td className="qtyTextr">{_v?.quantity}</td>
                                                            <td className="amountDallrs">{parseFloat(_v?.amount).toFixed(2)}</td>
                                                        </tr>
                                                            ]
                                                        }
                                                        })}
                                                        
                                                      
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="qtyTextr grandtotals">Grand Total (Incl. Tax)</td>
                                                            <td className="amountDallrs finalPrice">{parseFloat(sum()).toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row Prinatblebtn">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <ReactToPrint
                                                      trigger={() => <button type="button" className="btn btn-secondary save-button"><i className="fa fa-print"></i> Print</button>}
                                                      content={() => componentRef}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <TeamMemberFooter />
                </div>
            </div>
        </>
    )
}

export default OrderDetail