import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "../../front/Loader";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
    document.title = "BevPort - Deal Info List"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true);
    const [loader, setLoader] = useState(false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }



    const columns = [
        {
            name: 'Brand Name',
            selector: row => (row.brandName).toLowerCase(),
            cell: row => row.brandName,
            sortable: true,
        },
        {
            name: 'Product Name',
            selector: row => (row.productName).toLowerCase(),
            cell: row => row.productName,
            sortable: true,
        },
        {
            name: 'Popular Brewers',
            selector: row => row.popularBrew,
            cell: row => (row.popularBrew == "1") ? "Yes" : "No",
            sortable: true,

        },
        {
            name: 'New Arrival',
            selector: row => row.newArrival,
            cell: row => (row.newArrival == "1") ? "Yes" : "No",
            sortable: true,
        },
        {
            name: 'Special Deal',
            selector: row => row.specialDeal,
            cell: row => (row.specialDeal == "1") ? "Yes" : "No",
            sortable: true

        },
        {
            name: 'Monthly Special',
            selector: row => row.getHezy,
            cell: row => (row.getHezy == "1") ? "Yes" : "No",
            sortable: true,
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" to={"/admin/edit-deal-info/" + row.id}>Edit</Link>&nbsp;
                <Link className="btn btn-primary" to="#" onClick={() => handleDelete(row.id)} >Delete</Link></>,
        }
    ];
    const [data, setData] = useState([])

    useEffect(() => {
        getDealInfoList()
    }, [])


    const getDealInfoList = async () => {
        await axios.post(API_URL + "GetDealInfo?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode == "200") {
                setData(res.data.responseData)
              
            } else {
                 setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const handleDelete = async (id) => {
        let formData = {
            "ID": id
        }
        setLoader(true);
        await axios.post(API_URL + "DeleteDealInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode == "200") {
                toast.success(res.data.responseMessage, {
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,

                });
               
                getDealInfoList();
                setLoader(false);
            } else {
                toast.error(res.data.responseMessage)
            }
        }).catch(function (error) {

        })
    }
    const tableData = {
        columns,
        data
    };

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Deal Info List</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className='tbaShowcase'>
                                    <div className="Content-body dataTableSearchBox">
                                        <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Deal Info">
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noDataComponent={<span className='norecordFound'>No record found!</span>}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}