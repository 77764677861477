import React, { useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ProSidebarProvider, Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import "./../../assets/css/admin-style.css"
import Logo from "./../../assets/images/logo.png"
import SmallLogo from "./../../assets/images/small-logo.png"
import OrderListIcon from '@mui/icons-material/ViewList';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddchartIcon from '@mui/icons-material/Addchart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FactCheckIcon from '@mui/icons-material/FactCheck';

export default function SideBar({ collapse }) {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    useEffect(() => {
        collapseSidebar(collapse);
    }, [collapse])
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("isAuthentic");
        localStorage.removeItem("userType");
        localStorage.removeItem("firstName");
        localStorage.removeItem("businessName");
        navigate("/SSD/login")
    }
    return (
        <>
            <Sidebar className="mycustomSidebar">
                <Menu>
                    <MenuItem className="TopMiamLogo">
                        <span className="logoNotCollapsed"><img src={Logo} /></span>
                        <span className="logoCollapsed"><img src={SmallLogo} /></span>
                    </MenuItem>


                    <MenuItem className="dashboard" icon={<GridViewRoundedIcon />} component={<NavLink to="/SSD/Dashboard" className="link" />}> Dashboard </MenuItem>
                 
                               
                    <MenuItem icon={<LogoutRoundedIcon />} onClick={logout}> Logout </MenuItem>
                </Menu>
            </Sidebar>
        </>
    )
}
