import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));


//const injectGA = () => {


//    alert("in inject GA ");

//    /*alert("hello injectGA index.js");*/
//    if (typeof window == 'undefined') {
//        return;
//    }
//    window.dataLayer = window.dataLayer || [];
//    function gtag() {
//        window.dataLayer.push(arguments);
//    }
//    gtag('js', new Date());

//    gtag('config', 'G-JTNX21VVTP"');
//};


root.render(

    <React.StrictMode>

        {/* Global site tag (gtag.js) - Google Analytics */}
        {/*< script async src="https://www.googletagmanager.com/gtag/js?id=G-JTNX21VVTP" />*/}
        {/*<script>{injectGA()}</script>*/}


      
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
