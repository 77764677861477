import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-data-table-component-extensions/dist/index.css";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function AssociateSalesRep() {
    document.title = "BevPort - AllSalesRep"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [loader,setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [jsonData, setJsonData] = useState([])
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    let {salesRepID} = useParams();
    let Navigate = useNavigate();
    useEffect(() => {
        let id = localStorage.getItem("id");
        getActiveBrands(id);
    }, [])

    const getActiveBrands = async(userID) => {
        let formData = {
            ID : userID,
            SalesRepID : salesRepID
        }

        await axios.post(API_URL + "GetBrewersBrandData?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				let brandOption = [];
                let brData = [];
				let resData = res.data?.responseData?.[0]?.brandListData
				resData.forEach((da) => {
					if (da?.status == 1) {
						brandOption.push(da);
					}
				}
				);
                setData(resData);

                resData.forEach((_o,_i) => {
                        if(_o?.isAssociated){
                            let tempData = {
                                "BrewerID": _o?.userID, 
                                "SalesRepID": salesRepID, 
                                "BrandID": _o?.id, 
                                Status: "1"
                            }
                            brData.push(tempData)
                        }
                })
                setJsonData(brData)
                setLoader(false);
                setSubmitLoader(false);

			} else {
                setLoader(false);
                setSubmitLoader(false);
			}
		}).catch(function (error) {

		})
    }
    const handleValidate = () => {
        let formValid = true;
        if(!jsonData.length){
            formValid = false;
            toast.error("Select atleast one brand")
        }
        return formValid;
    }

    
    const handleSubmit = async () => {
        if(handleValidate()){
            let formData = jsonData;
            setSubmitLoader(true);
            await axios.post(API_URL + "InsertBrewerSalesRep?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode == "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/brewer/allSalesRep");
                }
                setSubmitLoader(false);
            }).catch(function (error) {
                setSubmitLoader(false);
            })

        }
    }

    const handleCheckBox = (e, da) => {
        if(e.target.checked){
            let f = { "BrewerID": da?.userID, "SalesRepID": salesRepID, "BrandID": da?.id, Status: "1" }
            setJsonData([...jsonData, f]);
            let dd = data.map((x,y) => {
                if(x?.id === da?.id){
                    x.isAssociated = 1;
                }
                return x;
            })
            setData(dd)
        }else{
            let index = jsonData.findIndex(x => x.BrandID === da?.id);
            jsonData[index].Status = 0;
            //jsonData.splice(index, 1);
            let dd = data.map((x,y) => {
                if(x?.id === da?.id){
                    x.isAssociated = 0;
                }
                return x;
            })
            setData(dd)
        }
    }

        
  return (
    <>
      <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <BrewerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                           
                            <h1>Associate Brands</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                <div className="Content-body">
                                    {
                                        (data.length)?
                                        <div className="row">
                                        <div className="col-md-6">
                                            <ul className="list-group list-height-data">
                                            {
                                                data.map((_v,_i) => {
                                                    return(
                                                         <li className="list-group-item" key={_i}><input type="checkbox" onChange={(e) => handleCheckBox(e, _v)} checked={_v?.isAssociated}/> &nbsp;{_v?.brandName}</li>
                                                    )
                                                })
                                             
                                            }
                                            </ul>
                                        </div>
                                    </div>:"No Data Found!"}
                                </div>
                                </div>
                                {(data.length)?<button className='btn btn-primary' onClick={handleSubmit}> Submit</button>:""}
                            </div>
                        </div>
                    </div>
                    <BrewerFooter />
                </div>
            </div>
    </>
  )
}

export default AssociateSalesRep