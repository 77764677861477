import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import BrewerSideBar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function OrderHistoryBuyer() {
    document.title = "BevPort - Brewer Order List"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const { buyerID } = useParams();



    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Quantity In Case',
            selector: row => row.quantityCount,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.totalAmount),
            cell: row => "$" + parseFloat(row.totalAmount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDate) ? moment(row.orderDate) : 0,
            cell: row => row.orderDate ? moment(row.orderDate).format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == 0) ? <span style={{ "color": "#00aeef" }}>Pending</span> : (row.status == 20) ? <span style={{ "color": "#00aeef" }}>Rejected</span> : <span style={{ "color": "#00aeef" }}>Placed</span>,
            sortable: true

        },
        {
            name: 'Action',
            selector: row => <>
                <Link className="btn btn-primary" to={!(row.status == 20) ? "/brewer/order-detail-buyer/" + row.orderID : "#"} target={!(row.status == 20) ? '_blank':''} disabled={(row.status == 20)?true : false}>View</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        if(buyerID){
        getOrderHistory(buyerID)
        }
    }, [buyerID])
  
    const getOrderHistory = async (userID, brewerID) => {
        let formData = {
            "ID": userID,
            "BrewerID": localStorage.getItem("id")
            }
        await axios.post(API_URL + "OrderHistoryBuyer?code=" + API_KEY, formData, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
      };
    return (
        <>

            <div className="mainAdminWrapper">
                <BrewerSideBar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <BrewerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Sales Order</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    {/* <h3 className="card-heading">Order List</h3> */}
                                <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Sales Order">
                                
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noDataComponent={(data.length) ? false : true}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={4}
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BrewerFooter />
                </div>
            </div>
        </>
    )
}