
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import LoginBGSide from "./../../assets/images/loginSide.jpg"
import privacybg from "./../../assets/images/privacy-page.jpg"
import LoginLeaves from "./../../assets/images/loginLeaves.png"
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Loader from "./Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
export default function Resource() {
    document.title = "BevPort - Reset Password"
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { token } = useParams();
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [passError, setPassError] = useState(false)
    const [passText, setPassText] = useState("")
    const [passwordSameError, setPasswordSameError] = useState(false)
    const [visible, SetVisible] = useState(false);
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(false);
        setPasswordSameError(false)
    }

    const handlePassword = (e) => {
        setPassError(false)
        setPassText("")
        setPassword(e.target.value);
        setPasswordError(false);
    }

    const handleShow = () => {
        SetVisible(!visible);
    }
    function checkPwd(str) {
        if (str.length < 6) {
            setPassError(true)
            setPassText("Must have at least 6 characters.")
            return false;
        } else if (str.search(/\d/) == -1) {
            setPassError(true)
            setPassText("Must have at least one number.")
            return false;
        } else if (str.search(/[A-Z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one capital letter.")
            return false;
        } else if (str.search(/[a-z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one small letter.")
            return false;
        } else if (str.search(/[!@#$%^&*()]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one symbol.")
            return false;
        }
        return true;
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!password) {
            formIsValid = false;
            setPasswordError(true)
        }
        if (password && !checkPwd(password)) {
            formIsValid = false;
            setPasswordError(true)
        }
        if (!confirmPassword) {
            formIsValid = false;
            setConfirmPasswordError(true);
        }
        if (confirmPassword && password !== confirmPassword) {
            formIsValid = false;
            setPasswordSameError(true)
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setSubmitLoader(true)
            let formData = {
                "Token": token,
                "Password": password
            }
            await axios.post(API_URL + "UpdateUserPassword?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    navigate("/login")
                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
            })
        }
    }
    useEffect(() => {
        let id = localStorage.getItem("id");
        let isAuthentic = localStorage.getItem("isAuthentic")
        if(id && isAuthentic === "true"){
            let userType = localStorage.getItem("userType")
            if(userType == "1"){
                navigate("/brewer/add-brand")
            }else if(userType == "2"){

            }
        }
    },[])
    return (
        <>
            {submitLoader ? <Loader /> : ""}
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />
                    <section className="simplyfying Empowering" id="simplys">
                        <div className="simplyfyingDiv">
                            <div className="container-fluid">
                                <div className="row upcontent">
                                    <div className="col-md-7 manBgimg logform">
                                        <div className="simplecontentimg">
                                            <img className="img-responsive" src={LoginLeaves} />
                                        </div>
                                    </div>
                                    <div className="col-md-5 manBgimg">
                                        <div className="manImg">
                                            <img className="img-responsive" src={LoginBGSide} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="moveTopUp loginBAoutphone">
                                        <div className="container">
                                            <div className="row main-contentprt">
                                                <div className="col-md-6">
                                                    <div className="textSimply loginForms">
                                                        <div className="loginformdesign">
                                                            <h3>Reset <span>Password</span></h3>
                                                            <p>Enter a new password for your account.</p>
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group passwordeye">
                                                                            <label for="firstname">Password <span className="red-tick">*</span></label>
                                                                            <div className="showhidepassowrd">
                                                                                <input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} placeholder="Password" onChange={handlePassword} autoComplete="off" />
                                                                                {passError ? <span className="error-message">{passText}</span> : ""}
                                                                                <span className="eyeslash" onClick={handleShow} > {!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group ">
                                                                            <label for="firstname">Confirm Password <span className="red-tick">*</span></label>
                                                                            <input type="password" className={confirmPasswordError ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleConfirmPassword} autoComplete="off" />
                                                                            {passwordSameError ? <span className="error-message">Confirm password should be same as password.</span> : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="remeberAndForgot">
                                                                            <div className="remebercheck">
                                                                                {/* <input className="form-check-input" type="checkbox" value="" id="form1Example3" autocompleted="" onChange={handleRemember} />
                                                                                <label className="form-check-label" for="form1Example3">Remember password</label> */}
                                                                            </div>
                                                                            <div className="forgotpswd">
                                                                                <Link to="/login" className="forgetpass">Go to login?</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="logibtns">
                                                                            <button type="submit" name="subxcmit" className="loginb" >Submit</button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>






                    <Footer />
                </div>
            </div>

        </>
    )
}