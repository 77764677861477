import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function FirstStep() {
    document.title = "BevPort"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const handleActivityState = async () => {
        let formData = {
            UserID: localStorage.getItem("id"),
            ActivityState: "25"
        }
        await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                localStorage.setItem("activityState", 25);
            } else {
                toast.error(res.data.responseMessage);
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            console.log(error)
            setSubmitLoader(false)
        })
    }
    return (
        <>
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    <BrewerHeader collapseSidebar={collapseSidebar} />

                    <div className="rightContetBlock welcomebevText">
                        <div className="headingTop">
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className="card-heading topsubheading">Welcome to BevPort! We are honored to have you joining the revolution.
                                            </h3>
                                            <p className=""><b>Alcoholic Products Only</b>: Your first step is to complete the sign on process to get your Brand submitted and approved in to the state of Florida. We will guide you through each step of the process. Once the final step is complete, you will receive an email confirming your Label Approvals, and you will be ready to go live and ready to sell to retailers.
                                            </p>
                                            <p>Heres are steps required:</p>
                                            <ol className="numbering">
                                                <li>Enter your Brand information</li>
                                                <li>Add products and label images</li>
                                                <li>Submit Licensing Compliance Information </li>
                                                <li>Upload label images</li>
                                                <li>Approve payment for label submission ($30.00 fee for alcohol brands only)</li>
                                                <li>Review and Sign Distribution Agreement (Beer and Malt-Based brands only.)</li>
                                                <li>We will submit everything to the state Licensing board.</li>
                                                <li>Once labels have been approved, Set up first Delivery to Warehouse</li>
                                                <li>Go live</li>
                                            </ol>


                                            <p className=""> <b> Non Alcoholic </b>: Your first step is to complete the sign on process, enter all your brand information and upload images.</p>

                                            <p>Heres are steps required:</p>
                                            <ol className="numbering">
                                                <li>Enter your Brand information</li>
                                                <li>Add products and label images</li>
                                                <li>Go to Dashboard/Delivery and set up your first Shipment to our warehouse.</li>
                                                <li>You will notified and contacted by our Logistic partner (Country Malt Group to set up delivery).</li>
                                                <li>Once product arrives in the warehouse you are ready to go live!</li>
                                              
                                            </ol>

                                            <div className="greenBtndbottom">
                                                <Link to="/brewer/add-brand" onClick={handleActivityState}>Let’s Get Started!</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <BrewerFooter />
                </div>
            </div>
        </>
    )
}

export default FirstStep;