

import { Link } from "react-router-dom";
import React, {useEffect} from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png"
import privacybg from "./../../assets/images/privacy-page.jpg"
import Header from './Header';
import Footer from './Footer';
export default function Resource(){
	document.title = "BevPort - Privacy"
	useEffect(() => {
	  	window.scrollTo(0, 0)
	}, [])
	return(
		<>
		<div className="site-wrapper">
        		<div className="main-wrapper">
			<Header />
			<section className="simplyfying Empowering privacy-policy" id="simplys">
				<div className="simplyfyingDiv">
				  <div className="container-fluid">
				    <div className="row upcontent">
				      <div className="col-md-5 simplecontent aboutmainBg"> 
				        <div className="simplecontentimg"> 
				          <img className="img-responsive" src={aboutbgd} />  
				        </div>    
				      </div>
				      <div className="col-md-7 manBgimg">
				        <div className="manImg">
				          <img className="img-responsive" src={privacybg} />
				        </div>
				      </div>
				    </div>
				    <div className="row">
				    <div className="moveTopUp aboutheadphone">
				      <div className="container">
				        <div className="row main-contentprt">
				          <div className="col-md-4">
				            <div className="textSimply muddtext">
				              <h1><span className="darkblacktext">Privacy</span>Policy</h1>
				            </div>
				          </div>
				          <div className="col-md-8">
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>

				</div>
				</section>

<section id="termsofusecontent" className="termscosent privacy-poilcys">
<div className="termsofusespara">
	<div className="container">
		<div className="row">
			<div className="col-md-12">
				<div className="termmainPart">
					<p>Bevport Distribution LLC (“Bevport,” “we,” “us,” or “our”) respects your privacy and is committed to protecting your personal information. This Privacy Policy describes how we collect, use, and share personal information through our website, located at <Link to="https://bevport.com/">www.bevport.com</Link> (the “Site”). We know that you are careful about the information you share on our web and how it’s used. We appreciate your trust that we will do so carefully and limited.</p>
<p><strong>By using the Site, you consent to the collection, use, and sharing of your personal information as described in this Privacy Policy.</strong></p>
<p><strong>Information We Collect</strong></p>
<p>We collect personal information from you when you voluntarily submit it to us through the Site. For example, we may collect your name, email address, and phone number when you complete a contact form on the Site or sign up to receive our newsletter. We may also collect information about your use of the Site, such as your IP address, browser type, and operating system.</p>

<p><strong>Mobile:</strong> When you download, or use apps, we may receive such information as information about your mobile device, your location, and a unique identifier for your device. We may use this information to provide you with location‐based services, such as advertising, search results, and other personalized content. Most mobile devices allow you to turn off location services and you should consult your device manual.</p>

<p><strong>Use of Information</strong></p>
<p>We use your personal information to respond to your inquiries, provide you with information about our products and services, and send you newsletters and other promotional materials. We may also use your information to improve the Site and our products and services, and to comply with legal and regulatory requirements.</p>

<p>We may share your personal information with third-party service providers who assist us in operating the Site, conducting our business, or providing services to you. We may also share your information with law enforcement agencies, regulatory bodies, or other third parties if we believe that such disclosure is necessary to comply with applicable laws, regulations, or legal processes.</p>

<p><strong>Cookies</strong></p>
<p>The Site uses cookies to collect information about your use of the Site. A cookie is a small text file that is stored on your computer or mobile device when you visit a website. We use cookies to improve the functionality of the Site, analyze site usage, and personalize your experience on the Site.</p>
<p>You can control cookies through your browser settings. However, disabling cookies may limit your ability to use certain features of the Site.</p>

<p><strong>Security</strong></p>
<p>We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, or destruction. However, no data transmission over the internet or electronic storage system can be guaranteed to be 100% secure.</p>

<p>We are Payment Card Industry Data Security Standard (PCI‐DSS) certified compliant. We use Secure Sockets Layer (SSL) software, which encrypts information you input to protect the security of your information during transmission.</p>

<p>We use Secure Payments to process credit card transactions and QuickBooks and <Link to="https://www.bill.com/" target="_blank">Bill.com</Link> to process banking transactions. No bank account or credit card information is stored in our platform. Rather it is handled by these trusted third parties. It is important for you to protect against unauthorized access to your password and to your computer and account. Be sure to sign off when finished using a shared computer. We recommend that you change your password at least monthly. When using a computer that is not yours, if the browser asks you if you want to save your password, click “No” Bevport Distribution requires confirmed email accounts and telephone numbers for SMS messaging. The platform will send you automated messages to confirm your information. Certain features of the platform will not be available until the information is confirmed</p>

<p><strong>Links to Third-Party Websites</strong></p>
<p>The Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these websites before providing any personal information.</p>

<p><strong>Children’s Privacy or Users under 21</strong></p>
<p>Bevport is an alcohol distributor and therefore does not advertise to, or allow sale by, or purchase by, those under 21. If you are under 21, do not use the site.</p>
<p>The Site is not intended for use by children or users under the age of 21. We do not knowingly collect personal information from users under the age of 21. If you are under the age of 21, please do not provide any personal information through the Site.</p>

<p><strong>Changes to this Privacy Policy</strong></p>
<p>We may update this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by posting the updated Privacy Policy on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the revised Privacy Policy.</p>

<p><strong>Contact Us</strong></p>
<p>If you have any questions or concerns about this Privacy Policy, please contact us at <Link to="mailto:info@bevport.com">info@bevport.com</Link>.</p>


				</div>
			</div>
		</div>
	</div>
</div>
</section>	
<Footer />
</div>
</div>
				
		</>
	)
}