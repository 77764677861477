import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER } from './../../config/constant.js';
import DefaultAvtar from './../../assets/images/avatar-1.jpg'
import moment from "moment"
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

export default function Profile() {
	document.title = "BevPort - Profile"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [userData, setUserData] = useState("");

	const fileInputRef = useRef(null);
	const [isFileInputVisible, setFileInputVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [imageRef, setImageRef] = useState(null);
	const [imageName, setImageName] = useState("");
	const [completeCrop, setCompleteCrop] = useState("");

	const [getApiResp, setGetApiResp] = useState(0);

	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);

	const [profilePic, setProfilePic] = useState("");

	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		x: 7,
		y: 7,

	});

	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		let id = localStorage.getItem("id")
		getBrandDataByID(id)
	}, [])

	const handleClose = () => {
		setShowModal(false);
	};

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			handleClose();
		}
	};

	const handleEscapeKey = (event) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	};

	useEffect(() => {
		if (showModal) {
			document.addEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [showModal]);
	
	const getBrandDataByID = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setUserData(data)
				setProfilePic(data?.filePath);
				console.log(data)
				if (data.filePath != "") {
					setGetApiResp(1);
				}
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const closeUploadMode = (e) => {
		e.preventDefault()
		window.$('#myModel2').modal('hide')
	}

	const handleDivClick = () => {
		setFileInputVisible(true);
		//document.getElementById("uploadFileInput").click();
		fileInputRef.current.click()

	};



	const handleFileInputChange = (event) => {
		// Handle file selection here
		const selectedFile = event.target.files[0];
		console.log('Selected file:', selectedFile);
		if (selectedFile) {
			const imageURL = URL.createObjectURL(selectedFile);
			console.log("imageUrl", imageURL);
			//setUploadProfile(selectedFile);
			setImageName(selectedFile?.name)
			setSelectedImage(imageURL);
			window.$('#myModel2').modal('show')
		}

		// After handling the file, hide the file input
		setFileInputVisible(false);
	};

	const CropImg = (e) => {

		setCrop(e);
	}
	const ImgLoad = (e) => {
		setImageRef(e.target);
	}


	const savePofile = async (dataFile) => {
		let da = dataFile;
		console.log("data", da);
		let finalArray = [];
		//da.forEach((_v, _i) => {
		let d = {
			"UserID": localStorage.getItem("id"),
			"BRANDID": "0",
			"PRODUCTID": "0",
			"FILETYPE": "UserPic",
			"FILEPATH": BLOB_URL + BLOB_TEAM_MEMBER_CONTAINER + '/' + da?.name,
			"FILEDATA": "",
			"FILENAME": da?.name,

		}
		finalArray.push(d)
		//})
		//console.log("tee", finalArray);
		if (finalArray.length) {

			// setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob([da], BLOB_TEAM_MEMBER_CONTAINER).then(async res => {
				console.log("resposnse", res);

				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					console.log(res.data.responseMessage)
					
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}

	const handleCropImage = (e) => {

		if (imageRef && crop.width && crop.height) {

			const canvas = document.createElement('canvas');
			const scaleX = imageRef.naturalWidth / imageRef.width;
			const scaleY = imageRef.naturalHeight / imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(
				imageRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);

			// const croppedImageUrl = canvas.toDataURL('image/jpeg', 'image/png');
			// setEditPriview(croppedImageUrl);
			// console.log("imageCrop", croppedImageUrl)
			//window.$('#myModel2').modal('hide');
			canvas.toBlob(
				(blob) => {
					const file = new File([blob], imageName, {
						type: "image/png",
						//preview: URL.createObjectURL(File),
						lastModified: Date.now(),
					});
					setGetApiResp(0);
					//setUploadProfile(file);
					console.log("file", file)
					setSelectedImage(URL.createObjectURL(file));
					setCompleteCrop(URL.createObjectURL(file));
					setProfilePic(URL.createObjectURL(file));
					savePofile(file);
					//setUploadProfile(file)
					window.$('#myModel2').modal('hide');
				}

			)
		}

	}
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					<BuyerHeader collapseSidebar={collapseSidebar} profilePic={profilePic} />
					<div className="rightContetBlock">
						<div className="headingTop">

						</div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-2 ImgPart" >
										<div className="profileImages editoptions" onClick={handleDivClick}>
											{getApiResp ? (<img src={userData.filePath} alt="Selected" />) : completeCrop ? (
												<img src={completeCrop} alt="Selected" />
											) : (
												<img src={DefaultAvtar} />
											)}


											<div className="clickable-div"></div>
											
												<input type='file' className='upload-input' id="uploadFileInput" ref={fileInputRef} onClick={e => (e.target.value = null)} onChange={handleFileInputChange} style={{ display: isFileInputVisible ? 'block' : 'none' }} accept="image/*" />
											
										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>
											</div>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="editUserProfile">
											<Link to="/buyer/edit-profile" className="btn btn-primary save-button">Edit Profile</Link>
										</div>
									</div>
								</div>
							</div>

						</div>

						<div className="addProfilePopup editpicture">
							<div className={`modal fade ${showModal ? 'show' : ''}`} id="myModel2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false" >
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" onClick={closeUploadMode}><span aria-hidden="true">&times;</span></button>
											<h4 className="modal-title" id="myModalLabel">Profile Upload</h4>
										</div>
										<div className="modal-body">
											<div className="row">
												<div className="col-md-12">
													{/* <div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} accept="image/*" />

																</div> */}
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														{/* <ReactCrop src={editPriview} onImageLoaded={setImage}
																		crop={crop} onChange={CropImg} />
																	<img src={editPriview} key="0" alt={"Image"} /> */}
														<ReactCrop crop={crop} aspect={1 / 1} onChange={CropImg} onImageLoaded={ImgLoad}>
															<img src={selectedImage} onLoad={ImgLoad} />
														</ReactCrop>
														<div className='checkbtnsse'>
														<button className='btn btn-primary save-button' onClick={handleCropImage}>Crop</button>
														</div>
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Contact Details</a></li>
										<li><a data-toggle="tab" href="#menu1">Business Details</a></li>
										{/* <li><a data-toggle="tab" href="#menu2">Bank Details</a></li>
									<li><a data-toggle="tab" href="#menu5">Subscription Details</a></li> */}
									</ul>
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Full Name</label>
														<span className="ValueEntites">{userData?.firstName} {userData?.lastName}</span>
													</div>
													<div className="comPundEntites">
														<label>Mobile</label>
														<span className="ValueEntites">{userData?.businessPhone}</span>
													</div>
													<div className="comPundEntites">
														<label>Mobile (Optional)</label>
														<span className="ValueEntites">{userData?.optionalPhone}</span>
													</div>
													<div className="comPundEntites">
														<label>E-mail</label>
														<span className="ValueEntites">{userData?.emailID}</span>
													</div>

												</div>
											</div>
										</div>
										<div id="menu1" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Business Name</label>
														<span className="ValueEntites">{userData?.businessName}</span>
													</div>
													<div className="comPundEntites">
														<label>EIN</label>
														<span className="ValueEntites">{userData?.ein}</span>
													</div>
													<div className="comPundEntites">
														<label>State Tax Number</label>
														<span className="ValueEntites">{userData?.stateTaxNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Address</label>
														<span className="ValueEntites">{userData?.streetAddress}, {userData?.city}, {userData?.stateName}, {userData?.zipCode}, USA.</span>
													</div>
												</div>
											</div>
										</div>
										<div id="menu2" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Bank Name</label>
														<span className="ValueEntites">{userData?.bankName}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Address</label>
														<span className="ValueEntites">{userData?.bankAddress}</span>
													</div>
													<div className="comPundEntites">
														<label>Routing Number</label>
														<span className="ValueEntites">{userData?.routingNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Account Number</label>
														<span className="ValueEntites">{userData?.accountNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Account Type</label>
														<span className="ValueEntites">{(userData?.accountType == 1) ? "Checking Account" : "Saving Account"}</span>
													</div>
													<div className="comPundEntites">
														<label>Name on Account</label>
														<span className="ValueEntites">{userData?.accountName}</span>
													</div>
												</div>
											</div>
										</div>
										<div id="menu5" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Plan</label>
														<span className="ValueEntites">{userData?.planType}</span>
													</div>
													<div className="comPundEntites">
														<label>Amount</label>
														<span className="ValueEntites">${userData?.planAmount}</span>
													</div>
													<div className="comPundEntites">
														<label>Start Date</label>
														<span className="ValueEntites">{moment(userData?.startDate).format("MM-DD-YYYY HH:mm")}</span>
													</div>
													<div className="comPundEntites">
														<label>Renewal Date</label>
														<span className="ValueEntites">{moment(userData?.endDate).format("MM-DD-YYYY HH:mm")}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<BuyerFooter />
				</div>
			</div>
		</>
	)
}