import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import Logo from './../../assets/images/logo.png'
import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import StripePaymentCard from "./paymentCard";
import { API_URL, API_KEY, API_TOKEN, STRIPE_PK } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import InputMask from 'react-input-mask';
import Select from 'react-select';
const stripePromise = loadStripe(STRIPE_PK)
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function CartList() {
	document.title = "BevPort - My Cart"
	const [toggle, setToggle] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(false)
	const [cartList, setCartList] = useState([])
	const [payableAmount, setPayableAmount] = useState(0)
	const [postData, setPostData] = useState([])
	const [loader, setLoader] = useState(false)

	let businessName = localStorage.getItem("businessName");
	const [addr, setAddr] = useState("");
	const [mobile, setMobile] = useState("");
	const [bussAddrID, setBussAddrID] = useState("");

	const [addrId, setAddrId] = useState();
	const [otherAddrValue, setOtherAddrValue] = useState("")
	const [bussinessAddress, setBussinessAddress] = useState("")
	const [businessAddressCheck, setBusinessAddressCheck] = useState(true);

	const [buttonText, setButtonText] = useState("Save");
	const [stateList, setStateList] = useState([]);
	const [streetAddr, setStreetAddr] = useState("");
	const [streetAddrError, setStreetAddrError] = useState(false);
	const [city, setCity] = useState("");
	const [cityError, setCityError] = useState(false);
	const [stateValue, setStateValue] = useState("");
	const [stateCode, setStateCode] = useState("");
	const [stateValueError, setStateValueError] = useState(false);
	const [zipCode, setZipCode] = useState("");
	const [zipCodeError, setZipCodeError] = useState(false);
	const [popupMobile, setPopupMobile] = useState("");
	const [popupMobileError, setPopupMobileError] = useState(false);
	const [buyerValue, setBuyerValue] = useState("");
	const [buyerError, setBuyerError] = useState(false);
	const [buyerList, setBuyerList] = useState([]);
	const [quantityError, setQuantityError] = useState(false);

	const [otherAddress, setOtherAddress] = useState([]);

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		getCartList()
		cartWishListCountFunc()
		// getNewAddress()
		getStateList()
		getBuyerList()
	}, [])

	useEffect(() => {
		console.log("buyerValue", buyerList)
		getBusinessAddress(buyerValue?.id)
		getNewAddress(buyerValue?.id);
	}, [buyerList, buyerValue])
	let Navigate = useNavigate();
	const getCartList = async () => {
		const formData = {
			"ID": localStorage.getItem("id")
		}
		setSubmitLoader(true);
		await axios.post(API_URL + "GetCartProducts?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200" && res.data?.responseData) {
				let data = res.data?.responseData;
				let tArray = []
				data.forEach((_x, _y) => {
					_x.fPrice = _x?.casePrice * _x?.quantity
					tArray.push(_x)
				})
				setCartList(tArray)
			} else {
				setCartList([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const getBuyerList = async () => {
		await axios.post(API_URL + "GetAllBuyersList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let arr = [];
				res.data.responseData?.map((_v, _i) => {
					arr.push({ id: _v.id, label: _v?.businessName })
				})
				setBuyerList(arr);

			} else {
				setBuyerList([])

			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const [remove, setRemove] = useState(false)
	const handleRemoveCart = async (id, index) => {
		const formData = {
			"ID": id
		}
		setLoader(true);
		await axios.post(API_URL + "RemoveCartProduct?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				//getCartList()
				cartList.splice(index, 1);
				setRemove(!remove)
				cartWishListCountFunc()
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}
	const [cartCount, setCartCount] = useState(0)
	const [wishListCount, setWishListCount] = useState(0)
	const cartWishListCountFunc = async () => {
		await axios.post(API_URL + "WishCartCount?code=" + API_KEY, { "ID": localStorage.getItem("id") }, config).then((res) => {
			if (res.data.responseCode === "200" && res.data.responseData.length) {
				let data = res.data.responseData[0];
				setCartCount(data?.cartCount)
				setWishListCount(data?.wishCount)
			} else {
				setCartCount(0)
				setWishListCount(0)
			}
		}).catch(function (error) {
		})
	}
	useEffect(() => {
		if (cartList.length) {
			let formData = []
			let totalPayableAmount = 0;
			cartList.forEach((val, ind) => {
				totalPayableAmount += parseFloat(val?.fPrice)
				let f = {
					productID: val?.productID,
					Quantity: val?.quantity
				}
				formData.push(f)
			})
			setPayableAmount(parseFloat(totalPayableAmount).toFixed(2))
			setPostData(formData)
		}
	}, [cartList, remove])

	const getBusinessAddress = async (id) => {
		let busAddr = "";
		let formData = {
			// ID: localStorage.getItem("id")
			ID: id
		}
		await axios.post(API_URL + "GetUserAddress?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let resAddr = res.data?.businessAddress?.[0];
				console.log("jbhjnk", resAddr)
				busAddr = resAddr?.streetAddress + ", " + resAddr?.city + ", " + resAddr?.state + " " + resAddr?.zipCode;
				setAddr(busAddr);
				setMobile(resAddr?.businessPhone);
				setBussAddrID(resAddr?.id)
			}
		}).catch(function (error) {
		})
	}
	const getNewAddress = async (id) => {
		let formData = {
			ID: id
		}
		await axios.post(API_URL + "GetUserAddress?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let otherAddr = res.data?.responseData;
				setOtherAddress(otherAddr);
			}
		}).catch(function (error) {
		})
	}
	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					stateOption.push({ value: da.statename, label: da.statename, id: da.id });
				}
				);
				setStateList(stateOption)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}



	const handleQuantity = (e, index) => {
		let count = 0;
		// if(e.target.value == 0){
		// 	setQuantityError(true);
		// }else{
		// 	setQuantityError(false);
		// }
		let tempData = [];
		let qty = (e.target.value) ? e.target.value : 0;
		cartList.forEach((_v, _i) => {
			if (_i === index) {
				/*let price = 0;
				if(_v?.fType === "case"){
					price = _v?.casePrice*parseInt(qty)
				}
				if(_v?.fType === "pack"){
					price = _v?.packPrice*parseInt(qty)
				}
				if(_v?.fType === "unit"){
					price = _v?.unitPrice*parseInt(qty)
				}*/
				_v.fPrice = _v?.casePrice * parseInt(qty)
				_v.quantity = e.target.value
			}
			tempData.push(_v)
		})
		tempData.map((_v, _i) => {
			if (_v?.quantity == 0) {
				count++;
			}
		})
		if (count) {
			setQuantityError(true);
		} else {
			setQuantityError(false);
		}
		setCartList(tempData)
	}
	const updateQuantity = async (e, cartID) => {
		let qty = (e.target.value) ? parseInt(e.target.value) : 0;
		if (qty) {
			let formData = {
				ID: cartID,
				Quantity: qty
			}
			await axios.post(API_URL + "UpdateCart?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					cartWishListCountFunc()
				}
			}).catch(function (error) {
			})
		}
	}
	const handleAcceptNumber = (evt) => {
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode < 48 || charCode > 57) {
			evt.preventDefault();
		}
	}
	const submitFunction = async (token) => {
		if (placeOrderValidation()) {
			if (!quantityError) {

				setLoader(true)
				let formData = {};
				if (addrId) {
					formData = {
						FinalPrice: payableAmount,
						//Token: token?.id,
						UserID: buyerValue.id,
						SalesRepID: localStorage.getItem("id"),
						id: addrId,
						data: postData,
					}
				}
				else {
					formData = {
						FinalPrice: payableAmount,
						//Token: token?.id,
						UserID: buyerValue.id,
						SalesRepID: localStorage.getItem("id"),
						id: "0",
						data: postData
					}
				}
				await axios.post(API_URL + "PlaceOrder?code=" + API_KEY, formData, config).then((res) => {
					setLoader(false)
					if (res.data.responseCode === "200") {
						toast.success(res.data.responseMessage);
						Navigate("/salesrep/order-history");
						cartWishListCountFunc()
						getCartList()
					} else {
						toast.error(res.data.responseMessage);
					}
				}).catch(function (error) {
					setLoader(false)
				})
			}
			else {
				toast.error("Add Quantity")
			}
		}
	}

	const handleAddrRadioButton = (e) => {
		setAddrId("");
		setBussinessAddress(e.target.value);
		setBusinessAddressCheck(true);
		setOtherAddrValue("")
	}

	const handleOtherAddrRadioButton = (e, id) => {
		setAddrId(id);
		setBusinessAddressCheck(false);
		setBussinessAddress("")
		setOtherAddrValue(e.target.value)
	}

	const placeOrderValidation = () => {
		let formIsValid = true;
		if (!businessAddressCheck && !addrId) {
			formIsValid = false;
		}
		if (!buyerValue?.id) {
			setBuyerError(true);
			formIsValid = false;
		}
		return formIsValid;
	}

	const handlebuyer = (e) => {
		setBuyerValue({ id: e.id, value: e.label, label: e.label });
		setBuyerError(false);
		if(otherAddress.length){
			setAddrId("");
			setOtherAddrValue("");
		}
		setBusinessAddressCheck(true);

		// setAddrId("");
	}

	return (
		<>

			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} cartCount={cartCount} wishListCount={wishListCount} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>My Cart</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content cartPages">
								<div className="Content-body">
									<div className="card ">
										<div className="row">
											<div className={cartList.length ? "col-lg-12 col-md-12 col-sm-12 col-12" : "col-lg-12 col-md-12 col-sm-12 col-12"}>
												<div className=" card-area list-items ">
													<div className="row heading">
														<div className="col-md-12">
															<div className="cartHeadings">
																<h4 className="main-head">Items in your cart</h4>
																<h5 className="items">({cartList.length}) {cartList.length > 1 ? "items" : "item"}</h5>
															</div>
														</div>
													</div>
													{
														cartList.length ?
															cartList.map((_v, _i) => {
																return (
																	<div className="cartItem row align-items-start">
																		<div className="col-lg-3 col-sm-3  col-md-3 col-3 mb-2">
																			<div className="productThumimf">
																				<img className="w-100" src={(_v?.filepath) ? _v?.filepath : Logo} alt="art image" />
																			</div>
																		</div>
																		<div className="col-lg-5 col-sm-5  col-md-5 col-5 mb-2">
																			<p className="pl-1 mb-0">{_v?.productName}</p>
																			<div className="sub-amountactual">${parseFloat(_v?.casePrice).toFixed(2)}/case</div>
																			<h6 className="subbrnadNames">{_v?.brandName}</h6>
																			<p className="trash-btn"><span onClick={() => handleRemoveCart(_v?.id, _i)}> <i className="fa fa-trash"></i>  Remove </span></p>

																		</div>
																		<div className="col-lg-2 col-sm-2  col-md-2 col-2">

																			<input type="text" className="form-control" value={_v?.quantity} onChange={(e) => handleQuantity(e, _i)} onBlur={(e) => updateQuantity(e, _v?.id)} onKeyPress={() => handleAcceptNumber()} placeholder="Quantity" />
																		</div>
																		<div className="col-lg-2 col-sm-2  col-md-2 col-2 text-right">
																			<p id={"cartItem1Price_" + _i}>${parseFloat(_v?.fPrice).toFixed(2)}</p>
																		</div>
																	</div>
																)
															})
															: <>
																<div className=" footer-list">
																	<div className="emptyCartdiv">
																		<div className="noiteminYOurcart">No Item in Your Cart.</div>
																		<Link className="btn btn-primary" to='/salesrep/products'>Continue Shopping</Link>
																	</div>
																</div>
															</>
													}
													<div className="row TotlaPyes">
														{cartList.length ? <div className="col-lg-10 col-sm-10 col-md-10 col-10"><span className="totla-Text">Sub Total</span><span className="total_numberText">${payableAmount}</span></div> : ""}
														<div className="col-lg-2 col-sm-2 col-md-2 col-2 text-right">

														</div>
													</div>




												</div>
											</div>
											{/*
											{
												cartList.length?<Elements stripe={stripePromise}><StripePaymentCard payableAmount={payableAmount} postData={postData} submitFunction={submitFunction} loaderFunc={loaderFunc}/></Elements>:""
											 }
											*/}
										</div>
									</div>

								</div>

								{(cartList.length) ? <div className="card-body bottomTwo">
									<div className="card">
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="card-area list-items">
													<div class="row heading">
														<div class="col-md-12">
															<div class="cartHeadings">
																<h4 class="main-head">Select Business</h4>

															</div>
														</div>
													</div>
													<div className='cartItem row selectBuyers'>
														<div className='col-md-6'>
															<Select className={(buyerError) ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																options={buyerList}
																onChange={handlebuyer}
																value={buyerValue}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Select Business</div>}
																isSearchable
															/>
														</div>
													</div>


												</div>
											</div>
										</div>
									</div>
								</div> : ""}

								{(cartList.length && buyerValue.id) ? <div className="card-body bottomTwo">
									<div className="card">
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="card-area list-items">
													<div class="row heading">
														<div class="col-md-12">
															<div class="cartHeadings">
																<h4 class="main-head">Delivery Address</h4>
															</div>
														</div>
													</div>


													<div className="row BusinessBlockAdd">
														<div className="col-md-12">
															<div className="cardCodadd">
																<div class="radioAddressBlock">
																	<div class="radio inline-block">
																		<label><input type="radio" name="optsradio" checked={businessAddressCheck} onClick={(e) => handleAddrRadioButton(e)} value={bussinessAddress} /></label>
																	</div>
																	<div className="radio-Content">
																		<h4>{businessName}</h4>
																		<p><span>Mobile:</span> {mobile}</p>
																		<p>{addr}</p>
																	</div>
																</div>
															</div>
															{(otherAddress.length && buyerValue.id) ? 
															otherAddress.map((_v, _i) => {
																return (<div className="cardCodadd">
																	<div class="radioAddressBlock">
																		<div class="radio inline-block">
																			<label><input type="radio" name="optsradio" onClick={(e) => handleOtherAddrRadioButton(e, _v?.id)} value={otherAddrValue}/></label>
																		</div>
																		<div className="radio-Content">
																			<h4>{businessName}</h4>
																			<p><span>Mobile:</span> {_v?.businessPhone}</p>
																			<p>{_v?.streetAddress + ", " + _v?.city + ", " + _v?.state + " " + _v?.zipCode} </p>
																		</div>
																	</div>
																</div>)
															})
															:""
															}

														</div>
													</div>


												</div>
											</div>
										</div>
									</div>
								</div> : ""}

								{/* {(cartList.length) ? <div className="card-body bottomTwo">
									<div className="card">
										{(otherAddress.length && buyerValue.id) ? <div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="card-area list-items">
													<div class="row heading">
														<div class="col-md-12">
															<div class="cartHeadings">
																<h4 class="main-head">Delivery Address</h4>
															</div>
														</div>
													</div>

													<div className="row deleiveryAddress">
														<div className="col-md-12">
															{otherAddress.map((_v, _i) => {
																return (<div className="cardCodadd">
																	<div class="radioAddressBlock">
																		<div class="radio inline-block">
																			<label><input type="radio" name="optsradio" onClick={(e) => handleOtherAddrRadioButton(e, _v?.id)} value={otherAddrValue}/></label>
																		</div>
																		<div className="radio-Content">
																			<h4>{businessName}</h4>
																			<p><span>Mobile:</span> {_v?.businessPhone}</p>
																			<p>{_v?.streetAddress + ", " + _v?.city + ", " + _v?.state + " " + _v?.zipCode} </p>
																		</div>
																	</div>
																</div>)
															})
															}
														</div>
													</div>


												</div>
											</div>
										</div> : ""}
									</div>
								</div> : ""} */}

								{(cartList.length) ? <div className="card-body bottomTwo">
									<div className="card">
										<div className="row PlaceOrderBottom">
											<div className="col-md-12">
												<div className="cardCodadd">
													<div className="row TotlaPyes">
														{cartList.length ? <div className="col-lg-10 col-sm-10 col-md-10 col-10"><span className="totla-Text">Total</span><span className="total_numberText">${payableAmount}</span></div> : ""}
														<div className="col-lg-2 col-sm-2 col-md-2 col-2 text-right">
															<div className="formBtns btn-spaces placeore">
																{cartList.length ? <button type="button" className="btn btn-primary save-button btn-block" onClick={submitFunction} >Place Order</button> : ""}
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div> : ""}



							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}