import React, { useState } from "react";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../../config/constant.js';
import Loader from "./../Loader"
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BasicInfo({ handlePage, userType }) {
	const [fName, setFname] = useState("")
	const [fnameError, setFnameError] = useState(false)
	const [lName, setLname] = useState("")
	const [lnameError, setLnameError] = useState(false)
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [cEmail, setCEmail] = useState("")
	const [cEmailError, setCEmailError] = useState(false)
	const [password, setPassword] = useState("")
	const [passwordError, setPasswordError] = useState(false)
	const [cPassword, setCPassword] = useState("")
	const [cPasswordError, setCPasswordError] = useState(false)
	const [emailSameError, setEmailSameError] = useState(false)
	const [passwordSameError, setPasswordSameError] = useState(false)
	const [passError, setPassError] = useState(false)
	const [passText, setPassText] = useState("")
	const [dupError, setDupError] = useState(false)
	const [dupErrorText, setDupErrorText] = useState("")
	const [submitLoader, setSubmitLoader] = useState(false)
	const [visible, SetVisible] = useState(false);

	const handleFirstName = (e) => {
		setFnameError(false)
		setFname(e.target.value)
	}
	const handleLastName = (e) => {
		setLnameError(false)
		setLname(e.target.value)
	}
	const handleEmail = (e) => {
		setEmailError(false)
		setEmailSameError(false)
		setEmail(e.target.value)
	}
	const handleConfirmEmail = (e) => {
		setCEmailError(false)
		setEmailSameError(false)
		setCEmail(e.target.value)
	}
	const handlePassword = (e) => {
		setPassError(false)
		setPassText("")
		setPasswordError(false)
		setPassword(e.target.value)
	}
	const handleConfirmPassword = (e) => {
		setPasswordSameError(false)
		setCPasswordError(false)
		setCPassword(e.target.value)
	}
	const handleShow = () =>{
		SetVisible(!visible);
	}
	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	function checkPwd(str) {
		if (str.length < 6) {
			setPassError(true)
			setPassText("Must have at least 6 characters.")
			return false;
		} else if (str.search(/\d/) == -1) {
			setPassError(true)
			setPassText("Must have at least one number.")
			return false;
		} else if (str.search(/[A-Z]/) == -1) {
			setPassError(true)
			setPassText("Must have at least one capital letter.")
			return false;
		} else if (str.search(/[a-z]/) == -1) {
			setPassError(true)
			setPassText("Must have at least one small letter.")
			return false;
		} else if (str.search(/[!@#$%^&*()]/) == -1) {
			setPassError(true)
			setPassText("Must have at least one symbol.")
			return false;
		}
		return true;
	}
	const handleValidation = () => {
		let formIsValid = true;
		if (!fName) {
			formIsValid = false;
			setFnameError(true)
		}
		if (!lName) {
			formIsValid = false;
			setLnameError(true)
		}
		if (!email) {
			formIsValid = false;
			setEmailError(true)
		}
		if (!validateEmail(email)) {
			formIsValid = false;
			setEmailError(true)
		}
		if (!cEmail) {
			formIsValid = false;
			setCEmailError(true)
		}
		if (cEmail && email !== cEmail) {
			formIsValid = false;
			setEmailSameError(true)
		}
		if (!password) {
			formIsValid = false;
			setPasswordError(true)
		}
		if (password && !checkPwd(password)) {
			formIsValid = false;
			setPasswordError(true)
		}
		if (!cPassword) {
			formIsValid = false;
			setCPasswordError(true)
		}
		if (cPassword && password !== cPassword) {
			formIsValid = false;
			setPasswordSameError(true)
		}
		return formIsValid;
	}
	const checkEmailAddressFunc = async (e) => {
		setDupError(false)
		setDupErrorText("")
		await axios.post(API_URL + "GetUser?code=" + API_KEY, { EmailID: e.target.value }, config).then((res) => {
			if (res.data.responseCode === "401") {
				setDupError(true)
				setDupErrorText(res.data.responseMessage)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}
	const handleBasicInfo = async (e) => {
		e.preventDefault()
		if (handleValidation() && !dupError) {
			setSubmitLoader(true)
			let formData = {
				"FirstName": fName,
				"LastName": lName,
				"EmailID": email,
				"PhoneNumber": "",
				"Password": password,
				"UserType": userType
			}
			await axios.post(API_URL + "UserRegister?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					let lastUserID = "";
					if (res.data.responseData.length) {
						lastUserID = res.data.responseData[0].id;
					}
					handlePage(2, lastUserID)
					toast.success(res.data.responseMessage);
				} else {
					toast.error(res.data.responseMessage);
				}
				setSubmitLoader(false)
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
			})
		}
	}
	return (
		<>

			{submitLoader ? <Loader /> : ""}
			<form onSubmit={handleBasicInfo} autoComplete="off">
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label for="firstname">First Name <span className="red-tick">*</span></label>
							<input type="text" className={fnameError ? "form-control error" : "form-control"} name="firstname" value={fName} placeholder="First Name" onChange={handleFirstName} />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label for="firstname">Last Name <span className="red-tick">*</span></label>
							<input type="text" className={lnameError ? "form-control error" : "form-control"} name="firstname" value={lName} placeholder="Last Name" onChange={handleLastName} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label for="email">Email Address <span className="red-tick">*</span></label>
							<input type="text" className={emailError ? "form-control error" : "form-control"} name="email" value={email} placeholder="Email Address" onChange={handleEmail} onBlur={checkEmailAddressFunc} />
							{dupError ? <span className="error-message">{dupErrorText}</span> : ""}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label for="cemail">Confirm Email <span className="red-tick">*</span></label>
							<input type="text" className={cEmailError ? "form-control error" : "form-control"} name="cemail" value={cEmail} placeholder="Confirm Email" onChange={handleConfirmEmail} />
							{emailSameError ? <span className="error-message">Confirm email should be same as email.</span> : ""}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group passwordeye">
							<label for="cemail">Password <span className="red-tick">*</span></label>
							<div className="showhidepassowrd">
								<input type={visible?"text":"password"} className={passwordError ? "form-control error" : "form-control"} name="password" value={password} placeholder="Password" onChange={handlePassword} />
								{passError ? <span className="error-message">{passText}</span> : ""}
								<span className="eyeslash" onClick={handleShow} > {!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> :<i className="fa fa-eye" aria-hidden="true"></i>}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group ">
							<label for="cemail">Confirm Password <span className="red-tick">*</span></label>

							<input type="password" className={cPasswordError ? "form-control error" : "form-control"} name="password" value={cPassword} placeholder="Confirm Password" onChange={handleConfirmPassword} />
							{passwordSameError ? <span className="error-message">Confirm password should be same as password.</span> : ""}


						</div>
					</div>
				</div>
				
						<div className="submitbtns">
							<button className="btn btn-default" type="submit" id="">JOIN THE REVOLUTION!</button>
						</div>
					
			</form>
		</>
	)
}