import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER } from './../../config/constant.js';
import DefaultAvtar from './../../assets/images/avatar-1.jpg';
import UplaodDocIcon from './../../assets/images/uploaddoc.png';
import Select from 'react-select';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { useRef } from 'react';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: 'transparent',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			border: '1px solid #b6b6af',
			boxShadow: null,
		},
		'&:focus': {
			border: '1px solid transparent',
			boxShadow: null,
		}
	})
};
export default function Profile() {
	document.title = "BevPort - Profile"
	const [toggle, setToggle] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [userData, setUserData] = useState("");

	const [cardData, setCardData] = useState("");
	const [monthLength, setMonthLength] = useState("");

	const [getApiResp, setGetApiResp] = useState(0);
	const [profilePic, setProfilePic] = useState("");
	const fileInputRef = useRef(null);
	const [isFileInputVisible, setFileInputVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [imageRef, setImageRef] = useState(null);
	const [imageName, setImageName] = useState("");
	const [completeCrop, setCompleteCrop] = useState("");
	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		x: 7,
		y: 7,

	});
	
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const createdBy = localStorage.getItem("createdBy");

	useEffect(() => {
		console.log("ggsadg", createdBy);
		let id = localStorage.getItem("id")
		if(createdBy == 0){
			getUserProfileFunction(id);
		getCardDetailsFunction(id);
		}else{
			getTeamInfo(id)
		}
		
	}, [])
	useEffect(() => {
		if (cardData) {
			setMonthLength(getlength(cardData?.expiryMonth));
		}
	})
	const CropImg = (e) => {

		setCrop(e);
	}
	const ImgLoad = (e) => {
		setImageRef(e.target);
	}
	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				if(createdBy == 0){
				setUserData(data)
				setProfilePic(data?.filePath);
				}
				if (data.filePath != "") {
					setGetApiResp(1);
				}
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getTeamInfo = async (userID) => {
		await axios.post(API_URL + "GetMemberInfo?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				console.log(data)
				
				setUserData(data)
				setProfilePic(data?.filePath);
				
				
				if (data.filePath != "") {
					setGetApiResp(1);
				}

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getCardDetailsFunction = async (userID) => {
		await axios.post(API_URL + "GetCardInfo?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setCardData(data);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getlength = (number) => {
		return number.toString().length;
	}

	useEffect(() => {
		getStateList();
	}, [])

	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {

					stateOption.push({ value: da.statename, label: da.statename, id: da.id });

				}
				);
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	const closeUploadMode = (e) => {
		e.preventDefault()
		window.$('#myModel2').modal('hide')
	}

	const handleClose = () => {
		setShowModal(false);
	};

	const handleDivClick = () => {
		setFileInputVisible(true);
		//document.getElementById("uploadFileInput").click();
		fileInputRef.current.click()

	};

	const handleFileInputChange = (event) => {
		// Handle file selection here
		const selectedFile = event.target.files[0];
		console.log('Selected file:', selectedFile);
		if (selectedFile) {
			const imageURL = URL.createObjectURL(selectedFile);
			console.log("imageUrl", imageURL);
			//setUploadProfile(selectedFile);
			setImageName(selectedFile?.name)
			setSelectedImage(imageURL);
			window.$('#myModel2').modal('show')
		}

		// After handling the file, hide the file input
		setFileInputVisible(false);
	};

	const handleCropImage = (e) => {

		if (imageRef && crop.width && crop.height) {

			const canvas = document.createElement('canvas');
			const scaleX = imageRef.naturalWidth / imageRef.width;
			const scaleY = imageRef.naturalHeight / imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(
				imageRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);

			// const croppedImageUrl = canvas.toDataURL('image/jpeg', 'image/png');
			// setEditPriview(croppedImageUrl);
			// console.log("imageCrop", croppedImageUrl)
			//window.$('#myModel2').modal('hide');
			canvas.toBlob(
				(blob) => {
					const file = new File([blob], imageName, {
						type: "image/png",
						//preview: URL.createObjectURL(File),
						lastModified: Date.now(),
					});
					setGetApiResp(0);
					//setUploadProfile(file);
					console.log("file", file)
					setSelectedImage(URL.createObjectURL(file));
					setCompleteCrop(URL.createObjectURL(file));
					setProfilePic(URL.createObjectURL(file));
					savePofile(file);
					//setUploadProfile(file)
					window.$('#myModel2').modal('hide');
				}

			)
		}

	}

	const savePofile = async (dataFile) => {
		let da = dataFile;
		console.log("data", da);
		let finalArray = [];
		//da.forEach((_v, _i) => {
		let d = {
			"UserID": localStorage.getItem("id"),
			"BRANDID": "0",
			"PRODUCTID": "0",
			"FILETYPE": "UserPic",
			"FILEPATH": BLOB_URL + BLOB_TEAM_MEMBER_CONTAINER + '/' + da?.name,
			"FILEDATA": "",
			"FILENAME": da?.name,

		}
		finalArray.push(d)
		//})
		//console.log("tee", finalArray);
		if (finalArray.length) {

			// setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob([da], BLOB_TEAM_MEMBER_CONTAINER).then(async res => {
				console.log("resposnse", res);

				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					console.log(res.data.responseMessage)
					
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}

	return (
		<>
			
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} profilePic={profilePic}/>
					<div className="rightContetBlock">
						<div className="headingTop">

						</div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-2 ImgPart">
									<div className="profileImages editoptions" onClick={handleDivClick}>
											{getApiResp ? (<img src={userData.filePath} alt="Selected" />) : completeCrop ? (
												<img src={completeCrop} alt="Selected" />
											) : (
												<img src={DefaultAvtar} />
											)}


											<div className="clickable-div"></div>
											
												<input type='file' className='upload-input' id="uploadFileInput" ref={fileInputRef} onClick={e => (e.target.value = null)} onChange={handleFileInputChange} style={{ display: isFileInputVisible ? 'block' : 'none' }} accept="image/*" disabled={(createdBy == 0)?false:true}/>
											
										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												{(createdBy == 0)?<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>:""}
											</div>
											<div className="profileIcons">
												{(createdBy == 0)?<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>:""}
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="addProfilePopup editpicture">
							<div className={`modal fade ${showModal ? 'show' : ''}`} id="myModel2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false" >
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" onClick={closeUploadMode}><span aria-hidden="true">&times;</span></button>
											<h4 className="modal-title" id="myModalLabel">Profile Upload</h4>
										</div>
										<div className="modal-body">
											<div className="row">
												<div className="col-md-12">
													{/* <div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} accept="image/*" />

																</div> */}
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														{/* <ReactCrop src={editPriview} onImageLoaded={setImage}
																		crop={crop} onChange={CropImg} />
																	<img src={editPriview} key="0" alt={"Image"} /> */}
														<ReactCrop crop={crop} aspect={1 / 1} onChange={CropImg} onImageLoaded={ImgLoad}>
															<img src={selectedImage} onLoad={ImgLoad} />
														</ReactCrop>
														<div className='checkbtnsse'>
														<button className='btn btn-primary save-button' onClick={handleCropImage}>Crop</button>
														</div>
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Contact Details</a></li>
									</ul>
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Full Name</label>
														<span className="ValueEntites">{userData?.firstName} {userData?.lastName}</span>
													</div>
													{(createdBy == 0)?<div className="comPundEntites">
														<label>Mobile</label>
														<span className="ValueEntites">{userData?.phoneNumber}</span>
													</div>:""}
													<div className="comPundEntites">
														<label>E-mail</label>
														<span className="ValueEntites">{userData?.emailID}</span>
													</div>
													{(createdBy == 0)?<div className="comPundEntites">
														<label>Address</label>
														<span className="ValueEntites">{userData?.streetAddress}, {userData?.city}, {userData?.stateName}, {userData?.zipCode}, USA</span>
													</div>:""}

												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}