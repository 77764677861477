import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
    document.title = "BevPort - Buyer Purchase Order List"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Qty. in Cases',
            selector: row => row.quantityCount,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.totalAmount),
            cell: row => "$" + parseFloat(row.totalAmount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDate) ? moment(row.orderDate) : 0,
            cell: row => row.orderDate ? moment(row.orderDate).format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            // cell: row => (row.status == 0) ? <span style={{ "color": "#00aeef" }}>Pending</span>: (row.status == 20)?<span style={{ "color": "#00aeef" }}>Rejected</span> : <span style={{ "color": "#00aeef" }}>P.O. Created</span>,
            cell: row => ( row.status == 1 || row.status == 2  ||  row.status == 3 ) ? <span style={{ "color": "#00aeef" }}>Placed</span> :( row.status == 4 || row.status == 5 || row.status == 6) ? <span style={{ "color": "#00aeef" }}>In Transit</span>:( row.status == 7)?<span style={{ "color": "#00aeef" }}>Delivered</span>:(row.status == 20)?<span style={{ "color": "#00aeef" }}>Rejected</span>:(row.status == 0)?<span style={{ "color": "#00aeef" }}>Pending</span>:"",
            sortable: true

        },
        {
            name: 'Action',
            selector: row => <>
                {/* <Link className="btn btn-primary" to={(row.status == 1) ? "/buyer/order-detail/" + row.orderID : "/buyer/order-detail/" + row.orderID} target='_blank' >View</Link></>, */}
                <Link className="btn btn-primary" to={(row.status != 20)?"/buyer/order-detail/" + row.orderID:"#"} target={(row.status != 20)?'_blank':''} >View</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        let userID = localStorage.getItem("id")
        getOrderHistory(userID)
    }, [])
    const getOrderHistory = async (userID) => {
        await axios.post(API_URL + "OrderHistoryBuyer?code=" + API_KEY, { ID: userID }, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)


            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
      };
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <BuyerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Purchase Order</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    {/* <h3 className="card-heading">Order List</h3> */}
                                <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">
                                
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noDataComponent={<span className='norecordFound'>No record found!</span>}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={4}
                                       
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BuyerFooter />
                </div>
            </div>
        </>
    )
}