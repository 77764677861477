

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import LoginBGSide from "./../../assets/images/loginSide.jpg"
import privacybg from "./../../assets/images/privacy-page.jpg"
import LoginLeaves from "./../../assets/images/loginLeaves.png"
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Loader from "./Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
export default function Resource() {
    document.title = "BevPort - Forgot Password"
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [submitLoader,setSubmitLoader] = useState(false)
    const [visible, SetVisible] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false)
    const handleEmail = (e) => {
        setEmail(e.target.value);
        setEmailError(false);

    }
    useEffect(() => {
		const script = document.getElementById('recaptcha-key');
	    if (script) {
	      	script.remove();
	    }
	    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
	    if (recaptchaElems.length) {
	      	recaptchaElems[0].remove();
	    }
	},[])
    useEffect(() => {
        let id = localStorage.getItem("id");
        let isAuthentic = localStorage.getItem("isAuthentic")
        if(id && isAuthentic === "true"){
            let userType = localStorage.getItem("userType")
            if(userType == "1"){
                navigate("/brewer/add-brand")
            }else if(userType == "2"){

            }
        }
    },[])
    

    const handleShow = () =>{
        SetVisible(!visible);
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleValidation = () => {
        let formIsValid = true;
        
        if (!email) {
            formIsValid = false;
            setEmailError(true)
        }
        if (!validateEmail(email)) {
            formIsValid = false;
            setEmailError(true)
        }
        
        
        return formIsValid;
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
       
        if (handleValidation()) {
            setSubmitLoader(true)
            let formData = {
                "emailid": email
                
            }
            await axios.post(API_URL + "ForgotPassword?code=" + API_KEY, formData, config).then((res) => {
               
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage)
                    setEmail("");
                
                }else{
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false);
            })
            
        }
    }
    
    return (
        <>
        {submitLoader?<Loader />:""}
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />
                    <section className="simplyfying Empowering" id="simplys">
                <div className="simplyfyingDiv">
                  <div className="container-fluid">
                    <div className="row upcontent">
                      <div className="col-md-7 manBgimg logform"> 
                        <div className="simplecontentimg"> 
                          <img className="img-responsive" src={LoginLeaves} />  
                        </div>    
                      </div>
                      <div className="col-md-5 manBgimg">
                        <div className="manImg">
                          <img className="img-responsive" src={LoginBGSide} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="moveTopUp loginBAoutphone">
                      <div className="container">
                        <div className="row main-contentprt">
                          <div className="col-md-6">
                            <div className="textSimply loginForms">
                             <div className="loginformdesign">
                                            <h3>Forgot <span>Password</span></h3>
                                            <p>If you've forgotten your password, don't worry. Just enter your email address below and We'll send you a link to reset it.</p>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label for="firstname">Email <span className="red-tick">*</span></label>
                                                            <input type="text" className={emailError ? "form-control error" : "form-control"} name="firstname" placeholder="Email" onChange={handleEmail} value={email} autoComplete="off"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="remeberAndForgot">
                                                            <div className="remebercheck">
                                                                {/* <input className="form-check-input" type="checkbox" value="" id="form1Example3" autocompleted="" onChange={handleRemember}/>
                                                                <label className="form-check-label" for="form1Example3">Remember password</label> */}
                                                            </div>
                                                            <div className="forgotpswd">
                                                                <Link to="/login" className="forgetpass">Go to login?</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12"> 
                                                        <div className="logibtns">
                                                            <button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit} disable={btnDisable}>Submit</button>
                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                            </form>
                                        </div>             
                            </div>
                          </div>
                          <div className="col-md-6">
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>

                </div>
                </section>






                    <Footer />
                </div>
            </div>

        </>
    )
}