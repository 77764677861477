import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import InputMask from 'react-input-mask';
import Select from 'react-select';
import Swal from 'sweetalert2';
import axios from 'axios';
import StripeCard from './stripeCard';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER } from './../../config/constant.js';
import DefaultAvtar from './../../assets/images/avatar-1.jpg'
import moment from "moment"
import { Email } from '@mui/icons-material';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PK } from './../../config/constant.js';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './blob';
const stripePromise = loadStripe(STRIPE_PK)
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: 'transparent',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			border: '1px solid #b6b6af',
			boxShadow: null,
		},
		'&:focus': {
			border: '1px solid transparent',
			boxShadow: null,
		}
	})
};

toast.configure()
export default function EditProfile() {
	document.title = "BevPort - Edit Profile"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const [userData, setUserData] = useState("");
	const [fname, setFname] = useState("");
	const [lname, setLName] = useState("");
	const [mobile, setMobile] = useState("");
	const [mOption, setMoption] = useState("");
	const [email, setEmail] = useState("")
	const [fnameError, setFnameError] = useState(false);
	const [lnameError, setLnameError] = useState(false);
	const [mobileError, setMobileError] = useState(false);
	const [optionalError, setOptionalError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [cEmail, setCemail] = useState("");
	const [cEmailError, setCemailError] = useState(false);


	const [stateList, setStateList] = useState([])
	const [bName, setBname] = useState("");
	const [bnameError, setBnameError] = useState(false);
	const [ein, setEin] = useState("");
	const [stn, setStn] = useState("");
	const [einError, setEinError] = useState(false);
	const [country, setCountry] = useState("1");
	const [countryValue, setCountryValue] = useState("");
	const [contryError, setContryError] = useState(false);
	const [state, setState] = useState("");
	const [stateID, setStateID] = useState("");
	const [stateValue, setStateValue] = useState("");
	const [stateError, setStateError] = useState(false);
	const [city, setCity] = useState("");
	const [cityError, setCityError] = useState(false);
	const [street, setStreet] = useState("");
	const [streetError, setStreetError] = useState(false);
	const [zip, setZip] = useState("");
	const [zipError, setZipError] = useState(false);


	const [bankName, setBankName] = useState("");
	const [bankNameError, setBankNameError] = useState(false);
	const [bankAddress, setBankAddress] = useState("");
	const [bankAddressError, setBankAddressError] = useState(false);
	const [routingNumber, setRoutingNumber] = useState("");
	const [routingNumberError, setRoutingNumberError] = useState(false);
	const [accountNumber, setAccountNumber] = useState("");
	const [accountNumberError, setAccountNumberError] = useState(false);
	const [accountType, setAccountType] = useState("");
	const [accontTypeError, setAccountTypeError] = useState(false);
	const [accountName, setAccountName] = useState("");
	const [accountNameError, setAccountNameError] = useState(false);

	const [planList, setPlanList] = useState([]);
	const [plan, setPlan] = useState("");
	const [planError, setPlanError] = useState(false);
	const [planID, setPlanID] = useState("");
	const [planLabel, setPlanLabel] = useState("");

	const fileInputRef = useRef(null);
	const [isFileInputVisible, setFileInputVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [imageRef, setImageRef] = useState(null);
	const [imageName, setImageName] = useState("");
	const [completeCrop, setCompleteCrop] = useState("");

	const [getApiResp, setGetApiResp] = useState(0);

	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [profilePic, setProfilePic] = useState("");

	const [payBankName, setPayBankName] = useState("")
	const [payBankAddress, setPayBankAddress] = useState("")
	const [payRoutingNumber, setPayRoutingNumber] = useState("")
	const [payAccountNumber, setPayAccountNumber] = useState("")
	const [payAccountType,setPayAccountType] = useState("")
	const [payAccountTypeId,setPayAccountTypeId] = useState("")
	const [payAccountName, setPayAccountName] = useState("")
	const [payBankNameError, setPayBankNameError] = useState(false)
	const [payBankAddressError, setPayBankAddressError] = useState(false)
	const [payRoutingNumberError, setPayRoutingNumberError] = useState(false)
	const [payAccountNumberError, setPayAccountNumberError] = useState(false)
	const [payAccountTypeError,setPayAccountTypeError] = useState(false)
	const [payAccountNameError, setPayAccountNameError] = useState(false)
	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		x: 7,
		y: 7,

	});


	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		let id = localStorage.getItem("id")
		getUserProfileFunction(id)
	}, [])

	useEffect(() => {
		getStateList()
	}, [])

	const accountTypeOption = [
		{ label: "Checking Account", value: "1" },
		{ label: "Saving Account", value: "2" }
	]

	const handleClose = () => {
		setShowModal(false);
	};

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			handleClose();
		}
	};

	const handleEscapeKey = (event) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	};

	useEffect(() => {
		if (showModal) {
			document.addEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [showModal]);

	useEffect(() => {
		if (userData) {
			setFname(userData?.firstName);
			setLName(userData?.lastName);
			setMobile(userData?.businessPhone);
			setMoption(userData?.optionalPhone);
			setEmail(userData?.emailID);
			setBname(userData?.businessName);
			setEin(userData?.ein);
			setStn(userData?.stateTaxNumber);
			setCountryValue(countryOption);
			stateList.forEach((_v, _i) => {
				if (_v.id == userData?.stateID) {
					setStateID(_v?.id)
					setState(_v.value);
					setStateValue(_v)
				}
			})
			setStreet(userData?.streetAddress);
			setZip(userData?.zipCode);
			setCity(userData.city);
			setAccountType({ "value": userData?.accountType, "label": userData?.accountTypeVal });
			setBankName(userData?.bankName);
			setBankAddress(userData?.bankAddress);
			setRoutingNumber(userData?.routingNumber);
			setAccountNumber(userData?.accountNumber);
			setAccountName(userData?.accountName);
			setPlan({ "value": userData?.planID, "label": userData?.planType + "$" + userData?.planAmount })
			setPlanLabel(userData?.planType);

			setPayBankName(userData?.userBankName)
			setPayBankAddress(userData?.userBankAddress)
			setPayRoutingNumber(userData?.bankRoutingNumber)
			setPayAccountNumber(userData?.userAccountNumber)
			setPayAccountType({ "value": userData?.bankAccountType, "label": userData?.bankAccountTypeVal })
			setPayAccountName(userData?.userAccountName)
			setPayAccountTypeId(userData?.bankAccountType)

		}
	}, [userData, stateList])

	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setUserData(data)
				setProfilePic(data?.filePath);
				if (data.filePath != "") {
					setGetApiResp(1);
				}

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const countryOption = [
		{ label: "USA", value: "1" },

	];



	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {

					stateOption.push({ value: da.statename, label: da.statename, id: da.id });

				}
				);

				setStateList(stateOption)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	const handleFname = (e) => {
		setFname(e.target.value);
		setFnameError(false);
	}

	const handleLastName = (e) => {
		setLName(e.target.value);
		setLnameError(false);
	}

	const handleMobile = (e) => {
		setMobile(e.target.value);
		setMobileError(false);
	}

	const handleOptionalMobile = (e) => {
		setMoption(e.target.value);
		setOptionalError(false);
	}

	const handleEmail = (e) => {
		setEmail(e.target.value);
		setEmailError(false);
	}

	const handleConfirmEmail = (e) => {
		setCemail(e.target.value);
		setCemailError(false);
	}

	const handleBussinesName = (e) => {
		setBname(e.target.value);
		setBnameError(false);
	}

	const handleEIN = (e) => {
		setEin(e.target.value);
		setEinError(false);
	}

	const handleSTN = (e) => {
		setStn(e.target.value);

	}

	const handleCountry = (e) => {
		setCountry("1");
		setCountryValue({ label: 'USA', value: '1' });
		setContryError(false);
	}

	const handleState = (e) => {

		setStateID(e.id);
		setState(e.value);
		setStateValue({ value: e.value, label: e.label })
		setStateError(false);
	}

	const handleCity = (e) => {
		setCity(e.target.value);
		setCityError(false);

	}

	const handleStrretAddress = (e) => {
		setStreet(e.target.value);
		setStreetError(false);
	}

	const handleZIP = (e) => {
		setZip(e.target.value);
		setZipError(false);
	}


	const handleBankName = (e) => {
		setBankName(e.target.value);
		setBankNameError(false);
	}


	const handleBankAddress = (e) => {
		setBankAddress(e.target.value);
		setBankAddressError(false);
	}

	const handleRoutingNumber = (e) => {
		setRoutingNumber(e.target.value);
		setRoutingNumberError(false);

	}

	const handleAccountNumber = (e) => {
		setAccountNumber(e.target.value);
		setAccountNumberError(false);
	}

	const handleAccountType = (e) => {
		setAccountType({ value: e.value, label: e.label })
		setAccountTypeError(false);
	}


	const handleAccountName = (e) => {
		setAccountName(e.target.value);
		setAccountNameError(false)
	}

	const handleValidation = () => {
		let formIsValid = true;

		if (!fname) {
			formIsValid = false;
			setFnameError(true)
		}
		if (!lname) {
			formIsValid = false;
			setLnameError(true)
		}
		if (!mobile) {
			formIsValid = false;
			setMobileError(true)
		}

		if (!email) {
			formIsValid = false;
			setEmailError(true);
		}

		if (cEmail == email) {
			if (!cEmail) {
				formIsValid = false;
				setCemailError(true);
			}
			return formIsValid;
		} else {
			if (cEmail != email) {
				formIsValid = false;
				setCemailError(true)
			}
		}


		return formIsValid;
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (handleValidation()) {
			setLoader(true)
			//setBtnDisable(true)
			let formData = {
				"ID": localStorage.getItem("id"),
				"FirstName": fname,
				"LastName": lname,
				"EmailID": email,
				"BusinessPhone": mobile,
				"OptionalPhone": mOption
			}

			await axios.post(API_URL + "UpdateUserDetails?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					getUserProfileFunction(localStorage.getItem("id"));
					
				} else {
					toast.error(res.data.responseMessage);
				}
				setLoader(false)
				//setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setLoader(false)
				//setBtnDisable(false)
			})
		}
	}

	const handleBussinessValidation = () => {
		let formIsValid = true;

		if (!bName) {
			formIsValid = false;
			setBnameError(true)
		}
		if (!ein) {
			formIsValid = false;
			setEinError(true)
		}
		if (!countryValue) {
			formIsValid = false;
			setContryError(true)
		}
		if (!state) {
			formIsValid = false;
			setStateError(true)
		}

		if (!street) {
			formIsValid = false;
			setStreetError(true);
		}
		if (!city) {
			formIsValid = false;
			setCityError(true);
		}
		if (!zip) {
			formIsValid = false;
			setZipError(true)
		}

		if (!mobile) {
			formIsValid = false;
			setMobileError(true)
		}


		return formIsValid;
	}

	const handleBussinessInfo = async (e) => {
		e.preventDefault()
		if (handleBussinessValidation()) {
			setLoader(true)
			//setBtnDisable(true)
			let formData = {
				"ID": localStorage.getItem("id"),
				"Type": "Business",
				"BusinessName": bName,
				"EIN": ein,
				"StateTaxNumber": stn,
				"StreetAddress": street,
				"State": stateID.toString(),
				"Country": "1",
				"City": city,
				"ZipCode": zip

			}

			await axios.post(API_URL + "UpdateUserInformation?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					getUserProfileFunction(localStorage.getItem("id"));
				} else {
					toast.error(res.data.responseMessage);
				}
				setLoader(false)
				//setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setLoader(false)
				//setBtnDisable(false)
			})
		}
	}


	const handleBankDetailsValidation = () => {
		let formIsValid = true;

		if (!bankName) {
			formIsValid = false;
			setBankNameError(true)
		}
		if (!bankAddress) {
			formIsValid = false;
			setBankAddressError(true)
		}
		if (!routingNumber) {
			formIsValid = false;
			setRoutingNumberError(true)
		}
		if (!accountNumber) {
			formIsValid = false;
			setAccountNumberError(true)
		}

		if (!accountType) {
			formIsValid = false;
			setAccountTypeError(true);
		}
		if (!accountName) {
			formIsValid = false;
			setAccountNameError(true);
		}



		return formIsValid;
	}


	const handleBankDetails = async (e) => {
		e.preventDefault()
		if (handleBankDetailsValidation()) {
			//console.log("sdf",);
			setLoader(true)
			//setBtnDisable(true)
			let formData = {
				"ID": localStorage.getItem("id"),
				"Type": "Bank",
				"BankName": bankName,
				"AccountNumber": accountNumber,
				"AccountName": accountName,
				"AccountType": accountType?.value.toString(),
				"RoutingNumber": routingNumber,
				"BankAddress": bankAddress

			}

			await axios.post(API_URL + "UpdateUserInformation?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					getUserProfileFunction(localStorage.getItem("id"));
				} else {
					toast.error(res.data.responseMessage);
				}
				setLoader(false)
				//setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setLoader(false)
				//setBtnDisable(false)
			})
		}
	}

	const getPlanDetails = async () => {
		await axios.post(API_URL + "GetPlans?code=" + API_KEY, { Type: process.env.REACT_APP_ENV }, config).then((res) => {
			if (res.data.responseCode === "200") {
				let resData = res.data.responseData
				let palanOption = [];

				resData.forEach((da) => {


					palanOption.push({ value: da.priceID, label: da.planType + "$" + da.planAmount, plantype: da.planType });

				}
				);
				setPlanList(palanOption)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	useEffect(() => {
		getPlanDetails()
	}, [])


	const handlePlans = (e) => {

		setPlanLabel(e.plantype)
		setPlan({ value: e.value, label: e.label });
		setPlanID(e.value);
		setPlanError(false);
	}

	const handlePlanValidation = () => {
		let formIsValid = true;

		if (!plan) {
			formIsValid = false;
			setPlanError(true)
		}
		return formIsValid;
	}


	const handlePlanDetails = async (e) => {
		e.preventDefault()
		if (handlePlanValidation()) {
			if (userData?.planType != planLabel) {
				Swal.fire({
					title: "Are you sure you want to change the plan?",
					text: "If you change the subscription plan then your current plan will be null and void.",
					html: "",
					icon: 'question',
					showDenyButton: true,
					allowOutsideClick: false,
					confirmButtonColor: '#205380',
					denyButtonColor: '#205380',
					confirmButtonText: 'YES',
					denyButtonText: 'NO',
					background: '#fff',
					color: '#000',
					iconColor: "rgb(255, 153, 0)",
					width: "500px",
					padding: '2em',
					reverseButtons: false
				}).then((result) => {

					if (result.isConfirmed) {
						setLoader(true)
						//setBtnDisable(true)
						let formData = {
							"ID": localStorage.getItem("id"),
							"PlanID": planID
						}

						axios.post(API_URL + "UpdateSubscription?code=" + API_KEY, formData, config).then((res) => {
							if (res.data.responseCode === "200") {
								toast.success(res.data.responseMessage);
							} else {
								toast.error(res.data.responseMessage);
							}
							setLoader(false)
							//setBtnDisable(false)
						}).catch(function (error) {
							console.log(error)
							setLoader(false)
							//setBtnDisable(false)
						})
					} else if (result.isDenied) {
						//setPlan({ "value": userData?.planID, "label": userData?.planType + "$" + userData?.planAmount });
					}
				})
			}

		}
	}

	const closeUploadMode = (e) => {
		e.preventDefault()
		window.$('#myModel2').modal('hide')
	}



	const handleDivClick = () => {
		setFileInputVisible(true);
		//document.getElementById("uploadFileInput").click();
		fileInputRef.current.click()

	};



	const handleFileInputChange = (event) => {
		// Handle file selection here
		const selectedFile = event.target.files[0];
		console.log('Selected file:', selectedFile);
		if (selectedFile) {
			const imageURL = URL.createObjectURL(selectedFile);
			console.log("imageUrl", imageURL);
			//setUploadProfile(selectedFile);
			setImageName(selectedFile?.name)
			setSelectedImage(imageURL);
			window.$('#myModel2').modal('show')
		}

		// After handling the file, hide the file input
		setFileInputVisible(false);
	};

	const CropImg = (e) => {

		setCrop(e);
	}
	const ImgLoad = (e) => {
		setImageRef(e.target);
	}


	const savePofile = async (dataFile) => {
		let da = dataFile;
		console.log("data", da);
		let finalArray = [];
		//da.forEach((_v, _i) => {
		let d = {
			"UserID": localStorage.getItem("id"),
			"BRANDID": "0",
			"PRODUCTID": "0",
			"FILETYPE": "UserPic",
			"FILEPATH": BLOB_URL + BLOB_TEAM_MEMBER_CONTAINER + '/' + da?.name,
			"FILEDATA": "",
			"FILENAME": da?.name,

		}
		finalArray.push(d)
		//})
		//console.log("tee", finalArray);
		if (finalArray.length) {

			// setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob([da], BLOB_TEAM_MEMBER_CONTAINER).then(async res => {
				console.log("resposnse", res);

				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					console.log(res.data.responseMessage)
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}


	const handleCropImage = (e) => {


		if (imageRef && crop.width && crop.height) {

			const canvas = document.createElement('canvas');
			const scaleX = imageRef.naturalWidth / imageRef.width;
			const scaleY = imageRef.naturalHeight / imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(
				imageRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);

			// const croppedImageUrl = canvas.toDataURL('image/jpeg', 'image/png');
			// setEditPriview(croppedImageUrl);
			// console.log("imageCrop", croppedImageUrl)
			//window.$('#myModel2').modal('hide');
			canvas.toBlob(
				(blob) => {
					const file = new File([blob], imageName, {
						type: "image/png",
						//preview: URL.createObjectURL(File),
						lastModified: Date.now(),
					});
					setGetApiResp(0);
					//setUploadProfile(file);
					console.log("file", file)
					setSelectedImage(URL.createObjectURL(file))
					setCompleteCrop(URL.createObjectURL(file))
					setProfilePic(URL.createObjectURL(file))
					savePofile(file);
					//setUploadProfile(file)
					window.$('#myModel2').modal('hide');
				}

			)
		}

	}

	const handlePayBankName = (e) => {
		setPayBankNameError(false)
		setPayBankName(e.target.value)
	}
	const handlePayBankAddress = (e) => {
		setPayBankAddress(e.target.value)
		setPayBankAddressError(false)
	}
	const handlePayRoutingNumber = (e) => {
		setPayRoutingNumber(e.target.value)
		setPayRoutingNumberError(false)
	}
	const handlePayAccountNumber = (e) => {
		setPayAccountNumber(e.target.value)
		setPayAccountNumberError(false)
	}
	const handlePayAccountType = (e) => {
		setPayAccountType(e)
		setPayAccountTypeId(e.value)
		setPayAccountTypeError(false)
	}
	const handlePayAccountName = (e) => {
		setPayAccountName(e.target.value)
		setPayAccountNameError(false)
	}
	const payHandelValidation = () => {
		let isFormValid = true
		if(!payBankName){
			isFormValid = false
			setPayBankNameError(true)
		}
		if(!payBankAddress){
			isFormValid = false
			setPayBankAddressError(true)
		}
		if(!payRoutingNumber){
			isFormValid = false
			setPayRoutingNumberError(true)
		}
		if(!payAccountNumber){
			isFormValid = false
			setPayAccountNumberError(true)
		}
		if(!payAccountType){
			isFormValid = false
			setPayAccountTypeError(true)
		}
		if(!payAccountName){
			isFormValid = false
			setPayAccountNameError(true)
		}
		return isFormValid;
	}
	const handlePaymnetBankDetails = async (e) => {
		e.preventDefault();
		if(payHandelValidation()){
			setLoader(true)
			let formData = {
				"ID": localStorage.getItem("id"),
			    "UserBankName": payBankName,
			    "UserAccountNumber": payAccountNumber,
			    "BankRoutingNumber": payRoutingNumber,
			    "BankAccountType": payAccountTypeId,
			    "UserBankAddress": payBankAddress,
			    "UserAccountName": payAccountName
			}
			
			await axios.post(API_URL + "UpdateBankInfo?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					getUserProfileFunction(localStorage.getItem("id"));
					
				} else {
					toast.error(res.data.responseMessage);
				}
				setLoader(false)
			}).catch(function (error) {
				setLoader(false)
			})
		}
	}
	return (
		<>

			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} profilePic={profilePic}/>
					<div className="rightContetBlock">
						<div className="headingTop">

						</div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-2 ImgPart" >
										<div className="profileImages editoptions" onClick={handleDivClick}>
											{getApiResp ? (<img src={userData.filePath} alt="Selected" />) : completeCrop ? (
												<img src={completeCrop} alt="Selected" />
											) : (
												<img src={DefaultAvtar} />
											)}


											<div className="clickable-div"></div>

											<input type='file' className='upload-input' id="uploadFileInput" ref={fileInputRef} onClick={e => (e.target.value = null)} onChange={handleFileInputChange} style={{ display: isFileInputVisible ? 'block' : 'none' }} accept="image/*" />

										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>
											</div>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="addProfilePopup editpicture">
							<div className={`modal fade ${showModal ? 'show' : ''}`} id="myModel2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false" >
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" onClick={closeUploadMode}><span aria-hidden="true">&times;</span></button>
											<h4 className="modal-title" id="myModalLabel">Profile Upload</h4>
										</div>
										<div className="modal-body">
											<div className="row">
												<div className="col-md-12">
													{/* <div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} accept="image/*" />

																</div> */}
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														{/* <ReactCrop src={editPriview} onImageLoaded={setImage}
																		crop={crop} onChange={CropImg} />
																	<img src={editPriview} key="0" alt={"Image"} /> */}
														<ReactCrop crop={crop} aspect={1 / 1} onChange={CropImg} onImageLoaded={ImgLoad}>
															<img src={selectedImage} onLoad={ImgLoad} />
														</ReactCrop>
														<div className='checkbtnsse'>
															<button className='btn btn-primary save-button' onClick={handleCropImage}>Crop</button>
														</div>
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Contact Details</a></li>
										<li><a data-toggle="tab" href="#menu1">Business Details</a></li>
										<li><a data-toggle="tab" href="#menu2">Bank Details</a></li>
										<li><a data-toggle="tab" href="#menu5">Subscription Details</a></li>
										{
											userData?.userAccountName?<li><a data-toggle="tab" href="#menu7">Payment Method</a></li>:<li><a data-toggle="tab" href="#menu6">Payment Method</a></li>
										}
									</ul>
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">First Name<span className="red-tick"> *</span></label>
															<input type="text" className={fnameError ? "form-control error" : "form-control"} placeholder="First Name" value={fname} onChange={handleFname} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Last Name<span className="red-tick"> *</span></label>
															<input type="text" className={lnameError ? "form-control error" : "form-control"} placeholder="Last Name" value={lname} onChange={handleLastName} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Mobile<span className="red-tick"> *</span></label>
															{/* <input type="text" className={mobileError ? "form-control error" : "form-control"} placeholder="Enter Mobile Number" value={mobile} onChange={handleMobile} /> */}
															<InputMask
																mask='(999) 999 9999'
																value={mobile}
																onChange={handleMobile}
																className={mobileError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Mobile Number"
															>
															</InputMask>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Mobile (Optional)</label>
															{/* <input type="text" className={optionalError ? "form-control error" : "form-control"} placeholder="Enter Mobile (Optional)" value={mOption} onChange={handleOptionalMobile} /> */}
															<InputMask
																mask='(999) 999 9999'
																value={mOption}
																onChange={handleOptionalMobile}
																className="form-control"
																maskChar={null}
																placeholder="Alternative Mobile Number"
															>
															</InputMask>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Email<span className="red-tick"> *</span></label>
															<input type="text" className={emailError ? "form-control error" : "form-control"} placeholder="Enter Email Address" value={email} onChange={handleEmail} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Confirm Email<span className="red-tick"> *</span></label>
															<input type="text" className={cEmailError ? "form-control error" : "form-control"} placeholder="Confirm Email" value={cEmail} onChange={handleConfirmEmail} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
														</div>
													</div>
												</div>

											</div>
										</div>
										<div id="menu1" className="tab-pane fade">

											<div className="row">
												<div className="col-md-12">

													<label style={{ fontSize: "20px", paddingTop: "10px" }}>To make any changes please contact <a>adminbevport@bevport.com</a></label>
												</div>
											</div>

											{/*// Jesse Dudley december 1 2023: busines details  form is hidden, becuause only admin can update business details  */}


											<div className="InsertForms hidden">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Business Name<span className="red-tick"> *</span></label>
															<input type="text" className={bnameError ? "form-control error" : "form-control"} placeholder="Business Name" value={bName} onChange={handleBussinesName} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Employer Identification Number<span className="red-tick"> *</span></label>
															<InputMask
											
																mask='99-9999999' 
																value={ein}
																onChange={handleEIN}
																className={einError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="EIN"
															>
															</InputMask>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">State Tax Number</label>
															<InputMask
																mask='999-99-9999'
																value={stn}
																onChange={handleSTN}
																className="form-control"
																maskChar={null}
																placeholder="If Different From EIN"
															>
															</InputMask>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Country<span className="red-tick"> *</span></label>
															<Select className={contryError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={countryOption}
																onChange={handleCountry}
																value={countryValue}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Country</div>}
																isSearchable
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">State<span className="red-tick"> *</span></label>
															<Select className={stateError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={stateList}
																onChange={handleState}
																value={stateValue}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">State</div>}
																isSearchable
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">City<span className="red-tick"> *</span></label>
															<input type="text" className={cityError ? "form-control error" : "form-control"} placeholder="City" value={city} onChange={handleCity} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Street Address<span className="red-tick"> *</span></label>
															<input type="text" className={streetError ? "form-control error" : "form-control"} placeholder="Street Address" value={street} onChange={handleStrretAddress} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">ZIP Code <span className="red-tick">*</span></label>
															<InputMask
																mask='99999'
																value={zip}
																onChange={handleZIP}
																className={zipError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="ZIP Code"
															>
															</InputMask>
														</div>
													</div>
												</div>
												<div className="row">
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handleBussinessInfo} >Save</button>
														</div>
													</div>
												</div>

											</div>
										</div>
										<div id="menu2" className="tab-pane fade">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Bank Name<span className="red-tick"> *</span></label>
															<input type="text" className={bankNameError ? "form-control error" : "form-control"} placeholder="Bank Name" value={bankName} onChange={handleBankName} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Bank Address<span className="red-tick"> *</span></label>
															<input type="text" className={bankAddressError ? "form-control error" : "form-control"} placeholder="Bank Address" value={bankAddress} onChange={handleBankAddress} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Routing Number<span className="red-tick"> *</span></label>
															<InputMask
																mask='999999999'
																value={routingNumber}
																onChange={handleRoutingNumber}
																className={routingNumberError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Routing Number"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Account Number<span className="red-tick"> *</span></label>
															<InputMask
																mask='999999999'
																value={accountNumber}
																onChange={handleAccountNumber}
																className={accountNumberError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Account Number"
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Bank Account Type<span className="red-tick"> *</span></label>
															<Select className={accontTypeError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={accountTypeOption}
																onChange={handleAccountType}
																value={accountType}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Bank Account Type</div>}
																isSearchable
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Name on Account<span className="red-tick"> *</span></label>
															<input type="text" className={accountNameError ? "form-control error" : "form-control"} placeholder="Name on Account" value={accountName} onChange={handleAccountName} />
														</div>
													</div>
												</div>


												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handleBankDetails} >Save</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="menu5" className="tab-pane fade">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Plan<span className="red-tick"> *</span></label>
															<Select className={planError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																//menuPlacement="top"
																options={planList}
																onChange={handlePlans}
																value={plan}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Select Plans</div>}
																isSearchable
															/>
														</div>
													</div>

													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handlePlanDetails} >Save</button>
														</div>
													</div>


												</div>



											</div>
										</div>
										<div id="menu6" className="tab-pane fade">
											<div className="InsertForms">

												<Elements stripe={stripePromise}>
													<StripeCard />
												</Elements>


											</div>
										</div>
										<div id="menu7" className="tab-pane fade">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Bank Name<span className="red-tick"> *</span></label>
															<input type="text" className={payBankNameError ? "form-control error" : "form-control"} placeholder="Bank Name" value={payBankName} onChange={handlePayBankName} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Bank Address<span className="red-tick"> *</span></label>
															<input type="text" className={payBankAddressError ? "form-control error" : "form-control"} placeholder="Bank Address" value={payBankAddress} onChange={handlePayBankAddress} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Routing Number<span className="red-tick"> *</span></label>
															<InputMask
																mask='999999999'
																value={payRoutingNumber}
																onChange={handlePayRoutingNumber}
																className={payRoutingNumberError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Routing Number"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Account Number<span className="red-tick"> *</span></label>
															<InputMask
																mask='999999999'
																value={payAccountNumber}
																onChange={handlePayAccountNumber}
																className={payAccountNumberError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Account Number"
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Bank Account Type<span className="red-tick"> *</span></label>
															<Select className={payAccountTypeError ? "selectTodesign error" : "selectTodesign"}
																styles={customStyles}
																options={accountTypeOption}
																onChange={handlePayAccountType}
																value={payAccountType}
																closeMenuOnSelect={true}
																placeholder={<div className="italic-placeholder">Bank Account Type</div>}
																isSearchable
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Name on Account<span className="red-tick"> *</span></label>
															<input type="text" className={payAccountNameError ? "form-control error" : "form-control"} placeholder="Name on Account" value={payAccountName} onChange={handlePayAccountName} />
														</div>
													</div>
												</div>


												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handlePaymnetBankDetails} >Save</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}