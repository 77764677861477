

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import homecnavs from "./../../assets/images/homecnavs.png"
import themainman from "./../../assets/images/themainman.jpg"
import iframe_cover from "./../../assets/images/iframe_cover.png"
import simply from "./../../assets/images/simply.png"
import buyersbg from "./../../assets/images/buyersbg.png"
import brewes from "./../../assets/images/brewes.jpg"
import buyers from "./../../assets/images/buyers.jpg"
import fastrackbg from "./../../assets/images/fastrackbg.jpg"
import useric  from "./../../assets/images/user-ic.png"
import truckic  from "./../../assets/images/truck-ic.png"
import globeic  from "./../../assets/images/globe-ic.png"
import shakehandic  from "./../../assets/images/shakehand-ic.png"
import foodies from "./../../assets/images/foodies.jpg"
import testimo from "./../../assets/images/testimo.png"
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Header from './Header';
import Footer from './Footer';
export default function Index(){
	document.title = "BevPort - Home"
	useEffect(() => {
	  	window.scrollTo(0, 0)
	  	const script = document.getElementById('recaptcha-key');
	    if (script) {
	      	script.remove();
	    }
	    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
	    if (recaptchaElems.length) {
	      	recaptchaElems[0].remove();
	    }
	}, [])


	const CostComparisonLink = () => {
	
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/CostComparison";

		window.location.href = profileLink;
	}

	return(
		<>
			<div className="site-wrapper">
        		<div className="main-wrapper">
			<Header />
			<section className="simplyfying">
				<div className="simplyfyingDiv">
				  <div className="container-fluid">
				    <div className="row upcontent">
				      <div className="col-md-5 simplecontent"> 
				        <div className="simplecontentimg"> 
				          <img className="img-responsive" src={homecnavs} />  
				        </div>    
				      </div>
				      <div className="col-md-7 manBgimg">
				        <div className="manImg">
				          <img className="img-responsive" src={themainman} />
				        </div>
				      </div>
				    </div>
				    <div className="row homePageToops">
				    <div className="moveTopUp">
				      <div className="container">
				        <div className="row main-contentprt">
				          <div className="col-md-4">
				            <div className="textSimply">				         
				              <h1><span className="browntext">Simplifying</span>Distribution</h1>
							   <p style={{fontSize: "24px"}} className="brownhite">At BevPort, we are dedicated to making distribution easier for those who are ready. BevPort will make selling beer & wine faster, easier, and less expensive; putting more control back into the hands of the brewers. </p>
				            </div>
				          </div>
				          <div className="col-md-8">
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>

				</div>
				</section>
				<section className="joinnew">
				<div className="newdistribution">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
						<h1>Join the New Distribution Revolution!</h1>
						<h2 style={{ color: "white" } }>“How BevPort Works”</h2>
										
				      </div>
				    </div>
				    <div className="row videorows">
				      <div className="col-md-6 col-sm-12">
				          <div className="video-container">
				          	<video width="100%" poster={iframe_cover} controls autoPlay={true} muted playsInline >
							    <source src="https://bevportfunctions20230303.blob.core.windows.net/video/bevport.mp4" type="video/webm" />
							    <source src="https://bevportfunctions20230303.blob.core.windows.net/video/bevport.mp4" type="video/mp4" />
							    Sorry, your browser doesn't support videos.
							</video>
				          </div>
				      </div>
				      <div className="col-md-6 col-sm-12">
										<div className="videoside">
											<p>Become part of BevPort’s transformative platform revolutionizing the beverage industry by streamlining distribution, optimizing inventory, and connecting suppliers with retailers for unparalleled operational efficiency and outstanding customer experiences.</p>
										</div>

										<div >
											<button onClick={CostComparisonLink}  className="costComparisonBtn" >“See how BevPort’s Pricing Compares to a Traditional Distributor”</button>
										</div>                
				        <div className="buttonbrownTwo">
				          <Link to="/brewers#joinew">FOR BREWERS</Link>
				          <Link to="/buyers#revolutionize">FOR BUYERS</Link>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				</section>

				<section className="brewersandByers">
				<div className="BrewersbyCompound">
				  <div className="container-fluid">
				    <div className="row hidethis">
				      <div className="col-md-6 brewersPart"> 
				        <div className="brewersBg"> 
				          <img className="img-responsive" src={simply} />  
				        </div>    
				      </div>
				      <div className="col-md-6 buyersPart">
				        <div className="buyerbg">
				          <img className="img-responsive" src={buyersbg} />
				        </div>
				      </div>
				    </div>
				    <div className="row showthis">
				    <div className="brewerBuyersSection">
				      <div className="container">
				        <div className="row">
				          <div className="col-md-6 brewersbake">
				            <div className="commonfeatures brewerText">
				              <div className="brewersimg">
				                <img className="img-responsive" src={brewes} />
				              </div>
				              <h1>Brewers</h1>
				              <p className="brewerPara"><span>GOT DISTRIBUTION?</span> BevPort offers an unparalleled advantage as the fastest and most cost-effective route to market with <span>PLANS STARTING AS LOW AS $29.99/MO</span>. Maximize your efficiency and minimize expenses with BevPort.</p>
				              <div className="learnMoreBtn">
				                <Link to="/brewers">Learn More</Link>
				              </div>
				            </div>
				          </div>
				          <div className="col-md-6 byersbake">
				            <div className="commonfeatures buyersText">
				              <div className="brewersimg">
				                <img className="img-responsive" src={buyers} />
				              </div>
				              <h1>Buyers</h1>
				              <p className="brewerPara"><span>CRAFT DELIGHTS!</span> Are you searching for exceptional craft beers to delight your customers? Explore and procure an incredible selection of top-notch craft brands. Elevate your offerings with the finest brews available.</p>
				              <div className="learnMoreBtn">
				                <Link to="/buyers">Learn More</Link>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>
				</div>
				</section>

				<section className="fast-track">
				<div className="yourConnections">
				  <div className="container-fluid">
				    <div className="row keepimg">
				      <div className="col-md-6 fast-trackbgs"> 
				        <div className="fast-trackbgsimg"> 
				          <img className="img-responsive" src={fastrackbg} />  
				        </div>    
				      </div>
				      <div className="col-md-6 fast-trackbgContent">
				        <div className="fast-trackbgContetpart">          
				        </div>
				      </div>
				    </div>
				    <div className="connectionswithRstaurant">
				      <div className="container">
				        <div className="row keepcontent">
				          <div className="col-md-6 order2">             
				            <div className="iconsText">
				              <div className="iconsTextCombo">
				                <div className="setIcon">
				                  <img src={useric} />
				                </div>
				                <h3>Who are we?</h3>
				                <p>We’re a dedicated team of Beverage Industry professionals looking to give Brewers a chance to have their brands seen.</p>
				              </div>
				          
				            
				              <div className="iconsTextCombo">
				                <div className="setIcon">
				                  <img src={truckic} />
				                </div>
				                <h3>What do we do?</h3>
				                <p>BevPort gives Beer Brands an opportunity to access distribution immediately, easily.</p>
				              </div>
				            
				            
				              <div className="iconsTextCombo">
				                <div className="setIcon">
				                  <img src={globeic} />
				                </div>
				                <h3>How do we do it?</h3>
				                <p>We leverage the internet’s power to innovate and optimize future distribution.</p>
				              </div>
				            
				            
				              <div className="iconsTextCombo">
				                <div className="setIcon">
				                  <img src={shakehandic} />
				                </div>
				                <h3>Who we work with</h3>
				                <p>Top industry partners like Country Malt Group, providing warehousing and logistics.</p>
				              </div>
				            
				              </div>
				          </div>
				          <div className="col-md-6 order1">
				            <div className="fasttrackContents">
				              <h1>Fast-track Your Connections with Restaurants, Bars, and Retailers</h1>
				              <p>Join BevPort to effortlessly connect with a wide network of restaurants, bars, and retailers. Our platform empowers you to
				              establish meaningful partnerships and seize new opportunities for growth in record time. Say goodbye to the traditional hurdles of building connections and embrace the efficiency of BevPort.</p>
				              <ul>
				                <li>Innovate new brands</li>
				                <li>Own account relationships</li>
				                <li>Access new markets</li>
				                <li>Fill any distribution gaps</li>
				              </ul>
				              <div className="learnMoreBtn">
				                <Link to="#">Learn More</Link>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				</section>

				<section className="distribution">
				<div className="distributionEmpowers">
				  <div className="container-fluid">
				    <div className="row">
				      <div className="col-md-6 distributionEmpowerstext"> 

				      </div>
				      <div className="col-md-6 foodDrinkbg">
				        <div className="foodiesimg">
				              <img src={foodies} />
				            </div>
				      </div>
				    </div>
				    <div className="connectionswithRstaurant">
				      <div className="container">
				        <div className="row">
				          <div className="col-md-6">
				            <div className="distributionEmpowersBg"> 
				            <h1>Distribution that will Empowers Your Brand</h1>
				            <p><span>NUMBERS DON’T LIE.</span> Our approach to distribution is based on tangible results. We empower every aspect of your distribution process, enabling you to make a significant impact on your bottom line.</p>
				            <div className="numericvalues">
				              <div className="numbers">
				                <h3>13.5K+</h3>
				                <span>BEER BRANDS INSIDE THE USA</span>
				              </div>
				              <div className="numbers">
				                <h3>600K+</h3>
				                <span>TOTAL RETAILERS IN THE USA</span>
				              </div>
				            </div>
				        </div>
				          </div>
				          <div className="col-md-6">
				            
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				</section>

				<section className="testimonialSection">
				<div className="testimonialContent">
				  <div className="container">
				  <div className="row">
				    <div className="col-sm-12">
				      <h3 className="raveheading">Rave Reviews</h3>
				            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" interval="false">
				              
				              <div className="carousel-inner">
				              <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
				              	<div className="item active">                  
				                    <div className="row">
				                    <div className="col-sm-2">
				                        <img src={testimo} className="img-responsive" style={{"width": "150px"}} />
				                        </div>
				                        <div className="col-sm-10">
				                          <p className="testimonial_para">"Innovation brands. Emerging brands. Any brand in need of distribution. Brands expanding to new markets. Anyone who loves authentic products."</p>
				                        <h4><strong>-Jack Anderson, Customer</strong></h4>
				                    </div>
				                    </div>                  
				                </div>
				                <div className="item"> 
				                    <div className="row">
				                    <div className="col-sm-2">
				                        <img src={testimo} className="img-responsive" style={{"width": "150px"}} />
				                        </div>
				                        <div className="col-sm-10">
				                          <p className="testimonial_para">"Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo-Text."</p>
				                        <h4><strong>-Kiara Andreson</strong></h4>                        
				                    </div>
				                    </div>
				                  
				                </div>
				              </Carousel>
				                
				               	
				              </div>
				            </div>
				            
				        </div>
				  </div>
				  </div>
				</div>

				</section>
			<Footer />
			</div>
			</div>
		</>
	)
}