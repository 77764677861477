import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER } from './../../config/constant.js';
import DefaultAvtar from './../../assets/images/avatar-1.jpg';
import UplaodDocIcon from './../../assets/images/uploaddoc.png';
import Select from 'react-select';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './blob';

import moment from "moment"
import { click } from '@testing-library/user-event/dist/click';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: 'transparent',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			border: '1px solid #b6b6af',
			boxShadow: null,
		},
		'&:focus': {
			border: '1px solid transparent',
			boxShadow: null,
		}
	})
};
export default function Profile() {
	document.title = "BevPort - Profile"
	const fileInputRef = useRef();
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [userData, setUserData] = useState("");

	const [cardData, setCardData] = useState("");
	const [monthLength, setMonthLength] = useState("");

	const [isFileInputVisible, setFileInputVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [imageRef, setImageRef] = useState(null);
	const [imageName, setImageName] = useState("");
	const [completeCrop, setCompleteCrop] = useState("");

	const [getApiResp, setGetApiResp] = useState(0);
	const [profilePic, setProfilePic] = useState("");


	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const userType = localStorage.getItem("userType");

	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		x: 7,
		y: 7,

	});



	const handleClose = () => {
		setShowModal(false);
	};

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			handleClose();
		}
	};

	const handleEscapeKey = (event) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	};

	useEffect(() => {
		if (showModal) {
			document.addEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [showModal]);



	const collapseSidebar = () => {
		setToggle(!toggle)
	}

	const adminID = localStorage.getItem("adminID")
	useEffect(() => {
		let id = localStorage.getItem("id")

		if (userType == "4") {
			getTeamInfo(adminID)
			getUserProfileFunction(adminID);
		} else {
			getUserProfileFunction(id);
			getCardDetailsFunction(id);
		}

	}, [])
	useEffect(() => {
		if (cardData) {
			setMonthLength(getlength(cardData?.expiryMonth));
		}
	})
	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				console.log(data)
				
				if(localStorage.getItem("userType") !== "4"){
					setProfilePic(data?.filePath);
					setUserData(data)
				}
				
				if (data.filePath != "") {
					setGetApiResp(1);
				}

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getTeamInfo = async (userID) => {
		await axios.post(API_URL + "GetMemberInfo?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				console.log(data)
				if(userType == 4){
					setUserData(data)
				setProfilePic(data?.filePath);
				}
				
				if (data.filePath != "") {
					setGetApiResp(1);
				}

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	const getCardDetailsFunction = async (userID) => {
		await axios.post(API_URL + "GetCardInfo?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setCardData(data);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getlength = (number) => {
		return number.toString().length;
	}

	useEffect(() => {
		getStateList();
	}, [])

	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {

					stateOption.push({ value: da.statename, label: da.statename, id: da.id });

				}
				);
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	const closeUploadMode = (e) => {
		e.preventDefault()
		window.$('#myModel2').modal('hide')
	}

	const handleDivClick = () => {
		setFileInputVisible(true);
		//document.getElementById("uploadFileInput").click();
		fileInputRef.current.click()

	};



	const handleFileInputChange = (event) => {

		let selectedFile = event.target.files[0];
		console.log('Selected file:', selectedFile);
		if (selectedFile) {

			const imageURL = URL.createObjectURL(selectedFile);
			setImageName(selectedFile?.name)
			setSelectedImage(imageURL);
			window.$('#myModel2').modal('show')


		}


		// After handling the file, hide the file input
		setFileInputVisible(false);
	};

	const CropImg = (e) => {

		setCrop(e);
	}
	const ImgLoad = (e) => {
		setImageRef(e.target);
	}


	const savePofile = async (dataFile) => {
		let da = dataFile;
		let finalArray = [];
		//da.forEach((_v, _i) => {
		let d = {
			"UserID": (localStorage.getItem("userType") == "4") ? adminID : localStorage.getItem("id"),
			"BRANDID": "0",
			"PRODUCTID": "0",
			"FILETYPE": "UserPic",
			"FILEPATH": BLOB_URL + BLOB_TEAM_MEMBER_CONTAINER + '/' + da?.name,
			"FILEDATA": "",
			"FILENAME": da?.name,

		}
		finalArray.push(d)
		//})

		if (finalArray.length) {

			// setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob([da], BLOB_TEAM_MEMBER_CONTAINER).then(async res => {
				console.log("resposnse", res);
				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					console.log(res.data.responseMessage)
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}


	const handleCropImage = (e) => {


		if (imageRef && crop.width && crop.height) {

			const canvas = document.createElement('canvas');
			const scaleX = imageRef.naturalWidth / imageRef.width;
			const scaleY = imageRef.naturalHeight / imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(
				imageRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);

			canvas.toBlob(
				(blob) => {
					const file = new File([blob], imageName, {
						type: "image/png",
						//preview: URL.createObjectURL(File),
						lastModified: Date.now(),
					});
					setGetApiResp(0);
					//setUploadProfile(file);
					console.log("file", file)
					setSelectedImage(URL.createObjectURL(file))
					setCompleteCrop(URL.createObjectURL(file))
					setProfilePic(URL.createObjectURL(file));
					savePofile(file);
					//setUploadProfile(file)
					window.$('#myModel2').modal('hide');
				}

			)
		}

	}


	return (
		<>

			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} profilePic={profilePic} />
					<div className="rightContetBlock">
						<div className="headingTop">

						</div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-2 ImgPart" onClick={handleDivClick} >
										<div className="profileImages editoptions">
											{getApiResp ? (<img src={userData.filePath} alt="Selected" />) : completeCrop ? (
												<img src={completeCrop} alt="Selected" />
											) : (
												<img src={DefaultAvtar} />
											)}


											<div className="clickable-div"></div>
											<input type='file' className='upload-input' id="uploadFileInput" ref={fileInputRef} onClick={e => (e.target.value = null)} onChange={(e) => { handleFileInputChange(e) }} style={{ display: isFileInputVisible ? 'block' : 'none' }} accept="image/*"  disabled={(userType==4)?true:false}/>
										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												{(localStorage.getItem("userType") == "4")?"":<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>}
											</div>
											<div className="profileIcons">
												{(localStorage.getItem("userType") == "4")?"":<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>}
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="editUserProfile">
											{(localStorage.getItem("userType") == "4") ? "" : <Link to="/brewer/edit-profile" className="btn btn-primary save-button">Edit Profile</Link>}
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="addProfilePopup editpicture">
							<div className={`modal fade ${showModal ? 'show' : ''}`} id="myModel2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false"  >
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" onClick={closeUploadMode}><span aria-hidden="true">&times;</span></button>
											<h4 className="modal-title" id="myModalLabel">Profile Upload</h4>
										</div>
										<div className="modal-body">
											<div className="row">
												<div className="col-md-12">
													{/* <div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} accept="image/*" />

																</div> */}
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														{/* <ReactCrop src={editPriview} onImageLoaded={setImage}
																		crop={crop} onChange={CropImg} />
																	<img src={editPriview} key="0" alt={"Image"} /> */}
														<ReactCrop crop={crop} aspect={1 / 1} onChange={CropImg} onImageLoaded={ImgLoad} style={{ border: '1px solid #ccc' }}>
															<img src={selectedImage} onLoad={ImgLoad} />
														</ReactCrop>
														<div className='checkbtnsse'>
															<button className='btn btn-primary save-button' onClick={handleCropImage}>Crop</button>
														</div>
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Contact Details</a></li>
										{(localStorage.getItem("userType") == "4") ? "" : <li><a data-toggle="tab" href="#menu1">Business Details</a></li>}
										{(localStorage.getItem("userType") == "4") ? "" : <li><a data-toggle="tab" href="#menu2">Bank Details</a></li>}
										{(localStorage.getItem("userType") == "4") ? "" : <li><a data-toggle="tab" href="#menu5">Subscription Details</a></li>}
										{
											userData?.userAccountName ? <li><a data-toggle="tab" href="#menu7">Payment Method</a></li> : (localStorage.getItem("userType") == "4") ? "" : <li><a data-toggle="tab" href="#menu6">Payment Method</a></li>
										}

									</ul>
									
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Full Name</label>
														<span className="ValueEntites">{userData?.firstName} {userData?.lastName}</span>
													</div>
													{(userType == 4)?"":<div className="comPundEntites">
														<label>Mobile</label>
														<span className="ValueEntites">{userData?.businessPhone}</span>
													</div>}
													{(userType == 4)?"":<div className="comPundEntites">
														<label>Mobile (Optional)</label>
														<span className="ValueEntites">{userData?.optionalPhone}</span>
													</div>}
													<div className="comPundEntites">
														<label>E-mail</label>
														<span className="ValueEntites">{userData?.emailID}</span>
													</div>

												</div>
											</div>
										</div>
										<div id="menu1" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Business Name</label>
														<span className="ValueEntites">{userData?.businessName}</span>
													</div>
													<div className="comPundEntites">
														<label>EIN</label>
														<span className="ValueEntites">{userData?.ein}</span>
													</div>
													<div className="comPundEntites">
														<label>State Tax Number</label>
														<span className="ValueEntites">{userData?.stateTaxNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Address</label>
														<span className="ValueEntites">{userData?.streetAddress}, {userData?.city}, {userData?.stateName}, {userData?.zipCode}, USA</span>
													</div>
												</div>
											</div>
										</div>
										<div id="menu2" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Bank Name</label>
														<span className="ValueEntites">{userData?.bankName}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Address</label>
														<span className="ValueEntites">{userData?.bankAddress}</span>
													</div>
													<div className="comPundEntites">
														<label>Routing Number</label>
														<span className="ValueEntites">{userData?.routingNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Account Number</label>
														<span className="ValueEntites">{userData?.accountNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Account Type</label>
														<span className="ValueEntites">{(userData?.accountType == 1) ? "Checking Account" : "Saving Account"}</span>
													</div>
													<div className="comPundEntites">
														<label>Name on Account</label>
														<span className="ValueEntites">{userData?.accountName}</span>
													</div>
												</div>
											</div>
										</div>
										<div id="menu5" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Plan</label>
														<span className="ValueEntites">{userData?.planType}</span>
													</div>
													<div className="comPundEntites">
														<label>Amount</label>
														<span className="ValueEntites">${userData?.planAmount}</span>
													</div>
													<div className="comPundEntites">
														<label>Start Date</label>
														<span className="ValueEntites">{(userData?.startDate) ? moment(userData?.startDate).format("MM-DD-YYYY hh:mm A") : "NA"}</span>
													</div>
													<div className="comPundEntites">
														<label>Renewal Date</label>
														<span className="ValueEntites">{(userData?.startDate) ? moment(userData?.endDate).format("MM-DD-YYYY hh:mm A") : "NA"}</span>
													</div>
												</div>
											</div>
										</div>

										<div id="menu6" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Card Type</label>
														<span className="ValueEntites">{(cardData?.brandName)}</span>
													</div>
													<div className="comPundEntites">
														<label>Card Number</label>
														<span className="ValueEntites">{"**** **** ****" + (cardData?.cardNumber)}</span>
													</div>
													<div className="comPundEntites">
														<label>Expiry Date</label>
														{console.log("first", cardData.expiryYear)}
														<span className="ValueEntites">{((monthLength == 2) ? cardData?.expiryMonth : "0" + cardData?.expiryMonth) + "/" + cardData.expiryYear}</span>
														<span className="ValueEntites"></span>
													</div>
												</div>
											</div>
										</div>
										{/* <div id="menu7" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Bank Name</label>
														<span className="ValueEntites">{userData?.userBankName}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Address</label>
														<span className="ValueEntites">{userData?.userBankAddress}</span>
													</div>
													<div className="comPundEntites">
														<label>Routing Number</label>
														<span className="ValueEntites">{userData?.bankRoutingNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Account Number</label>
														<span className="ValueEntites">{userData?.userAccountNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Account Type</label>
														<span className="ValueEntites">{(userData?.bankAccountType == 1) ? "Checking Account" : "Saving Account"}</span>
													</div>
													<div className="comPundEntites">
														<label>Name on Account</label>
														<span className="ValueEntites">{userData?.userAccountName}</span>
													</div>
												</div>
											</div>
										</div> */}

									</div>
								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}