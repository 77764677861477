import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import Select from 'react-select';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
	document.title = "BevPort - Delivery List"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [activeTab, setActiveTab] = useState("all")
	const [loader, setLoader] = useState(false)



	const collapseSidebar = () => {
		setToggle(!toggle)
	}

	useEffect(() => {
		getDeliveryListData()

	}, [])
	// const finalQuantity = (data) => {


	// 	let finalQty = 0
	// 	let addedData = 0;
	// 	let soldData = 0;
	// 	let damagedData = 0;
	// 	data?.inventoryData.forEach((v, i) => {

	// 		if (v?.inventoryType === "Added") {
	// 			addedData += v?.quantity
	// 		}
	// 		if (v?.inventoryType === "Sold") {
	// 			soldData += v?.quantity
	// 		}
	// 		if (v?.inventoryType === "Damaged") {
	// 			damagedData += v?.quantity
	// 		}
	// 		finalQty = addedData - (soldData + damagedData)
	// 	})
	// 	return finalQty;
	// }

	const handleDelete = async (id) => {
		let formData = {
			"ID": id
		}
		setLoader(true);
		await axios.post(API_URL + "DeleteDeliveryData?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				toast.success(res.data.responseMessage, {
					autoClose: 3000, //3 seconds
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,

				});

				getDeliveryListData();
				setLoader(false);
			} else {
				toast.error(res.data.responseMessage)
			}
		}).catch(function (error) {

		})
	}



	const columns = [
		{
			name: 'OrderID',
			selector: row => row.setUpDeliveryID,
			cell: row => row.setUpDeliveryID,
			sortable: true,
		},
		{
			name: 'Brewery Name',
			selector: row => row.breweryName,
			cell: row => row.breweryName,
			sortable: true,
		},
		{
			name: 'Warehouse',
			selector: row => row.wareHouseName,
			sortable: true,
		},
		{
			name: 'Total Palettes',
			selector: row => row.totalPalettes,
			cell: row => row.totalPalettes ,
			sortable: true,
		},
		{
			name: 'Requested Pickup Date',
			selector: row => (row.requestedPickUpDate) ? moment(row.requestedPickUpDate) : 0,
			cell: row => (row.requestedPickUpDate) ? (row.requestedPickUpDate !== "1900-01-01T00:00:00" ? moment(row.requestedPickUpDate).format('MM-DD-YYYY') : "-") : ("-"),
			sortable: true,
		},
		{
			name: 'Confirmed Pick Up Date',
			selector: row => (row.confirmPickUpDate) ? moment(row.confirmPickUpDate) : 0,
			cell: row => (row.confirmPickUpDate) ? (row.confirmPickUpDate !== "1900-01-01T00:00:00" ? moment(row.confirmPickUpDate).format('MM-DD-YYYY') : "-") : ("-"),
			sortable: true,
		},
		{
			name: 'Expected Delivery Date',
			selector: row => (row.expectedDeliveryDate) ? moment(row.expectedDeliveryDate) : 0,
			cell: row => (row.expectedDeliveryDate) ? (row.expectedDeliveryDate !== "1900-01-01T00:00:00" ? moment(row.expectedDeliveryDate).format('MM-DD-YYYY') : "-") : ("-"),
			sortable: true,
		},
		{
			name: 'Pick Up Instruction',
			selector: row => (row.pickUpInstruction)?row.pickUpInstruction:"",
			cell: row => (row.pickUpInstruction)?row.pickUpInstruction:"",
			sortable: true,
		},
		{
			name: 'Status',
			selector: row => row.status,
			cell: row => <span style={{ "color": "#00aeef" }}>{(row.status == 30)?"Processing": (row.status == 40)? "Out for Pick up": (row.status == 50)? "In Transit " : (row.status == 60)?"Delivered" :""}</span>,
	        sortable: true
			//width: '10px',
		},
		{
			name: 'Action',
			width: '240px',
			selector: row => <>
				<Link className="btn btn-secondary" to={"/admin/edit-setup-delivery/" + row.setUpDeliveryID}>Edit</Link>&nbsp;<Link className="btn btn-primary" to={"#"} onClick={() => handleDelete(row.setUpDeliveryID)} >Delete</Link>&nbsp;</>,
		}
	];

	const [data, setData] = useState([])

	const getDeliveryListData = async () => {
		await axios.post(API_URL + "GetSetUpDeliveryRecordByID?code=" + API_KEY, { ID: "0" }, config).then((res) => {
			console.log("deliveryDta", res);

			if (res.data.responseCode == "200") {
				let arr = res.data.responseData;
				setData(res.data.responseData)
				

			} else {
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const tableData = {
		columns,
		data
	};





	const ExpandedComponent = ({ data }) => {
		return (<table className="table child-table">
			<tbody>

				<div className='expandable-tablerow inventorytbds'>
					<div className='expandable col one'><strong>Brand Name</strong></div>
					<div className='expandable col two'><strong>Product Name</strong></div>
					<div className='expandable col three'><strong>Total Cases</strong></div>
					<div className='expandable col four'><strong>Case/ Palette</strong></div>
				</div>
								
					

				{
					data?.deliveryRecords?.map((v, i) => {
						return (
							<>
								<div className="expandable-tablerow inventorytbds">
									<div className="expandable col one">
										{v?.brandName}
									</div>
									<div className="expandable col two">
										{v?.productName}
									</div>
									<div className="expandable col three">
										{v?.quantity}
									</div>
									<div className="expandable col four">
										{v?.palette}
									</div>
									
									
								</div>
							</>
						)
					})
				}
			</tbody>
		</table>)
	};


	return (
		<>

			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">

						<div className="headingTop">
							<h1>Delivery List</h1>
						</div>
						<div className="blank150"></div>
						{/* <div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="editUserProfile">
										<Link to="/brewer/setup-delivery" className="btn btn-primary save-button">Add Delivery</Link>
									</div>
								</div>
							</div>
						</div> */}
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">



										<div className='inventoryTable mange-Fonts'>

											<DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Delivery">
												<DataTable
													columns={columns}
													data={data}
													expandableRows={true}
													expandableRowsComponent={ExpandedComponent}
													noDataComponent={<span className='norecordFound'>No record found!</span>}
													//defaultSortFieldId={5}
													className='managescroll'
												/>
											</DataTableExtensions>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}