
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png"
import contactbg from "./../../assets/images/contactpagebg.jpg"
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, SITE_KEY } from './../../config/constant.js';
import Loader from "./Loader"
import toast, { Toaster } from 'react-hot-toast';
import InputMask from 'react-input-mask';
import Header from './Header';
import Footer from './Footer';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
export default function Resource() {
	document.title = "BevPort - Contact Us"
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const [fname, setFname] = useState("")
	const [fnameError, setFnameError] = useState(false)
	const [lname, setLname] = useState("")
	const [lnameError, setLnameError] = useState(false)
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [mobile, setMobile] = useState("")
	const [mobileError, setMobileError] = useState(false)
	const [title, setTitle] = useState("Sales Services")
	const [titleError, setTitleError] = useState(false)
	const [companyName, setCompanyName] = useState("")
	const [companyNameError, setCompanyNameError] = useState(false)
	const [message, setMessage] = useState("")
	const [messageError, setMessageError] = useState(false)
	const [isSubmitLoader, setIsSubmitLoader] = useState(false)
	const [isDisable, setIsDisable] = useState(false)

	useEffect(() => {
		const loadScriptByURL = (id, url, callback) => {
			const isScriptExist = document.getElementById(id);
			if (!isScriptExist) {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = url;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}
			if (isScriptExist && callback) callback();
		}
		loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
			console.log("Script loaded!");
		});
	}, []);
	const handleFname = (e) => {
		setFnameError(false)
		setFname(e.target.value)
	}
	const handleLname = (e) => {
		setLnameError(false)
		setLname(e.target.value)
	}
	const handleEmail = (e) => {
		setEmailError(false)
		setEmail(e.target.value)
	}
	const handleMobile = (e) => {
		setMobileError(false)
		setMobile(e.target.value)
	}
	const handleTitle = (e) => {
		setTitleError(false)
		setTitle(e.target.value)
	}
	const handleComapny = (e) => {
		setCompanyNameError(false)
		setCompanyName(e.target.value)
	}
	const handleMessage = (e) => {
		setMessageError(false)
		setMessage(e.target.value)
	}
	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	const handleValidation = () => {
		let formIsValid = true;
		if (!fname) {
			formIsValid = false;
			setFnameError(true)
		}
		if (!lname) {
			formIsValid = false;
			setLnameError(true)
		}
		if (!email) {
			formIsValid = false;
			setEmailError(true)
		}
		if (!validateEmail(email)) {
			formIsValid = false;
			setEmailError(true)
		}
		if (!mobile) {
			formIsValid = false;
			setMobileError(true)
		}
		if (!title) {
			formIsValid = false;
			setTitleError(true)
		}
		if (!companyName) {
			formIsValid = false;
			setCompanyNameError(true)
		}
		if (!message) {
			formIsValid = false;
			setMessageError(true)
		}
		return formIsValid;
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (handleValidation()) {
			setIsDisable(true)
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async token => {
					setIsSubmitLoader(true)
					let formData = {
						"FIRSTNAME": fname,
						"LASTNAME": lname,
						"EMAILID": email,
						"PHONENUMBER": mobile,
						"COMPANYNAME": companyName,
						"TITLE": title,
						"CONTENT": message,
						"CaptchaToken": token
					}
					await axios.post(API_URL + "CreateContactUs?code=" + API_KEY, formData, config).then((res) => {
						if (res.data.responseCode === "200") {
							toast.success(res.data.responseMessage);
							setFname("")
							setLname("")
							setEmail("")
							setMobile("")
							/*setTitle("")*/
							setCompanyName("")
							setMessage("")
						} else {
							toast.error(res.data.responseMessage);
						}
						setIsDisable(false)
						setIsSubmitLoader(false)
					}).catch(function (error) {
						setIsDisable(false)
						setIsSubmitLoader(false)
					})
				})
			})

		}

	}
	return (
		<>
			<div className="site-wrapper">
				<div className="main-wrapper">
					<Header />
					{isSubmitLoader ? <Loader /> : ""}
					<section className="simplyfying Empowering contactpages" id="simplys">
						<div className="simplyfyingDiv">
							<div className="container-fluid">
								<div className="row upcontent">
									<div className="col-md-5 simplecontent aboutmainBg">
										<div className="simplecontentimg">
											<img className="img-responsive" src={aboutbgd} />
										</div>
									</div>
									<div className="col-md-7 manBgimg">
										<div className="manImg">
											<img className="img-responsive" src={contactbg} />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="moveTopUp aboutheadphone">
										<div className="container">
											<div className="row main-contentprt">
												<div className="col-md-4">
													<div className="textSimply muddtext">
														<h1><span className="darkblacktext">Sales Service</span>Contact</h1>
													</div>
												</div>
												<div className="col-md-8">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>

					<section id="contactcontent">
						<div className="contactbar">
							<div className="container">
								<div className="row">
									<div className="col-md-6">
										<div className="contactinfo">
											<h3>Get In Touch</h3>
											<p>Let us know what Brewing. Drop a note.</p>

											<h4>Email</h4>
											<p><Link to="mailto:info@bevport.com">info@bevport.com</Link></p>
											<h4>Address</h4>
											<p>Florida Warehouse<br />Tampa, FL</p>
											<h4>Toll-Free Number</h4>
											<p>1-844-973-PORT (7678) </p>
										</div>
									</div>
									<div className="col-md-6">
										<div className="formcnt">
											<form onSubmit={handleSubmit} autocomplete="off">
												<h3>Drop Us a Line</h3>
												<p>Please drop in your details and a short message of what you are looking for and we will contact you to take it further.</p>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">First Name <span className="red-tick">*</span></label>
															<input type="text" className={fnameError ? "form-control error" : "form-control"} name="firstname" placeholder="First" value={fname} onChange={handleFname} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Last Name <span className="red-tick">*</span></label>
															<input type="text" className={lnameError ? "form-control error" : "form-control"} name="lastname" placeholder="Last" value={lname} onChange={handleLname} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Email <span className="red-tick">*</span></label>
															<input type="text" className={emailError ? "form-control error" : "form-control"} name="firstname" placeholder="Email" value={email} onChange={handleEmail} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Mobile Number <span className="red-tick">*</span></label>
															<InputMask
																mask='(999) 999 9999'
																value={mobile}
																onChange={handleMobile}
																className={mobileError ? "form-control error" : "form-control"}
																maskChar={null}
																placeholder="Mobile Number"
															>
															</InputMask>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Title <span className="red-tick">*</span></label>
															<input type="text" className={titleError ? "form-control error" : "form-control"} name="firstname" placeholder="Title" value={title} onChange={handleTitle} disabled />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label for="firstname">Company Name <span className="red-tick">*</span></label>
															<input type="text" className={companyNameError ? "form-control error" : "form-control"} name="lastname" placeholder="Company Name" value={companyName} onChange={handleComapny} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label for="firstname">What can we do for you? <span className="red-tick">*</span></label>
															<textarea for="Content" id="Contents" name="Content" rows="3" className={messageError ? "form-control error" : "form-control"} placeholder="What can we do for you?" value={message} onChange={handleMessage}></textarea>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="submitbtns">
															<button className="btn btn-default" type="submit" disabled={isDisable}>{isDisable ? "Please wait.." : "SUBMIT"}</button>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>

			</div>
			<Toaster />
		</>
	)
}
