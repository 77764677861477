import React, { useState, useEffect } from 'react';
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import BrandInfo from "./brandComponent/brandInfo";
import BrandMedia from "./brandComponent/brandMedia";
import CollatralReviews from "./brandComponent/collatarlReviews";
import { Link, useParams } from "react-router-dom"
import Loader from "./../front/Loader";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

export default function AddBrand() {
	//document.title = "BevPort - Brand Information"
	const { brID } = useParams();
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
	const [activeTab, setActiveTab] = useState("brandInfo");
	const [brandID, setBrandID] = useState("");
	const [brandData, setBrandData] = useState("");
	const [brandMediaData, setBrandMediaData] = useState("");
	const [submitLoader, setSubmitLoader] = useState(false);
	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				if (data?.brandID) {
					localStorage.setItem("brandID", data?.brandID)
				}
			}
		}).catch(function (error) {
		})
	}
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const handleTab = (type) => {
		setActiveTab(type)
		if (type == "brandInfo") {
			document.title = "BevPort - Brand Information"
		} else if (type == "brandMedia") {
			document.title = "BevPort - Brand Media"
		} else if (type == "collatral") {
			if(localStorage.getItem("activityState") == 25){
				getUserProfileFunction(localStorage.getItem("id"))
			}
			document.title = "BevPort - Collateral and Reviews"
		}
	}
	const handleBrandID = (id) => {
		setBrandID(id)
	}
	const handleActiveTab = (tab) => {
		setActiveTab(tab)
		if (tab == "brandInfo") {
			document.title = "BevPort - Brand Information"
		} else if (tab == "brandMedia") {
			document.title = "BevPort - Brand Media"
		} else if (tab == "collatral") {
			if(localStorage.getItem("activityState") == 25){
				getUserProfileFunction(localStorage.getItem("id"))
			}
			document.title = "BevPort - Collateral and Reviews"
		}

	}
	useEffect(() => {
		if (brID && typeof brID !== "undefined") {
			setSubmitLoader(true)
			getBrandInfo(brID)
			getBrandMedia(brID)
			document.title = "BevPort - Brand Information"
		} else {
			setBrandData("")
			setActiveTab("brandInfo")
			setBrandMediaData("")
			document.title = "BevPort - Brand Information"
		}
	}, [brID])
	const getBrandInfo = async (id) => {
		await axios.post(API_URL + "GetBrandInfo?code=" + API_KEY, { "ID": id }, config).then((res) => {
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				setBrandData(res.data.responseData[0])
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	const getBrandMedia = async (id) => {
		await axios.post(API_URL + "FilesByBrand?code=" + API_KEY, { "BrandID": id }, config).then((res) => {
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				setBrandMediaData(res.data.responseData)
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	return (
		<>
			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Add Brand</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className={(activeTab === "brandInfo") ? "active" : ""}><Link to="#" onClick={(e) => (brID || brandID) ? handleTab("brandInfo") : e.preventDefault()}>Brand Information</Link></li>
										<li className={(activeTab === "brandMedia") ? "active" : ""}><Link to="#" onClick={(e) => (brID || brandID) ? handleTab("brandMedia") : e.preventDefault()}>Brand Media</Link></li>
										<li className={(activeTab === "collatral") ? "active" : ""}><Link to="#" onClick={(e) => (brID || brandID) ? handleTab("collatral") : e.preventDefault()}>Collateral and Reviews</Link></li>
										{/*<li className={(activeTab === "brandInfo")?"active":""}><Link to="#">Brand Information</Link></li>
									    <li className={(activeTab === "brandMedia")?"active":""}><Link to="#">Brand Media</Link></li>
									    <li className={(activeTab === "collatral")?"active":""}><Link to="#">Collateral and Reviews</Link></li>*/}
									</ul>

									<div className="tab-content">
										<div className={(activeTab === "brandInfo") ? "tab-pane fade in active" : "tab-pane fade"}>
											<BrandInfo handleBrandID={handleBrandID} brandID={brandID} handleActiveTab={handleActiveTab} brandData={brandData} />
										</div>
										<div id="menu1" className={(activeTab === "brandMedia") ? "tab-pane fade in active" : "tab-pane fade"}>
											<BrandMedia brandID={brandID} handleActiveTab={handleActiveTab} brandData={brandData} brandMediaData={brandMediaData} />
										</div>
										<div id="menu2" className={(activeTab === "collatral") ? "tab-pane fade in active" : "tab-pane fade"}>
											<CollatralReviews brandID={brandID} handleActiveTab={handleActiveTab} brandData={brandData} brandMediaData={brandMediaData} />
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<BrewerFooter />
				</div>
			</div>
		</>
	)
}
