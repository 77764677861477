import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProSidebarProvider } from "react-pro-sidebar";
import publicRoutes from './routes/publicRoutes'
import brewersRoutes from './routes/brewersRoutes'
import buyersRoutes from './routes/buyersRoutes'
import adminRoutes from './routes/adminRoute'
import NotFound from './components/front/404';
import teamMemberRoutes from './routes/teamMemberRoutes';
import salesRepRoutes from './routes/salesRepRoutes';
import SSDRoutes from './routes/SSDRoutes';

import ReactGA from "react-ga4";

const Tracking_ID = "G-JTNX21VVTP";
ReactGA.initialize(Tracking_ID);

function App() {

  
  return (
    <ProSidebarProvider>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        { publicRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />} exact={true}/>) }
        { brewersRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />}  exact={true}/>) }
        { buyersRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />}  exact={true}/>) }
        { adminRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />}  exact={true}/>) }
        { teamMemberRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />}  exact={true}/>) }
        { salesRepRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />} exact={true} />)}
        { SSDRoutes.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component />} exact={true} />)}
      </Routes>
    </BrowserRouter>
    </ProSidebarProvider>
  );

}

export default App;
