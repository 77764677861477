import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import Sidebar from "./BrewerSideBar";
import Select from 'react-select';
import Loader from "./../front/Loader";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import Logo from './../../assets/images/bevport-blogo.png'
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './blob';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import 'bootstrap';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function ProductDetails() {
	document.title = "BevPort - Team Members"
	const fileInputRef = useRef(null);
	const [toggle, setToggle] = useState(false)
	const [loader, setLoader] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [roleList, setRoleList] = useState([]);
	const [role, setRole] = useState("");
	const [roleVal, setRoleVal] = useState(null);
	const [roleError, setRoleError] = useState(false);
	const [roleID, setRoleID] = useState("");
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [firstName, setFirstName] = useState("");
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastName, setLastName] = useState("");
	const [lastNameError, setLastNameError] = useState(false);
	const [memberDetails, setMemberDetails] = useState("");
	const [buttonText, setButtonText] = useState("Save")
	const [memberId, setMemberId] = useState(null)
	const [uploadProfile, setUploadProfile] = useState([]);
	const [profileError, setProfileError] = useState(false);
	const [editPriview, setEditPriview] = useState("");
	const [fileID, setFileID] = useState();
	const [fileName, setFileName] = useState("")

	const [imageName, setImageName] = useState("");
	const [editCrop, setEditCrop] = useState(1);
	const [completeCrop, setCompleteCrop] = useState("");
	const [roleDisabled , setRoleDisabled] = useState(false);
	

	const [src, setSrc] = useState(null);

	const [brandList, setBrandList] = useState([])
	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		x: 7,
		y: 7,

	});
	const [image, setImage] = useState(null);
	const [output, setOutput] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const [imageRef, setImageRef] = useState(null);

	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const userType = localStorage.getItem("userType");
	const adminID = localStorage.getItem("adminID");

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};

	const handleClose = () => {
		setShowModal(false);
	};

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			handleClose();
		}
	};

	const handleEscapeKey = (event) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	};

	useEffect(() => {
		if (showModal) {
			document.addEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [showModal]);


	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		getRoleData();
		getTeamMEmberData();
		getBrandList();
		//$('#myModal').modal('show')
	}, [])




	const getTeamMEmberData = async () => {
		let formData = {
			"ID": localStorage.getItem('id')
		}
		//setLoader(true);
		await axios.post(API_URL + "GetTeamMembers?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				// const sortedData = data.sort((a, b) => a.firstName.localeCompare(b.firstName));
				// console.log("sotedDtaa", sortedData);
				setMemberDetails(res.data.responseData);

			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const getRoleData = async () => {
		await axios.post(API_URL + "GetTeamRoles?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let tempData = []
				let resData = res.data.responseData

				if(userType == 4){
					resData.forEach((da) => {
						if(da?.id !== 1){
							tempData.push({ value: da.userrole, label: da.userrole, id: da.id });
						}
					}
					);
				}else{
					resData.forEach((da) => {

						tempData.push({ value: da.userrole, label: da.userrole, id: da.id });
	
					}
					);
				}

				setRoleList(tempData);

			}
		}).catch(function (error) {

		})
	}

	const handleRole = (e) => {
		setRole(e.value);
		setRoleID(e.id);
		console.log("tees", e.id);
		setRoleVal({ value: e.value, label: e.label });
		setRoleError(false);
	}

	const handleEmail = (e) => {
		setEmail(e.target.value);
		setEmailError(false);

	}

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}


	const handleFirstName = (e) => {
		setFirstName(e.target.value);
		setFirstNameError(false);
	}

	const handleLastName = (e) => {
		setLastName(e.target.value)
		setLastNameError(false);
	}
	const handleValidation = () => {
		let formIsValid = true;

		if (!email) {
			formIsValid = false;
			setEmailError(true)
		}
		if (!validateEmail(email)) {
			formIsValid = false;
			setEmailError(true)
		}

		if (!firstName) {
			formIsValid = false;
			setFirstNameError(true);
		}
		if (!lastName) {
			formIsValid = false;
			setLastNameError(true);
		}
		if (!role) {
			formIsValid = false;
			setRoleError(true);
		}
		// if (!memberId) {
		// 	if (!uploadProfile.length) {
		// 		formIsValid = false
		// 		setProfileError(true);
		// 	}
		// }

		if (editCrop) {
			if (!uploadProfile.length && !editPriview) {
				formIsValid = false
				setProfileError(true);
			}
		}



		return formIsValid;
	}

	const associateSalesRep = async (id) => {
		console.log("ID received in associateSalesRep:", id);
		let brData = [];
		console.log("asdgasd", brandList);
		brandList.forEach((_v, _i) => {
			console.log("forEach", _v);
			let tempData = {
				"BrewerID": localStorage.getItem("id"),
				"SalesRepID": id,
				"BrandID": _v?.brandID,
				Status: "1"
			}
			brData.push(tempData)
		})
		let formData = brData;
		console.log("formdataa Accoisi", formData);
		await axios.post(API_URL + "InsertBrewerSalesRep?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				//toast.success(res.data.responseMessage);
			}
		}).catch(function (error) {
		})
	}
	const getBrandList = async () => {
		await axios.post(API_URL + "GetBrandList?code=" + API_KEY, { UserID: localStorage.getItem("id") }, config).then((res) => {
			if (res.data.responseCode == "200") {
				let brandOption = []
				let resData = res.data.responseData
				console.log("brandDta", res.data.responseData)
				resData.forEach((da) => {
					if (da?.status == 1) {
						brandOption.push({ value: da.brandName, brandID: da.id });
					}
				}
				);
				setBrandList(brandOption);
			}
		}).catch(function (error) {

		})
	}
	const registerSalesRep = async () => {
		//getBrandList();
		setLoader(true)
		let formData = {
			"FirstName": firstName,
			"LastName": lastName,
			"EmailID": email,
			"PhoneNumber": "",
			"UserType": (roleID == 2) ? "3" : "4",
			"StreetAddress": "",
			"State": "",
			"Country": "",
			"City": "",
			"ZipCode": "",
			"CreatedBy": localStorage.getItem("id"),
			"UserRole": roleID.toString()

		}
		await axios.post(API_URL + "RegisterUser?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success("Team Member Added Successfully.");
				if (roleID == 2) {
					associateSalesRep(res.data?.responseData?.[0]?.id);
				}
				
					
				

				savePofile(res.data?.responseData?.[0]?.id)
				console.log("oo", res.data?.responseData?.[0]?.id)

				window.$('#myModal').modal('hide')
				setRole("")
				setEmail("")
				setFirstName("")
				setTimeout(() => {
					getTeamMEmberData();
				}, 2000);

				setLastName("")
				setRoleVal("")
				setRoleID("")
				setRoleError(false)
				setEmailError(false)
				setFirstNameError(false)
				setLastNameError(false)
				setUploadProfile([]);
				setEditPriview("");
				setCompleteCrop("")
				setProfileError(false);
			} else {
				toast.error(res.data.responseMessage);
			}
			setLoader(false)
		}).catch(function (error) {
			console.log(error)
			setSubmitLoader(false)
		})

	}


	const insertTeamMemberFunc = async () => {
		let formData = {
			"ID": localStorage.getItem('id'),
			"FirstName": firstName,
			"LastName": lastName,
			"EmailID": email,
			"UserRole": roleID.toString()
		}
		await axios.post(API_URL + "AddNewUser?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage);
				//document.getElementById("closeBtn").click()
				let data = res.data?.responseData[0];

				savePofile(data?.userID)

				window.$('#myModal').modal('hide')
				setRole("")
				setEmail("")
				setFirstName("")
				setTimeout(() => {
					getTeamMEmberData();
				}, 1000);

				setLastName("")
				setRoleVal("")
				setRoleID("")
				setRoleError(false)
				setEmailError(false)
				setFirstNameError(false)
				setLastNameError(false)
				setUploadProfile([]);
				setProfileError(false);
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);

		}).catch(function (error) {
			console.log(error)
			setLoader(false)

		})
	}
	const updateTeamMemberFunc = async () => {
		let formData = {
			"ID": memberId,
			"FirstName": firstName,
			"LastName": lastName,
			"EmailID": email,
			"UserRole": roleID.toString()
		}
		await axios.post(API_URL + "UpdateNewUser?code=" + API_KEY, formData, config).then((res) => {

			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage);
				if (editCrop) {
					savePofile(memberId);
				}

				//document.getElementById("closeBtn").click()
				window.$('#myModal').modal('hide')
				setRole("")
				setEmail("")
				setFirstName("")
				setLastName("")
				setTimeout(() => {
					getTeamMEmberData();
				}, 2000);
				setRoleVal("")
				setRoleID("")
				setUploadProfile([]);
				setEditPriview("");
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
			setEditCrop(1);

		}).catch(function (error) {
			console.log(error)
			setLoader(false)

		})
	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		// saveFederalDocument();
		if (handleValidation()) {
			setLoader(true);
			if (buttonText === "Save") {
				// if (roleID == 2) {
				// 	registerSalesRep();
				// } else {
				// 	// insertTeamMemberFunc()

				// }

				registerSalesRep();

			} else {
				updateTeamMemberFunc()
			}

		}
	}
	const handleStatusFunction = async (id, status) => {
		setLoader(true);
		let formData = {
			"ID": id,
			"Status": status == 10 ? 90 : 10
		}
		await axios.post(API_URL + "UpdateUserStatus?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				toast.success(res.data.responseMessage);
				getTeamMEmberData();
			} else if (res.data.responseCode == "401") {
				toast.success(res.data.responseMessage);
				getTeamMEmberData();
			} else {
				toast.error(res.data.responseMessage);
			}
			setLoader(false)
		}).catch(function (error) {
			console.log(error)
			setLoader(false)
		});
	}
	const handleEditProfile = async (id) => {
		setLoader(true)
		await axios.post(API_URL + "GetMemberInfo?code=" + API_KEY, { "ID": id }, config).then((res) => {
			if (res.data.responseCode == "200") {
				let data = res.data.responseData;
				setRoleDisabled(true)

				setFirstName(data?.firstName)
				setLastName(data?.lastName)
				setEmail(data?.emailID)
				setRole(data?.userRoleVal);
				setRoleID(data?.userRole);
				setRoleVal({ value: data?.userRole, label: data?.userRoleVal });
				setButtonText("Update")
				setMemberId(data?.id)
				//setEditPriview(data?.filePath);
				setCompleteCrop(data?.filePath);
				setUploadProfile()
				setFileID(data?.fileID);
				setFileName(data?.fileName)
				window.$('#myModal').modal('show')
			}
			setLoader(false)
		}).catch(function (error) {
			console.log(error)
			setLoader(false)
		});

		setEditCrop(0);

	}
	const openPopup = (e) => {
		e.preventDefault()
		setMemberId("");
		setUploadProfile([]);
		setRoleDisabled(false);
		setEditPriview("");
		setCompleteCrop("")
		setButtonText("Save")
		window.$('#myModal').modal('show')
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}

	}



	const closeUploadMode = (e) => {
		e.preventDefault()
		window.$('#myModel2').modal('hide')
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}

	}

	const closeModal = (e) => {
		e.preventDefault()
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
		window.$('#myModal').modal('hide')
		setRole("")
		setEmail("")
		setFirstName("")
		setLastName("")
		setRoleVal("")
		setRoleID("")
		setRoleError(false)
		setEmailError(false)
		setFirstNameError(false)
		setLastNameError(false)
		setUploadProfile([]);
		setEditPriview("");
		setProfileError(false);
		setButtonText("Save")
	}

	const handleProfile = (e) => {
		
		let selectedFile = e.target.files[0];
		if (selectedFile) {
			const imageURL = URL.createObjectURL(selectedFile);
			setEditPriview(imageURL);
			setUploadProfile(selectedFile);
			setImageName(selectedFile.name);
			window.$('#myModel2').modal('show')
			setProfileError(false)
		}
		

	}

	const savePofile = async (ID) => {
		let da = uploadProfile;
		console.log("upload", uploadProfile);
		let finalArray = [];
		//da.forEach((_v, _i) => {
		let d = {
			"UserID": localStorage.getItem("id"),
			"BRANDID": "0",
			"PRODUCTID": "0",
			"FILETYPE": "MemberAvatar",
			"FILEPATH": BLOB_URL + BLOB_TEAM_MEMBER_CONTAINER + '/' + da?.name,
			"FILEDATA": "",
			"FILENAME": da?.name,
			"TeamMemberID": ID

		}
		finalArray.push(d)
		//})

		if (finalArray.length) {

			// setSubmitLoader(true)
			const blobsInContainer: string[] = await uploadFileToBlob([da], BLOB_TEAM_MEMBER_CONTAINER).then(async res => {

				await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
					console.log(res.data.responseMessage)
				}).catch(function (error) {
					console.log(error)
				})
			})
		}
	}

	const deleteFiles = async () => {
		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
			await deleteBlobIfItExists(fileName, BLOB_TEAM_MEMBER_CONTAINER)
		}).catch(function (error) {
			console.log(error)
		})
	}

	const removeImage = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
		setEditPriview("");
		setUploadProfile([]);
		setCompleteCrop("")

	}



	const CropImg = (e) => {

		setCrop(e);
	}
	const ImgLoad = (e) => {
		setImageRef(e.target);
	}

	const handleCropImage = (e) => {

		if (imageRef && crop.width && crop.height) {

			const canvas = document.createElement('canvas');
			const scaleX = imageRef.naturalWidth / imageRef.width;
			const scaleY = imageRef.naturalHeight / imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(
				imageRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);


			canvas.toBlob(
				(blob) => {
					const file = new File([blob], imageName, {
						type: "image/png",
						//preview: URL.createObjectURL(File),
						lastModified: Date.now(),
					});

					setEditPriview(URL.createObjectURL(file))
					setCompleteCrop(URL.createObjectURL(file));
					console.log("file", file);
					setUploadProfile(file);
					window.$('#myModel2').modal('hide');
				}


			)
			setEditCrop(1);
			
		}

	}

	return (
		<>

			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">

						<div className="headingTop">
							<h1>Team Members</h1>
						</div>
						<div className="blank150"></div>
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="editUserProfile">
										<button type="button" className="btn btn-primary save-button" onClick={openPopup}>
											Add Member
										</button>
									</div>
									<div className="addProfilePopup">
										<div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
											<div className="modal-dialog" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<button type="button" className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>
														<h4 className="modal-title" id="myModalLabel">{buttonText === "Update" ? "Update" : "Add"} Team Member</h4>
													</div>
													<div className="modal-body">
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label for="firstname">Choose Role <span className="red-tick"> *</span></label>
																	<Select className={roleError ? "selectTodesign error" : "selectTodesign"}
																		styles={customStyles}
																		options={roleList}
																		onChange={handleRole}
																		value={roleVal}
																		closeMenuOnSelect={true}
																		placeholder={<div className="italic-placeholder">Choose Role</div>}
																		isSearchable
																		isDisabled={roleDisabled}
																	/>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label for="firstname">Email <span className="red-tick"> *</span></label>
																	<input type="text" className={emailError ? "form-control error" : "form-control"} placeholder="Enter Email" onChange={handleEmail} value={email} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label for="firstname">First Name <span className="red-tick"> *</span></label>
																	<input type="text" className={firstNameError ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleFirstName} value={firstName} />
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label for="firstname">Last Name <span className="red-tick"> *</span></label>
																	<input type="text" className={lastNameError ? "form-control error" : "form-control"} placeholder="Enter Last Name" onChange={handleLastName} value={lastName} />
																</div>
															</div>
														</div>

														<div className="row">
															<div className="col-md-12">
																<div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} onClick={e => (e.target.value = null)} accept="image/*" />
																	{/* <button type="button" className="btn btn-primary" onClick={openUploadPopup} >Upload Images</button> */}
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">

																	{(completeCrop) ?

																		<div className='preview-img-icons'>
																			{(!memberId) ? <span className="deleteThis" onClick={removeImage}><i className="fa fa-times"></i></span> : ""}
																			<img src={completeCrop} key="0" alt={"Image"} />
																		</div> : ""

																	}


																</div>
															</div>

														</div>

													</div>
													<div className="modal-footer">
														<button type="button" className="btn btn-default" id="closeBtn" onClick={closeModal}>Close</button>
														<button type="button" className="btn btn-primary" onClick={handleSubmit}>Save</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="addProfilePopup editpicture">
										<div className={`modal fade ${showModal ? 'show' : ''}`} id="myModel2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false" >
											<div className="modal-dialog" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<button type="button" className="close" onClick={closeUploadMode}><span aria-hidden="true">&times;</span></button>
														<h4 className="modal-title" id="myModalLabel">Profile Upload</h4>
													</div>
													<div className="modal-body">
														<div className="row">
															<div className="col-md-12">
																{/* <div className="form-group">
																	<label for="firstname">Profile Upload <span className="red-tick"> *</span></label>
																	<input type="file" className={(memberId) ? "form-control" : (profileError) ? "form-control error" : "form-control"} placeholder="Enter First Name" onChange={handleProfile} ref={fileInputRef} accept="image/*" />

																</div> */}
															</div>
														</div>
														<div className="row">
															<div className="col-md-12">
																<div className="form-group">
																	{/* <ReactCrop src={editPriview} onImageLoaded={setImage}
																		crop={crop} onChange={CropImg} />
																	<img src={editPriview} key="0" alt={"Image"} /> */}
																	<ReactCrop crop={crop} aspect={1 / 1} onChange={CropImg} onImageLoaded={ImgLoad}>
																		<img src={editPriview} onLoad={ImgLoad} />
																	</ReactCrop>
																	<div className='checkbtnsse'>
																		<button className='btn btn-primary save-button' onClick={handleCropImage}>Crop</button>
																	</div>
																</div>
															</div>
														</div>

														<div className="row">

														</div>
														<div className="row">


														</div>

													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body teamPageMeber">
									<div className="row">
										{
											memberDetails.length ? memberDetails.map((_v, i) => {

												return (
													<div className="col-md-3 mb-5">
														<div className="teamMember rounded-bg">
															<img src={(_v?.filePath) ? _v?.filePath : Logo} />
															<h3>{_v?.firstName} {_v?.lastName}</h3>
															<h5>{_v?.userRoleVal}</h5>
															<div className="teamPageBtns">
																<Link className="btn btn-secondary" to="#" onClick={() => handleStatusFunction(_v?.id, _v?.status)}>{(_v?.status == 10) ? "Deactivate" : "Activate"}</Link>
																<Link className="btn btn-primary" to="#" onClick={() => handleEditProfile(_v?.id)}>Edit Profile</Link>
															</div>
														</div>
													</div>
												)

											}) :
												<>
													<div className="col-md-12">
														There are no records to display!
													</div>
												</>
										}




									</div>



								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}