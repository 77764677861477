import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
    document.title = "BevPort - Buyer Order List"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }


    const handleApproved = async (status, orderID) => {
        if (status == "0") {
            let formData = {
                "OrderID": orderID,
                "Status": "1"
            }


            setLoader(true);
            await axios.post(API_URL + "OrderStatusUpdate?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode == "200") {
                    toast.success(res.data.responseMessage, {
                        autoClose: 3000, //3 seconds
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,

                    });
                    getOrderHistory(localStorage.getItem("id"))
                    setLoader(false);
                } else {
                    toast.error(res.data.responseMessage)
                }
            }).catch(function (error) {

            })
        }

    }

    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '150px',
            sortable: true,
        },
        {
            name: 'Quantity In Case',
            selector: row => row.quantity,
            width: '170px',
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.finalPrice),
            cell: row => "$" + parseFloat(row.finalPrice).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row =>(row.orderDate) ? moment(row.orderDate) : 0,
            cell: row => row.orderDate ? moment(row.orderDate).format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Sales Rep Name',
            selector: row => (row.salesRepFirstName + row.salesRepLastName).toLowerCase(),
            cell: row => row.salesRepFirstName + " " + row.salesRepLastName,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == 0) ? <span style={{ "color": "#00aeef" }}>Pending</span> : (row.status == 20) ? <span style={{ "color": "#00aeef" }}>Rejected</span> : <span style={{ "color": "#00aeef" }}>Placed</span>,
            sortable: true
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" onClick={() => handleApproved(row.status, row.orderID)} disabled={( row.status == 0)? false : true} >Approve</Link>&nbsp;
                <Link className="btn btn-primary" to={(row.status == 0 || row.status == 1 || row.status == 2) ? "/buyer/order-detail/" + row.orderID : "#"} target={(row.status == 0 || row.status == 1) ? '_blank' : ''} disabled={(row.status == 20)?true : false}>View</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        let userID = localStorage.getItem("id")
        getOrderHistory(userID)
    }, [])
    const getOrderHistory = async (userID) => {
        await axios.post(API_URL + "OrderHistoryBySalesRep?code=" + API_KEY, { ID: userID }, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)
                console.log("data", res.data.responseData);

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <BuyerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Order By Sales Rep</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className='tbaShowcase'>
                                    <div className="Content-body dataTableSearchBox">
                                        {/* <h3 className="card-heading">Order List</h3> */}
                                        <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">

                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                defaultSortAsc={false}
                                                defaultSortFieldId={4}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BuyerFooter />
                </div>
            </div>
        </>
    )
}