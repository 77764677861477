import React, {useEffect, useState, useCallback} from 'react';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './../blob';
import Dropzone, {useDropzone} from 'react-dropzone'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_BRAND_CONTAINER } from '../../../config/constant';
import Loader from "../../front/Loader"
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandMedia({brandID, handleActiveTab, brandData, brandMediaData}){
	
	const [submitLoader, setSubmitLoader] = useState(false)
	const thumbsContainer = {
	  	display: 'flex',
	  	flexDirection: 'row',
	  	flexWrap: 'wrap',
	  	marginTop: 16
	};

	const thumbInner = {
	  	/*display: 'flex',
	  	minWidth: 0,
	  	overflow: 'hidden'*/
	};
	const thumbInnerVid = {
	  	display: 'flex',
	  	minWidth: 0,
	  	overflow: 'hidden'
	};
	const img = {
	  	display: 'block',
	  	width: '100px',
	  	height: '100%',
	  	objectFit: "contain"
	};
	const vdo = {
	  	display: 'block',
	  	width: '100px',
	  	height: '100%',
	  	objectFit: "contain"
	};
	//const [files, setFiles] = useState([]);
  	/*const {getRootProps, getInputProps} = useDropzone({
    	accept: {
      		'image/*': []
    	},
    	onDrop: useCallback((acceptedFiles,id) => {
    		console.log(acceptedFiles, id)
      		setFiles((files) => [...files, acceptedFiles.map(file => Object.assign(file, {
        		preview: URL.createObjectURL(file)
      		}))]);
    	})
  	});*/
  	const [brandLogo, setBrandLogo] = useState([])
  	const [brandPhotos, setBrandPhotos] = useState([])
  	const [brandVideos, setBrandVideos] = useState([])
  	const [brandLogoPre, setBrandLogoPre] = useState([])
  	const [brandPhotosPre, setBrandPhotosPre] = useState([])
  	const [brandVideosPre, setBrandVideosPre] = useState([])
  	const onDropFunction = (acceptedFiles, fileType) => {
  		if(fileType === "BrandLogo"){
  			const renamedAcceptedFiles = acceptedFiles.map((file) => (
	            new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Logo_${file.name}`, { type: file.type })
	        ))
  			setBrandLogo(renamedAcceptedFiles.map(file => Object.assign(file, {
	    		preview: URL.createObjectURL(file),
	    		imageType: fileType
	  		})));
  		}
  		if(fileType === "BrandPhoto"){
  			const renamedAcceptedFiles = acceptedFiles.map((file) => (
	            new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Photo_${file.name}`, { type: file.type })
	        ))
  			let imgData = [...brandPhotos, renamedAcceptedFiles.map(file => Object.assign(file, {
        		preview: URL.createObjectURL(file),
        		imageType: fileType
      		}))]
      		let tmpImgData = [].concat(...imgData)
  			setBrandPhotos(tmpImgData);
  		}
  		if(fileType === "BrandVideo"){
  			const renamedAcceptedFiles = acceptedFiles.map((file) => (
	            new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandID}_Video_${file.name}`, { type: file.type })
	        ))
  			let vidData = [...brandVideos, renamedAcceptedFiles.map(file => Object.assign(file, {
        		preview: URL.createObjectURL(file),
        		imageType: fileType
      		}))]
  			let tmpVidData = [].concat(...vidData)
  			setBrandVideos(tmpVidData);
  		}
  	}
  	const removeFiles = (index, type) => {
		console.log(index,type);
  		/*if(typeof fileID !== "undefined"){
  			deleteFiles(fileID, fileName)
  		}*/
  		if(type === "BrandLogo"){
  			const newFiles = [...brandLogo]; 
  			newFiles.splice(index, 1);
  			setBrandLogo(newFiles)
  		}
  		if(type === "brandPhotos"){
  			const array = [...brandPhotos]
  			array.splice(index, 1);
  			setBrandPhotos(array)
  		}
  		if(type === "BrandVideo"){
  			const arrayT = [...brandVideos]
  			arrayT.splice(index, 1);
  			setBrandVideos(arrayT)
  		}
  	}
  	const removeFilesPre = (index, type, fileID, fileName) => {
  		if(typeof fileID !== "undefined"){
  			deleteFiles(fileID, fileName)
  		}
  		if(type === "BrandLogo"){
  			const newFiles = [...brandLogoPre]; 
  			newFiles.splice(index, 1);
  			setBrandLogoPre(newFiles)
  		}
  		if(type === "brandPhotos"){
  			const array = [...brandPhotosPre]
  			array.splice(index, 1);
  			setBrandPhotosPre(array)
  		}
  		if(type === "BrandVideo"){
  			const arrayT = [...brandVideosPre]
  			arrayT.splice(index, 1);
  			setBrandVideosPre(arrayT)
  		}
  	}
  	const deleteFiles = async (fileID, fileName) => {
  		//await deleteBlobIfItExists(fileName)
  		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, {"ID":fileID}, config).then(async (res) => {
  			await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)
  		}).catch(function (error) {
  			console.log(error)
		})
  	}
  	const brandLogoThumb = brandLogo.map((file,i) => {
  		return(
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFiles(i,"BrandLogo")}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
	        	<img
	          		src={file.preview}
	          		style={img}
	          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
	        	/>
	      	</div>
    	</div>
  	)});
  	const brandLogoThumbPre = brandLogoPre.map((file,i) => {
  		let fileName = file?.preview;
  		let fFileName = (fileName.split("/")).slice(-1)[0];
  		return(
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFilesPre(i,"BrandLogo", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
	        	<img
	          		src={file.preview}
	          		style={img}
	          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
	        	/>
	      	</div>
    	</div>
  	)});
  	const brandPhotoThumb = brandPhotos.map((file,i) => {
  		let fileName = file?.preview;
  		let fFileName = (fileName.split("/")).slice(-1)[0];
  		return(
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFiles(i,"brandPhotos", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
	        	<img
	          		src={file.preview}
	          		style={img}
	          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
	        	/>
	      	</div>
    	</div>
  	)});
  	const brandPhotoPreThumb = brandPhotosPre.map((file,i) => {
  		let fileName = file?.preview;
  		let fFileName = (fileName.split("/")).slice(-1)[0];
  		return(
    	<div className="mainThumbnail" key={file.name}>
    		<span className="deleteThis" onClick={() => removeFilesPre(i, "brandPhotos", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
      		<div style={thumbInner}>
	        	<img
	          		src={file.preview}
	          		style={img}
	          		onLoad={() => { URL.revokeObjectURL(file.preview) }}
	        	/>
	      	</div>
    	</div>
  	)});
  	const brandVidoThumb = brandVideos.map((file, i) => {
		console.log(brandVideos)
  		return(
  			<div className="mainThumbnail abc" key={file.name}>
				<span className="deleteThis" onClick={() => removeFiles(i,"BrandVideo")}><i className="fa fa-times"></i></span>
				<div style={thumbInnerVid}>
	      			<video width="320" height="240" autoplay control muted>
					  <source src={file.preview} type="video/mp4" />
					  <source src={file.preview} type="video/ogg" />
					  Your browser does not support the video tag.
					</video>
		      	</div>
	    	</div>
  		)
  	})
  	const brandVidoPreThumb = brandVideosPre.map((file, i) => {
  		let fileName = file?.preview;
  		let fFileName = (fileName.split("/")).slice(-1)[0];
  		return(
  			<div className="mainThumbnail abc" key={file.name}>
				<span className="deleteThis" onClick={() => removeFilesPre(i, "BrandVideo", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInnerVid}>
	      			<video width="320" height="240" autoplay control muted>
					  <source src={file.preview} type="video/mp4" />
					  <source src={file.preview} type="video/ogg" />
					  Your browser does not support the video tag.
					</video>
		      	</div>
	    	</div>
  		)
  	})
  	const saveBrandMedia = async () => {
  		let da = (brandLogo.concat(brandPhotos)).concat(brandVideos);
  		let finalArray = [];
  		da.forEach((_v,_i) => {
  			let d = {
			    "UserID":localStorage.getItem("id"),
			    "BRANDID":(brandData)?brandData?.id:brandID,
			    "PRODUCTID":"0",
			    "FILETYPE":_v?.imageType,
			    "FILEPATH":BLOB_URL+BLOB_BRAND_CONTAINER+'/'+_v?.name,
			    "FILEDATA":"",
			    "FILENAME":_v?.name
			}
			finalArray.push(d)
  		})
		  setSubmitLoader(true)
  		if(da.length){
			setSubmitLoader(true)
  			const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
	  			await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
	  				/*if(!brandData){
	  					setBrandLogo([])
			  			setBrandPhotos([])
			  			setBrandVideos([])
	  				}*/
		  			
		  			handleActiveTab("collatral")
		  			toast.success(res.data.responseMessage)
					setSubmitLoader(false)
		  		}).catch(function (error) {
		  			console.log(error)
		  			setSubmitLoader(false)
				})
	  		})
  		}else{
  			handleActiveTab("collatral")
  		}
  		
  	}
  	const iamgeFormats = {
	   'image/*': []
	};
	const videoFormats = {
	   "video/*": []
	};
	useEffect(() => {
		let brandLogoTemp = []
		let brandPhotosTemp = []
		let brandVideoTemp = []
		if(brandMediaData.length){
			brandMediaData.map((_v,_i) => {
				if(_v?.filetype == "BrandLogo"){
					brandLogoTemp.push({"preview":_v.filepath,fileID:_v?.id})
				}
				if(_v?.filetype == "BrandPhoto"){
					brandPhotosTemp.push({"preview":_v.filepath,fileID:_v?.id})
				}
				if(_v?.filetype == "BrandVideo"){
					brandVideoTemp.push({"preview":_v.filepath,fileID:_v?.id})
				}
			})
			setBrandLogoPre(brandLogoTemp)
			setBrandPhotosPre(brandPhotosTemp)
			setBrandVideosPre(brandVideoTemp)
		}else{
			setBrandLogo([])
			setBrandPhotos([])
			setBrandVideos([])
			setBrandLogoPre([])
			setBrandPhotosPre([])
			setBrandVideosPre([])
		}
	},[brandMediaData])
	return(
		<>
		{submitLoader ? <Loader /> : ""}	
      	<div className="LogosUplaods">
      		<div className="row">
      			<div className="col-md-6">
      				<h3 className="card-heading">Logo Images</h3>
      				<p className="cardSubtext">Display your logo on your brand page for Buyers to easily identify your brand. Please use a Square logo for bevport Brand pages. Formats accepted: ( JPEG , JPG , PNG , JFIF ). </p>
      			</div>
      			<div className="col-md-4">
      			<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles,"BrandLogo")} multiple={false} accept={iamgeFormats}>
  					{({getRootProps, getInputProps}) => (
                                <div {...getRootProps({ className: 'dropzone custom-input-drop-brewer'})}>      					
				        	<input {...getInputProps()}/>
                                   
                                    <span className="addDocText_brewer">Drop Brand Logo Here or Click To Upload.
                                        <br />Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF).</span>
      				</div>
      				)}
                        </Dropzone>


                    </div>


                    <div class="col-md-2">
                        <div className="thumbanilOptions">
                            <aside style={thumbsContainer}>
                                {(brandLogo.length) ? brandLogoThumb : brandLogoThumbPre}
                            </aside>
                        </div>
                    </div>
      		</div>

              
      	</div>

      	<div className="LogosUplaods BrandPhotos">
      		<div className="row">
      			<div className="col-md-6">
      				<h3 className="card-heading">Brand Photos</h3>
      				<p className="cardSubtext">Showcase your business, employees and location. Let the personality of your brand shine through, enticing buyers to try your products. Individual products have a place to enter images, so keep these about you and your company/brand. Formats accepted: ( JPEG , JPG , PNG , JFIF ).</p>
      			</div>
      			<div className="col-md-4">
      				<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles,"BrandPhoto")} multiple={true} accept={iamgeFormats}>
  					{({getRootProps, getInputProps}) => (
                                <div {...getRootProps({ className: 'dropzone custom-input-drop-brewer'})}>      					
			        	<input {...getInputProps()} />
                                   
                                    <span className="addDocText_brewer">Drop Brand Photos Here or Click To Upload.
                                     <br />Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF).</span>
      				</div>
      				)}
				</Dropzone>
                    </div>

                    <div class="col-md-2">
                        <div className="thumbanilOptions">
                            <aside style={thumbsContainer}>
                                {brandPhotoPreThumb}
                                {brandPhotoThumb}
                            </aside>
                        </div>
                    </div>
      		</div>
      	
               
      	</div>

      	<div className="LogosUplaods VideosFiles">
      		<div className="row">
      			<div className="col-md-6">
      				<h3 className="card-heading">Videos</h3>
      				<p className="cardSubtext">You can add multiple videos here, highlighting the personality of you and your business. Individual products also have a place to enter videos, so keep these about you and your company/brand. Formats accepted: ( MP4 , MOV , WMV , AVI , MKV ).</p>
      			</div>
      			<div className="col-md-4">
      			<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles,"BrandVideo")} multiple={true} accept={videoFormats}>
  					{({getRootProps, getInputProps}) => (
                                <div {...getRootProps({ className: 'dropzone custom-input-drop-brewer'})}>      					
			        	<input {...getInputProps()} />
                                    {/*<p>Drop video here or click to upload.</p>	*/}


                                    <span className="addDocText_brewer">Drop Video Here or Click To Upload.
                                        <br />Formats accepted: (MP4 , MOV , WMV , AVI , MKV ).</span>
                                   
      				</div>
      				)}
				</Dropzone>
                    </div>


                    <div class="col-md-2">
                        <div className="thumbanilOptions">
                            <aside style={thumbsContainer}>
                                {brandVidoPreThumb}
                                {brandVidoThumb}
                            </aside>
                        </div>
                    </div>
      		</div>
      	
	      
      	</div>
      	<div className="row">
      		<div className="col-md-12">
      			<div className="formBtns threeSubSets">
      				<button type="button" className="btn btn-primary save-button" onClick={saveBrandMedia}>{brandData?"Save":"Save & Next"}</button>
      			</div>
      		</div>
      	</div>
      	</>
	)
}