import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Select from 'react-select';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER } from './../../config/constant';
import DatePicker from "react-datepicker";
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './blob';
import { jsPDF } from "jspdf";
import page1 from "./../../assets/agreements/page1.jpg"
import page2 from "./../../assets/agreements/page2.jpg"
import page3 from "./../../assets/agreements/page3.jpg"
import page4 from "./../../assets/agreements/page4.jpg"
import page5 from "./../../assets/agreements/page5.jpg"
import page6 from "./../../assets/agreements/page6.jpg"
import page7 from "./../../assets/agreements/page7.jpg"
import page8 from "./../../assets/agreements/page8.jpg"
import page9 from "./../../assets/agreements/page9.jpg"
import page10 from "./../../assets/agreements/page10.jpg"
import page11 from "./../../assets/agreements/page11.jpg"
import page12 from "./../../assets/agreements/page12.jpg"
import page13 from "./../../assets/agreements/page13.jpg"
import page14 from "./../../assets/agreements/page14.jpg"
import page15 from "./../../assets/agreements/page15.jpg"
import sign from "./../../assets/agreements/sign.png"
import moment from 'moment';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function FifthStep() {
    useEffect(() => {
          
        // get compliance details
        getComplianceDetails(localStorage.getItem("id"), localStorage.getItem("productID"));
        getCountiesData();


        // change paragraph text to add businessName and state in sentence
        var paraGraph1 = document.getElementById("agreementTextChange_p1");
        paraGraph1.innerHTML = 'THE FLORIDA BEER AND MALT BEVERAGE DISTRIBUTION AGREEMENT (this "Agreement") is made, effective as the date upon which the Agreement is fully executed by the Parties as specified on the signature page of this Agreement herein(the “Effective Date"), by and between  <b>' + localStorage.getItem("businessName") + '</b>, a <b>' + localStorage.getItem("userStateName") + ' </b> (“SUPPLIER”), and BEVPORT DISTRIBUTION LLC, dba BEVPORT.COM, an Arizona limited liability company("DISTRIBUTOR").SUPPLIER and DISTRIBUTOR are sometimes referred to in this Agreement each as a "Party" and, together, as the "Parties".';
          
    }, [])
    document.title = "BevPort"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [stateLicenseNumber, setStateLicenseNumber] = useState("");
    const [federalttb, setFederalttb] = useState("");
    const [date, setDate] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [stateLicenseNumberError, setStateLicenseNumberError] = useState("");
    const [federalttbError, setFederalttbError] = useState("");
    const [dateError, setDateError] = useState("");
    const [nameOfDistributorError, setNameOfDistributorError] = useState(false)
    const [countiesError, setCountiesError] = useState(false)
    const [distAgreement, setDistAgreement] = useState("")
    const [countiesOptions, setCountiesOptions] = useState([]);
    const [countiesOptionsTmp, setCountiesOptionsTmp] = useState([]);
    const [countiesValue, setCountiesValue] = useState("")
    const [countiesId, setCountiesId] = useState("")


    const [countiesError_bevPort, setCountiesError_bevPort] = useState(false)
    const [countiesOptions_bevPort, setCountiesOptions_bevPort] = useState([]);
    /*    const [countiesOptionsTmp_bevPort, setCountiesOptionsTmp_bevPort] = useState([]);*/
    const [countiesValue_bevPort, setCountiesValue_bevPort] = useState("");
    const [countiesId_bevPort, setCountiesId_bevPort] = useState("")


    const [distAgreementError, setDistAgreementError] = useState(false)
    const [nameOfDistributor, setNameOfDistributor] = useState("")

    let Navigate = useNavigate();
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    const handleFirstName = (e) => {
        setFirstName(e.target.value);
        setFirstNameError(false);
    }
    const handleLastName = (e) => {
        setLastName(e.target.value);
        setLastNameError(false);
    }
    const handleLicenseNumber = (e) => {
        setStateLicenseNumber(e.target.value);
        setStateLicenseNumberError(false);
    }
    const handleTtb = (e) => {
        setFederalttb(e.target.value);
        setFederalttbError(false);
    }
    const handleDate = (dt) => {
        setDate(dt);
        setDateError(false);
    }
    const handleValidation = () => {
        let formIsValid = true;
        if (!firstName) {
            formIsValid = false;
            setFirstNameError(true);
        }
        if (!lastName) {
            formIsValid = false;
            setLastNameError(true);
        }
        /*if (!stateLicenseNumber) {
          formIsValid = false;
          setStateLicenseNumberError(true);
        }*/
        if (!date) {
            formIsValid = false;
            setDateError(true);
        }
        //if (!distAgreement) {
        //    formIsValid = false;
        //    setDistAgreementError(true);

        //}
        //if (distAgreement === "yes" && !nameOfDistributor) {
        //    formIsValid = false;
        //    setNameOfDistributorError(true)
        //}
        //if (distAgreement && !countiesId) {
        //    formIsValid = false;
        //    setCountiesError(true)
        //}

        //if (distAgreement && !countiesId_bevPort) {
        //    formIsValid = false;
        //    setCountiesError_bevPort(true);
        //}


         if (!countiesId) {
            formIsValid = false;
             setCountiesError(true);
        }

       

        return formIsValid;
    }
    const handleActivityState = async () => {
        let formData = {
            UserID: localStorage.getItem("id"),
            ActivityState: "100"
        }
        await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                localStorage.setItem("activityState", 100);
                Navigate("/brewer/dashboard");
                // toast.success(res.data.responseMessage);
            } else {
                toast.error(res.data.responseMessage);
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            console.log(error)
            setSubmitLoader(false)
        })
    }
    const getComplianceDetails = async (userID, pid) => {
        let formData = {
            "UserID": userID,
            "ProductID": pid
        }
        await axios.post(API_URL + "GetUserCompliance?code=" + API_KEY, formData, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setStateLicenseNumber(data?.[0]?.licenseNumber);
                setFederalttb(data?.[0]?.permitNumber)
            }
        }).catch(function (error) {
        })
    }
    const handleAgreementRadio = (e) => {
        setDistAgreementError(false)
        setNameOfDistributorError(false)
        setCountiesError(false);
        setCountiesError_bevPort(false);
        //--
        setDistAgreement(e.target.value)
        if (e.target.value === "yes") {
            setCountiesValue("")
            setCountiesId("")
            setCountiesOptions(countiesOptionsTmp);
            //
            setCountiesValue_bevPort("");
            setCountiesId_bevPort("");
            setCountiesOptions_bevPort(countiesOptionsTmp);
        }
        if (e.target.value === "no") {
            let allCountOption = [{ value: 0, label: "All Counties" }]
            // -- Counties A
            setCountiesValue(allCountOption)
            setCountiesId("all_counties")
            setCountiesOptions(allCountOption.concat(countiesOptionsTmp));
            //--- Counties B
            setCountiesValue_bevPort(allCountOption);
            setCountiesId_bevPort("all_counties");
            setCountiesOptions_bevPort(allCountOption.concat(countiesOptionsTmp));
            //--

            setNameOfDistributor("")
        }
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: 'transparent',
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                border: '1px solid #b6b6af',
                boxShadow: null,
            },
            '&:focus': {
                border: '1px solid transparent',
                boxShadow: null,
            }
        })
    };

    // handle a - other counties on change event-----------
    const handleCounties = (e) => {
        let countiesID = [];
        e.forEach((v, i) => {
            countiesID.push(v.value)
        })
        setCountiesError(false)
        setCountiesValue(e)
        //setCountiesId(e.value)
        setCountiesId(countiesID.join(","))
    }
    //-----------------------------------------------------

    // handle b - bevport counties on change event---------
    const handleCounties_bevport = (e) => {
        let countiesID = [];
        e.forEach((v, i) => {
            countiesID.push(v.value)
        })
        setCountiesError_bevPort(false);
        setCountiesValue_bevPort(e);
        setCountiesId_bevPort(countiesID.join(","));
    }
    //----------------------------------------------------


    const getCountiesData = async () => {
        await axios.post(API_URL + "GetCountyData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                let cOption = [];
                let resData = res.data.responseData
                resData.forEach((da) => {
                    cOption.push({ value: da.id, label: da.countyName });
                });
                //setCountiesOptionsTmp(cOption);

                // set counties droopdown controls 
                let allCountOption = [{ value: 0, label: "All Counties" }]
                // -- Counties A
                setCountiesValue(allCountOption);
                setCountiesId("all_counties");
                setCountiesOptions(allCountOption.concat(cOption));


                ////--- Counties B - not getting used right now
                setCountiesValue_bevPort(allCountOption);
                setCountiesId_bevPort("all_counties");
                setCountiesOptions_bevPort(allCountOption.concat(cOption));

               
            }
        }).catch(function (error) {
            console.log(error)
        })
    }


   

   
    const handleNameofDistributer = (e) => {
        setNameOfDistributorError(false)
        setNameOfDistributor(e.target.value)
    }
    const handleSubmit = async () => {
        if (handleValidation()) {
            setSubmitLoader(true);
            let nameData = firstName + " " + lastName;
            let titleDate = nameOfDistributor;
            let businessName = localStorage.getItem("businessName");
            let stateName = localStorage.getItem("userStateName");
            let selectedCounties = countiesValue;
            let countiesAgreementText = "";
            let countiesAgreementText2 = "";
            let countiesAgreementText3 = "";
            let countiesAgreementText4 = "";
            let countiesAgreementText5 = "";
            let countiesAgreementText6 = "";


           

            // populate countiesAgreementText with all the counties the user selected
            // if they selected all counties --> then get all the possible counties and add that to the string instead
            for (var i = 0; i < selectedCounties.length; i++)
            {
                if (selectedCounties[i].label == "All Counties") {

                    for (var j = 0; j < countiesOptions.length; j++) {

                        if (countiesOptions[j].label == "All Counties") {

                        }

                        else {

                            if (j > 13 && j < 26) {

                                countiesAgreementText2 = countiesAgreementText2 + countiesOptions[j].label + "," 
                            }
                            else if (j >= 26 && j < 39)
                            {
                                countiesAgreementText3 = countiesAgreementText3 + countiesOptions[j].label + "," 
                            }
                            else if (j >= 39 && j < 52)
                            {
                                countiesAgreementText4 = countiesAgreementText4 + countiesOptions[j].label + "," 
                            }
                            else if (j >= 52  && j < 65) {
                                countiesAgreementText5 = countiesAgreementText5 + countiesOptions[j].label + ","
                            }
                            else if (j >= 65) {
                                countiesAgreementText6 = countiesAgreementText6 + countiesOptions[j].label + ","
                            }
                            else {
                                countiesAgreementText = countiesAgreementText + countiesOptions[j].label + "," 
                            }
                            
                            
                        }                     
                    }
                    
                }

                else {
                                     
                    if (i > 13 && i < 26) {

                        countiesAgreementText2 = countiesAgreementText2 + selectedCounties[i].label + ","
                    }
                    else if (i >= 26 && i < 39) {
                        countiesAgreementText3 = countiesAgreementText3 + selectedCounties[i].label + ","
                    }
                    else if (i >= 39 && i < 52) {
                        countiesAgreementText4 = countiesAgreementText4 + selectedCounties[i].label + ","
                    }
                    else if (i >= 52 && i < 65) {
                        countiesAgreementText5 = countiesAgreementText5 + selectedCounties[i].label + ","
                    }
                    else if (i >= 65) {
                        countiesAgreementText6 = countiesAgreementText6 + selectedCounties[i].label + ","
                    }
                    else {
                        countiesAgreementText = countiesAgreementText + selectedCounties[i].label + "," 
                    }

                }
            }
            //--------------------------------------------------------
        
         
            fileGenerate(nameData, federalttb, titleDate, businessName, stateName, countiesAgreementText, countiesAgreementText2, countiesAgreementText3, countiesAgreementText4, countiesAgreementText5, countiesAgreementText6)

        }
    }
    const getDataUri = (url) => {
        return new Promise(resolve => {
            var image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');

            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                canvas.getContext('2d').drawImage(this, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            image.src = url;
        })
    }

    const fileGenerate = async (brewerName, licenceNumber, title, businessName, stateName, countiesAgreementText, countiesAgreementText2, countiesAgreementText3, countiesAgreementText4, countiesAgreementText5, countiesAgreementText6) => {

        // ------make agreement pdf and past over text old code ------------

        //var pages1 = await getDataUri(page1);
        //var pages2 = await getDataUri(page2);
        //var pages3 = await getDataUri(page3);
        //var pages4 = await getDataUri(page4);
        //var pages5 = await getDataUri(page5);
        //var pages6 = await getDataUri(page6);
        //var pages7 = await getDataUri(page7);
        //var pages8 = await getDataUri(page8);
        //var pages9 = await getDataUri(page9);
        //var pages10 = await getDataUri(page10);
        //var pages11 = await getDataUri(page11);
        //var pages12 = await getDataUri(page12);
        //var pages13 = await getDataUri(page13);
        //var pages14 = await getDataUri(page14);
        //var pages15 = await getDataUri(page15);

        //let doc = new jsPDF("p", "mm", "a4");
        //doc.addImage(pages1, 'PNG', 2, 2, 210, 280);



        //doc.setFontSize(11)
        //doc.setFont(undefined, 'bold')
        //doc.text(businessName, 40, 57.5);
        //doc.text(stateName, 100, 57.5);
        //doc.addPage();

      


        ////p age 2
        //doc.addImage(pages2, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages3, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages4, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages5, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages6, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages7, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages8, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages9, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages10, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages11, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages12, 'PNG', 2, 2, 210, 280);     
        //doc.addPage();
        //doc.addImage(pages13, 'PNG', 2, 2, 210, 280);
        //doc.addPage();
        //doc.addImage(pages14, 'PNG', 2, 2, 210, 280);
        //doc.addImage(sign, 'PNG', 25, 135, 35, 35);
        //doc.addPage();

        //// page 15 populate counties saved from UI
        //doc.addImage(pages15, 'PNG', 2, 2, 210, 280);
        //doc.setFontSize(10)
        //doc.setFont(undefined, 'bold')
        //doc.text(countiesAgreementText, 5, 50);
        //doc.text(countiesAgreementText2, 5, 55); 
        //doc.text(countiesAgreementText3, 5, 60); 
        //doc.text(countiesAgreementText4, 5, 65);
        //doc.text(countiesAgreementText5, 5, 70);
        //doc.text(countiesAgreementText6, 5, 75);
        //doc.addPage();

        //var blobPDF = new Blob([doc.output('blob')], { type: 'application/pdf' });
        //var files = new File([blobPDF], licenceNumber + ".pdf");
        //var blobUrl = URL.createObjectURL(blobPDF);

        //console.log(files, blobUrl, blobPDF)

        let formData = {
            "ID": localStorage.getItem("id"),
            "FirstNameSign": firstName,
            "LastNameSign": lastName,
            "FederalTTB": federalttb,
            "SignDate": date,
            "IsAgreementInFlorida": distAgreement,
            "DistributorName": nameOfDistributor,
            "County": countiesId,
            "BevPortCounty": countiesId_bevPort,
            "AgreementFilePath": BLOB_URL + "distributor-agreement-files/" + federalttb + ".pdf"
        }


        // Simulate an API request after a 2-second delay
        await axios.post(API_URL + "UpdateDistributorAgreement?code=" + API_KEY, formData, config).then(async (res) => {
            if (res.data.responseCode === "200") {
                handleActivityState();
                toast.success(res.data.responseMessage);
                setSubmitLoader(false);
            }

            else if (res.data.responseCode === "401") {
                //alert("fail 401");
                toast.error(res.data.responseMessage);
                setSubmitLoader(false);
            }

            else {
                /*alert("fail");*/
                toast.error(res.data.responseMessage);
                setSubmitLoader(false);
            }
        }).catch(function (error) {
            console.log(error)
            setSubmitLoader(false);
        })


        /// old to to send and email distrubution agreement

        //const blobsInContainer: string[] = await uploadFileToBlob([files], "distributor-agreement-files").then(async res =>
        //{

        //    let formData = {
        //        "ID": localStorage.getItem("id"),
        //        "FirstNameSign": firstName,
        //        "LastNameSign": lastName,
        //        "FederalTTB": federalttb,
        //        "SignDate": date,
        //        "IsAgreementInFlorida": distAgreement,
        //        "DistributorName": nameOfDistributor,
        //        "County": countiesId,
        //        "BevPortCounty": countiesId_bevPort,
        //        "AgreementFilePath": BLOB_URL + "distributor-agreement-files/" + federalttb + ".pdf"
        //    }


        //    setTimeout(async () => {
        //        // Simulate an API request after a 2-second delay
        //        await axios.post(API_URL + "UpdateDistributorAgreement?code=" + API_KEY, formData, config).then(async (res) => {
        //            if (res.data.responseCode === "200") {
        //                handleActivityState();
        //                toast.success(res.data.responseMessage);
        //                setSubmitLoader(false);
        //            }

        //            else if (res.data.responseCode === "401") {
        //                //alert("fail 401");
        //                toast.error(res.data.responseMessage);
        //                setSubmitLoader(false);
        //            }

        //            else {
        //                /*alert("fail");*/
        //                toast.error(res.data.responseMessage);
        //                setSubmitLoader(false);
        //            }
        //        }).catch(function (error) {
        //            console.log(error)
        //            setSubmitLoader(false);
        //        })
        //    }, 15000);

        //    console.log("resposnse", res);
        //})
    }

    return (
        <div className="mainAdminWrapper brewersDashArea">
            <Sidebar collapse={toggle} />
            {submitLoader ? <Loader /> : ""}
            <div className="wholeCompoundRight">
                <div className="rightContetBlock welcomebevText">
                    <div className="headingTop">
                        <h1>Distribution Agreement</h1>
                    </div>
                    <div className="blank150"></div>
                    <div className="fixtopContentBg">
                        <div className="card-Content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                      {/*  <h3 className="card-heading topsubheading text-left">Distribution Agreement</h3>*/}
                                        <div className='agreement-box'>
                                            <h3 className="card-heading topsubheading">FLORIDA BEER AND MALT BEVERAGE DISTRIBUTION AGREEMENT</h3>
                                            <p id="agreementTextChange_p1" className="">THE FLORIDA BEER AND MALT BEVERAGE DISTRIBUTION AGREEMENT (this "Agreement") is 
                                                made, effective as the date upon which the Agreement is fully executed by the Parties as specified on the signature page of this Agreement herein (the “Effective Date"), by and between 
                                                _________________________, a _________________________ (“SUPPLIER”), and BEVPORT DISTRIBUTION LLC, dba BEVPORT.COM, an Arizona limited liability company ("DISTRIBUTOR"). 
                                                SUPPLIER and DISTRIBUTOR are sometimes referred to in this Agreement each as a "Party" and, together, as the "Parties". 
                                            </p>
                                            <h3 className="card-heading topsubheading">RECITALS</h3>
                                            <p className=""><b>SUPPLIER</b> is engaged in the business of alcoholic beverage, including beer and malt beverage production and supplying. Product may be brewed and/or imported beer and/or malt beverage products.  </p>
                                            <p className=""><b>SUPPLIER</b> desires to establish DISTRIBUTOR as the wholesale distributor of certain of its products with exclusive responsibility for the Territory described herein, upon the terms and conditions set forth in this Agreement. 

                                            </p>
                                            <p className=""><b>DISTRIBUTOR</b> desires to distribute and sell SUPPLIER's products in such Territory, upon the
                                                terms and conditions set forth in this Agreement. 

                                            </p>
                                           
                                            <p className=""><b>NOW, THEREFORE,</b> in consideration of the foregoing and the mutual promises set forth below,
                                                and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged,  SUPPLIER and DISTRIBUTOR hereby agree as follows: 


                                                <p>&nbsp;</p>
                                                <p className='makebold'>1)	APPOINTMENT.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>SUPPLIER hereby appoints DISTRIBUTOR as the exclusive wholesale distributor of SUPPLIER’s current and future beer and malt beverage products and all brand extensions and line extensions thereof ("Products") for the territory identified in Schedule A of this Agreement ("Territory"). DISTRIBUTOR hereby accepts such appointment and agrees to purchase the Products exclusively from SUPPLIER and sell the Products only to licensed retailers permitted to sell alcoholic beverages, including beer and malt beverage products and Products, for on-premises and off-premises consumption in the Territory ("Accounts"). DISTRIBUTOR acknowledges that it has paid no consideration to SUPPLIER in exchange for this appointment.  SUPPLIER warrants and represents that it is the manufacturer and/or sole source of supply of the Products in the Territory.  </li>
                                                    <li>SUPPLIER shall provide DISTRIBUTOR with the right of first refusal on any new alcoholic beverage products, listed on PRODUCT PAGE, or any new items DISTRIBUTOR becomes aware of, created by SUPPLIER subsequent to the Effective Date ("New Products"). In the event SUPPLIER creates or sells any New Products, SUPPLIER shall include such New Products in PRODUCT PAGE and the Territory already specified, plus any additional territories as the Parties may mutually agree. SUPPLIER shall not delete or remove permanently any Products from BevPort.com and offer to sell to another licensed wholesale distributor in the Territory specified in Exhibit A. Any release of Products is at the sole discretion of the DISTIBUTOR. Should SUPPLIER wish to remove any Products from BevPort.com, they must first obtain written permission from DISTRIBUTOR.</li>
                                                    <li>DISTRIBUTOR shall not sell or supply Products to (i) any retail account or other person located outside the Territory, (ii) any Account or other person within the Territory which DISTRIBUTOR knows or has reason to believe will sell or supply Products to a retail account or other person located outside the Territory, or any sub-distributor. Where permitted by law, DISTRIBUTOR may, with the prior written approval of SUPPLIER, sell one or more Products outside the Territory to the extent and so long as SUPPLIER shall authorize DISTRIBUTOR to do so. DISTRIBUTOR may, without SUPPLIER's prior consent, acquire the rights to sell other brands of beer, malt beverages, or other beverages in the Territory or elsewhere, provided the acquisition of such other brands shall not reduce DISTRIBUTOR's obligations to SUPPLIER hereunder.</li>
                                                    <li>lf the exclusive rights granted herein above are or shall become prohibited under federal law or the laws of Florida, then such exclusivity provisions above shall not apply. In that event, SUPPLIER appoints DISTRIBUTOR as a non-exclusive wholesale distributor of, and grants DISTRIBUTOR the right to sell the Products in, the Territory, which shall be DISTRIBUTOR's primary area of responsibility for sale of the Products, which Products shall be sold according to the terms herein, notwithstanding any exclusivity provisions to the contrary herein. </li>
                                                    <li>DISTRIBUTOR remains free to manage its operations as an independent business. Such management includes the unilateral authority to establish resale prices for the Products, distribute other brands of malt beverage and beer products as well as other non-alcoholic and alcoholic beverages, and hire and fire its employees.</li>
                                                </ul>
                                                <p className='makebold'>2) TERMS OF SALE.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>DISTRIBUTOR shall purchase Products from SUPPLIER at the prices and upon such other terms of sale established by SUPPLIER. SUPPLIER has the absolute right to change the prices and other terms of sale of the Products at any time upon reasonable prior written notice to DISTRIBUTOR, subject to Section 8(a) herein.</li>

                                                    <li>Any Product held in the warehouse by CMG, or any other third-party warehouse is not considered sold to CMG or DISTRIBUTOR. Product is maintained in storage until such time Product is moved for delivery to lawful buyers.</li>

                                                    <li>All sales shall be on a cash basis or upon such other terms as DISTRIBUTOR, may establish from time to time. Credit terms are established at net thirty (30) days from the date of delivery of Products to DISTRIBUTOR. All sales of Products are final. At SUPPLIER's request, DISTRIBUTOR may establish and maintain an arrangement providing for the payment of all amounts due to SUPPLIER by ACH payment directly to bank information provided by SUPPLIER when signing up on BevPort.com. All payments to SUPPLIER for Products will be paid every thirty (30) days for the previous thirty (30) days of sales.</li>

                                                    <li>Orders for Product with SUPPLIER ("Purchase Orders") shall be placed by DISTRIBUTOR through the forms and procedures set forth by DISTRIBUTOR. No terms of any Purchase Orders placed by DISTRIBUTOR that conflict with or are inconsistent with the terms of this Agreement shall apply to any sale of the Products. No additional terms of sale shall apply to any sale of the Products to DISTRIBUTOR other than those contained in this Agreement unless expressly accepted in writing by SUPPLIER in its Purchase Order confirmation. It is the SUPPLIER’s responsibility to maintain adequate Product inventory with CMG to fulfill Purchase Orders placed by BevPort.com.  </li>

                                                    <li>Purchase Orders placed by DISTRIBUTOR shall be subject to SUPPLIER's acceptance by written order confirmation, and upon confirmation, shall be binding.</li>

                                                    <li>DISTRIBUTOR shall be responsible for collection and payment of all state and local taxes associated with the sale of any Product.  SUPPLIER shall be responsible for collection and payment of all federal taxes associated with the Products.</li>

                                                  

                                                </ul>
                                                <p className='makebold'>3) SALE AND PROMOTION OF PRODUCTS.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>SUPPLIER shall specifically outline the born-on date, best before date, or other coding used by SUPPLIER to determine the date each Product was brewed, as well as the freshness and shelf-life of the Products inside the BevPort.com platform on the Inventory page. Additionally, SUPPLIER's written Product-related policies shall specifically address the quality and flavor expectations of every Product at the point of consumption so DISTRIBUTOR can try to ensure the delivery of consistent and expected flavor profiles of every Product.</li>
                                                    <li>
                                                        DISTRIBUTOR shall use commercially reasonable efforts to offer for sale, and promote distribution of the Products to all Accounts in the Territory in accordance with Florida law through BevPort.com.
                                                    </li>
                                                    <li>DISTRIBUTOR shall give SUPPLIER thirty (30) days’ prior written notice of any price increase with respect to DISTRIBUTOR margins, which is not directly related to a price increase by SUPPLIER.  Distributor shall, to the extent permitted by law, use its commercially reasonable efforts to maintain pricing for the Products that is competitive with comparable brands in the Territory.
                                                    </li>
                                                    <li>SUPPLIER shall be responsible for assigning Product sales responsibilities to a key person within its organization having experience in beverage sales to sell their brand within the Territory, including sales management and order taking. SUPPLIER further agrees to train key sales personnel in the market.
                                                    </li>
                                                    <li>DISTRIBUTOR and SUPPLIER shall each maintain all licenses and permits required to perform their respective obligations under this Agreement and shall at all times comply with all applicable laws, rules, and regulations, and orders of regulatory authorities. Should there be any changes to SUPPLIER’s licenses or permits, SUPPLIER will notify DISTRIBUTOR immediately in writing and upload such changes to the BevPort.com platform under Compliance.
                                                    </li>
                                                    <li>Should DISTRIBUTOR create any outside promotional materials, they will be designed in accordance with the SUPPLIER trademarks provided by SUPPLIER to DISTRIBUTOR. Such trademarks will be made available to DISTRIBUTOR on SUPPLIER's website or BevPort.com and changes will be available within a reasonable time after any new trademarks arising after the Effective Date are established.
                                                    </li>
                                                </ul>
                                                <p className='makebold'>4) DISTRIBUTOR'S FACILITIES AND OPERATIONS.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>DISTRIBUTOR shall maintain warehouse space in good condition and of sufficient capacity to meet Product inventory, storage and quality control standards as required under applicable laws.</li>

                                                    <li>All Products shall be shipped by DISTRIBUTOR, CMG (Country Malt Group), or DISTRIBUTOR’s third-party agent in a vehicle and stored in a legally licensed warehouse at all times.</li>

                                                    <li>Once a Product sales order is placed by an Account with DISTRIBUTOR, Product will be moved to DISTRIBUTOR’s warehouse for staging and transportation to the Account from DISTRIBUTOR’s, CMG’s, or DISTRIBUTOR’s third-party agent’s warehouse.</li>

                                                    <li>Upon reasonable prior written notice (which shall not be less than two (2) business days, unless in the case of an emergency), DISTRIBUTOR shall permit SUPPLIER to take physical inventory of the Products at DISTRIBUTOR’s warehouse during regular business hours. SUPPLIER shall use diligent efforts to avoid inconvenience to DISTRIBUTOR or disruption of DISTRIBUTOR's business when inspecting DISTRIBUTOR's warehouse. </li>

                                                    <li>Based on the information provided by SUPPLIER, DISTRIBUTOR shall rotate Product inventory in DISTRIBUTOR’s CMG’s, or DISTRIBUTOR’s third-party agent’s warehouse to ensure that the Products are sold to Accounts on a first-in, first-out basis. If such damaged or overage condition of Products results from any negligent or intentional act or omission of SUPPLIER, then, SUPPLIER shall pay for the destruction and shall reimburse DISTRIBUTOR for all of its laid-in costs (as hereinafter defined) for such Products. Laid in cost is defined as free on board cost, plus truces and freight.</li>

                                                    <li>Solely if legal under applicable federal, state, and local laws, rules, and regulations, DISTRIBUTOR shall retrieve and destroy, at SUPPLIER’s own cost and expense, any Products located at Accounts in the Territory that are or may become unsaleable due to age or deterioration in accordance with applicable federal, state, and local laws and regulations.</li>


                                                    <li>DISTRIBUTOR shall maintain or lease delivery personnel, vehicles, and equipment adequate to provide regular deliveries of the Products to Accounts at a frequency sufficient to ensure adequate Product inventory is maintained at all times by such Accounts. 
                                                    </li>
                                                    <li>DISTRIBUTOR shall maintain sufficient working capital to ensure that its facilities, equipment, and personnel are adequate to compete effectively with wholesale distributors of other similar beer and malt beverage brands in the Territory.
                                                    </li>
                                                </ul>
                                                <p className='makebold'>5) RECORDS AND REPORTS.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>DISTRIBUTOR shall maintain records of its distribution and handling of Products as required by the Florida Division of Alcoholic Beverages and Tobacco (“DABT”) or any other state or federal entity that regulates Products (collectively, the “Records”), and submit to SUPPLIER copies of such Records requested by SUPPLIER from time to time, which Records may be redacted as appropriate in DISTRIBUTOR’s discretion. Except when requested by DISTRIBUTOR, SUPPLIER shall maintain in confidence all financial information with respect to DISTRIBUTOR’s business submitted by DISTRIBUTOR to SUPPLIER.</li>

                                                </ul>
                                                <p className='makebold'>6) TERM AND TERMINATION.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>This Agreement shall commence on the Effective Date and continue unless terminated by:

                                                        <p className='romannumber'>i)  the mutual written consent of the Parties; </p>

                                                        <p className='romannumber'>ii) DISTRIBUTOR upon sixty (60) days’ prior written notice; or </p>

                                                        <p className='romannumber'>iii)	SUPPLIER under the conditions described in (b), (c), or (d) herein. Any such notice of termination shall be in accordance with Fla. Statutes Section 563.022, as may be hereafter amended.</p>
                                                    </li>

                                                    <li>This Agreement may be terminated by SUPPLIER, upon no less then fifteen (15) days’ prior written notice to DISTRIBUTOR, if any of the following have been proven to occur:
                                                        <p className='romannumber'>i)	The insolvency of DISTRIBUTOR;</p>
                                                        <p className='romannumber'>ii)	The institution of proceedings in bankruptcy (voluntary or involuntary) by or against DISTRIBUTOR;</p>
                                                        <p className='romannumber'>iii)	An assignment for the benefit of creditors by DISTRIBUTOR;</p>
                                                        <p className='romannumber'>iv)	The receivership or dissolution of DISTRIBUTOR;</p>
                                                        <p className='romannumber'>v)	DISTRIBUTOR’s loss (through revocation, failure to secure renewal or suspension) of any federal, state, or local license required by law and necessary in carrying out the provisions of this Agreement, whereby Distributor cannot distribute the Products for more than sixty (60) days; or</p>
                                                        <p className='romannumber'>vi)  The conviction of, or plea of guilty or no contest, by DISTRIBUTOR or any of its owners, officers, directors or shareholders, to a charge of violating a law related to the business of DISTRIBUTOR that materially affects DISTRIBUTOR’s ability to remain in business. </p>
                                                    </li>



                                                    <li> This Agreement may be terminated by SUPPLIER, upon no less then fifteen (15) days’ prior written notice to DISTRIBUTOR, if any of the following occur:
                                                        <p className='romannumber'>i)	DISTRIBUTOR engages in fraudulent conduct relating to a material provision of this Agreement;</p>
                                                        <p className='romannumber'>ii)	DISTRIBUTOR fails to pay for Product ordered and delivered and fails to make payment within forty-five (45) business days after receipt of written notice of the delinquency and demand for immediate payment from SUPPLIER for such Product;</p>
                                                        <p className='romannumber'>iii)	DISTRIBUTOR intentionally makes a transfer of its business without complying with the notice and approval requirements under applicable laws, rules, and regulations; or</p>
                                                        <p className='romannumber'>iv)	DISTRIBUTOR intentionally ceases or ceases for a period of more than ninety (90) days, to carry on business with respect to any of the Products unless such cessation is due to Force Majeure (as hereinafter defined) or labor dispute.</p>
                                                    </li>

                                                    <li>This Agreement may be terminated by SUPPLIER upon not less than ninety (90) days written notice if there is a proven failure by DISTRIBUTOR to comply with a provision of the Agreement which is both reasonable and of material significance to the business relationship between the Parties. In the case of a termination pursuant to this Section 6(d), DISTRIBUTOR shall be given sixty (60) days to submit a plan of corrective action and an additional one hundred and twenty (120) days to cure pursuant to that plan or to sell the Product distributorship, as permitted under the Florida Statutes Title XXXIV ALCOHOLIC BEVERAGES AND TOBACCO, including Chapter 563 and Chapter 564, as the same may be amended from time to time (the “ABT Laws”).</li>
                                                    <li> Notwithstanding any provision of this Section 6 to the contrary, both Parties reserve all of their respective statutory rights of termination as set forth in the ABT Laws.</li>
                                                    <li>If this Agreement is terminated by the SUPPLIER without good cause, then Section 7(a) hereof will apply.</li>
                                                </ul>
                                                <br />
                                                <p className='makebold'>7) CERTAIN OBLIGATIONS ON TERMINATION.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>
                                                        Upon termination of this Agreement for any reason, neither DISTRIBUTOR nor SUPPLIER shall be liable to the other for compensation or damages on account of the loss of prospective profits on anticipated sales or an account of investments or commitments in connection with the business or goodwill of SUPPLIER or DISTRJBUTOR or otherwise; provided, however, consistent with Section 563.022(17) of the Florida Statutes, if SUPPLIER terminates this Agreement for any reason other than as set forth in Section 6 of this Agreement, SUPPLIER shall pay DISTRIBUTOR the greater of an amount equal to (i) two (2) times the gross profit generated by DISTRIBUTOR’s sale of SUPPLIER’s Products in the immediately preceding twelve (12) month period or (ii) reasonable compensation, as defined under Fla. Statutes Section 563.022(17), as the same may be hereafter amended.
                                                    </li>
                                                    <li> Upon termination of this Agreement for any reason, SUPPLIER agrees to buy from DISTRIBUTOR and DISTRIBUTOR agrees to sell to SUPPLIER all of the Products with at least ten percent (10%) of shelf life remaining and in saleable condition then held by DISTRIBUTOR at one hundred percent (100%) of the actual price paid by DISTRIBUTOR for such Products, including all freight and laid in expenses incurred by DISTRIBUTOR. “Laid-in costs” shall mean the delivered purchase price paid by DISTRJBUTOR to SUPPLIER for such Products, plus deposits, plus the amount of any state and local taxes paid by DISTRIBUTOR in connection with the purchase of such Products. Payment to DISTRIBUTOR shall be made by SUPPLIER within ten (10) days of the termination of this Agreement. Upon such payment, DISTRJBUTOR shall transfer to SUPPLIER good title to all such Products free of liens and encumbrances. Shipping charges and risk of loss shall be the responsibility of SUPPLIER.
                                                    </li>
                                                    <li> Upon the termination of this Agreement for any reason, DISTRIBUTOR:
                                                        <p className='romannumber'>i)	shall promptly surrender and deliver to SUPPLIER, or as directed by SUPPLIER, all  cases and supplies acquired by DISTRIBUTOR from SUPPLIER, and SUPPLIER shall promptly refund DISTRIBUTOR’s deposits for such items to DISTRIBUTOR;</p>
                                                        <p className='romannumber'>ii)	shall promptly surrender and deliver to SUPPLIER, or as directed by SUPPLIER, all of SUPPLIER’s Product signs and advertising displays in DISTRIBUTOR’s possession, and SUPPLIER shall reimburse DISTRIBUTOR’s actual costs, plus cost of delivery as directed by SUPPLIER;</p>
                                                        <p className='romannumber'>iii)	shall at its own cost, remove SUPPLIER’s name, trade names, trademarks, labels, logos or other insignia from DISTRIBUTOR’s buildings, trucks, employees’ uniforms, stationery, websites and all other property retained by DISTRIBUTOR within a reasonable time and no longer than thirty (30) days after termination; and  </p>
                                                        <p className='romannumber'>iv)	shall make all payments then due and owing to SUPPLIER in a time and manner acceptable to SUPPLIER, subject to any offsets or amounts owing by SUPPLIER to DISTRJBUTOR. </p>
                                                    </li>

                                                    <li>Upon termination of this Agreement for any reason, DISTRIBUTOR and SUPPLIER shall, as promptly as practicable, adjust all outstanding accounts between them, and DISTRIBUTOR or SUPPLIER as the case may be, shall promptly pay to the other any remaining balances due. Upon termination, all unfilled Product Orders placed by DISTRIBUTOR shall be deemed canceled.</li>

                                                </ul>
                                                <p className='makebold'>8) SUPPLIER'S OBLIGATIONS.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>SUPPLIER shall have the absolute right to establish from time to time the prices for the sale of the Products to DISTRIBUTOR, and to set the terms and conditions for the sale of the Products to DISTRIBUTOR; provided, however, SUPPLIER shall not fix, maintain, or determine the price at which DISTRIBUTOR shall resell the Products to all Accounts, which DISTRIBUTOR may legally sell. Moreover, SUPPLIER must provide DISTRIBUTOR with at least forty-five (45) days' prior written notice of any price changes in order to allow DISTRIBUTOR sufficient time to comply with the legal requirement for DISTRIBUTOR to post any price changes with the DABT at least ten (10) days before such change of price becomes effective.</li>
                                                    <li>SUPPLIER shall use diligent efforts to supply DISTRIBUTOR with sufficient quantities of Products to meet DISTRIBUTOR's reasonable needs in the Territory. If a shortage of any Product shall occur, SUPPLIER shall allocate existing supplies of such Product among its wholesale distributors in an equitable manner, such that DISTRIBUTOR will be allocated a percentage of its requirements for the Products commensurate with its share of sales among all of SUPPLIER's wholesale distributors.  SUPPLIER shall sell Products to DISTRIBUTOR in sufficient quantity to meet Account demand and will not sell Products to any other wholesale distributors in the Territory. Notwithstanding anything in this Agreement to the contrary, DISTRIBUTOR shall be bound by the terms of this Agreement and obligated to fulfill its duties hereunder only to the extent that an adequate supply of the Products is provided on a timely basis, and DISTRIBUTOR shall not be liable for any claims resulting from an inadequate inventory of Products due to SUPPLIER's failure to provide such, regardless of whether or not any such failure is due to a Force Majeure event.
                                                    </li>
                                                    <li> SUPPLIER represents and warrants that the Products, on the date of shipment, will be free and clear of all liens, will be merchantable, not out of date and containing a minimum shelf life of at least 180 days, of good quality and fit for the purpose intended, and will not be impure, contaminated or adulterated, misbranded or mislabeled within the meaning of the Federal Food, Drug and Cosmetic Act, as amended, and the Food Additives Amendment of 1968, or an article which may not be introduced into interstate commerce.  SUPPLIER further represents and warrants that Products shall be manufactured, packaged, and labeled in conformity with all applicable federal, state, and local laws, rules, and regulations, including, without limitation, the rules and regulations of the United States Alcohol and Tobacco, Tax and Trade Bureau and the Food and Drug Administration.   </li>
                                                    <li>SUPPLIER shall fill Purchase Orders in a timely fashion.
                                                    </li>
                                                    <li>SUPPLIER shall respond promptly in writing to DISTRIBUTOR upon notification of a claim or action against SUPPLIER, DISTRIBUTOR, or any Account. SUPPLIER shall prepare all shipments of Products to DISTRIBUTOR in a manner suitable for overland transportation and in accordance with good beer and malt beverage industry practice.</li>
                                                    <li>SUPPLIER represents and warrants that its operations do and will at all times comply with all applicable federal, state, and local laws (e.g., ABT Laws), regulations, agency policy documents, and orders applicable to the marketing, production, and sale of the Products, including but not limited to maintaining all federal, state, and local permits, licenses, authorizations, and Product-related label, formula, and pricing approvals necessary to supply Products in the Territory to DISTRIBUTOR. SUPPLIER shall be responsible for registering every brand and Product as required under applicable law (including under Fla. Statutes Section 563.045, as the same may be hereafter amended) prior to providing any Product to DISTRIBUTOR.  SUPPLIER affirmatively represents that no actions within the knowledge of SUPPLIER are pending or contemplated that would in any way jeopardize any of its licenses, permits, or governmental approvals.</li>
                                                    <li>SUPPLIER represents and warrants that it has not appointed and will not appoint any other wholesale distributor as a wholesaler of the Products in the Territory.  Further, SUPPLIER’s exclusive wholesaler appointment under Section 1 of this Agreement does not violate any obligations or agreements that SUPPLIER has with another party.</li>
                                                </ul>
                                                <p className='makebold'>9) TITLE AND RISK OF LOSS.</p>
                                                <p>
                                                    All sales of Products by SUPPLIER to DISTRIBUTOR shall be FOB the DISTRIBUTOR’s loading dock location at DISTRIBUTOR’s warehouse premises. Title to and risk of loss for the Products sold hereunder shall pass to DISTRIBUTOR upon delivery of Products to DISTRIBUTOR at DISTRIBUTOR’s warehouse premises.</p>

                                                <p className=''><strong>10) TRADEMARKS.</strong> SUPPLIER hereby grants to DISTRJBUTOR a limited, non-exclusive, royalty free, non-sublicensable, non-assignable right and license to use SUPPLIER's name(s), trade name(s), trademark(s), logo(s), label(s), design(s), and other insignia(s) in the Territory during this Agreement in connection with advertising, promotion, distribution, and sale of the Products. All advertising and promotional materials prepared and used by DISTRIBUTOR in connection with its sale of the Products shall identify SUPPLIER as the manufacturer or supplier and as the owner of all trademarks and trade names associated with SUPPLIER and the Products. DISTRIBUTOR shall have no rights in or to SUPPLIER trademarks and trade names except as expressly provided herein, and all use of SUPPLIER trademarks and trade names by DISTRIBUTOR shall inure to the benefit of SUPPLIER. 
                                                </p>
                                                <p className=''><strong>11) IMPOSSIBILITY OF PERFORMANCE.</strong> Neither Party shall be liable for failure to perform its obligations under this Agreement (other than payments) if such failure is due to fire, hurricane, tornado, act of God, strike, lockout or other industrial disturbance, war, riot, civil insurrection, pandemic, or other cause beyond the control of such Party ("Force Majeure"). Failure by either Party to perform because of such reasons or if by reason of law, or order, rule or regulation of governmental authority, either Party is prevented from performing under this Agreement, such failure to perform shall not constitute a default under this Agreement except as specifically provided in Section 6. 
                                                </p>

                                                <p className=''><strong>12) ASSIGNMENT.</strong> DISTRIBUTOR may assign any or all rights hereunder to any wholesale distributor upon sixty (60) days' written notice to SUPPLIER, provided that the assignee agrees in writing to be bound by the terms of this Agreement.
                                                </p>

                                                <p className=''><strong>13) CHANGE IN OWNERSHIP.</strong> SUPPLIER shall provide at least sixty (60) days prior written notice to DISTRIBUTOR for any change in the ownership or control including: (i) any sale or transfer of outstanding voting shares or membership interest in Party or a change in partnership interests; (ii) any change, whether by one transaction or a series of transactions, having the practical effect of changing or transferring the power to determine Party’s business policies; (iii) a sale of all or substantially all of Party’s assets to a third party; and (iv) a change that would require Party’s notification to the U.S. Alcohol & Tobacco Tax & Trade Bureau of a change of ownership under the provisions of 27 C.F.R. § 1.42 (or successor regulation) (an “Ownership Change”).  DISTRIBUTOR may carry out an Ownership Change without prior notice to or consent of the SUPPLIER.  As a result of an Ownership Change, the applicable Party shall be required to maintain or assume the duties and obligations under the Agreement.  
                                                </p>

                                                <p className=''><strong>14) CONFIDENTIALITY. </strong> Each Party must protect Confidential Information provided to it during the course of fulfilling its obligations under this Agreement.  “Confidential Information” includes all information provided by one Party to the other Party in the course of performance under this Agreement, including but not limited to intellectual property, customer lists, financing sources, manufacturing or distribution programs, proprietary investment opportunities, products, including Products, formulations, recipes, flavors, flavor names, ingredients, advertising, labels, designs, equipment, processes, packaging, techniques, strategies, capabilities, systems and computer programs, technology, specifications, business plans, financial data, consumer data, employee information, and other confidential information.  A Party may not reveal Confidential Information provided to it by the other Party except: (i) as expressly required by law; (ii) as necessary to enforce the terms of this Agreement; (iii) as necessary to advise the Parties’ respective legal, accounting and financial advisors; (iv) with the express written consent of the other Party; and (v) where the Confidential Information in question is or has become generally known other than by disclosure by the Party receiving the Confidential Information under this Agreement.  The provisions of this Section 14 shall survive for two (2) years following the termination of this Agreement.
                                                </p>


                                                <p className='makebold'>15) RELATIONSHIP OF PARTIES.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li>DISTRIBUTOR, its agents and employees shall under no circumstances be deemed employees, agents or representatives of SUPPLIER and, except as SUPPLIER may specifically authorize in writing, DISTRIBUTOR shall have no right to enter into any contracts or commitments in the name or on behalf of SUPPLIER or to bind SUPPLIER in any respect whatsoever.</li>
                                                    <li>This Agreement establishes legal rights and responsibilities between DISTRIBUTOR and SUPPLIER only. This Agreement does not create, establish or continue any commitment or obligation on the part of SUPPLIER or DISTRIBUTOR to any other wholesale distributor to whom SUPPLIER or DISTRIBUTOR may sell the Products of SUPPLIER.</li>
                                                    <li>Except as expressly provided in this Agreement, neither Party shall be liable to the other for special, indirect, punitive or consequential damages, or for damages measured by lost profits or lost opportunity, arising out of or relating to the terms or conditions of this Agreement or such Party's performance or breach thereof.</li>
                                                </ul>
                                         
                                                <p className=''><strong>16) INDEMNIFICATION. </strong> SUPPLIER shall indemnify, defend and hold harmless DISTRIBUTOR and its managers, officers, employees and agents from and against, and reimburse each of them for, any and all liabilities, losses, damages, claims suits, actions, expenses (including, without limitation, attorney fees and costs) and any other costs of any nature or kind whatsoever arising out of, relating to or resulting from all claims, demands, actions or proceedings by any third party to the extent arising from: (i) the breach of any warranty, representation or agreement made by SUPPLIER to DISTRIBUTOR in this Agreement; (ii) DISTRIBUTOR's use of SUPPLIER's trademarks, trade names, brand names, logos, labels, insignias and designs in the advertising, promotion, distribution and sale of Products if used in compliance with this Agreement,  (iii) the quality or condition of or any defect in any of the Products at the time of delivery to DISTRIBUTOR; or (iv) any injuries to or death of persons, or any damage to property, occurring as a result of, or in any way arising out of, the sale, production, bottling, packaging, defects or storage of the Products by the SUPPLIER. Such costs shall include, but shall not be limited to, reasonable expenses, attorney fees, court costs, investigation costs, litigation costs and the costs of any settlement with SUPPLIER's consent (which consent may not be unreasonably withheld, conditioned or delayed) of any such claim. The indemnification obligations set forth in this Section 16 shall survive the termination of the Agreement.
                                                </p>
                                               
                                                <p className=''><strong>17) INSURANCE. </strong> 
                                                     SUPPLIER shall obtain and maintain at its own expense, commercial general liability insurance and product liability insurance in an amount not less than Two Million Dollars ($2,000,000) per occurrence in respect of bodily injury and property damages and with a general aggregate of not less than Four Million Dollars ($4,000,000). SUPPLIER agrees to provide DISTRIBUTOR with a certificate of insurance evidencing such insurance coverage, which shall name DISTRIBUTOR as an additional insured and shall provide that such insurance coverage may not be non-renewed, terminated, or modified except upon at least thirty (30) days' prior written notice by the insurance carrier to the other Party. 
                                                </p>



                                                <p className='makebold'>18) DISPUTE RESOLUTION.</p>
                                                <ul className='alpahabetic-lowe'>
                                                    <li> <u>Good Faith Meeting.</u>  In all disputes, controversies, or claims arising under or relating to this Agreement (a “Dispute”), including, but not limited to, any Dispute relating to payment or involving a Party’s parent company, subsidiaries, or affiliates under common control of any Party, the Parties first shall attempt in good faith to promptly resolve such Dispute by negotiation between executives of both Parties who have authority to settle the Despite (“Good Faith Meeting”).  Upon a Party’s written notice to the other Party of any Dispute not resolved in the normal course of business, the receiving Party shall, within ten (10) days, submit to the sending Party a written response. The notice and response shall include (a) a statement of that Party’s position and a summary of arguments supporting that position, and (b) the name and title of the executive who will represent that Party and of any other person who will accompany the executive at the Good Faith Meeting. Within thirty (30) days after delivery of the initial notice, the designated executives of both Parties shall meet for the Good Faith Meeting at a mutually acceptable time and place to attempt to resolve the Dispute. All negotiations and the settlement reached at the Good Faith Meeting shall be treated as compromise and settlement negotiations for purposes of applicable rules of evidence.</li>
                                                    <li> <u>Arbitration.</u>  If the Parties fail to resolve the Dispute at the conclusion of a Good Faith Meeting, the Dispute shall be submitted by either Party for consideration in arbitration pursuant to the then-current CPR Non-Administered Arbitration Rules (www.cpradr.org), except where they conflict with these provisions, in which case these provisions control. The arbitration shall be held in the state of Florida, specifically in Hillsborough County, unless another location is mutually agreed in writing by the Parties. All aspects of the arbitration, including any settlement negotiations, shall be treated as confidential.</li>
                                                    <li> <u>Designation of Arbitrator.</u>  The Dispute shall be resolved by a single neutral arbitrator chosen from the CPR Panel of Distinguished Neutrals, unless a candidate not on such panel is approved by both Parties. The arbitrator shall be a former judge of a court of general jurisdiction or a lawyer with at least fifteen (15) years’ experience in a law firm consisting of at least twenty-five (25) lawyers and having a corporate law department. If the Dispute requires a candidate with additional special expertise, either Party may so inform CPR and the other Party prior to the selection process, and CPR shall make every effort to assist the Parties in selecting arbitrators with such special expertise. </li>
                                                    <li> <u> Arbitration Proceedings.</u>  Within thirty (30) days after any arbitration hearing or successful dispositive motion, the arbitrator shall issue an order and opinion stating the factual and legal basis for the arbitrator’s decision and award. Each Party shall pay its own costs, expenses, and attorney’s fees related to such arbitration, except that the fees and expenses of the arbitrators and any court reporter shall be split between and paid by the Parties in equal part. </li>
                                                    <li> <u>Confidential Nature of Dispute Resolution Proceedings. </u> The Good Faith Meeting and all arbitration proceedings are confidential. The Parties shall maintain the confidential nature of the Good Faith Meetings and judicial proceedings unless otherwise required by law or judicial decision.  </li>
                                                </ul>


                                                
                                                <p className=''><strong>19) WAIVER. </strong>
                                                    The failure by either Party to insist upon a strict performance of any of the provisions of this Agreement by the other Party shall not be deemed a waiver of any rights or remedies of either Party, nor a waiver of any subsequent breach or default of any such provisions.
                                                </p>

                                                <p className=''><strong>20) SEVERABILITY. </strong>
                                                    In the event that any term, covenant or condition of this Agreement, or the application of any such term, covenant or condition shall be held invalid by any court or administrative body having jurisdiction, it is the intention of the Parties that in lieu of each such invalid term, covenant or condition, there be added as part of this Agreement an enforceable term, covenant or condition as similar in terms as possible to such invalid term, covenant or condition. This Agreement shall not be affected thereby and shall otherwise remain in force and effect.
                                                </p>


                                                <p className=''><strong>21) ENTIRE AGREEMENT. </strong>
                                                    This Agreement, including the attached schedules, constitutes the entire agreement between the Parties with respect to the subject matter of this Agreement.
                                                </p>
                                                <p className=''><strong>22) AMENDMENT. </strong> 
                                                    The terms of this Agreement may not be amended or otherwise modified, except by a writing signed by each of the Parties hereto. Any other attempt to amend or modify any of the terms of this Agreement will be null and void and of no force or effect.
                                                </p>
                                                <p className=''><strong>23) GOVERNING LAW. </strong> 
                                                    The provisions of this Agreement are subject to and shall be governed by the laws of Florida. The laws, rules and regulations of such jurisdiction are hereby incorporated in this Agreement and made a part hereof to the extent that such laws, rules and regulations are required to be so incorporated and, to such extent, shall supersede any conflicting provision of this Agreement, including, but not limited to, the requirement for or length of any notice period. The Parties agree that this Agreement shall be subject to ABT laws, including but not limited to Section 563.022 of the Florida Statutes or any successor statute, the provisions of which are incorporated into the Agreement and deemed a part thereof.  
                                                </p>
                                                <p className=''><strong>24) ATTORNEY FEES. </strong> 
                                                    In any action or proceeding between the Parties regarding this Agreement or its enforcement (including, but not limited to, any proceeding in any bankruptcy court), the prevailing Party in such action or proceeding shall be entitled to collect and recover from the non-prevailing Party in such action or proceeding all costs of such action or proceeding incurred by such prevailing Party, including, but not limited to, reasonable attorney fees and costs through all levels of proceedings, including appeals.
                                                </p>

                                                <p className=''><strong>25) NOTICES.</strong> Any written notice, payment, demand, or request herein provided for shall be sufficiently given or made upon delivery if delivered by certified mail return receipt requested, and shall be addressed to SUPPLIER and to DISTRIBUTOR at the addresses provided on the BevPort.com platform. It is the responsibility of the SUPPLIER to maintain accurate and current addresses at all times.</p>



                                                <div className="row">
                                                    <div style={{ width: "unset" }} className="col-md-6">
                                                        <div className='nfobox'>
                                                            <p>If to Distributor, to:<br />
                                                                BevPort Distribution LLC <br />
                                                                11912 N. 112th St.<br />
                                                                Scottsdale, AZ 85259<br />
                                                                Email Address:admin@bevport.com
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 pull-left">
                                                        <div className='nfobox'>
                                                            <p>If to Supplier, to:<br />
                                                                Address: <br />
                                                              <br />
                                                                Email Address:<br />
                                                                <br />
                                                                
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                               



                                                <p>As used in this Agreement, the term "reasonable prior written notice" shall mean at least three (3) business days' prior notice, unless the Parties agree that a shorter amount of time is reasonable under the circumstances, and such notice may be given in writing as described above, or by e-mail, to such numbers and e-mail addresses as the Parties may agree from time to time, which notice shall be sufficiently given or made upon sending if sent by e-mail. As used herein, the term "business day" shall not include Saturdays, Sundays, and legal holidays. In the event any notice period or other time period calculation falls on a Saturday, Sunday, or legal holiday, such date shall be extended to the next business day that follows such date.
                                                </p>
                                                <p className=''><strong>26) CONSTRUCTION RULES. </strong>
                                                    Titles of Sections in this Agreement are for convenience of reference only and shall neither limit nor amplify the provisions of this Agreement. References in this Agreement to particular Sections are references to Sections of this Agreement unless otherwise specifically provided herein. As used in this Agreement, the words "hereof," "herein," "hereto", "hereunder" and any similar word refers to this Agreement as a whole and not to any particular provision of this Agreement unless otherwise specifically provided herein.
                                                </p>
                                                <p className=''><strong>27) LIMITATION OF LIABILITY. </strong> . In no event shall either Party, or any of such Party's managers, officers, employees, or agents be liable to the other Party for any special, indirect, incidental, punitive, or consequential damages whether based on breach of contract, breach of express or implied warranty, misrepresentation, negligence, strict liability, or any other legal theory even if such Party was specifically advised about the possibility of such losses or damages. 
                                                </p>
                                                <p className=''><strong>28) EXECUTION IN COUNTERPARTS.</strong>
                                                    This Agreement may be executed by each Party on separate counterparts, each of which together shall be deemed to constitute a single document having the same force as if each Party had signed the same counterpart.

                                                </p>
                                                <p>&nbsp;</p>
                                                <p className='makebold'>IN WITNESS WHEREOF, the Parties have caused this Agreement to be executed as of the Effective Date by their signature below.</p>

                                                <p>&nbsp;</p>
                                                {/*<p>&nbsp;</p>*/}
                                                {/*<p className='makebold'>SUPPLIER: </p>*/}
                                                {/*  <p className='makebold'>Name </p>*/}
                                                {/*  <p className='makebold'>License #</p>*/}
                                                {/*  <p className='makebold'>By: -________________________  </p>*/}
                                                {/*  <p className='makebold'>Title:. ___ ______ _*/}
                                                {/*</p>*/}
                                                {/*<p>&nbsp;</p>*/}
                                                {/*<p className='makebold'>DISTRIBUTOR:</p>*/}
                                                {/*<p className='makebold'>BevPort</p>*/}
                                                {/*<p className='makebold'>LLC, an Arizona Limited liability company</p>*/}
                                                {/*<p className='makebold'>LICENSE#</p>*/}

                                                {/*<p className='makebold'>By:_________________________</p>*/}

                                                {/*<p className='makebold'>Name:______________________</p>*/}

                                                {/*<p className='makebold'>Title:_____________________</p>*/}
                                                {/*<h3 className="card-heading topsubheading">SCHEDULE A</h3>*/}
                                                {/*<p className='makebold'>PRODUCTS</p>*/}
                                                {/*<p>&nbsp;</p>*/}
                                                {/*<p>&nbsp;</p>*/}
                                                {/*<h3 className="card-heading topsubheading">SCHEDULE B</h3>*/}
                                                {/*<p className='makebold'>TERRITORY</p>*/}
                                                {/*<p className='alpahabetic-lowe'>ALL OF FLORIDA COUNTIES: FLAGLER, VOLUSIA, BREVARD, INDIAN RIVER, SAINT LUCIE, MARTIN, PALM BEACH, BROWARD, DADE, MONROE, COLLIER, LEE, SARASOTA, CHARLOTTE, DE SOTO, HARDEE, HIGHLANDS, GLADES, HENDRY, MANATEE, HILLSBOROUGH, PINELLAS, PASCO, HERNANDO, CITRUS, SUMTER, LAKE, POLK, OSCEOLA, ORANGE, SEMINOLE</p>*/}
                                                {/*<p>&nbsp;</p>*/}
                                                {/*<p>&nbsp;</p>*/}
                                                {/*<h3 className="card-heading topsubheading">SCHEDULE C</h3>*/}
                                                {/*<p className='alpahabetic-lowe'>CREDIT TERMS</p>*/}
                                            </p>
                                        </div>




                                        <div className="formcontainer">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div>
                                                            <label className="brand-tab">Territory Designations</label>
                                                        </div>


                                                        {/*<label className="brand-tab">Do you have any current distribution agreements in Florida?</label>*/}
                                                        {/*<div>*/}
                                                        {/*    <label class="radio-inline">*/}
                                                        {/*        <input type="radio" name="optradio" value="yes" onChange={handleAgreementRadio} />Yes*/}
                                                        {/*    </label>*/}
                                                        {/*    <label class="radio-inline">*/}
                                                        {/*        <input type="radio" name="optradio" value="no" onChange={handleAgreementRadio} />No*/}
                                                        {/*    </label>*/}

                                                        {/*</div>*/}
                                                        {/*<span className="error-message">{distAgreementError ? "Please choose distribution agreements." : ""}</span>*/}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*<div class="row" style={{ "display": (distAgreement == "no" ? "" : "none") }}>*/}
                                            {/*    <div class="col-md-6">*/}
                                            {/*        <label className="brewer-Label">I authorize Bevport Distribution LLC, rights to the following counties.</label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className="row" >
                                                {/*<div className="col-md-6" style={{ "display": (distAgreement == "yes" ? "" : "none") }}>*/}
                                                {/*    <div className="form-group">*/}
                                                {/*        <label className="brand-tab">Name of Distributor</label>*/}
                                                {/*        <input type="text" className={(nameOfDistributorError) ? "form-control error" : "form-control"} value={nameOfDistributor} onChange={handleNameofDistributer} />*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-md-6" style={{ "display": (distAgreement ? "" : "none") }} >*/}
                                                {/*    <div className="form-group">*/}
                                                {/*        <label className="brand-tab">Counties</label>*/}
                                                {/*        <Select className={countiesError ? "selectTodesign error" : "selectTodesign"}*/}
                                                {/*            styles={customStyles}*/}
                                                {/*            options={countiesOptions}*/}
                                                {/*            onChange={handleCounties}*/}
                                                {/*            value={countiesValue}*/}
                                                {/*            isMulti={true}*/}
                                                {/*            placeholder={<div className="italic-placeholder">Select Counties</div>}*/}
                                                {/*            isSearchable*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}


                                                <div className="col-md-6" >
                                                    <div className="form-group">
                                                        <label className="brand-tab">Counties</label>
                                                        <Select id="mainCounties" className={countiesError ? "selectTodesign error" : "selectTodesign"}
                                                            styles={customStyles}
                                                            options={countiesOptions}
                                                            onChange={handleCounties}
                                                            value={countiesValue}
                                                            isMulti={true}
                                                            placeholder={<div className="italic-placeholder">Select Counties</div>}
                                                            isSearchable
                                                        />
                                                    </div>
                                                </div>

                                            </div> {/*// end row*/}
                                        </div>


                                        <div class="row">
                                            <div class="col-md-6" style={{ "display": (distAgreement == "yes" ? "" : "none") }}>
                                                <label className="brewer-Label">I authorize Bevport Distribution LLC, rights to the following counties.</label>
                                                <div className="form-group">
                                                    <label className="brand-tab">BevPort Counties</label>
                                                    <Select className={countiesError_bevPort ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        options={countiesOptions_bevPort}
                                                        onChange={handleCounties_bevport}
                                                        value={countiesValue_bevPort}
                                                        isMulti={true}
                                                        placeholder={<div className="italic-placeholder">Select Counties</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{ "display": (distAgreement == "yes" ? "" : "none") }} >

                                            </div>
                                        </div>


                                        <p className="">By typing in your name below, you acknowledge and agree to the terms and conditions outlined herein. Your digital signature serves as your legally binding commitment to abide by the terms of this agreement, and any breach may result in legal consequences. Please review the contract carefully before signing, and if you have any questions or concerns, seek legal counsel or contact us for clarification. Once signed a copy will be emailed to you for your records.
                                        </p>
                                        <div className="formcontainer">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">First Name</label>
                                                        <input type="text" className={(firstNameError) ? "form-control error" : "form-control"} value={firstName} onChange={handleFirstName} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">Last Name</label>
                                                        <input type="text" className={(lastNameError) ? "form-control error" : "form-control"} value={lastName} onChange={handleLastName} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">State License Number</label>
                                                        <input type="text" className={(stateLicenseNumberError) ? "form-control error" : "form-control"} value={stateLicenseNumber} onChange={handleLicenseNumber} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">Federal TTB #</label>
                                                        <input type="text" className="form-control" value={federalttb} onChange={handleTtb} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">Date</label>&nbsp;&nbsp;
                                                        <br />
                                                        {/* <input type="text" className={(dateError)?"form-control error":"form-control"} value={date} /> */}
                                                        <DatePicker
                                                            className={(dateError) ? 'form-control error' : 'form-control'}
                                                            selected={date}
                                                            onChange={handleDate}
                                                            minDate={moment().toDate()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="greenBtndbottom text-left"><button type="submit" onClick={handleSubmit}>Sign Electronically</button></div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <BrewerHeader collapseSidebar={collapseSidebar} />
                <BrewerFooter />
            </div>
        </div>
    )
}

export default FifthStep
