import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loader from "./../../front/Loader";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_BRAND_CONTAINER } from './../../../config/constant.js';
import DefaultAvtar from './../../../assets/images/avatar-1.jpg';
import UplaodDocIcon from './../../../assets/images/uploaddoc-blue.png';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './../blob';

import moment from "moment"
import { click } from '@testing-library/user-event/dist/click';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};
export default function Profile({ productID, brandID, productData, handleActiveTab, upcData, manageProd }) {
    // document.title = "BevPort - Compliance"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)

    const [permitNumber, setPermitNumber] = useState("");
    const [permitNumberError, setPermitNumberError] = useState(false);
    const [registryNumber, setRegistryNumber] = useState("");
    const [federalDoc, setFederalDoc] = useState([]);
    const [federalDocPre, setFederalDocPre] = useState([]);
    const [federalDocError, setFederalDocError] = useState(false);

    const [stateOptions, setStateOptions] = useState([]);
    const [stateValue, setStateValue] = useState("");
    const [stateID, setStateID] = useState("")
    const [stateValueError, setStateValueError] = useState(false);
    const [licenseNumber, setLicenseNumber] = useState("");
    const [licenseNumberError, setLicenseNumberError] = useState(false);
    const [producerDoc, setProducerDoc] = useState([]);
    const [producerDocPre, setProducerDocPre] = useState([]);
    const [producerDocError, setProducerDocError] = useState(false);

    const [complianceDetails, setComplianceDetails] = useState([]);
    const [federalComplianceID, setFederalComplianceID] = useState("");
    const [stateComplianceID, setStateComplianceID] = useState("");

    const [dataFederal, setDataFederal] = useState([]);
    const [dataFederalPre, setDataFederalPre] = useState([]);
    const [dataState, setDataState] = useState([]);
    const [dataStatePre, setDataStatePre] = useState([]);

    const [federalStatus, setFederalStatus] = useState();
    const [stateComplianceStatus, setStateComplianceStatus] = useState();

    const [federalLicenseName, setFederalLicenseName] = useState("");
    const [federalLicenseNameError, setFederalLicenseNameError] = useState(false);

    const [producerLicenseName, setProducerLicenseName] = useState("");
    const [producerLicenseNameError, setProducerLicenseNameError] = useState(false);

    const [productUpc, setProductUpc] = useState("");
    const [productUpcImgPre, setProductUpcImgPre] = useState([]);

    const [isUpcChecked, setIsUpcChecked] = useState(1);
    const [ischecked, setIschecked] = useState(true);

    const [productLabelImgFrontPre, setProductLabelImgFrontPre] = useState([]);
    const [productLabelImgBackPre, setProductLabelImgBackPre] = useState([]);
    const [prodID, setProdID] = useState("");

    const [frontCheck, setFrontCheck] = useState(1);
    const [isFrontChecked, setIsFrontChecked] = useState(true);
    const [backCheck, setBackCheck] = useState(1);
    const [isBackChecked, setIsBackChecked] = useState(true);
    const [isComplianceApproved, setIsComplianceApproved] = useState(false);
    const [isComplianceApproved_nonalcholic, setIsComplianceApproved_nonalcholic] = useState(false);

    const [isPreCompliance, setIsPreCompliance] = useState();


    const [hasStateLabel, setHasStateLabel] = useState(false);

    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    let navigate = useNavigate();

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: '100px',
        height: '100%',
        objectFit: "contain"
    };
    useEffect(() => {
      
        //  check if product is  ---> brewerProductType == 4 which non alholic 
        if (productData?.brewerProductType == 4) {

            var hidden = document.getElementsByClassName("NonAlcoholicClass");
            for (var i = hidden.length - 1; i > -1; i--) {
                hidden[i].classList.remove("hidden");
            }
            setIsComplianceApproved(true);// disbale all controls

            var hidden2 = document.getElementsByClassName("AlcoholicClass");
            for (var i = hidden2.length - 1; i > -1; i--) {
                hidden2[i].classList.add("hidden");
            }
        }

        else {
            var hidden = document.getElementsByClassName("NonAlcoholicClass");
            for (var i = hidden.length - 1; i > -1; i--) {
                hidden[i].classList.add("hidden");
            }

            setIsComplianceApproved(false);// disbale all controls

            var hidden2 = document.getElementsByClassName("AlcoholicClass");
            for (var i = hidden2.length - 1; i > -1; i--) {
                hidden2[i].classList.remove("hidden");
            }
        }

        //-------------------------------------------------

   
        if (productData) {
            setProdID(productData?.id);
        }
        if (complianceDetails)
        {
            complianceDetails.forEach((_v, _i) => {
                if (_v?.complianceApproved == 3 && _v?.previousCompliance == "No") {
                    setIsComplianceApproved(true);
                    setIsComplianceApproved_nonalcholic(true);
                }
                if (_v?.previousCompliance == "No") {
                    setIsPreCompliance(false);
                } else if (_v?.previousCompliance == "Yes") {
                    setIsPreCompliance(true);
                }

                // if prevous compliance == yes----> means we are trying to populate values for compliance controls over to a completly new product --->
                // which we dont want to do -- we want to make the user ---> enter new information in every time
                if (_v?.previousCompliance == "Yes") {
                    //alert("hello");
                }

                else {


                    // if (_v.permitNumber) {
                    setIsUpcChecked(_v?.checkedUPC);
                    if (_v?.checkedUPC == 1) {
                        setIschecked(true);
                    } else {
                        setIschecked(false);
                    }
                    setFrontCheck(_v?.checkedFront);
                    if (_v?.checkedFront == 1) {
                        setIsFrontChecked(true);
                    } else {
                        setIsFrontChecked(false);
                    }
                    setBackCheck(_v?.checkedBack);
                    if (_v?.checkedBack == 1) {
                        setIsBackChecked(true);
                    } else {
                        setIsBackChecked(false);
                    }
                    setPermitNumber(_v?.permitNumber);
                    setRegistryNumber(_v?.registryNumber);
                    setFederalComplianceID(_v?.id);
                    setFederalStatus(_v?.status);
                    setFederalLicenseName(_v?.nameOnLicenseFederal)
                    // }
                    // if (_v.licenseNumber) {
                    stateOptions.forEach((_x, _y) => {
                        if (_x.id == _v?.state) {
                            setStateValue({ value: _x.id, label: _x.label })
                        }
                    })
                    setStateID(_v?.state);
                    setStateComplianceID(_v?.id);
                    setLicenseNumber(_v?.licenseNumber);
                    setStateComplianceStatus(_v?.status);
                    setProducerLicenseName(_v?.nameOnLicenseProducer)
                    // }
                    if (_v?.blobFiles) {
                        let federalDocTemp = [];
                        let stateDocTemp = [];
                        _v.blobFiles.forEach((_x, _y) => {
                            if (_x?.filetype == "FederalCompliance") {
                                federalDocTemp.push({ "preview": _x?.filepath, name: _x?.filename, fileID: _x?.id })
                            }
                            if (_x?.filetype == "StateCompliance") {
                                stateDocTemp.push({ "preview": _x?.filepath, name: _x?.filename, fileID: _x?.id })
                            }
                        })
                        // setFederalDoc(federalDocTemp);
                        setFederalDocPre(federalDocTemp);
                        // setProducerDoc(stateDocTemp);
                        setProducerDocPre(stateDocTemp);
                        setDataFederalPre(federalDocTemp);
                        setDataStatePre(stateDocTemp);
                    }

                } // emd else pre compliance 

                // has state label populate and set
                setHasStateLabel(_v?.hasStateLabel);
                if (_v?.hasStateLabel == true) {
                    document.getElementById("hasStateLabel").checked = true;
                }

                else {
                    document.getElementById("hasStateLabel").checked = false;
                }
               
                
               
            })
        } else {


        }
    }, [complianceDetails, stateOptions, productData]);
    useEffect(() => {
        // if(federalDoc){
        let tempArr = [].concat(...federalDoc)
        setDataFederal(tempArr);
        // }
    }, [federalDoc])
    useEffect(() => {
        let tempArr = [].concat(...producerDoc)
        setDataState(tempArr);
    }, [producerDoc])
    useEffect(() => {
        let id = localStorage.getItem("id")
        //if(!manageProd){
            getComplianceDetails(id, prodID);
        //}
        
        if (upcData) {
            setSubmitLoader(true)
            setProductUpc(upcData?.[0]?.productUPC)
            let prodUpcTemp = [];
            let labelFrontTemp = [];
            let labelBackTemp = [];
            upcData?.[0]?.blobFiles?.map((_v, _i) => {
                if (_v?.filetype == 'ProductUpc') {
                    prodUpcTemp.push({ "preview": _v?.filepath, fileID: _v?.id })
                }
                if (_v?.filetype == "LabelFront") {
                    labelFrontTemp.push({ "preview": _v.filepath, fileID: _v?.id })
                }
                if (_v?.filetype == "LabelBack") {
                    labelBackTemp.push({ "preview": _v.filepath, fileID: _v?.id })
                }
            })
            setProductUpcImgPre(prodUpcTemp);
            setProductLabelImgFrontPre(labelFrontTemp);
            setProductLabelImgBackPre(labelBackTemp);
        }
    }, [upcData]);

    const getComplianceDetails = async (userID, pid) => {
        let formData = {
            "UserID": userID,
            "ProductID": pid
        }
        await axios.post(API_URL + "GetUserCompliance?code=" + API_KEY, formData, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setComplianceDetails(data);
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })
    }
    // const getProductUPCFiles = async (userID) => {
    //     await axios.post(API_URL + "GetProductUPCFiles?code=" + API_KEY, { "PRODUCTID": userID }, config).then((res) => {
    //         if (res.data?.responseCode == "200") {
    //             let data = res.data?.responseData;

    //         }
    //         setSubmitLoader(false)
    //     }).catch(function (error) {
    //         setSubmitLoader(false)
    //     })
    // }
    useEffect(() => {
        getStateList();
    }, [])

    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode === "200") {
                let stateOption = [];
                let resData = res.data.responseData
                resData.forEach((da) => {

                    stateOption.push({ value: da.statename, label: da.statename, id: da.id });

                }
                );

                setStateOptions(stateOption)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handlePermitNumber = (e) => {
        setPermitNumber(e.target.value);
        setPermitNumberError(false);
    }
    const handleRegistryNumber = (e) => {
        setRegistryNumber(e.target.value);
    }

    const productUPCthumbPre = productUpcImgPre.map((file, i) => {
        let fileName = file?.preview;
        //let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });


    const handleFederalLicenseName = (e) => {
        setFederalLicenseName(e.target.value);
        setFederalLicenseNameError(false);
    }

    const saveFederalDocument = async () => {
       // if (federalDoc.length) {
            let da = (isPreCompliance) ? federalDocPre.concat(federalDoc) : federalDoc;
            let finalArray = [];
            let pid = (productID) ? productID : prodID;
            da.forEach((_v, _i) => {
                let d = {
                    "UserID": localStorage.getItem("id"),
                    "FILETYPE": "FederalCompliance",
                    "FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
                    "FILEDATA": "",
                    "FILENAME": _v?.name,
                    "PRODUCTID": pid
                }
                finalArray.push(d)
            })
        if (finalArray.length) {

            const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
                await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
                    console.log(res.data.responseMessage)
                    setFederalDoc([]);
                }).catch(function (error) {
                    console.log(error)
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                })
            })
        } // end if ----------------

    }

    const removeFederalDoc = (index, fileID, fileName) => {

        if (typeof fileID !== "undefined") {
            deleteFiles(fileID, fileName)
        }
        let newFiles = [...dataFederal];
        newFiles.splice(index, 1);
        setDataFederal(newFiles);
        setFederalDoc(newFiles);
        // getComplianceDetails(localStorage.getItem("id"))
    }

    const removeFederalDocPre = (index, fileID, fileName) => {
        if (typeof fileID !== "undefined") {
            deleteFiles(fileID, fileName)
        }
        let newFiles = [...dataFederalPre];
        newFiles.splice(index, 1);
        setDataFederalPre(newFiles);
        setFederalDocPre(newFiles);
    }

    const removeStateDoc = (index, fileID, fileName) => {

        if (typeof fileID !== "undefined") {
            deleteFiles(fileID, fileName)
        }
        let newFiles = [...dataState];
        newFiles.splice(index, 1);
        setDataState(newFiles);
        setProducerDoc(newFiles);
    }

    const removeStateDocPre = (index, fileID, fileName) => {

        if (typeof fileID !== "undefined") {
            deleteFiles(fileID, fileName)
        }
        let newFiles = [...dataStatePre];
        newFiles.splice(index, 1);
        setDataStatePre(newFiles);
        setProducerDocPre(newFiles);
    }

    const deleteFiles = async (fileID, fileName) => {
        await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
            await deleteBlobIfItExists(fileName, BLOB_BRAND_CONTAINER)
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleState = (e) => {
        setStateValue({ value: e.value, label: e.label });
        stateOptions.forEach((_v, _i) => {
            if (_v.value == e.value) {
                setStateID(_v.id)
            }
        })
        setStateValueError(false);
    }

    const handleLicense = (e) => {
        setLicenseNumber(e.target.value);
        setLicenseNumberError(false);
    }

    const handleImgClick = () => {
        let imageClick = document.getElementById("clickimg");
        let inputFile = document.getElementById("upload-federal");
        imageClick.onclick = function () {
            inputFile.click();
        }
    }

    const handleImgClickProducer = () => {
        let imageClick = document.getElementById("clickimgproducer");
        let inputFile = document.getElementById("upload-producer");
        imageClick.onclick = function () {
            inputFile.click();
        }
    }

    const handleFederalDoc = (e) => {
        //setFederalDoc(e.target.files[0]);
        let newArr = [].concat(...e.target.files);
        const newName = newArr.map((file) => (new File([file], `${Math.floor((Math.random() * 1000000) + 1)}_FederalCompliance_${file.name}`, { type: file.type })))
        // let newName = `${Math.floor((Math.random() * 1000000) + 1)}_FederalCompliance_${e.target.files[0].name}`
        let newDocs = [...federalDoc, newName.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            imageType: file.type
        }))]
        let newP = [].concat(...newDocs)
        setFederalDoc(newP);

        setFederalDocError(false);
    }
    const handleProducerDoc = (e) => {
        let newArr = [].concat(...e.target.files);
        const newName = newArr.map((file) => (new File([file], `${Math.floor((Math.random() * 1000000) + 1)}_StateCompliance_${file.name}`, { type: file.type })))

        let newDocs = [...producerDoc, newName.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            imageType: file.type
        }))]
        let newP = [].concat(...newDocs)
        setProducerDoc(newP);
        setProducerDocError(false);
    }

    const labelFrontThumbPre = productLabelImgFrontPre.map((file, i) => {
        let fileName = file?.preview;
        let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });

    const labelBackThumbPre = productLabelImgBackPre.map((file, i) => {
        let fileName = file?.preview;
        let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });
    const handleValidation = () => {
        let formIsValid = true;
        if (!permitNumber) {
            formIsValid = false;
            setPermitNumberError(true);
        }
        if (!federalLicenseName) {
            formIsValid = false;
            setFederalLicenseNameError(true);
        }
        if (!producerLicenseName) {
            formIsValid = false;
            setProducerLicenseNameError(true);
        }
        if (!federalDoc.length && !federalDocPre.length) {
            formIsValid = false;
            setFederalDocError(true);
        }
        if (!licenseNumber) {
            formIsValid = false;
            setLicenseNumberError(true);
        }
        return formIsValid;
    }

    const handleActivityState = async () => {

        // non-alcoholic  - skip activity state 75 and set to 100 
        if (productData?.brewerProductType == 4) {
                   
            let formData = {
                UserID: localStorage.getItem("id"),
                ActivityState: "100"
            }

            await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) =>
            {
                if (res.data.responseCode === "200") {
                    setSubmitLoader(false);
                    // toast.success(res.data.responseMessage);
                    localStorage.setItem("activityState", 100);

                    if (prodID) {
                        localStorage.setItem("productID", prodID)
                    } else {
                        localStorage.setItem("productID", productID)
                    }


                    navigate("/brewer/dashboard");

                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
            })// end api call-----------------------
        }


        else
        {

            let formData = {
                UserID: localStorage.getItem("id"),
                ActivityState: "75"
            }

            await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    setSubmitLoader(false);
                    // toast.success(res.data.responseMessage);
                    localStorage.setItem("activityState", 75);
                    if (prodID) {
                        localStorage.setItem("productID", prodID)
                    } else {
                        localStorage.setItem("productID", productID)
                    }

                   
                  navigate("/brewer/register/fifthstep");
                    


                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
            })// end api call-----------------------


        } // end else ------------------

       

    }

    const handleSubmit = async () => {

        if (handleValidation()) {
            setLoader(true);
            setIsComplianceApproved(true);
            setIsComplianceApproved_nonalcholic(true);

            if (isPreCompliance == false) {
                let pid = (productID) ? productID : prodID;
                let formData = {
                    "ID": federalComplianceID,
                    "UserID": localStorage.getItem("id"),
                    "PermitNumber": permitNumber,
                    "NameOnLicenseFederal": federalLicenseName,
                    "RegistryNumber": registryNumber,
                    "State": stateID,
                    "NameOnLicenseProducer": producerLicenseName,
                    "LicenseNumber": licenseNumber,
                    "CheckedUPC": isUpcChecked,
                    "ProductID": pid,
                    "CheckedFront": frontCheck,
                    "CheckedBack": backCheck,
                    "hasStateLabel": hasStateLabel
                }


                // used to test json payload for errors - you will need these values to pass into postman ----- UpdateCompliance
                //alert("id--->" + federalComplianceID);
                //alert("userid-->" + localStorage.getItem("id"));
                //alert("PermitNumber-->" + permitNumber);
                //alert("NameOnLicenseFederal-->" + federalLicenseName);
                //alert("RegistryNumber-->" + registryNumber);
                //alert(stateID);
                //alert(producerLicenseName);
                //alert(licenseNumber);
                //alert("checkupc" + isUpcChecked);
                //alert(pid);
                //alert(frontCheck);
                //alert(backCheck);

                await axios.post(API_URL + "UpdateCompliance?code=" + API_KEY, formData, config).then(async (res) => {
                    
                   await saveFederalDocument();
                   await saveProducerDocument();
                    //if(!manageProd){
                        await getComplianceDetails(localStorage.getItem("id"), pid);
                    //}
                    
                    if (res.data.responseCode === "200") {                      
                        toast.success(res.data.responseMessage)                     
                        handleActivityState();
                        navigate("/brewer/product-list");
                    } else {
                        toast.error(res.data.responseMessage);
                    }
                       
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                }).catch(function (error) {
                    console.log(error)
                    setLoader(false)
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                })
            } else {
                let pid = (productID) ? productID : prodID;
                let formData = {
                    "UserID": localStorage.getItem("id"),
                    "PermitNumber": permitNumber,
                    "NameOnLicenseFederal": federalLicenseName,
                    "RegistryNumber": registryNumber,
                    "State": stateID,
                    "NameOnLicenseProducer": producerLicenseName,
                    "LicenseNumber": licenseNumber,
                    "CheckedUPC": isUpcChecked,
                    "ProductID": pid,
                    "CheckedFront": frontCheck,
                    "CheckedBack": backCheck,
                    "hasStateLabel": hasStateLabel
                }

                await axios.post(API_URL + "CreateCompliance?code=" + API_KEY, formData, config).then(async (res) => {
                    await saveFederalDocument();
                    await saveProducerDocument();
                   // if(!manageProd){
                        await getComplianceDetails(localStorage.getItem("id"), pid);
                   // }
                    if (res.data.responseCode === "200") {                     
                        toast.success(res.data.responseMessage)                       
                        handleActivityState();
                        navigate("/brewer/product-list");
                    } else {
                        toast.error(res.data.responseMessage);
                    }
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                    setLoader(false)
                }).catch(function (error) {
                    console.log(error)
                    setLoader(false)
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                })
            }
        }
    }



    const handleSubmit_NonAlcoholic = async () => {
       
            setLoader(true);
            setIsComplianceApproved(true);
            setIsComplianceApproved_nonalcholic(true);
            if (isPreCompliance == false) {
                let pid = (productID) ? productID : prodID;
                let formData = {
                    "ID": federalComplianceID,
                    "UserID": localStorage.getItem("id"),
                    "PermitNumber": permitNumber,
                    "NameOnLicenseFederal": federalLicenseName,
                    "RegistryNumber": registryNumber,
                    "State": stateID,
                    "NameOnLicenseProducer": producerLicenseName,
                    "LicenseNumber": licenseNumber,
                    "CheckedUPC": isUpcChecked,
                    "ProductID": pid,
                    "CheckedFront": frontCheck,
                    "CheckedBack": backCheck,
                    "hasStateLabel": hasStateLabel
                }

                await axios.post(API_URL + "UpdateCompliance?code=" + API_KEY, formData, config).then(async (res) => {

                    //await saveFederalDocument();
                    //await saveProducerDocument();
                    //if(!manageProd){
                    await getComplianceDetails(localStorage.getItem("id"), pid);
                    //}

                    if (res.data.responseCode === "200")
                    {                     
                        toast.success(res.data.responseMessage)
                        if (localStorage.getItem("activityState") == 50) {

                            handleActivityState();
                        }
                        navigate("/brewer/product-list");
                    } else {
                        toast.error(res.data.responseMessage);
                    }

                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                }).catch(function (error) {
                    console.log(error)
                    setLoader(false)
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                })
            } else {
                let pid = (productID) ? productID : prodID;
                let formData = {
                    "UserID": localStorage.getItem("id"),
                    "PermitNumber": permitNumber,
                    "NameOnLicenseFederal": federalLicenseName,
                    "RegistryNumber": registryNumber,
                    "State": stateID,
                    "NameOnLicenseProducer": producerLicenseName,
                    "LicenseNumber": licenseNumber,
                    "CheckedUPC": isUpcChecked,
                    "ProductID": pid,
                    "CheckedFront": frontCheck,
                    "CheckedBack": backCheck,
                    "hasStateLabel": hasStateLabel
                }

                await axios.post(API_URL + "CreateCompliance?code=" + API_KEY, formData, config).then(async (res) => {
                    //await saveFederalDocument();
                   // await saveProducerDocument();
                    // if(!manageProd){
                    await getComplianceDetails(localStorage.getItem("id"), pid);
                    // }
                    if (res.data.responseCode === "200") {

                        toast.success(res.data.responseMessage)

                        if (localStorage.getItem("activityState") == 50) {
                            handleActivityState();
                        }
                        navigate("/brewer/product-list");
                    } else {
                        toast.error(res.data.responseMessage);
                    }
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                    setLoader(false)
                }).catch(function (error) {
                    console.log(error)
                    setLoader(false)
                    setIsComplianceApproved(false);
                    setIsComplianceApproved_nonalcholic(false);
                })
            }
        
    }
    const saveProducerDocument = async () => {
        // if (producerDoc.length) {
            let da = (isPreCompliance) ? producerDoc.concat(producerDocPre) : producerDoc;
            let finalArray = [];
            let pid = (productID) ? productID : prodID;
            da.forEach((_v, _i) => {
                let d = {
                    "UserID": localStorage.getItem("id"),
                    "FILETYPE": "StateCompliance",
                    "FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
                    "FILEDATA": "",
                    "FILENAME": _v?.name,
                    "PRODUCTID": pid
                }
                finalArray.push(d)
            })
            if (finalArray.length) {
               
                const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
                    await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
                        console.log(res.data.responseMessage)
                        setProducerDoc([])
                    }).catch(function (error) {
                        console.log(error)

                    })
                })
               
            } // end if 
    }

    const handleProducerLicenseName = (e) => {
        setProducerLicenseName(e.target.value);
        setProducerLicenseNameError(false);
    }

    const handleCheckbox = (e) => {
        if (e.target.checked) {
            setIschecked(true);
            setIsUpcChecked(1)
        } else {
            setIschecked(false);
            setIsUpcChecked(0)
        }
    }

    const handleFrontCheck = (e) => {
        if (e.target.checked) {
            setIsFrontChecked(true);
            setFrontCheck(1);
        } else {
            setIsFrontChecked(false);
            setFrontCheck(0)
        }
    }

    const handleBackCheck = (e) => {
        if (e.target.checked) {
            setIsBackChecked(true);
            setBackCheck(1);
        } else {
            setIsBackChecked(false);
            setBackCheck(0);
        }
    }


    // handleHasStateLabel on change event 
    const handleHasStateLabel = (e) => {
     
        setHasStateLabel(e.target.checked);
    }


    const checkboxStyle = {
        color: complianceDetails?.[0]?.complianceSubmitted == 3 ? 'green' : 'default-color',
    };

    return (
        <>

            {submitLoader ? <Loader /> : ""}
            <div className="InsertForms_Brewer">
                <div className="brewerShowOnly">
                    <div className="stepwizard">
                        <div className="stepwizard-row setup-panel">
                            <div className="stepwizard-step col-xs-4">
                                <Link to="#" type="button" className="btn btn-square btn-default btn-success">1</Link>
                                <p><small>Entered</small></p>
                            </div>
                            <div className="stepwizard-step col-xs-4">
                                <Link to="#" type="button" className={(federalStatus == 3 || federalStatus == 2) ? "btn btn-square btn-default btn-success" : "btn btn-default btn-square Pending"}>2</Link>
                                <p><small>Pending</small></p>
                            </div>
                            <div className="stepwizard-step col-xs-4">
                                <Link to="#" type="button" className={(federalStatus == 3) ? "btn btn-square btn-default btn-success" : "btn btn-default btn-square ready"} >3</Link>
                                <p><small>Ready</small></p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="fedralHeading text-center NonAlcoholicClass hidden">Non-Alcoholic Products Don't Require A Federal Permit </h3>
                        </div>
                    </div>

                    <div className="ComplianceForm hidden AlcoholicClass">
                        <div className="row">
                         
                           

                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className="brand-tab">FEDERAL TTB LABEL APPROVAL # (14 digit number)<span className="red-tick"> *</span></label>
                                    <InputMask
                                        /*   mask='**-*-*****'*/   // old mask
                                        mask='***************' // allow just letters and numbers
                                        value={permitNumber}
                                        onChange={handlePermitNumber}
                                        className={permitNumberError ? "form-control error" : "form-control"}
                                        disabled={isComplianceApproved}
                                        maskChar={null}
                                        placeholder="Federal TTB #">
                                    </InputMask>
                                    {/* <input type="text" className={(permitNumberError) ? "form-control error" : "form-control"} placeholder="Federal TTB #" value={permitNumber} onChange={handlePermitNumber} /> */}
                                </div>

                            </div>


                            <div class="col-md-6">
                                <div className="form-group">
                                    <label className="brand-tab">Name on License<span className="red-tick"> *</span></label>
                                    <input type="text" className={(federalLicenseNameError) ? "form-control error" : "form-control"} placeholder="Name on License" value={federalLicenseName} onChange={handleFederalLicenseName} disabled={isComplianceApproved} />
                                </div>

                            </div>

                        </div>


                        <div class="row">


                            <div class="col-md-6">
                                <div className="form-group">
                                    <label className="brand-tab">Registry Number, If Applicable</label>
                                    <InputMask
                                        mask='999999999999'
                                        value={registryNumber}
                                        onChange={handleRegistryNumber}
                                        className="form-control"
                                        maskChar={null}
                                        disabled={isComplianceApproved}
                                        placeholder="Registry Number, If Applicable">
                                    </InputMask>
                                    {/* <input type="text" className="form-control" placeholder="Registry Number, If Applicable" value={registryNumber} onChange={handleRegistryNumber} /> */}
                                </div>
                                {/* <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="formBtns">
                                                                        <button type="button" class="btn btn-primary save-button" onClick={handleFederalSubmit}>Save</button>&nbsp;&nbsp;
                                                                    </div>

                                                                </div>
                                                            </div> */}

                            </div>


                            <div className="col-md-6">
                                <div className={(federalDocError) ? "uploadIconspng_brewer error" : "uploadIconspng_brewer"} id='clickimg' onClick={handleImgClick}>
                                    {/* <div className="uploadIconspng error"> */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        id='upload-federal'
                                        // value={federalDoc}
                                        disabled={isComplianceApproved}
                                        onChange={handleFederalDoc}
                                        multiple="multiple"
                                    />
                                    <img className="img-responsive" src={UplaodDocIcon} />

                                    <span className="addDocText_brewer">Click To Add Document
                                        <br />Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF).</span>
                                </div>
                            </div>




                        </div> {/*//end row*/}


                        <div class="row">



                            {(dataFederal.length || dataFederalPre.length) ? <div class="row uplaodViews">
                                <div class="col-md-12">
                                    <h4>Uploaded Documents</h4>

                                    <div className="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th width="170">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataFederal.map((_v, _i) => {
                                                    return [
                                                        <tr>
                                                            <td className="fileNamede">
                                                                <span>{_v?.name}</span>
                                                            </td>
                                                            <td className="twoAnchorBtns">
                                                                <Link to={_v?.preview} className="btn btn-secondary" target="_blank">Preview</Link>
                                                                <button className="btn btn-secondary" onClick={() => removeFederalDoc(_i, _v?.fileID, _v?.name)} disabled={isComplianceApproved}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ]
                                                })}
                                                {dataFederalPre.map((_v, _i) => {
                                                    return [
                                                        <tr>
                                                            <td className="fileNamede">
                                                                <span>{_v?.name}</span>
                                                            </td>
                                                            <td className="twoAnchorBtns">
                                                                <Link to={_v?.preview} className="btn btn-secondary" target="_blank">Preview</Link>
                                                                <button className="btn btn-secondary" onClick={() => removeFederalDocPre(_i, _v?.fileID, _v?.name)} disabled={isComplianceApproved}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ]
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : ""}

                        </div>


                    </div>
                </div>

                <div className="brewerShowOnly">
                    <div className="ComplianceForm bottompart">
                        <div className="row hidden AlcoholicClass">
                            <h3 className="fedralHeading text-center">State Producer/Business License</h3>
                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className="brand-tab">Select State</label>
                                    <Select className={stateValueError ? "selectTodesign error" : "selectTodesign"}
                                        styles={customStyles}
                                        //menuPlacement="top"
                                        options={stateOptions}
                                        onChange={handleState}
                                        value={stateValue}
                                        closeMenuOnSelect={true}
                                        placeholder={<div className="italic-placeholder">State</div>}
                                        isDisabled={isComplianceApproved}
                                        isSearchable
                                    />
                                </div>

                            </div>


                            <div class="col-md-6">
                                <div className="form-group">
                                    <label className="brand-tab">Name on License<span className="red-tick"> *</span></label>
                                    <input type="text" className={(producerLicenseNameError) ? "form-control error" : "form-control"} placeholder="Name on License" value={producerLicenseName} onChange={handleProducerLicenseName} disabled={isComplianceApproved} />
                                </div>

                            </div>


                        </div> {/*// ---------------- end row--------------- */}


                        <div class="row hidden AlcoholicClass">
                            <div class="col-md-6">
                                <div className="form-group">
                                    <label className="brand-tab">License Number<span className="red-tick"> *</span></label>
                                    {/*  mask='999999999999'*/} {/*// old mask chnaged because of a user*/} 
                                    <InputMask
                                        mask='************'                                
                                        value={licenseNumber}
                                        onChange={handleLicense}
                                        className={licenseNumberError ? "form-control error" : "form-control"}
                                        maskChar={null}
                                        disabled={isComplianceApproved}
                                        placeholder="License Number">
                                    </InputMask>
                                    {/* <input type="text" className={(licenseNumberError) ? "form-control error" : "form-control"} placeholder="License Number" value={licenseNumber} onChange={handleLicense} /> */}
                                </div>
                            </div>



                            <div className="col-md-6">
                                <div className={(producerDocError) ? "uploadIconspng_brewer error" : "uploadIconspng_brewer"} id='clickimgproducer' onClick={handleImgClickProducer}>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        id="upload-producer"
                                        onChange={handleProducerDoc}
                                        disabled={isComplianceApproved}
                                        multiple="multiple"
                                    />
                                    <img className="img-responsive" src={UplaodDocIcon} />
                                    <span className="addDocText_brewer">Click To Add Document
                                        <br />Formats accepted: ( JPEG , JPG , PNG , JFIF, PDF).</span>
                                </div>
                            </div>


                        </div>





                        <div className="row hidden AlcoholicClass">

                            <div className="col-md-2">
                                {productUpc ? <><div className="form-group havecheckbox">
                                    <label className="brand-tab">Product UPC </label>
                                    <input type='text' className='form-control' value={productUpc} disabled={true} />
                                    {
                                        (productUPCthumbPre.length) ?
                                            <label class="containercheck"><span className='visiblitypro'>One</span>
                                                <input className={(complianceDetails?.[0]?.complianceSubmitted != 3) ? 'chcekcontrol form-control' : "chcekcontrol form-control greenCheck"} type='checkbox' value={isUpcChecked} onChange={handleCheckbox} checked={ischecked} disabled={(complianceDetails?.[0]?.complianceSubmitted == 3) ? true : false} />
                                                <span class="checkmark"></span>
                                            </label>

                                            : ""}
                                </div></> : ""}

                                {
                                    (productUPCthumbPre.length) ?
                                        <div className="thumbanilOptions">
                                            <aside style={thumbsContainer}>
                                                {productUPCthumbPre}
                                            </aside>
                                        </div> : ""
                                }
                            </div>

                            {
                                (labelFrontThumbPre.length) ?
                                    <div className="col-md-2">
                                        <div className="form-group Product-Label-Front ">
                                            <label className="brand-tab">Product Label Front </label>


                                            <label class="containercheck"><span className='visiblitypro'>Two</span>
                                                <input className={(complianceDetails?.[0]?.complianceSubmitted != 3) ? "chcekcontrol form-control" : "chcekcontrol form-control greenCheck"} type="checkbox" value={frontCheck} checked={isFrontChecked} onChange={handleFrontCheck} disabled={(complianceDetails?.[0]?.complianceSubmitted == 3) ? true : false} disabled={isComplianceApproved} />
                                                <span class="checkmark"></span>
                                            </label>


                                            <aside style={thumbsContainer}>
                                                {labelFrontThumbPre}
                                            </aside>
                                        </div>
                                    </div> : ""
                            }

                            {
                                (labelBackThumbPre.length) ?
                                    <div className="col-md-2">
                                        <div className="form-group Product-Label-Front ">
                                            <label className="brand-tab">Product Label Back </label>


                                            <label class="containercheck"><span className='visiblitypro'>Three</span>
                                                <input className={(complianceDetails?.[0]?.complianceSubmitted != 3) ? "chcekcontrol form-control" : "chcekcontrol form-control greenCheck"} type="checkbox" value={backCheck} checked={isBackChecked} onChange={handleBackCheck} disabled={(complianceDetails?.[0]?.complianceSubmitted == 3) ? true : false} disabled={isComplianceApproved} />
                                                <span class="checkmark"></span>
                                            </label>
                                            <aside style={thumbsContainer}>
                                                {labelBackThumbPre}
                                            </aside>
                                        </div>
                                    </div> : ""
                            }


                            {(dataState.length || dataStatePre.length) ? <div class="row uplaodViews">
                                <div class="col-md-6">
                                    <h4>Uploaded Documents</h4>

                                    <div className="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th width="170">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataState.map((_v, _i) => {
                                                    return [
                                                        <tr>
                                                            <td className="fileNamede">
                                                                <span>{_v?.name}</span>
                                                            </td>
                                                            <td className="twoAnchorBtns">
                                                                <Link to={_v?.preview} className="btn btn-secondary" target="_blank">Preview</Link>
                                                                <button className="btn btn-secondary" onClick={() => removeStateDoc(_i, _v?.fileID, _v?.name)} disabled={isComplianceApproved}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ]
                                                })}
                                                {dataStatePre.map((_v, _i) => {
                                                    return [
                                                        <tr>
                                                            <td>
                                                                {_v?.name}
                                                            </td>
                                                            <td className="twoAnchorBtns">
                                                                <Link to={_v?.preview} className="btn btn-secondary" target="_blank">Preview</Link>
                                                                <button className="btn btn-secondary" onClick={() => removeStateDocPre(_i, _v?.fileID, _v?.name)} disabled={isComplianceApproved}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ]
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : ""}

                        </div>



                        <div class="row hidden AlcoholicClass">


                        </div>

                        <di className="row hidden AlcoholicClass">
                            <div className="col-md-12">

                                <label style={{marginTop: "10px"}}>
                                    Already have a Florida label approval. IF box is checked there is no $35 state fee.
                                </label>
                                &nbsp;
                                <input id="hasStateLabel" value={hasStateLabel} onChange={handleHasStateLabel} style={{width:"50px"}} className="form-control pull-left" type="checkbox"></input>
                            </div>
                        </di>



                        <div className="row hidden AlcoholicClass">
                            <div className="col-md-12">
                                <div className="instructions">
                                    <h3>State of Florida Label Approval</h3>
                                    <p>Checked images will be submitted to the State for Approval. Once application is approved you will see Green check marks.
                                    </p>

                                    <p>If you do not want them submitted for approval, then uncheck.
                                    </p>

                                    <p>Note: State label renewal fee of $35 will be charged every June 20th for each item submitted. All labels expire yearly on June 30th.
                                    </p>

                                    <p><span className='underline'>By clicking Save & Submit you agree to a state Application fee of $35.00 per label and yearly renewals each year.</span>.
                                    </p>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-md-12">
                                <div className="formBtns">
                                    <button type="button" className="btn btn-primary save-button hidden AlcoholicClass" onClick={handleSubmit} disabled={isComplianceApproved}>Save & Submit</button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="formBtns">
                                    <button type="button" className="btn btn-primary save-button hidden NonAlcoholicClass" onClick={handleSubmit_NonAlcoholic} disabled={isComplianceApproved_nonalcholic}>Save & Submit</button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>




                    </div>
                </div>

            </div>
        </>
    )
}
