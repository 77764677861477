import ProductList from "../components/buyers/ProductList";
import ProductDetails from "../components/buyers/productDetail";
import Profile from "../components/buyers/Profile";
import WishList from "../components/buyers/WishList";
import EditProfile from "../components/buyers/editProfile";
import ChangePassword from "../components/buyers/changePassword";
import CartList from "../components/buyers/cartList";
import OrderList from "../components/buyers/OrderHistory";
import OrderDetail from "../components/buyers/OrderDetail";
import Dashboard from "../components/buyers/dashboard";
import OrderBySalesRep from "../components/buyers/OrderBySalesRep";
import BuyerOrderHistory from "../components/buyers/buyerOrderHistory";
import BuyerInvoiceList from "../components/buyers/BuyerInvoiceList";
import BuyerInvoiceDetails from "../components/buyers/BuyerInvoiceDetails";

const route = [
  { path: '/buyer/product-list', Component: ProductList },
  { path: '/buyer/product-detail/:productID', Component: ProductDetails },
  { path: '/buyer/profile', Component: Profile },
  { path: '/buyer/edit-profile', Component: EditProfile },
  { path: '/buyer/wishlist', Component: WishList },
  { path: '/buyer/change-password', Component: ChangePassword },
  { path: '/buyer/my-cart', Component: CartList },
  { path: '/buyer/order-list', Component: OrderList },
  { path: '/buyer/order-detail/:orderID', Component: OrderDetail },
  { path: '/buyer/dashboard', Component: Dashboard },
  { path: '/buyer/product-list/:brandID', Component: ProductList },
  { path: '/buyer/product-list-type/:productType', Component: ProductList },
  { path: '/buyer/ordersbysalesrep', Component: OrderBySalesRep },
  { path: '/buyer/order-history', Component: BuyerOrderHistory },
  { path: '/buyer/invoice-list', Component: BuyerInvoiceList },
  { path: '/buyer/invoice-details/:orderID', Component: BuyerInvoiceDetails },

  ]
export default route;