import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import SideBar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Logo from './../../assets/images/logo.png'
import Select from 'react-select';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function ProductList() {
	document.title = "BevPort - Product List"
	const [toggle, setToggle] = useState(false)
	const [productList, setProductList] = useState([])
	const [productListTemp, setProductListTemp] = useState([])
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const [canSizeList, setCanSizeList] = useState([]);
	const [packSizeList, setPackSizeList] = useState([]);
	const [CaseSizeList, setCaseSizeList] = useState([]);
	const [caseSizeValue, setCaseSizeValue] = useState("");
	const [packSizeValue, setPackSizeValue] = useState("");
	const [canSizeValue, setCanSizeValue] = useState("");
	const [pricerange, setPricerange] = useState(150000)
	const [sizeClick, setSizeClick] = useState(false);
	const [priceClick, setPriceClick] = useState(false);
	const [maxVal, setMaxVal] = useState(500);

	const [caseSizeValues, setCaseSizeValues] = useState("");
	const [canSizeValues, setCanSizeValues] = useState("");
	const [packSizeValues, setPackSizeValues] = useState("");

	const id = localStorage.getItem("id");
	const { brandID } = useParams();
	const { productID } = useParams();
	const { productType } = useParams();
	const [headingName, setHeadingName] = useState("Products");
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		if (typeof brandID !== "undefined") {
			getProductListByBrand();
		} else if (typeof productType !== "undefined") {
			if (productType === 'new-arival') {
				getNewArrivalProducts();
				setHeadingName("New Arrivals Products")
			}
			if (productType === 'special-deals') {
				getSpecialDealProducts();
				setHeadingName("Special Deals Products")
			}
			if (productType === 'monthly-special') {
				getMonthlySpecialProducts();
				setHeadingName("Monthly Special Products")
			}
		} else {
			getProductList();
		
		}
	}, [brandID, productType])
	useEffect(() => {
		getCanSize();
		getPackSize();
		getCaseSize();
	}, [])
	useEffect(() => {
		if (sizeClick || priceClick) {
			filterBySize()
		}
	}, [sizeClick, priceClick, pricerange])
	useEffect(() => {
		if (pricerange == 0) {
			setProductList([])
		}
	}, [pricerange])
	const getProductListByBrand = async () => {
		const formData = {
			BrandID: brandID,
			ID: id 
		}

		await axios.post(API_URL + "GetProductsByBrand?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const getNewArrivalProducts = async () => {
		
		await axios.post(API_URL + "GetNewArrivalinfo?code=" + API_KEY, {"ID":localStorage.getItem("id")}, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const getSpecialDealProducts = async () => {

		await axios.post(API_URL + "GetSpecialDealinfo?code=" + API_KEY, {"ID":localStorage.getItem("id")}, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}


	const getMonthlySpecialProducts = async () => {

		await axios.post(API_URL + "GetMonthlySpecialinfo?code=" + API_KEY, {"ID":localStorage.getItem("id")}, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const getProductList = async () => {
		const formData = {
			"ID": localStorage.getItem("id")
		}
		//setSubmitLoader(true);
		await axios.post(API_URL + "GetSalesRepProductlist?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				console.log(res.data?.responseData)
				// let newArr = [];
				// data.filter((item) => {
				// 	if(item?.status == 0)
				// 	newArr.push(item);
				// })
				setProductList(data)
				setProductListTemp(data)
			} else {
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const handleActiveInactiveStatus = async (productID, status) => {
		let formData = {
			"ID": productID,
			"Status": (status) ? 0 : 1
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeProductStatus?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				getProductList()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}
	const [searchText, setSearchText] = useState("")
	const handleSearchBox = (e) => {
		setSearchText(e.target.value);
		handleSearchInput(e.target.value);
	}
	function handleSearchClick(e) {
		e.preventDefault()
		if (searchText === "") { setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(searchText.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(searchText.toLowerCase()) || (item?.beerTypeVal).toLowerCase()
						.includes(searchText.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}

	function handleSearchInput(e) {
		if (e === "") { setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(e.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(e.toLowerCase()) || (item?.beerTypeVal).toLowerCase()
						.includes(e.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}

	const handleAlertBox = () => {
		toast.error("You don't have selling price. Please enter selling price before activating the product.")
	}

	const getCanSize = async () => {
		await axios.post(API_URL + "CanSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let canSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					canSizeOption.push({ value: da.value, label: da.value, id: da.id });
				}
				);
				setCanSizeList(canSizeOption);

			} else {

			}
		}).catch(function (error) {

		})
	}

	const getPackSize = async () => {
		await axios.post(API_URL + "PackageSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let packSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					packSizeOption.push({ value: da.value, label: da.value, id: da.id });
				}
				);
				setPackSizeList(packSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}

	const getCaseSize = async () => {
		await axios.post(API_URL + "CaseSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let caseSizeOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					caseSizeOption.push({ value: da.value, label: da.value, id: da.id });

				}
				);
				setCaseSizeList(caseSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}

	/*const handleCaseSize = (e) => {
		setSizeClick(true)
		setCaseSizeValue(e.value);
	}*/

	function handleCaseSize(e) {
      setSizeClick(true)
      setCaseSizeValue(e.value);
      setCaseSizeValues({value:e.value,label:e.label});
     }

	/*const handlePackSize = (e) => {
		setSizeClick(true)
		setPackSizeValue(e.value);
	}*/

    function handlePackSize(e) {
    setSizeClick(true)
    setPackSizeValue(e.value);
    setPackSizeValues({value:e.value,label:e.label});
    
   }

	/*const handleCanSize = (e) => {
		setSizeClick(true)
		setCanSizeValue(e.value);
	}*/

   function handleCanSize(e) {
	setSizeClick(true)
    setCanSizeValue(e.value);
    setCanSizeValues({value:e.value,label:e.label});
    }


	function filterBySize() {
		const filterProductsBySize = productListTemp.filter((item) => {
			if (((item?.productName).toLowerCase()
				.includes(packSizeValue.toLowerCase()) || (item?.packageSizeVal).toLowerCase()
					.includes(packSizeValue.toLowerCase()))
				&& ((item?.productName).toLowerCase()
					.includes(caseSizeValue.toLowerCase()) || (item?.caseSizeVal).toLowerCase()
						.includes(caseSizeValue.toLowerCase()))
				&& ((item?.productName).toLowerCase()
					.includes(canSizeValue.toLowerCase()) || (item?.canSizeVal).toLowerCase()
						.includes(canSizeValue.toLowerCase()))) { return item; }
		})
		if (priceClick) {
			const filterProductByPrice = filterProductsBySize.filter((item) => {
				if (item?.casePrice <= pricerange) {
					return item;
				}
			})
			setProductList(filterProductByPrice)
		}
		else {
			setProductList(filterProductsBySize);
		}
		setSizeClick(false);
		setPriceClick(false);
	}

	const handlePriceChange = (e) => {
		setMaxVal(e);
		setPriceClick(true)
		setPricerange(e);
	}

	const addToCart = async (productID) => {
		const formData = {
			"ID": localStorage.getItem("id"),
			"ProductID": productID,
			"Quantity": "1",
			"numvalue": "Increment"
		}
		setLoader(true);
		await axios.post(API_URL + "AddToUserCart?code=" + API_KEY, formData, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				await cartWishListCountFunc()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}

	//const addToFavourite = async (productID, status) => {
		//const formData = {
			//"ID": localStorage.getItem("id"),
			//"PRODUCTID": productID,
			//"FavStatus": status ? 0 : 1
		//}
		//setLoader(true);
		//await axios.post(API_URL + "ProductFavorite?code=" + API_KEY, formData, config).then(async (res) => {
			//if (res.data.responseCode === "200") {
				//toast.success(res.data.responseMessage)
				//await getProductList()
				//await cartWishListCountFunc()
			//} else {
				//toast.error(res.data.responseMessage)
			//}
			//setLoader(false);
		//}).catch(function (error) {
			//setLoader(false);
		//})
	//}

	const addToFavourite = async (productID, status) => {
		const formData = {
			"ID": localStorage.getItem("id"),
			"PRODUCTID": productID,
			"FavStatus": status ? 0 : 1
		}
		setLoader(true);
		await axios.post(API_URL + "ProductFavorite?code=" + API_KEY, formData, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				if(typeof brandID !== "undefined"){
					getProductListByBrand();
				}
				else if (typeof productType !== "undefined") {
			        if (productType === 'new-arival') {
				        // await getNewArrivalProducts();
				        setHeadingName("New Arrivals Products");
				        await cartWishListCountFunc();
			        }
			        if (productType === 'special-deals') {
				        // await getSpecialDealProducts();
				        setHeadingName("Special Deals Products");
				        await cartWishListCountFunc();
			        }
			        if (productType === 'monthly-special') {
				        // await getMonthlySpecialProducts();
				        setHeadingName("Monthly Special Products")
				        await cartWishListCountFunc();
			        }
		        }
		        else{
		        	// await getProductList()
				    await cartWishListCountFunc()
		        } 
				let prodList = productList.map((_v,_i) => {
					// let index = productList.findIndex((_v => _v?.id == productID));
						if (_v?.id == productID) {
							return { ..._v, favStatus: status ? 0 : 1 };
						}
						return _v;
				})
				setProductList(prodList);
				//await getProductList()
				//await cartWishListCountFunc()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}

	const [cartCount, setCartCount] = useState(0)
	const [wishListCount, setWishListCount] = useState(0)
	const cartWishListCountFunc = async () => {
		await axios.post(API_URL + "WishCartCount?code=" + API_KEY, { "ID": localStorage.getItem("id") }, config).then((res) => {
			if (res.data.responseCode === "200" && res.data.responseData.length) {
				let data = res.data.responseData[0];
				setCartCount(data?.cartCount)
				setWishListCount(data?.wishCount)
			}
		}).catch(function (error) {
		})
	}
	return (
		<>

			<div className="mainAdminWrapper">
				<SideBar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} cartCount={cartCount} wishListCount={wishListCount}/>
					<div className="rightContetBlock">

						<div className="headingTop">
							<h1>Products</h1>
						</div>
						<div className="blank150"></div>
						<div className="searchOntopPage">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-9 customPositionFilters">
										<div className="row">
											<div className="col-md-2">
												<Select className="selectTodesign"
													styles={customStyles}
													options={CaseSizeList}
													onChange={handleCaseSize}
													value={caseSizeValues}
													closeMenuOnSelect={true}
													placeholder={<div className="italic-placeholder">Case Size</div>}
												// isSearchable ={filterByCaseSize}
												/>
											</div>
											<div className="col-md-3">
												<Select className="selectTodesign"
													styles={customStyles}
													options={packSizeList}
													onChange={handlePackSize}
													value={packSizeValues}
													// closeMenuOnSelect={true}
													placeholder={<div className="italic-placeholder">Pack Size</div>}
												// isSearchable = {true}
												/>
											</div>
											<div className="col-md-3">
												<Select className="selectTodesign"
													styles={customStyles}
													options={canSizeList}
													onChange={handleCanSize}
													value={canSizeValues}
													// closeMenuOnSelect={true}
													placeholder={<div className="italic-placeholder">Can Size</div>}
													isSearchable
												/>
											</div>
											<div className="col-md-4">
												<div className="rangesliderDesign">
													<span className="prefiexval">$0</span>

													<InputRange
														maxValue={500}
														minValue={0}
														value={pricerange}
														step={1}
														onChange={handlePriceChange} />
													<span className="suffixVal">${maxVal}</span>
												</div>

											</div>
										</div>

									</div>

									<div className="col-md-3">
										<div className="searchBox">
											<form onSubmit={handleSearchClick}>
												<div className="input-group">
												<div className="input-group-btn">
														<button className="btn btn-default" type="submit"><i className="glyphicon glyphicon-search"></i></button>
													</div>
													<input type="text" className="form-control" placeholder="Search Products" onChange={handleSearchBox} />
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body productListingPage">
									<h3 className="card-heading"></h3>
									<div className="row">
										{
											productList.length ? productList.map((_v, i) => {
												let perCasePrice = 0;
												let u = (_v?.caseSizeVal).split(" ")
												if (_v?.unitPer === "unit") {
													perCasePrice = _v?.bevPortListingPrice * u[0]
												}
												if (_v?.unitPer === "pack") {
													let quantityPerPack = 0;
													if (_v?.packageSizeVal === "Singles") {
														quantityPerPack = 1
													} else {
														let u0 = (_v?.packageSizeVal).split(" ")
														quantityPerPack = u0[0]
													}
													perCasePrice = (_v?.bevPortListingPrice / quantityPerPack) * u[0]
												}
												return (
													<div className="col-md-3 mb-20">
														<Card sx={{ maxWidth: 345 }} className="product-list-component">
															{/* <Link to={"/brewer/product-detail/" + _v?.id}> */}
															<div className="stage" onClick={() => addToFavourite(_v?.id, _v?.favStatus)}>
																<i className={_v?.favStatus ? "fa fa-heart" : "fa fa-heart-o"}></i>
															</div>
															<Link to={"/salesrep/product-detail/" + _v?.id}>
																<CardMedia
																	component="img"
																	className="product-image"
																	alt={_v?.productName}
																	height="210"
																	image={(_v?.filePath) ? _v?.filePath : Logo}
																/>
															</Link>
															<CardContent>
																<Typography gutterBottom variant="h4" component="div">
																	{_v?.productName}
																</Typography>
																<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																	{_v?.brandName}
																</Typography>
																<Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px' }}>
																	{_v?.beerTypeVal}
																</Typography>
															</CardContent>
															<Divider />
															<CardContent className="ProductListCardContent">
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Can Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.canSizeVal}
																		</Typography>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Case Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.caseSizeVal}
																		</Typography>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Pack Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.packageSizeVal}
																		</Typography>
																	</div>
																</div>
																{
																	_v?.bevPortListingCasePrice ?
																		<div className="row mutliplePrices">
																			<div className="col-md-6">
																				<Typography gutterBottom variant="h6" component="div">
																					Case Price
																				</Typography>
																			</div>
																			<div className="col-md-6">
																				<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																					${parseFloat(_v?.bevPortListingCasePrice).toFixed(2)}
																				</Typography>

																			</div>
																		</div> : ""}
																{
																	_v?.bevPortListingPackPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingPackPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""}
																{
																	_v?.bevPortListingUnitPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Unit Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingUnitPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""
																}
															</CardContent>
															<CardActions className="cardactionBtns buttonWidth">
																<Link className="btn btn-primary" to={"#"} onClick={() => addToCart(_v?.id)}>Add To Cart</Link>

															</CardActions>
														</Card>
													</div>
												)
											}) :
												<>
													<div className="col-md-12">
														There are no records to display!
													</div>
												</>
										}

									</div>

								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}