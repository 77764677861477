import React, { useState, useEffect } from "react";
import InputMask from 'react-input-mask';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../../config/constant.js';
import Loader from "./../Loader"
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BusinessInfo({ handlePage, lastUserID, userType }) {
	const [bName, setBname] = useState("")
	const [bnameError, setbNameError] = useState(false)
	const [ein, setEin] = useState("")
	const [einError, setEinError] = useState(false)
	const [stn, setStn] = useState("")
	const [country, setCountry] = useState("")
	const [countryError, setCountryError] = useState(false)
	const [state, setState] = useState("")
	const [stateError, setStateError] = useState(false)
	const [city, setCity] = useState("")
	const [cityError, setCityError] = useState(false)
	const [street, setStreet] = useState("")
	const [streetError, setStreetError] = useState(false)
	const [zip, setZip] = useState("")
	const [zipError, setZipError] = useState(false)
	const [mobile, setMobile] = useState("")
	const [mobileError, setMobileError] = useState(false)
	const [altMobile, setAltMobile] = useState("")
	const [stateList, setStateList] = useState([])
	const [submitLoader, setSubmitLoader] = useState(false)
	const [check, setCheck] = useState("")
	const [checkError, setCheckError] = useState(false)
	const [altMobError, setaltMobError] = useState(false);
	const [disBtn, setDisBtn] = useState(false);
	useEffect(() => {
		getStateList()
	}, [])
	const navigate = useNavigate();
	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode === "200") {
				setStateList(res.data.responseData)
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	useEffect(() => {
		if (mobile != "" && altMobile != "") {
			checkAltNo();
		}

	}, [mobile, altMobile])

	const handleCheck = (e) => {
		setCheck(e.target.checked);
		setCheckError(false);
	}
	const handleBName = (e) => {
		setbNameError(false)
		setBname(e.target.value)
	}
	const handleEIN = (e) => {
		setEinError(false)
		setEin(e.target.value)
	}
	const handleSTN = (e) => {
		setStn(e.target.value)
	}
	const handleCountry = (e) => {
		setCountryError(false)
		setCountry(e.target.value)
	}
	const handleState = (e) => {
		setStateError(false)
		setState(e.target.value)
	}
	const handleCity = (e) => {
		setCityError(false)
		setCity(e.target.value)
	}
	const handleStreet = (e) => {
		setStreetError(false)
		setStreet(e.target.value)
	}
	const handleZIP = (e) => {
		setZipError(false)
		setZip(e.target.value)
	}
	const handleMobile = (e) => {
		setMobileError(false)
		setMobile(e.target.value)
	}
	const handleAltMobile = (e) => {
		setAltMobile(e.target.value)
	}
	const handleValidation = () => {
		let formIsValid = true;
		if (!bName) {
			formIsValid = false;
			setbNameError(true)
		}
		if (!ein) {
			formIsValid = false;
			setEinError(true)
		}
		if (!country) {
			formIsValid = false;
			setCountryError(true)
		}
		if (!state) {
			formIsValid = false;
			setStateError(true)
		}
		if (!city) {
			formIsValid = false;
			setCityError(true)
		}
		if (!street) {
			formIsValid = false;
			setStreetError(true)
		}
		if (!zip) {
			formIsValid = false;
			setZipError(true)
		}
		if (!mobile) {
			formIsValid = false;
			setMobileError(true)
		}
		if (userType == "2") {
			if (!check) {
				formIsValid = false;
				setCheckError(true);
			}
		}

		return formIsValid;
	}

	const checkAltNo = () => {
		if (mobile != "" && altMobile != "") {
			if (mobile == altMobile) {
				setaltMobError(true);
				setDisBtn(true);
			} else {
				setaltMobError(false);
				setDisBtn(false);
			}
		}
	}

	const handleBusinessInfo = async (e) => {
		e.preventDefault()
		if (handleValidation()) {
			setSubmitLoader(true)
			let formData = {
				"ID": lastUserID,
				"Type": "Business",
				"BusinessName": bName,
				"EIN": ein,
				"StateTaxNumber": stn,
				"StreetAddress": street,
				"BusinessPhone": mobile,
				"OptionalPhone": altMobile,
				"State": state,
				"Country": country,
				"City": city,
				"UserType": userType
			}
			await axios.post(API_URL + "UpdateUser?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					if (userType == 2) {
						toast.success(res.data.responseMessage);
						navigate('/login');
					} else {
						toast.success(res.data.responseMessage);
						handlePage(3, lastUserID)
					}

				} else {
					toast.error(res.data.responseMessage);
				}
				setSubmitLoader(false)
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
			})
		}
	}
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<form onSubmit={handleBusinessInfo} autoComplete="off">
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label for="firstname">Business Name <span className="red-tick">*</span></label>
							<input type="text" className={bnameError ? "form-control error" : "form-control"} value={bName} placeholder="Business Name" onChange={handleBName} />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label for="firstname">EIN <span className="red-tick">*</span></label>
							<InputMask
								mask='999-999999999'
								value={ein}
								onChange={handleEIN}
								className={einError ? "form-control error" : "form-control"}
								maskChar={null}
								placeholder="EIN"
							>
							</InputMask>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label for="email">State Tax Number</label>
							<InputMask
								mask='999-99-9999'
								value={stn}
								onChange={handleSTN}
								className="form-control"
								maskChar={null}
								placeholder="State Tax Number"
							>
							</InputMask>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">Country <span className="red-tick">*</span></label>
							<select className={countryError ? "form-control error" : "form-control"} onChange={handleCountry} value={country}>
								<option value="">Select Country</option>
								<option value="1">USA</option>
							</select>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">State <span className="red-tick">*</span></label>
							<select className={stateError ? "form-control error" : "form-control"} onChange={handleState} value={state}>
								<option value="">Select State</option>
								{
									stateList.length && stateList.map((_v, _i) => {
										return (
											<option key={_i} value={_v.id}>{_v.statename}</option>
										)
									})
								}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">City <span className="red-tick">*</span></label>
							<input type="text" className={cityError ? "form-control error" : "form-control"} value={city} placeholder="City" onChange={handleCity} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">Street <span className="red-tick">*</span></label>
							<input type="text" className={streetError ? "form-control error" : "form-control"} value={street} placeholder="Street" onChange={handleStreet} />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">ZIP Code <span className="red-tick">*</span></label>
							<InputMask
								mask='99999'
								value={zip}
								onChange={handleZIP}
								className={zipError ? "form-control error" : "form-control"}
								maskChar={null}
								placeholder="ZIP Code"
							>
							</InputMask>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">Mobile Number <span className="red-tick">*</span></label>
							<InputMask
								mask='(999) 999 9999'
								value={mobile}
								onChange={handleMobile}
								className={mobileError ? "form-control error" : "form-control"}
								maskChar={null}
								placeholder="Mobile Number"
							>
							</InputMask>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label for="cemail">Alternative Mobile Number</label>
							<InputMask
								mask='(999) 999 9999'
								value={altMobile}
								onChange={handleAltMobile}
								className={altMobError ? "form-control error" : "form-control"}
								maskChar={null}
								placeholder="Alternative Mobile Number"
							>
							</InputMask>
						</div>
					</div>
				</div>
				{
					userType == "2" ? <div className="row">
						<div className="col-md-12">
							<div className="termscheck">
								<div className="remebercheck">
									<input className="form-check-input" type="checkbox" id="form1Example3" onClick={handleCheck} />
									I agree with the <Link to="/termsofuse" target="_blank">Terms and Conditions</Link> and the <Link to="/privacy" target="_blank">Privacy Policy</Link>.
									{checkError ? <div className="error-message">Please accept our Terms and Conditions and Privacy Policy by checking the box before proceeding.</div> : ""}
								</div>
							</div>
						</div>
					</div> : ""
				}

				<div className="submitbtns">
					<button className="btn btn-default" type="submit" id="" disabled={disBtn}>NEXT</button>
				</div>

			</form>
		</>
	)
}