import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../../front/Loader";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function States() {
    document.title = "BevPort - Can Size"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([]);
    const [canSize, setCanSize] = useState("")
    const [canSizeError, setCanSizeError] = useState(false)
    const [canSizeID, setCanSizeID] = useState("")
    const [buttonName, setButtonName] = useState("Submit")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect ( () => {
        getCanSizeData();
    },[])

    const handleStatus = async (status, id) => {
        setLoader(true);
        let formData = {
            "ID":id,
            "Status":status
        }
        await axios.post(API_URL + "ChangeCanSizeStatus?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getCanSizeData();
                toast.success(res.data.responseMessage)
            }else{
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }


    const getCanSizeData = async () => {
        await axios.post(API_URL + "GetAllCanSizeData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setData(res.data.responseData)
                console.log("data", res.data.responseData);
                setSubmitLoader(false);
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    const columns = [
        {
            name: 'Can Size',
            selector: row => (row.value).toLowerCase(),
            cell: row => row.value,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == "0") ? <span style={{ "color": "#00aeef" }}>Inactive</span> : <span style={{ "color": "#00aeef" }}>Active</span>,
            sortable: true,
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "0") ? "1" : "0", row.id)}>{(row.status == "1") ? "DEACTIVATE" : "Activate"}</Link>&nbsp;<Link className="btn btn-primary" to={"#"} onClick={() => getCanSizeInfo(row.id)}>Edit</Link>&nbsp;</>,
        }
    ];
    const handleCanSize = (e) => {
        setCanSizeError(false)
        setCanSize(e.target.value)
    }
    const handleValidation = () => {
        let formValid = true
        if(!canSize){
            setCanSizeError(true)
            formValid = false
        }
        return formValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(handleValidation()){
            if(canSizeID){
                updateCanSizeRecord()
            }else{
                insertCanSizeRecord()
            }
        }
    }
    const insertCanSizeRecord = async () => {
        setLoader(true);
        let formData = {
            "Value":canSize,
            "Status":"1"
        }
        await axios.post(API_URL + "AddCanSizeData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getCanSizeData();
                toast.success(res.data.responseMessage)
                setCanSize("")
            }else{
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const getCanSizeInfo = async (id) => {
        setLoader(true);
        let formData = {
            "ID":id
        }
        await axios.post(API_URL + "GetCanSizeInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                window.scrollTo(0, 0)
                let data = res.data.responseData
                console.log("indfoData", data);
                setCanSize(data?.value)
                setButtonName("Update")
                setCanSizeID(data?.id)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const updateCanSizeRecord = async () => {
        setLoader(true);
        let formData = {
            "ID":canSizeID,
            "Value":canSize
        }
        await axios.post(API_URL + "UpdateCanSizeData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getCanSizeData();
                toast.success(res.data.responseMessage)
                setCanSize("")
                setButtonName("Submit")
                setCanSizeID("")
            }else{
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }

    const tableData = {
        columns,
        data
    };

    return (
        <>
           
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
				{submitLoader ? <Loader /> : ""}
				{loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Can Size</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className='wizardShowOnly'>
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <form className="form" onSubmit={handleSubmit}>

                                                <div className="form-group mx-sm-3 mb-2">
                                                    <label for="inputPassword2" >Can Size</label>
                                                    <input type="text" className={canSizeError?"form-control error":"form-control"} placeholder="Can Size" onChange={handleCanSize} value={canSize} />
                                                </div>
                                                <button type="submit" className="btn btn-primary mb-2">{buttonName}</button>
                                            </form>
                                        </div>

                                    </div>
                                    </div>
                                    <div className='tbaShowcase'>
                                    <div className="Content-body dataTableSearchBox">
                                            <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Can Size">
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                    //defaultSortFieldId={1}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}