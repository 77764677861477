
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Loader from "./Loader"
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
export default function PaymentForm({handleMemberPlan, memberShipPlan,  memberShipError, handlememberShipPlanErr, lastUserID }) {
    let mplan = memberShipPlan;
    
    const [planList, setPlanList] = useState([]);
    useEffect(() => {
        getPlanDetails()
    }, [])
    const getPlanDetails = async () => {
        await axios.post(API_URL + "GetPlans?code=" + API_KEY, { Type: process.env.REACT_APP_ENV }, config).then((res) => {
            if (res.data.responseCode === "200") {
                setPlanList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    let navigate = useNavigate();
    const [nameCard, setNameCard] = useState("")
    const [nameCardError, setNameCardError] = useState(false)
    const [cardNumberError, setCardNumberError] = useState(false)
    const [cardNumberErrorText, setCardNumberErrorText] = useState("")
    const [cardExpireError, setCardExpireError] = useState(false)
    const [cardExpireErrorText, setCardExpireErrorText] = useState("")
    const [cardCvvError, setCardCvvError] = useState(false)
    const [cardCvvErrorText, setCardCvvErrorText] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false)
    const [check, setCheck] = useState("")
    const [checkError, setCheckError] = useState(false);
    const handleNameCard = (e) => {
        const regex = /^[a-zA-Z ]*$/;
        const value = e.target.value;
        if (regex.test(value)) {
            setNameCard(value);
            setNameCardError(false);
        }

    }
    const stripeElementChange = (e) => {
        setCardNumberError(false)
        setCardNumberErrorText("")
        if (!e.complete) {
            setCardNumberError(true)
            setCardNumberErrorText(e?.error?.message)
        }
    }
    const stripeCvvChange = (e) => {
        setCardCvvError(false)
        setCardCvvErrorText("")
        if (!e.complete) {
            setCardCvvError(true)
            setCardCvvErrorText(e?.error?.message)
        }
    }
    const stripeExpireChange = (e) => {
        setCardExpireError(false)
        setCardExpireErrorText("")
        if (!e.complete) {
            setCardExpireError(true)
            setCardExpireErrorText(e?.error?.message)
        }
    }

    const handleCheck = (e) => {
        setCheck(e.target.checked);
        setCheckError(false);
    }
    const handleValidation = () => {
        let formIsValid = true;
        if (!nameCard) {
            formIsValid = false;
            setNameCardError(true)
        }
        if (!check) {
            formIsValid = false;
            setCheckError(true);
        }
        if(!memberShipPlan){
            formIsValid = false;
            handlememberShipPlanErr(0);
        }
        // const cardNumber = elements.getElement(CardNumberElement);
        // if(cardNumber?._implementation?._empty){
        //     setCardNumberError(true)
        // }
        // const cardExpire = elements.getElement(CardExpiryElement);
        // if(cardExpire?._implementation?._empty){
        //     setCardExpireError(true)
        // }
        // const cardCvv = elements.getElement(CardCvcElement);
        // if(cardCvv?._implementation?._empty){
        //     setCardCvvError(true)
        // }
        return formIsValid
    }
    const stripe = useStripe()
    const elements = useElements()
    const handleSubmit = async (e) => {
        console.log("mpp",mplan)
        e.preventDefault()
        if (handleValidation()) {
            /*const {error, paymentMethod} = await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement)
          })*/
            const card = elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement);
            const { error, token } = await stripe.createToken(card);
            console.log(error)
            if (!error) {
                
                let formData =

                {
                    "ID": localStorage.getItem("id"),
                    "TokenID": token?.id,
                    "PriceID": memberShipPlan
                }
                console.log(formData)
                setSubmitLoader(true)
                await axios.post(API_URL + "UpdateUserPayment?code=" + API_KEY, formData, config).then((res) => {
                    setSubmitLoader(false)
                    if (res.data.responseCode === "200") {
                        localStorage.removeItem('id');
                        localStorage.removeItem('userType');
                        localStorage.removeItem('isAuthentic');
                        localStorage.removeItem('brewerStatus');

                        toast.success(res.data.responseMessage);

                        navigate('/login');
                    } else {
                        toast.error(res.data.responseMessage);
                    }
                })
            } else {
                toast.error(error?.message)
            }
        }
    }
    return (
        <>
            {submitLoader ? <Loader /> : ""}

            {/* <div className="row">
                <div className="col-md-12">
                    <div className="form-headings">
                        <h2>Membership Plan and Payment</h2>
                        <span className="error-message">You won’t be charged until your license is approved and account is activated.</span>
                    </div>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <select className={memberShipError ? "form-control error" : "form-control"} onChange={handleMemberPlan}>
                            <option value="">Select Membership Plan</option>
                            {
                                planList.length && planList.map((_v, _i) => {
                                    return (
                                        <option value={_v?.priceID}>{`${_v?.planType} $${_v?.planAmount}`}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Card Number <span className="red-tick">*</span></label>
                        <CardNumberElement className={cardNumberError ? "form-control error" : "form-control"} onChange={stripeElementChange} />
                        {cardNumberError ? <span className="error-message">{cardNumberErrorText}</span> : ""}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Expiry Date <span className="red-tick">*</span></label>
                        <CardExpiryElement className={cardExpireError ? "form-control error" : "form-control"} onChange={stripeExpireChange} />
                        {cardExpireError ? <span className="error-message">{cardExpireErrorText}</span> : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group cvvTag">
                        <label for="firstname">CVC/CVV <span className="red-tick">*</span></label>
                        <CardCvcElement className={cardCvvError ? "form-control error" : "form-control"} onChange={stripeCvvChange} />
                        {cardCvvError ? <span className="error-message">{cardCvvErrorText}</span> : ""}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Name on Card <span className="red-tick">*</span></label>
                        <input type="text" className={nameCardError ? "form-control error" : "form-control"} placeholder="Name on Card" value={nameCard} onChange={handleNameCard} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group form-check">
                        <div className="remebercheck">
                            <input className="form-check-input" type="checkbox" id="form1Example3" onClick={handleCheck} />
                            I agree with the <Link to="/termsofuse" target="_blank">Terms and Conditions</Link> and the <Link to="/privacy" target="_blank">Privacy Policy</Link>.
                            {checkError ? <div className="error-message">Please accept our Terms and Conditions and Privacy Policy by checking the box before proceeding.</div> : ""}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="regitrationBtns">
                        <button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit}  >Submit</button>
                        <button type="submit" name="subxcmit" className="loginb resetFields">Reset</button>
                    </div>
                </div>
            </div>



        </>
    )
}