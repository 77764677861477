import React, { useState, useEffect } from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./../front/Loader";
import axios from 'axios';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};


toast.configure()
export default function EditInventory() {
    document.title = "BevPort - Add Buyer"
    const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [loader, setLoader] = useState(false)

    const [stateList, setStateList] = useState([])
    const [fname, setFname] = useState("");
    const [fnameError, setFnameError] = useState(false);
    const [lName, setLname] = useState("");
    const [lNameError, setLnameError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false)
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneError, setPhoneError] = useState(false)
    const [businessName, setBusinessName] = useState("");
    const [businessNameError, setBusinessNameError] = useState(false);
    const [countryValue, setCountryValue] = useState({ label: "USA", value: "1" });
    const [counrtyError, setCountryError] = useState(false);
    const [stateValue, setStateValue] = useState("");
    const [stateError, setStateError] = useState(false)
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false)
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState(false);
    const [userData, setUserData] = useState("");
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordSameError, setPasswordSameError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [passText, setPassText] = useState("")
    const [visible, SetVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [cPassword, setCPassword] = useState("")
    const [cPasswordError, setCPasswordError] = useState(false)
    const [ein, setEin] = useState("")
    const [einError, setEinError] = useState(false)
    const [stn, setStn] = useState("")
    const [altMobile, setAltMobile] = useState("")
    const [altMobError, setaltMobError] = useState(false);


    let Navigate = useNavigate();


    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const countryOption = [
        { label: "USA", value: "1" },

    ];

    

    useEffect(() => {

        getStateList();

    }, [])





    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode === "200") {
                let stateOption = [];
                let resData = res.data.responseData
                resData.forEach((da) => {

                    stateOption.push({ value: da.statename, label: da.statename, id: da.id });

                }
                );

                setStateList(stateOption)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleFirstName = (e) => {
        setFname(e.target.value);
        setFnameError(false);
    }

    const handleLastName = (e) => {
        setLname(e.target.value)
        setLnameError(false)
    }

    const handlePhoneNo = (e) => {
        setPhoneNo(e.target.value)
        setPhoneError(false);
    }
    const handleAltMobile = (e) => {
        setAltMobile(e.target.value)
    }

    const handleBusinessName = (e) => {
        setBusinessName(e.target.value);
        setBusinessNameError(false);
    }

    const handleCountry = (e) => {
        setCountryValue({ value: e.value, label: e.label })
        setCountryError(false);

    }

    const handleState = (e) => {
        setStateValue({ value: e.value, label: e.label, id: e.id })
        setStateError(false)
    }



    const handleCity = (e) => {
        setCity(e.target.value);
        setCityError(false)
    }

    const handleStreetAddress = (e) => {
        setStreet(e.target.value)
        setStreetError(false);
    }

    const handleZIP = (e) => {
        setZip(e.target.value);
        setZipError(false);

    }

    const handlePassword = (e) => {
        setPassError(false)
        setPassText("")
        setPasswordError(false)
        setPassword(e.target.value)
    }
    const handleConfirmPassword = (e) => {
        setPasswordSameError(false)
        setCPasswordError(false)
        setCPassword(e.target.value)
    }

    const handleEIN = (e) => {
        setEinError(false)
        setEin(e.target.value)
    }

    const handleSTN = (e) => {
        setStn(e.target.value)
    }






    const handleShow = () => {
        SetVisible(!visible);
    }

    const handleConfirmShow = () => {
		setConfirmVisible(!confirmVisible)
	}

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function checkPwd(str) {
        if (str.length < 6) {
            setPassError(true)
            setPassText("Must have at least 6 characters.")
            return false;
        } else if (str.search(/\d/) == -1) {
            setPassError(true)
            setPassText("Must have at least one number.")
            return false;
        } else if (str.search(/[A-Z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one capital letter.")
            return false;
        } else if (str.search(/[a-z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one small letter.")
            return false;
        } else if (str.search(/[!@#$%^&*()]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one symbol.")
            return false;
        }
        return true;
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
        setEmailError(false)
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!fname) {
            formIsValid = false;
            setFnameError(true)
        }
        if (!lName) {
            formIsValid = false;
            setLnameError(true)
        }
        if (!phoneNo) {
            formIsValid = false;
            setPhoneError(true)
        }
        if (!businessName) {
            formIsValid = false;
            setBusinessNameError(true)
        }
        if (!countryValue) {
            formIsValid = false;
            setCountryError(true)
        }
        if (!city) {
            formIsValid = false;
            setCityError(true)
        }
        if (!stateValue) {
            formIsValid = false;
            setStateError(true)
        }
        if (!street) {
            formIsValid = false;
            setStreetError(true)
        }
        if (!email) {
            formIsValid = false;
            setEmailError(true)
        }
        if (!validateEmail(email)) {
            formIsValid = false;
            setEmailError(true)
        }
        if (!password) {
            formIsValid = false;
            setPasswordError(true)
        }
        if (password && !checkPwd(password)) {
            formIsValid = false;
            setPasswordError(true)
        }
        if (!cPassword) {
            formIsValid = false;
            setCPasswordError(true)
        }
        if (cPassword && password !== cPassword) {
            formIsValid = false;
            setPasswordSameError(true)
        }
        if (!zip) {
            formIsValid = false;
            setZipError(true)
        }
        if (!ein) {
            formIsValid = false;
            setEinError(true)
        }
        if (altMobile) {
            if (phoneNo == altMobile) {
                setaltMobError(true);

            } else {
                setaltMobError(false);

            }
        }
        return formIsValid;
    }





    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setLoader(true)
            let formData = {
                "FirstName":fname,
                "LastName":lName,
                "emailid":email,
                //"PhoneNumber":"9532675321",
                "Password":password,
                "UserType":"2",
                "BusinessName":businessName,
                "EIN":ein,
                "StateTaxNumber":stn,
                "StreetAddress":street,
                "BusinessPhone":phoneNo,
                "OptionalPhone":altMobile,
                "State":stateValue?.id,
                "Country":"1",
                "City":city,
                "ZipCode": zip
            }
           
             await axios.post(API_URL + "AddUserByAdmin?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/buyer-list");
                } else {
                    toast.error(res.data.responseMessage);
                }
                setLoader(false)

                //setBtnDisable(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
                //setBtnDisable(false)
            })
        }
    }

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Add Buyer</h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">First Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={fnameError ? "form-control error" : "form-control"} placeholder="Enter First Name" value={fname} onChange={handleFirstName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Last Name<span className="red-tick"> *</span></label>
                                                    <input type="text" className={lNameError ? "form-control error" : "form-control"} placeholder="Enter Last Name" value={lName} onChange={handleLastName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Email<span className="red-tick"> *</span></label>

                                                    <input type="text" className={emailError ? "form-control error" : "form-control"} placeholder="Enter Email ID" value={email} onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Password<span className="red-tick"> *</span></label>
                                                    <div className="showhidepassowrd">
                                                        <input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} value={password} placeholder="Enter Password" onChange={handlePassword} />
                                                        {passError ? <span className="error-message">{passText}</span> : ""}
                                                        <span className="eyeslash" onClick={handleShow} > {!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">Confirm Password <span className="red-tick">*</span></label>
                                                    <div className="showhidepassowrd">
                                                    <input type={confirmVisible ? "text" : "password"} className={cPasswordError ? "form-control error" : "form-control"} value={cPassword} placeholder="Confirm Password" onChange={handleConfirmPassword} />
                                                    {passwordSameError ? <span className="error-message">Confirm password should be same as password.</span> : ""}
                                                    <span className="eyeslash" onClick={handleConfirmShow} > {!confirmVisible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Business Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={businessNameError ? "form-control error" : "form-control"} placeholder="Enter Business Name" value={businessName} onChange={handleBusinessName} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">Employer Identification Number <span className="red-tick">*</span></label>
                                                    <InputMask
                                                        mask='999-999999999'
                                                        value={ein}
                                                        onChange={handleEIN}
                                                        className={einError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="EIN"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">State Tax Number</label>
                                                    <InputMask
                                                        mask='999-99-9999'
                                                        value={stn}
                                                        onChange={handleSTN}
                                                        className="form-control"
                                                        maskChar={null}
                                                        placeholder="If Different From EIN"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Country <span className="red-tick"> *</span></label>
                                                    <Select className={counrtyError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={countryOption}
                                                        onChange={handleCountry}
                                                        value={countryValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Select Country</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">State<span className="red-tick"> *</span></label>
                                                    <Select className={stateError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={stateList}
                                                        onChange={handleState}
                                                        value={stateValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Select State</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">City <span className="red-tick"> *</span></label>
                                                    <input type="text" className={cityError ? "form-control error" : "form-control"} placeholder="City" value={city} onChange={handleCity} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Street Address<span className="red-tick"> *</span></label>

                                                    <input type="text" className={streetError ? "form-control error" : "form-control"} placeholder="Enter Street Address" value={street} onChange={handleStreetAddress} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">ZIP Code<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='99999'
                                                        value={zip}
                                                        onChange={handleZIP}
                                                        className={zipError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="ZIP Code"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Mobile Number<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='(999) 999 9999'
                                                        value={phoneNo}
                                                        onChange={handlePhoneNo}
                                                        className={phoneError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="Mobile Number"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">Alternative Mobile Number (optional)</label>
                                                    <InputMask
                                                        mask='(999) 999 9999'
                                                        value={altMobile}
                                                        onChange={handleAltMobile}
                                                        className={altMobError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="Alternative Mobile Number"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}