import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loader from "./../front/Loader";
import TeamMemberSideBar from "./TeamMemberSideBar";
import TeamMemberHeader from "./TeamMemberHeader";
import TeamMemberFooter from "./TeamMemberFooter";
import MyProfilePic from './../../assets/images/beernabs.png'
import Canareas from './../../assets/images/cansareas.png'
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
export default function Dashboard(){
	document.title = "BevPort - Dashboard"
	const [toggle, setToggle] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(false)
	const [loader, setLoader] = useState(false)
	const [isCompliance, setIsCompliance] = useState()
	let Navigate = useNavigate();
	const collapseSidebar = () =>{
		setToggle(!toggle)
	}
	useEffect(() => {
		getDashboardData()
	},[])
	
	const [orderList, setOrderList] = useState([])
	const [buyerList, setBuyerList] = useState([])
	const [sellState, setSellState] = useState("")
	const getDashboardData = async () => {
        let formData = {
             "ID":localStorage.getItem("createdBy")
        }
        await axios.post(API_URL + "GetBrewerDashboardData?code=" + API_KEY, formData, config).then((res) => {
            if(res.data?.responseCode === "200"){
                /*setBrewerList(res.data?.responseDataMostSoldProducts)
                setWhisList(res.data?.responseDataWishList)
                setOrderList(res.data?.responseDataOrder)
                setNewArrival(res.data?.buyerDashboardNewArrivals)*/
                setOrderList(res.data?.responseDataOrder)
                setBuyerList(res.data?.brewerDashboardBuyerlist)
                let graphData = res?.data?.brewerDashboardGraphData;
                //console.log(graphData)
                let graphArray = [];
                let monthArray = [1,2,3,4,5,6,7,8]
                monthArray.forEach((x,y) => {
                	let dataRes = graphData.filter((c,s) => {
                		if(c?.month === x){
                			return c;
                		}
                	})
                	if(dataRes.length){
                		graphArray.push(dataRes[0]?.total_Sales)
                	}else{
                		graphArray.push(0)
                	}
                })
                //setSellState(res?.data?.brewerDashboardGraphData)
                setSellState(graphArray)
            }
            
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const data = {
	  labels,
	  datasets: [
	    {
	      label: 'Sell',
	      data: sellState,
	      backgroundColor: '#65b12d',
	    }
	  ],
	};
	return(
		<>
			<div className="mainAdminWrapper brewersDashArea">
				<TeamMemberSideBar collapse={toggle}/>
				<div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
				{submitLoader ? <Loader /> : ""}
				{loader ? <Loader /> : ""}
					<TeamMemberHeader collapseSidebar={collapseSidebar}/>
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>TeamMembers Dashboard</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<div className="brewerDashboardInner">
										<div className="row">
											<div className="col-md-12">
												<h2 className="dashboardMainbuyer">Craft Your Beer Brand’s <span>Success on BevPort!</span></h2>
											</div>
										</div>
										<div className="row MainBoxSIde">
											<div className="col-md-9">
												<div className="row threeMainBoxe">
													<div className="col-md-4">
														<h3 className="yourOrderheadings">MY SALES</h3>
														<div className="productFoourCard">
															<Bar options={options} data={data} />
														</div>
														<p className="cardParas">
															An overview of your sales performance. Analyze sales trends to gain valuable insights for business growth and optimization.
														</p>
													</div>
													<div className="col-md-4">
														<h3 className="yourOrderheadings">INVENTORY</h3>
														<div className="productFoourCard InventoryCard">
															<Link to="/team-member/inventory">YOUR<br />INVENTORY</Link>
															<Link to="#">PLACE DELIVERY ORDER</Link>
															<Link to="#">PRODUCT DELIVERY LIVE VIEW</Link>
														</div>
														<p className="cardParas">
															The hub for inventory management, delivery scheduling, and real-time tracking of your products during transit, to enhance operational efficiency and customer communication.
														</p>
													</div>
													<div className="col-md-4">
														<h3 className="yourOrderheadings">ORDERS</h3>
														<div className="orderDetialsListing">
															<ul>
																{
                                                                    orderList.map((_value, _index) => {
                                                                        if(_index < 7){
                                                                            return(
                                                                                <li><Link to={"/team-member/order-detail/"+_value?.orderID} target="_blank"><span className="datesOrder">{moment(_value?.createdDate).format("MM-DD-YY")}</span><span className="orderIdOrder">#{((_value?.orderID).replace("BEVPORT", "")).substr(0, 9)}</span><span className="orderDallrPrice">${parseFloat(_value?.amount).toFixed(2)}</span></Link></li>
                                                                            )
                                                                        }
                                                                        
                                                                    })
                                                                }
																
															</ul>
															<div className="orderHisBtn">
																<Link to="/team-member/order-history">VIEW ALL ORDERS</Link>
															</div>
														</div>
														<p className="cardParas">
															Your organized list of all customer orders. It provides a clear view of what’s been ordered, enabling efficient order processing and management.
														</p>

													</div>

												</div>

												<div className="row threeMainBoxe btmpostions">
													<div className="col-md-4">
														<h3 className="yourOrderheadings">MY PROFILE</h3>
														<div className="productFoourCard profileimgBlocks">
															<div className="priflePicdata">
																<img src={MyProfilePic} />
															</div>
														</div>
														<p className="cardParas">
															View and edit your personal details, adjust account settings,and manage team member roles. Ensure accuracy to guarantee efficient team operations.
														</p>
													</div>
													<div className="col-md-4">
														<h3 className="yourOrderheadings">MY PRODUCTS</h3>
														<div className="productFoourCard profileimgBlocks">
															<div className="priflePicdata">
																<img src={Canareas} />
															</div>
														</div>
														<p className="cardParas">
															Update product details, add new offerings, or insert additional collateral. Detailed product information assists buyers in making informed decisions.
														</p>
													</div>
													<div className="col-md-4">
														<h3 className="yourOrderheadings">BUYERS</h3>
														<div className="orderDetialsListing">
															<ul>
																{
																	buyerList.map((_v,_o) => {
																		return(
																			<li>{_o+1}. {_v?.firstName+' '+_v?.lastName} {(_v?.state)?", "+_v?.state:""}</li>
																		)	
																	})
																}
															</ul>
															<div className="orderHisBtn">
																<Link to="/team-member/mybuyers">VIEW ALL BUYERS</Link>
															</div>
														</div>
														<p className="cardParas">
															Your comprehensive list of buyers, providing a quick overview of your customer base and allowing for effective relationship management.
														</p>

													</div>
													
												</div>
											</div>
											<div className="col-md-3 fiexedRightCols">
												<h3 className="yourOrderheadings">MY TASKS</h3>
												<div className="productFoourCard Fixedright">
													<ul>
														<li className="active"><span className="liTExtdata">Complete User Profile</span></li>
														<li><span className="liTExtdata">Add Company Logo</span></li>
														<li className="active"><span className="liTExtdata">Add New Products</span></li>
														<li><span className="liTExtdata">Add New Categories</span></li>
														<li><span className="liTExtdata">Update Inventory</span></li>
														<li><span className="liTExtdata">Add Collateral Pdf</span></li>
														<li><span className="liTExtdata">Low Inventory Alert: 10 Cases Left Of Ale</span></li>
													</ul>

												</div>
											</div>
										</div>









									</div>

								</div>
							</div>
						</div>
					</div>
					<TeamMemberFooter />
				</div>
			</div>
		</>
	)
}