import React, { useEffect, useState, useCallback } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone'
import { useNavigate } from "react-router-dom";
import Loader from "./../../front/Loader.js";
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_PRODUCT_CONTAINER } from './../../../config/constant';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from './../blob';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()


export default function ProductDesc({ productID, brandID, productData, handleActiveTab }) {

	const thumbsContainer = {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16
	};

	const thumbInner = {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden'
	};

	const img = {
		display: 'block',
		width: 'auto',
		height: '100%'
	};
	const iamgeFormats = {
		'image/*': []
	};
	const [productImages, setProductImages] = useState([])
	const [productImagesError, setProductImagesError] = useState(false);
	const [productdis, setProductDis] = useState("");
	const [productdisError, setProductDisError] = useState(false);
	const [ontheNose, setOnTheNose] = useState("");
	const [testingNotes, setTestingNotes] = useState("");
	const [brewersNotes, setBrewersNotes] = useState("");
	const [Keywords, setKeywords] = useState([]);
	const [awards, setAwards] = useState("");
	const [submitLoader, setSubmitLoader] = useState(true)
	const [btnDisable, setBtnDisable] = useState(false)
	const navigate = useNavigate();
	const [productImagesPre, setProductImagesPre] = useState([])
	const [keyWordOption, setKeywordsOption] = useState([]);
	const [keyValue, setKeyValue] = useState([]);
	const [preSelectValue, setPreSelectValue] = useState([]);


	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};

	const onDropFunction = (acceptedFiles, fileType) => {
		const renamedAcceptedFiles = acceptedFiles.map((file) => (
			new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${productID}_Product_Image_${file.name}`, { type: file.type })
		))
		let imgData = [...productImages, renamedAcceptedFiles.map(file => Object.assign(file, {
			preview: URL.createObjectURL(file),
			imageType: fileType
		}))]
		let tmpImgData = [].concat(...imgData)
		setProductImages(tmpImgData);
		setProductImagesError(false);
	}
	const removeFiles = (index, type) => {
		if (type === "productImages") {
			const newFiles = [...productImages];
			newFiles.splice(index, 1);
			setProductImages(newFiles)

		}
	}
	const removeFilesPre = (index, type, fileID, fileName) => {
		if (typeof fileID !== "undefined") {
			deleteFiles(fileID, fileName)
		}
		if (type === "productImages") {
			const newFiles = [...productImagesPre];
			newFiles.splice(index, 1);
			setProductImagesPre(newFiles)

		}
	}
	const deleteFiles = async (fileID, fileName) => {
		await axios.post(API_URL + "DeleteFileInfo?code=" + API_KEY, { "ID": fileID }, config).then(async (res) => {
			await deleteBlobIfItExists(fileName, BLOB_PRODUCT_CONTAINER)
		}).catch(function (error) {
			console.log(error)
		})
	}
	const brandLogoThumb = productImages.map((file, i) => (
		<div className="mainThumbnail" key={file.name}>
			<span className="deleteThis" onClick={() => removeFiles(i, "productImages")}><i className="fa fa-times"></i></span>
			<div style={thumbInner}>
				<img
					src={file.preview}
					style={img}
					onLoad={() => { URL.revokeObjectURL(file.preview) }}
				/>
			</div>
		</div>
	));
	const brandLogoThumbPre = productImagesPre.map((file, i) => {
		let fileName = file?.filename;
		return (
			<div className="mainThumbnail" key={file.name}>
				<span className="deleteThis" onClick={() => removeFilesPre(i, "productImages", file?.id, fileName)}><i className="fa fa-times"></i></span>
				<div style={thumbInner}>
					<img
						src={file.filepath}
						style={img}
						onLoad={() => { URL.revokeObjectURL(file.filepath) }}
					/>
				</div>
			</div>
		)
	});
	const handleProductDiscription = (e) => {
		setProductDis(e.target.value);
		setProductDisError(false);

	}

	const handleOnTheNose = (e) => {
		setOnTheNose(e.target.value);

	}

	const handleTestingNotes = (e) => {
		setTestingNotes(e.target.value);

	}

	const handleBrewersNotes = (e) => {
		setBrewersNotes(e.target.value);

	}

	const handleKeywords = (e) => {
		let data = [];
		let data1 = [];
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})
		setPreSelectValue(data);
		setKeywords(data);
		setKeyValue(data1);

	}

	const handleAwards = (e) => {
		setAwards(e.target.value);
	}



	const handleValidation = () => {
		let formIsValid = true;
		if (!productdis) {
			formIsValid = false;
			setProductDisError(true);
		}
		/*if (productData) {
			if (!productImagesPre.length) {
				formIsValid = false;
				setProductImagesError(true);
			}
		} else {
			if (!productImages.length) {
				formIsValid = false;
				setProductImagesError(true);
			}
		}*/
		if (!productImages.length && !productImagesPre.length) {
			formIsValid = false;
			setProductImagesError(true);
		}

		return formIsValid;

	}

	const handleSubmit = async (e) => {

		if (handleValidation()) {
			//setSubmitLoader(true)
			//setBtnDisable(true)
			let formData = {
				"ID": (productData) ? productData?.id : productID,
				"Description": productdis,
				"OnTheNose": ontheNose,
				"TastingNotes": testingNotes,
				"BrewersNotes": brewersNotes,
				"KeyWords": Keywords.join(', '),
				"AwardsRecog": awards

			}
			let finalArray = [];
			productImages.forEach((_v, _i) => {
				let d = {
					"UserID": localStorage.getItem("id"),
					"BRANDID": brandID,
					"PRODUCTID": (productData) ? productData?.id : productID,
					"FILETYPE": _v?.imageType,
					"FILEPATH": BLOB_URL + BLOB_PRODUCT_CONTAINER + '/' + _v?.name,
					"FILEDATA": "",
					"FILENAME": _v?.name
				}
				finalArray.push(d)
			})
			setSubmitLoader(true);
			setBtnDisable(true);
			if (productImages.length) {
				const blobsInContainer: string[] = await uploadFileToBlob(productImages, BLOB_PRODUCT_CONTAINER).then(async res => {
					await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {

					}).catch(function (error) { });
				});
			}
			await axios.post(API_URL + "AddProductDescription?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
					handleActiveTab("compliance")

				} else {
					toast.error(res.data.responseMessage)
				}
				setSubmitLoader(false);
				setBtnDisable(false);
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
				setBtnDisable(false)
			})
		}
	}


	useEffect(() => {

		if (productData) {
			
			setProductDis(productData?.description)
			setOnTheNose(productData?.onTheNose)
			setTestingNotes(productData?.tastingNotes)
			setBrewersNotes(productData?.brewersNotes)
			setAwards(productData?.awardsRecog)
			if (typeof productData?.blobFiles !== "undefined") {
				let productImage = productData?.blobFiles;
				let filtered = productImage.filter((v) => {
					return v?.filetype === "ProductImage"
				})
				setProductImagesPre(filtered)
			}

			if (productData?.keyWords != "") {

				let keywordDataOption = [];

				let data = productData?.keyWords.split(', ');
				let tempJsonArray = [];
				data.length && data.forEach((_v, _i) => {
					tempJsonArray.push({ value: _v, label: _v })
				})
				setKeyValue(tempJsonArray)
				setKeywords(data);
			} else {
				//setKeyValue([])

			}

		} else {
			setProductDis("")
			setOnTheNose("")
			setTestingNotes("")
			setBrewersNotes("")
			setAwards()
			setProductImagesPre([])
			//setKeyValue([])
			//setKeywords([]);
			handleActiveTab("packageInfo")
		}
	}, [productData])
	useEffect(() => {
		console.log(keyValue, productData, productID)
	}, [keyValue, productData, productID])
	useEffect(() => {
		if (brandID !== 0) {
			getBrandKeyWords(brandID)
		}
	}, [brandID])
	const getBrandKeyWords = async (brandID) => {
		await axios.post(API_URL + "BrandKeywords?code=" + API_KEY, { "ID": brandID }, config).then((res) => {
			if (res.data.responseCode === "200" && res.data.responseData.length) {
				let data = res.data.responseData[0].keywords.split(', ');
				console.log("datataaa", data?.[0] !== "");
				if (data?.[0] !== "") {
					let tempJsonArray = [];
					data.length && data.forEach((_v, _i) => {
						tempJsonArray.push({ value: _v, label: _v })
					})
					setKeyValue(tempJsonArray)
					setKeywords(data);
				}

				/*if(res.data.responseData[0].keywords != ""){
					let keywordDataOption = [];
				
				let data = res.data.responseData[0].keywords.split(', ');
				data.forEach((da) => {
					
					keywordDataOption.push({ value: da, label: da });

				}
				);
				setPreSelectValue(data);
				setKeyValue(keywordDataOption)
				setKeywords(keywordDataOption);
				}*/


			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	useEffect(() => {
		handleKeywordsData();
	}, [])

	const handleKeywordsData = async () => {
		setSubmitLoader(true)
		await axios.post(API_URL + "GetKeyWords?code=" + API_KEY, {}, config).then((res) => {

			let keywordDataOption = [];
			if (res.data.responseCode === "200") {

				let data = res.data.responseData
				data.forEach((da) => {
					keywordDataOption.push({ value: da.name, label: da.name });

				}
				);

				setKeywordsOption(keywordDataOption);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="InsertForms">
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Product Description <span className="red-tick"> *</span></label>
							<textarea className={productdisError ? "form-control error" : "form-control"} rows="4" placeholder='Product Description' onChange={handleProductDiscription} value={productdis} ></textarea>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">On the Nose</label>
							<textarea className="form-control" rows="4" placeholder='On the Nose' onChange={handleOnTheNose} value={ontheNose}></textarea>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Tasting Notes</label>
							<textarea className="form-control" rows="4" placeholder='Tasting Notes' onChange={handleTestingNotes} value={testingNotes} ></textarea>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Brewers Notes</label>
							<textarea className="form-control" rows="4" placeholder='Brewers Notes' onChange={handleBrewersNotes} value={brewersNotes} ></textarea>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Keywords</label>
							{/* <textarea className="form-control" rows="4" placeholder='Keywords'  onChange={handleKeywords} value={Keywords}></textarea> */}
							<CreatableSelect
								isMulti
								className="selectTodesign"
								options={keyWordOption}
								onChange={handleKeywords}
								styles={customStyles}
								value={keyValue}
								closeMenuOnSelect={false}
								placeholder={<div className="italic-placeholder">Select...</div>}

								isSearchable
							/>

						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="brand-tab">Awards and Recognition</label>
							<textarea className="form-control" rows="4" placeholder='Awards and Recognition' onChange={handleAwards} value={awards}></textarea>
						</div>
					</div>
				</div>
				<div className="LogosUplaods">
					<div className="row">
						<div className="col-md-6">
							<h3 className="card-heading">Product Photos <span className="red-tick"> *</span></h3>
							<p className="cardSubtext">Display your picture on your product page for buyers to easily identify your products. Preferably JPG and must be a square pic. Formats accepted: ( JPEG , JPG , PNG , JFIF ).</p>
						</div>
						<div className="col-md-4">
							<Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "ProductImage")} multiple={true} accept={iamgeFormats} >
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps({ className: (productImagesError) ? 'dropzone custom-input-drop-brewer error' : 'dropzone custom-input-drop-brewer' })}>
										<input {...getInputProps()} />
										<p>Drop Product Photos Here or Click to Upload.
											<br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
										</p>
									</div>
								)}
							</Dropzone>


						</div>

						<div class="col-md-2">
							<div className="thumbanilOptions">
								<aside style={thumbsContainer}>
									{brandLogoThumbPre}
									{brandLogoThumb}
								</aside>
							</div>
						</div>
					</div>


				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="formBtns threeSubSets">
							<button type="button" className="btn btn-primary save-button" onClick={handleSubmit} disabled={btnDisable} >{productData ? "Save" : "Save"}</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}