
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import LoginBGSide from "./../../assets/images/loginSide.jpg"
import privacybg from "./../../assets/images/privacy-page.jpg"
import LoginLeaves from "./../../assets/images/loginLeaves.png"
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Loader from "./Loader"
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
export default function Resource() {
	document.title = "BevPort - Login"
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
		if (script) {
			script.remove();
		}
		const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
		if (recaptchaElems.length) {
			recaptchaElems[0].remove();
		}
	}, [])
	const navigate = useNavigate();
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [password, setPassword] = useState("")
	const [passwordError, setPasswordError] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(false)
	const [visible, SetVisible] = useState(false);
	const [btnDisable, setBtnDisable] = useState(false);
	const [roleName, setRoleName] = useState();

	const [activityState, setActivityState] = useState("");
	const handleEmail = (e) => {
		setEmail(e.target.value);
		setEmailError(false);
	}

	const handlePassword = (e) => {
		setPassword(e.target.value);
		setPasswordError(false);
	}

	const handleShow = () => {
		SetVisible(!visible);
	}

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	const handleValidation = () => {
		let formIsValid = true;

		if (!email) {
			formIsValid = false;
			setEmailError(true)
		}
		if (!validateEmail(email)) {
			formIsValid = false;
			setEmailError(true)
		}

		if (!password) {
			formIsValid = false;
			setPasswordError(true)
		}
		return formIsValid;
	}
	const [remember, setRemember] = useState(false)
	const handleRemember = (e) => {
		setRemember(e.target.checked)
	}


	const setProductID_AvtivityState = async (param_UserID) => {
		const formData = {
			"UserID": param_UserID
		}

		await axios.post(API_URL + "GetProductDetailsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;


				localStorage.setItem("productID", data[0].id);

			} else {


			}

		}).catch(function (error) {

		})


	
	}

	useEffect(() => {
		let email = localStorage.getItem("email")
		if (email) {
			setEmail(email)
		}
		let password = localStorage.getItem("password")
		if (password) {
			setPassword(password)
		}
	}, [])
	useEffect(() => {
		let id = localStorage.getItem("id");
		let isAuthentic = localStorage.getItem("isAuthentic")
		if (id && isAuthentic === "true") {
			let userType = localStorage.getItem("userType")
			if (userType == "1" || userType == "4") {
				if (localStorage.getItem("activityState") == 0) {
					navigate("/brewer/register/firststep");
				} else {
					navigate("/brewer/dashboard")
				}
			} else if (userType == "2") {
				navigate("/buyer/dashboard")
			} else if (userType == "3") {
				navigate("/salesrep/dashboard");
			}  
			// else if (userType == "4") {
			// 	navigate("/brewer/dashboard");
			// }
			// else if (roleName == "SalesREP" || roleName == "Administrator" || roleName == "Staff") {
			// 	navigate("/team-member/dashboard")
			// }
		}
	}, [roleName, activityState])
	const getBrandDataByID = async (userID, userType) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				localStorage.setItem("businessName", data?.businessName);
				localStorage.setItem("userStateName", data?.stateName); // Jesse Dudley dec, 14, 2023 ---> set userStatName local storage first used on distribution agreement

				
				localStorage.setItem("firstName", data?.firstName);
				if (localStorage.getItem("activityState") == 25 && data?.brandID !== 0){
						localStorage.setItem("brandID", data?.brandID);
				}
				if ((localStorage.getItem("activityState") >= 50 && localStorage.getItem("activityState") <= 75) && data?.productID !== 0) {

					// if user has failed to finish there agreement -- then set there localStorage productID to first product in the list, which is the last productID they were creating a agreement For
					var firstProductID = setProductID_AvtivityState(userID);

					// old way was geeting the last product i nthe list
					//localStorage.setItem("productID", data?.productID);
				
				}
				if (data?.roleName == "SalesREP") {
					setRoleName(data?.roleName);
				}
				if (userType == "1") {
					if (localStorage.getItem("activityState") == 0) {
						navigate("/brewer/register/firststep");
					} else {
						localStorage.setItem("ShowPopUp", "True");
						navigate("/brewer/dashboard")
					}
				} else if (userType == "2") {
					navigate("/buyer/dashboard")
				} else if (userType == "3") {
					navigate("/salesrep/dashboard");
				}else if (userType == "4") {
					navigate("/brewer/dashboard");
				}
				//  else if (data?.roleName == "SalesREP" || data?.roleName == "Administrator" || data?.roleName == "Staff") {
				// 	localStorage.setItem("createdBy", data?.createdBy)
				// 	navigate("/team-member/dashboard")
				// }
			}
		}).catch(function (error) {
			console.log(error)
		})
	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		if (handleValidation()) {
			setSubmitLoader(true)
			setBtnDisable(true)
			let formData = {
				"emailid": email,
				"Password": password
			}

			await axios.post(API_URL + "UserLogin?code=" + API_KEY, formData, config).then((res) => {
				if (res.data.responseCode === "200") {
					if (res.data.responseData.length) {
						let resData = res.data.responseData[0];
						if (resData?.status == "10") {
							toast.success("Login Successful!")
							if(resData?.userType == "4"){
								localStorage.setItem("id", resData?.createdBy);
								localStorage.setItem("adminID", resData?.id);
							}else{
								localStorage.setItem("id", resData?.id);
							}
							if(resData?.userType == "3"){
								localStorage.setItem("createdBy", resData?.createdBy);
							}
							
							localStorage.setItem("isAuthentic", true);
							localStorage.setItem("userType", resData?.userType);
							localStorage.setItem("activityState", resData?.activityState);
							setActivityState(resData?.activityState);

							getBrandDataByID(resData?.id, resData?.userType)

							if (remember) {
								localStorage.setItem("email", email);
								localStorage.setItem("password", password);
							} else {
								localStorage.removeItem("email");
								localStorage.removeItem("password");
							}
							setSubmitLoader(false)
						} else if (resData?.status == "2") {
							localStorage.setItem("id", resData?.id);
							toast.success("Kindly Make The Payment!")
							navigate("/brewers-payments");
							setSubmitLoader(false)
						}
					}
				} else {
					toast.error(res.data.responseMessage);
					setSubmitLoader(false)
				}
				
				setBtnDisable(false)
			}).catch(function (error) {
				console.log(error)
				setSubmitLoader(false)
				setBtnDisable(false)
			})
		}
	}

	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="site-wrapper">
				<div className="main-wrapper">
					<Header />
					<section className="simplyfying Empowering" id="simplys">
						<div className="simplyfyingDiv">
							<div className="container-fluid">
								<div className="row upcontent">
									<div className="col-md-7 manBgimg logform">
										<div className="simplecontentimg">
											<img className="img-responsive" src={LoginLeaves} />
										</div>
									</div>
									<div className="col-md-5 manBgimg">
										<div className="manImg">
											<img className="img-responsive" src={LoginBGSide} />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="moveTopUp loginBAoutphone">
										<div className="container">
											<div className="row main-contentprt">
												<div className="col-md-6">
													<div className="textSimply loginForms">
														<div className="loginformdesign">
															<h3>Portal <span>Login</span></h3>
															<p>Numbers don’t lie. We empower every aspect of your distribution so you can make a material impact on your bottom line.</p>
															<form>
																<div className="row">
																	<div className="col-md-12">
																		<div className="form-group">
																			<label for="firstname">Email <span className="red-tick">*</span></label>
																			<input type="text" className={emailError ? "form-control error" : "form-control"} name="firstname" placeholder="Email" onChange={handleEmail} value={email} autocomplete="off" />
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-12">
																		<div className="form-group passwordeye">
																			<label for="firstname">Password <span className="red-tick">*</span></label>
																			<div className="showhidepassowrd">
																				<input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} name="firstname" placeholder="Password" onChange={handlePassword} value={password} autocomplete="off" />
																				<span className="eyeslash" onClick={handleShow} >{!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-12">
																		<div className="remeberAndForgot">
																			<div className="remebercheck">
																				<input className="form-check-input" type="checkbox" value="" id="form1Example3" autocompleted="off" onChange={handleRemember} />
																				<label className="form-check-label" for="form1Example3">Remember password</label>
																			</div>
																			<div className="forgotpswd">
																				<Link to="/forgot" className="forgetpass">Forgot Password?</Link>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-12">
																		<div className="logibtns">
																			<button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit} disable={btnDisable}>LogIn</button>
																		</div>
																		<div className="createAccount">
																			No account? <Link className="" to="/brewer-registration">Create Brewer</Link> | <Link className="" to="/buyer-registration">Create Buyer</Link>
																		</div>
																	</div>
																</div>

															</form>
														</div>
													</div>
												</div>
												<div className="col-md-6">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>






					<Footer />
				</div>
			</div>

		</>
	)
}