import React, { useState, useEffect } from 'react';
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import DatePicker from "react-datepicker";
import Loader from "./../front/Loader";
import { useParams, useNavigate } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Select from 'react-select';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { CommitSharp } from '@mui/icons-material';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};

toast.configure()
export default function EditProfile() {
    document.title = "BevPort - Setup Delivery"
    const { id } = useParams();
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [startDate, setStartDate] = useState(null);
    const navigate = useNavigate();
    const [BrandNameList, setBrandNameList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [brandNameError, setBrandNameError] = useState(false);
    const [brandID, setBrandID] = useState("");
    const [productName, setProductName] = useState("");
    const [productID, setProductID] = useState("");
    const [productNameError, setProductNameError] = useState(false);
    const [quantity, setQuantity] = useState("");
    const [quantityError, setQuantityError] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [loader, setLoader] = useState(false)
    const [warehouseList, setWarehouseList] = useState([]);
    const [warehouseValue, setWarehouseValue] = useState({ value: 'FL', label: 'FLORIDA', id: '1' });
    const [wareHouseError, setWarehouseError] = useState(false);
    const [deliveryData, setDeliveryData] = useState("")
    const [dateValue, setDateValue] = useState(null);
    const [paletteError, setPaletteError] = useState(false)
    const [palette, setPalette] = useState("")
    const [totalPalette, setTotalPalette] = useState("");
    const [totalPaletteError, setTotalPaletteError] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [userData, setUserData] = useState("");
    const [tempArray, setTempArray] = useState([]);
    const [pickUpInstruction, setPickUpInstruction] = useState("");
    const [editBtn, setEditBtn] = useState(0);

    const [IsFullPallets, setIsFullPallets] = useState(true);


     // Alternate Street address 
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false);

    // state
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState(15);
    const [stateError, setStateError] = useState(false);

    // city 
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);

    // zip 
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState(false);

    const [defaultChecked, setdefaultChecked] = useState(true);
    const [AlternateChecked, setAlternateChecked] = useState(false);
    //-------------------------------------------------------------------


    const collapseSidebar = () => {
        setToggle(!toggle)
    }

  
    useEffect(() => {
        getBrandName();
        //getProducName();
        getWareHouseList();

        // 5/09/2024
        getStateList()

    }, [])

    useEffect(() => {
        let id = localStorage.getItem("id")
        getUserProfileFunction(id);

    }, [])

    useEffect(() => {
        if (id) {
            getDeliveryInfo(id);
        } else {

        }
    }, [id])

    useEffect(() => {
     
        var hasFullPallet = true;
        tempArray.forEach((_v, _i) => {
            if (_v?.isFullPallet == false) {
                hasFullPallet = false;
            }
        })


        if (IsFullPallets == true && hasFullPallet == true) {
            let sum = 0;
            tempArray.forEach((_v, _i) => {
                sum = (Math.ceil(_v?.quantity / _v?.palette)) + sum;
            })
            if (sum != 0) {
                setTotalPalette(sum)
            }
            //------------------

            var ConvertLabel2 = document.getElementById("FullPalletsLabel");
            ConvertLabel2.innerHTML = "Full Pallets <span className='red-tick'> *</span>";

            setIsFullPallets(true);

            var btnConvert = document.getElementById("btnConvertFullandMixed");
            btnConvert.innerHTML = "Convert To Mixed Pallets";
        }

        else {
            let sum = 0;
            tempArray.forEach((_v, _i) => {
                var qty = Number(_v?.quantity)
                sum = sum + qty;         
            })

            var finalSum = 0;
            finalSum = (Math.ceil(sum / 100));

            if (finalSum != 0) {
                setTotalPalette(finalSum)
            }
            //------------

            var ConvertLabel = document.getElementById("FullPalletsLabel");
            ConvertLabel.innerHTML = "Mixed Pallets <span className='red-tick'> *</span>";

            setIsFullPallets(false);

            var btnConvert = document.getElementById("btnConvertFullandMixed");
            btnConvert.innerHTML = "Convert To Full Pallets";
        }
       
        //console.log("sum", sum);
    }, [tempArray, editBtn])



    useEffect(() => {


        if (id) {
            if (deliveryData) {
                setWarehouseValue({ value: deliveryData?.wareHouseName, label: deliveryData?.wareHouseName })
                setBrandID(deliveryData?.brandID)
                setProductID(deliveryData?.productID)
                setPickUpInstruction(deliveryData?.pickUpInstruction)
                if (dateValue) {
                    if (dateValue == 'Mon Jan 01 1900 00:00:00 GMT+0521 (India Standard Time)') {
                        setStartDate("")
                    } else {
                        setStartDate(dateValue);
                    }

                }
                setTotalPalette(deliveryData?.totalPalettes)
                setPickUpInstruction(deliveryData?.pickUpInstruction);
                let data = []

                deliveryData?.deliveryRecords.forEach((_v, _i) => {
                    data.push(_v)
                  

                })
                setTempArray(data);
                setBtnDisabled(true);


               // alert(data[0].altStreetAddress)
                setStreet(data[0].altStreetAddress);
                setCity(data[0].altCity);
                setState(data[0].altState);
                setZip(data[0].altZipCode);
                
                // switch check
                if (data[0].isAltAddress === true) {
                    //setdefaultChecked(false);
                    //setAlternateChecked(true);

                    handleAlternateCheckBox();
                }

                else {
                    //setdefaultChecked(true);
                    //setAlternateChecked(false);

                    handleDeafultCheckBox();
                }
                
                
            }



        } else {
            setBrandName("")
            setProductName("")
            setQuantity("")
            //setWarehouseValue("")
            setStartDate("");
            setPalette("");
            setPickUpInstruction("")
            setTotalPalette("")
            setBtnDisabled(false);
            setTempArray([])

        }
    }, [id, deliveryData, dateValue])

    const getUserProfileFunction = async (userID) => {
        await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setUserData(data)


            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })
    }


    const getDeliveryInfo = async (id) => {
        await axios.post(API_URL + "GetDeliveryDetailsByID?code=" + API_KEY, { "SetUpDeliveryID": id }, config).then((res) => {

            if (res.data.responseCode == "200") {
                setDeliveryData(res.data.responseData?.[0])
                const parsedDate = new Date(res.data.responseData?.[0]?.requestedPickUpDate);
                setDateValue(parsedDate);
                setSubmitLoader(false);

            }
        }).catch(function (error) {

        })
    }


    const getWareHouseList = async () => {
        await axios.post(API_URL + "GetWarehouses?code=" + API_KEY, { UserID: localStorage.getItem("id") }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let wareOption = []
                let wareid = [];
                let resData = res.data.responseData
                resData.forEach((da) => {
                    if (da?.status == 1) {
                        wareOption.push({ value: da.wareHouseCode, label: da.wareHouseName, brandID: da.id });
                    }
                }
                );

                setWarehouseList(wareOption);
                setSubmitLoader(false);


            } else {

            }
        }).catch(function (error) {

        })
    }

    const handlePickUpInstrucion = (e) => {
        setPickUpInstruction(e.target.value)
    }

    const getBrandName = async () => {
        await axios.post(API_URL + "GetBrandList?code=" + API_KEY, { UserID: localStorage.getItem("id") }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let brandOption = []
                let brID = [];
                let resData = res.data.responseData
                resData.forEach((da) => {
                    if (da?.status == 1) {
                        brandOption.push({ value: da.brandName, label: da.brandName, brandID: da.id });
                    }
                }
                );
                setBrandNameList(brandOption);
                setSubmitLoader(false);


            } else {

            }
        }).catch(function (error) {

        })

    }

    const handleFloatNumber = (e) => {
        if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    }
   
    const handleProduct = (e) => {
        setProductID(e.productID)
        setProductName({ value: e.value, label: e.label });
        setProductNameError(false);

        if (IsFullPallets == true) {

            getPaletteQuantity(e.productID)
        }


        else {
            //getPaletteQuantity(100)
            setPalette(100)
        }
       
    }
    const getPaletteQuantity = async (productID) => {
        await axios.post(API_URL + "GetPalette?code=" + API_KEY, { "ID": productID }, config).then((res) => {
            if (res?.data?.responseCode === "200") {
                setPalette(res?.data?.responseData?.palette)
            } else {
                setPalette("")
            }

        }).catch(function (error) {
            setPalette("")
        })
    }
    const handleQuantity = (e) => {
        setQuantity(e.target.value)
        setQuantityError(false);
    }

    const handleDate = (date) => {
        setStartDate(date);
    }

    const handleTotalPalettes = (e) => {
        setTotalPalette(e.target.value);
        setTotalPaletteError(false);
    }

    const handleValidation = () => {
        let formIsValid = true;

        // if (!brandName) {
        //     formIsValid = false;
        //     setBrandNameError(true)
        // }
        // if (!quantity) {
        //     formIsValid = false;
        //     setQuantityError(true)
        // }

        // if (!productName) {
        //     formIsValid = false;
        //     setProductNameError(true)
        // }

  
        // validation for alternate address -- if bit is true use that address and not the default address
        if (AlternateChecked === true) {
          
            // street
            if (!street) {
                formIsValid = false;
                setStreetError(true);
            }
            // city
            if (!city) {
                formIsValid = false;
                setCityError(true);
            }
            // state
            if (!state) {
                formIsValid = false;
                setStateError(true);
            }
            // zip
            if (!zip) {
                formIsValid = false;
                setZipError(true);
            }
            
        }

        if (!warehouseValue) {
            formIsValid = false;
            setWarehouseError(true)
        }

        if (!totalPalette) {
            formIsValid = false;
            setTotalPaletteError(true);
        }

        return formIsValid;
    }

    const insertData = async (e) => {
        let narr = [];
        tempArray.map((_v, _i) => {

            narr.push({
                "BrandID": _v?.brandID,
                "ProductID": _v?.productID,
                "Quantity": _v?.quantity,
                "Palette": _v?.palette,
                "IsFullPallet": _v?.isFullPallet,

                "altStreetAddress": street,
                "altCity": city,
                "altState": state,
                "altCountry": 1,
                "IsAltAddress": AlternateChecked,
                "altZipCode": zip
            })
        })
        //--
        let formData = {
            "ID": localStorage.getItem("id"),
            "RequestedPickUpDate": (startDate) ? moment(startDate).format("YYYY-MM-DD hh:mm:ss") : moment().format("YYYY-MM-DD hh:mm:ss"),
            "PickUpInstruction": pickUpInstruction,
            "WarehouseID": "1",
            "TotalCase": totalPalette,
            "TotalPalettes": totalPalette,
            "data": narr
        }

        setLoader(true)
        //setBtnDisable(true)

        await axios.post(API_URL + "SetUpDelivery?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);
                navigate("/brewer/setup-delivery-list")
            } else {
                toast.error(res.data.responseMessage);
            }
            setLoader(false)
            setBrandName("");
            setProductName("");
            setQuantity("");
            setStartDate("");
            setWarehouseValue("");
            setPalette("")
            //setBtnDisable(false)
        }).catch(function (error) {
            console.log(error)
            setLoader(false)
            //setBtnDisable(false)
        })

    }

    const updateRecord = async (id) => {
        setLoader(true)

        let narr = [];

        tempArray.map((_v, _i) => {
            narr.push({
                "BrandID": _v?.brandID,
                "ProductID": _v?.productID,
                "Quantity": _v?.quantity,
                "Palette": _v?.palette,
                "IsFullPallet": _v?.isFullPallet,

                "altStreetAddress": street,
                "altCity": city,
                "altState": state,
                "altCountry": 1,
                "IsAltAddress": AlternateChecked,
                "altZipCode": zip
            })
        })

        let formData = {
            "ID": localStorage.getItem("id"),
            "SetUpDeliveryID": id,
            "RequestedPickUpDate": (startDate) ? moment(startDate).format("YYYY-MM-DD hh:mm:ss") : moment().format("YYYY-MM-DD hh:mm:ss"),
            "PickUpInstruction": pickUpInstruction,
            "WarehouseID": "1",
            "TotalCase": totalPalette,
            "TotalPalettes": totalPalette,
            "data": narr
        }

    
       
        await axios.post(API_URL + "SetUpDelivery?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);
                navigate("/brewer/setup-delivery-list")
            } else {
                toast.error(res.data.responseMessage);
            }
            setLoader(false)

        }).catch(function (error) {
            console.log(error)
            setLoader(false)

        })

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            if (id) {
                updateRecord(id)
            } else {
                insertData()
            }

        }
    }

    const handleWareHouseList = (e) => {
        setWarehouseValue({ value: e.value, label: e.label, id: e.brandID })
        setWarehouseError(false)
    }
    const handlePalette = (e) => {
        setPalette(e.target.value)

    }

    const handleValidate = () => {
        let formIsValid = true;

        if (!brandName) {
            formIsValid = false;
            setBrandNameError(true)
        }
        if (!quantity) {
            formIsValid = false;
            setQuantityError(true)
        }

        if (!productName) {
            formIsValid = false;
            setProductNameError(true)
        }
        return formIsValid;
    }


    const handleEditField = () => {
        setLoader(true);
        if (handleValidate()) {
            tempArray[editVar].brandID = brandID;
            tempArray[editVar].productID = productID;
            tempArray[editVar].brandName = brandName?.value;
            tempArray[editVar].productName = productName?.value;
            tempArray[editVar].quantity = quantity;
            tempArray[editVar].palette = palette;

            setLoader(false)
            setEditBtn(0);
            setBrandName("");
            setProductName("");
            setQuantity("");
            setPalette("");
        }


    }


    const removeItemByIndex = (index) => {
        let length = 0;
        if (index >= 0 && index < tempArray.length) {
            const updatedItems = [...tempArray];
            updatedItems.splice(index, 1); // Remove the item at the specified index
            setTempArray(updatedItems);
            length = updatedItems.length;
        }
        if (length == 0) {
            setTotalPalette("");
        }
    };

    const [editVar, setEditVar] = useState("");

    const editFeilds = (index, data) => {
 
        console.log("darata", data);
        setBrandName({ value: data?.brandName, label: data?.brandName });
        setProductName({ value: data?.productName, label: data?.productName });
        setQuantity(data?.quantity);
        setPalette(data?.palette);
        setBtnDisabled(false)
        setBrandID(data?.brandID);
        setProductID(data?.productID);
        //removeItemByIndex(index);
        setEditBtn(1);
        setEditVar(index);
        //


        var isFullPalletsParam = data?.isFullPallet;

        if (isFullPalletsParam === false) {

            var ConvertLabel = document.getElementById("FullPalletsLabel");
            ConvertLabel.innerHTML = "Mixed Pallets <span className='red-tick'> *</span>";

            setIsFullPallets(false);

            var btnConvert = document.getElementById("btnConvertFullandMixed");
            btnConvert.innerHTML = "Convert To Full Pallets";
        }

        else {
            var ConvertLabel2 = document.getElementById("FullPalletsLabel");
            ConvertLabel2.innerHTML = "Full Pallets <span className='red-tick'> *</span>";

            setIsFullPallets(true);

            var btnConvert = document.getElementById("btnConvertFullandMixed");
            btnConvert.innerHTML = "Convert To Mixed Pallets";
        }


    }




    // btn click convert mixed pallets and back to full pallets 
    const handleConvertToMixedPallet = () => {

        var isFullPalletsParam = IsFullPallets;

        if (isFullPalletsParam === true) {

            var ConvertLabel = document.getElementById("FullPalletsLabel");
            ConvertLabel.innerHTML = "Mixed Pallets <span className='red-tick'> *</span>";

            setIsFullPallets(false);

            var btnConvert = document.getElementById("btnConvertFullandMixed");
            btnConvert.innerHTML = "Convert To Full Pallets";          
        }

        else {
            var ConvertLabel2 = document.getElementById("FullPalletsLabel");
            ConvertLabel2.innerHTML = "Full Pallets <span className='red-tick'> *</span>";

            setIsFullPallets(true);

            var btnConvert = document.getElementById("btnConvertFullandMixed");
            btnConvert.innerHTML = "Convert To Mixed Pallets";
        }

        //setLoader(false)
        //setEditBtn(0);

        // clear fields when switching between full pallets and mixed pallets
        setBrandName("");
        setProductName("");
        setQuantity("");
        setPalette("");

        setTempArray([]);
        setTotalPalette("");
      
    };

    const handleBrandName = async (e) => {
        setBrandID(e.brandID);
        setBrandName({ value: e.value, label: e.label });
        setBrandNameError(false)
        setProductID("")
        setProductName("");
        setProductList([])
        if ({ value: e.value, label: e.label }) {
            await axios.post(API_URL + "GetProductListByBrand?code=" + API_KEY, { BrandID: e.brandID }, config).then((res) => {
                if (res.data.responseCode == "200") {
                    let productOption = []
                    let resData = res.data.responseData
                    resData.forEach((da) => {
                        productOption.push({ value: da.productName, label: da.productName, productID: da.id });
                    }
                    );
                    setProductList(productOption);
                } else {

                }
            }).catch(function (error) {

            })
        }
    }

   

    //  ------- Full pallet add field function 
    const handleAddField = () => {
        if (handleValidate()) {
            let tempJSON = {
                "brandID": brandID,
                "productID": productID,
                "brandName": brandName?.value,
                "productName": productName?.value,
                "quantity": quantity,
                "palette": palette,
                "isFullPallet": IsFullPallets
            }


            console.log(tempJSON)
            // setTotalPalette(quantity / palette);
            setTempArray([...tempArray, tempJSON])
            setBrandName("");
            setProductName("");
            setQuantity("");
            setPalette("");
        }

    };


    // ----------------------------------New ------------------------ 5/24/2024----------------------------------

    // User Adds Extra Delviery Address
    const handleStreet = (e) => {
        setStreetError(false)
        setStreet(e.target.value)
    }


    const handleDeafultCheckBox = (e) => {

        // remove class hidden from alternate address text box control
        var hiddenAddress = document.getElementById("divAlternatePickupAddress");
        hiddenAddress.classList.add("hidden");

        var hiddenAddress2 = document.getElementById("divAlternatePickupAddress2");
        hiddenAddress2.classList.add("hidden");

        var hiddenAddress3 = document.getElementById("divAlternatePickupAddress3");
        hiddenAddress3.classList.add("hidden");
        //------------------------------------------------------------------------

        if (defaultChecked === false) {
            setdefaultChecked(true);
        }       
        if (AlternateChecked === true) {
            setAlternateChecked(false);
        }
     
         //setStreetError(false)
        
    }
    //--------------------------------------------------------------------



    const handleAlternateCheckBox = (e) => {

        // remove class hidden from alternate address text box control
        var hiddenAddress = document.getElementById("divAlternatePickupAddress");
        hiddenAddress.classList.remove("hidden");

        var hiddenAddress2 = document.getElementById("divAlternatePickupAddress2");
        hiddenAddress2.classList.remove("hidden");

        var hiddenAddress3 = document.getElementById("divAlternatePickupAddress3");
        hiddenAddress3.classList.remove("hidden");
        //-------------------------------------------------------------------------

        if (defaultChecked === true) {
            setdefaultChecked(false);
        }
       
        if (AlternateChecked === false) {
            setAlternateChecked(true);
        }
       
    }
      //--------------------------------------------------------------------


    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setStateList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    //-----------------


    const handleState = (e) => {
        setStateError(false)
        setState(e.target.value)
    }
    const handleCity = (e) => {
        setCityError(false)
        setCity(e.target.value)
    }


    const handleZIP = (e) => {
        setZipError(false)
        setZip(e.target.value)
    }

    return (   
        <>
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <BrewerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Setup Delivery</h1>
                            <p>Manage your Delivery quickly and efficiently. Setting up delivery for your next shipment by entering the information below.</p>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <input id="cb_defaultPickupAddress" checked={defaultChecked}  onChange={handleDeafultCheckBox} type="checkbox"></input> &nbsp;
                                                    <label className="brand-tab" >Pick up Address:</label>
                                                    <p className="uaserades">{userData?.streetAddress}</p>
                                                    <p>{userData?.city}, {userData?.stateName} {userData?.zipCode}</p>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <input id="cb_alternatePickupAddress" checked={AlternateChecked} onChange={handleAlternateCheckBox} type="checkbox"></input> &nbsp;
                                                    <label className="brand-tab" >Alternate Pick up Address:</label>                                                                                                 
                                                </div>
                                            </div>

                                            <div id="divAlternatePickupAddress" className="col-md-3 hidden">
                                                {/* <label className="brand-tab" > Address:</label> */}
                                                <label for="firstname">Address <span className="red-tick">*</span></label>
                                                <input type="text" className={streetError ? "form-control error" : "form-control"} value={street} placeholder="Enter Street Address" onChange={handleStreet} />
                                            </div>

                                            <div id="divAlternatePickupAddress2" className="col-md-3 hidden">                                             
                                                <div className="form-group">
                                                    <label style={{ marginBottom: "5px" }} for="firstname">City <span className="red-tick">*</span></label>
                                                    <input type="text" className={cityError ? "form-control error" : "form-control"} value={city} placeholder="City" onChange={handleCity} />
                                                </div>
                                            </div>

                                        </div>

                                        {/*// -------------------------------New row------------------------ */}
                                        <div id="divAlternatePickupAddress3" className="row hidden">
                                            <div className="col-md-3 col-md-offset-6">
                                                <div className="form-group">

                                                    <label for="firstname">State <span className="red-tick">*</span></label>
                                                    <select className={stateError ? "form-control error" : "form-control"} onChange={handleState} value={state}>
                                                        <option value="">Select State</option>
                                                        {
                                                            stateList.length && stateList.map((_v, _i) => {
                                                                return (
                                                                    <option key={_i} value={_v.id}>{_v.statename}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="firstname">ZIP Code <span className="red-tick">*</span></label>
                                                    <InputMask
                                                        mask='99999'
                                                        value={zip}
                                                        onChange={handleZIP}
                                                        className={zipError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="ZIP Code"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        {/*// -------------------------------end row------------------------ */}


                                       













                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group insturcsiotn">
                                                    <label className="brand-tab">Add Pick up instructions</label>
                                                    <textarea col="4" type="text" className="form-control" placeholder="Add Pick up instructions" value={pickUpInstruction} onChange={handlePickUpInstrucion} />
                                                </div>
                                            </div>
                                        </div>


                                        {/* //-------------------------------- Mixed pallets Convert Button ---------------------------------------- */}
                                        <div className="row">  
                                            <div classsName="col-md-3">

                                            </div>
                                            <div classsName="col-md-3 text-center">
                                                <button id={"btnConvertFullandMixed"} style={{ backgroundColor: "#b0efb0", marginLeft: "20px" }} onClick={handleConvertToMixedPallet}>Convert To Mixed Pallets</button>
                                            </div>
                                        </div>



                                        {/* //-----------------full pallets Header row--------------------------- */}
                                        <div style={{ paddingTop: "20px" }} className="row">
                                            <div className="col-md-3">
                                                <label id={"FullPalletsLabel"} style={{ fontSize: "20px" }} className="brand-tab">Full Pallets<span className="red-tick"> *</span></label> <br />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="brand-tab">Brand<span className="red-tick"> *</span></label>
                                                    <Select className={brandNameError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        options={BrandNameList}
                                                        onChange={handleBrandName}
                                                        closeMenuOnSelect={true}
                                                        value={brandName}
                                                        placeholder={<div className="italic-placeholder">Brand</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="brand-tab">Product<span className="red-tick"> *</span></label>
                                                    <Select className={productNameError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        options={productList}
                                                        onChange={handleProduct}
                                                        value={productName}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Product</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="brand-tab">Total Cases<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='9999999'
                                                        value={quantity}
                                                        onChange={handleQuantity}
                                                        className={quantityError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="Total Cases"
                                                    ></InputMask>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="brand-tab">Cases per Palette<span className="red-tick"> *</span></label>
                                                    <input type="text" className={paletteError ? "form-control error" : "form-control"} placeholder="Cases per Palette" value={palette} onChange={handlePalette} onKeyPress={handleFloatNumber} readOnly />   

                                                    <input className="hidden" type="text" value={IsFullPallets}></input>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className='add-removebtn text-right'>
                                                    <div className="form-group">
                                                        <label className="visibilty-hidden">hidencontent</label>
                                                        <button class="btn btn-primary" onClick={() => { (!editBtn) ? handleAddField() : handleEditField() }} type="submit" disabled={btnDisabled}>{/*<i className="fa fa-plus"></i>*/}Add into Item List</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                {/*    //---------------------------- Item List List section -----> when user clicks add item to list*/}

                                        <div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="brand-tab">Item List</label>
                                                </div>
                                            </div>
                                            <div className="table-responsive addbgs">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Brand</th>
                                                            <th>Product</th>
                                                            <th>Total Cases</th>
                                                            <th>Cases per Palette</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {tempArray.length ?
                                                        <tbody>
                                                            {tempArray.map((_v, _i) => {

                                                                return (
                                                                    <tr>
                                                                        <td>{_v?.brandName}</td>
                                                                        <td>{_v?.productName}</td>
                                                                        <td>{_v?.quantity}</td>
                                                                        <td>{_v?.palette}</td>
                                                                        <td>
                                                                            <div class="add-removebtn removebts text-right">
                                                                                <button class="btn btn-secondary" type="submit" onClick={() => removeItemByIndex(_i)}><i class="fa fa-minus"></i></button>&nbsp;&nbsp;
                                                                                {(id) ? <button class="btn btn-primary" type="submit" onClick={() => editFeilds(_i, _v)}>Edit</button> : ""}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody> : ""
                                                    }
                                                </table>
                                            </div>


                                        </div>

                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Warehouse<span className="red-tick"> *</span></label>
                                                    <Select className={wareHouseError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        options={warehouseList}
                                                        onChange={handleWareHouseList}
                                                        value={warehouseValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Warehouse</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Requested Pickup Date</label>
                                                    <Tooltip
                                                        title={<span className="toolTipData">The date on which product is ready for CMG to pickup.</span>}
                                                        arrow
                                                        placement={"right-start"}
                                                        style={{ marginBottom: "100px" }}
                                                    ><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>
                                                    </Tooltip>
                                                    <DatePicker selected={startDate}
                                                        dateFormat="MM-dd-yyyy"
                                                        onChange={handleDate}
                                                        placeholderText='Requested Pickup Date'
                                                        minDate={moment().toDate()}


                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Total Palettes<span className="red-tick"> *</span></label>

                                                    <input type="text" className={totalPaletteError ? "form-control error" : "form-control"} placeholder="Total Palettes" value={totalPalette} onKeyPress={handleFloatNumber} onChange={handleTotalPalettes} readOnly={true} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='fixeatottom'>
                                                    <p>*Once you SAVE, your Delivery order will be automatically processed. A team member from Country Malt Group will schedule your Pick up and notify you via email of your exact Pick up date.
                                                    </p>
                                                </div>
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <BrewerFooter />
                </div>
            </div>
        </>



    )
}