import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrewersList() {
	document.title = "BevPort - Brewers List"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}

	const handleStatus = async (status, id) => {
		let formData = {
			"ID": id,
			"Status": status
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeUserStatus?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				toast.success(res.data.responseMessage, {
					autoClose: 3000, //3 seconds
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,

				});
				getBrewersList()
				setLoader(false);
			} else {
				toast.error(res.data.responseMessage)
			}
		}).catch(function (error) {

		})
	}
	const alertFunction = () => {
		toast.error("Please activate brand before adding products")
	}


	const handleSignNow = (UserID) => {
		//window.location = "https://www.signnow.com/";

		BrewerSignNow_ByProduct(UserID);
	}

	const SignNow_ModalWindow = (productData) => {


		// set dates array for getting max date
		const arrOfDates = new Array();
		for (var i = 0; i < productData.length; i++) {
			var updatedDate = new Date(productData[i].updatedDate);
			arrOfDates.push(updatedDate)
		}
		//-------

		// get last approved product label 
		var lastProductName_Approved = "";
		var lastProductName_Rejected = "";
		var complianceApproved = 0;
		var MaxDate = new Date(Math.max(...arrOfDates)).toLocaleString('en-us', { hour12: false }); // get max date from array which is the last updated product label
		var RejectReasonText = "";


		// Check user's local storage --> activityState --> to see what html to show
		var modalHtml = "";
		var Title = "Sign Now Details";
		var modalText = "";

		for (var i = 0; i < productData.length; i++) {

			var productTypeName = "";
			switch (productData[i].brewerProductType)
			{
				case 1:
					productTypeName = "Beer";
					break;
				case 2:
					productTypeName = "Wine";
					break;
				case 3:
					productTypeName = "Wine Based Product";
					break;
				case 4:
					productTypeName = "Non Alcoholic";
					break;
				default:
					productTypeName = "";
					break;
			}
		
			modalHtml = modalHtml + '<div class="col-md-12 text-left"> <br> <label>Product Name: <b style="font-weight:200">' + productData[i].productName + '</b> </label> <br> <label>Product Type: <b style="font-weight:200">' + productTypeName + ' </b> </label>  </div> ';

			//var updatedDate = new Date(productData[i].updatedDate).toLocaleString('en-us', { hour12: false });

			//if (productData[i].complianceApproved == 3 && updatedDate == MaxDate) {
			//	lastProductName_Approved = productData[i].productName;
			//	complianceApproved = 3;
			//	break; // only get last approved label , so break out of loop so its always last approved product
			//}

			//else if (productData[i].complianceApproved == 90 && updatedDate == MaxDate) {
			//	lastProductName_Rejected = productData[i].productName;
			//	RejectReasonText = productData[i].rejectReason;
			//	complianceApproved = 90;
			//	break;
			//}
		} // end loop
		//--------------------------------------


		Swal.fire({
			title: Title,
			text: modalText,
			html: modalHtml,
			icon: 'question',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'SignNow',
			denyButtonText: 'Close Window',
			background: '#fff',
			color: '#000',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result) => {


			if (result.isConfirmed) {
				
			
				// open sign now new window 
				window.open("https://www.signnow.com/");

				localStorage.removeItem("ShowPopUp");
			} else if (result.isDenied) {
				
				localStorage.removeItem("ShowPopUp");
			}
		})
	}



	// jesse dudley --> might user later for getting product data related to user 
	const BrewerSignNow_ByProduct = async (param_UserID) => {
		const formData = {
			"UserID": param_UserID
		}
		
		await axios.post(API_URL + "GetProductDetailsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;

				// model window for sign now 
				SignNow_ModalWindow(data);

			} else {

				toast.error(res.data.responseMessage)
			}
		
		}).catch(function (error) {
			
		})
	}


	const columns = [

		//{
		//	name: 'UserID',
		//	selector: row => (row.id).toLowerCase(),
		//	cell: row => row.id,
		//	sortable: true,

		//},

		{
			name: 'Name',
			selector: row => (row.firstName + " " + row.lastName).toLowerCase(),
			cell: row => row.firstName + " " + row.lastName,
			sortable: true,

		},
		{
			name: 'E-mail',
			selector: row => (row.emailID).toLowerCase(),
			cell: row => (row.emailID).toLowerCase(),
			sortable: true,
			width: '250px',

		},
		{
			name: 'Phone No.',
			selector: row => row.businessPhone.toString(),
			cell: row => (row.businessPhone).toString(),
			sortable: true,

		},
		{
			name: 'Business Name',
			selector: row => (row.businessName).toLowerCase(),
			cell: row => row.businessName.toString(),
			sortable: true,

		},

		{
			name: 'Address',
			selector: row => row.streetAddress + ", " + row.city + ", " + row.stateName + ", " + row.zipCode + ", " + "USA",
			cell: row => row.streetAddress + ", " + row.city + ", " + row.stateName + ", " + row.zipCode + ", " + "USA",
			sortable: true,
			wrap: true,

		},

		{
			name: 'Status',
			selector: row => row.status,
			cell: row => (row.status == 10) ? <span style={{ "color": "#00aeef" }}>Active</span> : <span style={{ "color": "#00aeef" }}>Inactive</span>,
			sortable: true,


		},


		{
			name: 'Agreement',
			width: '250px',
			export: false,
			selector: row => <>
				<button className="btn btn-info" onClick={() => handleSignNow(row.id)} disabled={(row.agreementFilePath == "") ? true : false} >Agreement</button>

			</>,
		},

		// old agreement code -----
		//{

		//	name: 'Agreement',
		//	width: '250px',
		//	selector: row => <>
		//		<button className="btn btn-info" onClick={() => handleDownload(row.agreementFilePath)} disabled={(row.agreementFilePath == "") ? true : false}>Download</button>


		//	</>,
		//},
		{
			name: 'Action',
			width: '250px',
			export: false,
			selector: row => <>
				<Link className="btn btn-primary" to={"/admin/edit-brewers/" + row.id}>Edit</Link>&nbsp;
				<Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "10") ? "90" : "10", row.id)}>{(row.status == "10") ? "DEACTIVATE" : "Activate"}</Link>

			</>,
		}
	];


	const handleDownload = (agreementURL) => {
		const link = document.createElement('a');
		link.href = agreementURL;
		link.download = 'distributor-agreement-files.pdf'; // Specify the desired filename for the downloaded PDF
		link.style.display = 'none';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};


	const [data, setData] = useState([])
	useEffect(() => {
		let userID = localStorage.getItem("id")
		getBrewersList()
	}, [])

	const getBrewersList = async (userID) => {
		await axios.post(API_URL + "GetAllBrewersList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				setData(res.data.responseData)
				console.log("resposnse", res.data.responseData);

			} else {
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const tableData = {
		columns,
		data
	};
	return (
		<>

			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Brewer List</h1>
						</div>

						<div className="blank150"></div>

						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="editUserProfile mb-15">
									<Link className="btn btn-primary save-button " to={"/admin/add-brewers"}>
										Add Brewer
									</Link>
								</div>
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										{/* <h3 className="card-heading">Brewer List</h3> */}
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search Brewers">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={<span className='norecordFound'>No record found!</span>}
												//defaultSortFieldId={1
											/>
										</DataTableExtensions>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}