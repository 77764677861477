import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BuyerSideBar";
import BuyerHeader from "./BuyerHeader";
import BuyerFooter from "./BuyerFooter";
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Logo from './../../assets/images/logo.png';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function ProductList() {
	document.title = "BevPort - Product List"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
	const [productList, setProductList] = useState([]);
	const [productListTemp, setProductListTemp] = useState([]);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [loader, setLoader] = useState(false);
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		getProductList();
	}, [])
	const getProductList = async () => {
		const formData = {
			"ID": localStorage.getItem("id")
		}
		setSubmitLoader(true);
		await axios.post(API_URL + "WishList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
			} else {
				setProductList([]);
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	const [searchText, setSearchText] = useState("")
	const handleSearchBox = (e) => {
		setSearchText(e.target.value)
	}
	function handleSearchClick(e) {
		e.preventDefault()
		if (searchText === "") { setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(searchText.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(searchText.toLowerCase()) || (item?.masterBeerType).toLowerCase()
						.includes(searchText.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}
	const removeFromFavourite = async (productID) => {
		const formData = {
			"ID": localStorage.getItem("id"),
			"PRODUCTID": productID,
			"FavStatus": 0
		}
		setLoader(true);
		await axios.post(API_URL + "ProductFavorite?code=" + API_KEY, formData, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				getProductList()
				await cartWishListCountFunc()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}
	const addToCart = async (productID) => {
		const formData = {
			"ID": localStorage.getItem("id"),
			"ProductID": productID,
			"Quantity": "1",
			"numvalue": "Increment"
		}
		setLoader(true);
		await axios.post(API_URL + "AddToUserCart?code=" + API_KEY, formData, config).then(async (res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				await cartWishListCountFunc()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}
	const [cartCount, setCartCount] = useState(0)
	const [wishListCount, setWishListCount] = useState(0)
	const cartWishListCountFunc = async () => {
		await axios.post(API_URL + "WishCartCount?code=" + API_KEY, { "ID": localStorage.getItem("id") }, config).then((res) => {
			if (res.data.responseCode === "200" && res.data.responseData.length) {
				let data = res.data.responseData[0];
				setCartCount(data?.cartCount)
				setWishListCount(data?.wishCount)
			}
		}).catch(function (error) {
		})
	}
	return (
		<>

			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BuyerHeader collapseSidebar={collapseSidebar} cartCount={cartCount} wishListCount={wishListCount} />
					<div className={submitLoader ? "rightContetBlock vissibleHide" : "rightContetBlock"}>
						{submitLoader ? <Loader /> : ""}
						<div className="headingTop">
							<h1>Wishlist</h1>
						</div>
						<div className="blank150"></div>
						<div className="searchOntopPage">
							<div className="container-fluid">
								<form onSubmit={handleSearchClick}>
									<div className="row">
										<div className="col-md-12">
											{(productList.length) ? <div className="searchBox">
												<div className="input-group">
													<div className="input-group-btn">
														<button className="btn btn-default" type="submit"><i className="glyphicon glyphicon-search"></i></button>
													</div>
													<input type="text" className="form-control" placeholder="Search Products" onChange={handleSearchBox} />
												</div>
											</div> : ""}
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body productListingPage">
									{/* <h3 className="card-heading">Products</h3> */}
									{(productList.length) ?
										<div className="row">
											{
												productList.length ? productList.map((_v, i) => {
													let perCasePrice = 0;
													let u = (_v?.masterCaseSize).split(" ")
													if (_v?.unitPer === "unit") {
														perCasePrice = _v?.bevPortListingPrice * u[0]
													}
													if (_v?.unitPer === "pack") {
														let quantityPerPack = 0;
														if (_v?.masterPackSize === "Singles") {
															quantityPerPack = 1
														} else {
															let u0 = (_v?.masterPackSize).split(" ")
															quantityPerPack = u0[0]
														}
														perCasePrice = (_v?.bevPortListingPrice / quantityPerPack) * u[0]
													}
													return (
														<div className="col-md-3 mb-20">
															<Card className="product-list-component" sx={{ maxWidth: 345 }}>
																<div className="stage" onClick={() => removeFromFavourite(_v?.id)}>
																	<i className={_v?.favStatus ? "fa fa-heart" : "fa fa-heart-o"}></i>
																</div>

																<Link to={"/buyer/product-detail/" + _v?.id}>
																	<CardMedia
																		component="img"
																		className="product-image"
																		alt={_v?.productName}
																		height="210"
																		image={(_v?.filepath) ? _v?.filepath : Logo}
																	/>
																</Link>
																<CardContent>
																	<Typography gutterBottom variant="h4" component="div">
																		{_v?.productName}
																	</Typography>
																	<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																		{_v?.brandName}
																	</Typography>
																	<Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px' }}>
																		{_v?.masterBeerType}
																	</Typography>
																</CardContent>
																<Divider />
																<CardContent className="ProductListCardContent">
																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Can Size
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.masterCanSize}
																			</Typography>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Case Size
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.masterCaseSize}
																			</Typography>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Size
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.masterPackSize}
																			</Typography>
																		</div>
																	</div>
																	{
																	_v?.bevPortListingCasePrice ?
																		<div className="row mutliplePrices">
																			<div className="col-md-6">
																				<Typography gutterBottom variant="h6" component="div">
																					Case Price
																				</Typography>
																			</div>
																			<div className="col-md-6">
																				<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																					${parseFloat(_v?.bevPortListingCasePrice).toFixed(2)}
																				</Typography>

																			</div>
																		</div> : ""}
																{
																	_v?.bevPortListingPackPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingPackPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""}
																{
																	_v?.bevPortListingUnitPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Unit Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingUnitPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""
																}
																</CardContent>
																<CardActions className="cardactionBtns buttonWidth">
																	{/* <Link className="btn btn-secondary" to="#" onClick={() => handleActiveInactiveStatus(_v?.id,_v?.status)}>{(_v?.status)?"In Activate":"Activate"}</Link> */}
																	<Link className="btn btn-primary" to={"#"} onClick={() => addToCart(_v?.id)}>Add To Cart</Link>

																</CardActions>
															</Card>
														</div>
													)
												}) :
													""
											}

										</div>
										:
										<div className="col-md-12">
											There are no records to display!
										</div>
									}
									{/* <div className="col-md-12" style={{ "display": (nodata) ? "block" : "none" }} >
										There are no records to display!
									</div> */}
								</div>
							</div>
						</div>
					</div>
					<BuyerFooter />
				</div>
			</div>
		</>
	)
}