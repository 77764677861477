import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import Loader from "./../front/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()

export default function SripePaymentCard() {
    const [submitLoader, setSubmitLoader] = useState(false)
    const [nameCard, setNameCard] = useState("")
	const [nameCardError, setNameCardError] = useState(false)
	const [cardNumberError, setCardNumberError] = useState(false)
	const [cardNumberErrorText, setCardNumberErrorText] = useState("")
	const [cardExpireError, setCardExpireError] = useState(false)
	const [cardExpireErrorText, setCardExpireErrorText] = useState("")
	const [cardCvvError, setCardCvvError] = useState(false)
	const [cardCvvErrorText, setCardCvvErrorText] = useState("")
   
    const clearFeilds = (e) => {
       const cardClear = elements.getElement(CardNumberElement).clear();
        const cardExpireClear = elements.getElement(CardExpiryElement).clear();
        const ccvClear = elements.getElement(CardCvcElement).clear();
    
    }

    const handleNameCard = (e) => {
		const regex = /^[a-zA-Z ]*$/;
		const value = e.target.value;
		if (regex.test(value)) {
			setNameCard(value);
			setNameCardError(false);
		}

	}
	const stripeElementChange = (e) => {
        setCardNumberError(false)
        setCardNumberErrorText("")
		if (!e.complete && typeof e.error !== "undefined") {
			setCardNumberError(true)
			setCardNumberErrorText(e?.error?.message)
		}
	}
	const stripeCvvChange = (e) => {
		setCardCvvError(false)
        setCardCvvErrorText("")
		if (!e.complete && typeof e.error !== "undefined") {
			setCardCvvError(true)
			setCardCvvErrorText(e?.error?.message)
		}
	}
	const stripeExpireChange = (e) => {
		setCardExpireError(false)
        setCardExpireErrorText("")
		if (!e.complete && typeof e.error !== "undefined") {
			setCardExpireError(true)
			setCardExpireErrorText(e?.error?.message)
		}
	}

    const handleValidation = () => {
        let formIsValid = true;
        
        if(!nameCard){
          formIsValid = false;
          setNameCardError(true)
        }
        const cardNumber = elements.getElement(CardNumberElement);
        if(cardNumber?._implementation?._empty){
            setCardNumberError(true)
        }
        const cardExpire = elements.getElement(CardExpiryElement);
        if(cardExpire?._implementation?._empty){
            setCardExpireError(true)
        }
        const cardCvv = elements.getElement(CardCvcElement);
        if(cardCvv?._implementation?._empty){
            setCardCvvError(true)
        }
        return formIsValid
      }

    const stripe = useStripe()
    const elements = useElements()
    const handleSubmit = async (e) =>{
        e.preventDefault()
        if(handleValidation()){
            setSubmitLoader(true);
           
          const card = elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement);
          const {error, token} = await stripe.createToken(card);
          if(!error){
            let formData = {
                "ID":localStorage.getItem("id"),
                "TokenID":token?.id
            }
          

            axios.post(API_URL + "UpdateCardInfo?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                } else {
                    toast.error(res.data.responseMessage);
                }
                clearFeilds();
                setNameCard("")
                setCardNumberError(false);
                
              setSubmitLoader(false)
                //setBtnDisable(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
                //setBtnDisable(false)
            })
          }else{
            toast.error(error?.message)
            setSubmitLoader(false)
          }
        }
    }
    return (
        <>
        {submitLoader ? <Loader /> : ""}
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Card Number <span className="red-tick">*</span></label>
                        <CardNumberElement className={cardNumberError ? "form-control error" : "form-control"} onChange={stripeElementChange} />
                        {cardNumberError ? <span className="error-message">{cardNumberErrorText}</span> : ""}

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Expiration Date <span className="red-tick">*</span></label>
                        <CardExpiryElement className={cardExpireError ? "form-control error" : "form-control"} onChange={stripeExpireChange} />
                        {cardExpireError ? <span className="error-message">{cardExpireErrorText}</span> : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">CVC / CVV <span className="red-tick">*</span></label>
                        <CardCvcElement className={cardCvvError ? "form-control error" : "form-control"} onChange={stripeCvvChange} />
                        {cardCvvError ? <span className="error-message">{cardCvvErrorText}</span> : ""}

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="firstname">Name on Card <span className="red-tick">*</span></label>
                        <input type="text" className={nameCardError ? "form-control error" : "form-control"} placeholder="Name on Card" value={nameCard} onChange={handleNameCard} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="formBtns">
                        <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                    </div>
                </div>
            </div>

        </>
    )
}