import React, { useState, useEffect } from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./../front/Loader";
import axios from 'axios';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};

toast.configure()
export default function EditInventory() {
    document.title = "BevPort - Edit Brewers"
    const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [loader, setLoader] = useState(false)
    const { brewerID } = useParams();
    const [stateList, setStateList] = useState([])
    const [fname, setFname] = useState("");
    const [fnameError, setFnameError] = useState(false);
    const [lName, setLname] = useState("");
    const [lNameError, setLnameError] = useState(false);
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneError, setPhoneError] = useState(false)
    const [businessName, setBusinessName] = useState("");
    const [businessNameError, setBusinessNameError] = useState(false);
    const [countryValue, setCountryValue] = useState({ label: "USA", value: "1" });
    const [counrtyError, setCountryError] = useState(false);
    const [stateValue, setStateValue] = useState("");
    const [stateError, setStateError] = useState(false)
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false)
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState(false);
    const [userData, setUserData] = useState("");
    const [ein, setEin] = useState("")
    const [einError, setEinError] = useState(false)
    const [stn, setStn] = useState("")
    let Navigate = useNavigate();


    // Bank info
 

    const [bankName, setBankName] = useState("");
    const [bankNameError, setBankNameError] = useState(false);
    const [bankAddress, setBankAddress] = useState("");
    const [bankAddressError, setBankAddressError] = useState(false);
    const [routingNumber, setRoutingNumber] = useState("");
    const [routingNumberError, setRoutingNumberError] = useState(false);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState(false);
    const [accountType, setAccountType] = useState("");
    const [accontTypeError, setAccountTypeError] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [accountNameError, setAccountNameError] = useState(false);


    // password------------------------------------------------------------------------


    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [cpassword, setcPassword] = useState("");
    const [cpasswordError, setcPasswordError] = useState(false);
    const [passwordTxt, setPasswordTxt] = useState(false);
    const [visible, SetVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);

    const handleShow = () => {
        SetVisible(!visible);
    }

    const handlePassword = (e) => {
        setPasswordError(false)
        setPasswordTxt(false)
        setPassword(e.target.value)
    }

    const handleConfirmPassword = (e) => {
        setcPasswordError(false)
        setPasswordTxt(false)
        setcPassword(e.target.value)
    }

    const handleConfirmShow = () => {
        setConfirmVisible(!confirmVisible)
    }

    //------------------------------------------------- end password ------------------


    const handleBankName = (e) => {
        setBankName(e.target.value);
        setBankNameError(false);
    }


    const handleBankAddress = (e) => {
        setBankAddress(e.target.value);
        setBankAddressError(false);
    }

  
    const handleRoutingNumber = (e) => {
        setRoutingNumber(e.target.value);
        setRoutingNumberError(false);

    }

    const handleAccountNumber = (e) => {
        setAccountNumber(e.target.value);
        setAccountNumberError(false);
    }

    const accountTypeOption = [
        { label: "Checking Account", value: "1" },
        { label: "Saving Account", value: "2" }
    ]

    const handleAccountType = (e) => {
        setAccountType({ value: e.value, label: e.label })
        setAccountTypeError(false);
    }

    const handleAccountName = (e) => {
        setAccountName(e.target.value);
        setAccountNameError(false)
    }


    const handleBankDetailsValidation = () => {
        let formIsValid = true;

        if (!bankName) {
            formIsValid = false;
            setBankNameError(true)
        }
        if (!bankAddress) {
            formIsValid = false;
            setBankAddressError(true)
        }
        if (!routingNumber) {
            formIsValid = false;
            setRoutingNumberError(true)
        }
        if (!accountNumber) {
            formIsValid = false;
            setAccountNumberError(true)
        }

        if (!accountType) {
            formIsValid = false;
            setAccountTypeError(true);
        }
        if (!accountName) {
            formIsValid = false;
            setAccountNameError(true);
        }

        return formIsValid;
    }

    const handleBankDetails = async (e) => {
        e.preventDefault()
        if (handleBankDetailsValidation()) {

            //console.log("sdf",);
            setLoader(true)
            //setBtnDisable(true)
            let formData = {
                "ID": brewerID,
                "Type": "Bank",
                "BankName": bankName,
                "AccountNumber": accountNumber,
                "AccountName": accountName,
                "AccountType": accountType?.value.toString(),
                "RoutingNumber": routingNumber,
                "BankAddress": bankAddress

            }

            await axios.post(API_URL + "UpdateUserInformation?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);

                    Navigate("/admin/brewers-list");

                    // Just refresh the UserInformation load---> and stay on the page works --
                    //getUserProfileFunction(localStorage.getItem("id"));
                } else {
                    toast.error(res.data.responseMessage);
                }
                setLoader(false)
                //setBtnDisable(false)
            }).catch(function (error) {
                console.log(error)
                setLoader(false)
                //setBtnDisable(false)
            })
        }
    }


    //--------------------------------------------------------------------------



    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const countryOption = [
        { label: "USA", value: "1" },

    ];

    useEffect(() => {

        getStateList();

    }, [])

    useEffect(() => {
        getUserProfileFunction(brewerID)
    }, [])

    useEffect(() => {
        if (brewerID) {
            setFname(userData?.firstName)
            setLname(userData?.lastName)
            setEmail(userData?.emailID)
            setPhoneNo(userData?.businessPhone)
            setBusinessName(userData?.businessName)
            setStateValue({ value: userData?.stateName, label: userData?.stateName, id: userData?.stateID })
            setCity(userData?.city);
            setStreet(userData?.streetAddress)
            setZip(userData?.zipCode)
            setStn(userData?.stateTaxNumber)
            setEin(userData?.ein)

            // bank information 
            setBankName(userData?.bankName);
            setBankAddress(userData?.bankAddress);
            setRoutingNumber(userData?.routingNumber);
            setAccountNumber(userData?.accountNumber);
            setAccountType({ "value": userData?.accountType, "label": userData?.accountTypeVal });
            setAccountName(userData?.accountName);
        }
    }, [brewerID, userData])


    const getUserProfileFunction = async (userID) => {
        await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": brewerID }, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setUserData(data)


            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })
    }

    const getStateList = async () => {
        await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode === "200") {
                let stateOption = [];
                let resData = res.data.responseData
                resData.forEach((da) => {

                    stateOption.push({ value: da.statename, label: da.statename, id: da.id });

                }
                );

                setStateList(stateOption)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleFirstName = (e) => {
        setFname(e.target.value);
        setFnameError(false);
    }

    const handleLastName = (e) => {
        setLname(e.target.value)
        setLnameError(false)
    }

    const handlePhoneNo = (e) => {
        setPhoneNo(e.target.value)
        setPhoneError(false);
    }

    const handleBusinessName = (e) => {
        setBusinessName(e.target.value);
        setBusinessNameError(false);
    }

    const handleEIN = (e) => {
        setEinError(false)
        setEin(e.target.value)
    }
    const handleSTN = (e) => {
        setStn(e.target.value)
    }

    const handleCountry = (e) => {
        setCountryValue({ value: e.value, label: e.label })
        setCountryError(false);

    }

    const handleState = (e) => {
        setStateValue({ value: e.value, label: e.label, id: e.id })
        setStateError(false)
    }

    const handleCity = (e) => {
        setCity(e.target.value);
        setCityError(false)
    }

    const handleStreetAddress = (e) => {
        setStreet(e.target.value)
        setStreetError(false);
    }

    const handleZIP = (e) => {
        setZip(e.target.value);
        setZipError(false);

    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!fname) {
            formIsValid = false;
            setFnameError(true)
        }
        if (!lName) {
            formIsValid = false;
            setLnameError(true)
        }
        if (!phoneNo) {
            formIsValid = false;
            setPhoneError(true)
        }
        if (!businessName) {
            formIsValid = false;
            setBusinessNameError(true)
        }
        if (!ein) {
            formIsValid = false;
            setEinError(true)
        }
        if (!countryValue) {
            formIsValid = false;
            setCountryError(true)
        }
        if (!city) {
            formIsValid = false;
            setCityError(true)
        }
        if (!stateValue) {
            formIsValid = false;
            setStateError(true)
        }
        if (!street) {
            formIsValid = false;
            setStreetError(true)
        }
        if (!zip) {
            formIsValid = false;
            setZipError(true)
        }
        return formIsValid;
    }





    const handleSubmit = async (e) =>
    {
        e.preventDefault()
        if (handleValidation()) {
            setLoader(true)
            let formData = {
                "UserID": brewerID,
                "FirstName": fname,
                "LastName": lName,
                "BusinessName": businessName,
                "StreetAddress": street,
                "BusinessPhone": phoneNo,
                "State": stateValue?.id,
                "Country": "1",
                "City": city,
                "ZipCode": zip,
                "EIN": ein,
                "StateTaxNumber": stn
            }

            await axios.post(API_URL + "UpdateProfile?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/brewers-list");
                } else {
                    toast.error(res.data.responseMessage);
                }
                setLoader(false)

                //setBtnDisable(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
                //setBtnDisable(false)
            })
        }
    }





    const handleValidation_UserPassword = () => {
        let formIsValid = true;

        if (!password) {
            formIsValid = false;
            setPasswordError(true)
        }
        if (!cpassword) {
            formIsValid = false;
            setcPasswordError(true)
        }
        if (cpassword && password !== cpassword) {
            formIsValid = false;
            setcPasswordError(true)
            setPasswordTxt(true)
        }
        return formIsValid;
    }



    const handleSubmit_UserPassword = async (e) => {
        e.preventDefault()
        if (handleValidation_UserPassword()) {
            setLoader(true)
            let formData = {
                "ID": brewerID,
                "Password": password
            }
            await axios.post(API_URL + "ChangeUserPassword?code=" + API_KEY, formData, config).then((res) => {
                setLoader(false)
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                }
            }).catch(function (error) {
                console.log(error)
                setLoader(false)
            })
        }
    }

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Edit Brewers</h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">First Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={fnameError ? "form-control error" : "form-control"} placeholder="First Name" value={fname} onChange={handleFirstName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Last Name<span className="red-tick"> *</span></label>
                                                    <input type="text" className={lNameError ? "form-control error" : "form-control"} placeholder="Last Name" value={lName} onChange={handleLastName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Email<span className="red-tick"> *</span></label>

                                                    <input type="text" className="form-control" placeholder="Email" value={email} onChange={handleEmail} readOnly={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Phone No.<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='(999) 999 9999'
                                                        value={phoneNo}
                                                        onChange={handlePhoneNo}
                                                        className={phoneError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="Phone No."
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Business Name<span className="red-tick"> *</span></label>

                                                    <input type="text" className={businessNameError ? "form-control error" : "form-control"} placeholder="Business Name" value={businessName} onChange={handleBusinessName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">Employer Identification Number <span className="red-tick">*</span></label>
                                                    <InputMask
                                                        /*mask='999-999999999'*/  // old mask
                                                        mask='99-9999999' 
                                                        value={ein}
                                                        onChange={handleEIN}
                                                        className={einError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="EIN"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="firstname">State Tax Number</label>
                                                    <InputMask
                                                        mask='999-99-9999'
                                                        value={stn}
                                                        onChange={handleSTN}
                                                        className="form-control"
                                                        maskChar={null}
                                                        placeholder="If Different From EIN"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Country <span className="red-tick"> *</span></label>
                                                    <Select className={counrtyError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={countryOption}
                                                        onChange={handleCountry}
                                                        value={countryValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">Country</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">State<span className="red-tick"> *</span></label>
                                                    <Select className={stateError ? "selectTodesign error" : "selectTodesign"}
                                                        styles={customStyles}
                                                        //menuPlacement="top"
                                                        options={stateList}
                                                        onChange={handleState}
                                                        value={stateValue}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<div className="italic-placeholder">State</div>}
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">City <span className="red-tick"> *</span></label>
                                                    <input type="text" className={cityError ? "form-control error" : "form-control"} placeholder="City" value={city} onChange={handleCity} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Street Address<span className="red-tick"> *</span></label>

                                                    <input type="text" className={streetError ? "form-control error" : "form-control"} placeholder="Street Address" value={street} onChange={handleStreetAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">ZIP Code<span className="red-tick"> *</span></label>
                                                    <InputMask
                                                        mask='99999'
                                                        value={zip}
                                                        onChange={handleZIP}
                                                        className={zipError ? "form-control error" : "form-control"}
                                                        maskChar={null}
                                                        placeholder="ZIP Code"
                                                    >
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <h1 style={{ color: "white", fontSize: "28px", paddingLeft: "20px" }}>Bank Information</h1>
                        </div>
                        <div id="bankInfoDiv" className="fixtopContentBg">
                            <div className="InsertForms card-Content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="brand-tab">Bank Name<span className="red-tick"> *</span></label>
                                            <input type="text" className={bankNameError ? "form-control error" : "form-control"} placeholder="Bank Name" value={bankName} onChange={handleBankName} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="brand-tab">Bank Address<span className="red-tick"> *</span></label>
                                            <input type="text" className={bankAddressError ? "form-control error" : "form-control"} placeholder="Bank Address" value={bankAddress} onChange={handleBankAddress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="brand-tab">Routing Number<span className="red-tick"> *</span></label>
                                            <InputMask
                                                mask='999999999'
                                                value={routingNumber}
                                                onChange={handleRoutingNumber}
                                                className={routingNumberError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="Routing Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="brand-tab">Account Number<span className="red-tick"> *</span></label>
                                            <InputMask
                                                mask='999999999'
                                                value={accountNumber}
                                                onChange={handleAccountNumber}
                                                className={accountNumberError ? "form-control error" : "form-control"}
                                                maskChar={null}
                                                placeholder="Account Number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="brand-tab">Bank Account Type<span className="red-tick"> *</span></label>
                                            <Select className={accontTypeError ? "selectTodesign error" : "selectTodesign"}
                                                styles={customStyles}
                                                //menuPlacement="top"
                                                options={accountTypeOption}
                                                onChange={handleAccountType}
                                                value={accountType}
                                                closeMenuOnSelect={true}
                                                placeholder={<div className="italic-placeholder">Bank Account Type</div>}
                                                isSearchable
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="brand-tab">Name on Account<span className="red-tick"> *</span></label>
                                            <input type="text" className={accountNameError ? "form-control error" : "form-control"} placeholder="Name on Account" value={accountName} onChange={handleAccountName} />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="formBtns">
                                            <button type="button" className="btn btn-primary save-button" onClick={handleBankDetails} >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>


                    <div className="fixtopContentBg">
                        <div className="card-Content">
                            <div className="Content-body">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a data-toggle="tab" href="#home">Change Password</a></li>
                                </ul>
                                <div className="tab-content">
                                    <div id="home" className="tab-pane fade in active">
                                        <div className="InsertForms">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">Password<span className="red-tick"> *</span></label>
                                                        <div className="showhidepassowrd">
                                                            <input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} placeholder="Password" onChange={handlePassword} />
                                                            <span className="eyeslash" onClick={handleShow}><i className={!visible ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="brand-tab">Confirm Password<span className="red-tick"> *</span></label>
                                                        <div className="showhidepassowrd">
                                                            <input type={confirmVisible ? "text" : "password"} className={cpasswordError ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleConfirmPassword} />
                                                            {passwordTxt ? <span className="error-message">Confirm password should be same as password.</span> : ""}
                                                            <span className="eyeslash" onClick={handleConfirmShow}><i className={!confirmVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="formBtns">
                                                        <button type="button" className="btn btn-primary save-button" onClick={handleSubmit_UserPassword}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}