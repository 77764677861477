import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Logo from './../../assets/images/logo.png'
import Select from 'react-select'; // added 6/20/2024
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function ProductList() {
	document.title = "BevPort - Product List"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [productList, setProductList] = useState([])
	const [productListTemp, setProductListTemp] = useState([])
	const [submitLoader, setSubmitLoader] = useState(true)
	const [nodata, setNodata] = useState(0);
	const [loader, setLoader] = useState(false)


	// dropdown custom styles ----------------
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};
	const [sortList, setSortList] = useState([]);
	const [sortValue, setSortValue] = useState(null);
	//----------------------------------------


	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		getProductList();


		// set the dropdown values for the sort dropdown of products
        let sortOption = []
		sortOption.push({ value: "", label: "All", id: 1 });
		sortOption.push({ value: "1", label: "Active", id: 2 });
		sortOption.push({ value: "0", label: "Inactive", id: 3 });

		setSortList(sortOption);
		//setSortValue({ value: e.value, label: e.label });



	}, [])
	useEffect(() => {
		if (!productList.length) {
			setNodata(1);
		} else {
			setNodata(0);
		}
	}, [nodata, productList])
	const getProductList = async () => {
		const formData = {
			"UserID": localStorage.getItem("id")
		}
		//setSubmitLoader(true);
		await axios.post(API_URL + "GetAllProductsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
				if (data.length) {
					setNodata(0);
				} else {
					setNodata(1);
				}
			} else {
				setNodata(1);
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}


	const getProductListAll = async () => {

		setSubmitLoader(true);
		const formData = {
			"UserID": localStorage.getItem("id")
		}
		//setSubmitLoader(true);
		await axios.post(API_URL + "GetAllProductsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
				if (data.length) {
					setNodata(0);
				} else {
					setNodata(1);
				}
			} else {
				setNodata(1);
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}


	const getSortedProducts = async (ddlValue) => {

		setSubmitLoader(true);

		const formData = {
			"UserID": localStorage.getItem("id"),
			"Status": ddlValue
		}
		//setSubmitLoader(true);
		await axios.post(API_URL + "GetSortedProductsList?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				let data = res.data?.responseData;
				setProductList(data)
				setProductListTemp(data)
				if (data.length) {
					setNodata(0);
				} else {
					setNodata(1);
				}
			} else {
				setNodata(1);
				//toast.error(res.data.responseMessage)
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	//--------------- end get sorted Products ---------------------



	const handleActiveInactiveStatus = async (productID, status) => {
		let formData = {
			"ID": productID,
			"Status": (status) ? 0 : 1
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeProductStatus?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				getProductList()
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}
	const [searchText, setSearchText] = useState("")
	const handleSearchBox = (e) => {
		setSearchText(e.target.value)
		handleSearchInput(e.target.value);
	}
	function handleSearchInput(e) {
		// e.preventDefault()
		if (e === "") { setNodata(0); setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(e.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(e.toLowerCase()) || (item?.beerTypeVal).toLowerCase()
						.includes(e.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}
	function handleSearchClick(e) {
		e.preventDefault()
		if (searchText === "") { setNodata(0); setProductList(productListTemp); return; }
		const filterBySearch = productListTemp.filter((item) => {
			if ((item?.productName).toLowerCase()
				.includes(searchText.toLowerCase()) || (item?.brandName).toLowerCase()
					.includes(searchText.toLowerCase()) || (item?.beerTypeVal).toLowerCase()
						.includes(searchText.toLowerCase())) { return item; }
		})
		setProductList(filterBySearch);
	}
	const handleAlertBox = () => {
		toast.error("You don't have selling price. Please enter selling price before activating the product.")
	}
	const handleAlertCompBox = (status) => {
		if(status === 90){
			toast.error("You can't activate your product because product compliance is rejected by Admin.")
		}else{
			toast.error("You can't activate your product because product compliance is not approved by Admin.")
		}
		
	}

	// --- handle on change dropdown sort option in top left corner of ui
	const handleSortOnChange = (e) => {

		setSortValue({ value: e.value, label: e.label });

		if (e.value === "") {
			getProductListAll(); // get all Products
		}

		else {
			getSortedProducts(e.value);
		}		

	}
	//---
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							{/* {(productList.length) ? <h1>Products</h1> : ""} */}
							<h1>Products</h1>
						</div>
						<div className="blank150"></div>
						<div className="searchOntopPage">
							<div className="container-fluid">

							</div>
						</div>

						<div className="fixtopContentBg">
							<div className="card-Content">
								<form onSubmit={handleSearchClick}>
									<div className="row">


										<div className="col-md-3">
											

											<Select
												styles={customStyles}
												options={sortList}
												onChange={handleSortOnChange}
												value={sortValue}
												closeMenuOnSelect={true}
												placeholder={<div className="italic-placeholder">Sort List</div>}
												isSearchable
											/>

											{/*<select>*/}
											{/*</select>*/}
										</div>

										<div className="col-md-6 pull-right">


											<div className="searchBox">
												<div className="input-group">
													<div className="input-group-btn">
														<button className="btn btn-default" type="submit"><i className="glyphicon glyphicon-search"></i></button>
													</div>
													<input type="text" className="form-control" placeholder="Search Products" onChange={handleSearchBox} />

												</div>
											</div>
										</div>
									</div>
								</form>
								<div className="Content-body productListingPage">
									<h3 className="card-heading"></h3>
									<div className="row">
										{
											productList.length ? productList.map((_v, i) => {
												let perCasePrice = 0;
												let u = (_v?.caseSizeVal).split(" ")
												if (_v?.unitPer === "unit") {
													perCasePrice = _v?.bevPortListingPrice * u[0]
												}
												if (_v?.unitPer === "pack") {
													let quantityPerPack = 0;
													if (_v?.packageSizeVal === "Singles") {
														quantityPerPack = 1
													} else {
														let u0 = (_v?.packageSizeVal).split(" ")
														quantityPerPack = u0[0]
													}
													perCasePrice = (_v?.bevPortListingPrice / quantityPerPack) * u[0]
												}

												const approvedDiv = _v?.complianceSubmitted === 3 && <span className='approvedLabel'>Approved</span>;
												const pendingDiv = _v?.complianceSubmitted == 1 && <span className='newLabel'>Pending</span>;
												const rejectedDiv = _v?.complianceSubmitted == 90 && <span className='rejectedLabel'>Rejected</span>;

												return (
													<div className="col-md-3 mb-20">
														<Card sx={{ maxWidth: 345 }} className="product-list-component">

															{approvedDiv}
															{pendingDiv}
															{rejectedDiv}

															<Link to={"/admin/product-detail/" + _v?.id}>
																<CardMedia
																	component="img"
																	className="product-image"
																	alt={_v?.productName}
																	height="210"
																	image={(_v?.filePath) ? _v?.filePath : Logo}
																/>
															</Link>
															<CardContent>
																<Typography gutterBottom variant="h4" component="div">
																	{_v?.productName}
																</Typography>
																<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																	{_v?.brandName}
																</Typography>
																<Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px' }}>
																	{_v?.beerTypeVal}
																</Typography>
															</CardContent>
															<Divider />
															<CardContent className="ProductListCardContent">
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Can Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.canSizeVal}
																		</Typography>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Case Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.caseSizeVal}
																		</Typography>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Pack Size
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.packageSizeVal}
																		</Typography>
																	</div>
																</div>
																{
																	_v?.bevPortListingCasePrice ?
																		<div className="row mutliplePrices">
																			<div className="col-md-6">
																				<Typography gutterBottom variant="h6" component="div">
																					Case Price
																				</Typography>
																			</div>
																			<div className="col-md-6">
																				<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																					${parseFloat(_v?.bevPortListingCasePrice).toFixed(2)}
																				</Typography>

																			</div>
																		</div> : ""}
																{
																	_v?.bevPortListingPackPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingPackPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""}
																{
																	_v?.bevPortListingUnitPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Unit Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingUnitPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""
																}

																<div className="row">
																	<div className="col-md-6">
																		<Typography gutterBottom variant="h6" component="div">
																			Status
																		</Typography>
																	</div>
																	<div className="col-md-6">
																		<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																			{_v?.status == "1" ? "Active" : "Inactive"}
																		</Typography>
																	</div>
																</div>
															</CardContent>
															<CardActions className="cardactionBtns buttonWidth">
																<Link className="btn btn-secondary" to="#" disabled={(_v?.complianceSubmitted == 1 || _v?.complianceSubmitted == 0)?true:false} onClick={() => (_v?.bevPortListingUnitPrice != "0" || _v?.bevPortListingPackPrice != "0" || _v?.bevPortListingCasePrice != "0")?((_v?.complianceSubmitted == 3)?handleActiveInactiveStatus(_v?.id, _v?.status):handleAlertCompBox(_v?.complianceSubmitted)):handleAlertBox()}>{(_v?.status) ? "Deactivate" : "Activate"}</Link>
																<Link className="btn btn-primary" to={"/admin/edit-product/" + _v?.id}>Manage</Link>


															</CardActions>
														</Card>
													</div>
												)
											}) :
												""}

									</div>
									<div className="col-md-12" style={{ "display": (nodata) ? "block" : "none" }} >
										There are no records to display!
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}
