import React, { useState, useEffect } from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./../front/Loader";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
;
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};

toast.configure()
export default function EditInventory() {
    document.title = "BevPort - Edit Inventory"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false);

    const [quantity, setQuantity] = useState("");
    const [quantityError, setQuantityError] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);

    const [remark, setRemark] = useState("");
    const { productID } = useParams();
    const [type, setType] = useState("");
    const [brandID, setBrandID] = useState("");
    const [userID, setUserID] = useState("");

    let Navigate = useNavigate();
    let { state } = useLocation();
    //--

    //
    const [invCategoryList, setInvCategoryList] = useState([]);
    const [invCategoryID, setInvCategoryID] = useState(15);
    const [invCategory, setInvCategory] = useState("-Select-");
    const [invCategoryError, setInvCategoryError] = useState(false);
    const [invCategoryValue, setinvCategoryValue] = useState({ value: '-Select-', label: '-Select-', id: 0 });

    useEffect(() => {
        if(state){
        setType(state?.type);
        setBrandID(state?.brandID);
        setUserID(state?.userID);
        }

        // load the category lis t
        let invCategoryOption = [];       
        invCategoryOption.push({ value: '-Select-', label: '-Select-', id: 0 }, { value: '-DAMAGED-', label: '-DAMAGED-', id: 1 }, { value: 'SAMPLES', label: 'SAMPLES', id: 2 }, { value: 'MISCOUNT', label: 'MISCOUNT', id: 3 });
        setInvCategoryList(invCategoryOption);

      



    }, [state])


    // jesse dudley useeffect 10/6/2024 - used for page load checking type value --- add or subract page -- 

    useEffect(() => {
        
        if (type === "plus") {
            document.getElementById("minusPlusLabel").innerHTML = "+Plus Qty";
            document.getElementById("txtQtyInCases").placeholder = "Positive Quantity Only";
        }

        else if (type === "minus")
        {
            document.getElementById("minusPlusLabel").innerHTML = "-Minus Qty";
            document.getElementById("txtQtyInCases").placeholder = "Negative Quantity Only"; 
        }
        else {


           // document.getElementsByName('Email')[0].placeholder = 'new text for email';
        }


    })
    // end use effect blank ------------------------------------------------


    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const handleQuantity = (e) => {
        setQuantity(e.target.value)
        setQuantityError(false);
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!quantity) {
            formIsValid = false;
            setQuantityError(true)
        }

        // Category Change Error ---
        if (invCategory == "-Select-") {
            formIsValid = false;         
            setInvCategoryError(true);
        }

        return formIsValid;
    }

    const handleFloatNumber = (e) => {

        //alert(e.target.value);

        if (type === "plus") {
            if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
            }
        }

        else {

            if ((e.which != 45) && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
            }

        }

       
    }

    const handleRemark = (e) => {
        setRemark(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation())
        {
            setSubmitLoader(true)
            let formData = {
                ID: userID,
                BrandID: brandID,
                ProductID: productID,
                InventoryType: invCategory, //(type == "plus") ? "Added" : "Damaged", <--- old code ---> jesse dudley changed 9/17/2024
                Quantity: quantity,
                WareHouseID: "1",
                Remarks: remark
            }


            await axios.post(API_URL + "UpdateInventory?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    Navigate("/admin/inventory");
                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)

                //setBtnDisable(false)
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
                //setBtnDisable(false)
            })
        } //end validation if
    }


    //-------

    const handleInvCategory = (e) => {
        setInvCategoryID(e.id);
        setInvCategory(e.value);
        setinvCategoryValue({ value: e.value, label: e.label })
        setInvCategoryError(false);
    }

    return (
        <>
            {submitLoader ? <Loader /> : ""}
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? "wholeCompoundRight" : "wholeCompoundRight fullwidth"}>
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Edit Inventory</h1>
                        </div>


                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className="InsertForms" style={{ paddingTop: 0 }}>

                                        <div className="row">

                                            <div className="col-md-12 text-center">
                                                <label id="minusPlusLabel" style={{ color: "black" }}>-Minus Qty</label>
                                            </div>
                                        </div>

                                        <div className="row">


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="brand-tab">Quantity In Case's<span className="red-tick"> *</span></label>

                                                    <input id="txtQtyInCases" type="text" className={quantityError ? "form-control error" : "form-control"} placeholder="Quantity In Case" value={quantity} onChange={handleQuantity} onKeyPress={handleFloatNumber} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                
                                                    <div className="form-group">
                                                        <label className="brand-tab">Changed Category <span className="red-tick"> *</span></label>
                                                        <Select className={invCategoryError ? "selectTodesign error" : "selectTodesign"}
                                                            styles={customStyles}
                                                            menuPlacement="bottom"
                                                            options={invCategoryList}
                                                            onChange={handleInvCategory}
                                                            value={invCategoryValue}
                                                            closeMenuOnSelect={true}
                                                            placeholder={<div className="italic-placeholder">Changed Category</div>}
                                                            isSearchable
                                                            
                                                        />
                                                    </div>
                                            
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="brand-tab">Remark</label>
                                                    <input type="text" className="form-control" placeholder="Remark" value={remark} onChange={handleRemark}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <button type="button" className="btn btn-primary save-button" onClick={handleSubmit} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}