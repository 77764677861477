import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../../front/Loader";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function States() {
    document.title = "BevPort - States"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [state, setState] = useState();
    const [buttonName, setButtonName] = useState("Submit")
    const [stateID, setStateID] = useState("")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    const handleStatus = async (status, id) => {
        setLoader(true);
        let formData = {
            "ID": id,
            "Status": status
        }
        await axios.post(API_URL + "ChangeStateStatus?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getStateList();
                toast.success(res.data.responseMessage)
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    useEffect(() => {
        getStateList();
    }, [])
    const getStateInfo = async (id) => {
        setLoader(true);
        let formData = {
            "ID": id
        }
        await axios.post(API_URL + "GetStateInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                let data = res.data.responseData
                setStateCode(data?.statecode)
                setStateName(data?.statename)
                setButtonName("Update")
                setStateID(data?.id)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const getStateList = async () => {
        await axios.post(API_URL + "GetAllStateData?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setData(res.data.responseData);
                console.log("res",res.data.responseData );
                setSubmitLoader(false);
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const columns = [
        {
            name: 'States Code',
            selector: row => (row.statecode).toLowerCase(),
            cell: row => row.statecode,
            sortable: true,
        },
        {
            name: 'States Name',
            selector: row => (row.statename).toLowerCase(),
            cell: row => row.statename,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == "0") ? <span style={{ "color": "#00aeef" }}>Inactive</span> : <span style={{ "color": "#00aeef" }}>Active</span>,
            sortable: true,
        },
        {
            name: 'Action',
            width: '250px',
            selector: row => <>
                <Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "0") ? "1" : "0", row.id)}>{(row.status == "1") ? "DEACTIVATE" : "Activate"}</Link>&nbsp;<Link className="btn btn-primary" to={"#"} onClick={() => getStateInfo(row.id)}>Edit</Link>&nbsp;</>,
        }
    ];
    const [stateCode, setStateCode] = useState("")
    const [stateCodeError, setStateCodeError] = useState(false)
    const [stateName, setStateName] = useState("")
    const [stateNameError, setStateNameError] = useState(false)
    const handleStateCode = (e) => {
        setStateCodeError(false)
        setStateCode(e.target.value)
    }
    const handleStateName = (e) => {
        setStateNameError(false)
        setStateName(e.target.value)
    }
    const handleValidate = () => {
        let formValid = true;
        if (!stateCode) {
            setStateCodeError(true)
            formValid = false;
        }
        if (!stateName) {
            setStateNameError(true)
            formValid = false;
        }
        return formValid;
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        if (handleValidate()) {
            if (stateID) {
                updateRecord()
            } else {
                insertRecord()
            }

        }
    }
    const insertRecord = async () => {
        setLoader(true);
        let formData = {
            "StateCode": stateCode,
            "StateName": stateName
        }
        await axios.post(API_URL + "AddStateData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getStateList();
                toast.success(res.data.responseMessage)
                setStateCode("")
                setStateName("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }
    const updateRecord = async () => {
        setLoader(true);
        let formData = {
            "ID": stateID,
            "StateCode": stateCode,
            "StateName": stateName
        }
        await axios.post(API_URL + "UpdateStateData?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                getStateList();
                toast.success(res.data.responseMessage)
                setStateCode("")
                setStateName("")
                setButtonName("Submit")
                setStateID("")
            } else {
                toast.error(res.data.responseMessage)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
        })
    }

    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>States</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body">
                                    <div className='wizardShowOnly'>
                                        <form className="form" onSubmit={handleSubmitForm}>
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className="form-group mx-sm-3 mb-2">
                                                        <label for="inputPassword2" >State Code</label>
                                                        <input type="text" className={stateCodeError ? "form-control error" : "form-control"} placeholder="State Code" onChange={handleStateCode} value={stateCode} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group mx-sm-3 mb-2">
                                                        <label for="inputPassword2" >State Name</label>
                                                        <input type="text" className={stateNameError ? "form-control error" : "form-control"} placeholder="State Name" onChange={handleStateName} value={stateName} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button type="submit" className="btn btn-primary mb-2">{buttonName}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='tbaShowcase'>
                                        <div className="Content-body dataTableSearchBox">
                                            <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search States">
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                    //defaultSortFieldId={1}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}