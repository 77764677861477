import { Link ,useNavigate} from "react-router-dom";
import Logo from './../../assets/images/logo.png'
import {useState} from 'react' 
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function Login(){
	document.title = "BevPort - Admin Login"
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [usernameError, setUsernameError] = useState(false);
	const [btnText, setButtonText] = useState("Login")
	const [isDisable, setIsDisbale] = useState(false)
	const updateUsername = (event) => {
		setUsernameError(false)
		setUsername(event.target.value);
	};
	const updatePassword = (event) => {
        setPasswordError(false)
		setPassword(event.target.value);
	};
	const handleSubmit = (event) =>{
        event.preventDefault();
        if(handleValidation()){
        	setButtonText("Please Wait...")
        	setIsDisbale(true)
        	 axios.post(API_URL+"LoginAdmin?code="+API_KEY,{
        	    "EmailID":username,
        	    "Password":password
        	    
            }, config).then((res) => {
            	console.log(res?.data);
            	if(res?.data?.responseCode == "200"){
            		toast.success(res.data.responseMessage);
            		let resData = res.data.responseData
            		localStorage.setItem("id", resData?.id);
					localStorage.setItem("isAuthentic", true);
					localStorage.setItem("userType", "admin");
					localStorage.setItem("firstName", resData?.name);
            		navigate("/admin/dashboard");
            	}else{
            		toast.error(res.data.responseMessage);
            	}
            	setButtonText("Login")
        		setIsDisbale(false)
            }).catch(() => {
            	setButtonText("Login")
        		setIsDisbale(false)
            })
        }
    }
       
        
    const handleValidation = () => {
        let formValid = true;
        if(!username){
			formValid = false;
        	setUsernameError(true)
        }
        if(!password){
			formValid = false;
            setPasswordError(true)
        }
        return formValid;
   }
   const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

    const validate = () => {
    	let formIsValid = true;
        if(!username){
        	setUsernameError(true)
        	formIsValid = false
        }
        if(username && !validateEmail(username)){
        	setUsernameError(true)
        	formIsValid = false
        }
        if(!password){
        	setPasswordError(true)
        	formIsValid = false
        }
        return formIsValid;
    }
   

	document.title = "BevPort - Admin Login"
	return(
		<>
			<div className="lgoinWrapper">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="loignPop">								
								<div className="whitebgContain">	
									<div className="loginLogoImg">
										<img src={Logo} />
									</div>
									<form onSubmit={handleSubmit}>								
									    <div className="form-group">
										    <label className="brand-tab">Username <span className="red-tick">*</span></label>
										    <input type="text" className={usernameError?"form-control error":"form-control"} placeholder="Username" value={username} onChange={updateUsername} disabled={isDisable}/>
									    </div>
									    <div className="form-group">
										    <label className="brand-tab">Password <span className="red-tick">*</span></label>
										    <input type="password" className={passwordError?"form-control error":"form-control"} placeholder="Password" value={password} onChange={updatePassword} disabled={isDisable}/>
									    </div>
									
									    {/*<div className="remeberAndForgot">
									 	    <div className="remebercheck">
											    <input className="form-check-input" type="checkbox" id="form1Example3" autocompleted="off" value="" />
										        <label className="form-check-label" for="form1Example3">Remember password</label>
									 	    </div>
										    <div className="forgotpswd">
											    <Link className="forgetpass" to="#">Forgot Password?</Link>
									 	    </div>
									    </div>*/}
									    <div className="logibtns">
										    <button type="submit" name="subxcmit" className="loginb" onClick={validate} disabled={isDisable}>{btnText}</button>
									    </div>
									</form>
								    </div>
								        <p className="copyrightLogin">Copyrights © 2023 All Rights Reserved by BevPort.</p>
							        </div>
							    </div>
					        </div>
				        </div>
			        </div>
	    </>
	)
}