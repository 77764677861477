import aboutbgd from "./../../assets/images/aboutbgd.png"
import beerWindows from "./../../assets/images/beerwindows.jpg"
import philipguana from "./../../assets/images/philipguana.jpg"
import jameswilliams from "./../../assets/images/james-williams.jpg"
import bevind from "./../../assets/images/craftbrew.jpg"
import bevworld from "./../../assets/images/chilled.jpg"
import fastcompany from "./../../assets/images/beerandbrewering.jpg"
import Header from './Header';
import Footer from './Footer';
import { useEffect } from "react"

export default function Index(){
	document.title = "BevPort - About"
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
	    if (script) {
	      	script.remove();
	    }
	    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
	    if (recaptchaElems.length) {
	      	recaptchaElems[0].remove();
	    }
	},[])
	return(
		<>
			<div className="site-wrapper">
        		<div className="main-wrapper">
			<Header />
			<section className="simplyfying Empowering" id="simplys">
				<div className="simplyfyingDiv">
				  <div className="container-fluid">
				    <div className="row upcontent">
				      <div className="col-md-5 simplecontent aboutmainBg"> 
				        <div className="simplecontentimg"> 
				          <img className="img-responsive" src={aboutbgd} />  
				        </div>    
				      </div>
				      <div className="col-md-7 manBgimg">
				        <div className="manImg">
				          <img className="img-responsive" src={beerWindows} />
				        </div>
				      </div>
				    </div>
				    <div className="row">
				    <div className="moveTopUp aboutheadphone">
				      <div className="container">
				        <div className="row main-contentprt">
				          <div className="col-md-4">
				            <div className="textSimply muddtext">
				              <h1><span className="darkblacktext">The BevPort</span>Vision</h1> 
				              <p>In the dynamic sphere of beverage distribution, BevPort emerges as a trailblazer, led by seasoned beverage industry veterans Phil Guana and James Williams. With their decades of robust experience and their shared vision is the bedrock of our operations. We are a collective of passionate industry experts driven by this mutual ambition: To revolutionize beer distribution.</p>             
				            </div>
				          </div>
				          <div className="col-md-8">
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>

				</div>
				</section>

				<section className="GenesisBevPort" id="GenesisBevPortStory">
				<div className="Persistence">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">        
				        <h1>The Genesis of BevPort: A Story of Passion and Persistence</h1>
				      </div>
				    </div>
				    <div className="row">
				      <div className="col-md-6">
				        <p className="authorintro">Philip Guana and James Williams had always known they were kindred spirits. Both men, passionate about the beverage industry, had navigated its ebb and flow for years, garnering experience and insights that were as diverse as they were profound. Philip, the owner of a successful wine and spirits import and distribution firm, was a steadfast entrepreneur with an eye for innovation with years of experience working with National chains.</p>
				        <p className="StoryofPassion">James, on the other hand, had tasted the industry’s various flavors, starting his career with a distributor, before swiftly adapting to roles of increasing responsibility and diversity. Becoming, one of top industry professionals in just a few years. James’s vision and innovative sales techniques changes the beverage sales industry.</p>
				        <p className="StoryofPassion">In 2012, their paths crossed. Their shared love for the industry, coupled with their restless ambition to drive change, led to many years of working together and friendship. In 2016 Phil founded Edge Beverage Consulting to help small business owners gain access to National retailers and advocate for their brands in an industry controlled by a handful of Brands and distributors. In 2019, James joined Edge Beverage and together they built a successful business helping hundreds of brands.</p>
				        <p className="StoryofPassion">Through their time together, they spoke about the inefficiencies in beverage distribution, the challenges that emerging brands faced, and the transformative potential that lay untapped.
				        </p>
				      </div>
				      <div className="col-md-6">				        
				        <p className="StoryofPassion">After a series of conversations and visualizing ways to create something new, the idea of BevPort was born. As owners of a beer brand themselves, they shared difficult stories about brewers who struggled to break into the distribution scene despite having a superior product. There was an unmistakable spark of an idea in their exchange - a concept that could revolutionize the beer world!</p>
				        <p className="StoryofPassion">Then as fate would have it, they met Bryan Bechard from Country Malt Group, looking for a way to create more synergy with his clients. They discussed how they could combine resources and how that would look. The more they deliberated, the more they saw it. A platform that could streamline the distribution process, give voice to emerging brands, and empower the businesses that relied on them. And thus, BevPort was conceived - an embodiment of their shared vision to transform the beverage industry.</p>
				        <p className="StoryofPassion">The following months were a whirlwind of planning, strategy sessions, coding marathons, and relentless refinements. Philip’s business acumen combined with James’ deep industry knowledge drove them towards their ambitious goal.</p>
				        <p className="StoryofPassion">The rest, as they say, is history. BevPort came to life, combining the industry know-how of its founders with cutting-edge technology and help  from Country Malt Group. Today, it stands as a testament to Phil and James’ vision - poised to redefine the landscape of the beverage industry, one brand at a time. Their commitment to creating a more accessible and effective distribution process for small and emerging brands worldwide continues to drive BevPort forward, making it a force to be reckoned with in the beverage world.</p>
				      </div>
				    </div>
				  </div>
				</div>
				</section>

				<section className="partnerpart" id="partnersection">
				<div className="partnersdiv">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-6">
				    <div className="partner-member fisrtchild">
				      <img src={philipguana} />
				      <h2>Philip Guana</h2>
				      <p>A proven leader in the beverage sector, Philip Guana brings a wealth of experience spanning over two decades to BevPort. His journey, from owning a prosperous wine and spirits import and distribution firm to serving in executive roles for suppliers, has ingrained him with a unique industry perspective and an unyielding commitment to innovation.</p>
				      <div className="readMorebtn"><a href="#">Read More</a></div>
				    </div>
				  </div>
				    <div className="col-md-6">
				    <div className="partner-member secondchild">
				      <img src={jameswilliams} />
				      <h2>James Williams</h2>
				      <p>James Williams is an embodiment of versatility in the beverage industry. Over the past 20 years, James has navigated through various roles and tiers of the sector. Beginning his career with a distributor, he rapidly adapted to change, demonstrating his agility and deep understanding of the industry’s nuances.</p>
				      <div className="readMorebtn"><a href="#">Read More</a></div>
				    </div>
				  </div>
				    </div>
				  </div>  
				</div>
					</section>


					{/*// jesse dudley commented this 4/8/2024 might want to remove images later for more clean up ---> from images folder*/}
				{/*<section className="mediacoverage">*/}
				{/*<div className="mediaDiv">*/}
				{/*  <div className="container">*/}
				{/*    <div className="row">*/}
				{/*      <div className="col-md-12">*/}
				{/*        <h1>Media Coverage</h1>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="row toplines">*/}
				{/*      <div className="col-md-6">*/}
				{/*        <div className="mediaimg">*/}
				{/*          <img src={bevind} />*/}
				{/*        </div>*/}
				{/*      </div>*/}
				{/*      <div className="col-md-6">*/}
				{/*        <div className="mediaimg">*/}
				{/*          <img src={fastcompany} />*/}
				{/*        </div>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				    				    
				{/*  </div>*/}
				{/*</div>*/}
				{/*</section>*/}
			<Footer />
			</div>
			</div>
		</>
	)
}