

import FooterLogo from "./../../assets/images/base_logo_wht.png"
import LinkedIn from "./../../assets/images/icons8-linkedin-240.png"
import ExampleDoc from "./../../assets/WordDoc/DistributionContract2.pdf"

import Members1 from "./../../assets/images/Members/members1.png"
import Members2 from "./../../assets/images/Members/members2.png"
import Members3 from "./../../assets/images/Members/members3.png"
import Members4 from "./../../assets/images/Members/members4.png"

import { Link } from "react-router-dom";
export default function Footer(){
	return(
		<>

			<section style={{ backgroundColor: "#f7941d", border:"1px solid #292727" }} >
				<div className="container">
				

						<div className="row">
							<div style={{ marginBottom: "20px" }} className="col-md-12 text-center">
								<label style={{ color: "white", fontSize: "20px", marginTop:"20px" }}>BevPort Memberships and Partners</label>
							</div>
						</div>


						<div className="row text-center">
							<div style={{ marginBottom: "25px" }} className="col-md-3">
								<img width="115" height="115" src={Members1} />
							</div>
							<div style={{ marginBottom: "25px" }} className="col-md-3">
								<img width="90" height="115" src={Members2} />
							</div>
							<div style={{ marginBottom: "25px", paddingTop: "11px" }} className="col-md-3">
								<img width="135" height="95" src={Members3} />
							</div>
							<div style={{ marginBottom: "25px" }} className="col-md-3">
								<img width="65" height="115" src={Members4} />
							</div>
						</div>
					
				</div>

			</section>


			<section className="footer" id="myftr">
				<div className="container">

				
				  <div className="row">
				    <div className="col-md-4">
				        <div className="ftrlogo">
				          <Link to="/"><img src={FooterLogo} /></Link>
				        </div>
				        <p className="ftparas">BevPort streamlines beverage distribution, optimizes inventory, and connects suppliers with retailers for enhanced operations and exceptional customer experiences.</p>
				        <ul className="social-links">				     
							<li><Link to="https://www.linkedin.com/company/bevport-distribution-llc/"><img src={LinkedIn}/></Link></li>				            	
				        </ul>
				    </div>
				     <div className="col-md-4">
				        <h2 className="footer-heading">Trending</h2>
				        <ul>
				            <li><Link to="/brewers">Brewers</Link></li>
				            <li><Link to="/buyers">Buyers</Link></li>
				            <li><Link to="/brewers#memberships">Membership</Link></li>
				        </ul>
				    </div>
				     <div className="col-md-4">
				        <h2 className="footer-heading">Quick Links</h2>
				        <ul>
				            <li><Link to="/contact">Contact Us</Link></li>
				        </ul>
				    </div>				     
				  </div>
				 
				</div>
				</section>
				<section className="ftr-copyright">
				<div className="container">
				  <div className="row">
				    <div className="col-md-12">
				      <div className="copyright">
								<p>Copyrights &copy; 2023 All Rights Reserved by BevPort.<span> | <Link to="/privacy" target="_blank">PRIVACY</Link> | <Link to="/termsofuse" target="_blank">TERMS</Link> | <Link to={ExampleDoc} download="DistributionContract" target="_blank">DISTRIBUTION CONTRACT</Link></span></p>
				      </div>
				    </div>
				  </div>
				</div>
				</section>
		</>
	)
}