import React, { useState, useEffect } from 'react';
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Link, useParams } from "react-router-dom"
import BeerInfo from "./BrewersComplianceComponents/beerInfo";
import PriceCalculator from "./BrewersComplianceComponents/priceCalculator";
import ProductDesc from "./BrewersComplianceComponents/productDesc";
import Compliance from "./BrewersComplianceComponents/Compliance";
import Loader from "./../front/Loader";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};


export default function AddProduct() {
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [activeTab, setActiveTab] = useState("compliance")
	const [productID, setProductID] = useState("")
	const [packSize, setPackSize] = useState(0)
	const [caseSize, setCaseSize] = useState(0)
	const [brandID, setBrandID] = useState(0)
	const [productData, setProductData] = useState("")
	const [productImagesInfo , setProductImagesInfo] = useState("");
	const [complianceData, setComplianceData] = useState("")
	const [submitLoader, setSubmitLoader] = useState(false);
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const handleProductID = (id) => {
		setProductID(id)
	}
	const handleTab = (type) => {
		setActiveTab(type)
		if (type == "packageInfo") {
			document.title = "BevPort - Beer and Package Information"
		} 
         else if (type == "priceCalculator") {
			document.title = "BevPort - Price Calculator"
		} 
        else if (type == "productDesc") {
			document.title = "BevPort - Description and Tasting Notes"
		} 
        else if (type == "compliance") {
            console.log("teee", type);
			document.title = "BevPort - Compliance"
			//getUpcData()
		}
	}
	
	const handleActiveTab = (tab) => {
		//setActiveTab(tab)
		if (tab == "packageInfo") {
			document.title = "BevPort - Beer and Package Information"
		} 
        else if (tab == "priceCalculator") {
			document.title = "BevPort - Price Calculator"
		} 
        else if (tab == "productDesc") {
			document.title = "BevPort - Description and Tasting Notes"
		} 
        else if (tab == "compliance") {
			document.title = "BevPort - Compliance"
			//getUpcData()
		}
	}
	const handlePackSize = (v) => {
		setPackSize(v)
	}
	const handleCaseSize = (v) => {
		setCaseSize(v)
	}
	const handleBrandID = (id) => {
		setBrandID(id)
	}
	const { brID } = useParams();
	//const { prodID } = useParams();

	useEffect(() => {
		let userID = localStorage.getItem('id');
			getComplienceDetail(userID)
		
	}, [])
	const getComplienceDetail = async (userID) => {
		await axios.post(API_URL + "GetComplianceData?code=" + API_KEY, { userID: userID }, config).then((res) => {
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				
				let productData = res.data.responseData[0]
				
				//console.log("Compliance Dta", productData);
				//console.log("prductImages", productData?.productImages)
				setProductData( productData?.productData[0]);
				setProductImagesInfo(productData?.productImages);
				setComplianceData(productData);
				

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}


	
	return (
		<>

			<div className="mainAdminWrapper brewersDashArea">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
						<h1>Add Product</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className={(activeTab === "packageInfo") ? "active" : ""}><Link to="#" onClick={(e) =>  handleTab("packageInfo") }>Beer and Package Information</Link></li>
										<li className={(activeTab === "priceCalculator") ? "active" : ""}><Link to="#" onClick={(e) => handleTab("priceCalculator") }>Price Calculation</Link></li>
										<li className={(activeTab === "productDesc") ? "active" : ""}><Link to="#" onClick={(e) =>  handleTab("productDesc") }>Description and Tasting Notes</Link></li>
										<li className={(activeTab === "compliance") ? "active" : ""}><Link to="#" onClick={(e) =>  handleTab("compliance") }>Compliance</Link></li>
									</ul>

									<div className="tab-content">
										<div className={(activeTab === "packageInfo") ? "tab-pane fade in active" : "tab-pane fade"}>
											<BeerInfo bID={brID} handleProductID={handleProductID} productID={productID} handleActiveTab={handleActiveTab} handlePackSizeFun={handlePackSize} handleCaseSizeFun={handleCaseSize} handleBrandID={handleBrandID} productData={productData} productImagesInfo={productImagesInfo}/>
										</div>
										<div id="menu1" className={(activeTab === "priceCalculator") ? "tab-pane fade in active" : "tab-pane fade"}>
											<PriceCalculator packSizeD={packSize} caseSizeD={caseSize} productID={productID} handleActiveTab={handleActiveTab} handleProductID={handleProductID} productData={productData} />
										</div>
										<div id="menu2" className={(activeTab === "productDesc") ? "tab-pane fade in active" : "tab-pane fade"}>
											<ProductDesc productID={productID} brandID={brandID} productData={productData} handleActiveTab={handleActiveTab}   productImagesInfo={productImagesInfo}/>
										</div>
										<div id="menu3" className={(activeTab === "compliance") ? "tab-pane fade in active" : "tab-pane fade"}>
											<Compliance productID={productID} brandID={brandID} productData={productData}  handleActiveTab={handleActiveTab} complianceData={complianceData}/>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<BrewerFooter />
				</div>
			</div>
		</>
	)
}
