
import React, { useEffect, useRef, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useLocation, Link, useParams,useNavigate} from "react-router-dom"
import buyerstopbase from "./../../assets/images/buyerstopbase.png"
import beerwindows from "./../../assets/images/beer-windows.jpg"
import extensivebrand from "./../../assets/images/extensive-brand.jpg"
import userfriendly from "./../../assets/images/user-friendly.jpg"
import minimumorderre from "./../../assets/images/minimum-order-re.jpg"
import directtostore from "./../../assets/images/direct-to-store.jpg"
import personilizesupport from "./../../assets/images/personilize-support.jpg"
import freesignup from "./../../assets/images/free-signup.jpg"
import compass from "./../../assets/images/compass.jpg"
import beermug from "./../../assets/images/beer-mug.jpg"
import listicon from "./../../assets/images/list-icon.jpg"
import tonotchbar from "./../../assets/images/tonotchbar.jpg"
import regimd from "./../../assets/images/buyer-formimg.jpg"
import Header from './Header';
import Footer from './Footer';
import BasicInfo from './wizard/BasicInfo'
import BusinessInfo from './wizard/BusinessInfo'
import toast, { Toaster } from 'react-hot-toast';
export default function Buyers(){
	document.title = "BevPort - Buyers"
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
	    if (script) {
	      	script.remove();
	    }
	    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
	    if (recaptchaElems.length) {
	      	recaptchaElems[0].remove();
	    }
	},[])
	useEffect(() => {
	  	window.scrollTo(0, 0)
	}, [])
	const navigate = useNavigate();
	const { hash } = useLocation();
	const { tabID, userID } = useParams();
	const joinRef = useRef();
	useEffect(() => {
		if(hash){
			executeScroll(hash.slice(1))
		}
	},[hash])
	const executeScroll = (type) => {
		if(type === "revolutionize"){
			joinRef.current.scrollIntoView() 
		}
	}
	const [page, setPage] = useState(1)
	const [lastUserID, setLastUserID] = useState("")
	useEffect(() => {
		if(tabID){
			setPage(parseInt(tabID))
		}
	},[tabID])
	useEffect(() => {
		if(userID){
			setLastUserID(userID)
		}
	},[userID])
	const handlePage = (page, lastUserID) => {
		setPage(page)
		setLastUserID(lastUserID)
	}
	const handleJoin = () =>{
		navigate("/buyer-registration");
	}
	const [expanded, setExpanded] = React.useState('panel1');
	const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
	return(
		<>
		<Toaster />
			<div className="site-wrapper">
        		<div className="main-wrapper">
			<Header />
			<section className="simplyfying Empowering" id="simplys">
				<div className="simplyfyingDiv">
				  <div className="container-fluid">
				    <div className="row upcontent">
				      <div className="col-md-5 simplecontent buyermainhead"> 
				        <div className="simplecontentimg"> 
				          <img className="img-responsive" src={buyerstopbase} />  
				        </div>    
				      </div>
				      <div className="col-md-7 manBgimg">
				        <div className="manImg">
				          <img className="img-responsive" src={beerwindows} />
				        </div>
				      </div>
				    </div>
				    <div className="row">
				    <div className="moveTopUp Revolutionizing">
				      <div className="container">
				        <div className="row main-contentprt">
				          <div className="col-md-4">
				            <div className="textSimply whitecolorhead buyerHeasBtns">
				              <h1><span className="darkblacktext">Revolutionizing Beverage Distribution</span>Simplicity is Just a Click Away</h1>  
				              <div class="submitbtns">
				              	<button className="btn btn-default" type="submit" id="" onClick={handleJoin}  >JOIN THE REVOLUTION!</button>
				              </div>            
				            </div>
				          </div>
				          <div className="col-md-8">
				          </div>
				        </div>
				      </div>
				    </div>
				    </div>
				  </div>

				</div>
				</section>

				<section className="beverage distribution" id="beverage-distribution ">
				<div className="beverageeffortless">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">        
				        <p>With BevPort, we make beverage distribution effortless. Our platform hosts a dynamic selection of desirable brands your
				customers are seeking, all readily accessible with just a few clicks. Stand apart from your competition and diversify your offerings
				with our ever-growing portfolio of unique brands.</p>
				        <p>Getting started is as seamless as our service – sign up for free and embark on your enhanced shopping experience within
				minutes. Swift and reliable delivery straight to your storefront or restaurant is a staple of our commitment to you.</p>
				        <p>Say goodbye to disruptive sales representatives and the constraints of minimum orders. With BevPort, you hold the reins –
				select the brands that resonate with your business, place your order, and we’ll ensure your beverages arrive directly at your door,
				hassle-free.</p>
				        <p>We understand the value of customer satisfaction and the role it plays in your success. That’s why we are eager to cater to your
				needs. Is there a brand you’ve been longing to stock in your store but can’t because it isn’t distributed in your state? Let us know.
				We pledge to do our utmost to bridge this gap and bring your desired products closer to you.</p>
				        <p>BevPort: Simplifying distribution, amplifying satisfaction. Experience the future of beverage distribution with us.</p>
				      </div>
				    </div>
				  </div>
				</div>
				</section>
				
				<section className="streamlined-buyers" id="stremline">
				<div className="StreamlinedOnboarding patern1">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="StreamlinedProcess">
				          <h1>Features and Benefits of BevPort for Buyers</h1>
				          <p className="subtexs">At BevPort, we’re not just a platform; we’re your trusted partner in simplifying the beverage acquisition process. Our unique
				features are designed with your business in mind, providing benefits that transcend conventional distribution channels.</p>
				          <div className="imgtags">
				            <img className="imgstrem" src={extensivebrand}/>
				          </div>
				          <div className="imgcontent">
				            <p><strong>1. Extensive Brand Selection:</strong> Our platform hosts a diverse range of exciting brands,
				both local and international. This allows you to offer a unique selection that caters to your customers’ evolving tastes.</p>
				            <p className="orangeBold"><strong>BENEFIT:</strong> Stand out from the competition and attract a broader customer base with unique,
				in-demand offerings.</p>
				          </div>          
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="StreamlinedOnboarding patern2">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="StreamlinedProcess">
				          <div className="imgtags">
				            <img className="imgstrem" src={userfriendly} /> 
				          </div>   
				          <div className="imgcontent">
				            <p><strong>2. User-Friendly Platform:</strong> BevPort’s interface is intuitive and easy to navigate. You can
				browse our selection, place orders, and manage deliveries all in one place.</p>
				            <p className="orangeBold"><strong>BENEFIT:</strong> Save time and effort by managing all aspects of your beverage orders in a single, streamlined platform.</p>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="StreamlinedOnboarding patern1">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="StreamlinedProcess">
				          <div className="imgtags">
				            <img className="imgstrem" src={minimumorderre} /> 
				          </div>   
				          <div className="imgcontent">
				            <p><strong>3. No Minimum Order Requirements:</strong> BevPort lets you order exactly what you need, without the constraint of minimum order quantities.</p>
				            <p className="orangeBold"><strong>BENEFIT:</strong> Enjoy greater flexibility in managing your inventory and reduce overstocking or
				understocking issues.</p>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="StreamlinedOnboarding patern2">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="StreamlinedProcess">
				          <div className="imgtags"> 
				            <img className="imgstrem" src={directtostore} />
				            </div>   
				            <div className="imgcontent">
				            <p><strong>4. Direct-to-Store Delivery:</strong> We offer fast, reliable delivery straight to your store or restaurant.</p>
				            <p className="orangeBold"><strong>BENEFIT:</strong> Enjoy convenience and peace of mind, knowing your orders will reach you in a
				timely and efficient manner.</p>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="StreamlinedOnboarding patern1">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="StreamlinedProcess">
				          <div className="imgtags"> 
				            <img className="imgstrem" src={personilizesupport} />
				            </div>   
				            <div className="imgcontent">
				            <p><strong>5. Personalized Support:</strong> Can’t find a specific brand in your state? Let us know. We’ll do our best to bring it to you.</p>
				            <p className="orangeBold"><strong>BENEFIT:</strong> Enhance your product offering based on customer demand and your unique
				business needs.</p>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="StreamlinedOnboarding patern2">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="StreamlinedProcess">
				          <div className="imgtags"> 
				            <img className="imgstrem" src={freesignup} />
				            </div>   
				            <div className="imgcontent">
				            <p><strong>6. Free Sign-Up:</strong> BevPort provides free access to our platform. Start shopping in minutes without any upfront costs.</p>
				            <p className="orangeBold"><strong>BENEFIT:</strong> Lower your business expenses and start benefiting from our platform
				immediately upon sign-up.</p>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				</section>
				<section className="ExpectAsBevPort" id="ExpectAsBevPortB">
				<div className="ExpectAsBevPortBuyer">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <h2>What to Expect as a BevPort Buyer</h2>
				        <p className="bespoSubg">We’re thrilled to welcome you to BevPort, your one-stop online marketplace for all your beverage needs. As a BevPort buyer,
				you have at your disposal a plethora of benefits and resources that make your purchasing process as seamless as possible.</p>
				       <div className="befeaturesBlock"> 
				        <div className="bevfeartures">
				          <div className="featuresimg"><img src={compass} /></div>
				          <div className="mainFeatures">
				            <h3>EASY<br />NAVIGATION</h3> 
				            <p>Search, compare, and purchase your favorite beverages with just a few clicks. Our platform is designed with user-friendliness in mind, making your shopping experience a breeze.</p>
				          </div>
				        </div>

				        <div className="bevfeartures">
				          <div className="featuresimg"><img src={beermug} /></div>
				          <div className="mainFeatures">
				            <h3>WIDE PRODUCT<br />RANGE</h3> 
				            <p>We pride ourselves on offering a wide range of brands to suit your diverse needs. From well-known brands to niche craft selections, we strive to provide you with a robust selection to choose from.</p>
				          </div>
				        </div>

				        <div className="bevfeartures">
				          <div className="featuresimg"><img src={listicon} /></div>
				          <div className="mainFeatures">
				            <h3>REGULARLY<br />UPDATED SELECTION</h3> 
				            <p>Stay ahead of market trends with our regularly updated product offerings. We are always expanding our portfolio with the
				latest and greatest products, keeping your beverage selection fresh and exciting.</p>
				          </div>
				        </div>

				        <div className="bevfeartures">
				          <div className="featuresimg"><img src={tonotchbar} /></div>
				          <div className="mainFeatures">
				            <h3>TOP-NOTCH<br />CUSTOMER SERVICE</h3> 
				            <p>Your satisfaction is our priority. We are committed to serving you and providing the best possible buying experience.</p>
				          </div>
				        </div>
				    </div>
				      </div>
				    </div>
				  </div>
				</div>
				</section>
				<section className="revolutionize" id="revolutionize">
				<div className="strive-revolutionize">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <p>At BevPort, we strive to revolutionize the way you stock your store or restaurant. Join us and experience the future of beverage distribution.</p>
				      </div>
				    </div>
				  </div>
				</div>
				</section>
				<section className="JoinTheNew buyerspages" id="joinew">
				<div className="jointhe buyerjoin">
				  <div className="container" ref={joinRef}>
				    <div className="row">
				      <div className="col-md-12">
				        <h1>Join the New Distribution Revolution!</h1>
				      </div>
				    </div>
				    <div className="row">
				      <div className="col-md-6">
				        <div className="regText">
						 <p>Join BevPort today and revolutionize the way you buy brands for your store or restaurant. Expand your horizons, connect with new brands, or hard to find brands because large distributors block access and stand out from your competition. Embrace the power of the internet and experience seamless growth with BevPort.
				          </p>
				          <div className="submitbtns">
				          	<button className="btn btn-default" type="submit" id="" onClick={handleJoin}  >JOIN THE REVOLUTION!</button>
				          </div>
				        </div>
				      </div>
				      <div className="col-md-6">
				      	<div className="regImg">
				           <img className="img-responsive" src={regimd}/>
				        </div>	
				      	<div className="formtab-view" style={{display:'none'}}>
				      	<div className="formactions">
				          	<span className={(page ===1 )?"tabsbtns active":"tabsbtns"}>Basic Info</span>
				          	<span className={(page ===2)?"tabsbtns active" :"tabsbtns" }>Business Info</span>
				          </div>
				        <div className="registraionDiv">
				          
				          {
				          	page === 1? <BasicInfo handlePage={handlePage} userType="2"/>:""
				          }
				          {
				          	page === 2? <BusinessInfo handlePage={handlePage} lastUserID={lastUserID} userType="2"/>:""
				          }
						 
				        </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
				</section>
				

				<section className="faqSections buyerspage">
						<div className="faqDivision">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1>FAQ</h1>
										<div className="faqPanels">
									       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel1a-content"
									          id="panel1a-header"
									        >
									          <Typography className="accordianHead">When will BevPort Go Live?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Our teams are working day and night get BevPort Launched. We are expecting to be live in the Fall of 2023. Once we go live, we will be sure to let everyone know. Stay Tuned!
									          </Typography>
									        </AccordionDetails>
									      </Accordion>
									       <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel2a-content"
									          id="panel2a-header"
									        >
									          <Typography className="accordianHead">What does the BevPort platform do for me?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            BevPort introduces a revolutionary, modern, and convenient way to find new and exciting beverages not offered by anyone else. Also we streamline your order placement, shipping, and customer support. With BevPort, customers can effortlessly and effectively purchase products, explore new items, stay updated on deliveries, and review invoice history. This ensures greater flexibility and time savings for you.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>
									      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel3a-content"
									          id="panel3a-header"
									        >
									          <Typography className="accordianHead">Where do I start?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Begin by creating a Buyers Account and providing the necessary business information. Upon registration, you will receive a confirmation email and be directed to a start page where you can kick off your BevPort experience.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									     <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel4a-content"
									          id="panel4a-header"
									        >
									          <Typography className="accordianHead">How do I purchase products?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Utilizing BevPort, product browsing, searching, and purchasing become seamless. Buyers can search for items by name, type, keywords, and price. Alongside the full product catalogues, personalized recommendations will be displayed on your dashboard, and you can save favorites with heart icons on your shopping carts. When ready, review your Cart on the Order page and proceed to Checkout.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel5a-content"
									          id="panel5a-header"
									        >
									          <Typography className="accordianHead">Can I still have a Sales Rep place orders for me?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Absolutely. If you prefer a personal touch, a sales representative can place orders on your behalf in person or over the phone. Once the order is submitted, you'll review and approve it.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel6a-content"
									          id="panel6a-header"
									        >
									          <Typography className="accordianHead">How will I be invoiced?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Invoicing with BevPort remains consistent with your current process, whether it's through Fintech or a check. During sign-up, you'll indicate your payment method. If using Fintech, you'll be directed to integrate BevPort as a vendor. Our online platform seamlessly integrates orders into the existing system. Historical invoice viewing is available, allowing you to set reminders based on your frequently ordered products.
									          </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel7a-content"
									          id="panel7a-header"
									        >
									          <Typography className="accordianHead">How will my products be delivered?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Upon order placement, you'll receive a confirmation email. If any changes are needed, reach out to BevPort via email or phone. Anticipated shipment and tracking information will be accessible on the platform's Upcoming Deliveries page. Real-time delivery notifications will provide estimated arrival times. You can report any discrepancies upon delivery to our sales office promptly.									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel8a-content"
									          id="panel8a-header"
									        >
									          <Typography className="accordianHead">What if the products are out of stock?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            BevPort's e-commerce platform offers real-time product availability information. While we strive to display accurate availability during selection, fluctuations may occur beyond our control. Notifications and adjustments will align with established ordering procedures. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel9a-content"
									          id="panel9a-header"
									        >
									          <Typography className="accordianHead">How can I report damage or incomplete deliveries?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            For breakage, incorrect, or missing items, please contact our sales offices. We are committed to swiftly and accurately resolving any issues.
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel10a-content"
									          id="panel10a-header"
									        >
									          <Typography className="accordianHead">How easy is re-ordering?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									            Effortlessly set up automated product re-order lists or configure automated re-order reminders through the platform. Receive text messages for final approval prior to submission. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>

									      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
									        <AccordionSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-controls="panel11a-content"
									          id="panel11a-header"
									        >
									          <Typography className="accordianHead">Need something else? Can we help?</Typography>
									        </AccordionSummary>
									        <AccordionDetails>
									          <Typography className="accordianContent">
									           Certainly! Feel free to reach out to our sales office via phone or email at <Link to="mailto:admin@bevport.com">admin@bevport.com</Link> for any inquiries or assistance. 									          
									            </Typography>
									        </AccordionDetails>
									      </Accordion>
									      
									    </div>
									</div>
								</div>
							</div>
						</div>
					</section>

			<Footer />
			</div>
			</div>
		</>
	)
}