
import Header from './Header';
import Footer from './Footer';
import {Link} from "react-router-dom"
export default function NotFound(){
	return(
		<>
			<div className="site-wrapper">
        		<div className="main-wrapper">
				<Header />
					<section id="notfoundpage">
						<div className="sectionNotFound">
						  <div className="container">
						    <div className="row">
						      <div className="col-md-12">
						        <div className="notfound">
						            <h2>Oops!</h2>
						            <h1>404 Not Found</h1>
						            <p>Sorry, an error has occured, Requested page not found! </p>
						            <div className="btntakehome">
						              <Link className="homebtn" to="/">Take Me Home</Link>
						              <Link className="contactbtn" to="/contact">Contact Support</Link>
						            </div>
						        </div>
						      </div>
						    </div>
						  </div>
						</div>
						</section>
				<Footer />
				</div>
			</div>	
		</>
	)
}