import React, { useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ProSidebarProvider, Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import "./../../assets/css/admin-style.css"
import Logo from "./../../assets/images/logo.png"
import SmallLogo from "./../../assets/images/small-logo.png"
import OrderListIcon from '@mui/icons-material/ViewList';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddchartIcon from '@mui/icons-material/Addchart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FactCheckIcon from '@mui/icons-material/FactCheck';

export default function SideBar({ collapse }) {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    useEffect(() => {
        collapseSidebar(collapse);
    }, [collapse])
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("isAuthentic");
        localStorage.removeItem("userType");
        localStorage.removeItem("firstName");
        localStorage.removeItem("businessName");
        navigate("/admin/login")
    }
    return (
        <>
            <Sidebar className="mycustomSidebar">
                <Menu>
                    <MenuItem className="TopMiamLogo">
                        <span className="logoNotCollapsed"><img src={Logo} /></span>
                        <span className="logoCollapsed"><img src={SmallLogo} /></span>
                    </MenuItem>
                    <MenuItem className="dashboard" icon={<GridViewRoundedIcon />} component={<NavLink to="/admin/dashboard" className="link" />}> Dashboard </MenuItem>
                    <SubMenu label="Brands" icon={<DynamicFeedIcon />} active={((window.location.pathname).toLowerCase().includes(("/admin/brand-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/add-brand").toLowerCase())) ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/brand-list").toLowerCase()) || window.location.pathname == "/admin/add-brand") ? true : false}>
                        <MenuItem className="dashboard" icon={<LibraryAddOutlinedIcon />} component={<NavLink to="/admin/add-brand" className="link" />}> Add Brand</MenuItem>
                        <MenuItem className="dashboard" icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/brand-list" className="link" />}> Brand List</MenuItem>
                    </SubMenu>
                    <SubMenu label="Products" icon={<DynamicFeedIcon />} active={((window.location.pathname).toLowerCase().includes(("/admin/product-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/add-product").toLowerCase())) ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/product-list").toLowerCase()) || window.location.pathname == "/admin/add-product") ? true : false}>
                        <MenuItem className="dashboard" icon={<LibraryAddOutlinedIcon />} component={<NavLink to="/admin/add-product" className="link" />}> Add Product </MenuItem>
                        <MenuItem className="dashboard" icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/product-list" className="link" />}> Product List </MenuItem>
                    </SubMenu>
                    <SubMenu label="Sales Rep" icon={<DynamicFeedIcon />} active={((window.location.pathname).toLowerCase().includes(("/admin/add-salesrep").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/salesrep-list").toLowerCase())) ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/add-salesrep").toLowerCase()) || window.location.pathname == "/admin/salesrep-list") ? true : false}>
                        <MenuItem className="dashboard" icon={<LibraryAddOutlinedIcon />} component={<NavLink to="/admin/add-salesrep" className="link" />}> Add Sales Rep </MenuItem>
                        <MenuItem className="dashboard" icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/salesrep-list" className="link" />}> Sales Rep List </MenuItem>
                    </SubMenu>
                    <SubMenu label="Users" icon={<GroupIcon />} active={((window.location.pathname).toLowerCase().includes(("/admin/brewers-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/buyer-list").toLowerCase()) || window.location.pathname == "/admin/revolution-list" || window.location.pathname == "/admin/contact-list") ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/brewers-list").toLowerCase()) || window.location.pathname == "/admin/buyer-list" || window.location.pathname == "/admin/revolution-list" || window.location.pathname == "/admin/contact-list") ? true : false}>
                        <MenuItem icon={<AccountBoxIcon />} component={<NavLink to="/admin/brewers-list" className="link" />}>Brewer List</MenuItem>
                        <MenuItem icon={<AssignmentIndIcon />} component={<NavLink to="/admin/buyer-list" className="link" />}>Buyer List</MenuItem>
                        <MenuItem icon={<AssignmentIndIcon />} component={<NavLink to="/admin/revolution-list" className="link" />}>Revolution List</MenuItem>
                        <MenuItem icon={<AssignmentIndIcon />} component={<NavLink to="/admin/contact-list" className="link" />}>Contact List</MenuItem>
                    </SubMenu>
                    <SubMenu label="Master" icon={<AddchartIcon />} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/states").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/keywords").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/beer-type").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/can-size").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/case-size").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/package-size").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/warehouses").toLowerCase()  )
                        || window.location.pathname == "/admin/states" || window.location.pathname == "/admin/keywords" || window.location.pathname == "/admin/beer-type" || window.location.pathname == "/admin/can-size" || window.location.pathname == "/admin/case-size" || window.location.pathname == "/admin/package-size" ||  window.location.pathname == "/admin/warehouses" ) ? true : false}
                        active={((window.location.pathname).toLowerCase().includes(("/admin/states").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/keywords").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/beer-type").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/can-size").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/case-size").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/package-size").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/warehouses").toLowerCase())
                            || window.location.pathname == "/admin/states" || window.location.pathname == "/admin/keywords" || window.location.pathname == "/admin/beer-type" || window.location.pathname == "/admin/can-size" || window.location.pathname == "/admin/case-size" || window.location.pathname == "/admin/package-size" ||  window.location.pathname == "/admin/warehouses"  ) ? true : false}>
                        {/* <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/states" className="link" />}>States</MenuItem> */}
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/keywords" className="link" />}>Keywords</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/beer-type" className="link" />}>Beer Types</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/can-size" className="link" />}>Can Size</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/case-size" className="link" />}>Case Size</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/package-size" className="link" />}>Package Size</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/warehouses" className="link" />}> Warehouses </MenuItem>
                    </SubMenu>
                    <SubMenu label="Order Management" icon={<ReceiptIcon />} active={ (window.location.pathname == "/admin/order-history" || window.location.pathname == "/admin/order-bol" || window.location.pathname == "/admin/order-pick-list" ||  window.location.pathname == "/admin/bol-order-history" ||  window.location.pathname == "/admin/invoice-list") ? true : false} defaultOpen={(window.location.pathname == "/admin/order-history" || window.location.pathname == "/admin/order-bol" || window.location.pathname == "/admin/order-pick-list"  || window.location.pathname == "/admin/bol-order-history"  ||  window.location.pathname == "/admin/invoice-list" ) ? true : false}>
                        <MenuItem icon={<OrderListIcon />} component={<NavLink to="/admin/order-history" className="link" />}> Sales Order </MenuItem>
                        <MenuItem icon={<FactCheckIcon />} component={<NavLink to="/admin/order-pick-list" className="link" />}> Pack List </MenuItem>
                        <MenuItem icon={<FactCheckIcon />} component={<NavLink to="/admin/order-bol" className="link" />}> BOL </MenuItem>
                        <MenuItem icon={<FactCheckIcon />} component={<NavLink to="/admin/bol-order-history" className="link" />}> Order History </MenuItem>
                        <MenuItem icon={<FileCopyIcon />} component={<NavLink to="/admin/invoice-list" className="link" />}> Invoice </MenuItem>
                    </SubMenu>
                    {/* <SubMenu label="Compliance" icon={<AssuredWorkloadIcon />} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/federal-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/stateproducer-list").toLowerCase()) || window.location.pathname == "/admin/federal-list" || window.location.pathname == "/admin/stateproducer-list")?true:false} active={((window.location.pathname).toLowerCase().includes(("/admin/federal-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/stateproducer-list").toLowerCase()) || window.location.pathname == "/admin/federal-list" || window.location.pathname == "/admin/stateproducer-list")?true:false}> */}
                    <MenuItem icon={<AssuredWorkloadIcon />} component={<NavLink to="/admin/compliance" className="link" />}> Compliance</MenuItem>
                    {/* <MenuItem icon={<FormatListBulletedOutlinedIcon/>}  component={<NavLink to="/admin/stateproducer-list" className="link" />}> State Producer License</MenuItem> */}
                    {/* </SubMenu> */}
                    <SubMenu label="Deals" icon={<AssuredWorkloadIcon />} defaultOpen={((window.location.pathname).toLowerCase().includes(("/admin/deal-info").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/deal-info-list").toLowerCase()) || window.location.pathname == "/admin/deal-info" || window.location.pathname == "/admin/deal-info-list") ? true : false} active={((window.location.pathname).toLowerCase().includes(("/admin/deal-info").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/admin/deal-info-list").toLowerCase()) || window.location.pathname == "/admin/deal-info" || window.location.pathname == "/admin/deal-info-list") ? true : false}>
                        <MenuItem icon={<AddToPhotosOutlinedIcon />} component={<NavLink to="/admin/deal-info" className="link" />} > Add Deals</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/deal-info-list" className="link" />} > Deal List</MenuItem>
                    </SubMenu>
                    <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/admin/delivery-list" className="link" />} >Delivery List</MenuItem>
                    <MenuItem className="dashboard" icon={<Inventory2Icon />} component={<NavLink to="/admin/inventory" className="link" />}> Inventory </MenuItem>


                    <MenuItem className="dashboard" icon={<Inventory2Icon />} component={<NavLink to="/admin/SalesServiceList" className="link" />}> Sales Service </MenuItem>

                   {/* // jesse dudley 4/19/2024 -- commented out -- not using right now -- might get rid of - using for testing stuff from scratch*/}
                    {/*<MenuItem className="dashboard" icon={<Inventory2Icon />} component={<NavLink to="/admin/AccountingAnalytics" className="link" />}> Accounting/Analytics </MenuItem>*/}
                     
                    <MenuItem icon={<LogoutRoundedIcon />} onClick={logout}> Logout </MenuItem>
                </Menu>
            </Sidebar>
        </>
    )
}