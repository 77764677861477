import Login from "../components/admin/Login";
import Dashboard from "../components/admin/Dashboard";
import BrandList from "../components/admin/BrandList";
import BrewersList from "../components/admin/BrewersList";
import BuyerList from "../components/admin/BuyerList";
import ProductList from "../components/admin/ProductList";
import States from '../components/admin/masterComponent/states';
import Keywords from '../components/admin/masterComponent/keywords';
import BeerType from '../components/admin/masterComponent/BeerType';
import CanSize from '../components/admin/masterComponent/CanSize';
import CaseSize from '../components/admin/masterComponent/CaseSize';
import PackageSize from '../components/admin/masterComponent/PackageSize';
import OrderHistory from "../components/admin/OrderHistory";
import OrderDetail from '../components/admin/OrderDetail';
import FederalList from "../components/admin/complianceComponent/FederalList";
import ProductDetail from "../components/admin/productDetail"
import AddDeal from "../components/admin/dealComponents/DealInfo";
import DealList from "../components/admin/dealComponents/DealInfoList";
import AddProduct from "../components/admin/AddProduct";
import AddBrand from "../components/admin/AddBrand";
import Inventory from "../components/admin/Inventory";
import AccountingAnalytics from "../components/admin/AccountingAnalytics";
import Warehouses from "../components/admin/Warehouses";
import EditInventory from "../components/admin/EditInventory"
import EditBrewers from "../components/admin/EditBrewers";
import EditBuyers from "../components/admin/EditBuyers";
import AddSalesRep from "../components/admin/AddSalesRep";
import SalesRepList from "../components/admin/SalesRepList";
import AssociateSalesRep from "../components/admin/AssociateSalesRep";
import revolutionList from "../components/admin/revolutionList";
import AddBrewers from "../components/admin/AddBrewers";
import AddBuyer from "../components/admin/AddBuyer";
import BOL from "../components/admin/bol";
import PickList from "../components/admin/pickList";
import PickListDetails from "../components/admin/PickListDetails"
import BillOfLading from "../components/admin/BillOfLading"
import BolDetails from "../components/admin/BolDetails";
import BolHistory from "../components/admin/BolOrderHistory";
import BolOrderInvoice from "../components/admin/BolOrderInvoice";
import InvoiceList from "../components/admin/InvoiceList";
import AdminSetupDelivery from "../components/admin/AdminSetupDelivery";
import AdminSetupDeliveryList from "../components/admin/AdminSetupDeliveryList";
import AdminContactList from "../components/admin/AdminContactList";
import CreatePacklist from "../components/admin/CreatePacklist";
import CreateBol from "../components/admin/CreateBol";
import SalesServiceList from "../components/admin/SalesServiceList";

const route =  [
    { path: '/admin/login', Component: Login},
    { path: '/admin/dashboard', Component: Dashboard},
    { path: '/admin/brand-list', Component: BrandList},
    { path: '/admin/add-brand', Component: AddBrand},
    { path: '/admin/edit-brand/:brID', Component: AddBrand},
    { path: '/admin/brewers-list', Component: BrewersList},
    { path: '/admin/buyer-list', Component: BuyerList},
    { path: '/admin/product-list', Component: ProductList},
    { path: '/admin/product-detail/:productID', Component: ProductDetail},
    { path: '/admin/add-product', Component: AddProduct},
    { path: '/admin/edit-product/:prodID', Component: AddProduct},
    { path: '/admin/states', Component: States},
    { path: '/admin/keywords', Component: Keywords},
    { path: '/admin/beer-type', Component: BeerType},
    { path: '/admin/can-size', Component: CanSize},
    { path: '/admin/case-size', Component: CaseSize},
    { path: '/admin/package-size', Component: PackageSize},
    { path: '/admin/order-history', Component: OrderHistory},
    { path: '/admin/order-detail/:orderID', Component: OrderDetail},
    { path: '/admin/compliance', Component: FederalList},
    { path: '/admin/deal-info', Component: AddDeal},
    { path: '/admin/deal-info-list', Component: DealList},
    { path: '/admin/edit-deal-info/:id', Component: AddDeal},
    { path: '/admin/inventory', Component: Inventory },
    { path: '/admin/AccountingAnalytics', Component: AccountingAnalytics },
    { path: '/admin/warehouses', Component: Warehouses},
    { path: '/admin/inventory-edit/:productID', Component: EditInventory},
    { path: '/admin/edit-brewers/:brewerID', Component: EditBrewers},
    { path: '/admin/edit-buyers/:buyerID', Component: EditBuyers},
    { path: '/admin/add-salesrep', Component: AddSalesRep},
    { path: '/admin/edit-salesrep/:ID', Component: AddSalesRep},
    { path: '/admin/salesrep-list', Component: SalesRepList},
    { path: '/admin/associateSalesRep/:SalesRepID', Component: AssociateSalesRep},
    { path: '/admin/revolution-list', Component: revolutionList},
    { path: '/admin/add-brewers', Component: AddBrewers},
    { path: '/admin/add-buyer', Component: AddBuyer},
    { path: '/admin/order-bol', Component: BOL},
    { path: '/admin/order-pick-list', Component: PickList},
    { path: '/admin/pick-list-details/:orderID', Component: PickListDetails},
    { path: '/admin/bill-of-lading/:orderID', Component: BillOfLading},
    { path: '/admin/bol-details/:orderID', Component: BolDetails},
    { path: '/admin/bol-order-history', Component: BolHistory},
    { path: '/admin/bol-order-invoice/:orderID', Component: BolOrderInvoice},
    { path: '/admin/invoice-list', Component: InvoiceList},
    { path: '/admin/delivery-list', Component: AdminSetupDeliveryList},
    { path: '/admin/edit-setup-delivery/:id', Component: AdminSetupDelivery},
    { path: '/admin/contact-list', Component: AdminContactList},
    { path: '/admin/create-packlist/:orderID', Component: CreatePacklist},
    { path: '/admin/create-bol/:orderID', Component: CreateBol },
    { path: '/admin/SalesServiceList', Component: SalesServiceList},
  
   
    ]
export default route;