import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "../../front/Loader";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL, API_KEY, API_TOKEN } from '../../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function FederalList() {
    document.title = "BevPort - Federal Permit List"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false)
    const [stateOptions, setStateOptions] = useState([]);
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    const [data, setData] = useState([])

    const [lowerLimit, setLowerLimit] = useState(1)
    const [upperLimit, setUpperLimit] = useState(10);
    const [dataLength, setDataLength] = useState(0);
    useEffect(() => {
        getFederalList(0)
       
    }, [])

    const getFederalList = async (lower) => {
        // Jesse dudley note---> not using pagination any more - might clean up later - Just gets all the data just like every other page
        // Praveen added some weird pagination to this page
        // Taking it out ---> because it not needed cause you can search for text up top on the admin page or just scroll down 
        await axios.post(API_URL + "GetComplianceRecord?code=" + API_KEY, {
            "Lowerlimit": lower,
            "Upperlimit": "10"
        }
            , config).then((res) => {
                if (res.data.responseCode == "200") {

                    let data = res.data.responseData
                    console.log("testCompliance", data?.[0]?.rowCounts);
                    setDataLength(data?.[0]?.rowCounts)
                    // const filterData = data.filter(item => item.chargeID)
                    // console.log("thee", filterData);
                    setData(data)

                } else {
                    setData([])
                    setDataLength(0)
                }
                setSubmitLoader(false);
            }).catch(function (error) {
                setSubmitLoader(false);
            })
    }


    const handleActivityState_ByUser = async (userID) => {
        let formData = {
            UserID: userID,
            ActivityState: "150"
        }
        await axios.post(API_URL + "UpdateActivityState?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode === "200") {
                setSubmitLoader(false);

            } else {
                toast.error(res.data.responseMessage);
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            console.log(error)
            setSubmitLoader(false)
        })
    }


    const rejectCompliance_ModalWindow = (status, id, productID, userID, isRejectReason) => {

        // Check user's local storage --> activityState --> to see what html to show
        var modalHtml = "";
        var Title = "Rejection Reason";
        var modalText = "";

        modalHtml = '<div class="row" style="margin-left:0px !important; margin-right: 0px !important;"> <div class="col-md-12 text-center"> <textarea id="rejectReason" name="rejectReason"" rows="4" cols="25"></textarea></div></div>  ';

        Swal.fire({
            title: Title,
            text: modalText,
            html: modalHtml,
            icon: 'question',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Sumbit',
            denyButtonText: 'Close',
            background: '#fff',
            color: '#000',
            iconColor: "rgb(255, 153, 0)",
            width: "500px",
            padding: '2em',
            reverseButtons: false,

            preConfirm: () => {
                if (document.getElementById('rejectReason').value) {
                    // Handle return value 
                } else {
                    Swal.showValidationMessage('Rejection Reason Missing')
                }
            }
        }).then((result) => {


            if (result.isConfirmed) {
                isRejectReason = true; // set this to true to  allow  sending rejection api call
                var rejectReasonText = document.getElementById("rejectReason").value;
                handleStatus(status, id, productID, userID, isRejectReason, rejectReasonText);
                //localStorage.removeItem("ShowPopUp");
            } else if (result.isDenied) {
                //localStorage.removeItem("ShowPopUp");
            }
        })
    } // end rejectCompliance_ModalWindow--------------

    const handleStatus = async (status, id, productID, userID, isRejectReason, rejectReasonText) => {
        // if (chargeID != "") {
        let formData = {
            "ID": id,
            "Status": status,
            "ProductID": productID,
            "RejectReason": rejectReasonText,
            "UserID": userID
        }


        if (status == 90 && isRejectReason == false) {
            rejectCompliance_ModalWindow(status, id, productID, userID, isRejectReason);
        }

        else {
            setLoader(true);


            //alert(id);
            //alert(status);
            //alert(productID);
            //alert(rejectReasonText);
            //alert(userID);

            await axios.post(API_URL + "ChangeComplianceStatus?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode == "200") {
                    toast.success(res.data.responseMessage, {
                        autoClose: 3000, //3 seconds
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });


                    // update user 's activityState to 150
                    handleActivityState_ByUser(userID);
                    getFederalList();

                    setLoader(false);
                }
                else {
                    toast.error(res.data.responseMessage);
                    setLoader(false); //set spinning loader to false even if there is a error 
                }
            }).catch(function (error) {

            })
        }

    }
    const columns = [

        //{
        //    name: 'Product ID',
        //    selector: row => (row.productID).toLowerCase(),
        //    cell: row => row.productID,
        //    sortable: true,
        //    width: '150px'
        //},

        {
            name: 'Product Name',
            selector: row => (row.productName).toLowerCase(),
            cell: row => row.productName,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Business Name',
            selector: row => (row.businessName).toLowerCase(),
            cell: row => row.businessName,
            sortable: true,
            width: '170px'
        },
        {
            name: 'Federal TTB #',
            selector: row => (row.permitNumber) ? row.permitNumber : "-",
            sortable: true,
            // width: '250px',
        },
        {
            name: 'Registry No.',
            selector: row => (row.registryNumber) ? row.registryNumber : "-",
            sortable: true,
        },
        {
            name: 'State',
            selector: row => (row.stateName) ? row.stateName : "-",
            sortable: true,
            // width: '250px',
        },
        {
            name: 'License No.',
            selector: row => (row.licenseNumber) ? row.licenseNumber : "-",
            sortable: true,

        },
        {
            name: 'Created Date',
            selector: row => (row.createdDate) ? moment(row.createdDate, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.createdDate ? moment(row.createdDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Payment',
            selector: row => row.chargeID,
            cell: row => (row.chargeID) ? <span style={{ "color": "#00aeef" }}>Deducted</span> : <span style={{ "color": "#00aeef" }}>Not Deducted</span>,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == 3 && (row.payment_Status.toLowerCase() == "paid" || row.payment_Status == "active")) ? <span style={{ "color": "#00aeef" }}>Approved</span> : (row.status == 90) ? <span style={{ "color": "#00aeef" }}>Rejected</span> : <span style={{ "color": "#00aeef" }}>Pending</span>,
            sortable: true
        },
        {
            name: 'Action',
            width: '300px',
            selector: row => (row.status != "3") ? <><Link className="btn btn-primary" to="#" onClick={() => { handleStatus(3, row.id, row.productID, row.userID, false, "") }}  >Approve</Link> <Link className="btn btn-secondary" to="#" onClick={() => { handleStatus(90, row.id, row.productID, row.userID, false, "") }} >Reject</Link></> : <><Link className="btn btn-primary" to="#" onClick={() => { handleStatus(3, row.id, row.productID, row.userID, false, "") }} >Approve</Link> <Link className="btn btn-secondary" to="#" onClick={() => { handleStatus(90, row.id, row.productID, row.userID, false, "") }} >Reject</Link></>
        }


    ];

    const newData = data.map(_v => {
        let disabled = false;
        if (!_v?.blobFiles?.length) {
            console.log("first", _v)
            disabled = true;
        }
        return { ..._v, disabled };
    })

    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>
                {
                    data?.blobFiles?.map((_v, _i) => {
                        return (
                            <>
                                <div className="compliance-tablerow">
                                    <div className="compliance col one">
                                        {_v?.filename}
                                    </div>
                                    <div className="compliance col five">
                                        <Link to={_v?.filepath} className="btn btn-primary" target="_blank"> Download </Link>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </tbody>
        </table>)
    };

    

    const [currentPage, setCurrentPage] = useState(1);



    const tableData = {
        columns,
        data
    };




    const handlePageChange = (page) => {
        setSubmitLoader(true);
        // Assuming each page shows 10 records
        const newLowerLimit = (page - 1) * 10;

        // Call the API with the constant upper limit (10) and the new lower limit
        getFederalList(newLowerLimit, 10);

        // Update the current page
        setCurrentPage(page);
    };


    return (
        <>
            {submitLoader ? <Loader /> : ""}
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Compliance List</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox fedralListTable">
                                    {/* <h3 className="card-heading">Buyer List</h3> */}
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Compliances">
                                        <DataTable
                                            columns={columns}
                                            data={newData}
                                            expandableRows
                                            expandableRowDisabled={row => row.disabled}
                                            expandableRowsComponent={ExpandedComponent}
                                            noDataComponent={"No record found!"}
                                            className='managescroll iftenrecord'
                                          
                                        />

                                    </DataTableExtensions>

                                  {/*  // took out pagination 07/11/2024 causing problems*/}
                                    {/*<div className='cusotomPagination'>*/}
                                    {/*    <ul>*/}
                                    {/*        <li>*/}
                                    {/*            <span>{`Page ${currentPage} out of ${Math.ceil(dataLength / 10)}`}</span>*/}
                                    {/*        </li>*/}
                                    {/*        <li><button className='btn btn-customnav' type='submit' onClick={() => handlePageChange(currentPage - 1)}*/}
                                    {/*            disabled={currentPage === 1}  >*/}
                                    {/*            <i class="fa fa-backward" aria-hidden="true"></i>*/}
                                    {/*        </button>*/}
                                    {/*        </li>*/}
                                    {/*        <li><button className='btn btn-customnav' type='submit' onClick={() => handlePageChange(currentPage + 1)}*/}
                                    {/*            disabled={(currentPage * 10) >= dataLength}*/}
                                    {/*        >*/}
                                    {/*            <i class="fa fa-forward" aria-hidden="true"></i>*/}
                                    {/*        </button>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}

                                    {/*        </li>*/}

                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
