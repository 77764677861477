import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import TeamMemberSideBar from "./TeamMemberSideBar";
import TeamMemberHeader from "./TeamMemberHeader";
import TeamMemberFooter from "./TeamMemberFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
    document.title = "BevPort - Order List"
    const [toggle, setToggle] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [activeTab, setActiveTab] = useState("all")
    const [loader,setLoader] = useState(false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => row.quantityCount,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.totalAmount),
            cell: row => "$" + parseFloat(row.totalAmount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => moment(row.orderDate),
            cell: row => row.orderDate,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (row.status == 0) ? <span style={{ "color": "#00aeef" }}>Payment Failed</span> : <span style={{ "color": "#00aeef" }}>Order Placed</span>,
            sortable: true

        },
        {
            name: 'Action',
            selector: row => <>
                <Link className="btn btn-primary" to={(row.status == 1) ? "/team-member/order-detail/" + row.orderID : "#"} target='_blank'>View</Link></>,
                // <Link className="btn btn-primary" to={(row.status == 1) ? "#": "#"}>View</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        let userID = localStorage.getItem("createdBy")
        getOrderHistory(userID)
    }, [])

    const getOrderHistory = async (userID, filterType = null) => {
        await axios.post(API_URL + "OrderHistory?code=" + API_KEY, { ID: userID, "Type": filterType }, config).then((res) => {

            if (res.data.responseCode == "200") {
                console.log("iffiff")
                setData(res.data.responseData)

            } else {
                console.log("elseelse")
                setData([])
            }
            setLoader(false);
            setSubmitLoader(false);
        }).catch(function (error) {
            setLoader(false);
            setSubmitLoader(false);
        })
    }
    const handleFilter = type => {
       setLoader(true)
        setActiveTab(type)
        getOrderHistory(localStorage.getItem("createdBy"), type)
    }
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="mainAdminWrapper">
                <TeamMemberSideBar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <TeamMemberHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            {/* {(data.length) ?  */}
                            <h1>Sales Order</h1>
                             {/* : ""} */}
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    <div className="customFilterOptions">
                                        <div className={activeTab === "all" ? "FilterOptionsBtn active" : "FilterOptionsBtn"} onClick={() => handleFilter("all")}>
                                            All Orders
                                        </div>
                                        <div className={activeTab === "1" ? "FilterOptionsBtn active" : "FilterOptionsBtn"} onClick={() => handleFilter("1")}>
                                            Placed
                                        </div>
                                        <div className={activeTab === "2" ? "FilterOptionsBtn active" : "FilterOptionsBtn"} onClick={() => handleFilter("2")}>
                                            Shipped
                                        </div>
                                        <div className={activeTab === "3" ? "FilterOptionsBtn active" : "FilterOptionsBtn"} onClick={() => handleFilter("3")}>
                                            Delivered
                                        </div>
                                    </div>

                                    {/* <h3 className="card-heading">Order List</h3> */}
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noDataComponent={"No record found!"}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={4}
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TeamMemberFooter />
                </div>
            </div>
        </>
    )
}