import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function SalesRep() {
    document.title = "BevPort - SalesRep"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [activeTab, setActiveTab] = useState("all")
    const [loader,setLoader] = useState(false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.firstName + " " + row.lastName,
            cell: row => row.firstName + " " + row.lastName,
            width: '250px',
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: row => row.emailID,
            sortable: true,
        },
        {
            name: 'Phone No.',
            selector: row => row.businessPhone,
            cell: row => row.businessPhone,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row =>row.orderDate,
            cell: row =>(row.streetAddress || row.city || row.statename || row.zipCode)? row.streetAddress + ", " + row.city + ", " + row.statename + ", " + row.country + " " + row.zipCode :"",
            sortable: true,
        },
        // {
        //     name: 'Action',
        //     selector: row => <>
        //         <Link className="btn btn-primary" to={(row.status == 1) ? "/brewer/order-detail/" + row.orderID : "#"} target='_blank'>View</Link></>,
        // }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        let userID = localStorage.getItem("id")
        getAssociatedSalesRepList(userID)
    }, [])

    const getAssociatedSalesRepList = async (userID) => {
        await axios.post(API_URL + "GetAssociatedSalesRep?code=" + API_KEY, { ID: userID}, config).then((res) => {

            if (res.data.responseCode == "200") {
                console.log("iffiff")
                setData(res.data.responseData)

            } else {
                console.log("elseelse")
                setData([])
            }
            setLoader(false);
            setSubmitLoader(false);
        }).catch(function (error) {
            setLoader(false);
            setSubmitLoader(false);
        })
    }

    const tableData = {
        columns,
        data
    };
    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>
                {
                    data?.brandInfoData?.map((v, i) => {
                        return (
                            <>
                                <div className="expandable-tablerow inventorytbds">
                                    <div className="expandable col one">
                                        {v?.brandName}
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </tbody>
        </table>)
    };
    return (
        <>

            <div className="mainAdminWrapper brewersDashArea">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    {loader ? <Loader /> : ""}
                    <BrewerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            {/* {(data.length) ?  */}
                            <h1>Associated Sales Rep List</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    {/* <h3 className="card-heading">Order List</h3> */}
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Sales Rep">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noDataComponent={<span className='norecordFound'>No record found!</span>}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={4}
                                        expandableRows={true}
                                        expandableRowsComponent={ExpandedComponent}
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BrewerFooter />
                </div>
            </div>
        </>
    )
}