import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import TeamMemberSideBar from "./TeamMemberSideBar";
import TeamMemberHeader from "./TeamMemberHeader";
import TeamMemberFooter from "./TeamMemberFooter";
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BLOB_URL, BLOB_BRAND_CONTAINER } from './../../config/constant.js';
import DefaultAvtar from './../../assets/images/avatar-1.jpg';
import UplaodDocIcon from './../../assets/images/uploaddoc.png';
import Select from 'react-select';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';

import moment from "moment"
import { click } from '@testing-library/user-event/dist/click';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: 'transparent',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			border: '1px solid #b6b6af',
			boxShadow: null,
		},
		'&:focus': {
			border: '1px solid transparent',
			boxShadow: null,
		}
	})
};
export default function Profile() {
	document.title = "BevPort - Profile"
	const [toggle, setToggle] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [userData, setUserData] = useState("");

	const [cardData, setCardData] = useState("");
	const [monthLength, setMonthLength] = useState("");
	
	const collapseSidebar = () => {
		setToggle(!toggle)
	}

	useEffect(() => {
		let id = localStorage.getItem("createdBy")
		getUserProfileFunction(id);
		getCardDetailsFunction(id);
	}, [])
	useEffect(() => {
		if (cardData) {
			setMonthLength(getlength(cardData?.expiryMonth));
		}
	})
	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setUserData(data)

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getCardDetailsFunction = async (userID) => {
		await axios.post(API_URL + "GetCardInfo?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setCardData(data);
			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}

	const getlength = (number) => {
		return number.toString().length;
	}

	useEffect(() => {
		getStateList();
	}, [])

	const getStateList = async () => {
		await axios.post(API_URL + "GetStateData?code=" + API_KEY, {}, config).then((res) => {

			if (res.data.responseCode === "200") {
				let stateOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {

					stateOption.push({ value: da.statename, label: da.statename, id: da.id });

				}
				);
			}
		}).catch(function (error) {
			console.log(error)
		})
	}

	return (
		<>
			
			<div className="mainAdminWrapper">
				<TeamMemberSideBar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
					<TeamMemberHeader collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">

						</div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-2 ImgPart">
										<div className="profileImages">
											<img src={DefaultAvtar} />
										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>
											</div>
											<div className="profileIcons">
												<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="row">
									<div className="col-md-12">
										<div className="editUserProfile">
											<Link to="/brewer/edit-profile" className="btn btn-primary save-button">Edit Profile</Link>
										</div>
									</div>
								</div> */}
							</div>

						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Contact Details</a></li>
										<li><a data-toggle="tab" href="#menu1">Business Details</a></li>
										<li><a data-toggle="tab" href="#menu2">Bank Details</a></li>
										<li><a data-toggle="tab" href="#menu5">Subscription Details</a></li>
										<li><a data-toggle="tab" href="#menu6">CC Details</a></li>
									</ul>
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Full Name</label>
														<span className="ValueEntites">{userData?.firstName} {userData?.lastName}</span>
													</div>
													<div className="comPundEntites">
														<label>Mobile</label>
														<span className="ValueEntites">{userData?.businessPhone}</span>
													</div>
													<div className="comPundEntites">
														<label>Mobile (Optional)</label>
														<span className="ValueEntites">{userData?.optionalPhone}</span>
													</div>
													<div className="comPundEntites">
														<label>E-mail</label>
														<span className="ValueEntites">{userData?.emailID}</span>
													</div>

												</div>
											</div>
										</div>
										<div id="menu1" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Business Name</label>
														<span className="ValueEntites">{userData?.businessName}</span>
													</div>
													<div className="comPundEntites">
														<label>EIN</label>
														<span className="ValueEntites">{userData?.ein}</span>
													</div>
													<div className="comPundEntites">
														<label>State Tax Number</label>
														<span className="ValueEntites">{userData?.stateTaxNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Address</label>
														<span className="ValueEntites">{userData?.streetAddress}, {userData?.city}, {userData?.stateName}, {userData?.zipCode}, USA</span>
													</div>
												</div>
											</div>
										</div>
										<div id="menu2" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Bank Name</label>
														<span className="ValueEntites">{userData?.bankName}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Address</label>
														<span className="ValueEntites">{userData?.bankAddress}</span>
													</div>
													<div className="comPundEntites">
														<label>Routing Number</label>
														<span className="ValueEntites">{userData?.routingNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Account Number</label>
														<span className="ValueEntites">{userData?.accountNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Bank Account Type</label>
														<span className="ValueEntites">{(userData?.accountType == 1) ? "Checking Account" : "Saving Account"}</span>
													</div>
													<div className="comPundEntites">
														<label>Name on Account</label>
														<span className="ValueEntites">{userData?.accountName}</span>
													</div>
												</div>
											</div>
										</div>
										<div id="menu5" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Plan</label>
														<span className="ValueEntites">{userData?.planType}</span>
													</div>
													<div className="comPundEntites">
														<label>Amount</label>
														<span className="ValueEntites">${userData?.planAmount}</span>
													</div>
													<div className="comPundEntites">
														<label>Start Date</label>
														<span className="ValueEntites">{moment(userData?.startDate).format("MM-DD-YYYY HH:mm")}</span>
													</div>
													<div className="comPundEntites">
														<label>Renewal Date</label>
														<span className="ValueEntites">{moment(userData?.endDate).format("MM-DD-YYYY HH:mm")}</span>
													</div>
												</div>
											</div>
										</div>

										<div id="menu6" className="tab-pane fade">
											<div className="InsertForms">
												<div className="formEntites">
													<div className="comPundEntites">
														<label>Card Type</label>
														<span className="ValueEntites">{cardData?.brandName}</span>
													</div>
													<div className="comPundEntites">
														<label>Card Number</label>
														<span className="ValueEntites">**** **** **** {cardData?.cardNumber}</span>
													</div>
													<div className="comPundEntites">
														<label>Expiry Date</label>
														{/* <span className="ValueEntites">{moment(userData?.startDate).format("MM-DD-YYYY HH:mm")}</span> */}
														<span className="ValueEntites">{(monthLength == 2) ? cardData?.expiryMonth : "0" + cardData?.expiryMonth} / {cardData.expiryYear}</span>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
					<TeamMemberFooter />
				</div>
			</div>
		</>
	)
}