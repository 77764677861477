import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DefaultAvtar from './../../assets/images/avatar-1.jpg'
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function ChangePassword() {
	document.title = "BevPort - Change Password"
	const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [userData, setUserData] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [cpassword, setcPassword] = useState("");
	const [cpasswordError, setcPasswordError] = useState(false);
	const [passwordTxt, setPasswordTxt] = useState(false);
	const [visible, SetVisible] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const adminID = localStorage.getItem("adminID")
	useEffect(() => {
		let id = localStorage.getItem("id")
		
		if (localStorage.getItem("userType") == "4") {
			getUserProfileFunction(adminID)
		}else{
			getUserProfileFunction(id)
		}
		
	}, [])
	const getUserProfileFunction = async (userID) => {
		await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
			if (res.data?.responseCode == "200") {
				let data = res.data?.responseData;
				setUserData(data)


			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}
	const handlePassword = (e) => {
		setPasswordError(false)
		setPasswordTxt(false)
		setPassword(e.target.value)
	}
	const handleConfirmPassword = (e) => {
		setcPasswordError(false)
		setPasswordTxt(false)
		setcPassword(e.target.value)
	}
	const handleValidation = () => {
		let formIsValid = true;

		if (!password) {
			formIsValid = false;
			setPasswordError(true)
		}
		if (!cpassword) {
			formIsValid = false;
			setcPasswordError(true)
		}
		if (cpassword && password !== cpassword) {
			formIsValid = false;
			setcPasswordError(true)
			setPasswordTxt(true)
		}
		return formIsValid;
	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		if (handleValidation()) {
			setLoader(true)
			let formData = {
				"ID":(localStorage.getItem('userType') == "4") ? adminID : localStorage.getItem('id'),
				"Password": password
			}
			await axios.post(API_URL + "ChangeUserPassword?code=" + API_KEY, formData, config).then((res) => {
				setLoader(false)
				if (res.data.responseCode === "200") {
					toast.success(res.data.responseMessage);
				}
			}).catch(function (error) {
				console.log(error)
				setLoader(false)
			})
		}
	}
	const handleShow = () => {
		SetVisible(!visible);
	}

	const handleConfirmShow = () => {
		setConfirmVisible(!confirmVisible)
	}
	return (
		<>

			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<BrewerHeader collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop"></div>
						<div className="blank25"></div>
						<div className="ProfilePic">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-2 ImgPart">
										<div className="profileImages">
											{(userData?.filePath !== "") ? <img src={userData?.filePath} /> : <img src={DefaultAvtar} />}
										</div>
									</div>
									<div className="col-md-10 textParts">
										<div className="profileDescription">
											<h2>{userData?.firstName} {userData?.lastName}</h2>
											<h3>{userData?.businessName}</h3>
											<div className="profileIcons">
												{(localStorage.getItem("userType") == "4")?"":<span className="iconText"><i className="fa fa-location-arrow" aria-hidden="true"></i>{userData?.streetAddress} {userData?.city}</span>}
											</div>
											<div className="profileIcons">
												{(localStorage.getItem("userType") == "4")?"":<span className="iconText"><i className="fa fa-building-o" aria-hidden="true"></i>{userData?.stateName}</span>}
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="Content-body">
									<ul className="nav nav-tabs">
										<li className="active"><a data-toggle="tab" href="#home">Change Password</a></li>
									</ul>
									<div className="tab-content">
										<div id="home" className="tab-pane fade in active">
											<div className="InsertForms">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Password<span className="red-tick"> *</span></label>
															<div className="showhidepassowrd">
																<input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} placeholder="Password" onChange={handlePassword} />
																<span className="eyeslash" onClick={handleShow}><i className={!visible ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className="brand-tab">Confirm Password<span className="red-tick"> *</span></label>
															<div className="showhidepassowrd">
																<input type={confirmVisible ? "text" : "password"} className={cpasswordError ? "form-control error" : "form-control"} placeholder="Confirm Password" onChange={handleConfirmPassword} />
																{passwordTxt ? <span className="error-message">Confirm password should be same as password.</span> : ""}
																<span className="eyeslash" onClick={handleConfirmShow}><i className={!confirmVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="formBtns">
															<button type="button" className="btn btn-primary save-button" onClick={handleSubmit}>Save</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<BrewerFooter />
				</div>
			</div>
		</>
	)
}