import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import Select from 'react-select';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export const optionsData = {
  responsive: true,
  plugins: {
    legend: {
    	display: true,
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};
const typeOtions = [{label:"Inventory By Brand",value:"By Brand"}, {label:"Inventory By Product",value:"By Product"}]
export default function Dashboard(){
	document.title = "BevPort - Admin Dashboard"
	const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [dashboardData, setDashboardData] = useState("")
	const [buyerState, setBuyerState] = useState("")
	const [brewerState, setBrewerState] = useState("")
	const [sellState, setSellState] = useState("")
	const [topBrands, setTopBrands] = useState([])
	const [topProducts, setTopProducts] = useState([])
	const [topBuyers, setTopBuyers] = useState([])
	const [brandLabel, setBrandLabel] = useState([])
	const [brandValue, setBrandValue] = useState([])
	const [productLabel, setProductLabel] = useState([])
	const [productValue, setProductValue] = useState([])
	const [colorCode, setColorCode] = useState([])
	const [colorCodeBor, setColorCodeBor] = useState([])
	const [dType, setDType] = useState("By Brand")
	const [optionValue, setOptionValue] = useState({label:"Inventory By Brand",value:"Inventory By Brand"});
	const handleOptions = (e) => {
		setDType(e.value);
		setOptionValue(e);
	}
	const randomString = (length) => {
    let result = '';
    const characters = '23';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
	}
	const randomNumber = (length) => {
    let result = '';
    const characters = '1234567890';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
	}
	useEffect(() => {
		axios.post(API_URL+"GetDashboardData?code="+API_KEY,{},config).then((res) => {
			setSubmitLoader(false)
			//console.log(res?.data?.responseData)
			if(res?.data?.responseCode == 200){
				setDashboardData(res?.data?.responseData)
				setBuyerState(res?.data?.responseData?.activeBuyerData)
				setBrewerState(res?.data?.responseData?.activeBrewerData)
				setSellState(res?.data?.responseData?.soldProductData)
				setTopBrands(res?.data?.responseData?.topBrands)
				setTopProducts(res?.data?.responseData?.topProducts)
				setTopBuyers(res?.data?.responseData?.topBuyers)
				/*setBrandInventory(res?.data?.responseData?.totalBrandData)
				setProductInventroy(res?.data?.responseData?.totalProductData)*/
				let brandL = [];
				let brandV = [];
				let colorCodeList = [];
				let colorCodeBorList = [];
				res?.data?.responseData?.totalBrandData && res?.data?.responseData?.totalBrandData.forEach((_v,i) => {
					if(_v?.totalQuantity > 0){
						brandL.push(_v?.name)
						brandV.push(_v?.totalQuantity)
						let c1 = randomNumber(randomString(1));
						let c2 = randomNumber(randomString(1));
						let c3 = randomNumber(randomString(1));
						colorCodeList.push('rgba('+c1+', '+c2+', '+c3+', 0.2)')
						colorCodeBorList.push('rgba('+c1+', '+c2+', '+c3+', 1)')
					}
				})
				setColorCode(colorCodeList)
				setColorCodeBor(colorCodeBorList)
				setBrandLabel(brandL)
				setBrandValue(brandV)

				let productL = [];
				let productV = []
				res?.data?.responseData?.totalProductData && res?.data?.responseData?.totalProductData.forEach((_v,i) => {
					if(_v?.totalQuantity > 0){
						productL.push(_v?.name)
						productV.push(_v?.totalQuantity)
					}
				})
				setProductLabel(productL)
				setProductValue(productV)
			}
		}).catch((res) => {
			console.log(res)
			setSubmitLoader(false)
		})
	},[])
	const collapseSidebar = () =>{
		setToggle(!toggle)
	}
	const data = {
	  labels,
	  datasets: [
	    {
	      label: 'Buyer',
	      data: [buyerState?.january, buyerState?.february, buyerState?.march, buyerState?.april, buyerState?.may, buyerState?.june, buyerState?.july, buyerState?.august, buyerState?.september, buyerState?.october, buyerState?.november, buyerState?.december],
	      backgroundColor: '#337ab7',
	    },
	    {
	      label: 'Brewer',
	      data: [brewerState?.january, brewerState?.february, brewerState?.march, brewerState?.april, brewerState?.may, brewerState?.june, brewerState?.july, brewerState?.august, brewerState?.september, brewerState?.october, brewerState?.november, brewerState?.december],
	      backgroundColor: '#933EC5',
	    },
	    {
	      label: 'Sell',
	      data: [sellState?.january, sellState?.february, sellState?.march, sellState?.april, sellState?.may, sellState?.june, sellState?.july, sellState?.august, sellState?.september, sellState?.october, sellState?.november, sellState?.december],
	      backgroundColor: '#65b12d',
	    }
	  ],
	};
	const donutData = {
  labels: (dType == "By Brand")?brandLabel:productLabel,
  datasets: [
    {
      label: '# of Case',
      data: (dType == "By Brand")?brandValue:productValue,
      backgroundColor: colorCode,
      borderColor: colorCodeBor,
      borderWidth: 1,
    },
  ],
}

	return(
		<>
			{submitLoader?<Loader />:""}
			<div className="mainAdminWrapper superAdminbg">
				<Sidebar collapse={toggle}/>
				<div className={!toggle?"wholeCompoundRight":"wholeCompoundRight fullwidth"}>
					<Header collapseSidebar={collapseSidebar} />
						<div className="rightContetBlock">
							<div className="SuperAdminWrapper">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="superHeadingTOpHEad"><h2>Admin Dashboard</h2></div>
										</div>
									</div>
								</div>
								<div className="analytics-sparkle-area">
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
											    <div className="analytics-sparkle-line reso-mg-b-30">
											        <div className="analytics-content">
											            <h5>Brands</h5>
											            <h2><span className="counter">{dashboardData?.activeBrands}</span> <span className="tuition-fees">Active Brands</span></h2>
														{/*--- removed 4/24/2024 because it was hard coded ---*/}
											           {/* <span className="text-success">20%</span>*/}
											            <div className="progress m-b-0">
											                <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '20%' }}> <span className="sr-only" >20% Complete</span> </div>
											            </div>
											        </div>
											    </div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
											    <div className="analytics-sparkle-line reso-mg-b-30">
											        <div className="analytics-content">
											            <h5>Products</h5>
											            <h2><span className="counter">{dashboardData?.activeProducts}</span> <span className="tuition-fees">Active Products</span></h2>
											         {/*   <span className="text-success">20%</span>*/}
											            <div className="progress m-b-0">
											                <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '20%' }}> <span className="sr-only" >20% Complete</span> </div>
											            </div>
											        </div>
											    </div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
											    <div className="analytics-sparkle-line reso-mg-b-30">
											        <div className="analytics-content">
											            <h5>Brewers</h5>
											            <h2><span className="counter">{dashboardData?.activeBrewers}</span> <span className="tuition-fees">Active Brewers</span></h2>
											          {/*  <span className="text-success">20%</span>*/}
											            <div className="progress m-b-0">
											                <div className="progress-bar progress-bar-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '20%' }}> <span className="sr-only" >20% Complete</span> </div>
											            </div>
											        </div>
											    </div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
											    <div className="analytics-sparkle-line reso-mg-b-30">
											        <div className="analytics-content">
											            <h5>Buyers</h5>
											            <h2><span className="counter">{dashboardData?.activeBuyers}</span> <span className="tuition-fees">Active Buyers</span></h2>
											          {/*  <span className="text-success">20%</span>*/}
											            <div className="progress m-b-0">
											                <div className="progress-bar progress-bar-inverse" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '20%' }}> <span className="sr-only" >20% Complete</span> </div>
											            </div>
											        </div>
											    </div>
											</div>
										</div>
									</div>
								</div>

								<div className="product-sales-area mg-tb-30">
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
												<div className="product-sales-chart">
													<h3 className="box-title-top">Total Visit</h3>
													<Bar options={options} data={data} />
												</div>
												
											</div>
											<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
												<div className="analysis-progrebar res-mg-t-30">
													<Select className={ "selectTodesign"}
                              styles={customStyles}
                              options={typeOtions}
                              onChange={handleOptions}
                              value={optionValue}
                              closeMenuOnSelect={true}
                              isSearchable
                          />
												    <Doughnut options={optionsData} data={donutData} />
												</div>
												
											</div>
										</div>
									</div>
								</div>

								<div className="courses-area mg-b-15">
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
												<div className="white-box">
												    <h3 className="box-title-top">Top Buyers</h3>
												    <ul className="basic-list">
												    	{
												    		topBuyers.map((_v, ind) => {
												    			return(
												    				<li>{_v?.name} <span className={ind == 0?"pull-right label-success label-3 label":(ind == 1?"pull-right label-info label-4 label":(ind == 2?"pull-right label-purple label-2 label":(ind == 3?"pull-right label-purple label-7 label":(ind == 4?"pull-right label-purple label-6 label":(ind == 5?"pull-right label-info label-4 label":"pull-right label-danger label-1 label")))))}>{_v?.quantity}</span></li>
												    			)
												    		})
												    	}
												    </ul>
												</div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
											    <div className="white-box ">
											        <h3 className="box-title-top">Top Brands</h3>
											        <ul className="country-state">
											        {
											        	topBrands.map((_val, _ind) => {
											        		if(_ind < 5){
											        		return(
											        				<li>
												                <h2><span className="counter">{_val?.quantity}</span></h2> <small>{_val?.name}</small>
												                <div className="progress">
												                    <div className={_ind == 0?"progress-bar progress-bar-danger ctn-vs-1":(_ind == 1?"progress-bar progress-bar-info ctn-vs-2":(_ind == 2?"progress-bar progress-bar-info ctn-vs-1":(_ind == 3?"progress-bar progress-bar-success ctn-vs-4":"progress-bar progress-bar-inverse ctn-vs-5")))} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="10000000000" style={{width: _val?.quantity+'%' }}></div>
												                </div>
												            </li>
											        		)
											        	}
											        	})
											        }
											          
											        </ul>
											    </div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
											    <div className="white-box ">
											        <h3 className="box-title-top">Top Products</h3>
											        <ul className="country-state">
											            {
											        	topProducts.map((_val, _ind) => {
											        		if(_ind < 5){
											        		return(
											        				<li>
												                <h2><span className="counter">{_val?.quantity}</span></h2> <small>{_val?.name}</small>
												                <div className="progress">
												                    <div className={_ind == 0?"progress-bar progress-bar-danger ctn-vs-1":(_ind == 1?"progress-bar progress-bar-info ctn-vs-2":(_ind == 2?"progress-bar progress-bar-info ctn-vs-1":(_ind == 3?"progress-bar progress-bar-success ctn-vs-4":"progress-bar progress-bar-inverse ctn-vs-5")))} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="10000000000" style={{width: _val?.quantity+'%' }}></div>
												                </div>
												            </li>
											        		)
											        	}
											        	})
											        }
											        </ul>
											    </div>
											</div>
											

										</div>
									</div>
								</div>



							</div>
						</div>
					<Footer />
				</div>
			</div>
		</>
	)
}