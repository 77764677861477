import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function OrderHistory() {
    document.title = "BevPort - Bill of Lading"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("all")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    
    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Qty. in Cases',
            selector: row => row.quantity,
            cell: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.amount),
            cell: row => "$" + parseFloat(row.amount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDateVal) ? moment(row.orderDateVal, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.orderDateVal ? moment(row.orderDateVal, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => <span style={{ "color": "#00aeef" }}>BOL Created</span>,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <>
                <Link className="btn btn-primary" to={"/admin/bol-details/" + row.orderID} target='_blank'>View</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        getOrderHistory()
    }, [])
    const getOrderHistory = async () => {
        await axios.post(API_URL + "GetBOLData?code=" + API_KEY,{}, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)
                console.log("data", res.data.responseData);

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };
    return (
        <>
           
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
                {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Bill Of Lading</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={4}
                                        noDataComponent={<span className='norecordFound'>No record found!</span>}
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}