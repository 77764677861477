

import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BASE_URL } from './../../config/constant';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import LogoInvoice from './../../assets/images/logo.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";
import Swal from 'sweetalert2'
import moment from 'moment';
import InputMask from 'react-input-mask';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
function OrderDetail() {
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [quantity, setQuantity] = useState("");
    const [amount, setAmount] = useState("");
    const [payableAmount, setPayableAmount] = useState(0)
    const [postData, setPostData] = useState([])
    const [btnDisabled, setBtnDisabled] = useState("")
    const [addNotes, setAddNotes] = useState("");
    const [addNotesError, setAddNotesError] = useState(false);
    const [typeStatus, setTypeStatus] = useState("");

    const [showModal2, setShowModal2] = useState(false);

    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    let { orderID } = useParams();
    let { userID } = useParams();
    let { type } = useParams();
    let Navigate = useNavigate();
    const [data, setData] = useState([]);
    const [previousData, setPreviousData] = useState([])
    const [action, setAction] = useState("");
    const [submitLoader, setSubmitLoader] = useState(true);

    const [driverDetailsAvailable, setDriverDetailsAvailable] = useState();
    const [driverName, setDriverName] = useState("");
    const [driverNameError, setDriverNameError] = useState(false);
    const [trailerNumber, setTrailerNumber] = useState("");
    const [trailerNumberError, setTrailerNumberError] = useState(false);
    const modalRef = useRef(null);
    useEffect(() => {
        getOrderDetail();
    }, [])
    useEffect(() => {
        if (driverDetailsAvailable == false) {
            window.$('#myModal2').modal('show');
        }
    }, [data, driverDetailsAvailable])

    useEffect(() => {
        if (data.length) {
            let formData = []
            let totalPayableAmount = 0;
            data.forEach((val, ind) => {

                totalPayableAmount += parseFloat(val?.amount)
                let f = {
                    productID: val?.productID,
                    Quantity: (val?.quantity == "")?"0":val?.quantity,
                    Amount: val?.amount
                }
                formData.push(f)
            })
            setPayableAmount(parseFloat(totalPayableAmount).toFixed(2))
            setPostData(formData)
        }
    }, [data])
    useEffect(() => {
        if (showModal2) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showModal2]);

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose();
        }
    };
    const handleClose = (e) => {
        setShowModal2(false);
    };
    // e.preventDefault()
    // 	window.$('#myModal2').modal('hide')
    // 	if (modalRef.current) {
    // 		modalRef.current.value = '';
    // 	}

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };
    document.title = "#" + orderID
    let componentRef = useRef();
    const getOrderDetail = async () => {
        // let dt =[];
        await axios.post(API_URL + "GetInvoiceOrderData?code=" + API_KEY, { OrderID: orderID }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let data = res.data.responseData
                console.log(data?.[0]?.courierName)
                if (data?.[0]?.vehicleNumber) {
                    setDriverDetailsAvailable(true);
                } else {
                    setDriverDetailsAvailable(false);
                }
                let tempData = res.data.responseData
                console.log("dataa--->", data?.[0]?.status);
                setBtnDisabled(data?.[0]?.status)
                let tArray = []
                data.forEach((_x, _y) => {
                    _x.finalPrice = _x.productPrice * _x.quantity
                    tArray.push(_x)
                })
                setData(tArray);
                let newArr = [];
                if (tempData.length) {
                    tempData.forEach((_v, _i) => {
                        newArr.push({ "amount": _v?.amount })
                    })
                }
                setPreviousData(newArr);
                // } else if (res.data.responseCode == "401") {
                //     toast.error("Invalid QR Code", {
                //         autoClose: 3000,
                //         closeOnClick: true,
                //     });
                //     Navigate("/")
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const sum = () => {
        let add = 0;

        data.filter((_v, _i) => {
            if (_v?.brewerID == userID) {
                add = add + _v?.amount;
            }
        })
        return add;
    }

    const handleQuantity = (e, index) => {
        let count = 0;
        let tempData = [];
        let qty = (e.target.value) ? e.target.value : 0;
        data.forEach((_v, _i) => {
            if (_i === index) {

                _v.finalPrice = _v?.productPrice * parseInt(qty)
                _v.amount = _v?.productPrice * parseInt(qty)
                _v.quantity = e.target.value
            }
            tempData.push(_v)
        })

        setData(tempData)
    }

    const handleAddNotes = (e) => {
        setAddNotes(e.target.value);
        setAddNotesError(false);
    }

    const openPopup = (type) => {
        setTypeStatus(type)
        window.$('#myModal').modal('show')
        setAddNotes("");
        setAddNotesError(false);
    }

    const closeModal = () => {
        window.$('#myModal').modal('hide')

    }

    const handleValidate = () => {
        let formIsValid = true;

        if (!addNotes) {
            formIsValid = false;
            setAddNotesError(true)
        }


        return formIsValid;
    }

    const checkData = () => {
        let dataChange = false;
        if (data.length) {
            data.forEach((_v, _i) => {
                previousData.forEach((_x, _y) => {
                    if (_i == _y) {
                        if (_x?.amount != _v?.amount) {
                            dataChange = true;
                        }
                    }
                })
            })
        }
        return dataChange;

    }


    const handleNotes = async () => {
        if (handleValidate()) {
            console.log("typeeee-----> ", typeStatus);
            let status = ""
            if (typeStatus == 'orderPicup') {
                status = "3"
            }
            if (typeStatus == 'pickup') {
                status = "4"
            }
            if (typeStatus == 'ssdTaken') {
                status = "5"
            }
            if (typeStatus == 'outOfDelivery') {
                status = "6"
            }
            if (typeStatus == 'delivered') {
                status = "7"
            }
            if (typeStatus == 'declined') {
                status = "8"
            }


            let formData = {
                OrderID: data?.[0]?.orderID,
                Status: status,
                FinalPrice: payableAmount,
                DockNotes: addNotes,
                data: postData
            }
            console.log("formDta", formData);
            setSubmitLoader(true);
            await axios.post(API_URL + "UpdateInvoiceInfo?code=" + API_KEY, formData, config).then((res) => {
                //setLoader(false)
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    window.$('#myModal').modal('hide')
                    getOrderDetail();
                    sendNotes();
                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)
            }).catch(function (error) {
                setSubmitLoader(false)
            })
        }

    }
    const getStaticNote = () => {
        let pName = [];
        if (data.length) {
            data.forEach((_v, _i) => {
                previousData.forEach((_x, _y) => {
                    if (_i == _y) {
                        if (_x?.amount != _v?.amount) {
                            pName.push(_v?.productName + "(" + _v?.quantity + ")")
                        }
                    }
                })
            })
        }
        let newString = "";
        newString = "Updated Quantity of " + pName.filter((_v, _i) => {
            return _v;
        })
        return newString;
    }
    const sendNotes = async () => {
        let notes = addNotes + " ; " + getStaticNote()
        let formData = {
            "OrderID": orderID,
            "Action": (typeStatus == 'orderPicup') ? "Ready For Pick Up" : (typeStatus == 'pickup') ? "Left Warehouse" : (typeStatus == 'ssdTaken') ? "Staged At SSD" : (typeStatus == 'outOfDelivery') ? "Out For Delivery" : (typeStatus == 'delivered') ? "Delivered" : (typeStatus == 'declined') ? "Declined" : "",
            "Notes": (typeStatus == 'declined') ? addNotes : notes
        }
        await axios.post(API_URL + "AddOrderLogInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode == "200") {
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const handleButtons = async (type) => {
        if (checkData()) {
            openPopup(type)
        } else {
            handleSubmit(type);
        }

    }

    const handleDeclineButton = async (type) => {
        openPopup(type);
    }

    const handleSubmit = async (type) => {
        console.log("tyeee", type);
        let status = ""
        if (type == 'orderPicup') {
            status = "3"
        }
        if (type == 'pickup') {
            status = "4"
        }
        if (type == 'ssdTaken') {
            status = "5"
        }
        if (type == 'outOfDelivery') {
            status = "6"
        }
        if (type == 'delivered') {
            status = "7"
        }
        if (type == 'declined') {
            status = "8"
        }

        let formData = {
            OrderID: data?.[0]?.orderID,
            Status: status,
            FinalPrice: payableAmount,
            data: postData
        }
        console.log("denid", formData);
        setSubmitLoader(true);
        await axios.post(API_URL + "UpdateInvoiceInfo?code=" + API_KEY, formData, config).then((res) => {
            //setLoader(false)
            if (res.data.responseCode === "200") {
                toast.success(res.data.responseMessage);
                getOrderDetail();
            } else {
                toast.error(res.data.responseMessage);
            }
            setSubmitLoader(false)
        }).catch(function (error) {
            setSubmitLoader(false)
        })


    }

    const handleFloatNumber = (e) => {
        if ((e.which != 46 || e.target.value != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    }
    const handleDriverName = (e) => {
        setDriverName(e.target.value);
        setDriverNameError(false);
    }

    const handleTrailerNumber = (e) => {
        const value = e.target.value;
    const alphanumericValue = value.replace(/[^A-Za-z0-9]/g, '');
    const limitedValue = alphanumericValue.slice(0, 15);
        setTrailerNumber(limitedValue);
        setTrailerNumberError(false);
    }
    const handleValidation = () => {
        let formIsValid = true;
        if (!driverName) {
            formIsValid = false;
            setDriverNameError(true);
        }
        if (!trailerNumber) {
            formIsValid = false;
            setTrailerNumberError(true);
        }
        return formIsValid;
    }

    const handleDriverDetails = async () => {
        if (handleValidation()) {
            let formData = {
                "OrderID": data?.[0]?.orderID,
                "CourierName": "Sunshine State Delivery",
                "DriverName": driverName,
                "VehicleNumber": trailerNumber
            }
            await axios.post(API_URL + "CreateSSDInfo?code=" + API_KEY, formData, config).then((res) => {
                //setLoader(false)
                if (res.data.responseCode === "200") {
                    toast.success(res.data.responseMessage);
                    window.$('#myModal2').modal('hide');
                    getOrderDetail();
                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)
            }).catch(function (error) {
                setSubmitLoader(false)
            })

        }

    }


    var QRValue = BASE_URL + "/view-invoice/" + orderID + "/" + userID;

    return (
        <>
            {submitLoader ? <Loader /> : ""}
            <div className="invoicePagesWrapper ssdinvoice">
                <div className="wizardShowOnly">
                    <div className="wizardShowOnly secinfs" ref={(el) => (componentRef = el)}>

                        <div className="row invoiceHeader">
                            <div className="col-md-6">
                                <div className="LogoInvoice">
                                    <img src={LogoInvoice} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="InvoiceTextHead">
                                    <h3>Bill Of Lading</h3>
                                </div>
                            </div>

                        </div>

                        <div className="row orderHeader">
                            <div className="col-md-6">
                                <div className="helloAreas">
                                    {/* <p>Hello {data?.[0]?.buyerName},</p>
                                    <p>Thank you for shopping from our store. </p> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="orderIdText">
                                    <p>BOL ID: <span className="orderNumbersId">#{data?.[0]?.orderID}</span></p>
                                    <p>{moment(data?.[0]?.createdDate).format('MM-DD-YYYY hh:mm A')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row informationBiligns">
                            <div className="col-md-6">
                                <div className="billingImforArea">
                                    <h3>SHIP FROM</h3>
                                    <div className="callAdress samllwidth">
                                        <p>Bevport Distribution LLC
                                            <span className='showblock'>225 S. County Line Rd</span>
                                            <span className='showblock'>  Plant City, FL 33566</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="billingImforArea">
                                    <p ><span className='orderNumbersId' >CARRIER NAME: </span>Sunshine State Delivery</p>
                                    <div className="callAdress">
                                        <p ><span className='orderNumbersId' >DRIVER NAME: </span>{data?.[0]?.driverName} </p>
                                    </div>
                                    <div className="callAdress">
                                        <p ><span className='orderNumbersId' >TRAILER NUMBER: </span>{data?.[0]?.vehicleNumber} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="billingImforArea qr-code">
                                    <QRCode value={QRValue} />
                                    {/* <span className='bolidview'>
                                      <p><span class="orderNumbersId">SSD: </span>{data?.[0]?.courierName}</p>
                                         </span> */}
                                </div>
                            </div>
                        </div>

                        <div className="row informationBiligns Shippinhind">
                            <div className="col-md-6">
                                <div className="billingImforArea">
                                    <h3>SHIP TO</h3>
                                    <div className="callAdress samllwidth">
                                        <p>{data?.[0]?.businessName}</p>
                                        <p>{data?.[0]?.buyerAddress}<span className='showblock'> {data?.[0]?.cityName}, {data?.[0]?.stateName} {data?.[0]?.zipCode}</span></p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row puchaseHistoryTable">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        {data.map((_v, _i) => {
                                            return [
                                                <div className="contaedItemsList">
                                                    <div className="showdata-deivisoin">
                                                        <span className="label-value">Item</span>
                                                        <span className="label-text stinftest">{_v?.productName}<br /><span className='smaltxrs'>{_v?.breweryName}</span></span>


                                                    </div>
                                                    <div className="showdata-deivisoin">
                                                        <span className="label-value">Brand Name</span>
                                                        <span className="label-text">{_v?.brandName}</span>
                                                    </div>
                                                    <div className="showdata-deivisoin">
                                                        <span className="label-value">Cases</span>
                                                        <span className="label-text">
                                                            <InputMask
                                                                mask='9999999'
                                                                value={_v?.quantity}
                                                                onChange={(e) => handleQuantity(e, _i)}
                                                                className="form-control"
                                                                maskChar={null}
                                                            ></InputMask></span>
                                                    </div>
                                                    <div className="showdata-deivisoin">
                                                        <span className="label-value">$ Amount</span>
                                                        <span className="label-text">{parseFloat(_v?.amount).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            ]
                                        })}

                                        <div className="grandTotalDivs">
                                            <span className="gtotoaldiv">
                                                Grand Total (In $)
                                            </span>
                                            <span className="totlaNumbers">
                                                {parseFloat(payableAmount).toFixed(2)}
                                            </span>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="addProfilePopup makewidthgiger">
                            <div className={`modal fade ${showModal2 ? 'show' : ''}`} id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ display: showModal2 ? 'block' : 'none' }} aria-modal="true" data-backdrop="static" data-keyboard="false" >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            {/* <button type="button" className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></button> */}
                                        </div>
                                        <div className="modal-body">

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="firstname">Driver Name</label>
                                                        <input type="text" className={driverNameError ? "form-control error" : "form-control"} placeholder="" onChange={handleDriverName} value={driverName} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="firstname">Trailer Number</label>
                                                        <input type= "text" className={trailerNumberError ? "form-control error" : "form-control"} placeholder="" onChange={handleTrailerNumber} value={trailerNumber}/>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                        <div className="modal-footer text-center">

                                            <button type="button" className="btn btn-primary" onClick={handleDriverDetails}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="addProfilePopup makewidthgiger">
                            <div className="modal fade " id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>

                                        </div>
                                        <div className="modal-body">

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="firstname">Add Notes</label>
                                                        <textarea type="text" className={addNotesError ? "form-control error" : "form-control"} placeholder="" onChange={handleAddNotes} value={addNotes} />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                        <div className="modal-footer text-center">

                                            <button type="button" className="btn btn-primary" onClick={handleNotes}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row Prinatblebtn btns-bottoms">
                            <div className="col-md-12">
                                <div className="formBtns nutiplebtnsarea">
                                    <button type="button" className="btn btn-primary save-button" disabled={(btnDisabled == "3" || btnDisabled == "4" || btnDisabled == "5" || btnDisabled == "6" || btnDisabled == "7" || btnDisabled == "8") ? true : false} onClick={(e) => handleButtons('orderPicup')}>READY FOR PICK UP
                                        {(data?.[0]?.dockNotes != "") ? <span className='havetootip'>
                                            <i className="fa fa-info-circle"></i>
                                            <span className='showcasetext'>{data?.[0]?.dockNotes}</span>
                                        </span> : ""}
                                    </button>

                                    <button type="button" className="btn btn-secondary save-button" disabled={(btnDisabled == "4" || btnDisabled == "5" || btnDisabled == "6" || btnDisabled == "7" || btnDisabled == "8") ? true : false} onClick={(e) => handleButtons('pickup')} >LEFT WAREHOUSE
                                        {(data?.[0]?.wareHouseNotes != "") ? <span className='havetootip'>
                                            <i className="fa fa-info-circle"></i>
                                            <span className='showcasetext'>{data?.[0]?.wareHouseNotes}</span>
                                        </span> : ""}
                                    </button>

                                    <button type="button" className="btn btn-info save-button" disabled={(btnDisabled == "5" || btnDisabled == "6" || btnDisabled == "7" || btnDisabled == "8") ? true : false} onClick={(e) => handleButtons('ssdTaken')} >STAGED AT SSD
                                        {(data?.[0]?.ssdNotes != "") ? <span className='havetootip'>
                                            <i className="fa fa-info-circle"></i>
                                            <span className='showcasetext'>{data?.[0]?.ssdNotes}</span>
                                        </span> : ""}
                                    </button>
                                    <button type="button" className="btn btn-secondary save-button" disabled={(btnDisabled == "6" || btnDisabled == "7" || btnDisabled == "8") ? true : false} onClick={(e) => handleButtons('outOfDelivery')} >OUT FOR DELIVERY
                                        {(data?.[0]?.deliveryNotes != "") ? <span className='havetootip'>
                                            <i className="fa fa-info-circle"></i>
                                            <span className='showcasetext'>{data?.[0]?.deliveryNotes}</span>
                                        </span> : ""}
                                    </button>
                                    <button type="button" className="btn btn-danger save-button" disabled={(btnDisabled == "8" || btnDisabled == "7") ? true : false} onClick={(e) => handleDeclineButton('declined')} >DECLINED
                                        {(data?.[0]?.declineNotes != "") ? <span className='havetootip'>
                                            <i className="fa fa-info-circle"></i>
                                            <span className='showcasetext'>{data?.[0]?.declineNotes}</span>
                                        </span> : ""}
                                    </button>
                                    <button type="button" className="btn btn-success save-button" disabled={(btnDisabled == "7" || btnDisabled == "8") ? true : false} onClick={(e) => handleButtons('delivered')} >DELIVERED
                                        {(data?.[0]?.deliveredNotes != "") ? <span className='havetootip'>
                                            <i className="fa fa-info-circle"></i>
                                            <span className='showcasetext'>{data?.[0]?.deliveredNotes}</span>
                                        </span> : ""}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default OrderDetail