import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Select from 'react-select';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            border: '1px solid #b6b6af',
            boxShadow: null,
        },
        '&:focus': {
            border: '1px solid transparent',
            boxShadow: null,
        }
    })
};
toast.configure()
export default function BrandList() {
    document.title = "BevPort - Inventory"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [activeTab, setActiveTab] = useState("all")
    const [data, setData] = useState([])
    const [dataTemp, setDataTemp] = useState([])
    const [wareHouseList, setWarehouseList] = useState([]);
    const [wareHouseName, setWareHouseName] = useState("")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        let userID = localStorage.getItem("id")
        getInventory();
        getWareHouseList();
    }, [wareHouseName])

    const finalQuantity = (data) => {
        let finalQty = 0
        let addedData = 0;
        let soldData = 0;
        let damagedData = 0;
        let MISCOUNT = 0;
        let newDamaged = 0;
        let samples = 0;
        data?.inventoryData.forEach((v, i) => {

            if (v?.inventoryType === "Added") {
                addedData += v?.quantity
            }
            if (v?.inventoryType === "Sold") {
                soldData = soldData - v?.quantity;
            }
            if (v?.inventoryType === "Damaged") {
                damagedData = damagedData - v?.quantity;
            }
            if (v?.inventoryType === "MISCOUNT") {
                MISCOUNT += v?.quantity
            }
            if (v?.inventoryType.toUpperCase() === "-DAMAGED-") {
                newDamaged += v?.quantity
            }

            if (v?.inventoryType.toUpperCase() === "SAMPLES") {
                samples += v?.quantity
            }

            finalQty = addedData + soldData + damagedData;


            finalQty = finalQty + MISCOUNT;
            finalQty = finalQty + newDamaged;
            finalQty = finalQty + samples;
        })
        return finalQty
    }
    const columns = [
        {
            name: 'Business Name',
            selector: row => row.businessName,
            cell: row => row.businessName,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Brand Name',
            selector: row => row.brandName,
            cell: row => row.brandName,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Product Name',
            selector: row => row.productName,
            sortable: true,
            width: '200px',
        },
        {
            name: 'In Stock',
            selector: row => finalQuantity(row),
            cell: row => finalQuantity(row),
            sortable: true,
            width: '150px',
        },
        {
            name: '',
            selector: '',
            cell: '',
            sortable: false,
            width: '180px',
        },
        {
            name: 'Action',
            width: '120px',
            selector: row => <>
                <Link className="customPLusIcon" to={"/admin/inventory-edit/" + row.productID} state={{ type: 'plus', brandID: row.brandID, userID: row.userID }}><i className="fa fa-plus-square" aria-hidden="true"></i></Link>&nbsp;
                <Link className="customPLusIcon" to={"/admin/inventory-edit/" + row.productID} state={{ type: 'minus', brandID: row.brandID }}><i className="fa fa-minus-square" aria-hidden="true"></i></Link></>,
        },
        {
            name: '',
            selector: '',
            cell: '',
            sortable: false,
            // width: '200px',
        }
    ];




    const getWareHouseList = async () => {
        await axios.post(API_URL + "GetWarehouses?code=" + API_KEY, { UserID: localStorage.getItem("id") }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let wareOption = []
                let wareid = [];
                let resData = res.data.responseData
                resData.forEach((da) => {
                    if (da?.status == 1) {
                        wareOption.push({ value: da.wareHouseCode, label: da.wareHouseName, brandID: da.id });
                    }
                }
                );
                setWarehouseList(wareOption);


            } else {

            }
        }).catch(function (error) {

        })
    }
    const getInventory = async (userID) => {
        await axios.post(API_URL + "GetInventoryDetailsInfo?code=" + API_KEY, { ID: "" }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let arr = res.data.responseData;
                if (!wareHouseName) {
                    let responseData = res.data.responseData
                    
                    const dataWithTimestamp = responseData.map((_v, _i) => {
                        const sortedInventoryData = _v?.inventoryData.map((h, k) => {
                            const dateObject = new Date(h?.createdDate);
                            const timeStamp = dateObject.getTime();
                            return { ...h, createdTimestamp: timeStamp };
                        });

                        sortedInventoryData.sort((a, b) => b.createdTimestamp - a.createdTimestamp); 

                        return { ..._v, inventoryData: sortedInventoryData };
                    });

                    dataWithTimestamp.sort((a, b) => {
                        if (a.inventoryData.length > 0 && b.inventoryData.length > 0) {
                            
                            return b.inventoryData[0].createdTimestamp - a.inventoryData[0].createdTimestamp; 
                        }
                        return 0; 
                    });

                    dataWithTimestamp.map((_v, _i) => {
                        //console.log("Sorted Parent Data", _v);
                    });

                    setData(dataWithTimestamp);
                    // setData(res.data.responseData)
                    setDataTemp(res.data.responseData)
                }
                else {
                    const newARR = arr.filter((_v) => {
                        if (_v?.wareHouseName == wareHouseName) {
                            return _v;
                        }
                    })
                    setData(newARR);
                }
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const handleWarehouseName = (e) => {
        console.log("first", e.label)
        setWareHouseName(e.label);
    }
    const tableData = {
        columns,
        data
    };
    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>
                {
                    data?.inventoryData?.map((v, i) => {
                        return (
                            <>
                                <div className="expandable-tablerow inventorytbds">
                                    <div className="expandable col one">
                                        {v?.brandName}
                                    </div>
                                    <div className="expandable col two">
                                        {v?.productName}
                                    </div>
                                    <div className="expandable col three">
                                        {v?.quantity}
                                    </div>
                                    <div className="expandable col four">
                                        {v?.inventoryType}
                                    </div>
                                    <div className="expandable col five">
                                        <span className='remarkCols'>
                                            {(v?.remarks) ? v?.remarks : "-"}
                                        </span>
                                    </div>
                                    <div className="expandable col six">
                                        {moment(v?.createdDate).format('MM-DD-YYYY hh:mm A')}
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </tbody>
        </table>)
    };


    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            {(data.length) ? <h1>Inventory</h1> : ""}
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    <div className='inventoryTable mange-Fonts'>
                                        <div className="invSelectInventory">
                                            <Select className={"selectTodesign"}
                                                styles={customStyles}
                                                options={wareHouseList}
                                                onChange={handleWarehouseName}
                                                closeMenuOnSelect={true}
                                                placeholder={<div className="italic-placeholder">Warehouse</div>}
                                                isSearchable
                                            />
                                        </div>
                                        <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Inventory">
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                expandableRows={true}
                                                expandableRowsComponent={ExpandedComponent}
                                                noDataComponent={<span className='norecordFound'>No record found!</span>}
                                            //defaultSortFieldId={1}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}