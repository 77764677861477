
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import homecnavs from "./../../assets/images/homecnavs.png";
import brewermaie from "./../../assets/images/brewermaie.png";
import InputMask from 'react-input-mask';
import axios from 'axios';
import BankCardInfo from "./bankInfoCard";
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
import Header from './Header';
import Footer from './Footer';
import Loader from "./Loader"
import StripePaymentCard from "./BrewersStripeCardPayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { STRIPE_PK } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const stripePromise = loadStripe(STRIPE_PK)
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()
function Registration(props) {

    //*********************************************Bank Account Details Variables************************************************* */
    const [bankName, setBankName] = useState("")
    const [bankNameError, setBankNameError] = useState(false)
    const [bankAddress, setBankAddress] = useState("")
    const [bankAddressError, setBankAddressError] = useState(false)
    const [routingNumber, setRoutingNumber] = useState("")
    const [routingNumberError, setRoutingNumberError] = useState(false)
    const [accountNumber, setAccountNumber] = useState("")
    const [accountNumberError, setAccountNumberError] = useState(false)
    const [accountType, setAccountType] = useState("")
    const [accountTypeError, setAccountTypeError] = useState(false)
    const [nameAccount, setNameAccount] = useState("")
    const [accountNameError, setAccountNameError] = useState(false)

    //************************************************END Bank Account Details Variables********************************************* */

    //*******************************************Bank Info Variables ************************************************************ */
    const [bInfoName, setBinfoName] = useState("");
    const [bInfoNameError, setBinfoNameError] = useState(false);
    const [bInfoAddress, setBinfoAddress] = useState("");
    const [bInfoAddressError, setbInfoAddressError] = useState(false);
    const [bInfoRouting, setBinfoRouting] = useState("");
    const [bInfoRoutingError, setBinfoRoutingError] = useState(false);
    const [bInfoAccountNo, setBinfoAccountNo] = useState("");
    const [bInfoAccountNoError, setBinfoAccountNoError] = useState(false);
    const [bInfoAccountType, setBinfoAccountType] = useState("");
    const [bInfoAccountTypeError, setBinfoAccountTypeError] = useState(false);
    const [bInfoNameAccount, setBinfoNameAccount] = useState("");
    const [bInfoNameAccountError, setBinfoNameAccountError] = useState(false);
    //********************************************End Bank info******************************************************* */

    //**************************************************Plan and Payment Variables*************************************************** */
    const [memberShipPlan, setMemberShipPlan] = useState("")
    const [memberShipError, setMemberShipError] = useState(false)
    const [pMethodError, setPMethodError] = useState(false)
    const [nameCard, setNameCard] = useState("")
    const [nameCardError, setNameCardError] = useState(false)
    const [cardNumberError, setCardNumberError] = useState(false)
    const [cardNumberErrorText, setCardNumberErrorText] = useState("")
    const [cardExpireError, setCardExpireError] = useState(false)
    const [cardExpireErrorText, setCardExpireErrorText] = useState("")
    const [cardCvvError, setCardCvvError] = useState(false)
    const [cardCvvErrorText, setCardCvvErrorText] = useState("")
    const [planList, setPlanList] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("")

    const [bankData, setBankData] = useState("");

    const [submitLoader, setSubmitLoader] = useState(false)
    document.title = "BevPort - Brewers Payment"
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    useEffect(() => {
        getPlanDetails()
    }, [])
    useEffect(() => {
        getBankAccountInfo(localStorage.getItem('id'))
    }, [])
    // useEffect(() => {
    //     if (bankData) {
    //         setBinfoName(bankData?.bankName);
    //         setBinfoAddress(bankData?.bankAddress);
    //         setBinfoRouting(bankData?.routingNumber);
    //         setBinfoAccountNo(bankData?.accountNumber);
    //         setBinfoNameAccount(bankData?.accountName);
    //     }
    // }, [bankData])

    const GetBankInfo = () => {
        setBinfoName(bankData?.bankName);
        setBinfoAddress(bankData?.bankAddress);
        setBinfoRouting(bankData?.routingNumber);
        setBinfoAccountNo(bankData?.accountNumber);
        setBinfoNameAccount(bankData?.accountName);
        setBinfoAccountType(bankData?.accountType);
    }

    const getBankAccountInfo = async (userID) => {
        await axios.post(API_URL + "GetUserData?code=" + API_KEY, { "ID": userID }, config).then((res) => {
            if (res.data?.responseCode == "200") {
                let data = res.data?.responseData;
                setBankData(data);
            }
        }).catch(function (error) {
        })
    }
    const getPlanDetails = async () => {
        await axios.post(API_URL + "GetPlans?code=" + API_KEY, { Type: process.env.REACT_APP_ENV }, config).then((res) => {
            if (res.data.responseCode === "200") {
                setPlanList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }





    // useEffect(() => {
    //     let id = localStorage.getItem("id");
    //     let isAuthentic = localStorage.getItem("isAuthentic")
    //     if(id && isAuthentic === "true"){
    //         let userType = localStorage.getItem("userType")
    //         if(userType == "1"){
    //             navigate("/brewer/add-brand")
    //         }else if(userType == "2"){

    //         }
    //     }
    // },[])
    const handlePaymentMethod = (e) => {
        setPMethodError(false)
        setPaymentMethod(e.target.value)
        setBinfoNameError(false)
        setbInfoAddressError(false)
        setBinfoRoutingError(false)
        setBinfoAccountNoError(false)
        setBinfoAccountTypeError(false)
        setBinfoNameAccountError(false)
        setNameCardError(false)
        setCardNumberError(false)
        setCardExpireError(false)
        setCardCvvError(false)
        if (e.target.value == "bank") {
            GetBankInfo();
        } else {
            setBinfoName("")
            setBinfoAddress("")
            setBinfoRouting("")
            setBinfoAccountNo("")
            setBinfoAccountType("")
            setBinfoNameAccount("")
        }
    }

    const handleMemberPlan = (e) => {
        if (e.target.value == 'Select Membership Plan') {
            setMemberShipPlan("");
        } else {
            setMemberShipError(false)
            setMemberShipPlan(e.target.value)
        }

    }

    //**************************************************************Bank Info Function***************************************************** */

    const handleBinfoName = (e) => {
        setBinfoName(e.target.value);
        setBinfoNameError(false)
    }

    const handleBinfoAddress = (e) => {
        setBinfoAddress(e.target.value);
        setbInfoAddressError(false);
    }

    const handleBinfoRoutingNo = (e) => {
        setBinfoRouting(e.target.value);
        setBinfoRoutingError(false);
    }

    const handleBinfoAccountNo = (e) => {
        setBinfoAccountNo(e.target.value);
        setBinfoAccountNoError(false);
    }

    const handleBinfoAccountType = (e) => {
        setBinfoAccountType(e.target.value);
        setBinfoAccountTypeError(false);
    }

    const handleBinfoNameAccount = (e) => {
        setBinfoNameAccount(e.target.value);
        setBinfoNameAccountError(false);
    }
    const handleValidation = () => {
        let formIsValid = true;
        if (!bInfoName) {
            formIsValid = false;
            setBinfoNameError(true);
        }
        if (!bInfoAddress) {
            formIsValid = false;
            setbInfoAddressError(true);
        }
        if (!bInfoRouting) {
            formIsValid = false;
            setBinfoRoutingError(true);
        }
        if (!bInfoAccountNo) {
            formIsValid = false;
            setBinfoAccountNoError(true);
        }
        if (!bInfoAccountType) {
            formIsValid = false;
            setBinfoAccountTypeError(true);
        }
        if (!bInfoNameAccount) {
            formIsValid = false;
            setBinfoNameAccountError(true);
        }
        if (!memberShipPlan) {
            formIsValid = false;
            setMemberShipError(true)
        }

        if (paymentMethod == 0) {
            formIsValid = false;
            setPMethodError(true);
        }
        return formIsValid;
    }

    const handlememberShipPlanErr = (opt) => {
        if(opt == 0){
            setMemberShipError(true);
        }
    }

    const handleSubmit = async () => {

        if (handleValidation()) {
            setSubmitLoader(true);
            let formData = {
                "ID": localStorage.getItem("id"),
                "UserBankName": bInfoName,
                "UserAccountNumber": bInfoAccountNo,
                "BankRoutingNumber": bInfoRouting,
                "BankAccountType": bInfoAccountType,
                "UserBankAddress": bInfoAddress,
                "UserAccountName": bInfoNameAccount
            }
            await axios.post(API_URL + "UpdateBankInfo?code=" + API_KEY, formData, config).then((res) => {
                if (res.data?.responseCode == "200") {
                    localStorage.clear();
                    toast.success(res.data?.responseMessage);
                    setSubmitLoader(false);
                    navigate('/login');
                } else {
                    setSubmitLoader(false);
                    toast.error(res.data.responseMessage);
                }
            }).catch(function (error) {
            })
        }
    }

    const resetAll = () => {
        setBinfoName("");
        setBinfoAddress("");
        setBinfoRouting("");
        setBinfoAccountNo("");
        setBinfoAccountType("");
        setBinfoNameAccount("");
    }

    return (
        <>

            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />

                    {submitLoader ? <Loader /> : ""}

                    <section className="simplyfying Empowering registraionPages" id="simplys">
                        <div className="simplyfyingDiv">
                            <div className="container-fluid">
                                <div className="row upcontent">
                                    <div className="col-md-5 simplecontent registrationPg">
                                        <div className="simplecontentimg">
                                            <img className="img-responsive" src={homecnavs} />
                                        </div>
                                    </div>
                                    <div className="col-md-7 manBgimg">
                                        <div className="manImg">
                                            <img className="img-responsive" src={brewermaie} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="moveTopUp registraionPhone">
                                        <div className="container">
                                            <div className="row main-contentprt">
                                                <div className="col-md-4">
                                                    <div className="textSimply">
                                                        <h1><span class="browntext">Make</span>Payments</h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section id="registerformc">
                        <div className="regiterComponent colorshade">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-headings">
                                            <h2>Membership Plan and Payment</h2>
                                            <span className="error-message">You won’t be charged until your license is approved and account is activated.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Payment Method <span className="red-tick">*</span></label>
                                            <select className={pMethodError ? "form-control error" : "form-control"} onChange={handlePaymentMethod}>
                                                <option value="0">Select Payment Method</option>
                                                {/* <option value="bank">Bank Account</option> */}
                                                <option value="cc">Credit Card</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Membership Plan <span className="red-tick">*</span></label>
                                            <select className={memberShipError ? "form-control error" : "form-control"} onChange={handleMemberPlan}  >
                                                <option >Select Membership Plan</option>
                                                {
                                                    planList.length && planList.map((_v, _i) => {
                                                        return (
                                                            <option value={_v?.priceID}>{`${_v?.planType} $${_v?.planAmount}`}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    paymentMethod === "bank" ? <><BankCardInfo
                                        handleBinfoName={handleBinfoName}
                                        bInfoName={bInfoName}
                                        bInfoNameError={bInfoNameError}
                                        handleBinfoAddress={handleBinfoAddress}
                                        bInfoAddress={bInfoAddress}
                                        bInfoAddressError={bInfoAddressError}
                                        bInfoRouting={bInfoRouting}
                                        bInfoRoutingError={bInfoRoutingError}
                                        handleBinfoRoutingNo={handleBinfoRoutingNo}
                                        bInfoAccountNo={bInfoAccountNo}
                                        bInfoAccountNoError={bInfoAccountNoError}
                                        handleBinfoAccountNo={handleBinfoAccountNo}
                                        bInfoAccountType={bInfoAccountType}
                                        bInfoAccountTypeError={bInfoAccountTypeError}
                                        handleBinfoAccountType={handleBinfoAccountType}
                                        bInfoNameAccount={bInfoNameAccount}
                                        bInfoNameAccountError={bInfoNameAccountError}
                                        handleBinfoNameAccount={handleBinfoNameAccount}
                                    />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="regitrationBtns">
                                                    <button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit}>Submit</button>
                                                    <button type="submit" name="subxcmit" className="loginb resetFields" onClick={resetAll}>Reset</button>
                                                </div>
                                            </div>
                                        </div></> : (paymentMethod === "cc") ? <Elements stripe={stripePromise}>
                                            <StripePaymentCard
                                                handleMemberPlan={handleMemberPlan}
                                                memberShipPlan={memberShipPlan}
                                                memberShipError={memberShipError}
                                                handlememberShipPlanErr={handlememberShipPlanErr}
                                            />
                                        </Elements> : ""}


                            </div>
                        </div>


                        {/* <div className="regiterComponent colorshade">
                            <div className="container">
                                <Elements stripe={stripePromise}>
                                    <StripePaymentCard
                                    />
                                </Elements>


                            </div>
                        </div> */}

                    </section>



                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Registration;