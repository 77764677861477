import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ProSidebarProvider, Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PeopleIcon from '@mui/icons-material/People';
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BrewerHeader from "./BrewerHeader";
import "./../../assets/css/admin-style.css"
import Logo from "./../../assets/images/logo.png"
import SmallLogo from "./../../assets/images/small-logo.png"
import OrderListIcon from '@mui/icons-material/ViewList';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';


//  For Sales Service icon - Jesse Dudley 3/27/2024
import BackHandIcon from '@mui/icons-material/BackHand';

export default function SideBar({ collapse }) {
    const [sideBarDisable, setSideBarDisable] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("activityState") < 100) {

            // this control disabled everything ----> for  disbaling users to continue if you are under a certain activity state
            setSideBarDisable(true);
        }
    }, [])
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    useEffect(() => {
        collapseSidebar(collapse);
    }, [collapse])
    const navigate = useNavigate();
    const [userType, setUserType] = useState("");
    const logout = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("isAuthentic");
        localStorage.removeItem("userType");
        localStorage.removeItem("firstName");
        localStorage.removeItem("businessName");
        localStorage.removeItem("activityState");
        localStorage.removeItem("brandID");
        localStorage.removeItem("productID");
        navigate("/login")
    }

    useEffect(() => {
        setUserType(localStorage.getItem("userType"))
    }, [])

    return (
        <>
            <Sidebar className="mycustomSidebar" >
                <Menu>
                    <MenuItem className="TopMiamLogo">
                        <span className="logoNotCollapsed"><img src={Logo} /></span>
                        <span className="logoCollapsed"><img src={SmallLogo} /></span>
                    </MenuItem>
                    <MenuItem className="dashboard" icon={<GridViewRoundedIcon />} component={<NavLink to="/brewer/dashboard" className="link" />} disabled={sideBarDisable}> Dashboard </MenuItem>
                    <SubMenu label="Brands" className="brands" icon={<DynamicFeedIcon />} active={(window.location.pathname == "/brewer/add-brand" || window.location.pathname == "/brewer/brand-list" || (window.location.pathname).toLowerCase().includes(("brewer/edit-brand").toLowerCase())) ? true : false} defaultOpen={(window.location.pathname == "/brewer/add-brand" || window.location.pathname == "/brewer/brand-list") ? true : false} disabled={sideBarDisable}>
                        <MenuItem className="brands-sub" component={<NavLink to="/brewer/add-brand" className="link" />} icon={<LibraryAddOutlinedIcon />} disabled={sideBarDisable}> Add Brand </MenuItem>
                        <MenuItem component={<NavLink to="/brewer/brand-list" className="link" />} icon={<FormatListBulletedOutlinedIcon />} disabled={sideBarDisable}> Brand List </MenuItem>
                    </SubMenu>
                    <SubMenu label="Products" icon={<ProductionQuantityLimitsSharpIcon />} active={((window.location.pathname).toLowerCase().includes(("brewer/add-product").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("brewer/edit-product").toLowerCase()) || window.location.pathname == "/brewer/product-list") ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("brewer/add-product").toLowerCase()) || window.location.pathname == "/brewer/product-list") ? true : false} disabled={sideBarDisable}>
                        <MenuItem icon={<AddToPhotosOutlinedIcon />} component={<NavLink to="/brewer/add-product" className="link" />} disabled={sideBarDisable}>Add Product</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/brewer/product-list" className="link" />} disabled={sideBarDisable}>Product List</MenuItem>
                    </SubMenu>

                    <SubMenu label="Manage Team" icon={<ProductionQuantityLimitsSharpIcon />} active={((window.location.pathname).toLowerCase().includes(("/brewer/team-member").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/brewer/salesrep").toLowerCase()) || window.location.pathname == "/brewer/team-member") ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/brewer/salesrep").toLowerCase()) || window.location.pathname == "/brewer/team-member") ? true : false} disabled={sideBarDisable}>
                        <MenuItem icon={<PeopleIcon />} component={<NavLink to="/brewer/team-member" className="link" />} disabled={sideBarDisable}> Team Members </MenuItem>
                        <MenuItem icon={<PeopleIcon />} component={<NavLink to="/brewer/salesrep" className="link" />} disabled={sideBarDisable}> Sales Rep list </MenuItem>
                    </SubMenu>


                    <MenuItem icon={<PeopleIcon />} component={<NavLink to="/brewer/allSalesRep" className="link" />} disabled={sideBarDisable}> Manage Sales Rep </MenuItem>
                    <SubMenu label="Order Management" icon={<ReceiptIcon />} active={((window.location.pathname).toLowerCase().includes(("/brewer/order-history").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/brewer/order-history-list").toLowerCase()) || window.location.pathname == "/brewer/invoice-list") ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/brewer/order-history").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/brewer/order-history-list").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/brewer/invoice-list").toLowerCase())) ? true : false} disabled={sideBarDisable}>
                        <MenuItem icon={<OrderListIcon />} component={<NavLink to="/brewer/order-history" className="link" />} disabled={sideBarDisable}> Sales Order </MenuItem>
                        <MenuItem icon={<FactCheckIcon />} component={<NavLink to="/brewer/order-history-list" className="link" />} disabled={sideBarDisable}> Order History </MenuItem>
                        <MenuItem icon={<FileCopyIcon />} component={<NavLink to="/brewer/invoice-list" className="link" />} disabled={sideBarDisable}> Invoice </MenuItem>
                    </SubMenu>

                    <SubMenu label="Manage Delivery" icon={<DeliveryDiningIcon />} active={((window.location.pathname).toLowerCase().includes(("/brewer/setup-delivery").toLowerCase()) || (window.location.pathname).toLowerCase().includes(("/brewer/setup-delivery-list").toLowerCase())) ? true : false} defaultOpen={((window.location.pathname).toLowerCase().includes(("/brewer/setup-delivery").toLowerCase()) || window.location.pathname == "/brewer/setup-delivery-list") ? true : false} disabled={sideBarDisable}>
                        <MenuItem icon={<AddToPhotosOutlinedIcon />} component={<NavLink to="/brewer/setup-delivery" className="link" />} disabled={sideBarDisable}>Setup Delivery</MenuItem>
                        <MenuItem icon={<FormatListBulletedOutlinedIcon />} component={<NavLink to="/brewer/setup-delivery-list" className="link" />} disabled={sideBarDisable}>Delivery List</MenuItem>
                    </SubMenu>
                    <MenuItem className="dashboard" icon={<AssuredWorkloadIcon />} component={<NavLink to="/brewer/compliance" className="link" />} disabled={sideBarDisable}> Compliance </MenuItem>
                    <MenuItem className="dashboard" icon={<Inventory2Icon />} component={<NavLink to="/brewer/inventory" className="link" />} disabled={sideBarDisable}> Inventory </MenuItem>
                    <MenuItem icon={<OrderListIcon />} component={<NavLink to="/brewer/mybuyers" className="link" />} disabled={sideBarDisable}> My Buyers </MenuItem>
                    <MenuItem icon={<VisibilityIcon />} component={<NavLink to="/brewer/brewerbuyerview" className="link" />} disabled={sideBarDisable}> Buyers View </MenuItem>
                    <MenuItem icon={<BackHandIcon />} component={<NavLink to="/brewer/SalesServices" className="link" />} disabled={sideBarDisable}> Sales Services </MenuItem>
                    <MenuItem icon={<LogoutRoundedIcon />} onClick={logout}> Logout </MenuItem>
                </Menu>
            </Sidebar>
        </>
    )
}