
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import buyerstopbase from "./../../assets/images/buyerstopbase.png";
import beerwindows from "./../../assets/images/beer-windows.jpg";
import InputMask from 'react-input-mask';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, SITE_KEY, BLOB_URL, BLOB_TEAM_MEMBER_CONTAINER, BLOB_BRAND_CONTAINER } from './../../config/constant';
import Loader from "./Loader"
import Header from './Header';
import Footer from './Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Select from 'react-select';
import Dropzone, { useDropzone } from 'react-dropzone'; // for image upload and drag and drop
import uploadFileToBlob, { isStorageConfigured, deleteBlobIfItExists } from '../brewers/blob';

const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()

export default function Registartion() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    document.title = "BevPort - Buyer Registration"
    const navigate = useNavigate();
    const [fName, setFname] = useState("")
    const [fnameError, setFnameError] = useState(false)
    const [lName, setLname] = useState("")
    const [lnameError, setLnameError] = useState(false)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [cEmail, setCEmail] = useState("")
    const [cEmailError, setCEmailError] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [cPassword, setCPassword] = useState("")
    const [cPasswordError, setCPasswordError] = useState(false)
    const [emailSameError, setEmailSameError] = useState(false)
    const [passwordSameError, setPasswordSameError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [passText, setPassText] = useState("")
    const [dupError, setDupError] = useState(false)
    const [dupErrorText, setDupErrorText] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false);
    const [visible, SetVisible] = useState(false);
    const [confirmVisible , setConfirmVisible] = useState(false);

    //*****************************************************************BUSSINESS DETAILS VARIABLE************************************************************** */
    const [bName, setBname] = useState("")
    const [bnameError, setbNameError] = useState(false)
    const [ein, setEin] = useState("")
    const [einError, setEinError] = useState(false)
    const [stn, setStn] = useState("")
    const [country, setCountry] = useState("")
    const [countryError, setCountryError] = useState(false)

    // Buyer Payment Option const
    const [buyerPaymentTypeValue, setBuyerPaymentTypeValue] = useState("");// buyer payment type value get and set
    const [buyerPaymentTypeError, setBuyerPaymentTypeError] = useState(false); // buyer payment type error


    // Liquor License Number Const ------
    const [lLicenseNumber, setLLicenseNumber] = useState("");
    const [lLicenseError, setlLicenseError] = useState(false);
    const [lLicenseImg, setlLicenseImg] = useState([]);
    const [lLicenseImgPre, setlLicenseImgPre] = useState([]);
    const [lLicenseImgError, setlLicenseImgError] = useState(false);
    const imageFormats = {
        'image/*,.pdf': [] // jd added 1/28/2024 allow pdf and images added ,.pdf'
    };



    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: '100px',
        height: '100%',
        objectFit: "contain"
    };


    const [blobData, setBlobData] = useState("");


    const [state, setState] = useState("")
    const [stateError, setStateError] = useState(false)
    const [city, setCity] = useState("")
    const [SSDCustomerID, setSSDCustomerID] = useState("")
    const [cityError, setCityError] = useState(false)
    const [street, setStreet] = useState("")
    const [streetError, setStreetError] = useState(false)
    const [zip, setZip] = useState("")
    const [zipError, setZipError] = useState(false)
    const [mobile, setMobile] = useState("")
    const [mobileError, setMobileError] = useState(false)
    const [altMobile, setAltMobile] = useState("")
    const [stateList, setStateList] = useState([])
    const [check, setCheck] = useState("")
    const [checkError, setCheckError] = useState(false)
    const [altMobError, setaltMobError] = useState(false);
    const [btnDisable , setBtnDisable] = useState(false);
    //***************************************************************** END BUSSINESS DETAILS VARIABLE************************************************************** */





    useEffect(() => {
    	const loadScriptByURL = (id, url, callback) => {
	    	const isScriptExist = document.getElementById(id);
	    	if (!isScriptExist) {
	      		var script = document.createElement("script");
	      		script.type = "text/javascript";
	      		script.src = url;
	      		script.id = id;
	      		script.onload = function () {
	        		if (callback) callback();
	      		};
	      		document.body.appendChild(script);
	    	}
	    	if (isScriptExist && callback) callback();
	  	}
	  	loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
	    	console.log("Script loaded!");
	  	});
	}, []);
    const handleFirstName = (e) => {
        setFnameError(false)
        setFname(e.target.value)
    }
    const handleLastName = (e) => {
        setLnameError(false)
        setLname(e.target.value)
    }
    const handleEmail = (e) => {
        setEmailError(false)
        setEmailSameError(false)
        setEmail(e.target.value)
        setDupError(false)
        setDupErrorText("");
    }
    const handleConfirmEmail = (e) => {
        setCEmailError(false)
        setEmailSameError(false)
        setCEmail(e.target.value)
    }
    const handlePassword = (e) => {
        setPassError(false)
        setPassText("")
        setPasswordError(false)
        setPassword(e.target.value)
    }
    const handleConfirmPassword = (e) => {
        setPasswordSameError(false)
        setCPasswordError(false)
        setCPassword(e.target.value)
    }
    const handleShow = () => {
        SetVisible(!visible);
    }

    const handleConfirmShow = () =>{
        setConfirmVisible(!confirmVisible)  
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handle_LiquorLicenseNumber = (e) => {

       // alert(e.target.value);
        setLLicenseNumber(e.target.value);
        setlLicenseError(false);
    }


    // buyer payments Options array
    const buyerPaymentTypeOption = [
        { label: "-Select-", value: "" },
        { label: "Fintech", value: "1" },
        { label: "Check(COD)", value: "2" },
    ];
    function checkPwd(str) {
        if (str.length < 6) {
            setPassError(true)
            setPassText("Must have at least 6 characters.")
            return false;
        } else if (str.search(/\d/) == -1) {
            setPassError(true)
            setPassText("Must have at least one number.")
            return false;
        } else if (str.search(/[A-Z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one capital letter.")
            return false;
        } else if (str.search(/[a-z]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one small letter.")
            return false;
        } else if (str.search(/[!@#$%^&*()]/) == -1) {
            setPassError(true)
            setPassText("Must have at least one symbol.")
            return false;
        }
        return true;
    }
   
    const checkEmailAddressFunc = async (e) => {
        setDupError(false)
        setDupErrorText("")
        await axios.post(API_URL + "GetUser?code=" + API_KEY, { EmailID: e.target.value }, config).then((res) => {
            if (res.data.responseCode === "401") {
                setDupError(true)
                setDupErrorText(res.data.responseMessage)
                setBtnDisable(true);
            }else{
                setBtnDisable(false);
            }
        }).catch(function (error) {
            console.log(error)
            setBtnDisable(false);
        })
    }


    //***************************************************BUSSINESS DETAILS FUNCTION******************************************************************* */
    useEffect(() => {
        getStateList()
    }, [])

    const getStateList = async () => {
        await axios.post(API_URL + "GetStateDataBuyer?code=" + API_KEY, {}, config).then((res) => {
            if (res.data.responseCode === "200") {
                setStateList(res.data.responseData)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    useEffect(() => {
        if (mobile != "" && altMobile != "") {
            checkAltNo();
        }

    }, [mobile, altMobile])

    const handleCheck = (e) => {
        setCheck(e.target.checked);
        setCheckError(false);
    }
    const handleBName = (e) => {
        setbNameError(false)
        setBname(e.target.value)
    }
    const handleEIN = (e) => {
        setEinError(false)
        setEin(e.target.value)
    }
    const handleSTN = (e) => {
        setStn(e.target.value)
    }
    const handleCountry = (e) => {
        setCountryError(false)
        setCountry(e.target.value)
    }
    const handleState = (e) => {
        setStateError(false)
        setState(e.target.value)
    }
    const handleCity = (e) => {
        setCityError(false)
        setCity(e.target.value)
    }

    const handleSSDCustomerID = (e) => {
        
        setSSDCustomerID(e.target.value)
    }
    const handleStreet = (e) => {
        setStreetError(false)
        setStreet(e.target.value)
    }
    const handleZIP = (e) => {
        setZipError(false)
        setZip(e.target.value)
    }
    const handleMobile = (e) => {
        setMobileError(false)
        setMobile(e.target.value)
    }
    const handleAltMobile = (e) => {
        setAltMobile(e.target.value)
    }

    const handleBuyerPaymentType = (e) => {

        var PaymentLabel = document.getElementById("BuyerPaymentLinkLabel");
        var BuyerPaymentLinks = document.getElementById("BuyerPaymentLinks");

        // Go to links on change --------------
        switch (e.value) {
            case "1":
                PaymentLabel.classList.remove("hidden"); // remove bootstrap hidden class				
                BuyerPaymentLinks.classList.remove("hidden");
                break;
            case "2":
                PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
                BuyerPaymentLinks.classList.add("hidden");
                break;
            default:
                PaymentLabel.classList.add("hidden"); // add bootstrap hidden class				
                BuyerPaymentLinks.classList.add("hidden");
                break;
        }
        //------------- End Switch  -------------

        setBuyerPaymentTypeValue({ value: e.value, label: e.label });
        setBuyerPaymentTypeError(false);
    }


    const checkAltNo = () => {
        if (mobile != "" && altMobile != "") {
            if (mobile == altMobile) {
                setaltMobError(true);
                 setBtnDisable(true);
            } else {
                setaltMobError(false);
                setBtnDisable(false);
            }
        }
    }

    //*************************************************** END BUSSINESS DETAILS FUNCTION******************************************************************* */

    const handleValidation = (sumbitNumber) => {
        let formIsValid = true;

        var zoomElement_ContactDetails = document.getElementById("zoomContactDetails");
        var zoomElement_BusinessDetails = document.getElementById("zoomBusinessDetails");

        // Jesse Dudley --->5/23/2024--->commented out license image and licensenumber for being required to sign up as a buyer
        //if (!lLicenseImgPre.length) {
        //    if (!lLicenseImg.length) {
        //        formIsValid = false;
        //        setlLicenseImgError(true);
        //        zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        //    }
        //}

        //if (!lLicenseNumber) {
        //    formIsValid = false;
        //    setlLicenseError(true);
        //    zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        //}
        //-------------------------------------------------------------------------------------------------------

        // check validation for buyerPaymentType / 6/07 /2024 commented out and made it not  a required field
        //if (buyerPaymentTypeValue == "" || buyerPaymentTypeValue.value == "") {
        //    formIsValid = false;
        //    setBuyerPaymentTypeError(true);
        //    zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        //}

        // This is the terms and agreement checkbox - might put some where else 
        if (sumbitNumber === 2) {
            if (!check) {
                formIsValid = false;
                setCheckError(true);
                zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
            }
        }
       


        // if (altMobile) {
        //     if (mobile == altMobile) {
        //         setaltMobError(true);
        //         //  setBtnDisable(true);
        //     } else {
        //         setaltMobError(false);
        //         //setBtnDisable(false);
        //     }
        // }

        if (!mobile) {
            formIsValid = false;
            setMobileError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!zip) {
            formIsValid = false;
            setZipError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!street) {
            formIsValid = false;
            setStreetError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!city) {
            formIsValid = false;
            setCityError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!state) {
            formIsValid = false;
            setStateError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!country) {
            formIsValid = false;
            setCountryError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }


        // Jesse Dudley 5/9/2024 ---> comment out Employer Identification Number validation 
        //if (!ein) {
        //    formIsValid = false;
        //    setEinError(true);
        //    zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        //}


        if (!bName) {
            formIsValid = false;
            setbNameError(true);
            zoomElement_BusinessDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (cPassword && password !== cPassword) {
            formIsValid = false;
            setPasswordSameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }
        if (!cPassword) {
            formIsValid = false;
            setCPasswordError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (password && !checkPwd(password)) {
            formIsValid = false;
            setPasswordError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!password) {
            formIsValid = false;
            setPasswordError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (cEmail && email !== cEmail) {
            formIsValid = false;
            setEmailSameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!cEmail) {
            formIsValid = false;
            setCEmailError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!validateEmail(email)) {
            formIsValid = false;
            setEmailError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!email) {
            formIsValid = false;
            setEmailError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!lName) {
            formIsValid = false;
            setLnameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        if (!fName) {
            formIsValid = false;
            setFnameError(true);
            zoomElement_ContactDetails.scrollIntoView({ behavior: 'smooth' });
        }

        return formIsValid;
    }

    // ------------- On drop Liquor License Image--------
    const onDropFunction = (acceptedFiles, fileType) => {

        if (fileType == "LiquorLicense") {
            const renamedAcceptedFiles = acceptedFiles.map((file) => (
                new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${localStorage.getItem("id")}_lLicenseImg_${file.name}`, { type: file.type })
            ))
            setlLicenseImg(renamedAcceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                imageType: fileType
            })));
            setlLicenseImgError(false); // set image to false if user added a image

        }

    }
	//-------------------------------------------------------

    const lLicensethumb = lLicenseImg.map((file, i) => {
        let fileName = file?.preview;
        let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <span className="deleteThis" onClick={() => removeFiles(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });
    const lLicensethumbPre = lLicenseImgPre.map((file, i) => {
        let fileName = file?.preview;
        let fFileName = (fileName.split("/")).slice(-1)[0];
        return (
            <div className="mainThumbnail" key={file.name}>
                <span className="deleteThis" onClick={() => removeFilesPre(i, "LiquorLicense", file?.fileID, fFileName)}><i className="fa fa-times"></i></span>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        )
    });


    // ------------------------- Upload  image files to blob related functions -------------
    const removeFiles = (index, type, fileID, fileName) => {
        // if (typeof fileID !== "undefined") {
        // 	deleteFiles(fileID, fileName)
        // }
        if (type == "LiquorLicense") {
            const newFiles = [...lLicenseImg];
            newFiles.splice(index, 1);
            setlLicenseImg(newFiles);
        }

    }

    const removeFilesPre = (index, type, fileID, fileName) => {
        if (typeof fileID !== "undefined") {
           // deleteFiles(fileID, fileName); // test this
        }
        if (type === "LiquorLicense") {
            const newFiles = [...lLicenseImgPre];
            newFiles.splice(index, 1);
            setlLicenseImgPre(newFiles);
        }

    }






    // Save images function 
    const saveBuyerImages = async (prodID, UserID) => {
        let da = (lLicenseImg);
        console.log(da)
        let finalArray = [];
        da.forEach((_v, _i) => {
            let d = {
                "UserID": UserID,
                "BRANDID": 0,
                "PRODUCTID": prodID,
                "FILETYPE": _v?.imageType,
                "FILEPATH": BLOB_URL + BLOB_BRAND_CONTAINER + '/' + _v?.name,
                "FILEDATA": "",
                "FILENAME": _v?.name
            }
            finalArray.push(d)
        })

        if (da.length) {
            const blobsInContainer: string[] = await uploadFileToBlob(da, BLOB_BRAND_CONTAINER).then(async res => {
                await axios.post(API_URL + "SaveFilesInfo?code=" + API_KEY, finalArray, config).then((res) => {
                    setlLicenseImg([])

                }).catch(function (error) {
                    console.log(error)
                })
            })
        }
    }
	// -----------------end save Images function ----------------------

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation(2)) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async token => {
                    setSubmitLoader(true)
            setBtnDisable(true);
            let formData = {
                "FirstName": fName,
                "LastName": lName,
                "emailid": email,
                "Password": password,
                "UserType": "2",
                "BusinessName": bName,
                "EIN": ein,
                "StateTaxNumber": stn,
                "StreetAddress": street,
                "BusinessPhone": mobile,
                "OptionalPhone": altMobile,
                "State": state,
                "Country": country,
                "City": city,
                "ZipCode":zip,
                "CaptchaToken": token,
                "BuyerPaymentType": buyerPaymentTypeValue.value,
                "LiquorLicenseNumber": lLicenseNumber,
                "SSDCustomerID": SSDCustomerID
                    }


            await axios.post(API_URL + "RegisterUser?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode === "200") {

                    toast.success(res.data.responseMessage);

                    let UserData = res.data?.responseData[0];

                    saveBuyerImages(0, UserData?.id);// save buyer images after saving user info
                   navigate('/login');
                } else {
                    toast.error(res.data.responseMessage);
                }
                setSubmitLoader(false)
                setBtnDisable(false);
            }).catch(function (error) {
                console.log(error)
                setSubmitLoader(false)
                setBtnDisable(false);
            })
                })})
            
        }
    }
    useEffect(() => {
        let id = localStorage.getItem("id");
        let isAuthentic = localStorage.getItem("isAuthentic")
        if(id && isAuthentic === "true"){
            let userType = localStorage.getItem("userType")
            if(userType == "1"){
                navigate("/brewer/add-brand")
            }else if(userType == "2"){

            }
        }
    },[])

    const resetAll = () => {
        setFname("");
        setLname("");
        setEmail("");
        setCEmail("");
        setPassword("");
        setCPassword("");
        setBname("");
        setEin("");
        setStn("");
        setCountry("");
        setState("");
        setCity("");
        setStreet("");
        setZip("");
        setMobile("");
        setAltMobile("");
    }

    // --------------------------ContactDetailsContinue---------------------------------
    const ContactDetailsContinue = () => {   
       // e.preventDefault();
        if (handleValidation(1)) {
            var element = document.getElementById("ContactDetailsDiv");
            element.classList.add("hidden");

            var element2 = document.getElementById("BusinessDetailsDiv");
            element2.classList.add("hidden");

            var element3 = document.getElementById("paymentMethodDiv");
            element3.classList.remove("hidden");

        }//--   
    }
    // ------------------------end ContactDetailsContinue------------------------------


    // Payment Details Back Button
    const PaymentDetailsBackButton = () => {

        var element = document.getElementById("ContactDetailsDiv");
        element.classList.remove("hidden");

        var element2 = document.getElementById("BusinessDetailsDiv");
        element2.classList.remove("hidden");

        var element3 = document.getElementById("paymentMethodDiv");
        element3.classList.add("hidden");
    }



    return (
        <>
         {submitLoader ? <Loader /> : ""}
            <div className="site-wrapper">
                <div className="main-wrapper">
                    <Header />

                    <section className="simplyfying Empowering registraionPages buyersregs" id="simplys">
                        <div className="simplyfyingDiv">
                            <div className="container-fluid">
                                <div className="row upcontent">
                                    <div className="col-md-5 simplecontent registrationPg buyrersbg">
                                        <div className="simplecontentimg">
                                            <img className="img-responsive" src={buyerstopbase} />
                                        </div>
                                    </div>
                                    <div className="col-md-7 manBgimg">
                                        <div className="manImg">
                                            <img className="img-responsive" src={beerwindows} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="moveTopUp Revolutionizing">
                                        <div className="container">
                                            <div className="row main-contentprt">
                                                <div className="col-md-4">
                                                    <div className="textSimply whitecolorhead">
                                                        <h1><span className="darkblacktext">Buyers</span>Signup</h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>


                    {/* // contact Details ---> scroll to div on form submit error*/}
                    <div id="zoomContactDetails">
                    </div>

                    <section id="registerformc">
                        <div id="ContactDetailsDiv" style={{ padding: "20px 0" }} className="regiterComponent">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div style={{ marginBottom: "10px"}} className="form-headings">
                                            <h2>Contact Details</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">First Name <span className="red-tick"> *</span></label>
                                            <input type="text" className={fnameError ? "form-control error" : "form-control"} value={fName} placeholder="Enter First Name" onChange={handleFirstName} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Last Name <span className="red-tick"> *</span></label>
                                            <input type="text" className={lnameError ? "form-control error" : "form-control"} value={lName} placeholder="Enter Last Name" onChange={handleLastName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Email <span className="red-tick">*</span></label>
                                            <input type="text" className={emailError ? "form-control error" : "form-control"} value={email} placeholder="Enter Email ID" onChange={handleEmail} onBlur={checkEmailAddressFunc} />
                                            {dupError ? <span className="error-message">{dupErrorText}</span> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="firstname">Confirm Email <span className="red-tick">*</span></label>
                                            <input type="text" className={cEmailError ? "form-control error" : "form-control"} value={cEmail} placeholder="Confirm Email ID" onChange={handleConfirmEmail} />
                                            {emailSameError ? <span className="error-message">Confirm email should be same as email.</span> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group passwordeye">
                                            <label for="firstname">Password <span className="red-tick">*</span></label>
                                            <div className="showhidepassowrd">
                                                <input type={visible ? "text" : "password"} className={passwordError ? "form-control error" : "form-control"} value={password} placeholder="Enter Password" onChange={handlePassword} />
                                                {passError ? <span className="error-message">{passText}</span> : ""}
                                                <span className="eyeslash" onClick={handleShow} > {!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group passwordeye">
                                            <label for="firstname">Confirm Password <span className="red-tick">*</span></label>
                                            <div className="showhidepassowrd">
                                            <input type={confirmVisible ? "text" : "password"} className={cPasswordError ? "form-control error" : "form-control"} value={cPassword} placeholder="Confirm Password" onChange={handleConfirmPassword} />
                                            {passwordSameError ? <span className="error-message">Confirm password should be same as password.</span> : ""}
                                            <span className="eyeslash" onClick={handleConfirmShow} > {!confirmVisible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                 

                               
                            </div>
                        </div>


                        {/* // business Details ---> scroll to div on form submit error*/}
                        <div id="zoomBusinessDetails">
                        </div>


                        <div style={{ padding: "20px 0" }} className="regiterComponent colorshadebuyer">
                            <div className="container">

                                <div id="BusinessDetailsDiv">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div style={{ marginBottom: "10px" }} className="form-headings">
                                                <h2>Business Details</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">Business Name <span className="red-tick">*</span></label>
                                                <input type="text" className={bnameError ? "form-control error" : "form-control"} value={bName} placeholder="Enter Business Name" onChange={handleBName} />
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">Mobile Number <span className="red-tick">*</span></label>
                                                <InputMask
                                                    mask='(999) 999 9999'
                                                    value={mobile}
                                                    onChange={handleMobile}
                                                    className={mobileError ? "form-control error" : "form-control"}
                                                    maskChar={null}
                                                    placeholder="Mobile Number"
                                                >
                                                </InputMask>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">Street Address <span className="red-tick">*</span></label>
                                                <input type="text" className={streetError ? "form-control error" : "form-control"} value={street} placeholder="Enter Street Address" onChange={handleStreet} />
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">City <span className="red-tick">*</span></label>
                                                <input type="text" className={cityError ? "form-control error" : "form-control"} value={city} placeholder="City" onChange={handleCity} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">State <span className="red-tick">*</span></label>
                                                <select className={stateError ? "form-control error" : "form-control"} onChange={handleState} value={state}>
                                                    <option value="">Select State</option>
                                                    {
                                                        stateList.length && stateList.map((_v, _i) => {
                                                            return (
                                                                <option key={_i} value={_v.id}>{_v.statename}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">ZIP Code <span className="red-tick">*</span></label>
                                                <InputMask
                                                    mask='99999'
                                                    value={zip}
                                                    onChange={handleZIP}
                                                    className={zipError ? "form-control error" : "form-control"}
                                                    maskChar={null}
                                                    placeholder="ZIP Code"
                                                >
                                                </InputMask>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">Country <span className="red-tick">*</span></label>
                                                <select className={countryError ? "form-control error" : "form-control"} onChange={handleCountry} value={country}>
                                                    <option value="">Select Country</option>
                                                    <option value="1">USA</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="firstname">SSD Customer ID <span className="red-tick"></span></label>
                                                <InputMask
                                                    mask='*********************'
                                                    value={SSDCustomerID}
                                                    onChange={handleSSDCustomerID}
                                                    className={"form-control"}
                                                    maskChar={null}
                                                    placeholder="SSD Customer ID"
                                                >
                                                </InputMask>
                                            </div>
                                        </div>

                                        <div className="col-md-3 text-right">
                                            <label for="firstname"> <span className="red-tick"></span></label>
                                            <div className="form-group regitrationBtns">
                                                <button className="loginb" onClick={ContactDetailsContinue}>Continue</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                              


                              

                                <div id="paymentMethodDiv" className="hidden">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div style={{ marginBottom: "10px" }} className="form-headings">
                                                <h2>License Details</h2>
                                                <label style={{fontWeight:"600"}}>The following fields are required prior to placing your first order. You can skip for now and browse available brands.</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="brand-tab">Liquor License Number <span className="red-tick"> </span></label>
                                                <input type="text" className={lLicenseError ? "form-control error" : "form-control"} placeholder="Liquor License Number" value={lLicenseNumber} onChange={handle_LiquorLicenseNumber} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">Employer Identification Number <span className="red-tick"></span></label>
                                                <InputMask
                                                    mask='99-9999999'
                                                    value={ein}
                                                    onChange={handleEIN}
                                                    className={einError ? "form-control error" : "form-control"}
                                                    maskChar={null}
                                                    placeholder="EIN"
                                                >
                                                </InputMask>
                                            </div>
                                        </div>

                                        {/*<div className="col-md-6">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <label for="firstname">Alternative Mobile Number (optional)</label>*/}
                                        {/*        <InputMask*/}
                                        {/*            mask='(999) 999 9999'*/}
                                        {/*            value={altMobile}*/}
                                        {/*            onChange={handleAltMobile}*/}
                                        {/*            className={altMobError ? "form-control error" : "form-control"}*/}
                                        {/*            maskChar={null}*/}
                                        {/*            placeholder="Alternative Mobile Number"*/}
                                        {/*        >*/}
                                        {/*        </InputMask>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                    </div>


                             

                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="brand-tab">Liquor License Image <span className="red-tick"> </span></label>

                                                <Dropzone onDrop={acceptedFiles => onDropFunction(acceptedFiles, "LiquorLicense")} multiple={false} accept={imageFormats} disabled={(lLicenseImg.length !== 0 || lLicenseImgPre.length !== 0) ? true : false}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps({ className: (lLicenseImgError) ? 'dropzone custom-input-drop-buyer  error' : 'dropzone custom-input-drop-buyer' })}>
                                                            <input {...getInputProps()} />
                                                            <p>Drop or Click Liquor License Image.
                                                                <br />Formats accepted: ( JPEG , JPG , PNG , JFIF ).
                                                            </p>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div className="thumbanilOptions">
                                                <aside style={thumbsContainer}>
                                                    {/* {caseUPCthumbPre}
																{caseUPCthumb} */}
                                                    {(lLicenseImg.length) ? lLicensethumb : lLicensethumbPre}
                                                </aside>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="firstname">State Tax Number</label>
                                                <InputMask
                                                    mask='999-99-9999'
                                                    value={stn}
                                                    onChange={handleSTN}
                                                    className="form-control"
                                                    maskChar={null}
                                                    placeholder="If Different From EIN"
                                                >
                                                </InputMask>
                                            </div>
                                        </div>

                                    </div>

                                

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="buyerPaymentType">Payment Method <span className="red-tick"></span></label>
                                                <Select className={buyerPaymentTypeError ? "selectTodesign error" : "selectTodesign"}
                                                    // styles={customStyles}
                                                    //menuPlacement="top"
                                                    options={buyerPaymentTypeOption}
                                                    onChange={handleBuyerPaymentType}
                                                    value={buyerPaymentTypeValue}
                                                    closeMenuOnSelect={true}
                                                    placeholder={<div className="italic-placeholder">Payment Method</div>}
                                                    isSearchable
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <label id="BuyerPaymentLinkLabel" class="hidden" for="PaymentLinkLabel">Please use either link to log in to your Fintech account and Connect with Bevport Distrbution LLC. <span className="red-tick">*</span></label>
                                            {/*----------Link List -----*/}
                                            <ul id="BuyerPaymentLinks" class="hidden">
                                                <li class=""><a href="https://ezenroll.fintech.net/Retailers/Autopay/Index" target="_blank">DEFT (Distributor EFT)</a></li>
                                                <li class=""><a href="https://fintech.com/" target="_blank">Payment Source Subscription</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={handleCheck} />
                                                <label className="form-check-label" for="exampleCheck1">I agree with the <Link to="/termsofuse" target="_blank">Terms and Conditions</Link> and the <Link to="/privacy" target="_blank">Privacy Policy</Link>.</label>
                                                {checkError ? <div className="error-message">Please accept our Terms and Conditions and Privacy Policy by checking the box before proceeding.</div> : ""}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="row">*/}
                                    {/*    <div className="col-md-12">*/}
                                    {/*        <div className="regitrationBtns">*/}
                                    {/*            <button type="submit" name="subxcmit" className="loginb" onClick={handleSubmit} disabled={btnDisable} >Submit</button>*/}
                                    {/*            */}{/*   // Jesse Dudley Commented out 6/7/2024*/}

                                    {/*           */}{/* <button type="submit" name="subxcmit" className="loginb resetFields" onClick={resetAll}>Reset</button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}



                                    <div className="row">
                                      
                                        <div className="col-md-12 text-center">
                                            <label for="firstname"> <span className="red-tick"></span></label>
                                            <div className="form-group regitrationBtns">

                                                <button className="loginb" onClick={PaymentDetailsBackButton}>Back</button> &nbsp;&nbsp;&nbsp;&nbsp;
                                                <button type="submit" name="subxcmit2" className="loginb" onClick={handleSubmit} disabled={btnDisable} >Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        
                                            </div>
                                        </div>
                                    </div>

                                </div> {/*// end payment details  div*/}

                          
                            </div>
                        </div>



                    </section>



                    <Footer />
                </div>
            </div>
        </>
    )
}